import { toLocaleDate } from "utils/date";
import styles from "./createdAt.module.scss";

const CreatedAt = ({ created_at }: { readonly created_at: string }) => {
	const createdDate = toLocaleDate(created_at);
	const [date, time] = createdDate.split(" ");
	return (
		<>
			<div className={styles.date}>{date}</div>
			<div className={styles.time}>{time}</div>
		</>
	);
};

export default CreatedAt;
