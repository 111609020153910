// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Placeholder__placeholder-v5h{height:700px;width:617px;display:flex;flex-direction:column;align-items:center;padding-top:54px}.Placeholder__placeholder-v5h .Placeholder__subtitle-x52{color:#697077;font-size:14px;font-style:normal;font-weight:400;line-height:20px;margin-top:16px}.Placeholder__placeholder-v5h .Placeholder__callToAction-qFM{color:#21272a;font-size:16px;font-style:normal;font-weight:400;line-height:24px;position:relative;margin-top:24px}.Placeholder__placeholder-v5h .Placeholder__callToAction-qFM svg{position:absolute;bottom:0;right:100%}`, "",{"version":3,"sources":["webpack://./src/modules/PaymentSystems/Plugin/Placeholder/Placeholder.module.scss"],"names":[],"mappings":"AAAA,8BACI,YAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,gBAAA,CAEA,yDACI,aAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CACA,eAAA,CAGJ,6DACI,aAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CACA,iBAAA,CACA,eAAA,CAEA,iEACI,iBAAA,CACA,QAAA,CACA,UAAA","sourcesContent":[".placeholder {\n    height: 700px;\n    width: 617px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding-top: 54px;\n\n    .subtitle {\n        color: #697077;\n        font-size: 14px;\n        font-style: normal;\n        font-weight: 400;\n        line-height: 20px;\n        margin-top: 16px;\n    }\n\n    .callToAction {\n        color: #21272A;\n        font-size: 16px;\n        font-style: normal;\n        font-weight: 400;\n        line-height: 24px;\n        position: relative;\n        margin-top: 24px;\n\n        svg {\n            position: absolute;\n            bottom: 0;\n            right: 100%;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"placeholder": `Placeholder__placeholder-v5h`,
	"subtitle": `Placeholder__subtitle-x52`,
	"callToAction": `Placeholder__callToAction-qFM`
};
export default ___CSS_LOADER_EXPORT___;
