// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CurrencySelect__currencySelect-w9N{width:100%;display:grid;gap:8px;grid-template-columns:160px 1fr}.CurrencySelect__currencySelect-w9N .CurrencySelect__scrollBox-y8A{display:flex;gap:8px}.CurrencySelect__shortCurrencySelect-kPA{display:flex;gap:8px}.CurrencySelect__notFoundMessage-_wy{line-height:34px;margin-left:16px}`, "",{"version":3,"sources":["webpack://./src/modules/PaymentSystems/Tariffs/components/CurrencySelect/CurrencySelect.module.scss"],"names":[],"mappings":"AAAA,oCACI,UAAA,CACA,YAAA,CACA,OAAA,CACA,+BAAA,CAEA,mEACI,YAAA,CACA,OAAA,CAIR,yCACI,YAAA,CACA,OAAA,CAGJ,qCACI,gBAAA,CACA,gBAAA","sourcesContent":[".currencySelect {\n    width: 100%;\n    display: grid;\n    gap: 8px;\n    grid-template-columns: 160px 1fr;\n\n    .scrollBox {\n        display: flex;\n        gap: 8px;\n    }\n}\n\n.shortCurrencySelect {\n    display: flex;\n    gap: 8px;\n}\n\n.notFoundMessage {\n    line-height: 34px;\n    margin-left: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"currencySelect": `CurrencySelect__currencySelect-w9N`,
	"scrollBox": `CurrencySelect__scrollBox-y8A`,
	"shortCurrencySelect": `CurrencySelect__shortCurrencySelect-kPA`,
	"notFoundMessage": `CurrencySelect__notFoundMessage-_wy`
};
export default ___CSS_LOADER_EXPORT___;
