// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PageTitle__PageTitle-AF_{font-size:20px;line-height:32px;padding-bottom:8px;font-weight:400;margin-bottom:25px}`, "",{"version":3,"sources":["webpack://./src/components/PageTitle/PageTitle.module.scss"],"names":[],"mappings":"AAAA,0BACE,cAAA,CACA,gBAAA,CACA,kBAAA,CACA,eAAA,CACA,kBAAA","sourcesContent":[".PageTitle {\n  font-size: 20px;\n  line-height: 32px;\n  padding-bottom: 8px;\n  font-weight: 400;\n  margin-bottom: 25px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PageTitle": `PageTitle__PageTitle-AF_`
};
export default ___CSS_LOADER_EXPORT___;
