import { t } from "@paykassma/pay-kit";
import API from "api";
import { ICreateManualRequestData, IPossibleTransaction } from "api/transactionGroup";
import { useState } from "react";
import { errorsMap } from "utils/enums";

import { FormStateType } from "../index";

type ReturnType = {
	readonly create: (data: FormStateType, foundPossibleTransactions: readonly IPossibleTransaction[]) => void;
	readonly isLoading: boolean;
};

type HookType = (props: {
	readonly onSuccess: () => void;
	readonly onError: (errors: Record<string, string>) => void;
}) => ReturnType;

const useCreateManualTransaction: HookType = ({ onSuccess, onError }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const create = async (data: FormStateType, foundPossibleTransactions: readonly IPossibleTransaction[]) => {
		setIsLoading(true);
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { currentStep, selectedPossibleTrId, ...formData } = data;

		// if possible tr. exists, remove it first
		if (selectedPossibleTrId === undefined && foundPossibleTransactions.length > 0) {
			try {
				await Promise.all(foundPossibleTransactions.map(({ primary_id }) => API.transaction.removePossible({ primary_id })));
			} catch (err) {
				window.pushAlert({
					description: t("Could not remove existing possible transactions"),
					type: "error",
				});
			}
		}

		// if possible tr choosen
		if (selectedPossibleTrId !== undefined) {
			try {
				await Promise.all(foundPossibleTransactions.map(({ primary_id }) => {
					if (primary_id === selectedPossibleTrId) {
						return API.transaction.changePossibleStatus(primary_id);
					} else {
						return API.transaction.removePossible({ primary_id });
					}
				}));
			} catch (err) {
				window.pushAlert({
					description: t("Could not change status of existing possible transactions"),
					type: "error",
				});
			}
		}

		return API.transaction
			.createManual(formData as ICreateManualRequestData)
			.then((res: any) => {
				if (res.status === "success") {
					onSuccess();
					return res;
				} else {
					console.error(res);

					if (res.error_message) {
						window.pushAlert({
							description: res.error_message,
							type: "error",
						});

						if (res.errors) {
							const errors = {} as Record<string, string>;

							Object.keys(res.errors).forEach((fieldName: string) => {
								const errorValue = res.errors[fieldName];

								if (Array.isArray(res.errors[fieldName])) {
									errors[fieldName] = errorValue.join("\n") as string;
								}
							});

							onError(errors);
						}
					}

					// throw new Error("Unexpected error in useCreateManualTransaction");
					throw new Error(errorsMap.anyResponse);
				}
			})
			.finally(() => setIsLoading(false))
			.catch((err) => console.error(err));
	};

	return {
		isLoading,
		create,
	};
};

export default useCreateManualTransaction;
