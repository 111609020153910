// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WalletAmountColTitle__walletAmountColTitle-pkT{display:flex;align-items:center;gap:10px}.WalletAmountColTitle__walletAmountColTitle-pkT .WalletAmountColTitle__UpAndDownButtons-CPx{color:#697077}`, "",{"version":3,"sources":["webpack://./src/modules/Proxies/components/WalletAmountColTitle/walletAmountColTitle.module.scss"],"names":[],"mappings":"AAAA,gDACC,YAAA,CACA,kBAAA,CACA,QAAA,CAEA,4FACC,aAAA","sourcesContent":[".walletAmountColTitle {\n\tdisplay: flex;\n\talign-items: center;\n\tgap: 10px;\n\n\t.UpAndDownButtons {\n\t\tcolor: #697077;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"walletAmountColTitle": `WalletAmountColTitle__walletAmountColTitle-pkT`,
	"UpAndDownButtons": `WalletAmountColTitle__UpAndDownButtons-CPx`
};
export default ___CSS_LOADER_EXPORT___;
