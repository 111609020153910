import balanceGroup from "api/balanceGroup";
import changeLogGroup from "api/changeLogGroup";
import commissionGroup from "api/commissionGroup";
import cookiesGroup from "api/cookiesGroup";
import dashboardGroup from "api/dashboardGroup";
import postbackGroup from "api/postbackGroup";
import proxiesGroup from "api/proxiesGroup";
import { proxyGroup } from "api/proxyGroup";
import reportsGroup from "api/reportsGroup";
import stockpilingsGroup from "api/stockpilingsGroup";
import transactionGroup from "api/transactionGroup";
import walletsDebuggingGroup from "api/walletsDebuggingGroup";
import walletsGroup from "api/walletsGroup";
import walletTypesGroup from "api/walletTypesGroup";
import walletTypeToCurrencyGroup from "api/walletTypeToCurrencyGroup";

import authGroup from "./authGroup";
import countryGroup from "./countryGroup";
import currencyGroup from "./currencyGroup";
import { fetchOldApi } from "./fetch";
import paymentSystemGroup from "./paymentSystemGroup";
import settingsGroup from "./settingsGroup";
import translationsGroup from "./translationsGroup";
import walletGroup from "./walletGroup";
import withdrawalGroup from "./withdrawalGroup";

export type { UpdatePasswordProps, UpdateSettingsType } from "./settingsGroup";

const API = {
	fetch: fetchOldApi,
	auth: authGroup,
	paymentSystem: paymentSystemGroup,
	postback: postbackGroup,
	transaction: transactionGroup,
	country: countryGroup,
	settings: settingsGroup,
	wallet: walletGroup,
	walletType: walletTypesGroup,
	currency: currencyGroup,
	stockpiling: stockpilingsGroup,
	balances: balanceGroup,
	withdrawal: withdrawalGroup,
	proxy: proxyGroup,
	translation: translationsGroup,
	proxies: proxiesGroup,
	walletsDebugging: walletsDebuggingGroup,
	changeLog: changeLogGroup,
	dashboard: dashboardGroup,
	wallets: walletsGroup,
	commission: commissionGroup,
	reports: reportsGroup,
	walletTypeToCurrency: walletTypeToCurrencyGroup,
	cookies: cookiesGroup,
};

export default API;
