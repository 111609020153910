// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StockpilingsActions__stockPilingsActions-Hii{display:flex;gap:8px}.StockpilingsActions__transactionsModal-XCZ{max-width:1240px}`, "",{"version":3,"sources":["webpack://./src/modules/Stockpilings/components/StockpilingsActions/stockpilingsActions.module.scss"],"names":[],"mappings":"AAAA,8CACC,YAAA,CACA,OAAA,CAGD,4CACC,gBAAA","sourcesContent":[".stockPilingsActions {\n\tdisplay: flex;\n\tgap: 8px;\n}\n\n.transactionsModal {\n\tmax-width: 1240px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stockPilingsActions": `StockpilingsActions__stockPilingsActions-Hii`,
	"transactionsModal": `StockpilingsActions__transactionsModal-XCZ`
};
export default ___CSS_LOADER_EXPORT___;
