import CopyTextButton from "components/CopyTextButton";
import { Tooltip } from "pay-kit";
import React from "react";

import styles from "./TextWithCopyButton.module.scss";

interface ITextWithCopyButtonProps {
	readonly text: string | number | null | undefined;
	readonly className?: string;
	readonly textClassName?: string;
	readonly hideCopyButton?: boolean;
}

const TextWithCopyButton: React.FC<ITextWithCopyButtonProps> = ({ text, hideCopyButton, className, textClassName }) => {
	if (!text && text !== 0) {
		return null;
	}

	const classNames = [styles.TextWithCopyButton, className];

	return (
		<div className={classNames.join(" ")}>
			<Tooltip tip={text} preferredSide={"bottom"}>
				<div className={[styles.text, textClassName].join(" ")}>{text}</div>
			</Tooltip>
			{!hideCopyButton && <CopyTextButton text={text} data-test-id="useless-data-test-id" />}
		</div>
	);
};

export default TextWithCopyButton;
