import PageTitle from "components/PageTitle";
import ChangeLog from "modules/ChangeLog";
import { useTranslation } from "pay-kit";

const ChangeLogPage = () => {
	const {t} = useTranslation();

	return (
		<>
			<PageTitle title={t("Change Log")} />
			<ChangeLog />
		</>
	);
};

export default ChangeLogPage;