import ManipulateActions from "components/ManipulateActions";
import { useProxyContext } from "modules/Proxies/contexts/ProxyContext";
import { FC } from "react";

import styles from "./proxyActions.module.scss";

const ProxyActions: FC<ProxyActionsType> = ({ id }) => {

	const {
		loadProxyItem,
		checkProxy,
		proxyCheckingState,
		setIDsForDelete,
		setIsDeleteProxiesModal,
		setIsUpdateProxyModal,
		setProxyID
	} = useProxyContext();

	const OnDelete = () => {
		setIDsForDelete(({ proxies: [id] }));
		setIsDeleteProxiesModal(true);
	};

	const OnEdit = () => {
		setIsUpdateProxyModal(true);
		loadProxyItem(id);
		setProxyID(id);
	};

	const OnRelaunch = () => checkProxy(id);

	return (
		<ManipulateActions
			className={styles.actionsCol}
			onEdit={OnEdit}
			onRelaunch={OnRelaunch}
			onDelete={OnDelete}
			relaunchIconType={proxyCheckingState && proxyCheckingState[id]?.workingStatus}
			isRelaunchLoading={proxyCheckingState && proxyCheckingState[id]?.isLoading}
		/>
	);
};

export default ProxyActions;

type ProxyActionsType = {
	readonly id: string;
	readonly workingStatus?: "WORKING" | "NOT_WORKING"
}
