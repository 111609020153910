import React from "react";

type IconType = React.FC<{ readonly className?: string }>;

export const ErrorIcon: IconType = (props) => (
	<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5ZM15.4462 8.39705L15.5303 8.46967C15.7966 8.73594 15.8208 9.1526 15.6029 9.44621L15.5303 9.53033L13.061 12L15.5303 14.4697C15.7966 14.7359 15.8208 15.1526 15.6029 15.4462L15.5303 15.5303C15.2641 15.7966 14.8474 15.8208 14.5538 15.6029L14.4697 15.5303L12 13.061L9.53033 15.5303C9.26406 15.7966 8.8474 15.8208 8.55379 15.6029L8.46967 15.5303C8.2034 15.2641 8.1792 14.8474 8.39705 14.5538L8.46967 14.4697L10.939 12L8.46967 9.53033C8.2034 9.26406 8.1792 8.8474 8.39705 8.55379L8.46967 8.46967C8.73594 8.2034 9.1526 8.1792 9.44621 8.39705L9.53033 8.46967L12 10.939L14.4697 8.46967C14.7359 8.2034 15.1526 8.1792 15.4462 8.39705Z"
			fill="#DC3545"
		/>
	</svg>
);

export const InfoIcon: IconType = (props) => (
	<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M11.9996 1.99902C17.5233 1.99902 22.0011 6.47687 22.0011 12.0006C22.0011 17.5243 17.5233 22.0021 11.9996 22.0021C6.47589 22.0021 1.99805 17.5243 1.99805 12.0006C1.99805 6.47687 6.47589 1.99902 11.9996 1.99902ZM11.9996 3.49902C7.30432 3.49902 3.49805 7.3053 3.49805 12.0006C3.49805 16.6959 7.30432 20.5021 11.9996 20.5021C16.6949 20.5021 20.5011 16.6959 20.5011 12.0006C20.5011 7.3053 16.6949 3.49902 11.9996 3.49902ZM11.996 10.4996C12.3757 10.4994 12.6896 10.7813 12.7395 11.1473L12.7464 11.2491L12.75 16.7507C12.7503 17.1649 12.4147 17.5009 12.0005 17.5012C11.6208 17.5014 11.3069 17.2195 11.257 16.8535L11.25 16.7517L11.2464 11.2501C11.2462 10.8359 11.5817 10.4999 11.996 10.4996ZM12 7.00184C12.5516 7.00184 12.9987 7.44896 12.9987 8.0005C12.9987 8.55205 12.5516 8.99917 12 8.99917C11.4485 8.99917 11.0014 8.55205 11.0014 8.0005C11.0014 7.44896 11.4485 7.00184 12 7.00184Z"
			fill="#0072C3"
		/>
	</svg>
);

export const SuccessIcon: IconType = (props) => (
	<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5ZM10.75 13.4393L15.2197 8.96967C15.5126 8.67678 15.9874 8.67678 16.2803 8.96967C16.5466 9.23594 16.5708 9.6526 16.3529 9.94621L16.2803 10.0303L11.2803 15.0303C11.0141 15.2966 10.5974 15.3208 10.3038 15.1029L10.2197 15.0303L7.71967 12.5303C7.42678 12.2374 7.42678 11.7626 7.71967 11.4697C7.98594 11.2034 8.4026 11.1792 8.69621 11.3971L8.78033 11.4697L10.75 13.4393L15.2197 8.96967L10.75 13.4393Z"
			fill="#24A148"
		/>
	</svg>
);

export const WarningIcon: IconType = (props) => (
	<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M10.9092 2.78216C11.949 2.20625 13.247 2.54089 13.8848 3.5224L13.9676 3.66023L21.7266 17.6685C21.9114 18.0021 22.0084 18.3773 22.0084 18.7587C22.0084 19.9495 21.0832 20.9243 19.9124 21.0035L19.7584 21.0087H4.2426C3.86129 21.0087 3.48623 20.9118 3.15265 20.7271C2.11281 20.1513 1.70777 18.8734 2.20133 17.812L2.27422 17.6687L10.031 3.66046C10.2355 3.2911 10.5399 2.98674 10.9092 2.78216ZM20.4144 18.3953L12.6555 4.38702C12.4548 4.02467 11.9983 3.89363 11.636 4.09433C11.5437 4.14547 11.4635 4.21533 11.4005 4.299L11.3433 4.3871L3.58647 18.3954C3.38582 18.7577 3.51692 19.2141 3.87928 19.4148C3.96268 19.461 4.05385 19.4907 4.14785 19.5027L4.2426 19.5087H19.7584C20.1726 19.5087 20.5084 19.1729 20.5084 18.7587C20.5084 18.6633 20.4902 18.5691 20.4551 18.4811L20.4144 18.3953L12.6555 4.38702L20.4144 18.3953ZM12.0003 16.0018C12.5518 16.0018 12.9989 16.4489 12.9989 17.0004C12.9989 17.552 12.5518 17.9991 12.0003 17.9991C11.4487 17.9991 11.0016 17.552 11.0016 17.0004C11.0016 16.4489 11.4487 16.0018 12.0003 16.0018ZM11.9961 8.49955C12.3758 8.49925 12.6898 8.78115 12.7397 9.14718L12.7467 9.24895L12.7503 13.7505C12.7506 14.1648 12.4151 14.5008 12.0009 14.5011C11.6212 14.5014 11.3072 14.2195 11.2572 13.8535L11.2503 13.7517L11.2467 9.25015C11.2463 8.83594 11.5819 8.49988 11.9961 8.49955Z"
			fill="#E8882E"
		/>
	</svg>
);

export const CloseIcon: IconType = (props) => (
	<svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<rect
			x="12.9492"
			y="3.06982"
			width="1"
			height="15"
			rx="0.5"
			transform="rotate(45 12.9492 3.06982)"
			fill="currentColor"
		/>
		<rect
			x="13.6562"
			y="13.6763"
			width="1"
			height="15"
			rx="0.5"
			transform="rotate(135 13.6562 13.6763)"
			fill="currentColor"
		/>
	</svg>
);
