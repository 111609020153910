import { useTranslation } from "pay-kit";
import { Tooltip } from "pay-kit";

import styles from "./HelpToolTip.module.scss";

const HelpToolTip = () => {
	const { t } = useTranslation();

	return (
		<Tooltip
			tip={
				<div className={styles.helpToolTipBody}>
					<h4>{t("Confirmed")}.</h4>
					<p>
						{/* Выберите данный тип транзакции, если денежные средства поступили на счет, но транзакции по какой-то причине */}
						{/* нет в системе. */}
						{t(
							"Choose this transaction type if money was deposited on your account but there are no transactions in the	system."
						)}
					</p>

					<h4>{t("Debug")}.</h4>
					{/* <p>Выберите данный тип транзакции, если необходимо провести тестирование или демо-показ.</p> */}
					<p>{t("Choose this transaction type if you need to test or demo.")}</p>
				</div>
			}
		>
			<svg
				className={styles.helpToolTipIcon}
				width="16"
				height="16"
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2ZM8 3C5.23858 3 3 5.23858 3 8C3 10.7614 5.23858 13 8 13C10.7614 13 13 10.7614 13 8C13 5.23858 10.7614 3 8 3ZM8 10.5C8.41421 10.5 8.75 10.8358 8.75 11.25C8.75 11.6642 8.41421 12 8 12C7.58579 12 7.25 11.6642 7.25 11.25C7.25 10.8358 7.58579 10.5 8 10.5ZM8 4.5C9.10457 4.5 10 5.39543 10 6.5C10 7.23053 9.78822 7.63969 9.24605 8.20791L8.98196 8.47745C8.60451 8.87102 8.5 9.0831 8.5 9.5C8.5 9.77614 8.27614 10 8 10C7.72386 10 7.5 9.77614 7.5 9.5C7.5 8.76947 7.71178 8.36031 8.25395 7.79209L8.51804 7.52255C8.89549 7.12898 9 6.9169 9 6.5C9 5.94772 8.55228 5.5 8 5.5C7.44772 5.5 7 5.94772 7 6.5C7 6.77614 6.77614 7 6.5 7C6.22386 7 6 6.77614 6 6.5C6 5.39543 6.89543 4.5 8 4.5Z"
					fill="#A2A9B0"
				/>
			</svg>
		</Tooltip>
	);
};

export default HelpToolTip;
