// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PluginTheme__pluginThemeWrapper-RTF{display:grid;grid-template-columns:auto auto}.PluginTheme__pluginThemeWrapper-RTF .PluginTheme__selectWrapper-f1H{padding:24px}.PluginTheme__pluginThemeWrapper-RTF .PluginTheme__selectWrapper-f1H .PluginTheme__selectContainer-P1_{display:flex;align-items:flex-start;gap:16px}.PluginTheme__pluginThemeWrapper-RTF .PluginTheme__selectWrapper-f1H .PluginTheme__selectContainer-P1_ .PluginTheme__select-xFM{width:100%;max-width:264px}.PluginTheme__pluginThemeWrapper-RTF .PluginTheme__selectWrapper-f1H .PluginTheme__saveButton-AfB{gap:4px}.PluginTheme__label-Bw3{width:100%;max-width:214px;color:#697077;font-size:14px;font-weight:400;padding-top:6px}`, "",{"version":3,"sources":["webpack://./src/modules/AppSettings/Plugin/components/PluginTheme/pluginTheme.module.scss"],"names":[],"mappings":"AAAA,qCACC,YAAA,CACA,+BAAA,CAEA,qEACC,YAAA,CAEA,uGACC,YAAA,CACA,sBAAA,CACA,QAAA,CAEA,gIACC,UAAA,CACA,eAAA,CAIF,kGACC,OAAA,CAKH,wBACC,UAAA,CACA,eAAA,CACA,aAAA,CACA,cAAA,CACA,eAAA,CACA,eAAA","sourcesContent":[".pluginThemeWrapper {\n\tdisplay: grid;\n\tgrid-template-columns: auto auto;\n\n\t.selectWrapper {\n\t\tpadding: 24px;\n\n\t\t.selectContainer {\n\t\t\tdisplay: flex;\n\t\t\talign-items: flex-start;\n\t\t\tgap: 16px;\n\n\t\t\t.select {\n\t\t\t\twidth: 100%;\n\t\t\t\tmax-width: 264px;\n\t\t\t}\n\t\t}\n\n\t\t.saveButton {\n\t\t\tgap: 4px;\n\t\t}\n\t}\n}\n\n.label {\n\twidth: 100%;\n\tmax-width: 214px;\n\tcolor: #697077;\n\tfont-size: 14px;\n\tfont-weight: 400;\n\tpadding-top: 6px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pluginThemeWrapper": `PluginTheme__pluginThemeWrapper-RTF`,
	"selectWrapper": `PluginTheme__selectWrapper-f1H`,
	"selectContainer": `PluginTheme__selectContainer-P1_`,
	"select": `PluginTheme__select-xFM`,
	"saveButton": `PluginTheme__saveButton-AfB`,
	"label": `PluginTheme__label-Bw3`
};
export default ___CSS_LOADER_EXPORT___;
