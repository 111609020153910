import { fetchApi, fetchOldApi } from "./fetch";

const withdrawalGroup = {
	getList: (queryParams: IWithdrawalsFilterForm & IWithdrawalsPaginatorParams) => fetchOldApi("/withdrawal/list", queryParams, "GET"),
	getOne: (id: IWithdrawalListItem["id"]) => fetchApi(`/withdrawal/${id}`, undefined, "GET"),
	setComment: (id: IWithdrawalListItem["id"], comment: IWithdrawalListItem["comment"]) => fetchOldApi(`/withdrawal/set-comment/${id}`, { comment }, "POST"),
	reject: (id: IWithdrawalListItem["id"], comment: IWithdrawalListItem["comment"]) => fetchOldApi(`/withdrawal/reject/${id}`, { comment }, "POST"),
	approve: (id: IWithdrawalListItem["id"], comment: string, wallet_sender: string) => fetchOldApi(`/withdrawal/approve/${id}`, { comment, wallet_sender }, "POST"),
	getWalletsSender: (id: IWithdrawalListItem["id"]) => fetchOldApi(`/withdrawal/wallet-sender/${id}`, undefined, "GET"),
	create: (formData: ICreateWithdrawalData) => fetchOldApi(`/v2/withdrawal/create`, formData, "POST"),
	getReport: (reqParams: getReportReqParams) => fetchApi(`/withdrawal/report`, reqParams, "POST", {}, { responseType: "blob", withToken: true }),
	importReport: (formData: FormData) => fetchOldApi(`/withdrawal/import-report`, formData, "POST", { "Content-Type": "multipart/form-data" }),
	getStatuses: () => fetchOldApi(`/withdrawal/status`, undefined, "GET"),
};

export interface getReportReqParams {
	readonly date_from: string;
	readonly date_to: string;
	readonly payment_system?: string;
	readonly status?: WithdrawalStatusENUM;
}

export enum WithdrawalType {
	AUTO = 0,
	MANUAL = 1,
}

export enum WithdrawalStatusENUM {
	NEW = 0, // Новая
	COMPLETED = 1, // Завершена
	CANCELLED = 2, // Отменена
	PENDING = 3, // В обработке
	TO_CHECK = 4, // Проверка
	REJECTED = 5, // Отклонена
	ADDING_A_BENEFICIARY = 6, // Добавление бенефициара
	ERROR = 8, // Ошибка вывода
	APPROVING = 9, // Ждет подтверждения
}

export interface IStatusListItem {
	readonly status: WithdrawalStatusENUM;
	readonly name: string;
}

export enum WithdrawPostbackStatusENUM {
	ERROR = 0, // "Ошибка"
	SUCCESS = 1, // "Отправлен"
}

export interface IWithdrawalCommonFields {
	readonly id: number;
	readonly withdrawal_id: string;
	readonly label: string;
	readonly wallet_type: string;
	readonly amount: number;
	readonly currency_code: string;
	readonly status: WithdrawalStatusENUM;
	readonly type: WithdrawalType;
	readonly wallet_sender: string | null;
	readonly wallet_recipient: string;
	readonly is_manually: boolean;
	readonly is_test: boolean;
	readonly comment: string;
	readonly postback: number | null;
	readonly created_at: string;
}

export interface IWithdrawalListItem extends IWithdrawalCommonFields {
	readonly wallet_sender_identifier: string;
	readonly postback_id: number | null;
	readonly postback_status: WithdrawPostbackStatusENUM;
}

export interface IWithdrawalDetails extends IWithdrawalCommonFields {
	readonly wallet_recipient_name: string;
	readonly updated_at: string;
}

export interface IWithdrawalsFilterForm {
	readonly date_from?: string;
	readonly date_to?: string;
	readonly is_manually?: 0 | 1;
	readonly label?: string;
	readonly postback_status?: "0" | "1";
	readonly status?: WithdrawalStatusENUM;
	readonly type?: WithdrawalType;
	readonly wallet_recipient?: string;
	readonly wallet_sender?: string;
	readonly wallet_sender_identifier?: string;
	readonly wallet_type?: string;
	readonly withdrawal_id?: number;
}

export interface IWithdrawalsPaginatorParams {
	readonly limit?: number;
	readonly page?: number;
}

export interface ICreateWithdrawalData {
	readonly payment_system: string;
	readonly label: string;
	readonly amount: number;
	readonly currency_code: string;
	readonly account_number: string;
	readonly iban?: string;

	readonly comment?: string;
	readonly withdrawal_id?: string;
	readonly account_name?: string;
	readonly account_email?: string;
	readonly payments_details?: {
		readonly payments_method?: string;
		readonly payments_provider?: string;
	};
	readonly bank_details?: {
		readonly bank_code?: string;
		readonly branch_code?: string;
		readonly bank_code_in_payments_system?: string;
	};
	readonly type: 1;
}

export default withdrawalGroup;
