// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotFoundPage__pageContainer-dri{display:flex;flex-direction:column;align-items:center;justify-content:center;width:100%;height:100%}.NotFoundPage__pageContainer-dri .NotFoundPage__iconWrapper-rGb{display:flex;flex-direction:column;align-items:center}.NotFoundPage__pageContainer-dri .NotFoundPage__iconWrapper-rGb .NotFoundPage__errorCode-btB{margin-top:8px;font-size:16px}.NotFoundPage__pageContainer-dri .NotFoundPage__pageTitle-PvG,.NotFoundPage__pageContainer-dri .NotFoundPage__pageDescription-hpC{font-size:16px;margin-bottom:8px}`, "",{"version":3,"sources":["webpack://./src/layout/pages/NotFoundPage/NotFoundPage.module.scss"],"names":[],"mappings":"AAAA,iCACI,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,WAAA,CAEA,gEACI,YAAA,CACA,qBAAA,CACA,kBAAA,CAEA,6FACI,cAAA,CACA,cAAA,CAIR,kIACI,cAAA,CACA,iBAAA","sourcesContent":[".pageContainer {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n    height: 100%;\n\n    .iconWrapper {\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n    \n        .errorCode {\n            margin-top: 8px;\n            font-size: 16px;\n        }\n    }\n\n    .pageTitle, .pageDescription {\n        font-size: 16px;\n        margin-bottom: 8px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContainer": `NotFoundPage__pageContainer-dri`,
	"iconWrapper": `NotFoundPage__iconWrapper-rGb`,
	"errorCode": `NotFoundPage__errorCode-btB`,
	"pageTitle": `NotFoundPage__pageTitle-PvG`,
	"pageDescription": `NotFoundPage__pageDescription-hpC`
};
export default ___CSS_LOADER_EXPORT___;
