// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.columns__accountNumberWrapper-H5E{display:flex;gap:4px}.columns__accountNumberWrapper-H5E .columns__account_number-uit{border-bottom:1px dashed #697077;overflow:hidden;text-overflow:ellipsis;width:fit-content;max-width:100%;cursor:pointer}.columns__account_login-Re7{color:#009d9a;background-color:#e9fbfb;border-radius:6px;padding:0 4px;overflow:hidden;text-overflow:ellipsis;width:fit-content;max-width:100%}.columns__comment-oU4{display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:2;overflow:hidden}.columns__subRow-V4X{display:flex;justify-content:flex-start;align-items:center;gap:8px}`, "",{"version":3,"sources":["webpack://./src/modules/Wallets/ArchivedWallets/components/columns/Columns.module.scss"],"names":[],"mappings":"AAAA,mCACC,YAAA,CACA,OAAA,CAEA,gEACC,gCAAA,CACA,eAAA,CACA,sBAAA,CACA,iBAAA,CACA,cAAA,CACA,cAAA,CAIF,4BACC,aAAA,CACA,wBAAA,CACA,iBAAA,CACA,aAAA,CACA,eAAA,CACA,sBAAA,CACA,iBAAA,CACA,cAAA,CAGD,sBACC,mBAAA,CACA,2BAAA,CACA,oBAAA,CACA,eAAA,CAED,qBACC,YAAA,CACA,0BAAA,CACA,kBAAA,CACA,OAAA","sourcesContent":[".accountNumberWrapper {\n\tdisplay: flex;\n\tgap: 4px;\n\n\t.account_number {\n\t\tborder-bottom: 1px dashed #697077;\n\t\toverflow: hidden;\n\t\ttext-overflow: ellipsis;\n\t\twidth: fit-content;\n\t\tmax-width: 100%;\n\t\tcursor: pointer;\n\t}\n}\n\n.account_login {\n\tcolor: #009D9A;\n\tbackground-color: #E9FBFB;\n\tborder-radius: 6px;\n\tpadding: 0 4px;\n\toverflow: hidden;\n\ttext-overflow: ellipsis;\n\twidth: fit-content;\n\tmax-width: 100%;\n}\n\n.comment {\n\tdisplay: -webkit-box;\n\t-webkit-box-orient: vertical;\n\t-webkit-line-clamp: 2;\n\toverflow: hidden;\n}\n.subRow {\n\tdisplay: flex;\n\tjustify-content: flex-start;\n\talign-items: center;\n\tgap: 8px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accountNumberWrapper": `columns__accountNumberWrapper-H5E`,
	"account_number": `columns__account_number-uit`,
	"account_login": `columns__account_login-Re7`,
	"comment": `columns__comment-oU4`,
	"subRow": `columns__subRow-V4X`
};
export default ___CSS_LOADER_EXPORT___;
