// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StatisticWallets__point-txH{font-size:14px;font-weight:400;color:#dde1e6}.StatisticWallets__visibleWorkingAll-mzl{width:200px}.StatisticWallets__balanceOfVisibleMax-L__{width:168px}.StatisticWallets__turnoverDailyTurnover-at1{width:168px}.StatisticWallets__btn-aT8{border:none;padding:0;background:none;cursor:pointer}.StatisticWallets__arrayItem-O4E{display:flex;flex-direction:column;gap:8px;padding:8px}.StatisticWallets__arrayItem-O4E:not(:last-child){border-bottom:1px solid #eaecf0}.StatisticWallets__secondLabel-YOq{color:#697077}.StatisticWallets__target-dJX{height:20px}`, "",{"version":3,"sources":["webpack://./src/modules/Wallets/StatisticWallets/StatisticWallets.module.scss"],"names":[],"mappings":"AAAA,6BACC,cAAA,CACA,eAAA,CACA,aAAA,CAGD,yCACC,WAAA,CAGD,2CACC,WAAA,CAGD,6CACC,WAAA,CAGD,2BACC,WAAA,CACA,SAAA,CACA,eAAA,CACA,cAAA,CAGD,iCACC,YAAA,CACA,qBAAA,CACA,OAAA,CACA,WAAA,CACA,kDACC,+BAAA,CAIF,mCACC,aAAA,CAGD,8BACC,WAAA","sourcesContent":[".point {\n\tfont-size: 14px;\n\tfont-weight: 400;\n\tcolor: #dde1e6;\n}\n\n.visibleWorkingAll {\n\twidth: 200px;\n}\n\n.balanceOfVisibleMax {\n\twidth: 168px;\n}\n\n.turnoverDailyTurnover {\n\twidth: 168px;\n}\n\n.btn {\n\tborder: none;\n\tpadding: 0;\n\tbackground: none;\n\tcursor: pointer;\n}\n\n.arrayItem {\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 8px;\n\tpadding: 8px;\n\t&:not(:last-child) {\n\t\tborder-bottom: 1px solid #EAECF0;\n\t}\n}\n\n.secondLabel {\n\tcolor: #697077;\n}\n\n.target {\n\theight: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"point": `StatisticWallets__point-txH`,
	"visibleWorkingAll": `StatisticWallets__visibleWorkingAll-mzl`,
	"balanceOfVisibleMax": `StatisticWallets__balanceOfVisibleMax-L__`,
	"turnoverDailyTurnover": `StatisticWallets__turnoverDailyTurnover-at1`,
	"btn": `StatisticWallets__btn-aT8`,
	"arrayItem": `StatisticWallets__arrayItem-O4E`,
	"secondLabel": `StatisticWallets__secondLabel-YOq`,
	"target": `StatisticWallets__target-dJX`
};
export default ___CSS_LOADER_EXPORT___;
