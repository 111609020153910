// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WithdrawalsRequestPage__statusFilterWrapper-gyz{margin-top:36px}.WithdrawalsRequestPage__statusFilterWrapper-gyz .WithdrawalsRequestPage__statusSelectWrapper-Wxz{min-width:180px}.WithdrawalsRequestPage__statusFilterWrapper-gyz .WithdrawalsRequestPage__dateWrapper-MRO>input{color:#21272a}.WithdrawalsRequestPage__reloadButtonWrapper-dOJ{margin-top:46px}.WithdrawalsRequestPage__tableWrapper-BEE{margin-top:24px;margin-bottom:35px}.WithdrawalsRequestPage__target-cTO{height:20px}`, "",{"version":3,"sources":["webpack://./src/modules/TransferHistory/components/WithdrawalsHistoryTabs/components/WithdrawalsRequestPage/withdrawalsRequestPage.module.scss"],"names":[],"mappings":"AAAA,iDACC,eAAA,CAEA,kGACC,eAAA,CAKA,gGACC,aAAA,CAKH,iDACC,eAAA,CAGD,0CACC,eAAA,CACA,kBAAA,CAGD,oCACC,WAAA","sourcesContent":[".statusFilterWrapper {\n\tmargin-top: 36px;\n\n\t.statusSelectWrapper {\n\t\tmin-width: 180px;\n\t}\n\n\t.dateWrapper {\n\n\t\t& > input {\n\t\t\tcolor: #21272A;\n\t\t}\n\t}\n}\n\n.reloadButtonWrapper {\n\tmargin-top: 46px;\n}\n\n.tableWrapper {\n\tmargin-top: 24px;\n\tmargin-bottom: 35px;\n}\n\n.target {\n\theight: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statusFilterWrapper": `WithdrawalsRequestPage__statusFilterWrapper-gyz`,
	"statusSelectWrapper": `WithdrawalsRequestPage__statusSelectWrapper-Wxz`,
	"dateWrapper": `WithdrawalsRequestPage__dateWrapper-MRO`,
	"reloadButtonWrapper": `WithdrawalsRequestPage__reloadButtonWrapper-dOJ`,
	"tableWrapper": `WithdrawalsRequestPage__tableWrapper-BEE`,
	"target": `WithdrawalsRequestPage__target-cTO`
};
export default ___CSS_LOADER_EXPORT___;
