export const screenPassword = (rawPass: string): string => {
    let cleanPass = rawPass;

    const screeningSymbols ={
        "&quot;": '"',
        "&amp;": "&",
        "lt;": "<",
        "&gt;": ">",
    }

    Object.entries(screeningSymbols).forEach(([key, value]) => {
        cleanPass = cleanPass.replaceAll(key, value);
    })
    return cleanPass;
}