import DisabledWallets from "modules/Wallets/DisabledWallets/DisabledWallets";
import { useTranslation } from "pay-kit";
import React, { useEffect } from "react";

const DisabledWalletsPage: React.FC = () => {
	const { t } = useTranslation();
	useEffect(() => {
		document.title = t("Disabled wallets");
	}, []);

	return <DisabledWallets />;
};

export default DisabledWalletsPage;
