// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Amount__amount-Jvd{font-weight:400;font-size:14px;color:#21272a}`, "",{"version":3,"sources":["webpack://./src/modules/TransferHistory/components/WithdrawalsHistoryTabs/components/Amount/amount.module.scss"],"names":[],"mappings":"AAAA,oBACC,eAAA,CACA,cAAA,CACA,aAAA","sourcesContent":[".amount {\n\tfont-weight: 400;\n\tfont-size: 14px;\n\tcolor: #21272A;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"amount": `Amount__amount-Jvd`
};
export default ___CSS_LOADER_EXPORT___;
