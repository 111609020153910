import CircleIcon from "components/Icons/CircleIcon";
import { useWalletTypesList } from "modules/Proxies/components/WalletTypes/hooks/useWalletTypesList";
import { WalletsAmountSideBarDataType } from "modules/Proxies/contexts/ProxyContext";
import { ScrollBox, t } from "pay-kit";
import { Loader } from "pay-kit";
import { FC, useEffect } from "react";
import useGetWallets from "utils/hooks/useGetWallets";

import styles from "./walletTypes.module.scss";

const statusStyle = {
	0: styles.notWorking,
	1: styles.working,
};

const WalletTypes: FC<WalletTypesType> = ({ walletsData }) => {
	const getWalletsAPI = useGetWallets();
	const walletTypesListHelperAPI = useWalletTypesList();

	useEffect(() => {
		walletTypesListHelperAPI.handle({
			data: walletsData,
			WalletList: getWalletsAPI.list,
		});
	}, [walletTypesListHelperAPI.handle, walletsData?.IDs.length, getWalletsAPI.list?.length]);

	useEffect(() => {
		getWalletsAPI.load({ removed: 0 });
	}, [getWalletsAPI.load]);

	const renderWalletTypes = () => {
		const list = walletTypesListHelperAPI.list?.map((wallet, i) => {
			const status = wallet.isActive ? 1 : 0;

			return (
				<div key={i} className={styles.walletTypeRow}>
					<div className={styles.walletType}>
						<CircleIcon className={statusStyle[status]} />
						<div className={styles.walletCode}>{wallet.code}</div>
					</div>
					<div className={styles.walletId}>{wallet.id}</div>
					<div className={styles.otherProxies}>{wallet.proxies?.length > 1 && t("Yes")}</div>
				</div>
			);
		});

		return (
			// <ScrollBox className={styles.scroller} scrollDirection="vertical">
			// <div className={styles.scroller}>{list}</div>
			list
			// </ScrollBox>
		);
	};

	const loader = (
		<div className={styles.loader}>
			<Loader color="#A2A9B0" />
		</div>
	);

	return (
		<div className={styles.walletTypesWrapper}>
			{getWalletsAPI.isLoading || walletTypesListHelperAPI.isLoading ? (
				loader
			) : (
				<div className={styles.walletTypesContainer}>
					<div className={styles.scroller}>
						<div className={styles.walletTypesHeaders}>
							<div className={styles.walletTypeHeader}>{t("Wallet type")}</div>
							<div className={styles.walletIdHeader}>{t(`Number`)}</div>
							<div className={styles.otherProxieHeader}>{t("Other proxies")}</div>
						</div>

						<div className={styles.walletTypes}>{renderWalletTypes()}</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default WalletTypes;

type WalletTypesType = {
	readonly walletsData: WalletsAmountSideBarDataType[`walletsData`];
};
