import { useTranslation } from "pay-kit";
import { FormTypesItemType, TranslationHintType, TranslationItemType } from "api/translationsGroup";
import { Button } from "pay-kit";
import React, { useState } from "react";

import ActionsProvider, { ActionsContext } from "../components/ActionsProvider";
import Skeleton from "../components/Skeleton";
import TranslationsEditor from "../components/TranslationsEditor";
import useUpdateHint from "../hooks/useUpdateHint";
import styles from "./Fields.module.scss";
import NavigationTree from "./NavigationTree";
import { prepareNavTreeData } from "./utils";

type HintsPropsType = {
	readonly walletType: string;
	readonly formTypes: readonly FormTypesItemType[];
	readonly languages: readonly string[];
	readonly getHintsAPI: {
		readonly load: (wallet_type: string, isDefault: boolean) => void;
		readonly list: readonly TranslationHintType[],
		readonly isLoading: boolean,
	};
}

const Hints: React.FC<HintsPropsType> = ({ walletType, formTypes, languages, getHintsAPI }) => {
	const {t} = useTranslation();
	const [selectedField, setSelectedField] = useState<string | undefined>(undefined);

	const updateHintAPI = useUpdateHint({
		onSuccess: () => {
			getHintsAPI.load(walletType, false);
			window.pushAlert({ type: "success", title: t("Translation successfully updated") });
		}, onError: (err) => {
			window.pushAlert({ type: "error", title: t("Translation update error") });
			console.error(err);
		}
	});

	const navTreeData = prepareNavTreeData(getHintsAPI.list, formTypes);
	const usedNames = navTreeData.map((field) => field.name);
	const selectedHint = selectedField ? getHintsAPI.list.find(hints => hints.uuid === selectedField) : undefined;
	const showSkeleton = getHintsAPI.isLoading && getHintsAPI.list.length < 1;

	const editHandler = (translations: readonly TranslationItemType[]) => {
		if (selectedField && selectedHint) {
			updateHintAPI.update(selectedField, {
				is_manual: selectedHint.is_manual,
				name: selectedHint.name,
				translations
			});
		}
	};

	return (
		<ActionsProvider refreshHandler={() => getHintsAPI.load(walletType, false)}>
			<div className={styles.translations}>
				<nav>
					<div className={styles.header}>
						<h3>{t("Name")}</h3>
						<ActionsContext.Consumer>{({ setAddNewModalData }) => (
							<Button
								variant="text-primary"
								onClick={() => setAddNewModalData({ usedNames, walletType, translationType: "hint" })}
								data-test-id="add-new-field-group"
							>
								{t("Add")}
							</Button>
						)}</ActionsContext.Consumer>
					</div>
					{showSkeleton && <Skeleton rows={30} />}
					<NavigationTree
						data={navTreeData}
						selectedField={selectedField}
						setSelectedField={setSelectedField}
						usedNames={usedNames}
					/>
				</nav>
				<aside>
					<div className={styles.header}>
						<h3>{t("Transfer")}</h3>
						<div className={styles.translationName}>{selectedHint?.name}</div>
					</div>
					{showSkeleton && <Skeleton rows={30} />}
					<TranslationsEditor
						languages={languages}
						initState={selectedHint?.translations || []}
						title={formTypes.find((ft) => ft.type === selectedHint?.form_type)?.title}
						isNotEditable={selectedHint?.is_default === true}
						onSubmit={editHandler}
						isLoading={getHintsAPI.isLoading}
						textareaRows={3}
						undeletableLangs={["en"]}
					/>
				</aside>
			</div>
		</ActionsProvider>
	);
};

export default Hints;
