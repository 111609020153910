import { Modal, useTranslation } from "pay-kit";
export interface IEditingModalProps {
	readonly psId?: string;
	readonly isOpen: boolean;
	readonly onClose: () => void;
	readonly onSuccess: () => void;
}

const EditingModal: React.FC<IEditingModalProps> = ({ isOpen, onClose, psId, onSuccess }) => {
	const {t} = useTranslation();
	return <Modal isOpen={isOpen} title={t("Payment system editting")} onClose={onClose}>
		BACKEND METHODS ARE NOT READY
	</Modal>;
};

export default EditingModal;
