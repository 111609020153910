import { IWithdrawalListItem } from "api/withdrawalGroup";
import React, { useState } from "react";

import ApproveModal from "./ApproveModal";
import CreateNewModal from "./CreateNewModal";
import DetailedView from "./DetailedView";
import DownloadReportModal from "./DownloadReportModal";
import ImportReportModal from "./ImportReportModal";
import RejectModal from "./RejectModal";

export const ActionsContext = React.createContext({
	showDetailsFor: (id: IWithdrawalListItem["id"]) => {
		console.log(id);
	},
	reject: (id: IWithdrawalListItem["id"]) => {
		console.log(id);
	},
	approve: (id: IWithdrawalListItem["id"]) => {
		console.log(id);
	},
	createNew: () => {
		return;
	},
	importReport: () => {
		return;
	},
	downloadReport: () => {
		return;
	},
});

type ActionsProviderPropsType = {
	readonly children: React.ReactNode;
	readonly refreshHandler: () => void;
	readonly withdrawals: readonly IWithdrawalListItem[];
};

const ActionsProvider = ({ children, withdrawals, refreshHandler }: ActionsProviderPropsType) => {
	const [detailedViewId, setDetailedViewId] = useState<IWithdrawalListItem["id"] | undefined>(undefined);
	const [approveId, setApproveId] = useState<IWithdrawalListItem["id"] | undefined>(undefined);
	const [rejectId, setRejectId] = useState<IWithdrawalListItem["id"] | undefined>(undefined);
	const [createNewModalIsVisible, setCreateNewModalVisibility] = useState<boolean>(false);
	const [downloadReportModalIsVisible, setDownloadReportModalIsVisible] = useState<boolean>(false);
	const [importReportModalIsVisible, setImportReportModalIsVisible] = useState<boolean>(false);

	return (
		<ActionsContext.Provider
			value={{
				showDetailsFor: setDetailedViewId,
				reject: setRejectId,
				approve: setApproveId,
				createNew: () => setCreateNewModalVisibility(true),
				importReport: () => setImportReportModalIsVisible(true),
				downloadReport: () => setDownloadReportModalIsVisible(true),
			}}
		>
			<DetailedView
				onClose={() => setDetailedViewId(undefined)}
				withdrawalData={getWithdrawalById(detailedViewId, withdrawals)}
				refreshHandler={refreshHandler}
			/>
			<ApproveModal id={approveId} onClose={() => setApproveId(undefined)} refreshHandler={refreshHandler} />
			<RejectModal id={rejectId} onClose={() => setRejectId(undefined)} refreshHandler={refreshHandler} />
			<CreateNewModal
				isOpen={createNewModalIsVisible}
				onClose={() => setCreateNewModalVisibility(false)}
				onSuccess={() => {
					setCreateNewModalVisibility(false);
					refreshHandler();
				}}
			/>
			<DownloadReportModal
				isOpen={downloadReportModalIsVisible}
				onClose={() => setDownloadReportModalIsVisible(false)}
			/>
			<ImportReportModal
				isOpen={importReportModalIsVisible}
				onClose={() => setImportReportModalIsVisible(false)}
				onSuccess={() => setImportReportModalIsVisible(false)}
			/>
			{children}
		</ActionsContext.Provider>
	);
};

export default ActionsProvider;

const getWithdrawalById = (id: IWithdrawalListItem["id"] | undefined, withdrawals: readonly IWithdrawalListItem[]) => {
	return id ? withdrawals.find((withdrawal) => withdrawal.id === id) : undefined;
};
