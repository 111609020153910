import API from "api";
import { WalletStatistic } from "api/walletsGroup";
import axios from "axios";
import { createContext, FC, PropsWithChildren, useEffect, useState } from "react";
import { errorsMap } from "utils/enums";

type Ctx = {
	readonly walletsStatistic: ReadonlyArray<WalletStatistic>;
	readonly isModalOpened: boolean;
	readonly handleModal: (flag: "close" | "open") => () => void;
	readonly checkedWalletType: WalletStatistic['wallet_type'] | null;
	readonly checkWalletType: (type: WalletStatistic['wallet_type']) => void;
	readonly isLoading: boolean;
	readonly fetchWalletsStatistic: () => void,
}

export const StatisticWalletsCtx = createContext<Ctx | undefined>(undefined);

StatisticWalletsCtx.displayName = "StatisticWalletsCtx";


export const StatisticWalletsCtxProvider: FC<PropsWithChildren> = ({ children }) => {
	const [walletsStatistic, setWalletsStatistic] = useState<ReadonlyArray<WalletStatistic>>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [checkedWalletType, checkWalletType] = useState<WalletStatistic['wallet_type'] | null>(null);

	const [isModalOpened, setIsModalOpened] = useState(false);
	const handleModal = (flag: "close" | "open") => () => setIsModalOpened(flag === "open" ? true : false);

	const fetchWalletsStatistic = async () => {
		try {
			setIsLoading(true);
			const resp = await API.wallets.getStatistics();
			if (resp.status === "success") {
				setWalletsStatistic(resp.data);
			}
		} catch (e) {
			if (axios.isAxiosError(e)) {
				window.pushAlert({
					type: "error",
					description: e.message || errorsMap.cantGetStatisticData,
				});
			}
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchWalletsStatistic();
	}, []);

	return (
		<StatisticWalletsCtx.Provider value={{ walletsStatistic, isModalOpened, handleModal, isLoading, checkedWalletType, checkWalletType, fetchWalletsStatistic }}>
			{children}
		</StatisticWalletsCtx.Provider>
	);
};
