import { t } from "pay-kit";
import { FC } from "react";
import styles from "./transactionKind.module.scss";
import { StockPilingsType, TransactionsType } from "api/stockpilingsGroup";

type TransactionKindType = {
	type: TransactionsType[`type`];
	status?: StockPilingsType[`status`];
	direction?: StockPilingsType[`direction`];
};

const typeValue = {
	0: t("Confirmed"),
	1: t("Debug"),
	2: t("Forced"),
	3: t("Internal"),
};

const typeStyle = {
	0: styles.realTransaction,
	1: styles.fakeTransaction,
	2: styles.forcedTransaction,
	3: styles.innerTransaction,
};

const TransactionKind: FC<TransactionKindType> = ({ type, status, direction }) => {
	const dataKey = status === 0 && type === 0 && direction === `outgoing` ? 3 : type;

	return <div className={typeStyle[dataKey]}>{typeValue[dataKey]}</div>;
};

export default TransactionKind;
