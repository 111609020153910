// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FixEditor__wrapper-sSt{padding:24px}.FixEditor__wrapper-sSt h4{padding:0;margin:0;font-size:16px;font-style:normal;font-weight:600;line-height:24px}.FixEditor__wrapper-sSt .FixEditor__commission-JnJ{display:flex;gap:24px;align-items:flex-end;width:fit-content}.FixEditor__wrapper-sSt .FixEditor__commission-JnJ .FixEditor__input-wdq{display:block}`, "",{"version":3,"sources":["webpack://./src/modules/PaymentSystems/Tariffs/components/CommissionsEditor/FixEditor/FixEditor.module.scss"],"names":[],"mappings":"AAAA,wBACI,YAAA,CAEA,2BACI,SAAA,CACA,QAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CAGJ,mDACI,YAAA,CACA,QAAA,CACA,oBAAA,CACA,iBAAA,CAEA,yEACI,aAAA","sourcesContent":[".wrapper {\n    padding: 24px;\n\n    h4 {\n        padding: 0;\n        margin: 0;\n        font-size: 16px;\n        font-style: normal;\n        font-weight: 600;\n        line-height: 24px;\n    }\n\n    .commission {\n        display: flex;\n        gap: 24px;\n        align-items: flex-end;\n        width: fit-content;\n\n        .input {\n            display: block;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `FixEditor__wrapper-sSt`,
	"commission": `FixEditor__commission-JnJ`,
	"input": `FixEditor__input-wdq`
};
export default ___CSS_LOADER_EXPORT___;
