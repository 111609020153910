import { useEffect, useRef, useState } from "react";

const useDebounce: UseDebounceType = (value, delay = 500) => {
	const [debouncedValue, setDebouncedValue] = useState<ValueType>({});
	const timerRef = useRef<NodeJS.Timeout | undefined>();

	useEffect(() => {
		timerRef.current = setTimeout(() => setDebouncedValue(value), delay);

		return () => {
			if (timerRef.current) clearTimeout(timerRef.current);
		};
	}, [value.id, value.writeoff_amount, delay]);

	return debouncedValue;
};

export default useDebounce;

type ValueType = { readonly id?: string, readonly writeoff_amount?: string }

type UseDebounceType = (value: ValueType, delay: number) => ValueType