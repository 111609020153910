import ToggleButton from "components/Icons/Caret";
import { sliceWallets } from "modules/Balance/helpers";
import { ReactElement, useState } from "react";

import styles from "./showBalances.module.scss";

const ShowBalances = ({
	list,
	range,
	balancesList,
	dataTestId,
}: { readonly list: readonly ListType[] } & ShowTextWithCaretType) => {
	const [showAll, setShowAll] = useState(false);
	const slicedWallets = sliceWallets(list, showAll, range);

	return (
		<div>
			{balancesList(slicedWallets.wallets)}

			{list.length > range && (
				<ToggleButton
					setShowAll={setShowAll}
					showAll={showAll}
					customClass={styles.caretWrapper}
					dataTestId={dataTestId}
				/>
			)}
		</div>
	);
};

export default ShowBalances;

export type ListType = {
	readonly currency_code: string;
	readonly amount: number;
	readonly id?: number;
};

type ShowTextWithCaretType = {
	readonly range: number;
	readonly balancesList: (list: readonly ListType[]) => ReactElement;
	readonly dataTestId?: string;
};
