// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextWithCopyButton__row-Iaw{display:flex;justify-content:space-between;margin-left:24px;width:304px}.TextWithCopyButton__row-Iaw .TextWithCopyButton__rowValue-WvI{max-width:276px;word-break:break-all;color:#21272a}.TextWithCopyButton__row-Iaw>*:last-child{height:33px}`, "",{"version":3,"sources":["webpack://./src/modules/Stockpilings/components/TextWithCopyButton/textWithCopyButton.module.scss"],"names":[],"mappings":"AAAA,6BACC,YAAA,CACA,6BAAA,CACA,gBAAA,CACA,WAAA,CAEA,+DACC,eAAA,CACA,oBAAA,CACA,aAAA,CAGD,0CACC,WAAA","sourcesContent":[".row {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\tmargin-left: 24px;\n\twidth: 304px;\n\n\t.rowValue {\n\t\tmax-width: 276px;\n\t\tword-break: break-all;\n\t\tcolor: #21272A;\n\t}\n\n\t& > *:last-child {\n\t\theight: 33px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `TextWithCopyButton__row-Iaw`,
	"rowValue": `TextWithCopyButton__rowValue-WvI`
};
export default ___CSS_LOADER_EXPORT___;
