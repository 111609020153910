// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TransactionsPage__panel-FQI{padding:48px 0}.TransactionsPage__panel-FQI .TransactionsPage__panel__title-i3M{font-size:24px;font-weight:500;margin-bottom:48px}.TransactionsPage__panel-FQI .TransactionsPage__tabs__wrapper-Ni1{width:100%;position:relative}`, "",{"version":3,"sources":["webpack://./src/layout/pages/TransactionsPage/styles.module.scss"],"names":[],"mappings":"AAAA,6BACI,cAAA,CAEA,iEACI,cAAA,CACA,eAAA,CACA,kBAAA,CAGJ,kEACI,UAAA,CACA,iBAAA","sourcesContent":[".panel {\n    padding: 48px 0;\n\n    .panel__title {\n        font-size: 24px;\n        font-weight: 500;\n        margin-bottom: 48px;\n    }\n\n    .tabs__wrapper {\n        width: 100%;\n        position: relative;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": `TransactionsPage__panel-FQI`,
	"panel__title": `TransactionsPage__panel__title-i3M`,
	"tabs__wrapper": `TransactionsPage__tabs__wrapper-Ni1`
};
export default ___CSS_LOADER_EXPORT___;
