import { t } from "pay-kit";
export const TIPS = {
	requiredTipForLabel: t(`Required parameter. Unique identifier (ID) of the user making the payment.`),
	optionalTip: t("Optional"),
	requiredTip: t("Required"),
	optionalTipBySettings: t(
		`Optional parameter. The option is available if the "Use redirect URL from settings" switch is disabled in the settings`
	),
};

export const LANG_OPTIONS = [
	{ value: `en`, label: `en` },
	{ value: `vi`, label: `vi` },
	{ value: `ms`, label: `ms` },
	{ value: `id`, label: `id` },
	{ value: `uz`, label: `uz` },
	{ value: `es`, label: `es` },
	{ value: `pt`, label: `pt` },
	{ value: `tr`, label: `tr` },
	{ value: `ar`, label: `ar` },
	{ value: `fa`, label: `fa` },
	{ value: `ur`, label: `ur` },
	{ value: `bn`, label: `bn` },
	{ value: `ne`, label: `ne` },
	{ value: `ru`, label: `ru` },
	{ value: `hi`, label: `hi` },
	{ value: `my`, label: `my` },
	{ value: `si`, label: `si` },
	{ value: `pa`, label: `pa` },
	{ value: `sw`, label: `sw` },
];
