import { OldResponse } from "api/types";
import { WalletListItem, WalletTypeType } from "api/walletGroup";
import { useTranslation } from "pay-kit";
import { InfoBlock } from "pay-kit";
import { useEffect, useState } from "react";
import { ModalProps } from "utils/types";

type MassRecoverWalletModalProps = ModalProps & {
	readonly ids: readonly WalletTypeType[`hash_id`][];
	readonly isOpen: boolean;
	readonly actions: {
		readonly recoverWallet: (hash_id: WalletTypeType[`hash_id`], fetchWallets: boolean) => Promise<OldResponse<void>>;
		readonly getWallets: () => Promise<OldResponse<readonly WalletListItem[]>>;
		readonly clearSelectedIds: () => void;
	};
};

export const MassRecoverWalletModal = ({ closeModal, isOpen, ids, actions }: MassRecoverWalletModalProps) => {
	const { t } = useTranslation();
	const [proceedNum, setProceedNum] = useState<number>(0);
	const [isProceeded, setProceeded] = useState<boolean>(false);

	useEffect(() => {
		if (isOpen) {
			Promise.all(
				ids.map((id) =>
					actions.recoverWallet(id, false).then(() => {
						setProceedNum((num) => num + 1);
					})
				)
			).then(() => {
				setProceeded(true);
			});
		}
	}, [isOpen]);

	useEffect(() => {
		if (isProceeded) {
			actions.getWallets();
			window.pushAlert({
				type: "success",
				title: t("Success"),
				description: t("Wallets successfully recovered"),
			});
			actions.clearSelectedIds();
			closeModal && closeModal();
		}
	}, [isProceeded]);

	return (
		<div>
			<InfoBlock title={t("Please wait...")} />
			<div>
				{proceedNum} / {ids.length}
			</div>
		</div>
	);
};
