import { useTranslation } from "pay-kit";
import { Button } from "@paykassma/pay-kit";
import { WalletStatistic } from "api/walletsGroup";
import Money from "components/Money";
import dayjs from "dayjs";
import { FC } from "react";

import styles from "./StatisticModal.module.scss";

type Props = {
	readonly data: WalletStatistic;
};

function copyToClipboard(text: string) {
	const textArea = document.createElement(`textarea`);

	textArea.value = text;
	document.body.appendChild(textArea);

	textArea.select();
	document.execCommand(`copy`);

	document.body.removeChild(textArea);
}

export const formatDateWithoutTime = (date?: string): string => {
	if (!date) {
		return ``;
	}

	return dayjs(date).format(`DD.MM.YYYY`);
};

export function getMaxValue(data: WalletStatistic[`all_daily_turnover`]): number | null {
	if (!data || data.length === 0) {
		return 0;
	}

	return data.reduce((max, obj) => (obj.value > max ? obj.value : max), data[0].value);
}

export const StatisticModal: FC<Props> = ({ data }) => {
	const {t} = useTranslation();
	const { all_daily_turnover, currency } = data;
	const maxValue = getMaxValue(all_daily_turnover);

	return (
		<div>
			<table className={styles.table}>
				<thead className={styles.thead}>
					<tr>
						<td>{t("Date")}</td>
						<td>{t("Daily turnover")}</td>
					</tr>
				</thead>
				<tbody className={styles.tbody}>
					{all_daily_turnover?.map(({ date, value }) => (
						<tr key={date}>
							<td>{formatDateWithoutTime(date)}</td>
							{` `}
							<td>
								<div className={styles.maxContainer}>
									{maxValue && maxValue === value ? <span className={styles.max}>max {` `}</span> : null}
									<span className={styles.bold}><Money amount={value} currencyCode={currency} /></span>
								</div>
							</td>
						</tr>
					))}
				</tbody>
			</table>

			<Button
				data-test-id="copy-button"
				classname={styles.copy}
				onClick={() => {
					copyToClipboard(all_daily_turnover?.map(({ date, value }) => `${date} ${value} ${currency}`)?.join(`\n`));
					window.pushAlert({
						type: `success`,
						title: t("Data copied")
					});
				}}
				variant="secondary"
			>
				{t("Copy all")}
			</Button>
		</div>
	);
};
