// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IconButton__IconButton-xwl{display:flex;justify-content:center;align-items:center;height:36px;width:36px;border-radius:4px;padding:0;margin:0;border:0;outline:0;background:rgba(0,0,0,0);cursor:pointer;color:#697077}.IconButton__IconButton-xwl:hover{background:#f2f3f5}.IconButton__IconButton-xwl.IconButton__textButton-qn5{display:flex;justify-items:center;gap:8px;height:auto;width:auto;color:#fff;transition:all .3s}.IconButton__IconButton-xwl.IconButton__textButton-qn5:hover{background:rgba(0,0,0,0);color:rgba(255,255,255,.8)}`, "",{"version":3,"sources":["webpack://./src/components/IconButton/IconButton.module.scss"],"names":[],"mappings":"AAAA,4BACC,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,WAAA,CACA,UAAA,CACA,iBAAA,CACA,SAAA,CACA,QAAA,CACA,QAAA,CACA,SAAA,CACA,wBAAA,CACA,cAAA,CAEA,aAAA,CAEA,kCAEC,kBAAA,CAGD,uDACC,YAAA,CACA,oBAAA,CACA,OAAA,CACA,WAAA,CACA,UAAA,CAEA,UAAA,CACA,kBAAA,CAEA,6DACC,wBAAA,CACA,0BAAA","sourcesContent":[".IconButton {\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\theight: 36px;\n\twidth: 36px;\n\tborder-radius: 4px;\n\tpadding: 0;\n\tmargin: 0;\n\tborder: 0;\n\toutline: 0;\n\tbackground: transparent;\n\tcursor: pointer;\n\t/* Blue_gray/70 */\n\tcolor: #697077;\n\n\t&:hover {\n\t\t/* Blue_gray/10 */\n\t\tbackground: #f2f3f5;\n\t}\n\n\t&.textButton {\n\t\tdisplay: flex;\n\t\tjustify-items: center;\n\t\tgap: 8px;\n\t\theight: auto;\n\t\twidth: auto;\n\t\t/* White/Full */\n\t\tcolor: #ffffff;\n\t\ttransition: all 0.3s;\n\n\t\t&:hover {\n\t\t\tbackground: transparent;\n\t\t\tcolor: rgba(255, 255, 255, 0.8);\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"IconButton": `IconButton__IconButton-xwl`,
	"textButton": `IconButton__textButton-qn5`
};
export default ___CSS_LOADER_EXPORT___;
