import StatisticWalletsModule from "modules/Wallets/StatisticWallets";
import { useTranslation } from "pay-kit";
import { useEffect } from "react";

export const StatisticWallets = () => {
	const { t } = useTranslation();

	useEffect(() => {
		document.title = t("Wallet statistics");
	}, []);

	return <StatisticWalletsModule />;
};
