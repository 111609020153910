// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommissionsEditor__wrapper-lpw{flex:1;background:#fff;border-radius:8px;margin-top:16px;animation:CommissionsEditor__fadeIn-a2s 800ms}.CommissionsEditor__wrapper-lpw h3{padding:13px 24px;font-size:20px;font-weight:500;line-height:28px;border-bottom:1px solid #dde1e6}.CommissionsEditor__wrapper-lpw .CommissionsEditor__loader-SNT{height:200px}@keyframes CommissionsEditor__fadeIn-a2s{0%{opacity:0}100%{opacity:1}}`, "",{"version":3,"sources":["webpack://./src/modules/PaymentSystems/Tariffs/components/CommissionsEditor/CommissionsEditor.module.scss"],"names":[],"mappings":"AAAA,gCACI,MAAA,CACA,eAAA,CACA,iBAAA,CACA,eAAA,CACA,6CAAA,CAEA,mCACI,iBAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,+BAAA,CAGJ,+DACI,YAAA,CAIR,yCACI,GACI,SAAA,CAGJ,KACI,SAAA,CAAA","sourcesContent":[".wrapper {\n    flex: 1;\n    background: #fff;\n    border-radius: 8px;\n    margin-top: 16px;\n    animation: fadeIn 800ms;\n\n    h3 {\n        padding: 13px 24px;\n        font-size: 20px;\n        font-weight: 500;\n        line-height: 28px;\n        border-bottom: 1px solid #DDE1E6;\n    }\n\n    .loader {\n        height: 200px;\n    }\n}\n\n@keyframes fadeIn {\n    0% {\n        opacity: 0;\n    }\n\n    100% {\n        opacity: 1;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `CommissionsEditor__wrapper-lpw`,
	"fadeIn": `CommissionsEditor__fadeIn-a2s`,
	"loader": `CommissionsEditor__loader-SNT`
};
export default ___CSS_LOADER_EXPORT___;
