// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Menu__Menu-Ovf{display:flex;flex-direction:column;margin-top:46px;flex:1}`, "",{"version":3,"sources":["webpack://./src/components/Menu/Menu.module.scss"],"names":[],"mappings":"AAAA,gBACE,YAAA,CACA,qBAAA,CACA,eAAA,CACA,MAAA","sourcesContent":[".Menu {\n  display: flex;\n  flex-direction: column;\n  margin-top: 46px;\n  flex: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Menu": `Menu__Menu-Ovf`
};
export default ___CSS_LOADER_EXPORT___;
