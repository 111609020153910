// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ActiveFilters__activeFilters-JqM{display:flex;flex-wrap:wrap;margin-top:24px;gap:8px}.ActiveFilters__activeFilters-JqM button.ActiveFilters__clearAll-Ywe{height:32px;background:rgba(0,0,0,0);border:0;padding:0;margin:0}`, "",{"version":3,"sources":["webpack://./src/components/Filter/ActiveFilters/ActiveFilters.module.scss"],"names":[],"mappings":"AAAA,kCACI,YAAA,CACA,cAAA,CACA,eAAA,CACA,OAAA,CAEA,qEACI,WAAA,CACA,wBAAA,CACA,QAAA,CACA,SAAA,CACA,QAAA","sourcesContent":[".activeFilters {\n    display: flex;\n    flex-wrap: wrap;\n    margin-top: 24px;\n    gap: 8px;\n\n    button.clearAll {\n        height: 32px;\n        background: transparent;\n        border: 0;\n        padding: 0;\n        margin: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"activeFilters": `ActiveFilters__activeFilters-JqM`,
	"clearAll": `ActiveFilters__clearAll-Ywe`
};
export default ___CSS_LOADER_EXPORT___;
