import { CheckAllMultiAddingProxyType } from "api/proxiesGroup";
import CheckingStatus from "modules/Proxies/components/UploadedProxiesListTable/components/CheckingStatus";
import NotWorkingProxiesWarning from "modules/Proxies/components/UploadedProxiesListTable/components/NotWorkingProxiesWarning";
import ProxiesEditableSelect from "modules/Proxies/components/UploadedProxiesListTable/components/ProxiesEditableSelect";
import ProxiesEditableTextField from "modules/Proxies/components/UploadedProxiesListTable/components/ProxiesEditableTextField";
import ProxyManipulatingButtons from "modules/Proxies/components/UploadedProxiesListTable/components/ProxyManipulatingButtons";
import { useAddingProxiesContext } from "modules/Proxies/contexts/AddingProxiesContext";
import { useProxyContext } from "modules/Proxies/contexts/ProxyContext";
import { portPattern, proxyTypes } from "modules/Proxies/data";
import useInitCheckingMultiAddingProxy from "modules/Proxies/hooks/useInitCheckingMultiAddingProxy";
import { useTranslation } from "pay-kit";
import { Alert, Button, Table } from "pay-kit";
import { useState } from "react";

import styles from "./uploadedProxiesListTable.module.scss";

const UploadedProxiesListTable = () => {
	const { countryList } = useProxyContext();
	const { addProxies, multiAddingProxies, proxiesList, isRecheckingMultiAddingProxiesLoading } =
		useAddingProxiesContext();
	const initCheckingMultiAddingProxyAPI = useInitCheckingMultiAddingProxy();
	const { t } = useTranslation();
	const notWorkingProxiesList = proxiesList.filter((elem) => !elem.isFormatValid);
	const [tableEditableRow, setTableEditableRow] = useState({} as RowStatesType);
	const [allChangedDataSaved, setAllChangedDataSaved] = useState(true);
	const [areFieldsValid, setAreFieldsValid] = useState({} as RowStatesType);

	const typeOptions = proxyTypes.map((elem) => ({ label: elem, value: elem }));
	const CountryOptions = countryList?.map((elem) => ({ label: elem.code, value: elem.code })) || [];

	const editedFields = Object.values(tableEditableRow).filter((isEdited) => isEdited);

	const ipCol = {
		title: "IP|HOST",
		dataIndex: "ip_host",
		key: "ip_host",
		render: ({ ip_host, id }: UploadedProxiesListTableRowType) => (
			<ProxiesEditableTextField
				name="ip_host"
				id={id}
				classname={styles.editableHost}
				setIsValid={setAreFieldsValid}
				editableColContent={ip_host}
				setTableEditableRow={setTableEditableRow}
				tableEditableRow={tableEditableRow}
				validation={{
					message: "Input length must not exceed 100 characters!",
					max: 100,
				}}
			/>
		),
	};

	const portCol = {
		title: t("Port"),
		dataIndex: "port",
		key: "port",
		render: ({ port, id }: UploadedProxiesListTableRowType) => (
			<ProxiesEditableTextField
				name="port"
				id={id}
				classname={styles.editablePort}
				setIsValid={setAreFieldsValid}
				editableColContent={port}
				setTableEditableRow={setTableEditableRow}
				tableEditableRow={tableEditableRow}
				validation={{
					message: "Invalid format!",
					pattern: portPattern,
					maxAmount: 65535,
				}}
			/>
		),
	};

	const loginCol = {
		title: t("Login"),
		dataIndex: "username",
		key: "username",
		render: ({ username, id }: UploadedProxiesListTableRowType) => (
			<ProxiesEditableTextField
				name="username"
				id={id}
				classname={styles.editableUser}
				setIsValid={setAreFieldsValid}
				editableColContent={username}
				setTableEditableRow={setTableEditableRow}
				tableEditableRow={tableEditableRow}
			/>
		),
	};

	const passwordCol = {
		title: t("Password"),
		dataIndex: "password",
		key: "password",
		render: ({ password, id }: UploadedProxiesListTableRowType) => (
			<ProxiesEditableTextField
				name="password"
				id={id}
				classname={styles.editablePassword}
				setIsValid={setAreFieldsValid}
				editableColContent={password}
				setTableEditableRow={setTableEditableRow}
				tableEditableRow={tableEditableRow}
			/>
		),
	};

	const typeCol = {
		title: t("Type"),
		dataIndex: "type",
		key: "type",
		render: ({ type, id }: UploadedProxiesListTableRowType) => (
			<ProxiesEditableSelect
				name="type"
				id={id}
				classname={styles.editableSelect}
				options={typeOptions}
				editableColContent={type}
				setTableEditableRow={setTableEditableRow}
				tableEditableRow={tableEditableRow}
			/>
		),
	};

	const countryCol = {
		title: t("Country"),
		dataIndex: "country_code",
		key: "country_code",
		render: ({ country_code, id }: UploadedProxiesListTableRowType) => (
			<ProxiesEditableSelect
				name="country_code"
				id={id}
				classname={styles.editableSelect}
				options={CountryOptions}
				editableColContent={country_code}
				setTableEditableRow={setTableEditableRow}
				tableEditableRow={tableEditableRow}
			/>
		),
	};

	const statusCol = {
		title: t("Status"),
		dataIndex: "status",
		key: "status",
		render: ({ status, in_system, isChecking }: UploadedProxiesListTableRowType) => (
			<CheckingStatus status={status} in_system={in_system} isChecking={isChecking} />
		),
	};

	const actionsCol = {
		title: t("Actions"),
		dataIndex: "updated_at",
		key: "updated_at",
		render: ({ id, isChecking }: UploadedProxiesListTableRowType) => {
			return (
				<ProxyManipulatingButtons
					id={id}
					setTableEditableRow={setTableEditableRow}
					tableEditableRow={tableEditableRow}
					isLoading={initCheckingMultiAddingProxyAPI.isLoading}
					create={initCheckingMultiAddingProxyAPI.create}
					isFieldValid={!areFieldsValid[id]}
					isRelaunchLoading={isChecking}
				/>
			);
		},
	};

	const handleOnSubmit = (update_existed: boolean) => {
		return () => {
			const params = {
				proxies: multiAddingProxies?.map(({ id, ...proxy }) => proxy) || [],
				update_existed,
			};

			if (editedFields.length) setAllChangedDataSaved(false);
			else addProxies(params);
		};
	};

	const areEditedDataNotSaved = !allChangedDataSaved && !!editedFields.length;

	return (
		<div>
			<Table
				className={styles.uploadedProxiesListTable}
				rowKey={"id"}
				data={multiAddingProxies || []}
				columns={[ipCol, portCol, loginCol, passwordCol, typeCol, countryCol, statusCol, actionsCol]}
				isLoading={isRecheckingMultiAddingProxiesLoading}
			/>

			{notWorkingProxiesList.length > 0 && (
				<NotWorkingProxiesWarning
					notWorkingProxiesList={notWorkingProxiesList}
					allChangedDataSaved={!!editedFields.length}
				/>
			)}

			{areEditedDataNotSaved && (
				<Alert
					title={t("Save all changes")}
					className={styles.uploadedProxiesListTableAlert}
					variant="danger"
					withIcon={true}
				/>
			)}

			<div className={styles.UploadedProxiesListButtons}>
				{/*//TODO: the button's logic completion is left up to the next stage*/}
				{/*	<RelaunchButton isDisabled={!isValid || allManipulateButtonsDisabled}>*/}
				{/*		Запустить проверку всех прокси*/}
				{/*	</RelaunchButton>*/}

				{/*<Button data-test-id="NMMACgYOxuuaIlJr7Cozr"*/}
				{/*	classname={styles.forward}*/}
				{/*	onClick={handleOnForward}*/}
				{/*	disabled={!!validFields.length || allManipulateButtonsDisabled}*/}
				{/*>*/}
				{/*	Вперёд <CaretIcon className={styles.forwardCaret} />*/}
				{/*</Button>*/}

				<Button
					data-test-id="BZrzUve5bj9PcI5pmiQO-"
					classname={styles.addOnlyNew}
					onClick={handleOnSubmit(true)}
					disabled={isRecheckingMultiAddingProxiesLoading || areEditedDataNotSaved}
				>
					{t("Add new and update existed")}
				</Button>

				<Button
					data-test-id="sijNhyUxZspfQX-jj2-YN"
					classname={styles.addNew}
					onClick={handleOnSubmit(false)}
					disabled={isRecheckingMultiAddingProxiesLoading || areEditedDataNotSaved}
				>
					{t("Add only new")}
				</Button>
			</div>
		</div>
	);
};
export default UploadedProxiesListTable;

type UploadedProxiesListTableRowType = CheckAllMultiAddingProxyType & {
	readonly id: string;
	readonly isChecking: boolean;
};

export type RowStatesType = {
	readonly [id: string]: boolean;
};
