// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WalletDetails__WalletDetailsWrapper-Apc{width:560px}`, "",{"version":3,"sources":["webpack://./src/modules/WalletsLoad/components/ActionsProvider/WalletDetails/WalletDetails.module.scss"],"names":[],"mappings":"AAAA,yCACC,WAAA","sourcesContent":[".WalletDetailsWrapper {\n\twidth: 560px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"WalletDetailsWrapper": `WalletDetails__WalletDetailsWrapper-Apc`
};
export default ___CSS_LOADER_EXPORT___;
