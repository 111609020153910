// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Direction__Direction-YA8{display:inline-block;font-weight:500;font-size:14px;line-height:20px;text-transform:uppercase;border-radius:100px;padding:0px 8px}.Direction__Direction-YA8.Direction__outgoing-I5S{color:#e8882e;background:#fff4e2}.Direction__Direction-YA8.Direction__ingoing-wXN{color:#0072c3;background:#f1faff}`, "",{"version":3,"sources":["webpack://./src/modules/Postbacks/components/columns/components/Direction/Direction.module.scss"],"names":[],"mappings":"AAAA,0BACC,oBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,wBAAA,CACA,mBAAA,CACA,eAAA,CAEA,kDAEC,aAAA,CAEA,kBAAA,CAGD,iDAEC,aAAA,CAEA,kBAAA","sourcesContent":[".Direction {\n\tdisplay: inline-block;\n\tfont-weight: 500;\n\tfont-size: 14px;\n\tline-height: 20px;\n\ttext-transform: uppercase;\n\tborder-radius: 100px;\n\tpadding: 0px 8px;\n\n\t&.outgoing {\n\t\t/* Orange/50 */\n\t\tcolor: #e8882e;\n\t\t/* Orange/5 */\n\t\tbackground: #fff4e2;\n\t}\n\n\t&.ingoing {\n\t\t/* Brand/Primary */\n\t\tcolor: #0072c3;\n\t\t/* Brand/Ghost */\n\t\tbackground: #f1faff;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Direction": `Direction__Direction-YA8`,
	"outgoing": `Direction__outgoing-I5S`,
	"ingoing": `Direction__ingoing-wXN`
};
export default ___CSS_LOADER_EXPORT___;
