import { t, Tooltip } from "pay-kit";

import ApiIcon from "./icons/api.svg";
import ManualIcon from "./icons/manual.svg";

interface ICreationType {
	readonly is_manually: boolean;
	readonly dataTestId?: string;
}

const CreationType = ({ is_manually, dataTestId }: ICreationType) => {
	const CREATION_TYPE_MAP = {
		API: {
			// title: "Через API",
			title: t("Through API"),
			icon: <ApiIcon />
		},
		MANUAL: {
			// title: "Ручной из личного кабинета",
			title: t("Manually from the personal account"),
			icon: <ManualIcon />
		}
	};

	const currentType = is_manually ? CREATION_TYPE_MAP.MANUAL : CREATION_TYPE_MAP.API;

	return (
		<Tooltip
			preferredSide="bottom"
			tip={<>
				{/* <div>Споcоб вывода</div> */}
				<div>{t("Withdrawal method")}</div>
				<div data-test-id={dataTestId}>{currentType.title}</div>
			</>}>
			{currentType.icon}
		</Tooltip>
	);
};

export default CreationType;
