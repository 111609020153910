import { ModalRowComponentType } from "modules/TransferHistory/components/TransferringMoney";
import { testAgainstNonNumbers } from "modules/TransferHistory/helpers";
import { TextInput } from "pay-kit";
import { ChangeEvent, FC } from "react";

import styles from "./amount.module.scss";

const Amount: FC<Omit<ModalRowComponentType, "changeField">> = ({
	setForm,
	form,
	name,
	errors
}) => {

	const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = testAgainstNonNumbers(form[name] || "", e.target.value, 20);
		setForm(prevState => ({
			...prevState,
			[name]: value,
			isWriteOffAmountTouched: true,
			isCreditingTouched: false
		}));
	};

	return (
		<div className={styles.amountTextInput}>
			<TextInput
				name={name}
				className={styles.inputContainer}
				value={form[name] || ""}
				onChange={handleOnChange}
				isDisabled={!form.currency_code}
				error={errors[name]}
			/>
		</div>
	);
};

export default Amount;
