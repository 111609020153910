import { useTranslation } from "pay-kit";
import { Button } from "pay-kit";
import { FC, ReactElement } from "react";

import styles from "./selectBuilderButton.module.scss";

const FieldsBuilderButton: FC<FieldsBuilderButtonType> = ({ header, classes, children, insertItem, error }) => {
	const {t} = useTranslation();
	return (
		<div className={classes.wrapper}>
			{header && <div className={classes.paymentHeader}>{header}</div>}
			{children}
			<div className={styles.addButtonWrapper}>
				<Button
					data-test-id="add-wallet"
					variant="text-primary"
					onClick={() => insertItem({ type: undefined }, "insertAfter")}
					classname={styles.addButton}
				>
					+ {t("Add")}				</Button>
			</div>
			{error && <div className={styles.errorMessage}>{error}</div>}
		</div>
	);
};

export default FieldsBuilderButton;

type FieldsBuilderButtonType = {
	readonly header?: string;
	readonly classes: {
		readonly wrapper: string;
		readonly paymentHeader?: string;
	};
	readonly children: readonly ReactElement[];
	readonly error: string;
	readonly insertItem: (value: { readonly type: undefined }, pos: string) => void;
};
