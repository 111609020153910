import { Button, PayKitForm } from "@paykassma/pay-kit";
import { ElementType } from "@paykassma/pay-kit/lib/elements/PayKitForm/FormBuilder/types";
import { t, useTranslation } from "pay-kit";

import styles from "./PercentEditor.module.scss";
import PercentRow from "./PercentRow";

export const getCustomElements = ({ direction, tariffId, onSuccess, type }: any) => ({
	Wrapper: (props: ElementType<any>) => (
		<PayKitForm.Repeat
			{...props}
			render={(children, props) => (
				<>
					{props.error && <div style={{ color: "red" }}>{props.error}</div>}
					{children}
					<div className={styles.commissionsActions}>
						<Button
							data-testid="add-commission"
							variant="text-primary"
							data-test-id=""
							onClick={() => {
								typeof props.insertItem === "function" &&
									props.insertItem(
										{
											id: undefined,
											min_amount: "",
											max_amount: "",
											commission: "",
											type: type,
											direction,
										},
										"insertAfter"
									);

								const newRowPath = `${props.path}[${props.value.length}]`;
								props.formDataAPI.setValue("rowPathInEditMode", newRowPath);
							}}
						>
							+ {t("Add")}{" "}
						</Button>
					</div>
				</>
			)}
		/>
	),
	PercentRow: (props: ElementType<any>) => (
		<PayKitForm.Group
			{...props}
			render={(children: React.ReactElement, props) => <PercentRow {...{ children, props, tariffId, onSuccess }} />}
		/>
	),
	PercentTextInput: (props: ElementType<any>) => (
		<PayKitForm.Group
			{...props}
			render={(children) => (
				<div className={styles.percentCustomField}>
					{children}
					<div className={styles.percent}>%</div>
				</div>
			)}
		/>
	),
});
