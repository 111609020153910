import { useTranslation } from "pay-kit";
import { TransactionTypeEnum } from "api/transactionGroup";
import { Tooltip } from "pay-kit";
import React from "react";

import styles from "./TransactionType.module.scss";

interface ITransactionType {
	readonly type: TransactionTypeEnum;
	readonly dataTestId?: string;
}

const TransactionType: React.FC<ITransactionType> = ({ type, dataTestId }) => {
	const {t} = useTranslation();
	const TypeMap = {
		[TransactionTypeEnum.APPROVED]: {
			label: t("Confirmed"),
			className: styles.APPROVED
		},
		[TransactionTypeEnum.DEBUG]: {
			label: t("Debug"),
			className: styles.DEBUG
		},
		[TransactionTypeEnum.FORCED]: {
			label: t("Forced"),
			className: styles.FORCED
		},
		[TransactionTypeEnum.INTERNAL]: {
			label: t("Internal"),
			className: styles.INTERNAL
		}
	};

	// const typeObject = TypeMap[type] || { label: "Неопределенный вид", className: styles.notDefined };
	const typeObject = TypeMap[type] || { label: t("Not defined type"), className: styles.notDefined };
	const classNames = [styles.TransactionType, typeObject.className];

	return (
		<Tooltip tip={t("Transaction kind")} preferredSide="bottom">
			<div className={classNames.join(" ")} data-test-id={dataTestId}>{typeObject.label}</div>
		</Tooltip>
	);
};

export default TransactionType;
