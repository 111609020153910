import { useTranslation } from "@paykassma/pay-kit";
import API from "api";
import { CreateWithdrawalParamsType } from "api/balanceGroup";
import { useCallback, useState } from "react";


const useCreateWithdrawal = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<null | Error>(null);
	const t = useTranslation();

	const create: CreateHandler = useCallback((data, onSuccess) => {
		setIsLoading(true);
		setError(null);

		API.balances.createWithdrawal(data).then(
			res => {
				if (res.status === "ok") {
					onSuccess && onSuccess();
				} else {
					throw new Error(t("Unexpected response in useCreateWithdrawal"));
				}

				return res;
			}
		).catch((err) => {
			setError(err);
		}).finally(() =>
			setIsLoading(false)
		);

	}, []);

	return {
		create,
		isLoading,
		error
	};
};

export default useCreateWithdrawal;

type CreateHandler = (data: CreateWithdrawalParamsType, onSuccess?: () => void) => void