import { OldResponse } from "api/types";
import { WalletTypeType } from "api/walletGroup";
import { DeleteModal } from "components/Modals/DeleteModal/DeleteModal";
import { useTranslation } from "pay-kit";
import { ModalProps } from "utils/types";

export const DeleteWalletModal = ({ closeModal, actions, hash_id }: DeleteWalletModalProps) => {
	const { t } = useTranslation();
	return (
		<DeleteModal
			closeModal={closeModal}
			text={<>{t("Are you sure you want to delete wallet {{hash_id}}?", { hash_id })}</>}
			deleteLabel={t("Delete")}
			closeLabel={t("Cancel")}
			actions={{
				onDelete: () => {
					return actions.deleteWallet(hash_id).then((resp) => {
						if (resp.status === "fail") {
							window.pushAlert({
								type: "error",
								title: t("Error"),
								description: resp.message,
							});
						} else {
							window.pushAlert({
								type: "success",
								title: t("Success"),
								description: t("Wallet {{hash_id}} successfully deleted", { hash_id }),
							});
						}
					});
				},
			}}
		/>
	);
};

type DeleteWalletModalProps = ModalProps & {
	readonly hash_id: WalletTypeType[`hash_id`];
	readonly actions: {
		readonly deleteWallet: (hash_id: WalletTypeType[`hash_id`]) => Promise<OldResponse<void>>;
	};
};
