import { ModalRowComponentType } from "modules/TransferHistory/components/TransferringMoney";
import { testAgainstNonNumbers } from "modules/TransferHistory/helpers";
import { TextInput } from "pay-kit";
import { ChangeEvent, FC } from "react";

import styles from "./crediting.module.scss";

const Crediting: FC<ModalRowComponentType> = ({
	name,
	setForm,
	form,
	errors
}) => {

	const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = testAgainstNonNumbers(form[name] || "", e.target.value, 20);

		setForm(prevState => (
			{
				...prevState,
				[name]: value,
				isCreditingTouched: true,
				isWriteOffAmountTouched: false
			}));
	};

	return (
		<div className={styles.creditTextInput}>
			<TextInput
				name={name}
				className={styles.inputContainer}
				value={form[name] || ""}
				onChange={handleOnChange}
				error={errors[name]}
			/>
		</div>
	);
};

export default Crediting;
