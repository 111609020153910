import { Table } from "@paykassma/pay-kit";
import { TableSelectedRowIds } from "@paykassma/pay-kit/lib/elements/Table/types";
import { PlusIcon } from "components/Icons/PlusIcon/PlusIcon";
import { RefreshIcon } from "components/Icons/RefreshIcon";
import RightModal from "components/Modals/RightModal";
import SideModalContent from "components/SideModalContent";
import AuthContext, { Roles, useHasAccessHook } from "contexts/AuthContext";
import ReloadButton from "modules/Postbacks/components/ReloadButton";
import WalletsFilter from "modules/Wallets/components/Filter";
import WorkingWalletContextProvider, {
	useWorkingWalletContext,
} from "modules/Wallets/contexts/WorkingWalletContext/WorkingWalletContext";
import MultipleActions from "modules/Wallets/WorkingWallets/components/MultipleActions";
import { useTranslation } from "pay-kit";
import { Button } from "pay-kit";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useURLState from "utils/hooks/useURLState";

import styles from "../Wallets.module.scss";
import workingWalletsColumns, { subRow } from "./components/columns";

export const WorkingWallets: React.FC = () => {
	const { t } = useTranslation();
	const [page, setCurrentPage] = useURLState<number>("page", 1);
	const [limit, setLimit] = useURLState<number>("limit", 100);
	const { hasRole } = useContext(AuthContext);
	const {
		wallets,
		isLoading,
		selectedHashIDs,
		setSelectedHashIDs,
		openCreateWalletModal,
		getWallets,
		paginationInfo,
		filter,
		setFilter,
	} = useWorkingWalletContext();
	const [hashID, setHashID] = useState<string>();
	const [id, setID] = useState<number | undefined>();
	const navigate = useNavigate();

	const fetchWallets = () => {
		getWallets({ limit, page });
	};

	const hasListPermission = hasRole(Roles.WALLET_LIST);
	const createPermission = hasRole(Roles.WALLET_CREATE);
	const privateInformationAccess = hasRole(Roles.WALLET_NUMBER);
	const balanceAccess = hasRole(Roles.WALLET_BALANCE);
	useEffect(() => {
		if (hasListPermission && limit) {
			fetchWallets();
		}
	}, [filter, limit, page, hasListPermission]);

	const handleShowAllTransactionClick = (walletHashID?: string) => {
		navigate(`/transactions?wallet_hash_id=${walletHashID}`);
	};

	const handleCreate = () => {
		openCreateWalletModal(false);
	};

	useHasAccessHook({ rule: Roles.WALLET_LIST, redirect: true });

	return (
		<>
			<div className={styles.wallets}>
				{createPermission && (
					<>
						<Button
							classname={styles.create_button}
							data-test-id="utPFmhErEvdNo1tRc9xHo"
							onClick={handleCreate}
							variant="primary"
							dataTestId="createWalletButton"
						>
							<PlusIcon />
							<span
								style={{
									marginLeft: "8px",
								}}
							>
								{t("Create wallet")}
							</span>
						</Button>
						<Button data-test-id="" type="button" variant="text-primary" onClick={fetchWallets}>
							<RefreshIcon />
							{t("Refresh")}
						</Button>
					</>
				)}
			</div>
			<WalletsFilter filter={filter} setFilter={setFilter} type="working" />
			<RightModal
				bodyClassName={styles.DetailedViewWrapper}
				heading={t("Wallet")}
				onShow={() => null}
				isVisible={!!hashID}
				onClose={() => setHashID(undefined)}
			>
				<SideModalContent walletHashId={hashID} id={id} showAllTransactionsForWallet={handleShowAllTransactionClick} />
			</RightModal>
			<Table
				rowKey="hash_id"
				data={wallets}
				columns={workingWalletsColumns(setHashID, privateInformationAccess, balanceAccess, setID)}
				isLoading={isLoading}
				skeleton={{
					rowsCount: 10,
				}}
				className={styles.table}
				subRowRender={subRow}
				select={{
					multiselect: true,
					selectedIds: selectedHashIDs,
					onRowsSelect: (hashIds: TableSelectedRowIds, e?: MouseEvent) => {
						if (e) {
							e.stopPropagation();
						}
						setSelectedHashIDs(hashIds);
					},
					renderMultipleActions: () => <MultipleActions />,
				}}
				paginator={
					paginationInfo
						? {
								currentPage: page,
								setCurrentPage: (page: number) => setCurrentPage(page as number),
								limit,
								setLimit,
								lastAvailablePage: Math.ceil((paginationInfo.total + paginationInfo.offset) / paginationInfo.limit),
								prefixElement: <ReloadButton data-test-id="" isLoading={isLoading} onClick={fetchWallets} />,
								className: styles.paginator,
								bottomPaginatorProps: {
									className: styles.bottomPaginator,
								},
						  }
						: undefined
				}
			/>
		</>
	);
};

export default (props) => (
	<WorkingWalletContextProvider type="working" initFetch={false}>
		<WorkingWallets {...props} />
	</WorkingWalletContextProvider>
);
