import { fetchApi } from "api/fetch";

export type LoadWalletsSummaryType = {
	readonly all: number;
	readonly currency: string;
	readonly wallet_off_limit: number;
	readonly wallet_off_load: number;
	readonly wallet_type: string;
	readonly wallet_type_name: string;
	readonly worked: number;
};

export type WalletStatistic = {
	// Тип ПС
	readonly wallet_type: string;
	// Наименование ПС
	readonly wallet_type_name: string;
	// Валюта ПС
	readonly currency: string;
	// Общее количество рабочих кошельков с включенной видимостью
	readonly visible_counter: number;
	// Общее количество рабочих кошельков
	readonly worked_counter: number;
	// Общее количество кошельков
	readonly all_counter: number;
	// Текущий оборот видимых - сумма фактически совершенных транзакций за сегодняшний день (с 00:00 UTC) по видимым кошелькам
	readonly visible_daily_turnover: number;
	// Суммарное значение параметра "Отключать при балансе"
	readonly visible_wallet_off_limit: number;
	// Суммарное значение параметра "Баланс"
	readonly visible_balance: number;
	// Суммарное значение параметра "Суточный лимит по сумме"
	readonly visible_wallet_off_load: number;
	// Суммарное значение параметра "Баланс"
	readonly not_removed_balance: number;
	// 	Суммарное значение параметра "Отключать при балансе"
	readonly not_removed_wallet_off_limit: number;
	// Текущий оборот  - сумма фактически совершенных транзакций за сегодняшний день (с 00:00 UTC) по  кошелькам  (removed=NULL ) этой ПС
	readonly not_removed_daily_turnover: number;
	// Суммарное значение параметра "Суточный лимит по сумме"
	readonly not_removed_wallet_off_load: number;
	// Массив значений по дневному обороту за 7 дней
	readonly all_daily_turnover: ReadonlyArray<{
		// Дата, за которую был сформирован оборот по кошелькам
		readonly date: string;
		// Дневной оборот  - сумма фактически совершенных транзакций за день (с 00:00 UTC) по  кошелькам  этой ПС
		readonly value: number;
	}>;
};

const endpoint = "wallets";

const walletsGroup = {
	loadWalletsSummary: () => fetchApi<readonly LoadWalletsSummaryType[]>(`${endpoint}/summary`),
	getStatistics: () => fetchApi<ReadonlyArray<WalletStatistic>>(`${endpoint}/statistic`),
};

export default walletsGroup;
