// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PostbackConfirmation__postbackConfirmation-Mce{width:100%}.PostbackConfirmation__postbackConfirmation-Mce .PostbackConfirmation__postbackButtonsContainer-jVT{display:flex;margin-top:32px;justify-content:end}.PostbackConfirmation__postbackConfirmation-Mce .PostbackConfirmation__postbackButtonsContainer-jVT>*:last-child{margin-left:16px}`, "",{"version":3,"sources":["webpack://./src/modules/Stockpilings/components/PostbackConfirmation/postbackConfirmation.module.scss"],"names":[],"mappings":"AAAA,gDACC,UAAA,CACA,oGACC,YAAA,CACA,eAAA,CACA,mBAAA,CACA,iHACC,gBAAA","sourcesContent":[".postbackConfirmation {\n\twidth: 100%;\n\t.postbackButtonsContainer {\n\t\tdisplay: flex;\n\t\tmargin-top: 32px;\n\t\tjustify-content: end;\n\t\t& > *:last-child {\n\t\t\tmargin-left: 16px;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"postbackConfirmation": `PostbackConfirmation__postbackConfirmation-Mce`,
	"postbackButtonsContainer": `PostbackConfirmation__postbackButtonsContainer-jVT`
};
export default ___CSS_LOADER_EXPORT___;
