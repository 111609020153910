// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CreateNewTransaction__modal-Ad3{width:560px;max-width:none !important}`, "",{"version":3,"sources":["webpack://./src/modules/Transactions/CurrentTransactions/CreateNewTransaction/CreateNewTransaction.module.scss"],"names":[],"mappings":"AAAA,iCACC,WAAA,CACA,yBAAA","sourcesContent":[".modal {\n\twidth: 560px;\n\tmax-width: none!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `CreateNewTransaction__modal-Ad3`
};
export default ___CSS_LOADER_EXPORT___;
