// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormattedError__formattedError-azY{margin-top:16px}.FormattedError__formattedError-azY .FormattedError__title-ayD{font-weight:500}`, "",{"version":3,"sources":["webpack://./src/modules/Withdrawals/components/ActionsProvider/ImportReportModal/DropZone/FormattedError/FormattedError.module.scss"],"names":[],"mappings":"AAAA,oCAKI,eAAA,CAJA,+DACI,eAAA","sourcesContent":[".formattedError {\n    .title {\n        font-weight: 500;\n    }\n\n    margin-top: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formattedError": `FormattedError__formattedError-azY`,
	"title": `FormattedError__title-ayD`
};
export default ___CSS_LOADER_EXPORT___;
