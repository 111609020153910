// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ColumnTitleSeparator__separator-lHj{font-size:8px;padding:0 4px;color:#dde1e6;vertical-align:middle}`, "",{"version":3,"sources":["webpack://./src/components/ColumnTitleSeparator/ColumnTitleSeparator.module.scss"],"names":[],"mappings":"AAAA,qCACC,aAAA,CACA,aAAA,CAEA,aAAA,CACA,qBAAA","sourcesContent":[".separator {\n\tfont-size: 8px;\n\tpadding: 0 4px;\n\t/* Blue_gray/30 */\n\tcolor: #dde1e6;\n\tvertical-align: middle;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"separator": `ColumnTitleSeparator__separator-lHj`
};
export default ___CSS_LOADER_EXPORT___;
