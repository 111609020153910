// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ActivateConfirmationModal__form-PK6{width:100%}.ActivateConfirmationModal__form-PK6 .ActivateConfirmationModal__actions-V0J{margin-top:32px;display:flex;justify-content:flex-end;gap:16px}`, "",{"version":3,"sources":["webpack://./src/modules/Transactions/CurrentTransactions/ActionsProvider/ActivateConfirmationModal/ActivateConfirmationModal.module.scss"],"names":[],"mappings":"AAAA,qCACI,UAAA,CAGA,6EACI,eAAA,CACA,YAAA,CACA,wBAAA,CACA,QAAA","sourcesContent":[".form {\n    width: 100%;\n    // height: 300px;\n\n    .actions {\n        margin-top: 32px;\n        display: flex;\n        justify-content: flex-end;\n        gap: 16px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `ActivateConfirmationModal__form-PK6`,
	"actions": `ActivateConfirmationModal__actions-V0J`
};
export default ___CSS_LOADER_EXPORT___;
