import QuestionMark from "assets/icons/questionMark.svg";
import AuthContext from "contexts/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";
import { gettingTimezoneLabel, timeZoneLIstFormatter } from "modules/AppSettings/AppSettingsProfile/helpers";
import useGetTimezone from "modules/AppSettings/AppSettingsProfile/hooks/useGetTimezone";
import useGetTimezoneList from "modules/AppSettings/AppSettingsProfile/hooks/useGetTimezoneList";
import useSetTimeZone from "modules/AppSettings/AppSettingsProfile/hooks/useSetTimeZone";
import TabLoader from "modules/AppSettings/components/TabLoader";
import { useTranslation } from "pay-kit";
import { PayKitForm, Tooltip } from "pay-kit";
import { ReactElement, useContext, useEffect } from "react";

import styles from "./appSettingsProfile.module.scss";

const AppSettingsProfile = () => {
	const { t, changeLanguage, currentLanguage } = useTranslation();
	const getTimezoneListAPI = useGetTimezoneList();
	const availableTimezonesList = getTimezoneListAPI.list || [];
	const getTimezoneAPI = useGetTimezone();
	const defaultTimezone = gettingTimezoneLabel(availableTimezonesList, getTimezoneAPI.timezone);
	const timeZoneOptions = timeZoneLIstFormatter(availableTimezonesList);
	const setTimeZoneAPI = useSetTimeZone();

	const { hasRole } = useContext(AuthContext);
	const timezoneAccess = hasRole(Roles.TIMEZONE_GET);
	const timezoneUpdateAccess = hasRole(Roles.TIMEZONE_SET);

	useEffect(() => {
		if (timezoneAccess) {
			getTimezoneAPI.load();
		}
		getTimezoneListAPI.load();
	}, [timezoneAccess]);

	const onSubmit: OnSubmitType = async (state) => {
		await setTimeZoneAPI.update(state.timeZone);

		if (currentLanguage !== state.lang) {
			changeLanguage(state.lang);
		}
	};

	const TIME_ZONE_HINT =
		"The time zone defines the time stamp of transactions in the system. It is recommended to select the time zone of the country in which transactions are made";

	const SCHEMA = [
		{
			type: "Group",
			render: (element: ReactElement) => (
				<div className={styles.appSettingsProfile}>
					<div className={styles.top}>
						{t("Language")} {t(`/ Timezone`)}
					</div>
					<div className={styles.selectsContainer}>
						<div className={styles.selects}>{element}</div>
					</div>
				</div>
			),
			elements: [
				{
					name: "lang",
					type: "Select",
					label: t("Language"),
					options: [
						// { value: `ru`, label: "Русский" },
						{ value: `en`, label: "English" },
					],
				},
				{
					name: "timeZone",
					type: "Select",
					label: (
						<div className={styles.timeZoneLabel}>
							{t("Timezone")}{" "}
							<Tooltip tip={t(TIME_ZONE_HINT)}>
								<QuestionMark />
							</Tooltip>
						</div>
					),
					options: timeZoneOptions,
					isLoading: getTimezoneListAPI.isLoading || setTimeZoneAPI.isLoading || getTimezoneAPI.isLoading,
					disabled: !timezoneUpdateAccess,
					existsIf: timezoneAccess,
				},
			],
		},
		{
			type: "Group",
			render: (element: ReactElement) => <div className={styles.submitButton}>{element}</div>,
			elements: [
				{
					name: "submit",
					type: "SubmitButton",
					label: t(`Save changes`),
					onSubmit: onSubmit,
					isLoading: setTimeZoneAPI.isLoading,
				},
			],
		},
	];

	if (!getTimezoneListAPI.list) return <TabLoader />;

	const INIT_STATE = {
		timeZone: getTimezoneAPI.timezone || defaultTimezone?.value,
		lang: currentLanguage,
	};

	return (
		<div className={styles.appSettingsProfileWrapper}>
			<PayKitForm.Builder
				schema={SCHEMA}
				initialState={INIT_STATE}
				/*
					The key prop is a hack
					to simply reinitialize the form
					each time a new timezone value
					is received from the server.
				*/
				key={getTimezoneAPI.timezone}
			/>
		</div>
	);
};

export default AppSettingsProfile;

type OnSubmitType = (state: { readonly timeZone: string; readonly lang: string }) => void;
