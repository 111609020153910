// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Currency__currencySelect-g8Y{width:304px}.Currency__currencySelect-g8Y input{visibility:visible}.Currency__placeholderElement-PzH{display:flex;justify-content:center;align-items:center;height:37px;width:304px}`, "",{"version":3,"sources":["webpack://./src/modules/TransferHistory/components/TransferringMoney/components/Currency/currency.module.scss"],"names":[],"mappings":"AAAA,8BACC,WAAA,CACA,oCACC,kBAAA,CAIF,kCACC,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,WAAA,CACA,WAAA","sourcesContent":[".currencySelect {\n\twidth: 304px;\n\t:global(input) {\n\t\tvisibility: visible;\n\t}\n}\n\n.placeholderElement {\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\theight: 37px;\n\twidth: 304px\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"currencySelect": `Currency__currencySelect-g8Y`,
	"placeholderElement": `Currency__placeholderElement-PzH`
};
export default ___CSS_LOADER_EXPORT___;
