import WithdrawalsLogsList from "modules/WalletsDebugging/WithdrawalsLogsList";
import { useEffect } from "react";
import { useTranslation } from "pay-kit";

const WithdrawalsLogsListPage = () => {
	const {t} = useTranslation();
	useEffect(() => {
		document.title = t("Withdrawals logs");
	}, []);

	return <WithdrawalsLogsList />;
};

export default WithdrawalsLogsListPage;
