// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Skeleton__SkeletonRow-Mo3{display:flex;justify-content:stretch;align-items:center;padding:15px 20px;background:#fff;border-radius:8px;gap:20px;height:78px}.Skeleton__SkeletonRow-Mo3 .Skeleton__col-J4n{flex:1;font-weight:400;font-size:12px;line-height:18px;color:#697077;animation:Skeleton__opacityBlinking-F6_ 1s infinite}.Skeleton__SkeletonRow-Mo3 .Skeleton__col-J4n .Skeleton__fakeContent-ssC{background:rgba(0,0,0,.07);height:1em;border-radius:3px}@keyframes Skeleton__opacityBlinking-F6_{0%{opacity:1}50%{opacity:.4}}`, "",{"version":3,"sources":["webpack://./src/modules/WalletsLoad/components/WalletsLoadTable/Skeleton/Skeleton.module.scss"],"names":[],"mappings":"AAAA,2BACC,YAAA,CACA,uBAAA,CACA,kBAAA,CACA,iBAAA,CACA,eAAA,CACA,iBAAA,CACA,QAAA,CACA,WAAA,CAEA,8CACC,MAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,aAAA,CAEA,mDAAA,CAEA,yEACC,0BAAA,CACA,UAAA,CACA,iBAAA,CAKH,yCACC,GACC,SAAA,CAED,IACC,UAAA,CAAA","sourcesContent":[".SkeletonRow {\n\tdisplay: flex;\n\tjustify-content: stretch;\n\talign-items: center;\n\tpadding: 15px 20px;\n\tbackground: #FFFFFF;\n\tborder-radius: 8px;\n\tgap: 20px;\n\theight: 78px;\n\n\t.col {\n\t\tflex: 1;\n\t\tfont-weight: 400;\n\t\tfont-size: 12px;\n\t\tline-height: 18px;\n\t\t/* Blue_gray/70 */\n\t\tcolor: #697077;\n\n\t\tanimation: opacityBlinking 1s infinite;\n\n\t\t.fakeContent {\n\t\t\tbackground: rgba(0, 0, 0, 0.07);\n\t\t\theight: 1em;\n\t\t\tborder-radius: 3px;\n\t\t}\n\t}\n}\n\n@keyframes opacityBlinking {\n\t0% {\n\t\topacity: 1;\n\t}\n\t50% {\n\t\topacity: 0.4;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SkeletonRow": `Skeleton__SkeletonRow-Mo3`,
	"col": `Skeleton__col-J4n`,
	"opacityBlinking": `Skeleton__opacityBlinking-F6_`,
	"fakeContent": `Skeleton__fakeContent-ssC`
};
export default ___CSS_LOADER_EXPORT___;
