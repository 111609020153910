import DashboardContextProvider, { useDashboardContext } from "contexts/DashboardContext";
import DashboardTransactionsMetrics from "modules/Dashboard/components/DashboardTransactionsMetrics";
import WalletsTotalBalance from "modules/Dashboard/components/WalletsTotalBalance";
import { getDashboardFiltersInitPrams } from "modules/Dashboard/helpers";
import { Loader } from "pay-kit";
import { useEffect } from "react";

import styles from "./dashboard.module.scss";

const Dashboard = () => {
	const {
		updateTransactionMetrics,
		transactionRestMetrics
	} = useDashboardContext();

	useEffect(() => {
		const args = getDashboardFiltersInitPrams();
		updateTransactionMetrics(args);
	}, []);

	if (!transactionRestMetrics) return (
		<div className={styles.dashboardLoaderWrapper}>
			<Loader color={`#697077`} />
		</div>
	);

	return (
		<div className={styles.dashboardMetricsContainer}>

			<DashboardTransactionsMetrics />

			<WalletsTotalBalance
				psCount={transactionRestMetrics?.payment_systems_count}
				proxiesCount={transactionRestMetrics?.active_proxies_count}
			/>
		</div>
	);
};

export default () => (
	<DashboardContextProvider>
		<Dashboard />
	</DashboardContextProvider>
);