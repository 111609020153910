import styles from './TextArea.module.scss';

type TextAreaPropsType = {
	readonly name?: string;
	readonly value?: string;
	readonly onChange: (e: React.ChangeEvent) => void;
	readonly error?: string;
	readonly placeholder?: string;
}

const TextArea: React.FC<TextAreaPropsType> = ({ name, value, onChange, error, placeholder }) => {
	const classNames = [styles.wrapper];

	if (error !== undefined) {
		classNames.push(styles.hasError);
	}

	return (
		<div className={classNames.join(' ')}>
			<textarea
				placeholder={placeholder}
				className={styles.textarea}
				name={name}
				value={value}
				onChange={onChange}
				data-test-id=""
			/>
			<div className={styles.error}>{error}</div>
		</div>
	);
};

export default TextArea;
