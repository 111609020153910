// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WithdrawalsPage__panel-Lcs{padding:48px 0}.WithdrawalsPage__panel-Lcs .WithdrawalsPage__panel__title-Btl{font-size:24px;font-weight:500;margin-bottom:48px}`, "",{"version":3,"sources":["webpack://./src/layout/pages/WithdrawalsPage/styles.module.scss"],"names":[],"mappings":"AAAA,4BACI,cAAA,CAEA,+DACI,cAAA,CACA,eAAA,CACA,kBAAA","sourcesContent":[".panel {\n    padding: 48px 0;\n\n    .panel__title {\n        font-size: 24px;\n        font-weight: 500;\n        margin-bottom: 48px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": `WithdrawalsPage__panel-Lcs`,
	"panel__title": `WithdrawalsPage__panel__title-Btl`
};
export default ___CSS_LOADER_EXPORT___;
