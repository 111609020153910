import React from "react";

import styles from "./Money.module.scss";

interface IMoney {
	readonly amount: number;
	readonly direction?: "ingoing" | "outgoing";
	readonly currencyCode?: string;
	readonly className?: string;
	readonly withSign?: boolean;
	readonly maxFractionDigits?: number;
	readonly minFractionDigits?: number;
	readonly dontFormat?: boolean;
	readonly dataTestId?: string;
}

const Money: React.FC<IMoney> = ({
	amount,
	direction,
	currencyCode,
	className,
	withSign = true,
	maxFractionDigits = 2,
	minFractionDigits = 2,
	dontFormat = false,
	dataTestId
}) => {
	if (amount === null) {
		return null;
	}

	const classNames = [styles.Money, className];
	let directionSymbol = "";

	if (direction === "ingoing") {
		classNames.push(styles.ingoing);
		directionSymbol = "+";
	}

	if (direction === "outgoing") {
		classNames.push(styles.outgoing);
		directionSymbol = "-";
	}

	return (
		<div className={classNames.join(" ")} data-test-id={dataTestId}>
			{withSign && directionSymbol}
			{
				dontFormat ?
					amount :
					Intl.NumberFormat("en", {
						maximumFractionDigits: maxFractionDigits,
						minimumFractionDigits: minFractionDigits
					}).format(amount)
			}
			{currencyCode && ` ${currencyCode}`}
		</div>
	);
};

export default Money;
