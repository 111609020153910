import Paykassma from "assets/icons/paykassma.svg";
import { FC } from "react";

import styles from "./paykassma.module.scss";

const PaykassmaIcon: FC<PaykassmaIconType> = ({ className }) => {

	return (
		<div className={[styles.paykassma, className].join(" ")}>
			<Paykassma />
		</div>
	);
};

export default PaykassmaIcon;

type PaykassmaIconType = {
	readonly className?: string
}