import { useTranslation } from "pay-kit";
import PageTitle from "components/PageTitle";
import { Roles, useHasAccessHook } from "contexts/AuthContext";
import Proxies from "modules/Proxies";

const ProxiesPage = () => {
	useHasAccessHook({ rule: Roles.PROXY_LIST, redirect: true });
	const {t} = useTranslation();

	return (
		<>
			<PageTitle title={t("Proxy")} />
			<Proxies />
		</>
	);
};

export default ProxiesPage;
