// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HelpToolTip__helpToolTipIcon-Nrp{position:absolute;display:block;right:0;top:50%;transform:translate(calc(100% + 8px), -50%)}.HelpToolTip__helpToolTipBody-VgT h4{font-weight:600;font-size:14px;line-height:20px;color:#fff;margin:0 0 4px 0;padding:0}.HelpToolTip__helpToolTipBody-VgT p{font-weight:400;font-size:14px;line-height:20px;letter-spacing:.01em;color:#c5cbd1;margin:0 0 10px 0}`, "",{"version":3,"sources":["webpack://./src/modules/Transactions/CurrentTransactions/CreateNewTransaction/HelpToolTip/HelpToolTip.module.scss"],"names":[],"mappings":"AAAA,kCACI,iBAAA,CACA,aAAA,CACA,OAAA,CACA,OAAA,CACA,2CAAA,CAIA,qCACI,eAAA,CACA,cAAA,CACA,gBAAA,CAGA,UAAA,CACA,gBAAA,CACA,SAAA,CAGJ,oCACI,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,oBAAA,CAEA,aAAA,CACA,iBAAA","sourcesContent":[".helpToolTipIcon {\n    position: absolute;\n    display: block;\n    right: 0;\n    top: 50%;\n    transform: translate(calc(100% + 8px), -50%);\n}\n\n.helpToolTipBody {\n    h4 {\n        font-weight: 600;\n        font-size: 14px;\n        line-height: 20px;\n        /* identical to box height, or 143% */\n        /* White/100 */\n        color: #ffffff;\n        margin: 0 0 4px 0;\n        padding: 0;\n    }\n\n    p {\n        font-weight: 400;\n        font-size: 14px;\n        line-height: 20px;\n        /* or 143% */\n        letter-spacing: 0.01em;\n        /* Blue_gray/40 */\n        color: #c5cbd1;\n        margin: 0 0 10px 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpToolTipIcon": `HelpToolTip__helpToolTipIcon-Nrp`,
	"helpToolTipBody": `HelpToolTip__helpToolTipBody-VgT`
};
export default ___CSS_LOADER_EXPORT___;
