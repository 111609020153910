// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextWithCopyButton__TextWithCopyButton-L2I{display:flex;gap:8px}.TextWithCopyButton__TextWithCopyButton-L2I .TextWithCopyButton__textWrapper-K2V{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;direction:rtl}.TextWithCopyButton__TextWithCopyButton-L2I .TextWithCopyButton__textWrapper-K2V .TextWithCopyButton__text-xve{unicode-bidi:isolate;direction:ltr}`, "",{"version":3,"sources":["webpack://./src/components/TextWithCopyButton/TextWithCopyButton.module.scss"],"names":[],"mappings":"AAAA,4CACC,YAAA,CACA,OAAA,CAEA,iFACC,eAAA,CACA,sBAAA,CACA,kBAAA,CACA,aAAA,CAEA,+GACC,oBAAA,CACA,aAAA","sourcesContent":[".TextWithCopyButton {\n\tdisplay: flex;\n\tgap: 8px;\n\n\t.textWrapper {\n\t\toverflow: hidden;\n\t\ttext-overflow: ellipsis;\n\t\twhite-space: nowrap;\n\t\tdirection: rtl;\n\n\t\t.text {\n\t\t\tunicode-bidi: isolate;\n\t\t\tdirection: ltr;\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TextWithCopyButton": `TextWithCopyButton__TextWithCopyButton-L2I`,
	"textWrapper": `TextWithCopyButton__textWrapper-K2V`,
	"text": `TextWithCopyButton__text-xve`
};
export default ___CSS_LOADER_EXPORT___;
