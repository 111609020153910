import API from "api";
import { useTranslation } from "pay-kit";
import { Button, Modal, Textarea } from "pay-kit";
import React, { useEffect, useState } from "react";
import { errorsMap } from "utils/enums";

import styles from "./RejectModal.module.scss";

interface IRejectModal {
	readonly id: number | undefined;
	readonly onClose: () => void;
	readonly refreshHandler?: () => void;
}

const RejectModal: React.FC<IRejectModal> = ({ id, onClose, refreshHandler }) => {
	const {t} = useTranslation();
	const [comment, setComment] = useState<string>("");
	const canSubmit = comment !== "";

	useEffect(() => {
		setComment("");
	}, [id]);

	const sendRejectRequest = () => {
		if (id === undefined || !canSubmit)
			return;

		API.withdrawal.reject(id, comment).then((res: any) => {
			if (res.status === "ok") {
				window.pushAlert({
					// description: `Вывод отклонен`,
					description: t("Withdrawal rejected"),
					type: "success"
				});

				onClose();
				refreshHandler && refreshHandler();

				return res;
			} else {
				window.pushAlert({
					// description: `Произошла ошибка при попытке отклонить вывод`,
					description: t("Failed to reject withdrawal"),
					type: "error"
				});

				// throw new Error("Invalid response in withdrawal reject");
				throw new Error(errorsMap.anyResponse);
			}
		}).catch((err) => {
			console.error(err);
		});
	};

	return (
		<Modal
			isOpen={id !== undefined}
			title={t("Withdrawal rejection")}
			onClose={onClose}
		>
			<div className={styles.form}>
				<Textarea
					label={t("Comment")}
					id="comment"
					value={comment}
					onChange={(v) => setComment(v.target.value as string)}
					data-test-id=""
				/>

				<div className={styles.actions}>
					<Button
						disabled={!canSubmit}
						data-test-id=""
						onClick={sendRejectRequest}
					>
						{t("Reject")}					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default RejectModal;
