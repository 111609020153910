import { useTranslation } from "pay-kit";
import WalletsLoad from "modules/WalletsLoad";
import PageTitle from "src/components/PageTitle";

import styles from "./styles.module.scss";

export default function WalletsLoadPage() {
	const {t} = useTranslation();
	return (
		<div className={styles.panel}>
			<PageTitle title={t("Wallets' load")} />
			<WalletsLoad />
		</div>
	);
}
