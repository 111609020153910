// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Translations__filter-BPX{display:flex;gap:38px;margin-top:36px}.Translations__filter-BPX .Translations__select-khX{width:250px}.Translations__filter-BPX .Translations__translationTypeSwitcher-vyR>div{width:270px}.Translations__filter-BPX .Translations__translationTypeSwitcher-vyR>div>button{width:50%}.Translations__paymentSystemOptionLabel-Ka5{display:flex;gap:12px}.Translations__paymentSystemOptionLabel-Ka5 span{text-overflow:ellipsis;white-space:pre}.Translations__paymentSystemOptionLabel-Ka5 .Translations__logo-LIt.Translations__logo-LIt{height:20px;width:60px}`, "",{"version":3,"sources":["webpack://./src/modules/PaymentSystems/Translations/Translations.module.scss"],"names":[],"mappings":"AAAA,0BACI,YAAA,CACA,QAAA,CACA,eAAA,CAEA,oDACI,WAAA,CAGJ,yEACI,WAAA,CACA,gFACI,SAAA,CAKZ,4CACI,YAAA,CACA,QAAA,CAEA,iDACI,sBAAA,CACA,eAAA,CAGJ,2FACI,WAAA,CACA,UAAA","sourcesContent":[".filter {\n    display: flex;\n    gap: 38px;\n    margin-top: 36px;\n\n    .select {\n        width: 250px;\n    }\n\n    .translationTypeSwitcher > div {\n        width: 270px;\n        > button {\n            width: 50%;\n        }\n    }\n}\n\n.paymentSystemOptionLabel {\n    display: flex;\n    gap: 12px;\n\n    span {\n        text-overflow: ellipsis;\n        white-space: pre;\n    }\n\n    .logo.logo {\n        height: 20px;\n        width: 60px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter": `Translations__filter-BPX`,
	"select": `Translations__select-khX`,
	"translationTypeSwitcher": `Translations__translationTypeSwitcher-vyR`,
	"paymentSystemOptionLabel": `Translations__paymentSystemOptionLabel-Ka5`,
	"logo": `Translations__logo-LIt`
};
export default ___CSS_LOADER_EXPORT___;
