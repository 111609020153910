import {AlertsContext} from "contexts/AlertsProvider";
import {useContext, useEffect} from "react";
import { errorsMap } from "utils/enums";

export const PAYKASSMA_ERROR_MSG = `PAYKASSMA_ERROR_MSG`;

export default function ErrorPage() {
	const errorMessage = localStorage.getItem(PAYKASSMA_ERROR_MSG) || ``;
	//@ts-ignore
	const { alerts: { push } } = useContext(AlertsContext);

	useEffect(() => {
		if (push && errorMessage) {
			push({
				title: errorsMap.any,
				type: "error",
				description: errorMessage,
				pinned: true,
			});
			localStorage.removeItem(PAYKASSMA_ERROR_MSG);
		}
	}, [push, errorMessage]);

	return (
		<>
			<div>{errorsMap.any}</div>
		</>
	);
}
