// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProxyManipulatingButtons__UploadedProxiesActions-akY{gap:25px;margin:0 8px}`, "",{"version":3,"sources":["webpack://./src/modules/Proxies/components/UploadedProxiesListTable/components/ProxyManipulatingButtons/proxyManipulatingButtons.module.scss"],"names":[],"mappings":"AAAA,sDACC,QAAA,CACA,YAAA","sourcesContent":[".UploadedProxiesActions {\n\tgap: 25px;\n\tmargin: 0 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UploadedProxiesActions": `ProxyManipulatingButtons__UploadedProxiesActions-akY`
};
export default ___CSS_LOADER_EXPORT___;
