// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Crediting__creditTextInput-X1N .Crediting__inputContainer-TAe>div{width:304px}`, "",{"version":3,"sources":["webpack://./src/modules/TransferHistory/components/TransferringMoney/components/Crediting/crediting.module.scss"],"names":[],"mappings":"AACC,mEACC,WAAA","sourcesContent":[".creditTextInput {\n\t.inputContainer > div {\n\t\twidth: 304px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"creditTextInput": `Crediting__creditTextInput-X1N`,
	"inputContainer": `Crediting__inputContainer-TAe`
};
export default ___CSS_LOADER_EXPORT___;
