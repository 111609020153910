import { useTranslation } from "pay-kit";
import CaretIcon from "components/Icons/CaretIcon";
import {
	ReducedWalletsSummaryKeyListType,
	reducedWalletsSummaryType
} from "modules/Dashboard/components/WalletsTotalBalance";
import WalletSummary from "modules/Dashboard/components/WalletsTotalBalance/components/WalletSummary";
import { Loader, useOutsideClick } from "pay-kit";
import { FC, useRef, useState } from "react";

import styles from "./workingWallets.module.scss";

const WorkingWallets: FC<WorkingWalletsType> = ({
	reducedWalletsSummary,
	reducedWalletsSummaryKeyList,
	allWalletsCount,
	workingWalletsCount,
	isLoading
}) => {
	const {t} = useTranslation();
	const [openWalletsSummary, setOpenWalletsSummary] = useState(false);

	const walletListRef = useRef(null);
	useOutsideClick(walletListRef, () => setOpenWalletsSummary(false));

	const caretStyle = openWalletsSummary ? styles.caretIcon : undefined;

	return (
		<div
			className={styles.workingWalletsWrapper}
			ref={walletListRef}
		>
			<div className={styles.workingWallets}>
				{
					isLoading ?
						<div className={styles.workingWalletsLoader}>
							<Loader color={`#697077`} />
						</div> :
						<>
							<div
								data-test-id="nDf7rqwtyvg8UtZAFlFK5"
								className={styles.walletsListLabel}
								onClick={() => setOpenWalletsSummary(open => !open)}
							>
								{t("My wallets")}								<div className={styles.walletsCount}>
									{workingWalletsCount}
									<span>
									({allWalletsCount})
									</span>
									<CaretIcon className={caretStyle} />
								</div>
							</div>
						</>
				}
			</div>
			{openWalletsSummary &&
				<div className={styles.walletsList}>
					<WalletSummary
						reducedWalletsSummary={reducedWalletsSummary}
						reducedWalletsSummaryKeyList={reducedWalletsSummaryKeyList}
					/>
				</div>
			}
		</div>
	);
};

export default WorkingWallets;

type WorkingWalletsType = {
	readonly reducedWalletsSummary: reducedWalletsSummaryType | null
	readonly reducedWalletsSummaryKeyList: ReducedWalletsSummaryKeyListType | null
	readonly allWalletsCount: number | null
	readonly workingWalletsCount: number | null
	readonly isLoading: boolean
}