import ArchivedWallets from "modules/Wallets/ArchivedWallets";
import { useTranslation } from "pay-kit";
import React, { useEffect } from "react";

const ArchivedWalletsPage: React.FC = () => {
	const { t } = useTranslation();
	useEffect(() => {
		document.title = t("Archived wallets");
	}, []);

	return <ArchivedWallets />;
};

export default ArchivedWalletsPage;
