import { UpdateSettingsDataType } from "api/walletTypesGroup";

export interface IActionsContextValue {
	readonly updateSettingsAPI: {
		readonly update: (data: UpdateSettingsDataType) => void;
		readonly isLoading: boolean;
	};
	readonly showTariffSettings: (wallet_type: string) => void;
	readonly showCommonSettings: (wallet_type: string) => void;
	readonly showCreateNewModal: () => void;
	readonly showCurrenciesSettings: (wallet_type: string) => void;
	readonly showEditingModal: (wallet_type: string) => void;
	readonly showLimitsSettings: (wallet_type: string) => void;
	readonly showRemoveConfirmation: (wallet_type: string) => void;
	readonly showViewSettings: (wallet_type: string) => void;
}

export type ActionsProviderPropsType = {
	readonly children: React.ReactNode;
};

export enum ModalTypesENUM {
	TariffSettings = "TariffSettings",
	CommonSettings = "CommonSettings",
	CreateNewModal = "CreateNewModal",
	CurrenciesSettings = "CurrenciesSettings",
	EditingModal = "EditingModal",
	LimitsSettings = "LimitsSettings",
	RemoveConfirmation = "RemoveConfirmation",
	ViewSettings = "ViewSettings",
}

export type ActiveModalStateType = null | {
	readonly modalType: ModalTypesENUM;
	readonly psId?: string;
}