// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RemoveConfirmation__wrapper-PKd .RemoveConfirmation__text-MEt{font-size:14px}.RemoveConfirmation__wrapper-PKd .RemoveConfirmation__actions-MKl{display:flex;justify-content:flex-end;gap:16px;margin-top:32px}`, "",{"version":3,"sources":["webpack://./src/modules/Reports/AutoReports/components/RemoveConfirmation/RemoveConfirmation.module.scss"],"names":[],"mappings":"AACI,+DACI,cAAA,CAGJ,kEACI,YAAA,CACA,wBAAA,CACA,QAAA,CACA,eAAA","sourcesContent":[".wrapper {\n    .text {\n        font-size: 14px;\n    }\n\n    .actions {\n        display: flex;\n        justify-content: flex-end;\n        gap: 16px;\n        margin-top: 32px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `RemoveConfirmation__wrapper-PKd`,
	"text": `RemoveConfirmation__text-MEt`,
	"actions": `RemoveConfirmation__actions-MKl`
};
export default ___CSS_LOADER_EXPORT___;
