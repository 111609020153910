// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PayLogo__PayLogo-o8Q{margin:auto;margin-top:16px}`, "",{"version":3,"sources":["webpack://./src/components/PayLogo/PayLogo.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,eAAA","sourcesContent":[".PayLogo {\n  margin: auto;\n  margin-top: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PayLogo": `PayLogo__PayLogo-o8Q`
};
export default ___CSS_LOADER_EXPORT___;
