// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LabelWithHint__labelWithHint-dYY{display:flex;justify-content:center;align-items:center;gap:8px;font-size:14px;font-weight:400}.LabelWithHint__labelWithHint-dYY .LabelWithHint__questionMark-Crw{display:flex;align-items:center;justify-content:center;width:14px;height:13px}.LabelWithHint__labelWithHint-dYY .LabelWithHint__questionMark-Crw>svg{line-height:0;width:100%;height:100%}`, "",{"version":3,"sources":["webpack://./src/modules/AppSettings/components/LabelWithHint/labelWithHint.module.scss"],"names":[],"mappings":"AAAA,kCACC,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,OAAA,CAEA,cAAA,CACA,eAAA,CAEA,mEACC,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,WAAA,CAEA,uEACC,aAAA,CACA,UAAA,CACA,WAAA","sourcesContent":[".labelWithHint {\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\tgap: 8px;\n\n\tfont-size: 14px;\n\tfont-weight: 400;\n\n\t.questionMark {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tjustify-content: center;\n\t\twidth: 14px;\n\t\theight: 13px;\n\n\t\t& > svg {\n\t\t\tline-height: 0;\n\t\t\twidth: 100%;\n\t\t\theight: 100%;\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labelWithHint": `LabelWithHint__labelWithHint-dYY`,
	"questionMark": `LabelWithHint__questionMark-Crw`
};
export default ___CSS_LOADER_EXPORT___;
