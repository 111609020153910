// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProxyActions__actionsCol-xwd{gap:25px;width:150px;padding-left:8px}`, "",{"version":3,"sources":["webpack://./src/modules/Proxies/components/ProxiesTableColumns/components/ProxyActions/proxyActions.module.scss"],"names":[],"mappings":"AAAA,8BACC,QAAA,CACA,WAAA,CACA,gBAAA","sourcesContent":[".actionsCol {\n\tgap: 25px;\n\twidth: 150px;\n\tpadding-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionsCol": `ProxyActions__actionsCol-xwd`
};
export default ___CSS_LOADER_EXPORT___;
