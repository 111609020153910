// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AlertsProvider__alertsWrapper-nN5{position:fixed;min-width:300px;left:50%;transform:translateX(-50%);top:50px;z-index:1}`, "",{"version":3,"sources":["webpack://./src/contexts/AlertsProvider/Alerts.module.scss"],"names":[],"mappings":"AAAA,mCACE,cAAA,CACA,eAAA,CACA,QAAA,CACA,0BAAA,CACA,QAAA,CACA,SAAA","sourcesContent":[".alertsWrapper {\n  position: fixed;\n  min-width: 300px;\n  left: 50%;\n  transform: translateX(-50%);\n  top: 50px;\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alertsWrapper": `AlertsProvider__alertsWrapper-nN5`
};
export default ___CSS_LOADER_EXPORT___;
