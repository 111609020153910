// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WalletsTotalBalance__walletsTotalBalanceWrapper-urG{position:relative;height:max-content;max-width:340px;width:100%}.WalletsTotalBalance__walletsTotalBalanceWrapper-urG .WalletsTotalBalance__totalBalanceContainer-MXi{border-radius:8px;background:#fff;height:max-content;padding:16px 0}.WalletsTotalBalance__walletsTotalBalanceWrapper-urG .WalletsTotalBalance__totalBalanceContainer-MXi .WalletsTotalBalance__metricsWrapper-Ysh{display:flex;gap:8px;margin:8px 24px 8px 24px}.WalletsTotalBalance__walletsTotalBalanceWrapper-urG .WalletsTotalBalance__totalBalanceContainer-MXi .WalletsTotalBalance__metricsWrapper-Ysh .WalletsTotalBalance__metric-Ou9{flex-grow:1}.WalletsTotalBalance__walletsTotalBalanceWrapper-urG .WalletsTotalBalance__totalBalanceContainer-MXi .WalletsTotalBalance__metricsWrapper-Ysh .WalletsTotalBalance__link-A52{cursor:pointer;margin-left:4px}`, "",{"version":3,"sources":["webpack://./src/modules/Dashboard/components/WalletsTotalBalance/walletsTotalBalance.module.scss"],"names":[],"mappings":"AAAA,qDACC,iBAAA,CACA,kBAAA,CACA,eAAA,CACA,UAAA,CAEA,qGACC,iBAAA,CACA,eAAA,CACA,kBAAA,CACA,cAAA,CAEA,8IACC,YAAA,CACA,OAAA,CACA,wBAAA,CAEA,+KACC,WAAA,CAGD,6KACC,cAAA,CACA,eAAA","sourcesContent":[".walletsTotalBalanceWrapper {\n\tposition: relative;\n\theight: max-content;\n\tmax-width: 340px;\n\twidth: 100%;\n\n\t.totalBalanceContainer {\n\t\tborder-radius: 8px;\n\t\tbackground: #FFF;\n\t\theight: max-content;\n\t\tpadding: 16px 0;\n\n\t\t.metricsWrapper {\n\t\t\tdisplay: flex;\n\t\t\tgap: 8px;\n\t\t\tmargin: 8px 24px 8px 24px;\n\n\t\t\t.metric {\n\t\t\t\tflex-grow: 1;\n\t\t\t}\n\n\t\t\t.link {\n\t\t\t\tcursor: pointer;\n\t\t\t\tmargin-left: 4px;\n\t\t\t}\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"walletsTotalBalanceWrapper": `WalletsTotalBalance__walletsTotalBalanceWrapper-urG`,
	"totalBalanceContainer": `WalletsTotalBalance__totalBalanceContainer-MXi`,
	"metricsWrapper": `WalletsTotalBalance__metricsWrapper-Ysh`,
	"metric": `WalletsTotalBalance__metric-Ou9`,
	"link": `WalletsTotalBalance__link-A52`
};
export default ___CSS_LOADER_EXPORT___;
