// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommissionReport__form-z4T{display:flex;flex-direction:column;padding:32px}.CommissionReport__form-z4T .CommissionReport__baseSettings-hDr{display:grid;grid-template-columns:450px;width:100%;column-gap:48px;row-gap:12px}.CommissionReport__form-z4T .CommissionReport__actions-GQf{position:sticky;bottom:0;left:0;right:0;background:#f2f3f5;display:flex;align-items:center;justify-content:flex-end;margin-top:auto;gap:48px}.CommissionReport__form-z4T .CommissionReport__actions-GQf .CommissionReport__formatSwitcher-FRB{display:flex;align-items:center;gap:16px}.CommissionReport__form-z4T .CommissionReport__actions-GQf .CommissionReport__formatSwitcher-FRB button{padding:0 16px}.CommissionReport__form-z4T .CommissionReport__actions-GQf>button:last-child{min-width:170px}`, "",{"version":3,"sources":["webpack://./src/modules/Reports/ReportList/components/ActionsProvider/modals/NewReport/forms/CommissionReport/CommissionReport.module.scss"],"names":[],"mappings":"AAAA,4BACC,YAAA,CACA,qBAAA,CACA,YAAA,CAEA,gEACC,YAAA,CACA,2BAAA,CACA,UAAA,CACA,eAAA,CACA,YAAA,CAGD,2DACC,eAAA,CACA,QAAA,CACA,MAAA,CACA,OAAA,CACA,kBAAA,CACA,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,eAAA,CACA,QAAA,CAEA,iGACC,YAAA,CACA,kBAAA,CACA,QAAA,CAEA,wGACC,cAAA,CAIF,6EACC,eAAA","sourcesContent":[".form {\n\tdisplay: flex;\n\tflex-direction: column;\n\tpadding: 32px;\n\n\t.baseSettings {\n\t\tdisplay: grid;\n\t\tgrid-template-columns: 450px;\n\t\twidth: 100%;\n\t\tcolumn-gap: 48px;\n\t\trow-gap: 12px;\n\t}\n\n\t.actions {\n\t\tposition: sticky;\n\t\tbottom: 0;\n\t\tleft: 0;\n\t\tright: 0;\n\t\tbackground: #F2F3F5;\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tjustify-content: flex-end;\n\t\tmargin-top: auto;\n\t\tgap: 48px;\n\n\t\t.formatSwitcher {\n\t\t\tdisplay: flex;\n\t\t\talign-items: center;\n\t\t\tgap: 16px;\n\n\t\t\tbutton {\n\t\t\t\tpadding: 0 16px;\n\t\t\t}\n\t\t}\n\n\t\t> button:last-child {\n\t\t\tmin-width: 170px;\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `CommissionReport__form-z4T`,
	"baseSettings": `CommissionReport__baseSettings-hDr`,
	"actions": `CommissionReport__actions-GQf`,
	"formatSwitcher": `CommissionReport__formatSwitcher-FRB`
};
export default ___CSS_LOADER_EXPORT___;
