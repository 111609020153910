import { UpdateSettingsType } from "api";
import AuthContext from "contexts/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";
import PaymentMethodBlockErrors from "modules/AppSettings/AppSettingsSystem/components/PaymentMethodBlockErrors";
import RemovableFields from "modules/AppSettings/AppSettingsSystem/components/RemovableFields/RemovableFields";
import SettingsBlockWrapper from "modules/AppSettings/AppSettingsSystem/components/SettingsBlockWrapper/SettingsBlockWrapper";
import useGetSettingsParams from "modules/AppSettings/AppSettingsSystem/hook/useGetSettingsParams";
import useUpdateSettings from "modules/AppSettings/AppSettingsSystem/hook/useUpdateSettings";
import {
	getInitState,
	transactionsHints,
	transactionsTips,
} from "modules/AppSettings/AppSettingsSystem/utils/constants";
import {
	getUniqUPIOptions,
	isMinValueRequired,
	isRequired,
	isUnique,
	isValidUrl,
} from "modules/AppSettings/AppSettingsSystem/utils/helpers";
import FieldsBuilderButton from "modules/AppSettings/components/FieldsBuilderButton";
import LabelWithHint from "modules/AppSettings/components/LabelWithHint";
import TabLoader from "modules/AppSettings/components/TabLoader";
import { useTranslation } from "pay-kit";
import { PayKitForm } from "pay-kit";
import { ReactElement, useContext, useEffect, useState } from "react";

import styles from "./appSettingSystem.module.scss";

const AppSettingSystem = () => {
	const [isUpiError, setIsUpiError] = useState(false);
	const getSettingsParamsAPI = useGetSettingsParams();
	const updateSettingsAPI = useUpdateSettings();
	const { t } = useTranslation();

	const { hasRole } = useContext(AuthContext);
	const paramsAccess = hasRole(Roles.VIEW_SYSTEM_SETTINGS);

	useEffect(() => {
		if (paramsAccess) {
			getSettingsParamsAPI.load();
		}
	}, [paramsAccess]);

	const onSubmit = (state: UpdateSettingsType) => updateSettingsAPI.update(state);

	const SCHEMA = [
		{
			type: "Group",
			render: (element: readonly ReactElement[], { formDataAPI }: RenderRestType) => (
				<SettingsBlockWrapper
					header={t(`Displaying payment methods on the plugin in UPI wallet types`)}
					classes={{
						blockWrapper: styles.settlementBlockWrapper,
						header: styles.header,
						fieldsWrapper: styles.settlementFieldsWrapper,
					}}
					blockErrorFeedback={
						<PaymentMethodBlockErrors
							formState={formDataAPI.formState}
							checkError={(isError) => setIsUpiError(isError)}
						/>
					}
				>
					{element}
				</SettingsBlockWrapper>
			),
			elements: [
				{
					type: "Repeat",
					name: "upi_p2p_payment_methods",
					render: (children: readonly ReactElement[], { insertItem, error, formDataAPI }: RenderRestType) => {
						const insert = formDataAPI.formState?.upi_p2p_payment_methods?.length === 3 ? () => {} : insertItem;

						return (
							<FieldsBuilderButton
								data-test-id="selectBuilderButton"
								header="UPI P2P"
								classes={{
									wrapper: styles.addButtonWrapper,
									paymentHeader: styles.paymentMethodHeader,
								}}
								insertItem={insert}
								error={error}
							>
								{children}
							</FieldsBuilderButton>
						);
					},
					elements: [
						{
							type: "Group",
							render: (children: readonly ReactElement[], { removeCurrentItem, formDataAPI }: RenderRestType) => {
								const remove =
									formDataAPI.formState?.upi_p2p_payment_methods?.length === 1
										? () => formDataAPI.setValue("upi_p2p_payment_methods", [{}])
										: removeCurrentItem;

								return (
									<RemovableFields
										classes={{
											fieldsWrapper: styles.codeFieldsWrapper,
											removeButton: styles.removeButton,
											deleteFieldIcon: styles.deleteSelect,
										}}
										remove={remove}
									>
										{children}
									</RemovableFields>
								);
							},
							elements: [
								{
									type: "Group",
									render: (element: readonly ReactElement[]) => <div className={styles.codeFieldsGroup}>{element}</div>,
									elements: [
										{
											name: "order",
											type: "TextInput",
											label: "ID",
											className: styles.ID,
											disabled: true,
										},
										{
											name: "code",
											type: "Select",
											placeholder: t(`Choose`),
											options: getUniqUPIOptions({ upiKey: "upi_p2p_payment_methods" }),
											className: styles.selectWrapper,
											isLoading: updateSettingsAPI.isLoading,
											validation: [isRequired, isUnique("upi_p2p_payment_methods", "UPI P2P")],
										},
									],
								},
							],
						},
					],
				},
				{
					type: "Repeat",
					name: "upi_api_payment_methods",
					render: (children: readonly ReactElement[], { insertItem, formDataAPI, error }: RenderRestType) => {
						const insert = formDataAPI.formState?.upi_api_payment_methods?.length === 2 ? () => {} : insertItem;

						return (
							<FieldsBuilderButton
								data-test-id="selectBuilderButton"
								header="UPI API"
								classes={{
									wrapper: styles.addButtonWrapper,
									paymentHeader: styles.paymentMethodHeader,
								}}
								insertItem={insert}
								error={error}
							>
								{children}
							</FieldsBuilderButton>
						);
					},
					elements: [
						{
							type: "Group",
							render: (
								children: readonly ReactElement[],
								{ removeCurrentItem, formDataAPI, index }: RenderRestType
							) => {
								const remove =
									formDataAPI.formState?.upi_api_payment_methods?.length === 1
										? () => formDataAPI.setValue("upi_api_payment_methods", [{}])
										: removeCurrentItem;

								return (
									<RemovableFields
										classes={{
											fieldsWrapper: styles.codeFieldsWrapper,
											removeButton: styles.removeButton,
											deleteFieldIcon: styles.deleteSelect,
										}}
										remove={remove}
									>
										{children}
									</RemovableFields>
								);
							},
							elements: [
								{
									type: "Group",
									render: (element: readonly ReactElement[]) => <div className={styles.codeFieldsGroup}>{element}</div>,
									elements: [
										{
											name: "order",
											type: "TextInput",
											label: "ID",
											className: styles.ID,
											disabled: true,
										},
										{
											name: "code",
											type: "Select",
											placeholder: t(`Choose`),
											options: getUniqUPIOptions({ upiKey: "upi_api_payment_methods", isUPI_API: true }),
											className: styles.selectWrapper,
											isLoading: updateSettingsAPI.isLoading,
											validation: [isRequired, isUnique("upi_api_payment_methods", "UPI API")],
										},
									],
								},
							],
						},
					],
				},
			],
		},
		{
			type: "Group",
			render: (element: readonly ReactElement[]) => (
				<SettingsBlockWrapper
					header={t("Plugin")}
					classes={{
						blockWrapper: styles.commonBlockWrapper,
						header: styles.commonHeader,
						fieldsWrapper: styles.commonFieldsWrapper,
					}}
				>
					{element}
				</SettingsBlockWrapper>
			),
			elements: [
				{
					type: "Group",
					render: (element: ReactElement) => (
						<div className={styles.useUrlFromSettings}>
							{element}
							{t(`Display of the "Report a problem" feature`)}
						</div>
					),
					elements: [
						{
							type: "Toggler",
							name: "display_report_problem",
						},
					],
				},
			],
		},
		{
			type: "Group",
			render: (element: readonly ReactElement[]) => (
				<SettingsBlockWrapper
					header={t("Deposit")}
					classes={{
						blockWrapper: styles.commonBlockWrapper,
						header: styles.commonHeader,
						fieldsWrapper: styles.commonFieldsWrapper,
					}}
				>
					{element}
				</SettingsBlockWrapper>
			),
			elements: [
				{
					type: "Group",
					render: (element: ReactElement) => (
						<div className={styles.useUrlFromSettings}>
							{element}
							{t(`Use redirect URL from settings`)}
						</div>
					),
					elements: [
						{
							type: "Toggler",
							name: "use_url_from_settings",
						},
					],
				},
				{
					type: "Repeat",
					name: "succeeded_urls",
					render: (children: readonly ReactElement[], { insertItem, error }: RenderRestType) => (
						<FieldsBuilderButton
							data-test-id="selectBuilderButton"
							header={t(`Redirect URL on successful payment`)}
							classes={{
								wrapper: styles.addFieldsWrapper,
								paymentHeader: styles.redirectUrlHeader,
							}}
							insertItem={insertItem}
							error={error}
						>
							{children}
						</FieldsBuilderButton>
					),
					elements: [
						{
							type: "Group",
							render: (
								children: readonly ReactElement[],
								{
									removeCurrentItem,
								}: {
									readonly removeCurrentItem: <T>(e: T) => void;
								}
							) => (
								<RemovableFields
									classes={{
										fieldsWrapper: styles.dynamicField,
										removeButton: styles.removeButton,
										deleteFieldIcon: styles.deleteSelect,
									}}
									remove={removeCurrentItem}
								>
									{children}
								</RemovableFields>
							),
							elements: [
								{
									type: "Group",
									elements: [
										{
											name: "id",
											type: "TextInput",
											label: "ID",
											className: styles.ID,
											disabled: true,
										},
									],
								},
								{
									type: "Group",
									elements: [
										{
											name: "url",
											type: "TextInput",
											label: t("Url"),
											className: styles.link,
											validation: [isRequired, isValidUrl],
										},
									],
								},
							],
						},
					],
				},
				{
					type: "Repeat",
					name: "pending_urls",
					render: (children: readonly ReactElement[], { insertItem, error }: RenderRestType) => (
						<FieldsBuilderButton
							header={t(`Redirect URL on pending payment`)}
							classes={{
								wrapper: styles.addFieldsWrapper,
								paymentHeader: styles.redirectUrlHeader,
							}}
							insertItem={insertItem}
							error={error}
						>
							{children}
						</FieldsBuilderButton>
					),
					elements: [
						{
							type: "Group",
							render: (children: readonly ReactElement[], { removeCurrentItem }: RenderRestType) => (
								<RemovableFields
									classes={{
										fieldsWrapper: styles.dynamicField,
										removeButton: styles.removeButton,
										deleteFieldIcon: styles.deleteSelect,
									}}
									remove={removeCurrentItem}
								>
									{children}
								</RemovableFields>
							),
							elements: [
								{
									type: "Group",
									elements: [
										{
											name: "id",
											type: "TextInput",
											label: "ID",
											className: styles.ID,
											disabled: true,
										},
									],
								},
								{
									type: "Group",
									elements: [
										{
											name: "url",
											type: "TextInput",
											label: t("Url"),
											className: styles.link,
											validation: [isRequired, isValidUrl],
										},
									],
								},
							],
						},
					],
				},
				{
					type: "Repeat",
					name: "failed_urls",
					render: (children: readonly ReactElement[], { insertItem, error }: RenderRestType) => (
						<FieldsBuilderButton
							data-test-id="selectBuilderButton"
							header={t(`Redirect URL on failure payment`)}
							classes={{
								wrapper: styles.addFieldsWrapper,
								paymentHeader: styles.redirectUrlHeader,
							}}
							insertItem={insertItem}
							error={error}
						>
							{children}
						</FieldsBuilderButton>
					),
					elements: [
						{
							type: "Group",
							render: (children: readonly ReactElement[], { removeCurrentItem }: RenderRestType) => (
								<RemovableFields
									classes={{
										fieldsWrapper: styles.dynamicField,
										removeButton: styles.removeButton,
										deleteFieldIcon: styles.deleteSelect,
									}}
									remove={removeCurrentItem}
								>
									{children}
								</RemovableFields>
							),
							elements: [
								{
									type: "Group",
									elements: [
										{
											name: "id",
											type: "TextInput",
											label: "ID",
											className: styles.ID,
											disabled: true,
										},
									],
								},
								{
									type: "Group",
									elements: [
										{
											name: "url",
											type: "TextInput",
											label: t("Url"),
											className: styles.link,
											validation: [isRequired, isValidUrl],
										},
									],
								},
							],
						},
					],
				},
				{
					type: "Group",
					render: (element: ReactElement) => <div className={styles.transactionFieldsGroup}>{element}</div>,
					elements: [
						{
							name: "transaction_reactivation_timer",
							type: "TextInput",
							label: (
								<LabelWithHint
									className={styles.hint}
									text={transactionsHints.TRANSACTION_REACTIVATION_TIMER}
									tip={transactionsTips.TRANSACTION_REACTIVATION_TIMER}
								/>
							),
							className: styles.transactionField,
							validation: [isRequired],
						},
						{
							name: "transaction_recheck_timer",
							type: "TextInput",
							label: (
								<LabelWithHint
									className={styles.hint}
									text={transactionsHints.TRANSACTION_RECHECK_TIMER}
									tip={transactionsTips.TRANSACTION_RECHECK_TIMER}
								/>
							),
							className: styles.transactionField,
							validation: [isRequired, isMinValueRequired],
						},
						{
							name: "del_possible_trans_after_days",
							type: "TextInput",
							label: (
								<LabelWithHint
									className={styles.hint}
									text={transactionsHints.DEL_POSSIBLE_TRANSFER_AFTER_DAYS}
									tip={transactionsTips.DEL_POSSIBLE_TRANSFER_AFTER_DAYS}
								/>
							),
							className: styles.transactionField,
							validation: [isRequired],
						},
					],
				},
			],
		},
		{
			type: "Group",
			render: (element: readonly ReactElement[]) => (
				<SettingsBlockWrapper
					header={t("Withdrawal requests")}
					classes={{
						blockWrapper: styles.commonBlockWrapper,
						header: styles.commonHeader,
						fieldsWrapper: styles.commonFieldsWrapper,
					}}
				>
					{element}
				</SettingsBlockWrapper>
			),
			elements: [
				{
					type: "Group",
					render: (element: ReactElement) => (
						<div className={styles.useUrlFromSettings}>
							{element}
							{t("Restrict withdrawal requests of insufficient balance")}{" "}
						</div>
					),
					elements: [
						{
							type: "Toggler",
							name: "restrict_withdrawal_requests",
						},
					],
				},
			],
		},
		{
			type: "Group",
			render: (element: ReactElement) => <div className={styles.submitButton}>{element}</div>,
			elements: [
				{
					name: "submit",
					type: "SubmitButton",
					label: t(`Save changes`),
					onSubmit: onSubmit,
					isLoading: updateSettingsAPI.isLoading,
					disabled: isUpiError,
				},
			],
		},
	];

	if (!getSettingsParamsAPI.data) return <TabLoader />;

	return (
		<div className={styles.appSettingSystemWrapper}>
			<PayKitForm.Builder schema={SCHEMA} initialState={getInitState(getSettingsParamsAPI.data)} />
		</div>
	);
};

export default AppSettingSystem;

type RenderRestType = {
	readonly removeCurrentItem: <T>(e: T) => void;
	readonly formDataAPI: {
		readonly setValue: (field: string, list: readonly unknown[]) => void;
		readonly formState: UpdateSettingsType;
	};
	readonly insertItem: () => void;
	readonly error: string;
	readonly index: string;
};
