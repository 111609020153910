// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TableManipulateButtonsGroup__buttonsGroupWrapper-IWN{display:flex;gap:8px}.TableManipulateButtonsGroup__buttonsGroupWrapper-IWN .TableManipulateButtonsGroup__relaunch-d1r{font-size:14px;border:initial;padding-left:initial;color:#fff;background:#0072c3}.TableManipulateButtonsGroup__buttonsGroupWrapper-IWN .TableManipulateButtonsGroup__relaunch-d1r>div{color:#fff}.TableManipulateButtonsGroup__buttonsGroupWrapper-IWN .TableManipulateButtonsGroup__relaunch-d1r:hover{background:#00539a}.TableManipulateButtonsGroup__buttonsGroupWrapper-IWN .TableManipulateButtonsGroup__deleteButton-yKj{gap:8px}.TableManipulateButtonsGroup__buttonsGroupWrapper-IWN .TableManipulateButtonsGroup__deleteButton-yKj .TableManipulateButtonsGroup__deleteIcon-PVe{width:20px;color:#fff}`, "",{"version":3,"sources":["webpack://./src/modules/Proxies/components/TableManipulateButtonsGroup/tableManipulateButtonsGroup.module.scss"],"names":[],"mappings":"AAAA,sDACC,YAAA,CACA,OAAA,CAEA,iGACC,cAAA,CACA,cAAA,CACA,oBAAA,CACA,UAAA,CACA,kBAAA,CAEA,qGACC,UAAA,CAIF,uGACC,kBAAA,CAGD,qGACC,OAAA,CAEA,kJACC,UAAA,CACA,UAAA","sourcesContent":[".buttonsGroupWrapper {\n\tdisplay: flex;\n\tgap: 8px;\n\n\t.relaunch {\n\t\tfont-size: 14px;\n\t\tborder: initial;\n\t\tpadding-left: initial;\n\t\tcolor: #FFFFFF;\n\t\tbackground: #0072C3;\n\n\t\t& > div {\n\t\t\tcolor: #FFFFFF;\n\t\t}\n\t}\n\n\t.relaunch:hover {\n\t\tbackground: #00539a;\n\t}\n\n\t.deleteButton {\n\t\tgap: 8px;\n\n\t\t.deleteIcon {\n\t\t\twidth: 20px;\n\t\t\tcolor: #FFFFFF;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonsGroupWrapper": `TableManipulateButtonsGroup__buttonsGroupWrapper-IWN`,
	"relaunch": `TableManipulateButtonsGroup__relaunch-d1r`,
	"deleteButton": `TableManipulateButtonsGroup__deleteButton-yKj`,
	"deleteIcon": `TableManipulateButtonsGroup__deleteIcon-PVe`
};
export default ___CSS_LOADER_EXPORT___;
