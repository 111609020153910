import { PayKitForm } from "@paykassma/pay-kit";
import { SetCommentType } from "modules/Wallets/contexts/ArchivedWalletContext/ArchivedWalletContext";
import { useTranslation } from "pay-kit";

const CommentModal = ({ comment, hashId, closeModal, actions }: CommentModalProps) => {
	const { t } = useTranslation();

	const handleSubmit = (form: any) => {
		actions.setComment(form).then((resp) => {
			if (resp.status === "success") {
				window.pushAlert({
					type: "success",
					title: t("Success"),
					description: t("Comment successfully saved"),
				});
				closeModal();
			} else {
				window.pushAlert({
					type: "error",
					title: t("Error"),
					description: resp.error_message,
				});
			}
		});
	};

	const schema = [
		{
			name: `comment`,
			label: t("Comment"),
			type: `Textarea`,
		},
		{
			type: `SubmitButton`,
			label: t("Save"),
			onSubmit: handleSubmit,
		},
	];

	return (
		<div>
			<PayKitForm.Builder
				schema={schema}
				initialState={{
					comment,
					hash_id: hashId,
				}}
			/>
		</div>
	);
};

export default CommentModal;

type CommentModalProps = {
	readonly hashId: string;
	readonly comment: string | null;
	readonly closeModal: () => void;
	readonly actions: {
		readonly setComment: SetCommentType;
	};
};
