import React from "react";

import styles from "./StatusCode.module.scss";

type StatusCodePropsType = {
	readonly code: number;
	readonly dataTestId?: string
};

const StatusCode: React.FC<StatusCodePropsType> = ({ code, dataTestId }) => {
	switch (code) {
		case 200:
		case 201:
			return <span className={styles.success} data-test-id={dataTestId}>{code}</span>;
		default:
			return <span className={styles.error} data-test-id={dataTestId}>{code}</span>;
	}
};

export default StatusCode;
