import { useTranslation } from "pay-kit";
import { Button } from "pay-kit";

import styles from "./ConfirmationModal.module.scss";

type ConfirmationModalPropsType = {
	readonly text: string;
	readonly confirmText: string;
	readonly onConfirm: () => void;
	readonly onClose: () => void;
	readonly isLoading: boolean;
};

const ConfirmationModal = ({ text, confirmText, onConfirm, onClose, isLoading }: ConfirmationModalPropsType) => {
	const { t } = useTranslation();

	return (
		<div className={styles.ConfirmationModal}>
			<div className={styles.text}>{text}</div>
			<div className={styles.actions}>
				<Button data-test-id="cancel" onClick={onClose} variant="secondary">
					{t("Cancel")}{" "}
				</Button>
				<Button data-test-id="confirm" onClick={onConfirm} disabled={isLoading} isLoading={isLoading} variant="primary">
					{confirmText}
				</Button>
			</div>
		</div>
	);
};

export default ConfirmationModal;
