import api, { ISetTargetsRequest } from "api/walletTypesGroup";
import { useState } from "react";
import { errorsMap } from "utils/enums";

const useSetTargetsHook: UseSetTargetsHookType = ({ onSuccess, onFail }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const post = (psId: string, data: ISetTargetsRequest) => {
		setIsLoading(true);

		api
			.setTargets(psId, data)
			.then((res: any) => {
				if (res.status === "ok") {
					onSuccess(res);
				} else {
					// throw new Error("Unexpected response received in setTargets");
					throw new Error(errorsMap.anyResponse);
				}

				return res;
			})
			.finally(() => setIsLoading(false))
			.catch((err) => {
				onFail && onFail(err);
				window.pushAlert({ type: "error", title: err.toString() });
			});
	};

	return {
		post,
		isLoading,
	};
};

type UseSetTargetsHookType = (callbacks: {
	readonly onSuccess: (res: any) => void;
	readonly onFail: (err: Error) => void;
}) => { readonly post: (psId: string, data: ISetTargetsRequest) => void; readonly isLoading: boolean };

export default useSetTargetsHook;
