import { WalletListItem } from "api/walletGroup";
import CopyTextButton from "components/CopyTextButton";
import PaymentSystemLogo from "components/PaymentSystemLogo";
import he from "he";
import Balance from "modules/Wallets/components/Balance";
import { ArchivedWalletContext } from "modules/Wallets/contexts/ArchivedWalletContext";
import StatusBadges from "modules/Wallets/WorkingWallets/components/StatusBadges";
import { useTranslation } from "pay-kit";
import { Tooltip } from "pay-kit";
import { toLocaleDate } from "utils/date";

import Comment from "../../../components/Comments/index";
import Actions from "../Actions";
import styles from "./Columns.module.scss";

const archivedWalletsColumns = (
	t: ReturnType<typeof useTranslation>["t"],
	privateAccess: boolean,
	balanceAccess: boolean
) => {
	const idCol = {
		title: "ID",
		dataIndex: "id",
		key: "id",
		dataTestId: "id",
	};

	const hashCol = {
		title: t("Hash"),
		dataIndex: "hash_id",
		key: "hash_id",
		dataTestId: "hash_id",
	};

	const walletTypeCol = {
		title: t("Wallet type"),
		dataIndex: "type",
		key: "type",
		render: ({ wallet_type }: WalletListItem) => <PaymentSystemLogo id={wallet_type} dataTestId="type" />,
	};

	const identifierCol = {
		title: t("Wallet number"),
		dataIndex: "identifier",
		key: "identifier",
		render: ({ identifier }: WalletListItem) => (
			<Tooltip tip={identifier} preferredSide="bottom">
				<div className={styles.accountNumberWrapper}>
					<div className={styles.account_number} data-test-id="identifier">
						{identifier}
					</div>
					<CopyTextButton text={identifier} />
				</div>
			</Tooltip>
		),
	};

	const usernameCol = {
		title: t("Site login"),
		dataIndex: "username",
		key: "username",
		dataTestId: "username",
	};

	const emailCol = {
		title: t("Email"),
		dataIndex: "email_username",
		key: "email_username",
		dataTestId: "email_username",
	};

	const balanceCol = {
		title: t("Balance"),
		dataIndex: "balance",
		key: "balance",
		render: ({ balance, currency, extra_balances }: WalletListItem) => (
			<Balance balance={balance} currency={currency} extra_balances={extra_balances} />
		),
	};

	const extraInfoCol = {
		title: t("Extra information"),
		dataIndex: "upi_addresses",
		key: "upi_addresses",
		render: ({ upi_addresses, display_identifier }: WalletListItem) => (
			<>
				<span data-test-id="upi_addresses">{upi_addresses && upi_addresses.join(", ")}</span>
				<div>{display_identifier && he.decode(display_identifier)}</div>
			</>
		),
	};

	const archivingCol = {
		title: t("Archiving date"),
		dataIndex: "removed_at",
		key: "removed_at",
		render: ({ removed_at }: WalletListItem) => (
			<Tooltip tip={toLocaleDate(removed_at)}>
				<span data-test-id="removed_at">{toLocaleDate(removed_at)}</span>
			</Tooltip>
		),
	};

	const commentCol = {
		title: t("Comment"),
		dataIndex: "comment",
		key: "comment",
		render: ({ comment, hash_id }: WalletListItem) => (
			<Comment comment={comment} hash_id={hash_id} ctx={ArchivedWalletContext} dataTestId="comment" />
		),
	};

	let columns = [idCol, hashCol, walletTypeCol, identifierCol];

	if (privateAccess) {
		columns = [...columns, emailCol, usernameCol];
	}

	if (balanceAccess) {
		columns = [...columns, balanceCol];
	}

	columns = [...columns, archivingCol, extraInfoCol, commentCol];

	return columns;
};

export const subRow = ({ hash_id, states }: WalletListItem) => {
	return (
		<>
			<div className={styles.subRow}>
				<StatusBadges state={states} dataTestId="state" />
				<Actions hash_id={hash_id} />
			</div>
		</>
	);
};

export default archivedWalletsColumns;
