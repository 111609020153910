// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WalletIdentifier__walletID-doT{cursor:pointer;border-bottom:1px dashed #697077}`, "",{"version":3,"sources":["webpack://./src/modules/WalletsDebugging/LogsList/components/WalletIdentifier/walletID.module.scss"],"names":[],"mappings":"AAAA,gCACC,cAAA,CACA,gCAAA","sourcesContent":[".walletID {\n\tcursor: pointer;\n\tborder-bottom: 1px dashed #697077;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"walletID": `WalletIdentifier__walletID-doT`
};
export default ___CSS_LOADER_EXPORT___;
