import { useTranslation } from "pay-kit";
import useCancelBalance from "modules/TransferHistory/hooks/useCancelBalance";
import { Button } from "pay-kit";
import { Dispatch, FC, SetStateAction } from "react";

import styles from "./cancelOperation.module.scss";

const CancelOperation: FC<CancelOperationType> = ({
	id,
	onCancelOperation,
	closeModal,
	isGetOperationHistoryLoading,
}) => {
	const { t } = useTranslation();
	const cancelBalanceAPI = useCancelBalance();

	const onCancel = () => closeModal(false);
	const cancelOperation = () => {
		const onSuccess = () => {
			onCancelOperation();

			window.pushAlert({
				type: "success",
				// description: "Операция отменена",
				description: t("Operation canceled"),
			});
		};

		cancelBalanceAPI.update(id, onSuccess);
	};

	return (
		<>
			{/* <p>Вы действительно хотите отменить операцию?</p> */}
			<p>{t("Are you sure you want to cancel the operation?")}</p>
			<div className={styles.modalBtns}>
				<Button data-test-id="close-the-modal-btn" onClick={onCancel} variant="secondary">
					{t("Cancel")}{" "}
				</Button>
				<Button
					data-test-id="cancel-transaction-btn"
					variant="delete"
					onClick={cancelOperation}
					isLoading={cancelBalanceAPI.isLoading || isGetOperationHistoryLoading}
				>
					{t("OK")}
				</Button>
			</div>
		</>
	);
};

export default CancelOperation;

type CancelOperationType = {
	readonly id: string;
	readonly onCancelOperation: () => void;
	readonly closeModal: Dispatch<SetStateAction<boolean>>;
	readonly isGetOperationHistoryLoading: boolean;
};
