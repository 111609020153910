// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ResponseJSON__ResponseJSONContainer-PIF{display:flex;margin-top:8px;gap:8px}.ResponseJSON__ResponseJSONContainer-PIF .ResponseJSON__Response-P_i{font-family:monospace,Consolas;font-style:normal;font-weight:400;font-size:14px;line-height:20px;color:#697077;white-space:pre;text-overflow:ellipsis;overflow:hidden}`, "",{"version":3,"sources":["webpack://./src/modules/Postbacks/components/columns/components/ResponseJSON/ResponseJSON.module.scss"],"names":[],"mappings":"AAAA,yCACC,YAAA,CACA,cAAA,CACA,OAAA,CAEA,qEACC,8BAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,aAAA,CACA,eAAA,CACA,sBAAA,CACA,eAAA","sourcesContent":[".ResponseJSONContainer {\n\tdisplay: flex;\n\tmargin-top: 8px;\n\tgap: 8px;\n\n\t.Response {\n\t\tfont-family: monospace, Consolas;\n\t\tfont-style: normal;\n\t\tfont-weight: 400;\n\t\tfont-size: 14px;\n\t\tline-height: 20px;\n\t\t/* Blue_gray/70 */\n\t\tcolor: #697077;\n\t\twhite-space: pre;\n\t\ttext-overflow: ellipsis;\n\t\toverflow: hidden;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ResponseJSONContainer": `ResponseJSON__ResponseJSONContainer-PIF`,
	"Response": `ResponseJSON__Response-P_i`
};
export default ___CSS_LOADER_EXPORT___;
