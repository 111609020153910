import {fetchOldApi} from "api/fetch";

export type Proxy = {
	readonly country_code: string;
	readonly created_at: string;
	readonly id: string;
	readonly uuid: string;
	readonly ip: string;
	readonly is_checking: number;
	readonly password: string;
	readonly port: number;
	readonly status: number;
	readonly type: string;
	readonly updated_at: string;
	readonly username: string;
	readonly wallets: readonly number[];
}

export type ProxyResponse = {
	readonly proxies: readonly Proxy[]; 
}

export const proxyGroup = {
	getProxies: () => fetchOldApi<ProxyResponse>("/proxy/list"),
};