import WalletTypes from "modules/Proxies/components/WalletTypes";
import { useProxyContext } from "modules/Proxies/contexts/ProxyContext";
import { useTranslation } from "pay-kit";
import { ReactElement, useEffect } from "react";

import styles from "./walletsAmountSideBar.module.scss";

const renderData: renderDataType = ({ label, value }) => {
	return (
		<div className={styles.proxyData}>
			<span className={styles.label}>{label}</span>
			{value}
		</div>
	);
};

const WalletsAmountSideBar = () => {
	const { walletsAmountSideBarState, getWallets } = useProxyContext();
	const { walletsData } = walletsAmountSideBarState;

	const { t } = useTranslation();

	useEffect(() => {
		getWallets(false);
	}, []);

	return (
		<div>
			{renderData({ label: "IP", value: walletsData?.ip })}
			{renderData({
				label: t("Count"),
				value: walletsData?.amount ? walletsData?.amount : 0,
			})}
			<WalletTypes walletsData={walletsData} />
		</div>
	);
};

export default WalletsAmountSideBar;

type renderDataType = (params: {
	readonly label: string;
	readonly value: number | string | JSX.Element | undefined;
}) => ReactElement;
