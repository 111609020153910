import { AccountsBalanceDateType } from "api/balanceGroup";
import Filter from "components/Filter";
import { useAccountsBalancesContext } from "contexts/AccountsBalanceContext";
import { accountBalancesColumns } from "modules/Balance/components/Columns";
import HideZeroBalances from "modules/Balance/components/HideZeroBalances";
import { useTranslation } from "pay-kit";
import { Table } from "pay-kit";
import { useEffect, useState } from "react";
import { fromLocaleDate, notLaterThanToday } from "utils/date";

import styles from "./accounts.module.scss";
import useURLFilters from "utils/hooks/useURLFilters";

const Accounts = () => {
	const { getAccountsBalanceHistory, getAccountsBalanceHistoryByDate, filteredBalances, isLoading, error } =
		useAccountsBalancesContext();
	const [filters, setFilters] = useURLFilters();
	const { t } = useTranslation();

	useEffect(() => {
		getAccountsBalanceHistory();
	}, []);

	useEffect(() => {
		let formattedDate = { ...filters };
		if (formattedDate.date) {
			formattedDate = { ...formattedDate, date: fromLocaleDate(formattedDate.date) };
		}
		getAccountsBalanceHistoryByDate(formattedDate);
	}, [filters?.date]);

	const balanceDateText = Object.keys(filters).length ? t("Balance on") : t("Total funds");
	return (
		<div className={styles.accountsWrapper}>
			<div className={styles.filterWrapper}>
				<span className={styles.filtersHeader}>{balanceDateText}</span>
				<Filter
					classNames={{
						wrapper: styles.accountsFilterContainer,
					}}
					values={filters}
					fields={[
						{
							name: "date",
							placeholder: t("Date"),
							component: "date",
							dateFormat: "DD.MM.YYYY",
							blockPredicate: notLaterThanToday,
							withTime: true,
						},
					]}
					onApply={(values) => setFilters(values)}
					disabled={isLoading}
				/>
			</div>

			<HideZeroBalances />

			<div>
				<Table
					className={styles.accountsBalancesTable}
					columns={accountBalancesColumns(filters)}
					data={filteredBalances}
					rowKey="payment_system"
					message={error ? { text: `${error}`, type: "error" } : undefined}
					isLoading={isLoading}
					skeleton={{ rowsCount: 14 }}
				/>
			</div>
		</div>
	);
};

export default Accounts;
