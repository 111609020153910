import React, { useEffect } from "react";

import styles from "./PageTitle.module.scss";

type PageTitlePropsType = {
	readonly title: string;
};

export const PageTitle: React.FC<PageTitlePropsType> = ({ title }) => {
	useEffect(() => {
		document.title = title;
	}, []);

	return <div className={styles.PageTitle}>{title}</div>;
};
