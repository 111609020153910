// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PossibleTransactions__filter-Qde{margin-top:36px}.PossibleTransactions__filter-Qde .PossibleTransactions__inlineFilter-kJJ>*{width:160px}.PossibleTransactions__paginator-rnC{margin-top:20px}.PossibleTransactions__paginator-rnC .paginator__postfix_element{display:none}.PossibleTransactions__paginator-rnC .page-count{margin-right:0}.PossibleTransactions__paginator-rnC.PossibleTransactions__bottomPaginator-ZiE .paginator__prefix_element{visibility:hidden}.PossibleTransactions__PossibleTransactionsTable-PPC{margin-top:18px;table-layout:fixed}.PossibleTransactions__PossibleTransactionsTable-PPC tr{position:relative}.PossibleTransactions__PossibleTransactionsTable-PPC td{box-sizing:content-box}.PossibleTransactions__PossibleTransactionsTable-PPC col:nth-child(1){width:135px}`, "",{"version":3,"sources":["webpack://./src/modules/Transactions/PossibleTransactions/PossibleTransactions.module.scss"],"names":[],"mappings":"AAAA,kCACC,eAAA,CAGC,4EACC,WAAA,CAKH,qCACC,eAAA,CAEA,iEACC,YAAA,CAGD,iDACC,cAAA,CAIA,0GACC,iBAAA,CAKH,qDACC,eAAA,CACA,kBAAA,CAEA,wDACC,iBAAA,CAGD,wDACC,sBAAA,CAID,sEACC,WAAA","sourcesContent":[".filter {\n\tmargin-top: 36px;\n\n\t.inlineFilter {\n\t\t> * {\n\t\t\twidth: 160px;\n\t\t}\n\t}\n}\n\n.paginator {\n\tmargin-top: 20px;\n\n\t:global(.paginator__postfix_element) {\n\t\tdisplay: none;\n\t}\n\n\t:global(.page-count) {\n\t\tmargin-right: 0;\n\t}\n\n\t&.bottomPaginator {\n\t\t:global(.paginator__prefix_element) {\n\t\t\tvisibility: hidden;\n\t\t}\n\t}\n}\n\n.PossibleTransactionsTable {\n\tmargin-top: 18px;\n\ttable-layout: fixed;\n\n\ttr {\n\t\tposition: relative;\n\t}\n\n\ttd {\n\t\tbox-sizing: content-box;\n\t}\n\n\t// Тип кошелька\n\tcol:nth-child(1) {\n\t\twidth: 135px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter": `PossibleTransactions__filter-Qde`,
	"inlineFilter": `PossibleTransactions__inlineFilter-kJJ`,
	"paginator": `PossibleTransactions__paginator-rnC`,
	"bottomPaginator": `PossibleTransactions__bottomPaginator-ZiE`,
	"PossibleTransactionsTable": `PossibleTransactions__PossibleTransactionsTable-PPC`
};
export default ___CSS_LOADER_EXPORT___;
