import { WalletTypes } from "utils/enums";

/**
 * Проверяет, что переданная ПС не находится в списке.
 * 
 * @link https://confluence.fixmost.com/pages/viewpage.action?pageId=39478438
 * @param paymentSystem Тип платежной системы
 * @returns true, если система не поддерживает парсинг
 */
export const isWithoutParsePaymentSystem = (paymentSystem: WalletTypes) => {
	const list = new Set([
		WalletTypes.BKASH,
		WalletTypes.CLICK_UZS,
		WalletTypes.NAGAD,
		WalletTypes.ROCKET,
		WalletTypes.VIETCOM_BANK,
		WalletTypes.EASYPAISA,
		WalletTypes.UPI_HB,
		WalletTypes.UPI_IB,
		WalletTypes.UPI_W,
		WalletTypes.UPI_IDB,
		WalletTypes.UPI_IN,
		WalletTypes.UPI_HB,
		WalletTypes.IMPS_HB,
		WalletTypes.IMPS_IB,
		WalletTypes.IMPS_IDB,
		WalletTypes.PAY_TM,
	]);

	return !list.has(paymentSystem);
};


/**
 * Проверяет, что переданная ПС находится в списке.
 * 
 * @link https://confluence.fixmost.com/pages/viewpage.action?pageId=39478438
 * @param paymentSystem Тип платежной системы
 * @returns true, если система поддерживает парсинг
 */
export const isWithParsePaymentSystem = (paymentSystem: WalletTypes) => {
	const list = new Set([
		WalletTypes.BKASH,
		WalletTypes.CLICK_UZS,
		WalletTypes.NAGAD,
		WalletTypes.ROCKET,
		WalletTypes.VIETCOM_BANK,
		WalletTypes.EASYPAISA,
		WalletTypes.UPI_HB,
		WalletTypes.UPI_IB,
		WalletTypes.UPI_W,
		WalletTypes.UPI_IDB,
		WalletTypes.UPI_IN,
		WalletTypes.UPI_HB,
		WalletTypes.IMPS_HB,
		WalletTypes.IMPS_IB,
		WalletTypes.IMPS_IDB,
		WalletTypes.PAY_TM,
	]);

	return list.has(paymentSystem);
};
