import api from "api/translationsGroup";
import { useState } from "react";
import { errorsMap } from "utils/enums";

type useLanguagesType = () => {
	readonly load: () => void;
	readonly list: readonly string[];
	readonly isLoading: boolean;
};

const useLanguages: useLanguagesType = () => {
	const [list, setList] = useState<readonly string[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const load = () => {
		setIsLoading(true);

		api
			.getLanguages()
			.then((res: any) => {
				setIsLoading(false);

				if (res?.status === "success" && !!res?.data) {
					setList(res.data);

					return res;
				} else {
					// throw new Error(`Getting languages list error`);
					throw new Error(errorsMap.anyResponse);
				}
			})
			.finally(() => setIsLoading(false))
			.catch((err) => {
				console.error(err);
			});
	};

	return {
		load,
		list,
		isLoading,
	};
};

export default useLanguages;
