import { useTranslation } from "pay-kit";
import useMakingReplenishment from "modules/TransferHistory/components/Replenish/hook/useMakingReplenishment";
import { useOperationHistoryContext } from "modules/TransferHistory/contexts/OperationHistoryContext";
import { isRequired, isValidAmount } from "modules/TransferHistory/helpers";
import { PayKitForm } from "pay-kit";
import { ReactElement } from "react";

import styles from "./replenish.module.scss";
import { useOperationHistoryTableContext } from "modules/TransferHistory/contexts/OperationHistoryTableContext";

const Replenish = () => {
	const makingReplenishmentAPI = useMakingReplenishment();
	const { getWithdrawalsList, showReplenishModal } = useOperationHistoryContext();
	const { onLoadOperationHistory } = useOperationHistoryTableContext();

	const { t } = useTranslation();

	const onSubmit: OnSubmitHandler = (state) => {
		const onSuccess = () => {
			getWithdrawalsList();
			showReplenishModal(false);
			window.pushAlert({
				type: `success`,
				title: t("Success"),
				// description: `Пополнение выполнено`,
				description: t("Replenishment was successful"),
			});
			onLoadOperationHistory();
		};

		if (!makingReplenishmentAPI.isLoading) {
			makingReplenishmentAPI.update({ amount: state.amount }, onSuccess);
		}
	};

	const SCHEMA: any = [
		{
			type: "Group",
			elements: [
				{
					name: "amount",
					type: "TextInput",
					label: t("Amount"),
					className: "",
					isRequired: true,
					validation: [isRequired, isValidAmount],
				},
			],
		},
		{
			type: "Group",
			render: (element: readonly ReactElement[]) => <div className={styles.submitButton}>{element}</div>,
			elements: [
				{
					name: "submit",
					type: "SubmitButton",
					// label: "Пополнить",
					label: t("Replenish"),
					isLoading: makingReplenishmentAPI.isLoading,
					onSubmit: onSubmit,
				},
			],
		},
	];

	const INIT_STATE = {
		amount: "",
	};

	return <PayKitForm.Builder schema={SCHEMA} initialState={INIT_STATE} />;
};

export default Replenish;

type OnSubmitHandler = (state: { readonly amount: string }) => void;
