// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CaretIcon__commonCaret-SaG{width:9px;color:#697077}.CaretIcon__commonCaret-SaG:hover{color:#636567}.CaretIcon__commonCaret-SaG>svg{width:100%;height:100%;line-height:initial}`, "",{"version":3,"sources":["webpack://./src/components/Icons/CaretIcon/caretIcon.module.scss"],"names":[],"mappings":"AAAA,4BACC,SAAA,CACA,aAAA,CAEA,kCACC,aAAA,CAGD,gCACC,UAAA,CACA,WAAA,CACA,mBAAA","sourcesContent":[".commonCaret {\n\twidth: 9px;\n\tcolor: #697077;\n\n\t&:hover {\n\t\tcolor: #636567;\n\t}\n\n\t& > svg {\n\t\twidth: 100%;\n\t\theight: 100%;\n\t\tline-height: initial;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"commonCaret": `CaretIcon__commonCaret-SaG`
};
export default ___CSS_LOADER_EXPORT___;
