// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.columns__subRow-lIW{display:flex;justify-content:flex-start;align-items:center;gap:8px}.columns__subRow-lIW .columns__actions-pbP{margin-left:auto;display:flex;justify-content:flex-end;gap:8px}.columns__totalCommission-T4S{margin-top:8px}.columns__activatedAt-X8s,.columns__walletIdentifier-_pr,.columns__exchangerIdentifier-U0Q{margin-top:8px;color:#697077}.columns__label-oon{height:20px;padding:0px 4px;background:#e8faed;border-radius:6px;color:#24a148}.columns__merchantOrderIdBadge-Cpu{margin-top:8px}.columns__merchantOrderIdBadge-Cpu .columns__merchantOrderId-VTb{height:20px;padding:0px 4px;background:#e9fbfb;border-radius:6px;color:#009d9a}`, "",{"version":3,"sources":["webpack://./src/modules/Transactions/components/columns/Columns.module.scss"],"names":[],"mappings":"AAAA,qBACC,YAAA,CACA,0BAAA,CACA,kBAAA,CACA,OAAA,CAEA,2CACC,gBAAA,CACA,YAAA,CACA,wBAAA,CACA,OAAA,CAIF,8BACC,cAAA,CAGD,2FAGC,cAAA,CAEA,aAAA,CAGD,oBACC,WAAA,CACA,eAAA,CAEA,kBAAA,CACA,iBAAA,CAEA,aAAA,CAGD,mCACC,cAAA,CACA,iEACC,WAAA,CACA,eAAA,CAEA,kBAAA,CACA,iBAAA,CAEA,aAAA","sourcesContent":[".subRow {\n\tdisplay: flex;\n\tjustify-content: flex-start;\n\talign-items: center;\n\tgap: 8px;\n\n\t.actions {\n\t\tmargin-left: auto;\n\t\tdisplay: flex;\n\t\tjustify-content: flex-end;\n\t\tgap: 8px;\n\t}\n}\n\n.totalCommission {\n\tmargin-top: 8px;\n}\n\n.activatedAt,\n.walletIdentifier,\n.exchangerIdentifier {\n\tmargin-top: 8px;\n\t/* Blue_gray/70 */\n\tcolor: #697077;\n}\n\n.label {\n\theight: 20px;\n\tpadding: 0px 4px;\n\t/* Green/5 */\n\tbackground: #e8faed;\n\tborder-radius: 6px;\n\t/* Green/50 */\n\tcolor: #24a148;\n}\n\n.merchantOrderIdBadge {\n\tmargin-top: 8px;\n\t.merchantOrderId {\n\t\theight: 20px;\n\t\tpadding: 0px 4px;\n\t\t/* Additional/Teal/Ghost */\n\t\tbackground: #e9fbfb;\n\t\tborder-radius: 6px;\n\t\t/* Additional/Teal/Primary */\n\t\tcolor: #009d9a;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subRow": `columns__subRow-lIW`,
	"actions": `columns__actions-pbP`,
	"totalCommission": `columns__totalCommission-T4S`,
	"activatedAt": `columns__activatedAt-X8s`,
	"walletIdentifier": `columns__walletIdentifier-_pr`,
	"exchangerIdentifier": `columns__exchangerIdentifier-U0Q`,
	"label": `columns__label-oon`,
	"merchantOrderIdBadge": `columns__merchantOrderIdBadge-Cpu`,
	"merchantOrderId": `columns__merchantOrderId-VTb`
};
export default ___CSS_LOADER_EXPORT___;
