import { DatePicker } from "@paykassma/pay-kit";
import { OldResponse } from "api/types";
import { WalletTypeType } from "api/walletGroup";
import { DeleteModal } from "components/Modals/DeleteModal/DeleteModal";
import { WalletTypesContext } from "contexts/WalletTypesContext";
import { ArchivedWalletContext } from "modules/Wallets/contexts/ArchivedWalletContext";
import { useTranslation } from "pay-kit";
import { useContext, useMemo, useState } from "react";
import { ModalProps } from "utils/types";

import styles from "./RecoverWalletModal.module.scss";

export const RecoverWalletModal = ({ closeModal, actions, hash_id }: RecoverWalletModalProps) => {
	const { t } = useTranslation();
	const [parsing_start_at, setParsingStartAt] = useState<string>("");
	const { wallets } = useContext(ArchivedWalletContext);
	const { walletTypes } = useContext(WalletTypesContext);
	const p2pWalletTypes = walletTypes.filter((wt) => wt.payment_type === "P2P").map((wt) => wt.code);
	const selectedWallet = wallets.find((w) => w.hash_id === hash_id);
	const selectedWalletIsP2P = selectedWallet ? p2pWalletTypes.includes(selectedWallet?.type) : false;

	const modalText = useMemo(() => {
		let text = <>{t("Are you sure you want to recover wallet {{hash_id}}?", { hash_id })}</>;

		if (selectedWalletIsP2P) {
			text = (
				<>
					{text}
					<br />
					{t("If wallet is P2P, specify transaction parsing start date")}
				</>
			);
		}

		return text;
	}, [selectedWalletIsP2P]);

	return (
		<DeleteModal
			closeModal={() => {
				closeModal();
				setParsingStartAt("");
			}}
			btnClassname={styles.successBtn}
			text={
				<div className={styles.modalContent}>
					<p>{modalText}</p>

					{selectedWalletIsP2P && (
						<>
							<DatePicker
								value={parsing_start_at}
								onChange={(date) => setParsingStartAt(date)}
								label={t("Parsing start date")}
								placeholder={t("Wallet recovery date")}
								data-test-id="parsing_start_at"
								dateTimeFormat={"YYYY-MM-DD HH:mm:ss"}
								withTime
							/>
						</>
					)}
				</div>
			}
			closeLabel={t("Cancel")}
			deleteLabel={t("Recovery")}
			actions={{
				onDelete: () => {
					return actions.recoverWallet(hash_id, true, parsing_start_at).then(() => {
						window.pushAlert({
							type: "success",
							title: t("Success"),
							description: t("Wallet {{hash_id}} successfully recovered", { hash_id }),
						});
					});
				},
			}}
		/>
	);
};

type RecoverWalletModalProps = ModalProps & {
	readonly closeModal: () => void;
	readonly hash_id: WalletTypeType[`hash_id`];
	readonly actions: {
		readonly recoverWallet: (
			hash_id: WalletTypeType[`hash_id`],
			fetchWallets: boolean,
			parsing_start_at: string
		) => Promise<OldResponse<void>>;
	};
};
