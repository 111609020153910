import WorkingWallets from "modules/Wallets/WorkingWallets";
import { useTranslation } from "pay-kit";
import React, { useEffect } from "react";

const WorkingWalletsPage: React.FC = () => {
	const { t } = useTranslation();
	useEffect(() => {
		document.title = t("My wallets");
	}, []);

	return <WorkingWallets />;
};

export default WorkingWalletsPage;
