import { IStatusListItem } from "api/withdrawalGroup";

export const blobToParsedJSON = async (blob: Blob) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.addEventListener("loadend", (e) => {
			const text = e.srcElement.result;

			try {
				const object = JSON.parse(text);
				resolve(object);
			} catch (err) {
				resolve({ status: "success" });
			}
		});

		reader.readAsText(blob);
	});

export const isSaveListContext = (list: IStatusListItem): boolean => {
	if (!list) return false;
	if (!Array.isArray(list)) return false;
};
