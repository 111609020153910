// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Amount__amountTextInput-d8j .Amount__inputContainer-ZAg>div{width:304px}`, "",{"version":3,"sources":["webpack://./src/modules/TransferHistory/components/TransferringMoney/components/Amount/amount.module.scss"],"names":[],"mappings":"AACC,6DACC,WAAA","sourcesContent":[".amountTextInput {\n\t.inputContainer > div {\n\t\twidth: 304px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"amountTextInput": `Amount__amountTextInput-d8j`,
	"inputContainer": `Amount__inputContainer-ZAg`
};
export default ___CSS_LOADER_EXPORT___;
