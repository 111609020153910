import api, { ILimitsItem } from "api/walletTypesGroup";
import { useState } from "react";
import { errorsMap } from "utils/enums";

const useGetLimitsHook = () => {
	const [list, setList] = useState<readonly ILimitsItem[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const load = (psId: string) => {
		setIsLoading(true);

		api
			.getLimits(psId)
			.then((res: any) => {
				if (res.status === "ok" && Array.isArray(res?.data)) {
					setList(res?.data);
				} else {
					// throw new Error("Unexpected response received in getLimits");
					throw new Error(errorsMap.anyResponse);
				}

				return res;
			})
			.finally(() => setIsLoading(false))
			.catch((err) => console.error(err));
	};

	return {
		list,
		load,
		isLoading,
	};
};

export default useGetLimitsHook;
