// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TransactionKind__transactionsCommonRuleSet-iVR,.TransactionKind__innerTransaction-NB4,.TransactionKind__forcedTransaction-_jG,.TransactionKind__realTransaction-EmJ,.TransactionKind__fakeTransaction-a08{display:inline-block;max-width:180px;overflow:hidden;padding:2px 8px 0 8px;border-radius:100px;text-overflow:ellipsis;text-align:left;text-transform:uppercase}.TransactionKind__fakeTransaction-a08{border:1px solid #ffce78;color:#e8882e}.TransactionKind__realTransaction-EmJ{color:#24a148;border:1px solid #6fdc8c}.TransactionKind__forcedTransaction-_jG{border:1px solid #82cfff;color:#0072c3}.TransactionKind__innerTransaction-NB4{border:1px solid #c5c2f1;color:#645eb4}`, "",{"version":3,"sources":["webpack://./src/modules/Stockpilings/components/TransactionKind/transactionKind.module.scss"],"names":[],"mappings":"AAAA,2MACC,oBAAA,CACA,eAAA,CACA,eAAA,CACA,qBAAA,CACA,mBAAA,CACA,sBAAA,CACA,eAAA,CACA,wBAAA,CAGD,sCAEE,wBAAA,CACA,aAAA,CAGF,sCAEC,aAAA,CACA,wBAAA,CAGD,wCAEC,wBAAA,CACA,aAAA,CAGD,uCAEC,wBAAA,CACA,aAAA","sourcesContent":[".transactionsCommonRuleSet {\n\tdisplay: inline-block;\n\tmax-width: 180px;\n\toverflow: hidden;\n\tpadding: 2px 8px 0 8px;\n\tborder-radius: 100px;\n\ttext-overflow: ellipsis;\n\ttext-align: left;\n\ttext-transform: uppercase;\n}\n\n.fakeTransaction {\n\t@extend .transactionsCommonRuleSet;\n\t\tborder: 1px solid #FFCE78;\n\t\tcolor: #E8882E;\n}\n\n.realTransaction {\n\t@extend .transactionsCommonRuleSet;\n\tcolor: #24A148;\n\tborder: 1px solid #6FDC8C;\n}\n\n.forcedTransaction {\n\t@extend .transactionsCommonRuleSet;\n\tborder: 1px solid #82CFFF;\n\tcolor: #0072C3;\n}\n\n.innerTransaction {\n\t@extend .transactionsCommonRuleSet;\n\tborder: 1px solid #C5C2F1;\n\tcolor: #645EB4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"transactionsCommonRuleSet": `TransactionKind__transactionsCommonRuleSet-iVR`,
	"innerTransaction": `TransactionKind__innerTransaction-NB4`,
	"forcedTransaction": `TransactionKind__forcedTransaction-_jG`,
	"realTransaction": `TransactionKind__realTransaction-EmJ`,
	"fakeTransaction": `TransactionKind__fakeTransaction-a08`
};
export default ___CSS_LOADER_EXPORT___;
