import { WalletListItem } from "api/walletGroup";
import Money from "components/Money";
import { FC } from "react";

type BalanceProps = {
	readonly balance: WalletListItem["balance"];
	readonly currency: WalletListItem["currency"];
	readonly extra_balances: WalletListItem["extra_balances"];
};

const Balance: FC<BalanceProps> = ({ balance, currency, extra_balances }) => {
	const renderExtraBalances = () =>
		extra_balances?.map(({ currency: balanceCurrency, balance: balanceAmount }, key) => {
			return (
				<div key={key} data-test-id="extra_balances">
					{balanceCurrency}: {balanceAmount}
				</div>
			);
		});

	if (extra_balances && extra_balances.length > 0) {
		return <>{renderExtraBalances()}</>;
	}

	return (
		<Money direction="ingoing" amount={balance} currencyCode={currency} withSign={false} dataTestId="balance" />
	);
};

export default Balance;
