import { Table } from "@paykassma/pay-kit";
import { TableSelectedRowIds } from "@paykassma/pay-kit/lib/elements/Table/types";
import { PlusIcon } from "components/Icons/PlusIcon/PlusIcon";
import { RefreshIcon } from "components/Icons/RefreshIcon";
import RightModal from "components/Modals/RightModal";
import SideModalContent, { CURRENT_TAB_MAP } from "components/SideModalContent";
import AuthContext, { Roles, useHasAccessHook } from "contexts/AuthContext";
import ReloadButton from "modules/Postbacks/components/ReloadButton";
import WalletsFilter from "modules/Wallets/components/Filter";
import ResetCookiesModal from "modules/Wallets/components/modals/ResetCookiesModal";
import WorkingWalletContextProvider, { WorkingWalletContext } from "modules/Wallets/contexts/WorkingWalletContext";
import MultipleActions from "modules/Wallets/DisabledWallets/components/MultipleActions";
import { Modal, useTranslation } from "pay-kit";
import { Button } from "pay-kit";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useURLState from "utils/hooks/useURLState";

import styles from "../Wallets.module.scss";
import disabledWalletsColumns, { subRow } from "./components/columns";

const DisabledWallets: React.FC = () => {
	const [openResetCookiesModal, setOpenResetCookiesModal] = useState(false);
	const { t } = useTranslation();
	const {
		wallets,
		isLoading,
		selectedHashIDs,
		setSelectedHashIDs,
		openCreateWalletModal,
		getWallets,
		paginationInfo,
		filter,
		setFilter,
	} = useContext(WorkingWalletContext);
	const [hashID, setHashID] = useState<string | undefined>();
	const [id, setID] = useState<number | undefined>();

	const navigate = useNavigate();
	const [page, setCurrentPage] = useURLState<number>("page", 1);
	const [limit, setLimit] = useURLState<number>("limit", 100);
	const { hasRole } = useContext(AuthContext);

	const fetchWallets = () => {
		getWallets({ ...filter, limit, page });
	};

	const hasListPermission = hasRole(Roles.WALLET_LIST);
	const createPermission = hasRole(Roles.WALLET_CREATE);
	const privateInformationAccess = hasRole(Roles.WALLET_NUMBER);
	const balanceAccess = hasRole(Roles.WALLET_BALANCE);

	useEffect(() => {
		if (hasListPermission) {
			fetchWallets();
		}
	}, [filter, limit, page, hasListPermission]);

	useHasAccessHook({ rule: Roles.WALLETS_DISABLED, redirect: true });

	const onShowAllTransactionClick = (walletHashID?: string) => {
		navigate(`/transactions?wallet_hash_id=${walletHashID}`);
	};

	const onCreate = () => {
		openCreateWalletModal(true);
	};

	const onCloseResetCookies = () => {
		setOpenResetCookiesModal(false);
		setHashID(undefined);
	};

	return (
		<>
			<div className={styles.wallets}>
				{createPermission && (
					<>
						<Button classname={styles.create_button} data-test-id="create_button" onClick={onCreate} variant="primary">
							<PlusIcon />
							<span
								style={{
									marginLeft: "8px",
								}}
							>
								{t("Create wallet")}
							</span>
						</Button>
						<Button data-test-id="" type="button" variant="text-primary" onClick={getWallets}>
							<RefreshIcon />
							{t("Refresh")}
						</Button>
					</>
				)}
			</div>
			<WalletsFilter filter={filter} setFilter={setFilter} type="disabled" />
			<RightModal
				bodyClassName={styles.DetailedViewWrapper}
				heading={t("Wallet")}
				onShow={() => null}
				isVisible={!!hashID && !openResetCookiesModal}
				onClose={() => setHashID(undefined)}
			>
				<SideModalContent
					data-test-id="_ZVur6BEUczQZh4Wljq6X"
					walletHashId={hashID}
					id={id}
					showAllTransactionsForWallet={onShowAllTransactionClick}
					currentTab={CURRENT_TAB_MAP.disabled}
					onClick={() => setOpenResetCookiesModal(true)}
				/>
			</RightModal>

			<Modal title={t(`Reset cookies`)} onClose={onCloseResetCookies} isOpen={openResetCookiesModal}>
				<ResetCookiesModal hashID={hashID} onCancel={onCloseResetCookies} onReset={onCloseResetCookies} />
			</Modal>

			<Table
				rowKey="hash_id"
				data={wallets}
				columns={disabledWalletsColumns(setHashID, setID, t, privateInformationAccess, balanceAccess)}
				isLoading={isLoading}
				skeleton={{
					rowsCount: 10,
				}}
				subRowRender={subRow}
				className={styles.table}
				select={{
					multiselect: true,
					selectedIds: selectedHashIDs,
					onRowsSelect: (hashIds: TableSelectedRowIds, e?: MouseEvent) => {
						e && e.stopPropagation();
						setSelectedHashIDs(hashIds);
					},
					renderMultipleActions: () => <MultipleActions />,
				}}
				paginator={
					paginationInfo
						? {
								currentPage: page,
								setCurrentPage: (page: number) => setCurrentPage(page as number),
								limit,
								setLimit,
								lastAvailablePage: Math.ceil((paginationInfo.total + paginationInfo.offset) / paginationInfo.limit),
								prefixElement: <ReloadButton data-test-id="" isLoading={isLoading} onClick={fetchWallets} />,
								className: styles.paginator,
								bottomPaginatorProps: {
									className: styles.bottomPaginator,
								},
						  }
						: undefined
				}
			/>
			{/* <div ref={intersectionObserverAPI.targetRef} className={styles.target} /> */}
		</>
	);
};

export default (props) => (
	<WorkingWalletContextProvider type="disabled" initFetch={false}>
		<DisabledWallets {...props} />
	</WorkingWalletContextProvider>
);
