// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WalletsAmountSideBar__proxyData-W98{display:flex;gap:24px;margin-top:16px;padding:8px 0;font-weight:400;font-size:14px}.WalletsAmountSideBar__proxyData-W98 .WalletsAmountSideBar__label-Edi{width:168px;color:#697077}.WalletsAmountSideBar__proxyData-W98 .WalletsAmountSideBar__iDList-GKo{display:flex;flex-direction:column;max-height:50vh;overflow:auto;width:100%}.WalletsAmountSideBar__proxyData-W98 .WalletsAmountSideBar__iDList-GKo .WalletsAmountSideBar__iDWithStatus-qaI{display:flex;align-items:center;gap:8px}.WalletsAmountSideBar__proxyData-W98 .WalletsAmountSideBar__iDList-GKo .WalletsAmountSideBar__iDWithStatus-qaI .WalletsAmountSideBar__notWorking-JkN{color:#dc3545}.WalletsAmountSideBar__proxyData-W98 .WalletsAmountSideBar__iDList-GKo .WalletsAmountSideBar__iDWithStatus-qaI .WalletsAmountSideBar__working-g84{color:#24a148}`, "",{"version":3,"sources":["webpack://./src/modules/Proxies/components/WalletsAmountSideBar/walletsAmountSideBar.module.scss"],"names":[],"mappings":"AAAA,qCACC,YAAA,CACA,QAAA,CACA,eAAA,CACA,aAAA,CACA,eAAA,CACA,cAAA,CAEA,sEACC,WAAA,CACA,aAAA,CAGD,uEACC,YAAA,CACA,qBAAA,CACA,eAAA,CACA,aAAA,CACA,UAAA,CAEA,+GACC,YAAA,CACA,kBAAA,CACA,OAAA,CAEA,qJACC,aAAA,CAGD,kJACC,aAAA","sourcesContent":[".proxyData {\n\tdisplay: flex;\n\tgap: 24px;\n\tmargin-top: 16px;\n\tpadding: 8px 0;\n\tfont-weight: 400;\n\tfont-size: 14px;\n\n\t.label {\n\t\twidth: 168px;\n\t\tcolor: #697077;\n\t}\n\n\t.iDList {\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\tmax-height: 50vh;\n\t\toverflow: auto;\n\t\twidth: 100%;\n\n\t\t.iDWithStatus {\n\t\t\tdisplay: flex;\n\t\t\talign-items: center;\n\t\t\tgap: 8px;\n\n\t\t\t.notWorking {\n\t\t\t\tcolor: #DC3545;\n\t\t\t}\n\n\t\t\t.working {\n\t\t\t\tcolor: #24A148;\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"proxyData": `WalletsAmountSideBar__proxyData-W98`,
	"label": `WalletsAmountSideBar__label-Edi`,
	"iDList": `WalletsAmountSideBar__iDList-GKo`,
	"iDWithStatus": `WalletsAmountSideBar__iDWithStatus-qaI`,
	"notWorking": `WalletsAmountSideBar__notWorking-JkN`,
	"working": `WalletsAmountSideBar__working-g84`
};
export default ___CSS_LOADER_EXPORT___;
