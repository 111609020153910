import { useTranslation } from "pay-kit";
import { Button, Select, Textarea } from "@paykassma/pay-kit";
import { TranslationItemType } from "api/translationsGroup";
import PlusIcon from "assets/icons/plus.svg";
import TrashIcon from "assets/icons/trash.svg";
import { useEffect, useState } from "react";
import { deepClone } from "utils/deepClone";

import styles from "./TranslationsEditor.module.scss";
import { validate } from "./utils";

type TranslationsEditorPropsType = {
	readonly initState: readonly TranslationItemType[];
	readonly title?: string;
	readonly isNotEditable: boolean;
	readonly isLoading: boolean;
	readonly languages: readonly string[];
	readonly onSubmit: (data: readonly TranslationItemType[]) => void;
	readonly textareaRows?: number;
	readonly undeletableLangs?: readonly string[];
};

const TranslationsEditor: React.FC<TranslationsEditorPropsType> = ({
	initState,
	title,
	isNotEditable,
	languages,
	onSubmit,
	isLoading,
	textareaRows = 1,
	undeletableLangs
}) => {
	const {t} = useTranslation();
	const [translations, setTranslations] = useState<readonly TranslationItemType[]>(initState);

	useEffect(() => {
		setTranslations(initState);
	}, [initState]);

	if (!translations || translations.length < 1) {
		return null;
	}

	const errors = validate(translations);
	const hasErrors = Object.keys(errors).length > 0;
	const hasNoChanges = JSON.stringify(initState) === JSON.stringify(translations);

	const addNewTranslation = () => setTranslations((prevState) => [{ language: "", text: "" }, ...prevState]);

	const removeTarnslation = (n: number) =>
		setTranslations((prevState) => deepClone(prevState).filter((t: string, _n: number) => n !== _n));

	const onEditTranslation = (n: number, type: "language" | "text") => (v: string) =>
		setTranslations((prevState) => {
			const prevStateClone = deepClone(prevState);
			prevStateClone[n] = { ...prevStateClone[n], [type]: v };
			return prevStateClone;
		});

	const getAvailableLangOptions = (currentLang: string) => {
		const unselectedLangs = languages.filter((_l) => translations.map(({ language }) => language).indexOf(_l) === -1);

		if (!currentLang) {
			return unselectedLangs.map((lang) => ({ value: lang, label: lang }));
		}

		return [currentLang, ...unselectedLangs].map((lang) => ({ value: lang, label: lang }));
	};

	const submitHandler = () => {
		if (!hasErrors) {
			onSubmit(translations);
		}
	};

	return (
		<div className={styles.translationsEditor}>
			<div className={styles.titleAndAddNewBar}>
				{title}
				<Button
					data-test-id="add-new-translation"
					variant={"text-primary"}
					onClick={addNewTranslation}
					disabled={isNotEditable}
				>
					<PlusIcon /> {t("Add")}
				</Button>
			</div>

			<div className={styles.columnsTitles}>
				<div>{t("Language")}</div>
				<div>{t("Transfer")}</div>
			</div>

			{translations.map((t, n) => {

				const undeletable = undeletableLangs ? undeletableLangs.includes(t.language) : false;
				const showRemoveButton = !undeletable && !isNotEditable && translations.length > 1;

				const removeButtonStyle = showRemoveButton ?
					styles.removeButton :
					[styles.removeButton, styles.hiddenVisibility].join(" ");

				return (
					<div key={`${t.language}_${n}`} className={styles.translation}>
						<Select
							className={styles.language}
							value={t.language}
							name="lang_selector"
							options={getAvailableLangOptions(t.language)}
							onChange={(v) => onEditTranslation(n, "language")(v as string)}
							isDisabled={isNotEditable}
							error={errors[`[${n}].language`]}
							data-test-id="language-selector"
						/>
						<Textarea
							value={t.text}
							data-test-id="translation-field"
							onChange={(v) => onEditTranslation(n, "text")(v.target.value)}
							rows={textareaRows}
							disabled={isNotEditable}
							error={errors[`[${n}].text`]}
							className={styles.text}
						/>
						<button
							className={removeButtonStyle}
							data-test-id="remove-translation"
							onClick={() => removeTarnslation(n)}
							disabled={!showRemoveButton}
						>
							<TrashIcon />
						</button>
					</div>
				);
			})}

			<Button
				variant={"primary"}
				onClick={submitHandler}
				data-test-id="save-translation-list"
				disabled={hasErrors || hasNoChanges}
				isLoading={isLoading}
				classname={styles.submitButton}
			>
				{t("Save")}
			</Button>
		</div>
	);
};

export default TranslationsEditor;
