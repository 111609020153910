import API from "api";
import { useState } from "react";
import { errorsMap } from "utils/enums";

const useGetTimezone = () => {
	const [error, setError] = useState<Error | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [timezone, setTimezone] = useState("");

	const load = () => {
		setError(null);
		setIsLoading(true);
		setTimezone("");

		API.settings
			.getTimezone()
			.then((resp) => {
				if (resp.status === "success") {
					setTimezone(resp.data.timezone);
				} else {
					// throw new Error("Unexpected response in getTimezone");
					throw new Error(errorsMap.anyResponse);
				}
			})
			.catch((err) => {
				console.log(err);
				setError(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return {
		load,
		timezone,
		isLoading,
		error,
	};
};

export default useGetTimezone;
