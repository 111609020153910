import { Select, Textarea, TextInput } from "@paykassma/pay-kit";

import { IFormData } from "../index";

export interface IField {
	readonly component: "select" | "textInput" | "textarea";
	readonly name: keyof IFormData;
	readonly isRequired: boolean;
	readonly label: string;
	readonly formData: IFormData;
	readonly fieldChangeHandler: (name: keyof IFormData, value: unknown) => void;
	readonly options?: readonly { readonly label: any; readonly value: any }[];
	readonly isLoading?: boolean;
	readonly className?: string;
	readonly placeholder?: string;
	readonly isHidden?: boolean;
	readonly fieldsErrors: { readonly [key in keyof IFormData]?: string };
	readonly touchedFields: readonly (keyof IFormData | "*")[];
}

const Field = ({
	component,
	formData,
	name,
	label,
	isRequired,
	fieldChangeHandler,
	options,
	isLoading,
	className,
	placeholder,
	isHidden,
	fieldsErrors,
	touchedFields,
}: IField) => {
	if (isHidden) {
		return null;
	}

	const _label = <>{label}</>;
	const isTouched = touchedFields.includes("*") || touchedFields.includes(name);
	const fieldError = isTouched ? fieldsErrors[name] : undefined;

	switch (component) {
		case "select":
			if (options === undefined) {
				return null;
			}

			return (
				<Select
					name={name}
					label={_label as any}
					value={formData[name]}
					options={options}
					onChange={(v) => fieldChangeHandler(name, v)}
					data-test-id=""
					required={isRequired}
					isLoading={isLoading}
					className={className}
					placeholder={placeholder}
					error={fieldError}
				/>
			);
		case "textInput":
			return (
				<TextInput
					label={label}
					name={name}
					value={(formData[name] || "") as string}
					onChange={(v) => fieldChangeHandler(name, v.target.value)}
					data-test-id=""
					error={fieldError}
					required={isRequired}
				/>
			);
		case "textarea":
			return (
				<Textarea
					label={_label as any}
					value={(formData[name] || "") as string}
					onChange={(v) => fieldChangeHandler(name, v.target.value)}
					data-test-id=""
					error={fieldError}
					required={isRequired}
					rows={2}
				/>
			);
		default:
			return null;
	}
};

export default Field;
