import { useTranslation } from "pay-kit";
import AuthContext from "contexts/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";
import { PayKitForm } from "pay-kit";
import { useContext } from "react";

import styles from "./ActivateConfirmationModal.module.scss";

type FormState = {
	readonly label: string;
	readonly merchant_order_id: string;
	readonly id?: string;
};

type ActivateConfirmationModalPropsType = {
	readonly confirmText: string;
	readonly onConfirm: (params: FormState) => void;
	readonly onClose: () => void;
	readonly isLoading: boolean;
};

const ActivateConfirmationModal = ({
	confirmText,
	onConfirm,
	onClose,
	isLoading,
}: ActivateConfirmationModalPropsType) => {
	const { hasRole } = useContext(AuthContext);

	const { t } = useTranslation();

	const initialState: FormState = {
		label: "",
		merchant_order_id: "",
		id: "",
	};

	const isRequired = (value: string) => (!value ? t("Required field") : undefined);
	const notLongerThan = (maxLength: number) => (value: string) => value.length > maxLength ? t("Maximum allowed number of characters:{{count}}",{count: maxLength}) : undefined;

	const schema: any = [
		{
			type: "Group",
			render: (children: React.ReactNode) => <div className={styles.fields}>{children}</div>,
			elements: [
				{
					name: "label",
					label: t("Label"),
					type: "TextInput",
					isRequired: true,
					validation: [isRequired],
				},
				{
					name: "merchant_order_id",
					label: t("Order ID"),
					type: "TextInput",
				},
				{
					name: "transaction_id",
					label: t("Transaction number"),
					type: "TextInput",
					existsIf: () => !hasRole(Roles.TRANSACTION_NUMBER_VIEW),
					isRequired: true,
					validation: [isRequired, notLongerThan(25)],
				},
			],
		},
		{
			type: "Group",
			render: (children: React.ReactNode) => <div className={styles.actions}>{children}</div>,
			elements: [
				{
					type: "SubmitButton",
					label: confirmText,
					isLoading: isLoading,
					onSubmit: onConfirm,
				},
				{
					type: "Button",
					label: t("Cancel"),
					variant: "secondary",
					onClick: onClose,
				},
			],
		},
	];

	return (
		<div className={styles.form}>
			<PayKitForm.Builder<FormState> schema={schema} initialState={initialState} />
		</div>
	);
};

export default ActivateConfirmationModal;
