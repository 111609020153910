import Logo from "assets/icons/logo.svg";
import { NavLink } from "react-router-dom";

import styles from "./PayLogo.module.scss";

export const PayLogo = () => (
	<NavLink to="/" className={styles.PayLogo}>
		<Logo />
	</NavLink>
);
