/* eslint-disable ternary/no-unreachable */
import { ICreateNewWalletType } from "api/walletTypesGroup";
import { t, useTranslation } from "pay-kit";
import { AnyObjectSchema, array, mixed, object, string, ValidationError } from "yup";

const isValidFile = (file: File, context: any) => {
	if (!file) {
		return context?.createError();
	}

	return true;
};

export const formValidationSchema = ({ showPaymentConfirmation }: { readonly showPaymentConfirmation: boolean }) =>
	object().shape({
		targets: array().of(
			object({
				logo: mixed().test("required-file-list", t("Choose file"), isValidFile as any),
				title: string().required(t("Required field")),
				currencies: array().of(string().required()).min(1, t("Required field")),
				hint_requisites: string().required(t("Required field")),
				field_requisites: string().required(t("Required field")),
				hint_payment: showPaymentConfirmation
					? string().required(t("Required field")).typeError(t("Required field"))
					: string().notRequired().nullable(),
				field_payment: showPaymentConfirmation
					? string().required(t("Required field")).typeError(t("Required field"))
					: string().notRequired().nullable(),
			})
		),
	});

export const validateForm = async (formState: ICreateNewWalletType, formSchema: AnyObjectSchema) => {
	// eslint-disable-next-line promise/avoid-new
	return new Promise((resolve) =>
		formSchema
			.validate(formState, { abortEarly: false })
			.then(() => resolve(null))
			.catch((err) => {
				// eslint-disable-next-line functional/prefer-readonly-type
				const errors: { [key: string]: string } = {};

				err.inner.forEach((e: ValidationError) => {
					if (e.path) {
						errors[e.path] = e.message;
					}
				});

				resolve(errors);
			})
	);
};

type getErrorsForTouchedFieldsType = (
	errors: { readonly [key: string]: string } | null,
	touchedFields: readonly string[] | "*"
) => { readonly [key: string]: string };

export const getErrorsForTouchedFields: getErrorsForTouchedFieldsType = (errors, touchedFields) => {
	if (errors === null) {
		return {};
	}

	if (touchedFields === "*") {
		return errors;
	}

	// eslint-disable-next-line functional/prefer-readonly-type
	const visibleErrors: { [key: string]: string } = {};

	Object.keys(errors).forEach((fieldName) => {
		if (touchedFields.includes(fieldName)) {
			visibleErrors[fieldName] = errors[fieldName];
		}
	});

	return visibleErrors;
};
