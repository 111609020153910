import { TableColumnType } from "@paykassma/pay-kit/lib/elements/Table/types";
import { WalletListItem } from "api/walletGroup";
import ColumnTitleSeparator from "components/ColumnTitleSeparator";
import CopyTextButton from "components/CopyTextButton";
import PaymentSystemLogo from "components/PaymentSystemLogo";
import he from "he";
import Balance from "modules/Wallets/components/Balance";
import Priority from "modules/Wallets/components/Priority";
import StatusBadges from "modules/Wallets/WorkingWallets/components/StatusBadges";
import { VisibilityToggler } from "modules/Wallets/WorkingWallets/components/Togglers/VisibilityToggler";
import { t } from "pay-kit";
import { Tooltip } from "pay-kit";
import { Dispatch, SetStateAction } from "react";

import Comment from "../../../components/Comments/index";
import Actions from "../Actions";
import styles from "./Columns.module.scss";

const workingWalletsColumns: workingWalletsColumnsType = (setHashID, privateAccess, balanceAccess, setID) => {
	const idCol = {
		title: "ID",
		dataIndex: "id",
		key: "id",
		dataTestId: "id",
	};

	const hashCol = {
		title: t("Hash"),
		dataIndex: "hash_id",
		key: "hash_id",
		dataTestId: "hash_id",
		render: ({ hash_id }: WalletListItem) => <div>{hash_id}</div>,
	};

	const walletTypeCol = {
		title: t("Wallet type"),
		dataIndex: "type",
		key: "type",
		render: ({ wallet_type }: WalletListItem) => <PaymentSystemLogo id={wallet_type} dataTestId="type" />,
	};

	const identifierCol: IdColType = (setHashID, setID) => ({
		title: t("Wallet number"),
		dataIndex: "identifier",
		key: "identifier",
		render: ({ identifier, hash_id, id }: WalletListItem) => (
			<Tooltip tip={identifier} preferredSide="bottom">
				<div className={styles.accountNumberWrapper}>
					<div
						className={styles.account_number}
						onClick={() => {
							setHashID(hash_id);
							setID(id);
						}}
						data-test-id="identifier"
					>
						{identifier}
					</div>
					<CopyTextButton text={identifier} />
				</div>
			</Tooltip>
		),
	});

	const usernameCol = {
		title: t("Site login"),
		dataIndex: "username",
		key: "username",
		render: ({ username }: WalletListItem) => (
			<Tooltip tip={username} preferredSide="bottom">
				<div data-test-id="username">{username}</div>
			</Tooltip>
		),
	};

	const emailCol = {
		title: t("Email"),
		dataIndex: "email_username",
		key: "email_username",
		render: ({ email_username }: WalletListItem) => (
			<Tooltip tip={email_username} preferredSide="bottom">
				<div data-test-id="email_username">{email_username}</div>
			</Tooltip>
		),
	};

	const balanceCol = {
		title: t("Balance"),
		dataIndex: "balance",
		key: "balance",
		render: ({ balance, currency, extra_balances }: WalletListItem) => (
			<Balance balance={balance} currency={currency} extra_balances={extra_balances} />
		),
	};

	const extraInfoCol = {
		title: t("Extra information"),
		dataIndex: "upi_addresses",
		key: "upi_addresses",
		render: ({ upi_addresses, display_identifier }: WalletListItem) => (
			<>
				<span data-test-id="upi_addresses">{upi_addresses && upi_addresses.join(", ")}</span>
				<div>{display_identifier && he.decode(display_identifier)}</div>
			</>
		),
	};

	const commentCol = {
		title: t("Comment"),
		dataIndex: "comment",
		key: "comment",
		render: ({ comment, hash_id }: WalletListItem) => (
			<Comment comment={comment} hash_id={hash_id} dataTestId="comment" />
		),
	};

	let columns = [idCol, hashCol, walletTypeCol, identifierCol(setHashID, setID)];

	if (privateAccess) {
		columns = [...columns, emailCol, usernameCol];
	}

	if (balanceAccess) {
		columns = [...columns, balanceCol];
	}

	columns = [...columns, extraInfoCol, commentCol];

	return columns;
};

export const subRow = ({
	id,
	hash_id,
	plugin_status,
	states,
	identifier,
	currency,
	wallet_type,
	priority,
}: WalletListItem) => {
	return (
		<div className={styles.subRow}>
			{typeof plugin_status !== "undefined" && (
				<>
					<VisibilityToggler hash_id={hash_id} plugin_status={plugin_status} />
					<ColumnTitleSeparator />
				</>
			)}
			<Priority priority={priority} />
			{(states || []).length > 0 && (
				<>
					<ColumnTitleSeparator />
					<StatusBadges state={states} type={wallet_type} />
				</>
			)}
			<Actions hash_id={hash_id} identifier={identifier} type={wallet_type} currency={currency} />
		</div>
	);
};

export default workingWalletsColumns;

type workingWalletsColumnsType = (
	setHashID: Dispatch<SetStateAction<string | undefined>>,
	privateAccess: boolean,
	balanceAccess: boolean,
	setID: Dispatch<SetStateAction<number | undefined>>
) => readonly TableColumnType[];

type IdColType = (
	setHashID: Dispatch<SetStateAction<string | undefined>>,
	setID: Dispatch<SetStateAction<number | undefined>>
) => TableColumnType;
