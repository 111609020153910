import React from "react";

import { CloseIcon,ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from "./icons";
import styles from "./styles.module.scss";

export interface AlertType {
	readonly id?: string | number;
	readonly title?: string;
	readonly description?: string;
	readonly type?: "error" | "info" | "success" | "warning";
	readonly timeout?: number;
	readonly onClose?: () => void;
	readonly onClick?: () => void;
	readonly pinned?: boolean;
}

const Alert = ({ title, description, type, onClose, onClick }: AlertType) => {
	const classNames = [styles.alert];

	switch (type) {
		case "error":
			classNames.push(styles.error);
			break;
		case "info":
			classNames.push(styles.info);
			break;
		case "success":
			classNames.push(styles.success);
			break;
		case "warning":
			classNames.push(styles.warning);
			break;
	}

	if (!title) {
		classNames.push(styles.compact);
	}

	if (onClick) {
		classNames.push(styles.clickable);
	}

	return (
		<div data-test-id="1u-yWmQMBXIEganp4sUIV" className={classNames.join(" ")} role="alert" onClick={onClick}>
			<div className={styles.alertBody}>
				{type === "error" && <ErrorIcon className={styles.icon} />}
				{type === "info" && <InfoIcon className={styles.icon} />}
				{type === "success" && <SuccessIcon className={styles.icon} />}
				{type === "warning" && <WarningIcon className={styles.icon} />}

				<div className={styles.content}>
					{title && <h4 className={styles.title}>{title}</h4>}
					{description && <div className={styles.description}>{description}</div>}
				</div>

				{onClose && (
					<button data-test-id="blNZ_WIzLpccElqoxeTQE"
						onClick={(e) => {
							e.stopPropagation();
							onClose();
						}}
						className={styles.closeButton}
					>
						<CloseIcon />
					</button>
				)}
			</div>
		</div>
	);
};

export default Alert;
