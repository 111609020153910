// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Filter__filter-cpl{margin-top:36px}.Filter__filter-cpl .Filter__inlineFilter-CwK{display:grid;grid-template-columns:1fr 1fr 1fr 380px 1fr 1fr}.Filter__additionalFiltersWrapper-ojV{width:350px}`, "",{"version":3,"sources":["webpack://./src/modules/Transactions/ArchivedTransactions/Filter/ArchivedTransactionsFilter.module.scss"],"names":[],"mappings":"AAAA,oBACC,eAAA,CAEA,8CACC,YAAA,CACA,+CAAA,CAIF,sCACC,WAAA","sourcesContent":[".filter {\n\tmargin-top: 36px;\n\n\t.inlineFilter {\n\t\tdisplay: grid;\n\t\tgrid-template-columns: 1fr 1fr 1fr 380px 1fr 1fr;\n\t}\n}\n\n.additionalFiltersWrapper {\n\twidth: 350px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter": `Filter__filter-cpl`,
	"inlineFilter": `Filter__inlineFilter-CwK`,
	"additionalFiltersWrapper": `Filter__additionalFiltersWrapper-ojV`
};
export default ___CSS_LOADER_EXPORT___;
