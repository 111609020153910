import api from "api/settingsGroup";
import { useState } from "react";
import { errorsMap } from "utils/enums";

type TimezoneType = { readonly name: string, readonly value: string }

type useTimezonesType = () => {
	readonly load: () => void;
	readonly list: readonly TimezoneType[];
	readonly isLoading: boolean;
};

const useTimezones: useTimezonesType = () => {
	const [list, setList] = useState<readonly TimezoneType[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const load = () => {
		setIsLoading(true);

		api
			.getTimezoneList()
			.then((res) => {
				setIsLoading(false);

				if (res?.status === "ok" && Array.isArray(res?.available_timezones)) {
					setList(res?.available_timezones);

					return res;
				} else {
					// throw new Error(`Getting timezones list error`);
					throw new Error(errorsMap.anyResponse);
				}
			})
			.finally(() => setIsLoading(false))
			.catch((err) => {
				console.error(err);
			});
	};

	return {
		load,
		list,
		isLoading,
	};
};

export default useTimezones;
