// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sidebar__sidebar-mP9{display:flex;flex-direction:column;position:fixed;top:0;left:0;z-index:2;width:90px;height:100vh;background-color:#21272a;color:#a2a9b0}.Sidebar__sidebar-mP9 .Sidebar__container-Ec0{display:flex;flex:1;flex-direction:column;align-items:center}`, "",{"version":3,"sources":["webpack://./src/layout/components/Sidebar/Sidebar.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,cAAA,CACA,KAAA,CACA,MAAA,CACA,SAAA,CACA,UAAA,CACA,YAAA,CACA,wBAAA,CACA,aAAA,CAEA,8CACE,YAAA,CACA,MAAA,CACA,qBAAA,CACA,kBAAA","sourcesContent":[".sidebar {\n  display: flex;\n  flex-direction: column;\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: 2;\n  width: 90px;\n  height: 100vh;\n  background-color: #21272A;\n  color: #A2A9B0;\n\n  .container {\n    display: flex;\n    flex: 1;\n    flex-direction: column;\n    align-items: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": `Sidebar__sidebar-mP9`,
	"container": `Sidebar__container-Ec0`
};
export default ___CSS_LOADER_EXPORT___;
