import { useTranslation } from "pay-kit";
/* eslint-disable react/prop-types */
import { Radio, Tooltip } from "@paykassma/pay-kit";
import { IPossibleTransaction } from "api/transactionGroup";
import Money from "components/Money";

import styles from "./PossibleTransactonSelect.module.scss";

type PossibleTransactonSelectType = {
	readonly transactions?: readonly IPossibleTransaction[];
	readonly onChange: (selectedPossibleTrId?: number) => void;
	readonly selectedPossibleTrId?: number;
};

const PossibleTransactonSelect: React.FC<PossibleTransactonSelectType> = ({
	transactions,
	onChange,
	selectedPossibleTrId,
}) => {
	const {t} = useTranslation();
	if (!transactions || transactions?.length < 1) {
		return null;
	}

	return (
		<>
			<div className={styles.info}>
				{/* {`${transactions.length >= 2 ? "Найдены возможные транзакции" : "Найдена возможная транзакция"} с номером:`} */}
				{`${transactions.length >= 2 ? t("Possible transactions found") : t("Possible transaction found")} ${t("with number")}`}
				<br />
				<strong>
					{transactions[0].id} – {transactions[0].wallet_type}
				</strong>
				.
				<br />
				{/* Выберите позицию из списка и нажмите "Далее" */}
				{t("Select position from list and click 'Next'")}
			</div>
			<div className={styles.possibleTransactonSelect}>
				<table className={styles.transactionsTable}>
					<colgroup>
						<col />
						<col />
						<col />
						<col />
					</colgroup>
					<thead>
						<tr>
							<th></th>
							<th>{t("Amount")}</th>
							<th>{t("Label")}</th>
							<th>{t("Order ID")}</th>
						</tr>
					</thead>
					<tbody>
						{transactions.map((tr) => (
							<tr key={tr.primary_id}>
								<td>
									<Radio
										checked={selectedPossibleTrId === tr.primary_id}
										onChange={() => onChange(tr.primary_id)}
										data-test-id="radio"
									/>
								</td>
								<td>
									<Money className={styles.amount} amount={tr.amount} currencyCode={tr.currency_symbol} />
								</td>
								<td>
									<Tooltip preferredSide="top" tip={tr.label}>
										<div className={styles.label}>{tr.label}</div>
									</Tooltip>
								</td>
								<td>
									<Tooltip preferredSide="top" tip={tr.merchant_order_id}>
										<div className={styles.orderId}>{tr.merchant_order_id}</div>
									</Tooltip>
								</td>
							</tr>
						))}
						<tr>
							<td>
								<Radio
									checked={selectedPossibleTrId === undefined}
									onChange={() => onChange(undefined)}
									data-test-id="radio"
								/>
							</td>
							<td colSpan={3}>{t("Do not use data from the possible transaction")}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</>
	);
};

export default PossibleTransactonSelect;
