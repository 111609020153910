// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WithdrawalForm__formWrapper-vZ7{flex:1;width:480px}.WithdrawalForm__form-C6i{width:100%;max-height:500px}.WithdrawalForm__form-C6i.WithdrawalForm__firstStep-hCN [class^=Spoiler__spoiler]{display:none}.WithdrawalForm__actions-olU{display:flex;justify-content:flex-end;margin-top:32px}`, "",{"version":3,"sources":["webpack://./src/modules/Withdrawals/components/ActionsProvider/CreateNewModal/WithdrawalForm/WithdrawalForm.module.scss"],"names":[],"mappings":"AAAA,iCACI,MAAA,CACA,WAAA,CAGJ,0BACI,UAAA,CACA,gBAAA,CAEA,kFACI,YAAA,CAIR,6BACI,YAAA,CACA,wBAAA,CACA,eAAA","sourcesContent":[".formWrapper {\n    flex: 1;\n    width: 480px;\n}\n\n.form {\n    width: 100%;\n    max-height: 500px;\n\n    &.firstStep :global([class^=Spoiler__spoiler]) {\n        display: none;\n    }\n}\n\n.actions {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 32px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formWrapper": `WithdrawalForm__formWrapper-vZ7`,
	"form": `WithdrawalForm__form-C6i`,
	"firstStep": `WithdrawalForm__firstStep-hCN`,
	"actions": `WithdrawalForm__actions-olU`
};
export default ___CSS_LOADER_EXPORT___;
