// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProxyStatus__statusBadge-Rqc{width:max-content}`, "",{"version":3,"sources":["webpack://./src/modules/Proxies/components/ProxiesTableColumns/components/ProxyStatus/proxyStatus.module.scss"],"names":[],"mappings":"AAAA,8BACC,iBAAA","sourcesContent":[".statusBadge {\n\twidth: max-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statusBadge": `ProxyStatus__statusBadge-Rqc`
};
export default ___CSS_LOADER_EXPORT___;
