import { FC } from "react";

import styles from "./plusIcon.module.scss";

export const PlusIcon: FC<PlusIconType> = ({ className }) => {
	return (
		<div className={[styles.plusIcon, className].join(" ")}>
			<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fillRule="evenodd" clipRule="evenodd"
					d="M8.5015 3.75391C8.5015 3.47776 8.27765 3.25391 8.0015 3.25391C7.72536 3.25391 7.5015 3.47776 7.5015 3.75391V7.50149H3.75391C3.47776 7.50149 3.25391 7.72535 3.25391 8.00149C3.25391 8.27763 3.47776 8.50149 3.75391 8.50149H7.5015V12.2498C7.5015 12.526 7.72536 12.7498 8.0015 12.7498C8.27765 12.7498 8.5015 12.526 8.5015 12.2498V8.50149H12.2499C12.5261 8.50149 12.7499 8.27763 12.7499 8.00149C12.7499 7.72535 12.5261 7.50149 12.2499 7.50149H8.5015V3.75391Z"
					fill="currentColor" />
			</svg>
		</div>
	);
};

type PlusIconType = {
	readonly className?: string
}
