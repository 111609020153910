// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TrashIcon__trashIcon-rIw{width:24px;color:#697077;cursor:pointer}.TrashIcon__trashIcon-rIw>svg{width:100%;height:100%;line-height:initial}`, "",{"version":3,"sources":["webpack://./src/components/Icons/TrashIcon/trashIcon.module.scss"],"names":[],"mappings":"AAAA,0BACC,UAAA,CACA,aAAA,CACA,cAAA,CAEA,8BACC,UAAA,CACA,WAAA,CACA,mBAAA","sourcesContent":[".trashIcon {\n\twidth: 24px;\n\tcolor: #697077;\n\tcursor: pointer;\n\n\t& > svg {\n\t\twidth: 100%;\n\t\theight: 100%;\n\t\tline-height: initial;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"trashIcon": `TrashIcon__trashIcon-rIw`
};
export default ___CSS_LOADER_EXPORT___;
