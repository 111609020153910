// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CheckBoxesList__checkbox-Fi9{padding:6px 8px}`, "",{"version":3,"sources":["webpack://./src/modules/Dashboard/components/CheckBoxesList/checkBoxesList.module.scss"],"names":[],"mappings":"AAAA,8BACC,eAAA","sourcesContent":[".checkbox {\n\tpadding: 6px 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox": `CheckBoxesList__checkbox-Fi9`
};
export default ___CSS_LOADER_EXPORT___;
