// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RejectModal__form-QyB{display:flex;flex-direction:column;width:100%;gap:28px}.RejectModal__form-QyB>:nth-child(1){display:grid;grid-template-columns:170px 1fr;gap:24px}.RejectModal__form-QyB>[class^=Select__Select]{display:grid;grid-template-columns:170px 1fr;gap:24px}.RejectModal__form-QyB>[class^=TextInput],.RejectModal__form-QyB>.Textarea{display:grid;grid-template-columns:170px 1fr;gap:24px}.RejectModal__form-QyB>[class^=TextInput]>:nth-child(1),.RejectModal__form-QyB>.Textarea>:nth-child(1){font-weight:400;font-size:14px;line-height:20px;color:#697077}.RejectModal__actions-G11{display:flex;flex:1;align-self:flex-end}.RejectModal__note-SG8{margin-top:40px}`, "",{"version":3,"sources":["webpack://./src/modules/Withdrawals/components/ActionsProvider/RejectModal/RejectModal.module.scss"],"names":[],"mappings":"AAAA,uBACI,YAAA,CACA,qBAAA,CACA,UAAA,CACA,QAAA,CAEA,qCACI,YAAA,CACA,+BAAA,CACA,QAAA,CAGJ,+CACI,YAAA,CACA,+BAAA,CACA,QAAA,CAGJ,2EACI,YAAA,CACA,+BAAA,CACA,QAAA,CAEA,uGACI,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,aAAA,CAKZ,0BACI,YAAA,CACA,MAAA,CACA,mBAAA,CAGJ,uBACI,eAAA","sourcesContent":[".form {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    gap: 28px;\n\n    >:nth-child(1) {\n        display: grid;\n        grid-template-columns: 170px 1fr;\n        gap: 24px;\n    }\n\n    >:global([class^=Select__Select]) {\n        display: grid;\n        grid-template-columns: 170px 1fr;\n        gap: 24px;\n    }\n\n    >:global([class^=TextInput]), >:global(.Textarea) {\n        display: grid;\n        grid-template-columns: 170px 1fr;\n        gap: 24px;\n\n        >:nth-child(1) {\n            font-weight: 400;\n            font-size: 14px;\n            line-height: 20px;\n            /* Blue_gray/70 */\n            color: #697077;\n        }\n    }\n}\n\n.actions {\n    display: flex;\n    flex: 1;\n    align-self: flex-end;\n}\n\n.note {\n    margin-top: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `RejectModal__form-QyB`,
	"actions": `RejectModal__actions-G11`,
	"note": `RejectModal__note-SG8`
};
export default ___CSS_LOADER_EXPORT___;
