import { FC, ReactElement } from "react";

import styles from "./section.module.scss";

const Section: FC<SectionType> = ({ children, header, className }) => {
	return (
		<div className={[styles.pluginSectionWrapper, className].join(" ")}>
			<div className={styles.header}>{header}</div>
			{children}
		</div>
	);
};
export default Section;

type SectionType = {
	readonly children: ReactElement
	readonly header: string
	readonly className?: string
}