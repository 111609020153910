// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RenameModal__renameModal-NLa{max-width:350px}.RenameModal__renameModal-NLa [class^=Modal__header]{display:none}.RenameModal__renameModal-NLa [class^=Modal__content]{display:flex;margin:0}.RenameModal__renameModal-NLa [class^=TextInput__inputWrapper]{width:100%}.RenameModal__renameModal-NLa p{margin-bottom:16px}.RenameModal__renameModal-NLa .RenameModal__controls-H62{margin-top:8px;display:flex;gap:6px}.RenameModal__renameModal-NLa .RenameModal__controls-H62 button{flex:1}`, "",{"version":3,"sources":["webpack://./src/modules/PaymentSystems/Translations/components/ActionsProvider/modals/RenameModal/RenameModal.module.scss"],"names":[],"mappings":"AAAA,8BACI,eAAA,CAEA,qDACI,YAAA,CAGJ,sDACI,YAAA,CACA,QAAA,CAIJ,+DACI,UAAA,CAGJ,gCACI,kBAAA,CAGJ,yDACI,cAAA,CACA,YAAA,CACA,OAAA,CAEA,gEACI,MAAA","sourcesContent":[".renameModal {\n    max-width: 350px;\n\n    [class^=\"Modal__header\"] {\n        display: none;\n    }\n\n    [class^=\"Modal__content\"] {\n        display: flex;\n        margin: 0;\n    }\n\n\n    [class^=\"TextInput__inputWrapper\"] {\n        width: 100%;\n    }\n\n    p {\n        margin-bottom: 16px;\n    }\n\n    .controls {\n        margin-top: 8px;\n        display: flex;\n        gap: 6px;\n\n        button {\n            flex: 1;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"renameModal": `RenameModal__renameModal-NLa`,
	"controls": `RenameModal__controls-H62`
};
export default ___CSS_LOADER_EXPORT___;
