// import { Button } from "@paykassma/pay-kit";
import { WalletsSummaryType } from "modules/Dashboard/components/WalletsTotalBalance";
import WalletSummaryLimit from "modules/Dashboard/components/WalletsTotalBalance/components/WalletSummaryLimit";
import { useTranslation } from "pay-kit";
import { FC, useState } from "react";

import styles from "./walletSummaryDisplay.module.scss";

const WalletSummaryDisplay: FC<WalletSummaryDisplayType> = ({ summaryKey, WalletsSummary }) => {
	const { t } = useTranslation();
	const [activeButtonType, setActiveButtonType] = useState<ActiveButtonKeyType>("limits");

	const walletSummaryDisplayWrapperStyle = {
		up: [styles.walletSummaryDisplayWrapper, styles.display].join(" "),
		down: styles.walletSummaryDisplayWrapper,
	};

	const defaultButtonStyle = [styles.walletSummaryButton, styles.activeWalletSummaryButton].join(" ");
	const activeButtonStyle = styles.activeWalletSummaryButton;

	const turnOffButtonStyle = activeButtonType === "limits" ? activeButtonStyle : defaultButtonStyle;
	const limitsButtonStyle = activeButtonType === "wallets" ? activeButtonStyle : defaultButtonStyle;

	return (
		<div className={walletSummaryDisplayWrapperStyle[summaryKey] || walletSummaryDisplayWrapperStyle.down}>
			{/*
			// Temporary disabled: https://jira.fixmost.com/browse/PAY-15585?filter=-1

			<div className={styles.buttonsPair}>
				<Button data-test-id="NJH1bkz8iS_QsmX_m1J0H"
					classname={turnOffButtonStyle}
					onClick={() => setActiveButtonType("limits")}
				>
					{t("Limits")}				</Button>
				<Button data-test-id="luBtVVlBHSYbcbkEWwhjE"
					classname={limitsButtonStyle}
					onClick={() => setActiveButtonType("wallets")}
				>
					{t("Wallets")}				</Button>
			</div>
			*/}

			{activeButtonType === "limits" && (
				<>
					<WalletSummaryLimit
						limits={WalletsSummary?.wallet_off_limit}
						className={styles.turnOff}
						header={t("Wallet off limit:")}
					/>

					<WalletSummaryLimit
						limits={WalletsSummary?.wallet_off_load}
						className={styles.dailyLimit}
						header={t("Daily limit on the amount:")}
					/>
				</>
			)}

			{activeButtonType === "wallets" && (
				<div className={styles.walletsList}>The completion of this panel is postponed to the next stage!</div>
			)}
		</div>
	);
};

export default WalletSummaryDisplay;

type WalletSummaryDisplayType = {
	readonly summaryKey: "up" | "down";
	readonly WalletsSummary: WalletsSummaryType | null;
};

type ActiveButtonKeyType = "wallets" | "limits";
