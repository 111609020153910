import CaretIcon from "components/Icons/CaretIcon";
import { WalletTypesContext } from "contexts/WalletTypesContext";
import Limits from "modules/Dashboard/components/WalletsTotalBalance/components/Limits";
import { useTranslation } from "pay-kit";
import { useOutsideClick } from "pay-kit";
import { useContext, useRef, useState } from "react";

import styles from "./paymentSystemsLimits.module.scss";

const PaymentSystemsLimits = () => {
	const { walletTypes } = useContext(WalletTypesContext);
	const [openLimits, setOpenLimits] = useState(false);
	const { t } = useTranslation();

	const limitsOptions = walletTypes?.map((ps) => ({
		label: ps.name,
		value: ps.code,
	}));

	const limitsRef = useRef(null);
	useOutsideClick(limitsRef, () => setOpenLimits(false));

	const caretStyle = openLimits ? styles.caretUp : undefined;

	return (
		<div className={styles.paymentSystemsLimitsWrapper} ref={limitsRef}>
			<div
				data-test-id="ZELK7zRdFQHQ5x-VNfn1z"
				className={styles.paymentSystemsLimits}
				onClick={() => setOpenLimits((open) => !open)}
			>
				{t("Limits")} <CaretIcon className={caretStyle} />
			</div>
			{openLimits && (
				<div className={styles.limits}>
					<Limits options={limitsOptions} />
				</div>
			)}
		</div>
	);
};

export default PaymentSystemsLimits;
