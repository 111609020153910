import { t, Tabs } from "@paykassma/pay-kit";
import { IArchivedTransaction } from "api/transactionGroup";
import RightModal from "components/Modals/RightModal";
import AuthContext, { Roles } from "contexts/AuthContext";
import { Postback } from "modules/Transactions/CurrentTransactions/DetailedView/Tabs/Postback";
import { Wallet } from "modules/Transactions/CurrentTransactions/DetailedView/Tabs/Wallet";
import React, { useContext, useMemo, useState } from "react";

import CommonData from "./CommonData";
import styles from "./DetailedView.module.scss";

interface IDetailedViewProps {
	readonly transactionData: IArchivedTransaction | null | undefined;
	readonly onClose: () => void;
}

const DetailedView: React.FC<IDetailedViewProps> = ({ transactionData, onClose }) => {
	const { hasRole } = useContext(AuthContext);

	const hasWalletAccess = hasRole(Roles.WALLET_LIST);

	const tabs = useMemo(() => {
		let result = [
			{
				value: "common",
				label: t("Common info"),
			},
		];

		if (hasWalletAccess) {
			result.push({
				value: "wallet",
				label: t("Wallet"),
			});
		}

		result = [
			...result,
			{
				value: "postback",
				label: t("Postback"),
			},
		];

		return result;
	}, [hasWalletAccess]);

	const [activeTab, setActiveTab] = useState<string>("common");

	return (
		<RightModal
			bodyClassName={styles.DetailedViewWrapper}
			onShow={() => null}
			isVisible={transactionData !== null}
			onClose={onClose}
		>
			<Tabs tabs={tabs} value={activeTab} onChange={(value) => setActiveTab(value as string)} data-test-id="tabs" />
			{transactionData && activeTab === "common" && <CommonData transactionData={transactionData} />}
			{transactionData && activeTab === "wallet" && <Wallet wallet_hash_id={transactionData.wallet_hash_id} />}
			{transactionData && activeTab === "postback" && <Postback transactionData={transactionData} />}
		</RightModal>
	);
};

export default DetailedView;
