// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TransactionStatus__TransactionStatus-Ctq{line-height:20px;padding:0 8px;border-radius:10px;text-transform:uppercase;font-weight:500;font-size:14px}.TransactionStatus__NOT_ACTIVATED-Qzy,.TransactionStatus__SUSPECT-bav,.TransactionStatus__CANCELED-Fz5{background:#fff4e2;color:#e8882e}.TransactionStatus__ACTIVATED-KVQ{background:#e8faed;color:#24a148}.TransactionStatus__PENDING-qc5,.TransactionStatus__IN_PROCESSING-wcf{color:#645eb4;background:#f0efff}.TransactionStatus__CRASHED-GNq,.TransactionStatus__REFUSED-Hj9,.TransactionStatus__ERROR-YDR{color:#dc3545;background:#fff1f1}`, "",{"version":3,"sources":["webpack://./src/modules/Transactions/components/TransactionStatus/TransactionStatus.module.scss"],"names":[],"mappings":"AAAA,0CACC,gBAAA,CACA,aAAA,CACA,kBAAA,CACA,wBAAA,CACA,eAAA,CACA,cAAA,CAGD,uGAIC,kBAAA,CAEA,aAAA,CAGD,kCAEC,kBAAA,CAEA,aAAA,CAGD,sEAEC,aAAA,CACA,kBAAA,CAGD,8FAIC,aAAA,CAEA,kBAAA","sourcesContent":[".TransactionStatus {\n\tline-height: 20px;\n\tpadding: 0 8px;\n\tborder-radius: 10px;\n\ttext-transform: uppercase;\n\tfont-weight: 500;\n\tfont-size: 14px;\n}\n\n.NOT_ACTIVATED,\n.SUSPECT,\n.CANCELED {\n\t/* Orange/5 */\n\tbackground: #fff4e2;\n\t/* Orange/50 */\n\tcolor: #e8882e;\n}\n\n.ACTIVATED {\n\t/* Additional/Green/Ghost */\n\tbackground: #e8faed;\n\t/* Additional/Green/Primary */\n\tcolor: #24a148;\n}\n\n.PENDING,\n.IN_PROCESSING {\n\tcolor: #645eb4;\n\tbackground: #f0efff;\n}\n\n.CRASHED,\n.REFUSED,\n.ERROR {\n\t/* Additional/Red/Primary */\n\tcolor: #dc3545;\n\t/* Additional/Red/Ghost */\n\tbackground: #fff1f1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TransactionStatus": `TransactionStatus__TransactionStatus-Ctq`,
	"NOT_ACTIVATED": `TransactionStatus__NOT_ACTIVATED-Qzy`,
	"SUSPECT": `TransactionStatus__SUSPECT-bav`,
	"CANCELED": `TransactionStatus__CANCELED-Fz5`,
	"ACTIVATED": `TransactionStatus__ACTIVATED-KVQ`,
	"PENDING": `TransactionStatus__PENDING-qc5`,
	"IN_PROCESSING": `TransactionStatus__IN_PROCESSING-wcf`,
	"CRASHED": `TransactionStatus__CRASHED-GNq`,
	"REFUSED": `TransactionStatus__REFUSED-Hj9`,
	"ERROR": `TransactionStatus__ERROR-YDR`
};
export default ___CSS_LOADER_EXPORT___;
