import { useTranslation } from "pay-kit";
import { CommonWalletData } from "api/walletGroup";
import { Tooltip } from "pay-kit";
import React from "react";

import EyeIconSVG from "./eye.svg";
import styles from "./ProxiesView.module.scss";

interface IProxiesProps {
	readonly proxies: CommonWalletData["proxies"];
}

const ProxiesView: React.FC<IProxiesProps> = ({ proxies }) => {
	const {t} = useTranslation();
	if (!proxies || proxies.length < 1) {
		return <>{t("No")}</>;
	}

	const renderProxies = (
		<div className={styles.proxies}>
			{proxies.map(({ id, ip, port, type, country_code }) => (
				<div key={id} className={styles.proxy}>
					<div>
						<span>IP: </span>
						<span>{ip}</span>
					</div>
					<div>
						<span>{t("Port")}: </span>
						<span>{port}</span>
					</div>
					<div>
						<span>{t("Type")}: </span>
						<span>{type}</span>
					</div>
					<div>
						<span>{t("Country")}: </span>
						<span>{country_code}</span>
					</div>
				</div>
			))}
		</div>
	);

	return (
		<Tooltip tip={renderProxies}>
			<EyeIconSVG />
		</Tooltip>
	);
};

export default ProxiesView;
