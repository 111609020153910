import { useTranslation } from "@paykassma/pay-kit";
import API from "api";
import { WalletListItem } from "api/walletGroup";
import EditableComment from "components/EditableComment";
import React, { useEffect, useState } from "react";
import { errorsMap } from "utils/enums";

interface IUpdateCommentProps {
	readonly hash_id: WalletListItem["hash_id"];
	readonly comment: WalletListItem["comment"];
	readonly onSuccess: (comment: string | null) => void;
}

const EditableCommentContainer: React.FC<IUpdateCommentProps> = (props) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [comment, setComment] = useState<string | null>(props.comment);
	const [editingModeOn, setEditingModeOn] = useState<boolean>(false);

	const { t } = useTranslation();

	const updateSettings = (comment: string | null) => {
		setIsLoading(true);

		API.wallet
			.updateSetting({ hash_id: props.hash_id, comment } as any)
			.then((res) => {
				if (res?.status === "success") {
					setIsLoading(false);
					window.pushAlert({ description: t("Comment updated"), type: "success" });
					setEditingModeOn(false);
					props.onSuccess(comment);

					if (comment === null) {
						setComment(null);
					}

					return res;
				} else {
					throw new Error(errorsMap.anyResponse);
				}
			})
			.catch((err) => {
				console.error(err);
				setIsLoading(false);
				window.pushAlert({ description: t("Failed to update comment"), type: "error" });
			});
	};

	useEffect(() => {
		setComment(props.comment);
	}, [editingModeOn]);

	return (
		<EditableComment
			data-test-id="useless-data-test-id"
			{...{
				isLoading,
				editingModeOn,
				setEditingModeOn,
				initialState: props.comment,
				value: comment,
				onChange: setComment,
				onSubmit: updateSettings,
			}}
		/>
	);
};

export default EditableCommentContainer;
