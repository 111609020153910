import { FC, ReactElement } from "react";
import { useTranslation } from "pay-kit";

import styles from "./uploadFile.module.scss";

const UploadFile: FC<UploadFileType> = ({
	children,
	Icon,
	invalidFormat,
	fileSizeExceeded,
	mouseOver
}) => {
	const {t} = useTranslation();
	
	const invalidFieldBackgroundStyle = mouseOver ? [styles.invalidFormatBackground, styles.mouseOverInvalidField].join(" ") : styles.invalidFormatBackground;
	const validFieldBackgroundStyle = mouseOver ? [styles.hintContainer, styles.mouseOverField].join(" ") : styles.hintContainer;

	const hintStyle = invalidFormat || fileSizeExceeded ? [validFieldBackgroundStyle, invalidFieldBackgroundStyle].join(" ") : validFieldBackgroundStyle;

	return (
		<div className={hintStyle}>
			{Icon}
			{invalidFormat && <div className={styles.boldStyle}>{t("Could not recognize the file")}</div>}
			{fileSizeExceeded && <div className={styles.boldStyle}>{t("File size exceeded")}</div>}
			<div className={styles.allowedFormatsList}>
				{t("Drop the file here in one of the following formats")}
				<span className={styles.boldStyle}>
					gif, mp4, m4v, mpeg, mpg
				</span>
			</div>
			<div className={styles.orWord}>{t("or")}</div>
			<label className={styles.hintLabel}>
				{t("Upload file")}
				{children}
			</label>
		</div>
	);
};

export default UploadFile;

type UploadFileType = {
	readonly children: ReactElement
	readonly Icon: ReactElement
	readonly invalidFormat?: boolean
	readonly fileSizeExceeded?: boolean
	readonly mouseOver?: boolean
}