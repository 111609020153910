import { useTranslation } from "pay-kit";
import { IPossibleTransaction } from "api/transactionGroup";
import Money from "components/Money";
import PaymentSystemLogo from "components/PaymentSystemLogo";
import TextWithCopyButton from "components/TextWithCopyButton";
import { ScrollBox } from "pay-kit";
import React from "react";

import PossibleTransactionStatus from "../../../components/PossibleTransactionStatus";
import Actions from "../../Actions";
import styles from "./CommonData.module.scss";

interface ICommonDataProps {
	readonly transactionData: IPossibleTransaction;
}

const CommonData: React.FC<ICommonDataProps> = ({ transactionData }) => {
	const {t} = useTranslation();
	const ROWS_MAP = [
		{
			id: "wallet_type",
			title: t("Wallet type"),
			render: ({ wallet_type }: IPossibleTransaction) => <PaymentSystemLogo id={wallet_type} hideLabel={true} />
		},
		{
			id: "wallet_type",
			title: t("Name"),
			render: ({ wallet_type }: IPossibleTransaction) => <PaymentSystemLogo id={wallet_type} hideLogo={true} />
		},
		{
			id: "id",
			title: "ID",
			render: ({ primary_id }: IPossibleTransaction) => <TextWithCopyButton text={primary_id} />
		},
		{
			id: "id",
			title: t("Transaction number"),
			render: ({ id }: IPossibleTransaction) => <TextWithCopyButton text={id} />
		},
		{
			id: "amount",
			title: t("Amount"),
			render: ({ amount }: IPossibleTransaction) => (
				<Money amount={amount} />
			)
		},
		{
			id: "created_at",
			title: t("Creation date")
		},
		{
			id: "merchant_order_id",
			title: t("Order ID"),
			render: ({ merchant_order_id }: IPossibleTransaction) => <TextWithCopyButton text={merchant_order_id} />
		},
		{
			id: "label",
			title: t("Label"),
			render: ({ label }: IPossibleTransaction) => <TextWithCopyButton text={label} />
		},
		{
			id: "status",
			title: t("Status"),
			render: ({ status }: IPossibleTransaction) => (<PossibleTransactionStatus status={status} />)
		}
	];

	return (
		<div className={styles.CommonData}>
			<ScrollBox>
				{ROWS_MAP.map((row) => {
					const value = transactionData[row.id as keyof IPossibleTransaction];
					const renderedValue = row.render ? row.render(transactionData) : value;

					return (
						<div key={`${row.id}_${row.title}`} className={styles.row}>
							<div className={styles.title}>{row.title}</div>
							<div className={styles.value}>{renderedValue}</div>
						</div>
					);
				})}
			</ScrollBox>
			<div className={styles.actions}>
				<Actions
					is_manual={transactionData.is_manual}
					primary_id={transactionData.primary_id}
					screenshot={transactionData.screenshot}
					status={transactionData.status}
				/>
			</div>
		</div>
	);
};


export default CommonData;
