import { useTranslation } from "pay-kit";
import { getPostbackListType, StatusCodeType } from "api/stockpilingsGroup";
import Mark from "assets/icons/mark.svg";
import TextWithCopyButton from "modules/Stockpilings/components/TextWithCopyButton";
import { getDateString } from "modules/Stockpilings/contexts/StockPilingsListContext/helpers";
import { ScrollBox } from "pay-kit";
import { FC } from "react";

import styles from "./postback.module.scss";

type PartialGetPostbackListType = Partial<getPostbackListType>;

const Postback: FC<PartialGetPostbackListType> = ({ ...postback }) => {
	const {t} = useTranslation();
	const { status_code, request_body } = postback;
	// if (!Object.entries(postback).length) return <div className={styles.emptyPostbacks}>Список постбeков пуст</div>;
	if (!Object.entries(postback).length) return <div className={styles.emptyPostbacks}>{t("Postack list is empty")}</div>;

	const markElement = status_code ? (
		<span className={styles.markElement}>
			<Mark />
		</span>
	) : null;
	const ROWS_MAP = [
		{
			id: "id",
			title: "ID",
			render: ({ id }: PartialGetPostbackListType) => (
				<TextWithCopyButton copy={true} rowValue={id} copiedTextValue={id} copiedTitle="ID" />
			)
		},
		{
			id: "url",
			title: t("Url"),
			render: ({ url }: PartialGetPostbackListType) => (
				<TextWithCopyButton copy={true} rowValue={url} copiedTextValue={url} copiedTitle={t("Url")} />
			)
		},
		{
			id: "status_code",
			title: t("Status code"),
			render: ({ status_code }: PartialGetPostbackListType) => {
				const statusCodeStyle = {
					200: styles.successStatus,
					400: styles.errorStatus,
					501: styles.errorStatus,
					500: styles.errorStatus
				};

				return (
					<div className={styles.row}>
						<div className={[styles.rowValue, statusCodeStyle[status_code as StatusCodeType]].join(" ")}>
							{status_code}
						</div>
					</div>
				);
			}
		},
		{
			id: "response",
			title: t("Response")
		},
		{
			id: "status",
			title: t("Status"),
			render: () => <TextWithCopyButton rowValue={markElement} />
		},
		{
			id: "created_at",
			title: t("Creation date"),
			render: ({ created_at }: PartialGetPostbackListType) => (
				<div className={styles.row}>{getDateString(created_at || "")}</div>
			)
		},
		{
			id: "request_body",
			title: t("Request body"),
			render: ({ request_body }: PartialGetPostbackListType) => (
				<TextWithCopyButton copy={true} copiedTextValue={request_body} copiedTitle={t("Request body")} />
			)
		}
	];

	return (
		<div>
			{ROWS_MAP.map((row, i) => (
				<div className={styles.rowContainer} key={i}>
					<div className={styles.rowTitle}>{row.title}</div>
					{row.render ? (
						row.render(postback)
					) : (
						<div className={styles.row}>{postback[row.id as keyof getPostbackListType]}</div>
					)}
				</div>
			))}
			<div className={styles.requestBody}>
				<ScrollBox className={styles.scrollBox}>
					<div className={styles.requestBodyValue}>{request_body}</div>
				</ScrollBox>
			</div>
		</div>
	);
};
export default Postback;
