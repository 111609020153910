import { FC } from "react";
import { useTranslation } from "pay-kit";

import styles from "./hint.module.scss";

const Hint: FC<HintType> = ({ url, format, isGif }) => {
	const {t} = useTranslation();

	return isGif ? <img src={url} alt="hint" /> : (
		<video width="496" height="auto" controls autoPlay>
			<source src={url} type={format} />
			{t("Your browser does not support video tag")}
		</video>
	);
};

export default Hint;

type HintType = {
	readonly url?: string
	readonly format?: string
	readonly isGif?: boolean
}