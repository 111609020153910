import AddProxy from "modules/Proxies/components/AddProxyModal";
import UploadedProxiesListTable from "modules/Proxies/components/UploadedProxiesListTable";
import { Loader, ScrollBox, useTranslation } from "pay-kit";
import { FC } from "react";

import styles from "./addProxy.module.scss";

const ProxyModalContentManager: FC<ProxyModalContentManagerType> = ({
	isProxyAddingLoaderLoading,
	isUploadedProxiesListLoading,
}) => {
	const { t } = useTranslation();

	if (isProxyAddingLoaderLoading)
		return (
			<div className={styles.proxyLoader}>
				<Loader />
				<span className={styles.proxyLoaderText}>{t(`Checking`)}...</span>
			</div>
		);

	if (isUploadedProxiesListLoading)
		return (
			<ScrollBox className={styles.UploadedProxiesListTableScroll}>
				<UploadedProxiesListTable />
			</ScrollBox>
		);

	return <AddProxy />;
};

export default ProxyModalContentManager;

type ProxyModalContentManagerType = {
	readonly isProxyAddingLoaderLoading: boolean;
	readonly isUploadedProxiesListLoading: boolean;
};
