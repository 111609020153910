import Plugin from "modules/PaymentSystems/Plugin";
import { useTranslation } from "pay-kit";
import { useEffect } from "react";

export default function PluginPage() {
	const { t } = useTranslation();

	useEffect(() => {
		document.title = t("Plugin");
	}, []);

	return <Plugin />;
}
