// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Filter__field-hvz{flex:1}`, "",{"version":3,"sources":["webpack://./src/modules/Wallets/components/Filter/Filter.module.scss"],"names":[],"mappings":"AAAA,mBACC,MAAA","sourcesContent":[".field {\n\tflex: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": `Filter__field-hvz`
};
export default ___CSS_LOADER_EXPORT___;
