import { UpdatePrivateSettingsType } from "api/settingsGroup";
import useGetPrivateSettings from "modules/AppSettings/AppSettingsPrivate/hooks/useGetPrivateSettings";
import useUpdatePrivateSettings from "modules/AppSettings/AppSettingsPrivate/hooks/useUpdatePrivateSettings";
import LabelWithHint from "modules/AppSettings/components/LabelWithHint";
import TabLoader from "modules/AppSettings/components/TabLoader";
import { useTranslation } from "pay-kit";
import { PayKitForm } from "pay-kit";
import { ReactElement, useEffect } from "react";

import styles from "./appSettingsPrivate.module.scss";

const AppSettingsPrivate = () => {
	const getPrivateSettingsAPI = useGetPrivateSettings();
	const updatePrivateSettingsAPI = useUpdatePrivateSettings();
	const { t } = useTranslation();

	useEffect(() => {
		getPrivateSettingsAPI.load();
	}, []);

	const isRequired = (value: number | string) => (!value ? t("Required field") : undefined);
	const onSubmit: OnSubmitType = (state) => updatePrivateSettingsAPI.update(state);

	const SCHEMA = [
		{
			type: "Group",
			render: (elements: readonly ReactElement[]) => (
				<div className={styles.postbackBlockWrapper}>
					<div className={styles.commonHeader}>{t(`Postback`)}</div>
					<div className={styles.postbackFieldWrapper}>{elements}</div>
				</div>
			),
			elements: [
				{
					name: "postback_access_key",
					type: "TextInput",
					label: (
						<LabelWithHint
							className={styles.hint}
							text={t(`Postback access key`)}
							tip={t(`Public access key used to encrypt postback data`)}
						/>
					),
					className: styles.postbackField,
					validation: [isRequired],
				},
				{
					name: "postback_private_access_key",
					type: "TextInput",
					label: (
						<LabelWithHint
							className={styles.hint}
							text={t(`Private postback access key`)}
							tip={t(`Secret access key used to decrypt postback data`)}
						/>
					),
					className: styles.postbackField,
					validation: [isRequired],
				},
				{
					type: "Group",
					render: (element: ReactElement) => (
						<div className={styles.depositWrapper}>
							<div className={styles.header}>{t(`Deposit`)}</div>
							{element}
						</div>
					),
					elements: [
						{
							name: "postback_url",
							type: "TextInput",
							label: (
								<LabelWithHint
									className={styles.hint}
									text={t(`Postback URL of the deposit`)}
									tip={t(`URL to which a postback will be sent about crediting funds to the user's account`)}
								/>
							),
							className: styles.postbackField,
							validation: [isRequired],
						},
					],
				},
				{
					type: "Group",
					render: (element: ReactElement) => (
						<div className={styles.depositWrapper}>
							<div className={styles.header}>{t("Withdrawal")}</div>
							{element}
						</div>
					),
					elements: [
						{
							name: "withdrawal_postback_url",
							type: "TextInput",
							label: (
								<LabelWithHint
									className={styles.hint}
									text={t(`Postback URL for manual output`)}
									tip={t(
										`URL to which the postback will be sent about the withdrawal of funds from the user's account`
									)}
								/>
							),
							className: styles.postbackField,
							validation: [isRequired],
						},
						{
							name: "delete_withdrawals_after_days",
							type: "TextInput",
							label: (
								<LabelWithHint
									className={styles.hint}
									text={t("Number of downtime days to delete withdrawals")}
									tip={t(`The number of days after which the unprocessed withdrawal will be deleted from the System`)}
								/>
							),
							className: styles.withdrawalDeletion,
							validation: [isRequired],
						},
					],
				},
			],
		},
		{
			type: "Group",
			render: (element: ReactElement) => <div className={styles.submitButton}>{element}</div>,
			elements: [
				{
					name: "submit",
					type: "SubmitButton",
					label: t(`Save changes`),
					onSubmit: onSubmit,
					isLoading: updatePrivateSettingsAPI.isLoading,
				},
			],
		},
	];

	const INIT_STATE = {
		delete_withdrawals_after_days: getPrivateSettingsAPI.data?.delete_withdrawals_after_days,
		postback_access_key: getPrivateSettingsAPI.data?.postback_access_key,
		postback_private_access_key: getPrivateSettingsAPI.data?.postback_private_access_key,
		postback_url: getPrivateSettingsAPI.data?.postback_url,
		withdrawal_postback_url: getPrivateSettingsAPI.data?.withdrawal_postback_url,
	};

	if (!getPrivateSettingsAPI.data) return <TabLoader />;

	return (
		<div className={styles.appSettingsPrivateWrapper}>
			<PayKitForm.Builder schema={SCHEMA} initialState={INIT_STATE} />
		</div>
	);
};

export default AppSettingsPrivate;

type OnSubmitType = (state: UpdatePrivateSettingsType) => void;
