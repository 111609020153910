// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WithdrawalsLogsList__withdrawalsLogsTableWrapper-Dae{margin-top:36px}.WithdrawalsLogsList__withdrawalsLogsTableWrapper-Dae .WithdrawalsLogsList__withdrawalsLogsTable-rYI{margin:24px 0}.WithdrawalsLogsList__withdrawalsLogsTableWrapper-Dae .WithdrawalsLogsList__withdrawalsLogsTable-rYI col:nth-child(1){width:130px}.WithdrawalsLogsList__withdrawalsLogsTableWrapper-Dae .WithdrawalsLogsList__withdrawalsLogsTable-rYI col:nth-child(2){width:962px}.WithdrawalsLogsList__withdrawalsLogsTableWrapper-Dae .WithdrawalsLogsList__withdrawalsLogsTable-rYI col:nth-child(3){width:130px}.WithdrawalsLogsList__logsFilters-QcU{margin-top:36px}.WithdrawalsLogsList__logsFilters-QcU .WithdrawalsLogsList__selectField-yY8{max-width:180px;width:100%}`, "",{"version":3,"sources":["webpack://./src/modules/WalletsDebugging/WithdrawalsLogsList/withdrawalsLogsList.module.scss"],"names":[],"mappings":"AAAA,sDACC,eAAA,CAEA,qGACC,aAAA,CAEA,sHACC,WAAA,CAGD,sHACC,WAAA,CAGD,sHACC,WAAA,CAKH,sCACC,eAAA,CAEA,4EACC,eAAA,CACA,UAAA","sourcesContent":[".withdrawalsLogsTableWrapper {\n\tmargin-top: 36px;\n\n\t.withdrawalsLogsTable {\n\t\tmargin: 24px 0;\n\n\t\tcol:nth-child(1) {\n\t\t\twidth: 130px;\n\t\t}\n\n\t\tcol:nth-child(2) {\n\t\t\twidth: 962px;\n\t\t}\n\n\t\tcol:nth-child(3) {\n\t\t\twidth: 130px;\n\t\t}\n\t}\n}\n\n.logsFilters {\n\tmargin-top: 36px;\n\n\t.selectField {\n\t\tmax-width: 180px;\n\t\twidth: 100%;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"withdrawalsLogsTableWrapper": `WithdrawalsLogsList__withdrawalsLogsTableWrapper-Dae`,
	"withdrawalsLogsTable": `WithdrawalsLogsList__withdrawalsLogsTable-rYI`,
	"logsFilters": `WithdrawalsLogsList__logsFilters-QcU`,
	"selectField": `WithdrawalsLogsList__selectField-yY8`
};
export default ___CSS_LOADER_EXPORT___;
