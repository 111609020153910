import { useTranslation } from "pay-kit";
import { GetListByStockPilingType } from "api/stockpilingsGroup";
import Mark from "assets/icons/mark.svg";
import Money from "components/Money";
import TextWithCopyButton from "components/TextWithCopyButton";
import { TransactionByStockpilingIdType } from "modules/Stockpilings/components/Columns/contexts/StockpilingsContext";
import TransactionKind from "modules/Stockpilings/components/TransactionKind";
import { Table } from "pay-kit";
import { FC } from "react";

import styles from "./stockpilingTransactions.module.scss";

const StockpilingTransactions: FC<StockpilingTransactionsType> = ({ transactionByStockpilingId }) => {
	const { t } = useTranslation();

	const transactionsColumns = [
		{
			title: t("Wallet number"),
			dataIndex: "wallet_identifier",
			key: "wallet_identifier",
			render: ({ wallet_identifier }: TransactionByStockpilingIdType) => (
				<TextWithCopyButton className={styles.walletIdentifier} text={wallet_identifier} hideCopyButton={true} />
			),
		},
		{
			title: t("Transaction number"),
			dataIndex: "id",
			key: "id",
			render: ({ id }: TransactionByStockpilingIdType) => (
				<TextWithCopyButton className={styles.walletIdentifier} text={id} hideCopyButton={true} />
			),
		},
		{
			title: t("Transaction amount"),
			dataIndex: "amount",
			key: "amount",
			render: ({ symbol, amount }: TransactionByStockpilingIdType) => <Money amount={amount} currencyCode={symbol} />,
		},
		{
			title: t("Date"),
			dataIndex: "created_at",
			key: "created_at",
			render: ({ created_at }: TransactionByStockpilingIdType) => {
				const [date, time] = created_at.split(" ");
				const [year, month, day] = date.split("-");

				return (
					<>
						{day}.{month}.{year} {time}
					</>
				);
			},
		},
		{
			title: t("Transaction kind"),
			dataIndex: "type",
			key: "type",
			render: ({ type, status, direction }: TransactionByStockpilingIdType) => (
				<TransactionKind type={type} status={status} direction={direction} />
			),
		},
		{
			title: t("Archived transaction"),
			dataIndex: "is_archived",
			key: "is_archived",
			className: styles.checkMark,
			render: ({ is_archived }: TransactionByStockpilingIdType) => <>{is_archived && <Mark />}</>,
		},
	];

	return (
		<Table
			className={styles.stockPilingTransactionsTable}
			rowKey={"id"}
			data={transactionByStockpilingId || []}
			columns={transactionsColumns}
			isLoading={false}
			selecton={{ rowsCount: 6 }}
		/>
	);
};

export default StockpilingTransactions;

type StockpilingTransactionsType = {
	readonly transactionByStockpilingId: GetListByStockPilingType["transactions"] | null;
};
