// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.component__time-FRl{margin-top:4px;color:#697077}.component__messageWrapper-lyg{width:300px}.component__messageWrapper-lyg>p{width:962px}`, "",{"version":3,"sources":["webpack://./src/modules/WalletsDebugging/WithdrawalsLogsList/component/withdrawalsLogsListColumns.module.scss"],"names":[],"mappings":"AAAA,qBACC,cAAA,CACA,aAAA,CAGD,+BACC,WAAA,CAEA,iCACC,WAAA","sourcesContent":[".time {\n\tmargin-top: 4px;\n\tcolor: #697077;\n}\n\n.messageWrapper {\n\twidth: 300px;\n\n\t& > p {\n\t\twidth: 962px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"time": `component__time-FRl`,
	"messageWrapper": `component__messageWrapper-lyg`
};
export default ___CSS_LOADER_EXPORT___;
