// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PaymentSystemsPage__panel-nTe{padding:48px 0}`, "",{"version":3,"sources":["webpack://./src/layout/pages/PaymentSystemsPage/styles.module.scss"],"names":[],"mappings":"AAAA,+BACI,cAAA","sourcesContent":[".panel {\n    padding: 48px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": `PaymentSystemsPage__panel-nTe`
};
export default ___CSS_LOADER_EXPORT___;
