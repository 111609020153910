// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[class^="Modal__content"][class^="Modal__content"][class^="Modal__content"]{display:block}.RemoveConfirmation__actions-Shk{display:flex;justify-content:flex-end;gap:16px;margin-top:32px}`, "",{"version":3,"sources":["webpack://./src/modules/PaymentSystems/PaymentSystemsList/components/ActionsProvider/modals/RemoveConfirmation/RemoveConfirmation.module.scss"],"names":[],"mappings":"AACA,4EACI,aAAA,CAGJ,iCACI,YAAA,CACA,wBAAA,CACA,QAAA,CACA,eAAA","sourcesContent":["// TODO: remove after disabling display: flex for Modal content in UI Kit\n:global([class^=\"Modal__content\"][class^=\"Modal__content\"][class^=\"Modal__content\"]) {\n    display: block;\n}\n\n.actions {\n    display: flex;\n    justify-content: flex-end;\n    gap: 16px;\n    margin-top: 32px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": `RemoveConfirmation__actions-Shk`
};
export default ___CSS_LOADER_EXPORT___;
