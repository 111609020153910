import api from "api/walletTypesGroup";
import { useState } from "react";

import { FormStateType } from "../index";

type useConstructorSettingsHookType = (args: {
	readonly onSuccess?: (res: any) => void;
	readonly onError?: (error: Error) => void;
}) => {
	readonly update: (walletType: string, settings: FormStateType) => void;
	readonly isLoading: boolean;
};

const useUpdateSettingsHook: useConstructorSettingsHookType = ({ onSuccess, onError }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const update = (wallet_type: string, settings: FormStateType) => {
		setIsLoading(true);

		api
			.updateSettings({ ...settings, wallet_type })
			.finally(() => setIsLoading(false))
			.then((result) => {
				onSuccess && onSuccess(result);
				return result;
			})
			.catch((error) => {
				console.error(error);
				onError && onError(error);
			});
	};

	return {
		update,
		isLoading,
	};
};

export default useUpdateSettingsHook;
