import UpAndDownButtons from "components/UpAndDownButtons";
import { useProxyContext } from "modules/Proxies/contexts/ProxyContext";
import { useTranslation } from "pay-kit";

import styles from "./walletAmountColTitle.module.scss";

const WalletAmountColTitle = () => {
	const { setProxies } = useProxyContext();
	const { t } = useTranslation();

	const sortAscending = () => {
		setProxies((prevState) => prevState.slice().sort((a, b) => a.wallets.length - b.wallets.length));
	};

	const sortDescending = () => {
		setProxies((prevState) => prevState.slice().sort((a, b) => b.wallets.length - a.wallets.length));
	};

	return (
		<div className={styles.walletAmountColTitle}>
			<span>{t("Wallets' count")}</span>
			<UpAndDownButtons className={styles.UpAndDownButtons} onUp={sortAscending} onDown={sortDescending} />
		</div>
	);
};

export default WalletAmountColTitle;
