// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Columns__stockPilingsLabel-atu{max-width:98px;width:max-content;overflow:hidden;padding:0 4px 0 2px;border-radius:6px;text-overflow:ellipsis;direction:rtl;text-align:left;background:#e8faed;color:#24a148}.Columns__stockPiling-bNj{display:inline-block;max-width:136.67px;overflow:hidden;text-overflow:ellipsis;border-radius:100px;padding:0 8px}.Columns__isRealHall-tSE{width:max-content}`, "",{"version":3,"sources":["webpack://./src/modules/Stockpilings/components/Columns/columns.module.scss"],"names":[],"mappings":"AAAA,gCACC,cAAA,CACA,iBAAA,CACA,eAAA,CACA,mBAAA,CACA,iBAAA,CACA,sBAAA,CACA,aAAA,CACA,eAAA,CAEA,kBAAA,CAGA,aAAA,CAGD,0BACC,oBAAA,CACA,kBAAA,CACA,eAAA,CACA,sBAAA,CACA,mBAAA,CACA,aAAA,CAGD,yBACC,iBAAA","sourcesContent":[".stockPilingsLabel {\n\tmax-width: 98px;\n\twidth: max-content;\n\toverflow: hidden;\n\tpadding: 0 4px 0 2px;\n\tborder-radius: 6px;\n\ttext-overflow: ellipsis;\n\tdirection: rtl;\n\ttext-align: left;\n\t/* Green/5 */\n\tbackground: #E8FAED;\n\n\t/* Green/50 */\n\tcolor: #24A148;\n}\n\n.stockPiling {\n\tdisplay: inline-block;\n\tmax-width: 136.67px;\n\toverflow: hidden;\n\ttext-overflow: ellipsis;\n\tborder-radius: 100px;\n\tpadding: 0 8px;\n}\n\n.isRealHall {\n\twidth: max-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stockPilingsLabel": `Columns__stockPilingsLabel-atu`,
	"stockPiling": `Columns__stockPiling-bNj`,
	"isRealHall": `Columns__isRealHall-tSE`
};
export default ___CSS_LOADER_EXPORT___;
