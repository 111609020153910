// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ActivatePayTM__form-DDm{width:100%}.ActivatePayTM__form-DDm .ActivatePayTM__inputRow-JMK{display:flex;justify-content:space-between;gap:24px}.ActivatePayTM__form-DDm .ActivatePayTM__inputRow-JMK .ActivatePayTM__label-eRo{line-height:34px}.ActivatePayTM__form-DDm .ActivatePayTM__inputRow-JMK input{width:300px}.ActivatePayTM__form-DDm .ActivatePayTM__actions-LZy{margin-top:16px;display:flex;justify-content:flex-end}.ActivatePayTM__enterSMS-oua{gap:8px}`, "",{"version":3,"sources":["webpack://./src/components/SideModalContent/ActivatePayTM/ActivatePayTM.module.scss"],"names":[],"mappings":"AAAA,yBACC,UAAA,CAEA,sDACC,YAAA,CACA,6BAAA,CACA,QAAA,CAEA,gFACC,gBAAA,CAGD,4DACC,WAAA,CAIF,qDACC,eAAA,CACA,YAAA,CACA,wBAAA,CAIF,6BACC,OAAA","sourcesContent":[".form {\n\twidth: 100%;\n\n\t.inputRow {\n\t\tdisplay: flex;\n\t\tjustify-content: space-between;\n\t\tgap: 24px;\n\n\t\t.label {\n\t\t\tline-height: 34px;\n\t\t}\n\n\t\tinput {\n\t\t\twidth: 300px;\n\t\t}\n\t}\n\n\t.actions {\n\t\tmargin-top: 16px;\n\t\tdisplay: flex;\n\t\tjustify-content: flex-end;\n\t}\n}\n\n.enterSMS {\n\tgap: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `ActivatePayTM__form-DDm`,
	"inputRow": `ActivatePayTM__inputRow-JMK`,
	"label": `ActivatePayTM__label-eRo`,
	"actions": `ActivatePayTM__actions-LZy`,
	"enterSMS": `ActivatePayTM__enterSMS-oua`
};
export default ___CSS_LOADER_EXPORT___;
