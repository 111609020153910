// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UpAndDownButtons__upAndDownButtonsWrapper-97z{display:flex;flex-direction:column;gap:8px;align-items:center;width:11px}.UpAndDownButtons__upAndDownButtonsWrapper-97z .UpAndDownButtons__caret-k6x{display:flex;align-items:center;width:inherit;cursor:pointer}.UpAndDownButtons__upAndDownButtonsWrapper-97z>svg{width:100%;height:100%;line-height:initial}`, "",{"version":3,"sources":["webpack://./src/components/UpAndDownButtons/upAndDownButtons.module.scss"],"names":[],"mappings":"AAAA,+CACC,YAAA,CACA,qBAAA,CACA,OAAA,CACA,kBAAA,CACA,UAAA,CAEA,4EACC,YAAA,CACA,kBAAA,CACA,aAAA,CACA,cAAA,CAGD,mDACC,UAAA,CACA,WAAA,CACA,mBAAA","sourcesContent":[".upAndDownButtonsWrapper {\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 8px;\n\talign-items: center;\n\twidth: 11px;\n\n\t.caret {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\twidth: inherit;\n\t\tcursor: pointer;\n\t}\n\n\t& > svg {\n\t\twidth: 100%;\n\t\theight: 100%;\n\t\tline-height: initial;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upAndDownButtonsWrapper": `UpAndDownButtons__upAndDownButtonsWrapper-97z`,
	"caret": `UpAndDownButtons__caret-k6x`
};
export default ___CSS_LOADER_EXPORT___;
