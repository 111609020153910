// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SelectBuilderButton__addButtonWrapper-zeX{display:flex;justify-content:space-between;align-items:center;margin-top:16px}.SelectBuilderButton__addButtonWrapper-zeX .SelectBuilderButton__walletText-NM7{font-size:14px;font-weight:600}.SelectBuilderButton__errorMessage-zpF{color:red}`, "",{"version":3,"sources":["webpack://./src/modules/Proxies/components/SelectBuilderButton/selectBuilderButton.module.scss"],"names":[],"mappings":"AAAA,2CACC,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,eAAA,CAEA,gFACC,cAAA,CACA,eAAA,CAIF,uCACC,SAAA","sourcesContent":[".addButtonWrapper {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\talign-items: center;\n\tmargin-top: 16px;\n\n\t.walletText {\n\t\tfont-size: 14px;\n\t\tfont-weight: 600;\n\t}\n}\n\n.errorMessage {\n\tcolor: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addButtonWrapper": `SelectBuilderButton__addButtonWrapper-zeX`,
	"walletText": `SelectBuilderButton__walletText-NM7`,
	"errorMessage": `SelectBuilderButton__errorMessage-zpF`
};
export default ___CSS_LOADER_EXPORT___;
