// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BalancesLinks__accountsWrapper-DmX{display:inline-block;color:#21272a;font-size:14px;padding:8px;width:149px;text-overflow:ellipsis}.BalancesLinks__accountsWrapper-DmX .BalancesLinks__accountsBalance-Z8X{display:inline-flex;text-decoration:none;color:initial}.BalancesLinks__accountsWrapper-DmX .BalancesLinks__accountsCurrencyCode-XXE{margin-right:4px;color:#697077}.BalancesLinks__accountsWrapper-DmX:not(:nth-child(6n)){margin-right:36px}`, "",{"version":3,"sources":["webpack://./src/modules/Balance/components/BalancesLinks/balancesLinks.module.scss"],"names":[],"mappings":"AAAA,oCACC,oBAAA,CACA,aAAA,CACA,cAAA,CACA,WAAA,CACA,WAAA,CACA,sBAAA,CAEA,wEACC,mBAAA,CACA,oBAAA,CACA,aAAA,CAGD,6EACC,gBAAA,CACA,aAAA,CAIF,wDACC,iBAAA","sourcesContent":[".accountsWrapper {\n\tdisplay: inline-block;\n\tcolor: #21272A;\n\tfont-size: 14px;\n\tpadding: 8px;\n\twidth: 149px;\n\ttext-overflow: ellipsis;\n\n\t.accountsBalance {\n\t\tdisplay: inline-flex;\n\t\ttext-decoration: none;\n\t\tcolor: initial;\n\t}\n\n\t.accountsCurrencyCode {\n\t\tmargin-right: 4px;\n\t\tcolor: #697077;\n\t}\n}\n\n.accountsWrapper:not(:nth-child(6n)) {\n\tmargin-right: 36px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accountsWrapper": `BalancesLinks__accountsWrapper-DmX`,
	"accountsBalance": `BalancesLinks__accountsBalance-Z8X`,
	"accountsCurrencyCode": `BalancesLinks__accountsCurrencyCode-XXE`
};
export default ___CSS_LOADER_EXPORT___;
