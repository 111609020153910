type TimezoneProps = {
	readonly name: string, readonly value: string
}

export const gettingTimezoneLabel = (timezoneList: readonly TimezoneProps[] = [], timezone = ``) => {
	const elem = timezoneList.find((item: { readonly name: string, readonly value: string }) => item.value === timezone);
	return ({ label: elem?.name || "", value: elem?.value || "" });
};

export const timeZoneLIstFormatter = (arr: readonly TimezoneProps[] = []) => {
	return arr.map(({ name, value }: TimezoneProps) => ({ label: name, value }));
};
