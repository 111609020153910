import API from "api";
import { useCallback, useState } from "react";

const useCancelBalance = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<Error | null>(null);

	const update: UpdateHandlerType = useCallback((id, onSuccess) => {
		setIsLoading(true);
		setError(null);

		API.balances
			.cancelBalance(id)
			.then((res) => {
				if (res.status === "success") {
					onSuccess && onSuccess();
				}
			})
			.catch((e) => {
				console.error(e);
				setError(e);
			})
			.finally(() => setIsLoading(false));
	}, []);

	return {
		update,
		isLoading,
		error,
	};
};

export default useCancelBalance;

type UpdateHandlerType = (id: string, onSuccess?: () => void) => void;
