// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LimitsSettings__modal-doE{min-width:880px}`, "",{"version":3,"sources":["webpack://./src/modules/PaymentSystems/PaymentSystemsList/components/ActionsProvider/modals/LimitsSettings/LimitsSettings.module.scss"],"names":[],"mappings":"AAAA,2BACI,eAAA","sourcesContent":[".modal {\n    min-width: 880px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `LimitsSettings__modal-doE`
};
export default ___CSS_LOADER_EXPORT___;
