// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Withdrawal__amountField-Z2b{gap:24px}.Withdrawal__addressField-eBq{gap:24px}.Withdrawal__submitButton-KSd{margin-top:16px}.Withdrawal__submitButton-KSd>button{margin-left:auto}`, "",{"version":3,"sources":["webpack://./src/modules/TransferHistory/components/Withdrawal/withdrawal.module.scss"],"names":[],"mappings":"AAAA,6BACC,QAAA,CAGD,8BACC,QAAA,CAGD,8BACC,eAAA,CAEA,qCACC,gBAAA","sourcesContent":[".amountField {\n\tgap: 24px;\n}\n\n.addressField {\n\tgap: 24px;\n}\n\n.submitButton {\n\tmargin-top: 16px;\n\n\t& > button {\n\t\tmargin-left: auto;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"amountField": `Withdrawal__amountField-Z2b`,
	"addressField": `Withdrawal__addressField-eBq`,
	"submitButton": `Withdrawal__submitButton-KSd`
};
export default ___CSS_LOADER_EXPORT___;
