import DefaultIcon from "assets/icons/defaultIcon.svg";
import { WalletTypesContext } from "contexts/WalletTypesContext";
import React, { useContext } from "react";

type PaymentSystemLogoPropsType = {
	readonly id: string;
	readonly hideLabel?: boolean;
	readonly hideLogo?: boolean;
	readonly classNames?: {
		readonly wrapper?: string;
		readonly logo?: string;
		readonly caption?: string;
	};
	readonly dataTestId?: string;
};

import { Tooltip } from "@paykassma/pay-kit";

import styles from "./PaymentSystemLogo.module.scss";

const PaymentSystemLogo: React.FC<PaymentSystemLogoPropsType> = ({
	id,
	hideLabel,
	hideLogo,
	classNames,
	dataTestId
}) => {
	const { walletTypes, isLoading } = useContext(WalletTypesContext);

	if (isLoading || walletTypes.length < 1) {
		return null;
	}

	const currentWalletType = walletTypes.find((wt) => wt.code === id);

	const inlineLogoStyle = { backgroundImage: `url(${currentWalletType?.logo || ""})` };

	const logoStyle = currentWalletType ? styles.logo : `${styles.logo} ${styles.defaultLogo}`;

	return (
		<div className={[styles.PaymentSystemBadge, classNames?.wrapper].join(" ")}>
			{!hideLogo && (
				<div className={[logoStyle, classNames?.logo].join(" ")} style={inlineLogoStyle}>
					{!currentWalletType && (
						<>
							<div><DefaultIcon /></div>
							{id ? (
								<Tooltip tip={id} preferredSide="bottom">
									<div className={styles.withoutLogo} data-test-id={dataTestId}>{id}</div>
								</Tooltip>) :
								`N/A`
							}
						</>
					)}
				</div>
			)}
			{!hideLabel && currentWalletType?.name &&
				<div
					className={[styles.caption, classNames?.caption].join(" ")}
					data-test-id={dataTestId}
				>
					{currentWalletType?.name || ""}</div>}
		</div>
	);
};

export default PaymentSystemLogo;
