import { FC } from "react";
import Menu from "src/components/Menu";
import PayLogo from "src/components/PayLogo";

import styles from "./Sidebar.module.scss";

export const Sidebar: FC = () => {
	return (
		<aside className={styles.sidebar} id="mainSidebar">
			<div className={styles.container}>
				<PayLogo />
				<Menu />
			</div>
		</aside>
	);
};
