// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WalletsLoad__walletsLoad-vqH{display:flex;width:100%;gap:48px;margin-bottom:40px}.WalletsLoad__walletsLoad-vqH .WalletsLoad__content-MPk{flex:1}.WalletsLoad__walletsLoad-vqH .WalletsLoad__controls-cCJ{width:490px}`, "",{"version":3,"sources":["webpack://./src/modules/WalletsLoad/WalletsLoad.module.scss"],"names":[],"mappings":"AAAA,8BACI,YAAA,CACA,UAAA,CACA,QAAA,CACA,kBAAA,CAEA,wDACI,MAAA,CAGJ,yDACI,WAAA","sourcesContent":[".walletsLoad {\n    display: flex;\n    width: 100%;\n    gap: 48px;\n    margin-bottom: 40px;\n\n    .content {\n        flex: 1;\n    }\n\n    .controls {\n        width: 490px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"walletsLoad": `WalletsLoad__walletsLoad-vqH`,
	"content": `WalletsLoad__content-MPk`,
	"controls": `WalletsLoad__controls-cCJ`
};
export default ___CSS_LOADER_EXPORT___;
