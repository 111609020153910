import { postbackType } from "api/postbackGroup";
import { WithdrawPostbackStatusENUM } from "api/withdrawalGroup";
import { useTranslation } from "pay-kit";
import { Button, Loader, ScrollBox } from "pay-kit";
import React, { useEffect } from "react";

import PostbackStatus from "../../../columns/components/PostbackStatus";
import useForceSendHook from "./hooks/useForceSendHook";
import useGetPostbackData from "./hooks/useGetPostbackData";
import styles from "./Postback.module.scss";
import StatusCode from "./StatusCode";
import TextWithCopyButton from "./TextWithCopyButton";

interface IPostbackProps {
	readonly postback_status: WithdrawPostbackStatusENUM;
	readonly postback_id: number;
	readonly direction: "outgoing" | "ingoing";
}

export const Postback: React.FC<IPostbackProps> = ({ postback_status, postback_id, direction }) => {
	const { t } = useTranslation();
	const forceSendAPI = useForceSendHook({ onSuccess: () => getPostbackDataAPI.load(postback_id, direction) });
	const getPostbackDataAPI = useGetPostbackData();

	useEffect(() => {
		getPostbackDataAPI.load(postback_id, direction);
	}, [postback_id]);

	if (getPostbackDataAPI.isLoading) {
		return (
			<div className={styles.Postback}>
				<Loader />
			</div>
		);
	}

	const ROWS_MAP = [
		{
			id: "id",
			// title: "ID",
			title: t("ID"),
			render: ({ id }: postbackType) => <TextWithCopyButton textClassName={styles.longString} text={id} />,
		},
		{
			id: "url",
			title: t("Url"),
			render: ({ url }: postbackType) => <TextWithCopyButton textClassName={styles.longString} text={url} />,
		},
		{
			id: "status_code",
			title: t("Status code"),
			render: ({ status_code }: postbackType) => <StatusCode code={status_code} />,
		},
		{
			id: "response",
			title: t("Response"),
			render: ({ response }: postbackType) => <TextWithCopyButton textClassName={styles.longString} text={response} />,
		},
		{
			id: "status",
			title: t("Status"),
			render: () => <PostbackStatus status={postback_status} showCaption />,
		},
		{ id: "created_at", title: t("Creation date") },
		{
			id: "request_body",
			title: t("Request body"),
			render: ({ request_body }: postbackType) => (
				<TextWithCopyButton
					className={styles.requestBody}
					textClassName={styles.requestBodyString}
					text={request_body}
					data-test-id="useless-data-test-id"
				/>
			),
		},
	];

	if (getPostbackDataAPI.postbackData === undefined) {
		// return <>Нет данных</>;
		return <>{t("No data")}</>;
	}

	const postbackData = getPostbackDataAPI.postbackData as postbackType;

	return (
		<div className={styles.Postback}>
			<ScrollBox className={styles.content}>
				{ROWS_MAP.map((row) => {
					const value = postbackData[row.id as keyof postbackType];

					return (
						<div key={`${row.id}_${row.title}`} className={styles.row}>
							<div className={styles.title}>{row.title}</div>
							<div className={styles.value}>{row.render ? row.render(postbackData) : `${value}`}</div>
						</div>
					);
				})}

				<ScrollBox className={styles.requestBodyFull}>{postbackData.request_body}</ScrollBox>
			</ScrollBox>
			<div className={styles.actions}>
				{postback_status === WithdrawPostbackStatusENUM.ERROR && (
					<Button
						onClick={() => forceSendAPI.sendForceSendRequest(postback_id)}
						variant="primary"
						data-test-id="resendPostbackButton"
						isLoading={forceSendAPI.isLoading}
					>
						{t("Resend")}
					</Button>
				)}
			</div>
		</div>
	);
};
