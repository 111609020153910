// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CheckingStatus__badgeMaxContent-gTG{width:max-content}.CheckingStatus__inSystemBadge-cZf{margin-top:4px}`, "",{"version":3,"sources":["webpack://./src/modules/Proxies/components/UploadedProxiesListTable/components/CheckingStatus/checkingStatus.module.scss"],"names":[],"mappings":"AAAA,qCACC,iBAAA,CAGD,mCACC,cAAA","sourcesContent":[".badgeMaxContent {\n\twidth: max-content;\n}\n\n.inSystemBadge {\n\tmargin-top: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badgeMaxContent": `CheckingStatus__badgeMaxContent-gTG`,
	"inSystemBadge": `CheckingStatus__inSystemBadge-cZf`
};
export default ___CSS_LOADER_EXPORT___;
