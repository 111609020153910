import API from "api";
import { WalletType } from "api/walletTypesGroup";
import AuthContext from "contexts/AuthContext";
import React, { useContext, useEffect, useState } from "react";
import { errorsMap } from "utils/enums";

export type WalletTypesContext = {
	readonly walletTypes: readonly WalletType[];
	readonly isLoading: boolean;
	readonly error: null | Error;
	readonly getWalletTypesList: () => void;
};

export const WalletTypesContext = React.createContext<WalletTypesContext>({
	walletTypes: [] as readonly WalletType[],
	getWalletTypesList: () => {},
	isLoading: false,
	error: null,
});
WalletTypesContext.displayName = "WalletTypesContext";

type WalletTypesContextProviderPropsType = {
	readonly children: React.ReactNode;
};

const WalletTypesContextProvider = ({ children }: WalletTypesContextProviderPropsType) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [walletTypes, setWalletTypes] = useState<readonly WalletType[]>([]);
	const [error, setError] = useState<null | Error>(null);
	const { isAuth } = useContext(AuthContext);

	const getWalletTypesList = () => {
		setIsLoading(true);
		setWalletTypes([]);

		API.walletType
			.getList()
			.then((res: any) => {
				if (res?.status === "success" && res?.["data"]) {
					setWalletTypes(res?.["data"]);
					return res;
				}

				throw new Error("Unexpected response in getWalletTypesList (WalletTypesContextProvider)");
			})
			.finally(() => setIsLoading(false))
			.catch((err) => {
				console.error(err);
				setError(err);
				window.pushAlert({ type: "error", description: errorsMap.cantGetWalletsData });
			});
	};

	useEffect(() => {
		if (isAuth) {
			getWalletTypesList();
		}
	}, [isAuth]);

	return (
		<WalletTypesContext.Provider
			value={{
				isLoading,
				walletTypes,
				getWalletTypesList,
				error,
			}}
		>
			{children}
		</WalletTypesContext.Provider>
	);
};

export default WalletTypesContextProvider;

export const useWalletTypesContext = () => useContext(WalletTypesContext);
