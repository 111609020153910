import API from "api";
import { UpdatePluginSettingsType } from "api/settingsGroup";
import { useTranslation } from "pay-kit";
import { useCallback, useState } from "react";
import { errorsMap } from "utils/enums";

const useUpdatePluginSettings = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<null | Error>(null);
	const { t } = useTranslation();

	const update = useCallback((data: UpdatePluginSettingsType) => {
		setIsLoading(true);
		setError(null);

		API.settings
			.updatePluginSettings(data)
			.then((res) => {
				if (res.status === "ok") {
					window.pushAlert({
						title: t("Success"),
						description: t(`Item has been successfully updated`),
						type: "success",
					});
				} else {
					// throw new Error("Unexpected response in useUpdatePluginSettings");
					throw new Error(errorsMap.anyResponse);
				}

				return res;
			})
			.catch((err) => {
				setError(err);
			})
			.finally(() => setIsLoading(false));
	}, []);

	return {
		update,
		isLoading,
		error,
	};
};
export default useUpdatePluginSettings;
