import { useTranslation } from "pay-kit";
import styles from './FormattedError.module.scss';

type FormattedErrorsProps = {
	readonly error: {
		readonly [date: string]: readonly number[];
	};
	readonly title: string;
};

const FormattedError = ({ error, title }: FormattedErrorsProps) => {
	const { t } = useTranslation();

	if (error === null) return null;

	return (
		<div className={styles.formattedError}>
			<div className={styles.title}>{title}</div>
			{Object.keys(error).map((date: string) => (
				<div key={date}>
					{/* Дата: {date} строки: {error ? error[date].join(", ") : null} */}
					{t("Date")}: {date} {t("lines")}: {error ? error[date].join(", ") : null}
				</div>
			))}
		</div>
	);
};

export default FormattedError;
