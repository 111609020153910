// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Actions__wrapper-YEr{display:flex;justify-content:flex-end;flex-grow:1;gap:8px}.Actions__label-XgN{margin-left:8px}`, "",{"version":3,"sources":["webpack://./src/modules/Wallets/DisabledWallets/components/Actions/Actions.module.scss"],"names":[],"mappings":"AAAA,sBACC,YAAA,CACA,wBAAA,CACA,WAAA,CACA,OAAA,CAGD,oBACC,eAAA","sourcesContent":[".wrapper {\n\tdisplay: flex;\n\tjustify-content: flex-end;\n\tflex-grow: 1;\n\tgap: 8px;\n}\n\n.label {\n\tmargin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Actions__wrapper-YEr`,
	"label": `Actions__label-XgN`
};
export default ___CSS_LOADER_EXPORT___;
