// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DetailedView__DetailedViewWrapper-MRq{width:560px}`, "",{"version":3,"sources":["webpack://./src/modules/Transactions/ArchivedTransactions/DetailedView/DetailedView.module.scss"],"names":[],"mappings":"AAAA,uCACC,WAAA","sourcesContent":[".DetailedViewWrapper {\n\twidth: 560px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DetailedViewWrapper": `DetailedView__DetailedViewWrapper-MRq`
};
export default ___CSS_LOADER_EXPORT___;
