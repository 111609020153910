import API from "api";
import { WalletsFilterForm,WalletStatus } from "api/walletGroup";
import Filter from "components/Filter";
import AuthContext, { Roles } from "contexts/AuthContext";
import { WalletTypesContext } from "contexts/WalletTypesContext";
import { useTranslation } from "pay-kit";
import { useContext, useEffect, useState } from "react";
import { rejectSettlement } from "utils/filterSettlement";

import styles from "./Filter.module.scss";

type WalletsFilterProps = {
	readonly filter: WalletsFilterForm;
	readonly setFilter: React.Dispatch<React.SetStateAction<WalletsFilterForm>>;
	readonly type: "archived" | "working" | "disabled";
};

const WalletsFilter = ({ filter, setFilter, type }: WalletsFilterProps) => {
	const { walletTypes } = useContext(WalletTypesContext);
	const { hasRole } = useContext(AuthContext);

	const [statuses, setStatuses] = useState<readonly WalletStatus[]>([]);
	const privateInformationAccess = hasRole(Roles.WALLET_NUMBER);

	const { t } = useTranslation();

	useEffect(() => {
		API.wallet.getPossibleWalletStatuses("worked").then((resp) => {
			if (resp.status === "ok") {
				setStatuses(resp.statuses);
			}
		});
	}, []);

	return (
		<Filter
			values={filter}
			fields={[
				{
					name: `id`,
					placeholder: "ID",
					component: "textInput",
					className: styles.field,
					type: "number",
				},
				{
					name: `hash_id`,
					placeholder: t("Hash"),
					component: "textInput",
					className: styles.field,
				},
				{
					name: `type`,
					placeholder: t("Wallet type"),
					component: "select",
					options: [
						{
							value: ``,
							label: t("All"),
						},
						...rejectSettlement(walletTypes).map((walletType) => ({
							value: walletType.code,
							label: walletType.name,
						})),
					],
					className: styles.field,
				},
				{
					name: `identifier`,
					placeholder: t("Wallet number"),
					component: "textInput",
					className: styles.field,
				},
				{
					name: `username`,
					placeholder: t("Site login"),
					component: "textInput",
					className: styles.field,
					hide: !privateInformationAccess,
				},
				{
					name: `email_username`,
					placeholder: t("Email"),
					component: "textInput",
					className: styles.field,
					hide: !privateInformationAccess,
				},
				{
					name: `display_identifier`,
					placeholder: t("Payment details"),
					component: "textInput",
					// className: type !== "archived" && styles.field,
					className: styles.field,
				},
				{
					name: `state`,
					placeholder: t("Status"),
					component: "select",
					options: [
						{
							label: t("All"),
							value: ``,
						},
						...statuses.map((status) => ({
							label: status.text,
							value: status.code,
						})),
					],
					className: styles.field,
					// hide: type === "archived",
				},
			]}
			onApply={setFilter}
		/>
	);
};

export default WalletsFilter;
