import CaretIcon from "components/Icons/CaretIcon";
import { useDashboardContext } from "contexts/DashboardContext";
import { WalletTypesContext } from "contexts/WalletTypesContext";
import CheckBoxesList from "modules/Dashboard/components/CheckBoxesList";
import { Button, useTranslation } from "pay-kit";
import { useContext, useState } from "react";

import styles from "./paymentOptionsList.module.scss";

const PaymentOptionsList = () => {
	const { walletTypes } = useContext(WalletTypesContext);
	const { setCheckedPS, checkedPS } = useDashboardContext();
	const [openPSList, setOpenPSList] = useState(false);
	const { t } = useTranslation();

	const OnClickAll = () => {
		const allChecked = walletTypes.reduce(
			(total, curr, i) => ({ ...total, [i]: { isChecked: true, value: curr.code } }),
			{}
		);

		setCheckedPS(allChecked);
	};

	const psCaretStyle = openPSList ? [styles.psCaret, styles.caretUp].join(" ") : styles.psCaret;

	return (
		<div className={styles.psWrapper}>
			<div className={styles.PSOptions}>
				<Button
					data-test-id="psSystems"
					classname={styles.psSystems}
					onClick={() => setOpenPSList((prevState) => !prevState)}
				>
					{t(`Payment systems`)} <CaretIcon className={psCaretStyle} />
				</Button>

				{openPSList && (
					<>
						<Button data-test-id="g4QCQMRvrYZD6mTK7Fb7E" classname={styles.optionsButton} onClick={OnClickAll}>
							{t(`Choose all`)}
						</Button>

						<Button
							data-test-id="AcgQKHfz79vAtEYjSb8zB"
							classname={styles.optionsButton}
							onClick={() => setCheckedPS({})}
						>
							{t(`Reset`)}
						</Button>
					</>
				)}
			</div>

			{openPSList && (
				<div className={styles.checkboxListContainer}>
					<CheckBoxesList walletTypes={walletTypes} setCheckedPS={setCheckedPS} checkedPS={checkedPS} />
				</div>
			)}
		</div>
	);
};

export default PaymentOptionsList;
