// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Date__Date-vGv{margin-top:8px;font-style:normal;font-weight:400;font-size:14px;line-height:20px;color:#697077}`, "",{"version":3,"sources":["webpack://./src/modules/Postbacks/components/columns/components/Date/Date.module.scss"],"names":[],"mappings":"AAAA,gBACC,cAAA,CAEA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,aAAA","sourcesContent":[".Date {\n\tmargin-top: 8px;\n\t/* Base_1/14-20-r */\n\tfont-style: normal;\n\tfont-weight: 400;\n\tfont-size: 14px;\n\tline-height: 20px;\n\t/* Blue_gray/70 */\n\tcolor: #697077;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Date": `Date__Date-vGv`
};
export default ___CSS_LOADER_EXPORT___;
