import { WalletType } from "api/walletGroup";
import { TrashIcon } from "components/Icons/TrashIcon/TrashIcon";
import Spoiler from "components/Spoiler";
import SelectBuilderButton from "modules/Proxies/components/SelectBuilderButton";
import { getUniqueWalletOptions } from "modules/Proxies/components/UpdateProxyModal/helpers";
import { useProxyContext } from "modules/Proxies/contexts/ProxyContext";
import { isIPHOSTRequired, isIPHOSTValid, isPortValid, isRequired, proxyTypes } from "modules/Proxies/data";
import useAvailableWallets from "modules/Proxies/hooks/useAvailableWallets";
import { useTranslation } from "pay-kit";
import { Button, Loader, PayKitForm, ScrollBox } from "pay-kit";
import { ReactElement, useEffect } from "react";

import styles from "./updateProxyModal.module.scss";

const UpdateProxy = () => {
	const {
		getWallets,
		walletsList,
		updateProxy,
		countryList,
		proxyItem,
		proxyID,
		setIsProxyLoading,
		isWalletListLoading,
		isProxyLoading,
	} = useProxyContext();

	const { t } = useTranslation();

	useEffect(() => {
		getWallets(false);
	}, []);

	const countryOptions = countryList?.map((elem) => ({ label: elem.code, value: elem.code })) || [];
	const typeOptions = proxyTypes.map((elem) => ({ label: elem, value: elem }));
	const dynamicSelectOptions = useAvailableWallets([]) || [];

	const onSubmit = (formState: formStateType) => {
		const filteredWallets = dynamicSelectOptions?.filter(({ value }) =>
			formState.wallets?.some((wallet) => wallet.type === value)
		);

		const mappedWallets = filteredWallets.map(({ value, parser_type }) => {
			const foundWallet = walletsList?.find(({ hash_id }) => hash_id === value) as WalletType | null;

			return { hash_id: foundWallet?.hash_id, parser_type };
		});

		setIsProxyLoading(true);

		return updateProxy(
			{
				...formState,
				wallets: mappedWallets,
			},
			proxyID
		);
	};

	const isLoading = isProxyLoading || (!!proxyItem?.wallets.length && isWalletListLoading);

	const SCHEMA = [
		{
			type: "Group",
			render: (element: ReactElement) => (
				<ScrollBox scrollDirection={"vertical"} className={styles.scrollBox}>
					{element}
				</ScrollBox>
			),
			elements: [
				{
					type: "Group",
					render: (children: ReactElement) => (
						<Spoiler isExpanded title={t(`Parameters`)}>
							{children}
						</Spoiler>
					),
					elements: [
						{
							type: "TextInput",
							name: "ip",
							label: "IP|HOST",
							isRequired: true,
							validation: [isIPHOSTRequired, isIPHOSTValid],
							className: styles.ere,
						},
						{
							type: "TextInput",
							name: "port",
							label: t("Port"),
							isRequired: true,
							validation: [isRequired, isPortValid],
						},
						{
							type: "TextInput",
							name: "username",
							label: t("Login"),
							isRequired: true,
							validation: [isRequired],
						},
						{
							type: "TextInput",
							name: "password",
							label: t("Password"),
							isRequired: true,
							htmlType: "password",
							validation: [isRequired],
						},
						{
							type: "Select",
							name: "type",
							label: t("Type"),
							placeholder: t(`Choose`),
							options: typeOptions,
							isRequired: true,
							validation: [isRequired],
						},
						{
							type: "Select",
							name: "country_code",
							label: t("Country"),
							placeholder: t(`Choose`),
							options: countryOptions,
							isRequired: true,
							validation: [isRequired],
						},
					],
				},
				{
					type: "Repeat",
					name: "wallets",
					render: (children: ReactElement, { insertItem, error }: insertItemType) => (
						<SelectBuilderButton data-test-id="selectBuilderButton" insertItem={insertItem} error={error}>
							{children}
						</SelectBuilderButton>
					),
					elements: [
						{
							type: "Group",
							render: (
								children: ReactElement,
								{
									removeCurrentItem,
								}: {
									readonly removeCurrentItem: <T>(e: T) => void;
								}
							) => (
								<div className={styles.dynamicSelect}>
									{children}
									<Button data-test-id="removeButton" onClick={removeCurrentItem} classname={styles.removeButton}>
										<TrashIcon activeClass={styles.deleteSelect} />
									</Button>
								</div>
							),
							elements: [
								{
									name: "type",
									type: "Select",
									placeholder: t(`Choose`),
									isLoading: isWalletListLoading,
									disabled: isWalletListLoading,
									options: getUniqueWalletOptions(proxyItem?.wallets, dynamicSelectOptions),
									validation: [isRequired],
								},
							],
						},
					],
				},
			],
		},
		{
			type: "Group",
			render: (element: ReactElement) => <div className={styles.submitButton}>{element}</div>,
			elements: [
				{
					name: "submit",
					type: "SubmitButton",
					label: t("Update"),
					onSubmit: onSubmit,
					isLoading: isLoading,
				},
			],
		},
	];

	const responseWallets = proxyItem
		? proxyItem.wallets
				?.filter((elem) => elem.hash_id && elem.identifier)
				.map((elem) => ({
					type: elem.hash_id,
				}))
		: [];

	const INITIAL_STATE = {
		ip: proxyItem?.ip,
		password: proxyItem?.password,
		port: proxyItem?.port,
		type: proxyItem?.type,
		country_code: proxyItem?.country_code,
		username: proxyItem?.username,
		wallets: responseWallets,
	};

	if (!Object.keys(proxyItem || {}).length)
		return (
			<div className={styles.updateLoader}>
				<Loader color="#A2A9B0" />
			</div>
		);

	const updateProxyModalWrapperStyle = isLoading
		? [styles.UpdateProxyModalWrapper, styles.disableContent].join(" ")
		: styles.UpdateProxyModalWrapper;

	return (
		<div className={updateProxyModalWrapperStyle}>
			<PayKitForm.Builder schema={SCHEMA} initialState={INITIAL_STATE} />
		</div>
	);
};

export default UpdateProxy;

export type formStateType = {
	readonly ip: string;
	readonly port: string;
	readonly username: string;
	readonly password: string;
	readonly type: typeof proxyTypes;
	readonly country_code: string;
	readonly wallets?: readonly {
		readonly type: string;
	}[];
};

type insertItemType = {
	readonly insertItem: (value: { readonly type: undefined }, pos: string) => void;
	readonly error: string;
};
