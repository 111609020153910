// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Metric__metricsBlock-mkc{width:118px;border-radius:8px;border:1px solid #f2f3f5;padding:12px;background:#fcfcfd;position:relative}.Metric__metricsBlock-mkc.Metric__link-tDF{cursor:pointer}.Metric__metricsBlock-mkc .Metric__metricTitle-hSq{font-size:14px;color:#697077}.Metric__metricsBlock-mkc .Metric__count-gx6{font-size:20px}.Metric__metricsBlock-mkc svg{position:absolute;top:12px;right:12px;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/modules/Dashboard/components/WalletsTotalBalance/components/Metric/metric.module.scss"],"names":[],"mappings":"AAAA,0BACC,WAAA,CACA,iBAAA,CACA,wBAAA,CACA,YAAA,CACA,kBAAA,CACA,iBAAA,CAEA,2CACC,cAAA,CAGD,mDACC,cAAA,CACA,aAAA,CAGD,6CACC,cAAA,CAGD,8BACC,iBAAA,CACA,QAAA,CACA,UAAA,CACA,cAAA","sourcesContent":[".metricsBlock {\n\twidth: 118px;\n\tborder-radius: 8px;\n\tborder: 1px solid #F2F3F5;\n\tpadding: 12px;\n\tbackground: #FCFCFD;\n\tposition: relative;\n\n\t&.link {\n\t\tcursor: pointer;\n\t}\n\n\t.metricTitle {\n\t\tfont-size: 14px;\n\t\tcolor: #697077;\n\t}\n\n\t.count {\n\t\tfont-size: 20px;\n\t}\n\n\tsvg {\n\t\tposition: absolute;\n\t\ttop: 12px;\n\t\tright: 12px;\n\t\tcursor: pointer;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"metricsBlock": `Metric__metricsBlock-mkc`,
	"link": `Metric__link-tDF`,
	"metricTitle": `Metric__metricTitle-hSq`,
	"count": `Metric__count-gx6`
};
export default ___CSS_LOADER_EXPORT___;
