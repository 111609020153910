// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Filter__filter-REI{margin-top:36px}.Filter__filter-REI .Filter__inlineFilter-XTx{display:grid;grid-template-columns:1fr 1fr 1fr 1fr 110px 160px}`, "",{"version":3,"sources":["webpack://./src/modules/Transactions/CurrentTransactions/Filter/CurrentTransactionsFilter.module.scss"],"names":[],"mappings":"AAAA,oBACI,eAAA,CAEA,8CACI,YAAA,CACA,iDAAA","sourcesContent":[".filter {\n    margin-top: 36px;\n\n    .inlineFilter {\n        display: grid;\n        grid-template-columns: 1fr 1fr 1fr 1fr 110px 160px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter": `Filter__filter-REI`,
	"inlineFilter": `Filter__inlineFilter-XTx`
};
export default ___CSS_LOADER_EXPORT___;
