import { useTranslation } from "pay-kit";
import AutoReports from "modules/Reports/AutoReports";
import { useEffect } from "react";

export default function AutoReportsPage() {
	const {t} = useTranslation();
	useEffect(() => {
		document.title = t("Tasks");
	}, []);

	return <AutoReports />;
}
