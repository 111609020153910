// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CircleIcon__circleWrapper-B5i{width:8px;display:flex;align-items:center}.CircleIcon__circleWrapper-B5i .CircleIcon__circle-lOG{display:flex;align-items:center;cursor:pointer}.CircleIcon__circleWrapper-B5i .CircleIcon__circle-lOG svg{width:100%;height:100%;line-height:initial}`, "",{"version":3,"sources":["webpack://./src/components/Icons/CircleIcon/circleIcon.module.scss"],"names":[],"mappings":"AAAA,+BACC,SAAA,CACA,YAAA,CACA,kBAAA,CAEA,uDACC,YAAA,CACA,kBAAA,CACA,cAAA,CAEA,2DACC,UAAA,CACA,WAAA,CACA,mBAAA","sourcesContent":[".circleWrapper {\n\twidth: 8px;\n\tdisplay: flex;\n\talign-items: center;\n\n\t.circle {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tcursor: pointer;\n\n\t\tsvg {\n\t\t\twidth: 100%;\n\t\t\theight: 100%;\n\t\t\tline-height: initial;\n\t\t}\n\t}\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"circleWrapper": `CircleIcon__circleWrapper-B5i`,
	"circle": `CircleIcon__circle-lOG`
};
export default ___CSS_LOADER_EXPORT___;
