// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProxiesTableColumns__walletsAmount-uOn{cursor:pointer}.ProxiesTableColumns__badgeMaxContent-Hd0{width:max-content}.ProxiesTableColumns__actionsColButtons-htY{display:flex;gap:45px}.ProxiesTableColumns__actionsColButtons-htY .ProxiesTableColumns__actionsColButton-wgB{color:#697077;cursor:pointer;width:22px}.ProxiesTableColumns__actionsColButtons-htY .ProxiesTableColumns__actionsColButton-wgB>svg{width:100%;height:100%;line-height:initial}.ProxiesTableColumns__actionsCol-eRd{gap:25px;width:150px;padding-left:8px}`, "",{"version":3,"sources":["webpack://./src/modules/Proxies/components/ProxiesTableColumns/proxiesTableColumns.module.scss"],"names":[],"mappings":"AAAA,wCACC,cAAA,CAGD,0CACC,iBAAA,CAGD,4CACC,YAAA,CACA,QAAA,CAEA,uFACC,aAAA,CACA,cAAA,CACA,UAAA,CAEA,2FACC,UAAA,CACA,WAAA,CACA,mBAAA,CAKH,qCACC,QAAA,CACA,WAAA,CACA,gBAAA","sourcesContent":[".walletsAmount {\n\tcursor: pointer;\n}\n\n.badgeMaxContent {\n\twidth: max-content;\n}\n\n.actionsColButtons {\n\tdisplay: flex;\n\tgap: 45px;\n\n\t.actionsColButton {\n\t\tcolor: #697077;\n\t\tcursor: pointer;\n\t\twidth: 22px;\n\n\t\t& > svg {\n\t\t\twidth: 100%;\n\t\t\theight: 100%;\n\t\t\tline-height: initial;\n\t\t}\n\t}\n}\n\n.actionsCol {\n\tgap: 25px;\n\twidth: 150px;\n\tpadding-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"walletsAmount": `ProxiesTableColumns__walletsAmount-uOn`,
	"badgeMaxContent": `ProxiesTableColumns__badgeMaxContent-Hd0`,
	"actionsColButtons": `ProxiesTableColumns__actionsColButtons-htY`,
	"actionsColButton": `ProxiesTableColumns__actionsColButton-wgB`,
	"actionsCol": `ProxiesTableColumns__actionsCol-eRd`
};
export default ___CSS_LOADER_EXPORT___;
