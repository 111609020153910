import { useTranslation } from "pay-kit";
import useClickOutside from "@paykassma/pay-kit/lib/utils/useOutsideClick";
import CaretDown from "assets/icons/selectCaretDown.svg";
import Filter from "components/Filter";
import PaymentSystemLogo from "components/PaymentSystemLogo";
import { WalletTypesContext } from "contexts/WalletTypesContext";
import ReloadButton from "modules/Postbacks/components/ReloadButton";
import AvailableBalance from "modules/TransferHistory/components/AvailableBalance";
import ButtonsList from "modules/TransferHistory/components/ButtonsList";
import useOperationHistoryTableColumns from "modules/TransferHistory/components/OperationHistory/components/Columns";
import PSSelect from "modules/TransferHistory/components/PSSelect";
import WithdrawalsHistoryTabs from "modules/TransferHistory/components/WithdrawalsHistoryTabs";
import OperationHistoryTableContextProvider, {
	useOperationHistoryTableContext,
} from "modules/TransferHistory/contexts/OperationHistoryTableContext";
import { Table } from "pay-kit";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { uniqueID } from "utils/uniquwID";

import styles from "./operationHistory.module.scss";
import { useOperationHistoryContext } from "modules/TransferHistory/contexts/OperationHistoryContext";

const OperationHistory = () => {
	const { id, ps } = useParams();
	const operationHistoryTableContextAPI = useOperationHistoryTableContext();
	const operationHistoryContextAPI = useOperationHistoryContext();

	const { walletTypes } = useContext(WalletTypesContext);
	const operationHistoryTableColumns = useOperationHistoryTableColumns();
	const [option, setOption] = useState<OptionType>({ label: "", value: "" });
	const [open, setOpen] = useState(false);
	const [searchBarValue, setSearchBarValue] = useState("");
	const ref = useRef(null);

	const { t } = useTranslation();

	const extendedTransactions = useMemo(
		() =>
			operationHistoryTableContextAPI.data?.transactions.map((transaction) => ({
				...transaction,
				uniqueId: uniqueID(),
			})),
		[operationHistoryTableContextAPI.data?.transactions]
	);

	useEffect(() => {
		if (walletTypes.length) {
			const value = walletTypes.find((type) => type.code === ps);
			setOption({ label: value?.name || "", value: value?.code || "" });
		}
	}, [walletTypes.length]);

	const filteredBalance = operationHistoryTableContextAPI.filteredBalances.find(
		({ payment_system }) => payment_system === ps
	);

	useEffect(() => {
		operationHistoryTableContextAPI.onLoadOperationHistory();
	}, [
		operationHistoryTableContextAPI.onLoadOperationHistory,
		operationHistoryTableContextAPI.filter,
		id,
		operationHistoryTableContextAPI.currentPage,
		operationHistoryTableContextAPI.limit,
	]);

	useEffect(() => {
		operationHistoryTableContextAPI.onLoadOperationHistory(() => operationHistoryTableContextAPI.setCurrentPage(1));
	}, [ps]);

	const isSettlement = ps === "paykassma";

	useClickOutside(ref, () => setOpen(false));

	const onSetLimit: OnSetLimitType = (limit) => {
		operationHistoryTableContextAPI.setCurrentPage(1);
		operationHistoryTableContextAPI.setLimit(limit);
	};

	return (
		<div>
			{!!operationHistoryContextAPI.filteredBalances.length && (
				<div className={styles.currentCurrency}>
					<div className={styles.backupWrapper}>
						<PaymentSystemLogo id={ps || ""} />
					</div>

					<div className={styles.caretDownWrapper} ref={ref}>
						<div
							data-test-id="biWxRI5R3eQYUmO_Gbq_U"
							className={styles.caretDown}
							onClick={() => setOpen((prevState) => !prevState)}
						>
							<CaretDown />
						</div>
						{open && (
							<PSSelect
								setOption={setOption}
								option={option}
								setOpen={setOpen}
								resetSearchBarValue={setSearchBarValue}
							/>
						)}
					</div>

					<ButtonsList
						paymentSystem={ps || ""}
						PSBalance={operationHistoryTableContextAPI.PSBalance}
						balances={filteredBalance?.balances}
						setSearchBarValue={setSearchBarValue}
						searchBarValue={searchBarValue}
					/>
				</div>
			)}

			<AvailableBalance PSBalance={operationHistoryTableContextAPI.PSBalance} />

			{!isSettlement && !!operationHistoryContextAPI.filteredBalances.length && (
				<>
					<Filter
						classNames={{
							wrapper: styles.filterWrapper,
						}}
						values={operationHistoryTableContextAPI.filter}
						fields={[
							{
								name: "direction",
								placeholder: t("Direction"),
								component: "select",
								className: styles.select,
								options: [
									{
										value: "",
										label: t("All"),
									},
									{
										value: `outgoing`,
										// label: `Исходящие`,
										label: t("Outgoing"),
									},
									{
										value: `ingoing`,
										// label: `Входящие`,
										label: t("Ingoing"),
									},
								],
							},
							{
								name: "date",
								component: "dateRange",
								// toPlaceholder: "Дата до",
								toPlaceholder: t("Date to"),
								// fromPlaceholder: "Дата от",
								fromPlaceholder: t("Date from"),
								dateFormat: "YYYY-MM-DD",
								withTime: true,
								blockPredicate: (date) => date.getTime() >= new Date().getTime(),
								customStyles: () => ({
									top: "0",
								}),
							},
						]}
						onApply={(values) => operationHistoryTableContextAPI.setFilter(values)}
					/>

					<Table
						className={styles.tableWrapper}
						rowKey={"uniqueId"}
						data={extendedTransactions || []}
						paginator={
							operationHistoryTableContextAPI.paginationInfo
								? {
										currentPage: operationHistoryTableContextAPI.currentPage || 1,
										setCurrentPage: operationHistoryTableContextAPI.setCurrentPage,
										limit: operationHistoryTableContextAPI.limit,
										setLimit: onSetLimit,
										lastAvailablePage:
											Math.ceil(
												operationHistoryTableContextAPI.paginationInfo.total / operationHistoryTableContextAPI.limit
											) || 1,
										prefixElement: (
											<ReloadButton
												data-test-id=""
												isLoading={operationHistoryTableContextAPI.isGetOperationHistoryLoading}
												onClick={operationHistoryTableContextAPI.onLoadOperationHistory}
											/>
										),
								  }
								: undefined
						}
						columns={operationHistoryTableColumns}
						isLoading={operationHistoryTableContextAPI.isGetOperationHistoryLoading}
					/>
				</>
			)}

			{isSettlement && !!operationHistoryContextAPI.filteredBalances.length && <WithdrawalsHistoryTabs />}
		</div>
	);
};

export default OperationHistory;

export type OptionType = {
	readonly label: string;
	readonly value: string;
};

export type FiltersType = {
	readonly id?: string;
	readonly date?: {
		readonly from: string;
		readonly to: string;
	};
	readonly direction?: string;
	readonly operation_type?: string;
};

type OnSetLimitType = (limit: number) => void;
