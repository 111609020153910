import React from "react";

export const QuestionMark = () => {
	return (
		<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0ZM6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1ZM6 8.5C6.41421 8.5 6.75 8.83579 6.75 9.25C6.75 9.66421 6.41421 10 6 10C5.58579 10 5.25 9.66421 5.25 9.25C5.25 8.83579 5.58579 8.5 6 8.5ZM6 2.5C7.10457 2.5 8 3.39543 8 4.5C8 5.23053 7.78822 5.63969 7.24605 6.20791L6.98196 6.47745C6.60451 6.87102 6.5 7.0831 6.5 7.5C6.5 7.77614 6.27614 8 6 8C5.72386 8 5.5 7.77614 5.5 7.5C5.5 6.76947 5.71178 6.36031 6.25395 5.79209L6.51804 5.52255C6.89549 5.12898 7 4.9169 7 4.5C7 3.94772 6.55228 3.5 6 3.5C5.44772 3.5 5 3.94772 5 4.5C5 4.77614 4.77614 5 4.5 5C4.22386 5 4 4.77614 4 4.5C4 3.39543 4.89543 2.5 6 2.5Z"
				fill="currentColor"
			/>
		</svg>
	);
};
