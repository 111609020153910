// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.columns__postbacksActions-EBs{width:100%;display:flex;justify-content:space-between}.columns__label-bGO{height:20px;padding:0px 4px;background:#e8faed;border-radius:6px;color:#24a148}.columns__merchantOrderIdBadge-yvU{margin-top:8px}.columns__merchantOrderIdBadge-yvU .columns__merchantOrderId-wxV{height:20px;padding:0px 4px;background:#e9fbfb;border-radius:6px;color:#009d9a}`, "",{"version":3,"sources":["webpack://./src/modules/Postbacks/components/columns/Columns.module.scss"],"names":[],"mappings":"AAAA,+BACC,UAAA,CACA,YAAA,CACA,6BAAA,CAGD,oBACC,WAAA,CACA,eAAA,CAEA,kBAAA,CACA,iBAAA,CAEA,aAAA,CAGD,mCACC,cAAA,CAEA,iEACC,WAAA,CACA,eAAA,CAEA,kBAAA,CACA,iBAAA,CAEA,aAAA","sourcesContent":[".postbacksActions {\n\twidth: 100%;\n\tdisplay: flex;\n\tjustify-content: space-between;\n}\n\n.label {\n\theight: 20px;\n\tpadding: 0px 4px;\n\t/* Green/5 */\n\tbackground: #e8faed;\n\tborder-radius: 6px;\n\t/* Green/50 */\n\tcolor: #24a148;\n}\n\n.merchantOrderIdBadge {\n\tmargin-top: 8px;\n\n\t.merchantOrderId {\n\t\theight: 20px;\n\t\tpadding: 0px 4px;\n\t\t/* Additional/Teal/Ghost */\n\t\tbackground: #e9fbfb;\n\t\tborder-radius: 6px;\n\t\t/* Additional/Teal/Primary */\n\t\tcolor: #009d9a;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"postbacksActions": `columns__postbacksActions-EBs`,
	"label": `columns__label-bGO`,
	"merchantOrderIdBadge": `columns__merchantOrderIdBadge-yvU`,
	"merchantOrderId": `columns__merchantOrderId-wxV`
};
export default ___CSS_LOADER_EXPORT___;
