// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TabLoader__loaderWrapper-RIX{max-width:998px;width:100%;display:flex;justify-content:center;margin-top:100px}`, "",{"version":3,"sources":["webpack://./src/modules/AppSettings/components/TabLoader/tabLoader.module.scss"],"names":[],"mappings":"AAAA,8BACC,eAAA,CACA,UAAA,CAEA,YAAA,CACA,sBAAA,CACA,gBAAA","sourcesContent":[".loaderWrapper {\n\tmax-width: 998px;\n\twidth: 100%;\n\n\tdisplay: flex;\n\tjustify-content: center;\n\tmargin-top: 100px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderWrapper": `TabLoader__loaderWrapper-RIX`
};
export default ___CSS_LOADER_EXPORT___;
