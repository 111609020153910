// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PaymentSystemsLimits__paymentSystemsLimitsWrapper-Kt_{position:relative;margin:12px 24px 0 24px}.PaymentSystemsLimits__paymentSystemsLimitsWrapper-Kt_ .PaymentSystemsLimits__paymentSystemsLimits-PO_{display:flex;justify-content:space-between;font-size:14px;padding:10px 12px;border-radius:8px;border:1px solid #f2f3f5;background:#fcfcfd;cursor:pointer}.PaymentSystemsLimits__paymentSystemsLimitsWrapper-Kt_ .PaymentSystemsLimits__paymentSystemsLimits-PO_ .PaymentSystemsLimits__caretUp-yZF{transform:rotate(180deg)}.PaymentSystemsLimits__paymentSystemsLimitsWrapper-Kt_ .PaymentSystemsLimits__limits-Ao5{position:absolute;left:-24px;right:-24px}`, "",{"version":3,"sources":["webpack://./src/modules/Dashboard/components/WalletsTotalBalance/components/PaymentSystemsLimits/paymentSystemsLimits.module.scss"],"names":[],"mappings":"AAAA,uDACC,iBAAA,CACA,uBAAA,CAEA,uGACC,YAAA,CACA,6BAAA,CACA,cAAA,CACA,iBAAA,CACA,iBAAA,CACA,wBAAA,CACA,kBAAA,CACA,cAAA,CAEA,0IACC,wBAAA,CAIF,yFACC,iBAAA,CACA,UAAA,CACA,WAAA","sourcesContent":[".paymentSystemsLimitsWrapper {\n\tposition: relative;\n\tmargin: 12px 24px 0 24px;\n\n\t.paymentSystemsLimits {\n\t\tdisplay: flex;\n\t\tjustify-content: space-between;\n\t\tfont-size: 14px;\n\t\tpadding: 10px 12px;\n\t\tborder-radius: 8px;\n\t\tborder: 1px solid #F2F3F5;\n\t\tbackground: #FCFCFD;\n\t\tcursor: pointer;\n\n\t\t.caretUp {\n\t\t\ttransform: rotate(180deg);\n\t\t}\n\t}\n\n\t.limits {\n\t\tposition: absolute;\n\t\tleft: -24px;\n\t\tright: -24px;\n\t}\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paymentSystemsLimitsWrapper": `PaymentSystemsLimits__paymentSystemsLimitsWrapper-Kt_`,
	"paymentSystemsLimits": `PaymentSystemsLimits__paymentSystemsLimits-PO_`,
	"caretUp": `PaymentSystemsLimits__caretUp-yZF`,
	"limits": `PaymentSystemsLimits__limits-Ao5`
};
export default ___CSS_LOADER_EXPORT___;
