import IconButton from "components/IconButton";
import { useWorkingWalletContext } from "modules/Wallets/contexts/WorkingWalletContext/WorkingWalletContext";
import { useTranslation } from "pay-kit";

const MultipleActions = () => {
	const { openMassDisableWalletModal } = useWorkingWalletContext();
	const { t } = useTranslation();

	const handleMassDisableClick = () => {
		openMassDisableWalletModal();
	};
	return (
		<IconButton
			data-test-id="NmwEDZ1zxuVnXdxbLP-ac"
			icon="disable"
			onClick={handleMassDisableClick}
			caption={t("Disable")}
		/>
	);
};

export default MultipleActions;
