import { fetchApi, fetchOldApi } from "api/fetch";

const stockpilingsGroup = {
	getStockPilingsList: (params: GetStockPilingsListParamsType) =>
		fetchOldApi<getListSuccessResponseType>("/stockpiling/list", params),
	getCurrencyList: () => fetchApi<CurrencyListType>("/currency/list"),
	getListByStockPiling: (params: GetListByStockPilingParamsType) =>
		fetchOldApi<GetListByStockPilingType>("/transaction/list-by-stockpiling", params),
	getPostbackList: (id: number, params: GetPostbackListParamsProps) =>
		fetchApi<getPostbackListType>(`/postback/${id}`, params),
	sendPostback: (id: number) => fetchOldApi<void>(`/stockpiling/${id}/force-send`, undefined, "POST"),
};

export default stockpilingsGroup;

export type paginationInfoType = {
	readonly current_page: number;
	readonly from: number;
	readonly to: number;
	readonly per_page: number;
	readonly last_available_page: number;
};

export type getListSuccessResponseType = {
	readonly stockpiling_list: {
		readonly data: readonly (StockPilingsType & { readonly code: string })[];
	} & paginationInfoType;
};

export type CurrencyType = {
	readonly id: number;
	readonly name: string;
	readonly code: string;
	readonly symbol: string;
	readonly is_crypt: boolean;
	readonly payment_systems_user_sort_enabled: boolean;
	readonly rate: string;
	readonly symbol_position: string;
};

export type CurrencyListType = {
	readonly currencies: readonly CurrencyType[];
};

export type GetPostbackListParamsProps = {
	readonly direction: string;
};

export type getPostbackListType = {
	readonly count_failure: number;
	readonly created_at: string;
	readonly direction: string;
	readonly id: number;
	readonly label: string;
	readonly request_body: string;
	readonly response: string;
	readonly status: number;
	readonly status_code: StatusCodeType;
	readonly url: string;
	readonly wallet_type: string;
};

export type GetListByStockPilingParamsType = {
	readonly stockpiling_id: number;
};

export type TransactionsType = {
	readonly amount: number;
	readonly created_at: string;
	readonly id: string;
	readonly primary_id: number;
	readonly stockpiling_id: number;
	readonly symbol: string;
	readonly type: 0 | 1 | 2;
	readonly wallet_identifier: string;
	readonly is_archived: boolean;
};

export type GetListByStockPilingType = {
	readonly transactions: readonly TransactionsType[];
};

export type StatusCodeType = 400 | 200 | 500 | 501;

export type StockPilingsType = {
	readonly id: number;
	readonly label: string;
	readonly direction: string;
	readonly request_body: string;
	readonly status_code: StatusCodeType;
	readonly response: string;
	readonly status: 1 | 0;
	readonly amount: string;
	readonly count_transactions: number;
	readonly currency_symbol: string;
	readonly is_real: boolean;
	readonly min_amount: string;
	readonly payment_system: string;
	readonly postback_id: number;
	readonly postback_send: number;
};

export type GetStockPilingsListFilterParamsType = {
	readonly date_from?: string;
	readonly date_to?: string;
	readonly wallet_type?: string;
	readonly direction?: "outgoing" | "ingoing";
	readonly label?: string;
	readonly payment_system?: string;
};

export type GetStockPilingsListParamsType = {
	readonly limit?: number;
	readonly page?: number;
	readonly label?: string;
	readonly payment_system?: string;
	readonly is_real?: number;
} & GetStockPilingsListFilterParamsType;
