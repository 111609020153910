import { PSAndBalancesType } from "api/balanceGroup";
import { useAccountsBalancesContext } from "contexts/AccountsBalanceContext";
import { WalletTypesContext } from "contexts/WalletTypesContext";
import { OptionType } from "modules/TransferHistory/components/OperationHistory";
import SearchBar from "modules/TransferHistory/components/SearchBar";
import { getFilteredList } from "modules/TransferHistory/helpers";
import { ScrollBox } from "pay-kit";
import { ChangeEvent, Dispatch, FC, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import styles from "./psselect.module.scss";

const gettingID: GettingIDType = (balances, value) => {
	const balanceData = balances.find((balance) => balance.payment_system === value);
	return balanceData?.balances[0].id || "";
};

export const NO_ID = `NO_ID`;

const PSSelect: FC<PSSelectType> = ({
	setOption,
	option,
	setOpen,
	resetSearchBarValue
}) => {
	const { walletTypes } = useContext(WalletTypesContext);
	const { filteredBalances } = useAccountsBalancesContext();
	const [filteredWalletTypes, setFilteredWalletTypes] = useState(walletTypes);
	const psOptionRef = useRef<HTMLAnchorElement | null>(null);

	useEffect(() => {
		psOptionRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
	}, []);

	const renderPSList = () => {
		return filteredWalletTypes.map(({ code, logo, name }, i) => {
			const isSelected = code === option.value;

			const psRef = isSelected ? psOptionRef : undefined;
			const id = gettingID(filteredBalances, code);

			const style = isSelected ?
				[styles.PaymentSystemBadge, styles.active].join(" ") :
				styles.PaymentSystemBadge;

			const handleOnClick = () => {
				setOption({ label: name, value: code });
				resetSearchBarValue("");
				setOpen(false); // hides whole select inclusive of searchbar
			};

			return (
				<Link data-test-id="wsRMDqbCKDBOoxLmz_bBv" to={`/operation-history/${code}/${id || NO_ID}`} className={style}
					onClick={handleOnClick}
					key={i}
					ref={psRef}
				>
					<img className={styles.logo} height={16} src={logo} alt={name} />
					<div className={styles.caption}>{name}</div>
				</Link>
			);
		});
	};

	const handleOnchange = (e: ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value;
		const filteredValue = getFilteredList({ list: walletTypes, fieldKey: "code", value: inputValue });
		setFilteredWalletTypes(filteredValue);
		setOption((prevState) => ({ ...prevState, label: inputValue }));
	};

	return (
		<div
			className={styles.selectWrapper}
		>

			{!!filteredWalletTypes.length &&
				<>
					<SearchBar
						data-test-id="6zbs51a2sLsj_tctMJxZ4s"
						onChange={handleOnchange}
						value={option.label}
					/>

					<div className={styles.listContainer}>
						<ScrollBox className={styles.scrollBox}>
							{renderPSList()}
						</ScrollBox>
					</div>
				</>
			}
		</div>
	);
};

export default PSSelect;


type PSSelectType = {
	readonly setOption: Dispatch<SetStateAction<OptionType>>
	readonly option: OptionType
	readonly setOpen: Dispatch<boolean>
	readonly resetSearchBarValue: Dispatch<string>
}

type GettingIDType = (balances: readonly PSAndBalancesType[], value: string) => number | string