import API, { ChartDataRequestParams, DashboardDataType } from "api/dashboardGroup";
import { useState } from "react";
import { errorsMap } from "utils/enums";

const useGetTransactionsData = () => {
	const [data, setData] = useState<undefined | DashboardDataType[`data`]>(undefined);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const load = (params: ChartDataRequestParams) => {
		setIsLoading(true);

		API.getTransactionsData(params)
			.then((res) => {
				if (res.status === "ok") {
					setData(res.data);
					return res;
				} else {
					throw new Error(errorsMap.anyResponse);
				}
			})
			.finally(() => {
				setIsLoading(false);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	return {
		load,
		isLoading,
		data,
	};
};

export default useGetTransactionsData;
