import { Button, PayKitForm } from "@paykassma/pay-kit";
import { TrashIcon } from "components/Icons/TrashIcon/TrashIcon";
import Spoiler from "components/Spoiler";
import InputFormatHint from "modules/Proxies/components/InputFormatHint";
import { ProxyCommonType } from "modules/Proxies/components/ProxyModalRows";
import SelectBuilderButton from "modules/Proxies/components/SelectBuilderButton";
import { proxiesListElemType, useAddingProxiesContext } from "modules/Proxies/contexts/AddingProxiesContext";
import { useProxyContext } from "modules/Proxies/contexts/ProxyContext";
import {
	checkedStatuses,
	isIPHOSTRequired,
	isIPHOSTValid,
	isPortValid,
	isRequired,
	proxyTypes,
} from "modules/Proxies/data";
import { constructMultiAddingProxiesList, formWallets, getUniqueWalletOptions } from "modules/Proxies/helpers";
import useAvailableWallets from "modules/Proxies/hooks/useAvailableWallets";
import { useTranslation } from "pay-kit";
import { ScrollBox } from "pay-kit";
import { ReactElement, useEffect, useState } from "react";

import styles from "./addProxyModal.module.scss";

const AddProxy = () => {
	const { setIsProxiesOpen, initCheckingMultiAddingProxies, createProxy, isCreateProxyLoading } =
		useAddingProxiesContext();
	const { getWallets, countryList, isWalletListLoading } = useProxyContext();
	const [proxyCheckingStatus, setProxyCheckingStatus] = useState<ProxyCheckingStatusType>(checkedStatuses.NOT_CHECKED);
	const [proxiesList, setProxiesList] = useState<readonly proxiesListElemType[]>([]);
	const { t } = useTranslation();

	const countryOptions = countryList?.map((elem) => ({ label: elem.code, value: elem.code })) || [];
	const dynamicSelectOptions = useAvailableWallets([]) || [];
	const typeOptions = proxyTypes.map((elem) => ({ label: elem, value: elem }));

	const onStateChange = (prevstate: formStateType, currState: formStateType) => {
		const value = currState.creating_by_list_form?.proxies_list || "";
		setProxiesList(constructMultiAddingProxiesList(value));
	};

	const onListSubmit = () => {
		setProxyCheckingStatus(() => {
			const isValid = proxiesList.some((elem) => elem.isFormatValid);
			if (isValid) return checkedStatuses.WORKING;
			else return checkedStatuses.NOT_WORKING;
		});
	};

	const onManualListSubmit = (formData: formStateType) => {
		const requestDate = formData?.manual_creating_form.wallets
			? {
					...formData.manual_creating_form,
					wallets: formWallets(formData.manual_creating_form, dynamicSelectOptions),
			  }
			: formData.manual_creating_form;

		createProxy(requestDate as Partial<ProxyCommonType>);
	};

	useEffect(() => {
		getWallets(false);
	}, []);

	useEffect(() => {
		if (proxyCheckingStatus === checkedStatuses.WORKING) {
			setIsProxiesOpen((proxiesOpen) => ({ ...proxiesOpen, isUploadedProxiesOpen: true }));
			const proxies = proxiesList.filter((elem) => elem.isFormatValid).map(({ proxy }) => proxy);

			initCheckingMultiAddingProxies(proxies);
		}
	}, [proxyCheckingStatus]);

	const SCHEMA: any = [
		{
			type: "Group",
			render: (element: ReactElement) => (
				<ScrollBox scrollDirection={"vertical"} className={styles.scrollBox}>
					{element}
				</ScrollBox>
			),
			elements: [
				{
					type: "Toggler",
					name: "list_mode_on",
					label: t("Load by list"),
				},
				{
					type: "Group",
					name: "creating_by_list_form",
					render: (element: ReactElement) => (
						<InputFormatHint proxyCheckingStatus={proxyCheckingStatus}>{element}</InputFormatHint>
					),
					existsIf: ({ list_mode_on }: formStateType) => list_mode_on === true,
					elements: [
						{
							type: "Textarea",
							name: "proxies_list",
							placeholder: t("Proxy list"),
							rows: 5,
							className: styles.textArea,
						},
						{
							type: "Group",
							render: (element: ReactElement) => <div className={styles.actions}>{element}</div>,
							elements: [
								{
									name: "submit",
									onSubmit: onListSubmit,
									type: "SubmitButton",
									disabled: ({ creating_by_list_form }: formStateType) => !creating_by_list_form?.proxies_list,
									label: t("Check list"),
								},
							],
						},
					],
				},
				{
					type: "Group",
					name: "manual_creating_form",
					existsIf: ({ list_mode_on }: formStateType) => !list_mode_on,
					elements: [
						{
							type: "Group",
							render: (children: ReactElement) => (
								<Spoiler isExpanded title={t(`Parameters`)}>
									{children}
								</Spoiler>
							),
							elements: [
								{
									type: "TextInput",
									name: "ip",
									label: "IP|HOST",
									isRequired: true,
									className: styles.proxySelect,
									validation: [isIPHOSTRequired, isIPHOSTValid],
								},
								{
									type: "TextInput",
									name: "port",
									label: t("Port"),
									isRequired: true,
									className: styles.proxySelect,
									validation: [isRequired, isPortValid],
								},
								{
									type: "TextInput",
									name: "username",
									label: t("Login"),
									isRequired: true,
									validation: [isRequired],
								},
								{
									type: "TextInput",
									htmlType: "password",
									name: "password",
									label: t("Password"),
									isRequired: true,
									validation: [isRequired],
								},
								{
									type: "Select",
									name: "type",
									label: t("Type"),
									placeholder: t(`Choose`),
									options: typeOptions,
									isRequired: true,
									validation: [isRequired],
								},
								{
									type: "Select",
									name: "country_code",
									placeholder: t(`Choose`),
									label: t("Country"),
									options: countryOptions,
									isRequired: true,
									validation: [isRequired],
								},
							],
						},
						{
							type: "Repeat",
							name: "wallets",
							render: (children: ReactElement, { insertItem, error }: insertItemType) => (
								<SelectBuilderButton data-test-id="selectBuilderButton" insertItem={insertItem} error={error}>
									{children}
								</SelectBuilderButton>
							),
							elements: [
								{
									type: "Group",
									render: (
										children: ReactElement,
										{
											removeCurrentItem,
										}: {
											readonly removeCurrentItem: <T>(e: T) => void;
										}
									) => (
										<div className={styles.dynamicSelect}>
											{children}
											<Button data-test-id="removeButton" onClick={removeCurrentItem} classname={styles.removeButton}>
												<TrashIcon activeClass={styles.deleteSelect} />
											</Button>
										</div>
									),
									elements: [
										{
											name: "type",
											type: "Select",
											placeholder: t(`Choose`),
											isLoading: isWalletListLoading,
											disabled: isWalletListLoading,
											options: (formState: formStateType, currentValue: number) =>
												getUniqueWalletOptions(formState, currentValue, dynamicSelectOptions),
											validation: [isRequired],
										},
									],
								},
							],
						},
					],
				},
			],
		},
		{
			type: "Group",
			render: (element: ReactElement) => <div className={styles.submitButton}>{element}</div>,
			existsIf: ({ list_mode_on }: formStateType) => !list_mode_on,
			elements: [
				{
					name: "submit",
					type: "SubmitButton",
					label: t("Create"),
					onSubmit: onManualListSubmit,
					isLoading: isCreateProxyLoading,
				},
			],
		},
	];

	const AddProxyModalWrapperStyle = isCreateProxyLoading
		? [styles.AddProxyModalWrapper, styles.disableContent].join(" ")
		: styles.AddProxyModalWrapper;

	return (
		<div className={AddProxyModalWrapperStyle}>
			<PayKitForm.Builder schema={SCHEMA} onStateChange={onStateChange} />
		</div>
	);
};

export default AddProxy;

type insertItemType = {
	readonly insertItem: (value: { readonly type: undefined }, pos: string) => void;
	readonly error: string;
};

export type formStateType = {
	readonly list_mode_on?: boolean;
	readonly creating_by_list_form?: {
		readonly proxies_list: string;
	};
	readonly manual_creating_form: Partial<{
		readonly ip: string;
		readonly port: string;
		readonly username: string;
		readonly password: string;
		readonly type: typeof proxyTypes;
		readonly country_code: string;
		readonly wallets: readonly {
			readonly type: string;
		}[];
	}>;
};

export type ProxyCheckingStatusType = "WORKING" | "NOT_WORKING" | "NOT_CHECKED";
