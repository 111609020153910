import { getListFilterParamsType } from "api/postbackGroup";
import { WalletType } from "api/walletTypesGroup";
import { FormattedWalletListType } from "modules/Stockpilings/contexts/StockPilingsListContext";
import { fromLocaleDate } from "utils/date";

export const getFormattedWalletTypes = (
	walletTypes: readonly WalletType[]
): readonly FormattedWalletListType[] => walletTypes.map(({ code, name }) => ({ value: code, label: name }));

export const getDateString: GetDateStringType = (dateString) => dateString.replace(/(\d+)-(\d+)-(\d+)/, "$3-$2-$1");

export const getFormattedFilterData = (filterData: getListFilterParamsType) => {
	let formattedFilterData;
	const { date, ...rest } = filterData;
	if (date) {
		formattedFilterData = { ...rest, date_from: fromLocaleDate(date.from), date_to: fromLocaleDate(date.to) };
	} else formattedFilterData = filterData;

	return formattedFilterData;
};

type GetDateStringType = (dateString: string) => string
