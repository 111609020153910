import { Button } from "@paykassma/pay-kit";
import { useTranslation } from "pay-kit";
import { ReactNode, useState } from "react";
import { ModalProps } from "utils/types";

import styles from "./DeleteModal.module.scss";
import { close } from "inspector";

type IDeleteModalProps = ModalProps & {
	readonly actions: {
		readonly onDelete: () => Promise<any>;
	};
	readonly text: ReactNode;
	readonly btnClassname?: string;
	readonly closeLabel?: string;   //label for close button 
	readonly deleteLabel?: string;  //label for delete button
};

export const DeleteModal = ({
	closeModal,
	actions,
	text,
	btnClassname = ``,
	closeLabel,
	deleteLabel,
}: IDeleteModalProps) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState<boolean>(false);

	const handleClose = () => {
		closeModal && closeModal();
	};

	const handleDelete = () => {
		setLoading(true);
		// eslint-disable-next-line promise/catch-or-return, promise/always-return
		actions.onDelete().then(() => {
			setLoading(false);
			handleClose();
		});
	};

	closeLabel = closeLabel ? closeLabel : t("Close")
	deleteLabel = deleteLabel ? deleteLabel : t("Delete")

	return (
		<div className={styles.wrapper}>
			<div className={styles.text}>{text}</div>
			<div className={styles.controls}>
				<Button
					classname={styles.closeBtn}
					data-test-id="UdljijEGncDvVtSnmDxr4"
					variant="secondary"
					onClick={handleClose}
				>
					{closeLabel}
				</Button>
				<Button
					isLoading={loading}
					classname={btnClassname}
					data-test-id="UdljijEGncDvVtSnmDxr4"
					variant="delete"
					onClick={handleDelete}
				>
					{deleteLabel}
				</Button>
			</div>
		</div>
	);
};
