import { fetchApi, fetchOldApi } from "api/fetch";

const walletTypesGroup = {
	getList: () => fetchApi("/wallet-types", {}, "GET"),
	getSupportedCurrency: (ps: string) => fetchOldApi(`/wallet-type/get-support-currency/${ps}`, undefined, "GET"),
	getCurrenciesList: (walletType: string) =>
		fetchApi(`/wallet-type-to-currency/?wallet_type=${walletType}`, undefined, "GET"),
	updateCurrenciesList: (walletType: string, data: UpdateCurrenciesListReqDataType) =>
		fetchApi(`/wallet-type-to-currency/?wallet_type=${walletType}`, data, "PUT"),
	getLimits: (walletType: string) => fetchOldApi(`/wallet-type/limits/${walletType}`, undefined, "GET"),
	setLimits: (walletType: string, data: readonly ILimitsItem[]) =>
		fetchOldApi(`/wallet-type/limits/${walletType}`, data, "POST"),
	getTargets: (walletType: string) => fetchOldApi(`/wallet-type/get-targets/${walletType}`, undefined, "GET"),
	setTargets: (walletType: string, data: ISetTargetsRequest) =>
		fetchOldApi(`/wallet-type/targets/${walletType}`, data, "POST", { "Content-Type": "multipart/form-data" }),
	getAvailableTranslations: (walletType: string) =>
		fetchOldApi(`/wallet-type/available-translations/${walletType}`, undefined, "GET"),
	createNew: (data: ICreateNewWalletType) =>
		fetchOldApi(`/wallet-types`, data, "POST", { "Content-Type": "multipart/form-data" }),
	getWorktime: (walletType: string) => fetchOldApi(`/wallet-type/worktime/${walletType}`, undefined, "GET"),
	getProxySettings: (walletType: string) =>
		fetchOldApi<GetProxySettingsType>(`/wallet-type/proxy-setting/${walletType}`, undefined, "GET"),
	getConstructorSettings: (walletType: string) =>
		fetchApi(`/wallet-type/settings-constructor/${walletType}`, undefined, "GET"),
	updateSettings: (data: UpdateSettingsDataType) => fetchOldApi(`/wallet-types/settings`, data, "PUT"),
	getWalletTypeLimits: (walletType: string) => fetchOldApi<GetWalletTypeLimitsType>(`wallet-type/limits/${walletType}`),
	getManualPSRequisites: (ps: string) => fetchApi<GetRequisites>(`/wallet-type/requisites/${ps}`),
	getIngoingLimits: () => fetchApi(`/wallet-types/limits?direction=ingoing`, undefined, "GET"),
	getImages: () => fetchApi<GetImagesType>(`/wallet-type/images`),
	createImage: (data: CreateImageParamsType) =>
		fetchApi<CreateImageType>(`/wallet-type/images`, data, "POST", { "Content-Type": `multipart/form-data` }),
};

export type GetProxySettingsType = { readonly use_proxy: boolean };

export type CreateImageParamsType = {
	readonly wallet_type: string;
	readonly file: Blob;
};
export type CreateImageType = readonly unknown[];

export type ImageType = {
	readonly type: string;
	readonly url: string;
};
export type GetImagesType = Record<string, ImageType>;

export type Requisite = {
	readonly code: string;
	readonly name: string;
};

type GetRequisites = {
	readonly items: readonly Requisite[];
};

export type WalletTypeLimitsType = {
	readonly currency: string;
	readonly defaults: {
		readonly deposit: {
			readonly max: number | null;
			readonly min: number | null;
		};
		readonly withdrawal: {
			readonly max: number | null;
			readonly min: number | null;
		};
	};
	readonly limits: {
		readonly deposit: {
			readonly max: number | null;
			readonly min: number | null;
		};
		readonly withdrawal: {
			readonly max: number | null;
			readonly min: number | null;
		};
	};
};

export type GetWalletTypeLimitsType = {
	readonly data: readonly WalletTypeLimitsType[];
};

export type UpdateSettingsDataType =
	| {
			readonly wallet_type: string;
			readonly all_time?: boolean;
			readonly begin?: string;
			readonly end?: string;
			readonly timezone?: string;
			readonly impression_counter?: number;
			readonly no_transactions_period?: number;
			readonly use_proxy?: boolean;
			readonly allowed_update_requisites?: boolean;
			readonly disable_wallet_visibility?: boolean;
			readonly transactions_verification?: boolean;
			readonly is_check_amount?: boolean;
	  }
	| (
			| { readonly use_possible_transactions: boolean; readonly notifications: boolean }
			| { readonly plugin_visibility: boolean; readonly notifications: boolean }
			| { readonly is_standard: boolean; readonly notifications: boolean }
			| { readonly standard_threshold: number; readonly notifications: boolean }
			| { readonly notifications: boolean }
	  );

export type WalletType = {
	readonly code: string;
	readonly name: string;
	readonly is_settlement: boolean;
	readonly payment_type: "P2P" | "API" | "MANUAL";
	readonly applied_currencies: readonly string[];
	readonly supported_currencies: readonly string[];
	readonly supported_wallet_currencies: readonly string[];
	readonly logo: string;
	readonly type_settings: {
		readonly is_check_amount: boolean;
		readonly is_control: boolean;
		readonly use_possible_transactions: boolean;
		readonly is_standard: boolean;
		readonly standard_threshold: number;
		readonly plugin_visibility: boolean;
		readonly is_enabled: boolean;
		readonly enable_notifications: boolean;
		readonly show_transaction_id: boolean;
		readonly valid_trans_id_from: number;
		readonly valid_trans_id_to: number;
		readonly show_payment_bill: boolean;
	};
};

export type UpdateCurrenciesListReqDataType = {
	readonly currencies: readonly string[];
	readonly items: readonly { readonly id: number; readonly is_applied: boolean }[];
};

export interface ICurrenciesListItem {
	readonly currency: string;
	readonly id: number;
	readonly is_applied: boolean;
	readonly order: number;
	readonly wallet_type: string;
}

export interface ILimitsItem {
	readonly currency: string;
	readonly limits: {
		readonly withdrawal: {
			readonly min: null | number;
			readonly max: null | number;
		};
		readonly deposit: {
			readonly min: null | number;
			readonly max: null | number;
		};
		readonly stockpiling: {
			readonly min: number | null;
		};
	};
	readonly defaults: {
		readonly withdrawal: {
			readonly min: null | number;
			readonly max: null | number;
		};
		readonly deposit: {
			readonly min: null | number;
			readonly max: null | number;
		};
		readonly stockpiling: {
			readonly min: null | number;
		};
	};
}

export interface IGetTargetsResponse {
	readonly status: "ok";
	readonly default: {
		readonly hint_payment: string;
		readonly hint_requisit: string;
		readonly field_payment: string;
		readonly field_requisit: string;
		readonly title: string;
		readonly is_enabled: boolean;
		readonly logo: string;
		readonly currencies: readonly IGetTargetsCurrenciesItem[];
		readonly show_limits: true;
	};
	readonly targets: readonly ITargetsItem[];
	// eslint-disable-next-line functional/prefer-readonly-type
	deleted?: readonly string[];
}

export interface IGetTargetsCurrenciesItem {
	readonly id: number | string;
	readonly created_at: string;
	readonly updated_at: string;
	readonly currency: string;
	readonly wallet_type: string;
	readonly is_applied: boolean;
	readonly is_supported: boolean;
	readonly is_wallet: boolean;
	readonly order: number;
}

export interface ITargetsItem {
	readonly id: number | string;
	readonly title: string | number;
	readonly hint_payment: string;
	readonly hint_requisites: string;
	readonly field_payment: string;
	readonly field_requisites: string;
	readonly is_enabled: number;
	readonly logo: string | FileList;
	readonly currencies: readonly string[];
	readonly show_limits: boolean;
}

export interface ISetTargetsRequest {
	readonly is_default_enabled: 1 | 0;
	readonly show_limits: boolean;
	readonly targets?: {
		readonly title: string;
		readonly currencies: readonly string[];
		readonly hint_requisites: string;
		readonly hint_payment: string;
		readonly field_requisites: string;
		readonly field_payment: string;
		readonly show_limits: boolean;
	};
}

export interface IGetAvailableTranslations {
	readonly status: "ok";
	readonly hints: readonly ITranslation[];
	readonly fields: readonly ITranslation[];
}

interface ITranslation {
	readonly id: string;
	readonly form_type: "requisite" | "payment";
	readonly name: string;
}

export enum ENUMBooleanSetting {
	ON = 1,
	OFF = 0,
}

export enum ENUMFormType {
	PAYMENT = "payment",
	REQUISITE = "requisite",
}

export interface ICreateNewWalletType {
	readonly animation_hint: FileList | null | string;
	readonly screenshot: FileList | null | string;
	readonly logo: FileList | null | string;
	readonly show_transaction_id: ENUMBooleanSetting;
	readonly name: string;
	readonly code: string;
	readonly currency?: string;
	readonly show_payment_bill: ENUMBooleanSetting;
	readonly validation_transaction_id_from?: number;
	readonly validation_transaction_id_to?: number;
	readonly hints: readonly {
		// readonly id?: string | number;
		readonly lang?: string;
		readonly text: string;
		readonly form_type: ENUMFormType;
	}[];
	readonly limits: {
		readonly deposit: {
			readonly min?: number;
			readonly max?: number;
		};
	};
	readonly requisites: readonly {
		readonly code: string;
	}[];
}

export interface IGetWorktimeSuccessResponse {
	readonly status: "ok";
	readonly time: {
		readonly begin: string;
		readonly end: string;
		readonly timezone: string;
		readonly all_time: boolean;
	};
}

export interface IProxySettingsSuccessResponse {
	readonly status: "ok";
	readonly use_proxy: boolean;
}

export interface IConstructorSettingsSuccessResponse {
	readonly status: "success";
	readonly message: string;
	readonly code: 20000;
	readonly data: {
		readonly allowed_update_requisites: boolean;
		readonly disable_wallet_visibility: boolean;
		readonly impression_counter: number;
		readonly no_transactions_period: number;
		readonly transactions_verification: boolean;
		readonly is_check_amount: boolean;
	};
	readonly paginate: {
		readonly offset: number;
		readonly total: number;
		readonly limit: number;
	};
}

export type WalletTypesItemType = {
	readonly code: string;
	readonly name: string;
	readonly is_control: boolean;
	readonly use_possible_transactions: boolean;
	readonly is_standard: boolean;
	readonly standard_threshold: number;
	readonly plugin_visibility: boolean;
	readonly is_enabled: boolean;
	readonly enable_notifications: boolean;
	readonly show_transaction_id: boolean;
	readonly valid_trans_id_from: number;
	readonly valid_trans_id_to: number;
	readonly show_payment_bill: boolean;
	readonly is_settlement: boolean;
	readonly payment_type: "P2P" | "API" | "MANUAL";
	readonly applied_currencies: readonly string[];
	readonly supported_currencies: readonly string[];
	readonly supported_wallet_currencies: readonly string[];
	readonly logo: string;
};

export default walletTypesGroup;
