import { Alert, Button, ScrollBox } from "@paykassma/pay-kit";
import { WalletTypesContext } from "contexts/WalletTypesContext";
import { useTranslation } from "pay-kit";
import { useContext } from "react";
import Spoiler from "src/components/Spoiler";
import { rejectSettlement } from "utils/filterSettlement";

import { IFormData } from "../index";
import Field, { IField } from "./Field";
import styles from "./WithdrawalForm.module.scss";

interface IWithdrawFormProps {
	readonly formData: IFormData;
	readonly fieldChangeHandler: (name: keyof IFormData, value: unknown) => void;
	readonly supportedCurrencyList: readonly string[];
	readonly supportedCurrencyListIsLoading: boolean;
	readonly submitHandler: () => void;
	readonly paymentSystemIsChoosed: boolean;
	readonly isLoading: boolean;
	readonly formErrors: readonly string[];
	readonly fieldsErrors: { readonly [key in keyof IFormData]?: string };
	readonly touchedFields: readonly (keyof IFormData | "*")[];
}

const WithdrawForm = ({
	formData,
	fieldChangeHandler,
	supportedCurrencyList,
	supportedCurrencyListIsLoading,
	submitHandler,
	paymentSystemIsChoosed,
	isLoading,
	formErrors,
	fieldsErrors,
	touchedFields,
}: IWithdrawFormProps) => {
	const walletTypesAPI = useContext(WalletTypesContext);

	const { t } = useTranslation();

	const allFields: readonly Omit<
		IField,
		"fieldChangeHandler" | "formData" | "formErrors" | "fieldsErrors" | "touchedFields"
	>[] = [
		{
			component: "select",
			name: "payment_system",
			isRequired: true,
			label: t("Wallet type"),
			options: rejectSettlement(walletTypesAPI.walletTypes).map((ps: any) => ({ value: ps.code, label: ps.name })),
			isLoading: walletTypesAPI.isLoading,
			className: styles.paymentSystemSelect,
			placeholder: t("Select the wallet"),
			isHidden: false,
		},
		{
			component: "textInput",
			name: "label",
			label: t("Label"),
			isRequired: true,
			isHidden: !paymentSystemIsChoosed,
		},
		{
			component: "textInput",
			name: "amount",
			label: t("Amount"),
			isRequired: true,
			isHidden: !paymentSystemIsChoosed,
		},
		{
			component: "select",
			name: "currency_code",
			label: t("Currency"),
			options: supportedCurrencyList.map((c) => ({ value: c, label: c })),
			isRequired: true,
			isLoading: supportedCurrencyListIsLoading,
			// placeholder: "Выберите валюту",
			placeholder: t("Select the currency"),
			isHidden: !paymentSystemIsChoosed,
		},
		{
			component: "textInput",
			name: "account_number",
			label: t("Wallet recipient"),
			isRequired: true,
			isHidden: !paymentSystemIsChoosed,
		},
		{
			component: "textInput",
			name: "iban",
			label: "IBAN",
			isRequired: !!formData.payment_system && ["paygiga"].includes(formData.payment_system),
			isHidden: !paymentSystemIsChoosed || !formData.payment_system || !["paygiga"].includes(formData.payment_system),
		},
		{
			component: "textInput",
			name: "withdrawal_id",
			// label: "ID Вывода",
			label: t("Withdrawal ID"),
			isRequired: false,
			isHidden: !paymentSystemIsChoosed,
		},
		{
			component: "textInput",
			name: "account_name",
			label: t("Recipient name"),
			isRequired:
				!!formData.payment_system && ["help2pay", "upi_ib", "paygiga", "imps_ib"].includes(formData.payment_system),
			isHidden: !paymentSystemIsChoosed,
		},
		{
			component: "textInput",
			name: "account_email",
			// label: "Email получателя",
			label: t("Recipient email"),
			isRequired: !!formData.payment_system && ["bkash", "cashmaal"].includes(formData.payment_system),
			isHidden: !paymentSystemIsChoosed,
		},
		{
			component: "textInput",
			name: "payments_method",
			// label: "Cпособ оплаты",
			label: t("Payment method"),
			isRequired: false,
			isHidden: !paymentSystemIsChoosed,
		},
		{
			component: "textInput",
			name: "payments_provider",
			label: t("Payments provider"),
			isRequired: false,
			isHidden: !paymentSystemIsChoosed,
		},
		{
			component: "textInput",
			name: "bank_code",
			label: t("Bank Code"),
			isRequired: !!formData.payment_system && ["help2pay", "paygiga"].includes(formData.payment_system),
			isHidden: !paymentSystemIsChoosed,
		},
		{
			component: "textInput",
			name: "branch_code",
			label: t("Branch code"),
			isRequired: false,
			isHidden: !paymentSystemIsChoosed,
		},
		{
			component: "textInput",
			name: "bank_code_in_payments_system",
			label: t("Bank code in payments system"),
			isRequired: false,
			isHidden: !paymentSystemIsChoosed,
		},
		{
			component: "textarea",
			name: "comment",
			label: t("Comment"),
			isRequired: false,
			isHidden: !paymentSystemIsChoosed,
		},
	];

	const visibleFields = allFields.filter(({ isHidden }) => !isHidden);
	const requiredFields = visibleFields.filter(({ isRequired }) => isRequired);
	const additionalFields = visibleFields.filter(({ isRequired }) => !isRequired);

	return (
		<div className={styles.formWrapper}>
			<ScrollBox className={[styles.form, paymentSystemIsChoosed ? undefined : styles.firstStep].join(" ")}>
				{requiredFields.map((field) => (
					<Field key={field.name} {...field} {...{ formData, fieldChangeHandler, fieldsErrors, touchedFields }} />
				))}

				{/* <Spoiler title="Больше данных" className={styles.additionalFields}> */}
				<Spoiler title={t("More")} className={styles.additionalFields}>
					{additionalFields.map((field) => (
						<Field key={field.name} {...field} {...{ formData, fieldChangeHandler, fieldsErrors, touchedFields }} />
					))}
				</Spoiler>
			</ScrollBox>

			<div className={styles.formErrors}>
				{formErrors.map((error) => (
					<Alert key={error} title={error} variant="danger" withIcon />
				))}
			</div>

			<div className={styles.actions}>
				<Button
					data-test-id=""
					onClick={!isLoading ? submitHandler : undefined}
					disabled={isLoading || !paymentSystemIsChoosed}
					isLoading={isLoading}
				>
					{t("Create")}
				</Button>
			</div>
		</div>
	);
};

export default WithdrawForm;
