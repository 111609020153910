import { useTranslation } from "pay-kit";
import { AutoReportListItemType } from "api/reportsGroup";

import EditIcon from "/assets/icons/edit_24x24.svg";
import TrashIcon from "/assets/icons/trash.svg";

import { ActionsContext } from "../ActionsProvider";
import styles from "../AutoReports.module.scss";
import EmailsList from "./EmailsList";

const columns = (t: ReturnType<typeof useTranslation>['t']) => [
	{
		key: "id",
		dataIndex: "id",
		title: "ID",
		dataTestId: "id"
	},
	{
		key: "report_type",
		dataIndex: "report_type",
		title: t("Report"),
		render: ({ report_type }: AutoReportListItemType) => {
			const reportType = (text: string, type: string) => <span data-test-id={type}>{text}</span>;
			switch (report_type) {
				case "transactions_sum":
					return reportType(t("Sum of transactions"), "transactions_sum");
				case "transactions_list":
					return reportType(t("Transaction list"), "transactions_list");
				case "transactions_sum-detail":
					return reportType(t("Sum of transactions with details by day"), "transactions_sum-detail");
				default:
					return null;
			}
		}
	},
	{
		key: "emails",
		dataIndex: "emails",
		title: t("Recipient"),
		render: ({ emails }: AutoReportListItemType) => <EmailsList emails={emails} dataTestId="emails" />
	},
	{
		key: "start",
		dataIndex: "start",
		title: t("Start"),
		render: ({ start }: AutoReportListItemType) => {
			const splittedTime = start.split(" ");

			return (
				<>
					<div data-test-id="startTime">
						{splittedTime[0]} {splittedTime[1]}
					</div>
					<div data-test-id="timeFormat">{splittedTime[2]}</div>
				</>
			);
		}
	},
	{
		key: "reporting_period",
		dataIndex: "reporting_period",
		title: t("Reporting period"),
		render: ({ reporting_period }: AutoReportListItemType) => {
			let period = "";
			switch (reporting_period) {
				case "day":
					period = t("Day");
					break;
				case "week":
					period = t("Week");
					break;
				case "month":
					period = t("Mounth");
					break;
				case "quarter":
					period = t("Quarter");
					break;
				case "year":
					period = t("Year");
					break;
				default:
					period = "";
			}
			return <span data-test-id="reporting_period">{period}</span>;
		}
	},
	{
		key: "author",
		dataIndex: "author",
		title: t("Author"),
		dataTestId: "author"
	},
	{
		key: "actions",
		dataIndex: "id",
		title: t("Actions"),
		render: ({ id }: AutoReportListItemType) => (
			<ActionsContext.Consumer>
				{({ edit, remove }) => (
					<div className={styles.actions}>
						<button onClick={() => edit(id)} data-test-id="Edit">
							<EditIcon />
						</button>
						<button onClick={() => remove([id])} data-test-id="Remove">
							<TrashIcon />
						</button>
					</div>
				)}
			</ActionsContext.Consumer>
		)
	}
];

export default columns;
