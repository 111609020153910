import Circle from "assets/icons/circle.svg";
import { Tooltip } from "pay-kit";
import { FC } from "react";

import styles from "./circleIcon.module.scss";

const CircleIcon: FC<CircleIconType> = ({ className, tip }) => {

	return (
		<div className={[styles.circleWrapper, className].join(" ")}>
			<Tooltip tip={tip} preferredSide="bottom">
				<span className={styles.circle}>
					<Circle />
				</span>
			</Tooltip>
		</div>);
};

export default CircleIcon;

type CircleIconType = {
	readonly className?: string
	readonly tip?: string
}
