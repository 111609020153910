import Transactions from "assets/icons/transactions.svg";
import { FC } from "react";

import styles from "./transactionsIcon.module.scss";

const TransactionsIcon: FC<TransactionsIconType> = ({
	className
}) => {

	return (
		<div
			className={[styles.commonIcon, className].join(" ")}
		>
			<Transactions />
		</div>
	);
};

export default TransactionsIcon;


type TransactionsIconType = {
	readonly className?: string
}