import { useTranslation } from "@paykassma/pay-kit";
import api, { EditCommissionType } from "api/tariffsGroup";
import { useState } from "react";
import { errorsMap } from "utils/enums";

type useEditCommissionHookType = () => {
	readonly edit: (
		id: number,
		data: EditCommissionType,
		args: {
			readonly onSuccess?: (res: any) => void;
			readonly onError?: (error: Error) => void;
		}
	) => void;
	readonly isLoading: boolean;
};

const useEditCommissionHook: useEditCommissionHookType = () => {
	const {t} = useTranslation();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const edit: ReturnType<useEditCommissionHookType>["edit"] = (
		id: number,
		data: EditCommissionType,
		{ onSuccess, onError }
	) => {
		setIsLoading(true);

		api
			.editCommission(id, data)
			.then((result) => {
				if (result.status !== "fail") {
					onSuccess && onSuccess(result);
					window.pushAlert({ type: "success", description: t("Comission successfully changed"), timeout: 2000 });
				} else {
					Object.keys(result.message).forEach((key) => {
						const value = result.message[key];
						window.pushAlert({ type: "error", description: value.join('\n'), timeout: 2000 });
					});

					window.pushAlert({ type: "error", description: errorsMap.cantUpdateComission, timeout: 2000 });
					onError && onError(result.message);
				}

				return result;
			})
			.finally(() => setIsLoading(false))
			.catch((error) => {
				window.pushAlert({ type: "error", description: errorsMap.cantUpdateComission, timeout: 2000 });
				console.error(error);
			});
	};

	return {
		edit,
		isLoading,
	};
};

export default useEditCommissionHook;
