import { AutoReportListItemType } from "api/reportsGroup";
import { Modal } from "pay-kit";
import React, { useState } from "react";

import RemoveConfirmation from "../components/RemoveConfirmation";
import ActiveWalletsProvider from "./ActiveWalletsContext";
import EditTask from "./EditTask";
import NewTask from "./NewTask";

export const ActionsContext = React.createContext({});

type ActionsProviderPropsType = {
	readonly children: React.ReactNode;
	readonly refreshList: () => void;
};

const ActionsProvider = ({ children, refreshList }: ActionsProviderPropsType) => {
	const [editId, setEditId] = useState<AutoReportListItemType["id"] | undefined>();
	const [removeIds, setRemoveIds] = useState<readonly AutoReportListItemType["id"][]>([]);
	const [createNewMode, setCreateNewMode] = useState<boolean>(false);

	return (
		<ActionsContext.Provider
			value={{
				edit: setEditId,
				remove: setRemoveIds,
				create: setCreateNewMode,
			}}
		>
			<ActiveWalletsProvider>
				<NewTask closeHandler={() => setCreateNewMode(false)} isOpen={createNewMode} refreshList={refreshList} />
				<EditTask closeHandler={() => setEditId(undefined)} taskId={editId} refreshList={refreshList} />
			</ActiveWalletsProvider>

			<Modal
				isOpen={removeIds.length > 0}
				title={`Удаление ${removeIds.length > 1 ? "задач" : "задачи"} на автогенерацию`}
				onClose={() => setRemoveIds([])}
			>
				<RemoveConfirmation removeIds={removeIds} closeHandler={() => setRemoveIds([])} refreshList={refreshList} />
			</Modal>

			{children}
		</ActionsContext.Provider>
	);
};

export default ActionsProvider;
