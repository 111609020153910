import { HintType } from "modules/AppSettings/Plugin/components/PSHints/context";
import { useTranslation } from "pay-kit";
import { Button } from "pay-kit";
import { FC } from "react";

import styles from "./removalConfirmation.module.scss";

const RemovalConfirmation: FC<RemovalConfirmationType> = ({ hint, closeConfirmationModal }) => {
	const { t } = useTranslation();

	const onRemove = () => {
		closeConfirmationModal();
		setTimeout(() => {
			// TODO: Apply the first alert notification after fixing the problem with the not functioning API
			// window.pushAlert({ title: t("Success"), description: "Файл удалён", type: "success" });
			window.pushAlert({
				title: t("Warning"),
				description: t("Unfortunately, the deletion API is not ready yet"),
				type: "warning",
			});
		}, 1000);
	};

	return (
		<div className={styles.removalConfirmation}>
			{t(`Are you sure you want to remove the hint`)} <span className={styles.hint}>{hint?.name}</span>?
			<div className={styles.actions}>
				<Button data-test-id="dwJJkMsYF6M04W4MFKy9s" classname={styles.cancel} onClick={closeConfirmationModal}>
					{t("Cancel")}
				</Button>
				<Button data-test-id="wgN4L_pzxKwJa8ijNW3oD" classname={styles.remove} onClick={onRemove}>
					{t("Delete")}
				</Button>
			</div>
		</div>
	);
};

export default RemovalConfirmation;

type RemovalConfirmationType = {
	readonly hint: Partial<HintType> | null;
	readonly closeConfirmationModal: () => void;
};
