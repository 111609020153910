import { FormTypesItemType, TranslationItemType, TranslationPackageType } from "api/translationsGroup";
import { useTranslation } from "pay-kit";
import { Button } from "pay-kit";
import React, { useState } from 'react';
import { deepClone } from 'utils/deepClone';

import ActionsProvider, { ActionsContext } from '../components/ActionsProvider';
import Skeleton from '../components/Skeleton';
import TranslationsEditor from "../components/TranslationsEditor";
import useUpdatePackages from "../hooks/useUpdatePackages";
import NavigationTree from "./NavigationTree";
import styles from "./Packages.module.scss";
import { prepareNavTreeData } from './utils';

type PackagesPropsType = {
	readonly walletType: string;
	readonly formTypes: readonly FormTypesItemType[];
	readonly languages: readonly string[];
	readonly getPackagesAPI: {
		readonly load: (wallet_type: string, isDefault: boolean) => void;
		readonly list: readonly TranslationPackageType[],
		readonly isLoading: boolean,
	}
}

const Packages: React.FC<PackagesPropsType> = ({ walletType, formTypes, languages, getPackagesAPI }) => {
	const {t} = useTranslation();
	const [selectedField, setSelectedField] = useState<{ readonly packageId: string, readonly fieldId: string } | undefined>();

	const updatePackagesAPI = useUpdatePackages({
		onSuccess: () => {
			getPackagesAPI.load(walletType, false);
			window.pushAlert({ type: "success", title: t("Translation successfully updated") });
		}
	});

	const navTreeData = prepareNavTreeData(getPackagesAPI.list, formTypes);
	const usedNames = navTreeData.map((field) => field.name);
	const selectedPackages = getPackagesAPI.list.find((f) => f.uuid === selectedField?.packageId);
	const selectedPackagesTranslations = selectedPackages ? selectedPackages.packages_translations.find(pt => pt.field === selectedField?.fieldId) : undefined;
	const showSkeleton = getPackagesAPI.isLoading && getPackagesAPI.list.length < 1;

	const editHandler = (translations: readonly TranslationItemType[]) => {
		if (selectedField && selectedPackages) {
			updatePackagesAPI.update(selectedField.packageId, {
				is_manual: selectedPackages.is_manual,
				name: selectedPackages.name,
				packages_translations: deepClone(selectedPackages.packages_translations).map((pt: any) => ({
					field_uuid: pt.field,
					translations: pt.field === selectedField.fieldId ? deepClone(translations) : deepClone(pt.translations),
				}))
			});
		}
	};

	return (
		<ActionsProvider refreshHandler={() => getPackagesAPI.load(walletType, false)}>
			<div className={styles.translations}>
				<nav>
					<div className={styles.header}>
						<h3>{t("Name")}</h3>
						<ActionsContext.Consumer>{({ setAddNewModalData }) => (
							<Button
								variant="text-primary"
								onClick={() => setAddNewModalData({ usedNames, walletType, translationType: "package" })}
								data-test-id="add-new-field-group"
							>
								{t("Add")}
							</Button>
						)}</ActionsContext.Consumer>
					</div>
					{showSkeleton && <Skeleton rows={30} />}
					<NavigationTree
						data={navTreeData}
						selectedField={selectedField}
						setSelectedField={setSelectedField}
						usedNames={usedNames}
					/>
				</nav>
				<aside>
					<div className={styles.header}>
						<h3>{t("Transfer")}</h3>
						<div className={styles.translationName}>{selectedPackages?.name}</div>
					</div>
					{showSkeleton && <Skeleton rows={30} />}
					<TranslationsEditor
						languages={languages}
						initState={selectedPackagesTranslations?.translations || []}
						title={selectedPackagesTranslations?.name}
						isNotEditable={selectedPackages?.is_default === true}
						onSubmit={editHandler}
						isLoading={updatePackagesAPI.isLoading}
						undeletableLangs={['en']}
					/>
				</aside>
			</div>
		</ActionsProvider>
	);
};

export default Packages;
