import API from "api";
import { GetSettlementCommissionParamsType, GetSettlementCommissionType } from "api/commissionGroup";
import { useState } from "react";

const useGetSettlementCommission = () => {
	const [error, setError] = useState<Error | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState<GetSettlementCommissionType | null>(null);

	const load = (params: Partial<GetSettlementCommissionParamsType>) => {
		setError(null);
		setIsLoading(true);

		API.commission.getSettlementCommission(params).then(
			res => {
				if (res.status === "success") {
					setData(res.data);
				}
			}
		).catch(e => {
			console.log(e);
			setError(e);
		}).finally(() => {
			setIsLoading(false);
		});
	};

	return ({
		load,
		data,
		isLoading,
		error
	});
};

export default useGetSettlementCommission;