import { useTranslation } from "pay-kit";
import Translations from 'modules/PaymentSystems/Translations';
import { useEffect } from "react";

export default function TranslationsPage() {
	const {t} = useTranslation();
	useEffect(() => {
		document.title = t("Translations");
	}, []);

	return <Translations />;
}
