// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CreatedAt__date-e8P{font-weight:400;font-size:14px;color:#21272a}.CreatedAt__time-xSI{font-weight:400;font-size:14px;color:#697077}`, "",{"version":3,"sources":["webpack://./src/modules/TransferHistory/components/WithdrawalsHistoryTabs/components/CreatedAt/createdAt.module.scss"],"names":[],"mappings":"AAAA,qBACC,eAAA,CACA,cAAA,CACA,aAAA,CAGD,qBACC,eAAA,CACA,cAAA,CACA,aAAA","sourcesContent":[".date {\n\tfont-weight: 400;\n\tfont-size: 14px;\n\tcolor: #21272A;\n}\n\n.time {\n\tfont-weight: 400;\n\tfont-size: 14px;\n\tcolor: #697077;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"date": `CreatedAt__date-e8P`,
	"time": `CreatedAt__time-xSI`
};
export default ___CSS_LOADER_EXPORT___;
