import { useTranslation } from "pay-kit";
import { Tooltip } from "pay-kit";

import AutoIcon from "./icons/a.svg";
import ManualIcon from "./icons/p.svg";

interface ITransactionType {
	readonly isAuto: boolean;
	readonly dataTestId?: string;
}

const TransactionType = ({ isAuto, dataTestId }: ITransactionType) => {
	const { t } = useTranslation();

	const TRANSACTION_TYPE_MAP = {
		API: {
			title: t("Auto"),
			icon: <AutoIcon />
		},
		MANUAL: {
			title: t("Manual"),
			icon: <ManualIcon />
		}
	};

	const currentType = isAuto ? TRANSACTION_TYPE_MAP.API : TRANSACTION_TYPE_MAP.MANUAL;

	return (
		<Tooltip
			preferredSide="bottom"
			tip={<>
				<div>{t("Request type")}</div>
				<div data-test-id={dataTestId}>{currentType.title}</div>
			</>}>
			{currentType.icon}
		</Tooltip>
	);
};

export default TransactionType;
