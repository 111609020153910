import { ProxyCheckingStatusType } from "modules/Proxies/components/AddProxyModal";
import { checkedStatuses } from "modules/Proxies/data";
import { useTranslation } from "pay-kit";
import { Alert } from "pay-kit";
import { FC, ReactElement } from "react";

import styles from "./inputFormatHint.module.scss";

const InputFormatHint: FC<InputFormatHintType> = ({ children, proxyCheckingStatus }) => {
	const { t } = useTranslation();

	const stringFormat = (
		<span>
			{t(`String format`)} -
			<span className={styles.alertDescription}>IP:{t(`Port:Login:Password:Type:Country_code`)}</span>
		</span>
	);

	return (
		<div>
			<Alert
				className={styles.infoAlertWrapper}
				withIcon={true}
				title={t("Insert proxy list")}
				variant="info"
				description={stringFormat}
			/>
			{children}
			{proxyCheckingStatus === checkedStatuses.NOT_WORKING && (
				<Alert
					className={styles.warningAlertWrapper}
					title={t(`Add at least one working proxy`)}
					variant="warning"
					withIcon={true}
				/>
			)}
		</div>
	);
};

export default InputFormatHint;

type InputFormatHintType = {
	readonly children: ReactElement;
	readonly proxyCheckingStatus: ProxyCheckingStatusType;
};
