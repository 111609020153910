// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecoverWalletModal__modalContent-y4k{display:flex;flex-direction:column;gap:18px}.RecoverWalletModal__successBtn-HYH{background-color:#24a148 !important;color:#fff !important}`, "",{"version":3,"sources":["webpack://./src/modules/Wallets/ArchivedWallets/components/modals/RecoverWalletModal/RecoverWalletModal.module.scss"],"names":[],"mappings":"AAAA,sCACC,YAAA,CACA,qBAAA,CACA,QAAA,CAID,oCACC,mCAAA,CACA,qBAAA","sourcesContent":[".modalContent {\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 18px;\n}\n\n\n.successBtn {\n\tbackground-color: #24a148 !important;\n\tcolor: white !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContent": `RecoverWalletModal__modalContent-y4k`,
	"successBtn": `RecoverWalletModal__successBtn-HYH`
};
export default ___CSS_LOADER_EXPORT___;
