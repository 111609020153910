import { useTranslation } from "pay-kit";
import API from "api";
import { SetSmsCodeParams } from "api/walletGroup";
import { Modal } from "pay-kit";
import { Button, TextInput } from "pay-kit";
import React, { useState } from "react";

import PowerIcon from "../icons/powerIcon.svg";
import styles from "./ActivatePayTM.module.scss";
import { errorsMap } from "utils/enums";

interface IActivatePayTMProps {
	readonly identifier: SetSmsCodeParams["identifier"];
}

const ActivatePayTM: React.FC<IActivatePayTMProps> = ({ identifier }) => {
	const { t } = useTranslation();
	const [sms_code, setSmsCode] = useState<SetSmsCodeParams["sms_code"]>("");
	const [modalIsVisible, setModalVisibility] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const setSmsCodeRequest = () => {
		setIsLoading(true);

		API.wallet
			.setSmsCode({ sms_code, identifier })
			.then((res) => {
				if (res?.status === "ok") {
					setIsLoading(false);
					// window.pushAlert({ description: "Кошелек активирован", type: "success" });
					window.pushAlert({ description: t("Wallet activated"), type: "success" });
					setModalVisibility(false);
					return;
				} else {
					// throw new Error("Unexpected response");
					throw new Error(errorsMap.anyResponse);
				}
			})
			.catch((err) => {
				setIsLoading(false);
				console.error(err);
				// window.pushAlert({ description: "Не удалось подтвердить телефон", type: "error" });
				window.pushAlert({ description: t("Failed to confirm phone"), type: "error" });
			});
	};

	return (
		<>
			<Modal isOpen={modalIsVisible} title={t("Phone confirmation")} onClose={() => setModalVisibility(false)}>
				<form
					className={styles.form}
					onSubmit={(e) => {
						e.preventDefault();
						setSmsCodeRequest();
					}}
				>
					<div className={styles.inputRow}>
						<div className={styles.label}>{t("Enter code")}</div>
						<TextInput
							name="sms_code"
							// placeholder="Код"
							placeholder={t("Code")}
							type="text"
							value={sms_code}
							onChange={(e) => setSmsCode(e.target.value)}
							data-test-id="ActivatePayTM_sms_code"
						/>
					</div>

					<div className={styles.actions}>
						<Button data-test-id="confirm" type="submit" onClick={() => {}} isLoading={isLoading}>
							{t("Confirm")}{" "}
						</Button>
					</div>
				</form>
			</Modal>
			<Button data-test-id="activate" onClick={() => setModalVisibility(true)} color="#24A148">
				<PowerIcon />
				&nbsp;
				{t("Activate")}{" "}
			</Button>
		</>
	);
};

export default ActivatePayTM;
