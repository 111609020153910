import { Toggler } from "@paykassma/pay-kit";
import { WalletType } from "api/walletTypesGroup";
import HelpTip from "components/HelpTip";
import PaymentSystemLogo from "components/PaymentSystemLogo";
import { WalletTypesContext } from "contexts/WalletTypesContext";
import { t, useTranslation } from "pay-kit";

import { ActionsContext } from "../ActionsProvider";
import Actions from "./Actions";
import CurrenciesList from "./CurrenciesList";
import Threshold from "./Threshold";

const columns = [
	{
		title: t("Wallet type"),
		dataIndex: "type",
		key: "type",
		render: ({ code }: WalletType) => <PaymentSystemLogo id={code} dataTestId="name" />,
	},
	{
		title: t("Currencies"),
		dataIndex: "applied_currencies",
		key: "applied_currencies",
		render: ({ applied_currencies }: WalletType) => (
			<CurrenciesList list={applied_currencies} dataTestId="applied_currencies" />
		),
	},
	{
		title: (
			<>
				{t("Possible transactions")}
				<HelpTip tip={t("If there is an error activating a transaction on the plugin, a possible transaction will be created automatically.")} />
			</>
		),
		dataIndex: "use_possible_transactions",
		key: "use_possible_transactions",
		render: ({
			type_settings: { use_possible_transactions, enable_notifications, is_check_amount },
			code,
		}: WalletType) => (
			<ActionsContext.Consumer>
				{({ updateSettingsAPI }) => (
					<WalletTypesContext.Consumer>
						{({ walletTypes }) => {
							const isP2P = walletTypes.find((w) => w.payment_type === "P2P" && w.code === code) !== undefined;

							if (!isP2P) return null;

							return (
								<Toggler
									value={use_possible_transactions}
									onChange={(v) =>
										updateSettingsAPI.update({
											wallet_type: code,
											notifications: enable_notifications,
											use_possible_transactions: v,
											is_check_amount,
										})
									}
									disabled={updateSettingsAPI.isLoading}
									data-test-id=""
									dataTestId="use_possible_transactions"
								/>
							);
						}}
					</WalletTypesContext.Consumer>
				)}
			</ActionsContext.Consumer>
		),
	},
	{
		title: t("Enable on plugin"),
		dataIndex: "plugin_visibility",
		key: "plugin_visibility",
		render: ({ type_settings: { plugin_visibility, enable_notifications, is_check_amount }, code }: WalletType) => (
			<ActionsContext.Consumer>
				{({ updateSettingsAPI }) => (
					<Toggler
						value={plugin_visibility}
						onChange={(v) =>
							updateSettingsAPI.update({
								wallet_type: code,
								notifications: enable_notifications,
								plugin_visibility: v,
								is_check_amount,
							})
						}
						disabled={updateSettingsAPI.isLoading}
						data-test-id=""
						dataTestId="plugin_visibility"
					/>
				)}
			</ActionsContext.Consumer>
		),
	},
	{
		title: t("Standard PS"),
		dataIndex: "is_standard",
		key: "is_standard",
		render: ({ type_settings: { is_standard, enable_notifications, is_check_amount }, code }: WalletType) => (
			<ActionsContext.Consumer>
				{({ updateSettingsAPI }) => (
					<Toggler
						value={is_standard}
						onChange={(v) =>
							updateSettingsAPI.update({
								wallet_type: code,
								notifications: enable_notifications,
								is_standard: v,
								is_check_amount,
							})
						}
						data-test-id=""
						disabled={updateSettingsAPI.isLoading}
						dataTestId="is_standard"
					/>
				)}
			</ActionsContext.Consumer>
		),
	},
	{
		title: t("Notification"),
		dataIndex: "notifications",
		key: "notifications",
		render: ({ type_settings: { enable_notifications, is_check_amount }, code }: WalletType) => (
			<ActionsContext.Consumer>
				{({ updateSettingsAPI }) => (
					<Toggler
						value={enable_notifications}
						disabled={updateSettingsAPI.isLoading}
						onChange={(v) => updateSettingsAPI.update({ wallet_type: code, notifications: v, is_check_amount })}
						data-test-id=""
						dataTestId="enable_notifications"
					/>
				)}
			</ActionsContext.Consumer>
		),
	},
	{
		title: t("Standard threshold (USD)"),
		dataIndex: "standard_threshold",
		key: "standard_threshold",
		render: ({ type_settings: { standard_threshold, enable_notifications, is_check_amount }, code }: WalletType) => (
			<Threshold
				{...{
					type: code,
					initialValue: standard_threshold,
					notifications: enable_notifications,
					is_check_amount,
				}}
			/>
		),
	},
	{
		title: t("Actions"),
		dataIndex: "type",
		key: "actions",
		render: ({ code }: WalletType) => <Actions type={code} dataTestId="code" />,
	},
];

export default columns;
