// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Withdrawal__form-d5I{display:flex;flex-direction:column;padding:32px}.Withdrawal__form-d5I .Withdrawal__baseSettings-Whc{display:grid;grid-template-columns:450px 450px;width:100%;column-gap:48px;row-gap:12px}.Withdrawal__form-d5I .Withdrawal__actions-Qx_{display:flex;align-items:center;justify-content:flex-end;gap:48px;margin-top:auto}.Withdrawal__form-d5I .Withdrawal__actions-Qx_ .Withdrawal__formatSwitcher-jth{display:flex;align-items:center;gap:16px}.Withdrawal__form-d5I .Withdrawal__actions-Qx_ .Withdrawal__formatSwitcher-jth button{padding:0 16px}.Withdrawal__form-d5I .Withdrawal__actions-Qx_>button:last-child{min-width:170px}`, "",{"version":3,"sources":["webpack://./src/modules/Reports/ReportList/components/ActionsProvider/modals/NewReport/forms/Withdrawal/Withdrawal.module.scss"],"names":[],"mappings":"AAAA,sBACC,YAAA,CACA,qBAAA,CACA,YAAA,CAEA,oDACC,YAAA,CACA,iCAAA,CACA,UAAA,CACA,eAAA,CACA,YAAA,CAGD,+CACC,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,QAAA,CACA,eAAA,CAEA,+EACC,YAAA,CACA,kBAAA,CACA,QAAA,CAEA,sFACC,cAAA,CAIF,iEACC,eAAA","sourcesContent":[".form {\n\tdisplay: flex;\n\tflex-direction: column;\n\tpadding: 32px;\n\n\t.baseSettings {\n\t\tdisplay: grid;\n\t\tgrid-template-columns: 450px 450px;\n\t\twidth: 100%;\n\t\tcolumn-gap: 48px;\n\t\trow-gap: 12px;\n\t}\n\n\t.actions {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tjustify-content: flex-end;\n\t\tgap: 48px;\n\t\tmargin-top: auto;\n\n\t\t.formatSwitcher {\n\t\t\tdisplay: flex;\n\t\t\talign-items: center;\n\t\t\tgap: 16px;\n\n\t\t\tbutton {\n\t\t\t\tpadding: 0 16px;\n\t\t\t}\n\t\t}\n\n\t\t> button:last-child {\n\t\t\tmin-width: 170px;\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `Withdrawal__form-d5I`,
	"baseSettings": `Withdrawal__baseSettings-Whc`,
	"actions": `Withdrawal__actions-Qx_`,
	"formatSwitcher": `Withdrawal__formatSwitcher-jth`
};
export default ___CSS_LOADER_EXPORT___;
