import { BarElement, CategoryScale, Chart as ChartJS, ChartData, LinearScale, Title, Tooltip } from "chart.js";
import { FC } from "react";
import { Bar } from "react-chartjs-2";

import { createExternalTooltipHandler } from "../utils";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

const BarChart: FC<BarChartType> = ({ data, unit, psMap }) => {
	const options = {
		plugins: {
			title: {
				display: false,
			},
			legend: {
				display: false,
			},
			tooltip: {
				enabled: false,
				external: createExternalTooltipHandler(psMap, unit),
			},
		},
		tension: 0.4,
		responsive: true,
		maintainAspectRatio: false,
		interaction: {
			intersect: false,
			axis: "xy",
			mode: "index",
		},
		scales: {
			x: {
				stacked: true,
			},
			y: {
				stacked: true,
			},
		},
	} as never;

	return <Bar options={options} data={data} height={380} />;
};

export default BarChart;

type BarChartType = 	{
	readonly data: ChartData<"bar", readonly number[], string>,
	readonly unit?: string
	readonly psMap: Record<string, string>
}