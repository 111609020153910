// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HideZeroBalances__hideZeroBalancesWrapper-KQB{display:flex;margin-top:36px;gap:10px}.HideZeroBalances__togglerHeader-goy{line-height:1.3}`, "",{"version":3,"sources":["webpack://./src/modules/Balance/components/HideZeroBalances/hideZeroBalances.module.scss"],"names":[],"mappings":"AAAA,+CACC,YAAA,CACA,eAAA,CACA,QAAA,CAGD,qCACC,eAAA","sourcesContent":[".hideZeroBalancesWrapper {\n\tdisplay: flex;\n\tmargin-top: 36px;\n\tgap: 10px;\n}\n\n.togglerHeader {\n\tline-height: 1.3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hideZeroBalancesWrapper": `HideZeroBalances__hideZeroBalancesWrapper-KQB`,
	"togglerHeader": `HideZeroBalances__togglerHeader-goy`
};
export default ___CSS_LOADER_EXPORT___;
