// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CancelOperation__modalBtns-mTZ{margin-top:24px;display:flex;justify-content:flex-end}.CancelOperation__modalBtns-mTZ button{margin-left:12px}`, "",{"version":3,"sources":["webpack://./src/modules/TransferHistory/components/CancelOperation/cancelOperation.module.scss"],"names":[],"mappings":"AAAA,gCACC,eAAA,CACA,YAAA,CACA,wBAAA,CAEA,uCACC,gBAAA","sourcesContent":[".modalBtns {\n\tmargin-top: 24px;\n\tdisplay: flex;\n\tjustify-content: flex-end;\n\n\tbutton {\n\t\tmargin-left: 12px;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBtns": `CancelOperation__modalBtns-mTZ`
};
export default ___CSS_LOADER_EXPORT___;
