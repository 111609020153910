import API, { UpdateSettingsType } from "api";
import { useState } from "react";
import { errorsMap } from "utils/enums";


const useGetSettingsParams = () => {
	const [error, setError] = useState<Error | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState<UpdateSettingsType | null>(null);

	const load = () => {
		setError(null);
		setIsLoading(true);
		setData(null);

		API.settings.getSettingsParams().then((resp) => {
			if (resp.status === "success") {
				setData(resp.data);
			} else {
				// throw new Error("Unexpected response in getTimezone");
				throw new Error(errorsMap.anyResponse);
			}
		}).catch((err) => {
			console.log(err);
			setError(err);
		}).finally(() => {
			setIsLoading(false);
		});
	};

	return ({
		load,
		data,
		isLoading,
		error
	});
};

export default useGetSettingsParams;