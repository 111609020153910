// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProxiesView__proxies-TJb{display:flex;flex-direction:column;gap:8px}.ProxiesView__proxies-TJb .ProxiesView__proxy-Vkn{color:#fff}.ProxiesView__proxies-TJb .ProxiesView__proxy-Vkn>div>span:first-child{color:#a2a9b0}`, "",{"version":3,"sources":["webpack://./src/modules/Transactions/CurrentTransactions/DetailedView/Tabs/Wallet/ProxiesView/ProxiesView.module.scss"],"names":[],"mappings":"AAAA,0BACC,YAAA,CACA,qBAAA,CACA,OAAA,CAEA,kDAEC,UAAA,CAEA,uEAEC,aAAA","sourcesContent":[".proxies {\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 8px;\n\n\t.proxy {\n\t\t/* White/100 */\n\t\tcolor: #ffffff;\n\n\t\t& > div > span:first-child {\n\t\t\t/* Blue_gray/50 */\n\t\t\tcolor: #a2a9b0;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"proxies": `ProxiesView__proxies-TJb`,
	"proxy": `ProxiesView__proxy-Vkn`
};
export default ___CSS_LOADER_EXPORT___;
