import { useTranslation } from "@paykassma/pay-kit";
import api, { UpdateTranslationPackageDataType } from "api/translationsGroup";
import { useState } from "react";
import { errorsMap } from "utils/enums";

type useUpdatePackagesHookType = (args: {
	readonly onSuccess?: (res: any) => void;
	readonly onError?: (error: Error) => void;
}) => {
	readonly update: (packageId: string, data: UpdateTranslationPackageDataType) => void;
	readonly isLoading: boolean;
};

const useUpdatePackagesHook: useUpdatePackagesHookType = ({ onSuccess, onError }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const {t} = useTranslation();

	const update = (packageId: string, data: UpdateTranslationPackageDataType) => {
		setIsLoading(true);

		api.updatePackages(packageId, data)
			.then((result) => {
				if (result.status === "success") {
					onSuccess && onSuccess(result);
					return result;
				} else {
					// throw new Error("Unexpected server response in useUpdatePackagesHook");
					throw new Error(errorsMap.anyResponse);
				}
			})
			.finally(() => setIsLoading(false))
			.catch((error) => {
				console.error(error);
				onError && onError(error);
				window.pushAlert({ type: "error", title: t("Translation updating error") });
			});
	};

	return {
		update,
		isLoading,
	};
};

export default useUpdatePackagesHook;
