import api, { AutoReportListItemType } from "api/reportsGroup";
import { useState } from "react";
import { errorsMap } from "utils/enums";

export type getAutoReportsResultType = {
	readonly load: () => void;
	readonly list: readonly AutoReportListItemType[];
	readonly isLoading: boolean;
};

export type getAutoReportsHookType = () => getAutoReportsResultType;

const useGetAutoReportsHook = () => {
	const [list, setList] = useState<readonly AutoReportListItemType[]>([]);
	const [isLoading, setLoading] = useState<boolean>(false);

	const load = () => {
		setLoading(true);

		api
			.getAutoReports()
			.then((res: any) => {
				if (res.status !== "success" || !res?.data) {
					// throw new Error("Unexpected response in getAutoReports");
					throw new Error(errorsMap.anyResponse);
				}

				setList(res?.data);
				return res;
			})
			.finally(() => setLoading(false))
			.catch((err) => {
				console.error(err);
				window.pushAlert({ title: err, type: "error" });
			});
	};

	return {
		load,
		list,
		isLoading,
	};
};

export default useGetAutoReportsHook;
