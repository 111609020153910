// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TransactionMetrics__metricsWrapper-nNy{width:max-content;border:1px solid #f2f3f5;border-radius:8px;background:#fcfcfd;font-size:14px}.TransactionMetrics__metricsWrapper-nNy .TransactionMetrics__summaryHeader-xZ5{margin:12px 12px 0 12px}.TransactionMetrics__metricsWrapper-nNy .TransactionMetrics__metrics-poN{margin:8px 12px 12px 12px;display:flex;gap:16px}.TransactionMetrics__metricsWrapper-nNy .TransactionMetrics__metrics-poN .TransactionMetrics__amountType-WsN{font-size:12px;color:#697077}.TransactionMetrics__metricsWrapper-nNy .TransactionMetrics__metrics-poN .TransactionMetrics__amount-u60{font-size:16px}.TransactionMetrics__metricsWrapper-nNy .TransactionMetrics__metrics-poN .TransactionMetrics__currency-kri{margin-left:4px;font-size:11px;font-weight:400}`, "",{"version":3,"sources":["webpack://./src/modules/Dashboard/components/TransactionMetrics/transactionMetrics.module.scss"],"names":[],"mappings":"AAAA,wCACC,iBAAA,CACA,wBAAA,CACA,iBAAA,CACA,kBAAA,CACA,cAAA,CAEA,+EACC,uBAAA,CAGD,yEACC,yBAAA,CACA,YAAA,CACA,QAAA,CAEA,6GACC,cAAA,CACA,aAAA,CAGD,yGACC,cAAA,CAGD,2GACC,eAAA,CACA,cAAA,CACA,eAAA","sourcesContent":[".metricsWrapper {\n\twidth: max-content;\n\tborder: 1px solid #F2F3F5;\n\tborder-radius: 8px;\n\tbackground: #FCFCFD;\n\tfont-size: 14px;\n\n\t.summaryHeader {\n\t\tmargin: 12px 12px 0 12px;\n\t}\n\n\t.metrics {\n\t\tmargin: 8px 12px 12px 12px;\n\t\tdisplay: flex;\n\t\tgap: 16px;\n\n\t\t.amountType {\n\t\t\tfont-size: 12px;\n\t\t\tcolor: #697077;\n\t\t}\n\n\t\t.amount {\n\t\t\tfont-size: 16px;\n\t\t}\n\n\t\t.currency {\n\t\t\tmargin-left: 4px;\n\t\t\tfont-size: 11px;\n\t\t\tfont-weight: 400;\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"metricsWrapper": `TransactionMetrics__metricsWrapper-nNy`,
	"summaryHeader": `TransactionMetrics__summaryHeader-xZ5`,
	"metrics": `TransactionMetrics__metrics-poN`,
	"amountType": `TransactionMetrics__amountType-WsN`,
	"amount": `TransactionMetrics__amount-u60`,
	"currency": `TransactionMetrics__currency-kri`
};
export default ___CSS_LOADER_EXPORT___;
