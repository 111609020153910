// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WithdrawalsFilter__filter-toP{margin-top:36px}.WithdrawalsFilter__filter-toP .WithdrawalsFilter__inlineFilter-RDp{display:grid;grid-template-columns:180px 180px 180px 120px 265px 110px 160px}.WithdrawalsFilter__filter-toP .WithdrawalsFilter__inlineFilter-RDp input[name=date-range-picker-left-field]{width:100px}.WithdrawalsFilter__filter-toP .WithdrawalsFilter__inlineFilter-RDp input[name=date-range-picker-right-field]{width:116px}`, "",{"version":3,"sources":["webpack://./src/modules/Withdrawals/components/WithdrawalsFilter/WithdrawalsFilter.module.scss"],"names":[],"mappings":"AAAA,+BACI,eAAA,CAEA,oEACI,YAAA,CACA,+DAAA,CAEA,6GACI,WAAA,CAGJ,8GACI,WAAA","sourcesContent":[".filter {\n    margin-top: 36px;\n\n    .inlineFilter {\n        display: grid;\n        grid-template-columns: 180px 180px 180px 120px 265px 110px 160px;\n\n        input[name=\"date-range-picker-left-field\"] {\n            width: 100px;\n        }\n\n        input[name=\"date-range-picker-right-field\"] {\n            width: 116px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter": `WithdrawalsFilter__filter-toP`,
	"inlineFilter": `WithdrawalsFilter__inlineFilter-RDp`
};
export default ___CSS_LOADER_EXPORT___;
