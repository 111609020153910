import { CurrencyListType, StockPilingsType } from "api/stockpilingsGroup";

export const formStockPilingsList: getCurrencyCodeType = (stockPilings, currencyCodes) => {

	return stockPilings.map((stockPilingElement: StockPilingsType) => {
		const specificCurrencyData: {
			readonly code: string,
			readonly symbol: string
		} | undefined = currencyCodes.find(({ symbol }: {
			readonly symbol: string
		}) => symbol === stockPilingElement.currency_symbol);
		return { ...stockPilingElement, code: specificCurrencyData?.code };
	});

};

type getCurrencyCodeType = {
	(stockPilings: readonly StockPilingsType[], currencyCodes: CurrencyListType["currencies"]): readonly StockPilingsType[]
}
