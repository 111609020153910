import LinkIcon from "assets/icons/link.svg";
import { FC, ReactElement } from "react";

import styles from "./metric.module.scss";

const Metric: FC<MetricType> = ({ title, count, className, dataTestId, onClick }) => {
	const classNames = [styles.metricsBlock, className];

	if (onClick) {
		classNames.push(styles.link);
	}

	return (
		<div data-test-id="wFJV4hCoAx6jq_W7qZhuL" className={classNames.join(" ")} onClick={onClick}>
			<div className={styles.metricTitle}>{title}</div>
			<div className={styles.count} data-test-id={dataTestId}>
				{count}
			</div>
			{onClick && <LinkIcon />}
		</div>
	);
};

export default Metric;

type MetricType = {
	readonly title: string;
	readonly count: number;
	readonly Link?: ReactElement;
	readonly className?: string;
	readonly dataTestId?: string;
	readonly onClick?: React.MouseEventHandler<HTMLDivElement>;
};
