import { useTranslation } from "pay-kit";
import { Loader, Modal } from "pay-kit";
import { useEffect } from "react";

import LimitsForm from "./LimitsForm";
import styles from "./LimitsSettings.module.scss";
import useGetLimitsHook from "./useGetLimitsHook";
import useSetLimitsHook from "./useSetLimitsHook";

export interface ILimitsSettingsProps {
	readonly psId?: string;
	readonly isOpen: boolean;
	readonly onClose: () => void;
	readonly onSuccess: () => void;
}

const LimitsSettings: React.FC<ILimitsSettingsProps> = ({ isOpen, onClose, psId, onSuccess }) => {
	const { t } = useTranslation();
	const getLimitsHook = useGetLimitsHook();

	const setLimitsHook = useSetLimitsHook({
		onSuccess: () => {
			onSuccess();
			onClose();
			window.pushAlert({ description: t("Limits updated successfully"), type: "success" });
		},
		onFail: () => window.pushAlert({ description: t("An error occurred while trying to change the limit"), type: "error" }),
	});

	useEffect(() => {
		if (psId !== undefined) {
			getLimitsHook.load(psId);
		}
	}, [psId]);

	return (
		<Modal isOpen={isOpen} title={t("Limits")} onClose={onClose} className={styles.modal}>
			{getLimitsHook.isLoading ? (
				<Loader />
			) : (
				<LimitsForm
					isLoading={setLimitsHook.isLoading}
					initState={getLimitsHook.list}
					onSubmit={(data) => setLimitsHook.post(psId as string, data)}
				/>
			)}
		</Modal>
	);
};

export default LimitsSettings;
