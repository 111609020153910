// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TransactionType__TransactionType-_Ph{line-height:20px;padding:0 8px;border-radius:10px;text-transform:uppercase;font-weight:500;font-size:14px}.TransactionType__TransactionType-_Ph.TransactionType__APPROVED-qdC{color:#24a148;border:1px solid #6fdc8c}.TransactionType__TransactionType-_Ph.TransactionType__DEBUG-tLN{color:#e8882e;border:1px solid #ffce78}.TransactionType__TransactionType-_Ph.TransactionType__FORCED-GPx{color:#0072c3;border:1px solid #82cfff}.TransactionType__TransactionType-_Ph.TransactionType__INTERNAL-oQw{color:#645eb4;border:1px solid #c5c2f1}`, "",{"version":3,"sources":["webpack://./src/modules/Transactions/components/TransactionType/TransactionType.module.scss"],"names":[],"mappings":"AAAA,sCACC,gBAAA,CACA,aAAA,CACA,kBAAA,CACA,wBAAA,CACA,eAAA,CACA,cAAA,CAEA,oEAEC,aAAA,CAEA,wBAAA,CAGD,iEAEC,aAAA,CAEA,wBAAA,CAGD,kEAEC,aAAA,CAEA,wBAAA,CAGD,oEACC,aAAA,CACA,wBAAA","sourcesContent":[".TransactionType {\n\tline-height: 20px;\n\tpadding: 0 8px;\n\tborder-radius: 10px;\n\ttext-transform: uppercase;\n\tfont-weight: 500;\n\tfont-size: 14px;\n\n\t&.APPROVED {\n\t\t/* Additional/Green/Primary */\n\t\tcolor: #24a148;\n\t\t/* Additional/Green/Light */\n\t\tborder: 1px solid #6fdc8c;\n\t}\n\n\t&.DEBUG {\n\t\t/* Additional/Orange/Primary */\n\t\tcolor: #e8882e;\n\t\t/* Additional/Orange/Light */\n\t\tborder: 1px solid #ffce78;\n\t}\n\n\t&.FORCED {\n\t\t/* Brand/Primary */\n\t\tcolor: #0072c3;\n\t\t/* Additional/Blue/Light */\n\t\tborder: 1px solid #82cfff;\n\t}\n\n\t&.INTERNAL {\n\t\tcolor: #645eb4;\n\t\tborder: 1px solid #c5c2f1;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TransactionType": `TransactionType__TransactionType-_Ph`,
	"APPROVED": `TransactionType__APPROVED-qdC`,
	"DEBUG": `TransactionType__DEBUG-tLN`,
	"FORCED": `TransactionType__FORCED-GPx`,
	"INTERNAL": `TransactionType__INTERNAL-oQw`
};
export default ___CSS_LOADER_EXPORT___;
