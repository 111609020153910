import { ReportStatus, ReportsListItemType } from "api/reportsGroup";
import DownloadIcon from "assets/icons/download.svg";
import styles from "../../ReportList.module.scss";
import { IActionsContextValue } from "../ActionsProvider/types";
import StatusBadge from "../StatusBadge";
import { t } from "@paykassma/pay-kit";
import { TrashIcon } from "components/Icons/TrashIcon/TrashIcon";

type ReportColumnsProps = {
	downloadReports: IActionsContextValue["downloadReports"];
	deleteReports: IActionsContextValue["deleteReports"];
};

export const reportColumns = ({downloadReports, deleteReports}: ReportColumnsProps) => [
    {
        key: "file_name",
        dataIndex: "file_name",
        title: t("File"),
        dataTestId: "file_name"
    },
    {
        key: "status",
        dataIndex: "status",
        title: t("Status"),
        dataTestId: "status",
        render: ({ status }: ReportsListItemType) => <StatusBadge status={status}/>,
    },
    {
        key: "created_at",
        dataIndex: "created_at",
        title: t("Creation date"),
        className: styles.dateCol,
        dataTestId: "created_at"
    },
    {
        key: "processed_at",
        dataIndex: "processed_at",
        title: t("Completion date"),
        className: styles.dateCol,
        dataTestId: "processed_at"
    },
    {
        key: "file_path",
        dataIndex: "file_path",
        title: t("Actions"),
        className: styles.actionsCol,
        render: (report: ReportsListItemType) => {
            return <div className={styles.actions}>
                {report.status === ReportStatus.SUCCESS && (
                    <button
                        data-test-id="download"
                        onClick={() => downloadReports([report.file_path])}
                    >
                        <DownloadIcon />
                    </button>
                )}
                <button
                    data-test-id="delete"
                    className={styles.deleteReport}
                    onClick={() => deleteReports([report.file_path])}
                >
                    <TrashIcon />
                </button>
            </div>;
        }
    }
];
