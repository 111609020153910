// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConfirmationModal__ConfirmationModal-k1I{width:100%}.ConfirmationModal__ConfirmationModal-k1I .ConfirmationModal__actions-UHC{margin-top:32px;display:flex;justify-content:flex-end;gap:16px}`, "",{"version":3,"sources":["webpack://./src/modules/Transactions/PossibleTransactions/ActionsProvider/ConfirmationModal/ConfirmationModal.module.scss"],"names":[],"mappings":"AAAA,0CACI,UAAA,CAEA,0EACI,eAAA,CACA,YAAA,CACA,wBAAA,CACA,QAAA","sourcesContent":[".ConfirmationModal {\n    width: 100%;\n\n    .actions {\n        margin-top: 32px;\n        display: flex;\n        justify-content: flex-end;\n        gap: 16px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ConfirmationModal": `ConfirmationModal__ConfirmationModal-k1I`,
	"actions": `ConfirmationModal__actions-UHC`
};
export default ___CSS_LOADER_EXPORT___;
