import { ScrollBox } from "@paykassma/pay-kit";
import CaretIcon from "components/Icons/CaretIcon";
import {
	ReducedWalletsSummaryKeyListType,
	reducedWalletsSummaryType,
} from "modules/Dashboard/components/WalletsTotalBalance";
import WalletSummaryDisplay from "modules/Dashboard/components/WalletsTotalBalance/components/WalletSummaryDisplay";
import { useTranslation } from "pay-kit";
import { FC, Fragment, useState } from "react";

import styles from "./walletSummary.module.scss";

const WalletSummary: FC<WalletsSummaryType> = ({ reducedWalletsSummary, reducedWalletsSummaryKeyList }) => {
	const [summaryKey, setSummaryKey] = useState({} as summaryKeyType);
	const { t } = useTranslation();

	const summeryItemStyle = {
		up: [styles.summaryItem, styles.summaryItemActive].join(" "),
		down: styles.summaryItem,
	};
	const caretStyle = {
		up: [styles.caret, styles.caretUp].join(" "),
		down: styles.caret,
	};

	const OnClick: OnClickType = (key) => {
		setSummaryKey((item) => ({
			...item,
			[key]: item[key] === "up" ? "down" : "up",
		}));
	};

	const renderSummaryItems = () => {
		return reducedWalletsSummaryKeyList?.map((summary, i) => (
			<Fragment key={i}>
				<div
					data-test-id="summaryItem"
					className={summeryItemStyle[summaryKey[i]] || summeryItemStyle.down}
					onClick={() => OnClick(i)}
				>
					<CaretIcon className={caretStyle[summaryKey[i]] || caretStyle.down} />

					<span className={styles.summary}>{summary}</span>

					{reducedWalletsSummary && (
						<span className={styles.count}>
							{reducedWalletsSummary[summary].worked} ({reducedWalletsSummary[summary].all})
						</span>
					)}
				</div>
				<WalletSummaryDisplay
					summaryKey={summaryKey[i]}
					WalletsSummary={reducedWalletsSummary && reducedWalletsSummary[summary]}
				/>
			</Fragment>
		));
	};

	return (
		<div className={styles.walletSummaryWrapper}>
			<div className={styles.header}>
				{t(`PS`)} {t(`Working (Total)`)}
			</div>

			<ScrollBox scrollDirection="vertical" className={styles.walletsummaryScroll}>
				{renderSummaryItems()}
			</ScrollBox>
		</div>
	);
};

export default WalletSummary;

type WalletsSummaryType = {
	readonly reducedWalletsSummary: reducedWalletsSummaryType | null;
	readonly reducedWalletsSummaryKeyList: ReducedWalletsSummaryKeyListType | null;
};

export type summaryKeyType = {
	readonly [key: number]: "up" | "down";
};

type OnClickType = (key: number) => void;
