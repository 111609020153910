import API from "api";
import { MakingReplenishmentParamsType } from "api/balanceGroup";
import { useCallback, useState } from "react";

const useMakingReplenishment = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<null | Error>(null);

	const update: UpdateHandler = useCallback((data, onSuccess) => {
		setIsLoading(true);
		setError(null);

		API.balances.makingReplenishment(data).then(
			res => {
				if (res.status === "ok") {
					onSuccess && onSuccess();
				} else {
					throw new Error("Unexpected response in useMakingReplenishment");
				}

				return res;
			}
		).catch((err) => {
			setError(err);
		}).finally(() =>
			setIsLoading(false)
		);

	}, []);

	return {
		update,
		isLoading,
		error
	};
};

export default useMakingReplenishment;

type UpdateHandler = (data: MakingReplenishmentParamsType, onSuccess?: () => void) => void