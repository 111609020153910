import { useTranslation } from "pay-kit";
import { Badge, Loader } from "pay-kit";
import { FC } from "react";

import styles from "./checkingStatus.module.scss";

const CheckingStatus: FC<CheckingStatusType> = ({
	status,
	in_system,
	isChecking
}) => {
	const {t} = useTranslation();

	const statusesText = {
		0: t("Not working"),
		1: t("Working"),
		2: t("Checking")
	};

	const statusesVariants = {
		0: "red",
		1: "green",
		2: "orange"
	} as const;

	const statusKey = isChecking === undefined || isChecking ? status : 2;

	const statusType = (
		isChecking ?
			<Loader color="#A2A9B0" /> :
			<Badge variant={statusesVariants[statusKey]}>
				<div>{statusesText[statusKey]}</div>
			</Badge>);

	return (
		<>
			<div className={styles.badgeMaxContent}>
				{statusType}
			</div>
			{in_system && !isChecking &&
				<div className={[styles.badgeMaxContent, styles.inSystemBadge].join(" ")}>
					<Badge variant="orange">
						<div>{t("existsInSystem")}</div>
					</Badge>
				</div>
			}
		</>
	);
};

export default CheckingStatus;

type CheckingStatusType = {
	readonly status: 0 | 1
	readonly in_system: boolean
	readonly isChecking: boolean

}