import { WalletListItem } from "api/walletGroup";

export const getUniqueWallets = (wallets: WalletListItem[]) => {
    const ids: WalletListItem[`id`][] = [];

    return wallets.filter((wallet: WalletListItem) => {
        if (!ids.includes(wallet.id)) {
            ids.push(wallet.id);
            return true;
        }

        return false;
    })
}