import { t } from '@paykassma/pay-kit';
import ArrowSVG from './arrow.svg';
import styles from './EmptyPlaceholder.module.scss';
import PlaceholderSVG from './placeholder.svg';


const EmptyPlaceholder = () => {
	return (
		<div className={styles.placeholder}>
			<div>
				<PlaceholderSVG />
				<div className={styles.caption}>
					{/* Выберите параметры в настройках нагрузки */}
					{t("Select load parameters")}
				</div>
			</div>
			<div>
				<ArrowSVG />
			</div>
		</div>
	);
};

export default EmptyPlaceholder;
