import { TableColumnType } from "@paykassma/pay-kit/lib/elements/Table/types";
import { WalletListItem } from "api/walletGroup";
import ColumnTitleSeparator from "components/ColumnTitleSeparator";
import CopyTextButton from "components/CopyTextButton";
import PaymentSystemLogo from "components/PaymentSystemLogo";
import he from "he";
import Balance from "modules/Wallets/components/Balance";
import Priority from "modules/Wallets/components/Priority";
import StatusBadges from "modules/Wallets/WorkingWallets/components/StatusBadges";
import { useTranslation } from "pay-kit";
import { Tooltip } from "pay-kit";
import { Dispatch, SetStateAction } from "react";

import Comment from "../../../components/Comments/index";
import Actions from "../Actions";
import styles from "./Columns.module.scss";

type disabledWalletsColumnsType = (
	setWalletId: Dispatch<SetStateAction<string | undefined>>,
	setID: Dispatch<SetStateAction<number | undefined>>,
	t: ReturnType<typeof useTranslation>["t"],
	privateAccess: boolean,
	balanceAccess: boolean
) => readonly TableColumnType[];
type IdColType = (
	setWalletId: Dispatch<SetStateAction<string | undefined>>,
	setID: Dispatch<SetStateAction<number | undefined>>
) => TableColumnType;

const disabledWalletsColumns: disabledWalletsColumnsType = (setWalletId, setID, t, privateAccess, balanceAccess) => {
	const idCol = {
		title: "ID",
		dataIndex: "id",
		key: "id",
		render: ({ id }: WalletListItem) => (
			<div data-test-id="id" className={styles.id}>
				{id}
			</div>
		),
	};

	const hashCol = {
		title: t("Hash"),
		dataIndex: "hash_id",
		key: "hash_id",
		dataTestId: "hash_id",
	};

	const walletTypeCol = {
		title: t("Wallet type"),
		dataIndex: "type",
		key: "type",
		render: ({ wallet_type }: WalletListItem) => <PaymentSystemLogo id={wallet_type} dataTestId="type" />,
	};

	const identifierCol: IdColType = (setWalletId, setID) => ({
		title: t("Wallet number"),
		dataIndex: "identifier",
		key: "identifier",
		render: ({ identifier, hash_id, id }: WalletListItem) => (
			<Tooltip tip={identifier} preferredSide="bottom">
				<div className={styles.accountNumberWrapper}>
					<div
						className={styles.account_number}
						onClick={() => {
							setWalletId(hash_id);
							setID(id);
						}}
						data-test-id="identifier"
					>
						{identifier}
					</div>
					<CopyTextButton text={identifier} />
				</div>
			</Tooltip>
		),
	});

	const usernameCol = {
		title: t("Site login"),
		dataIndex: "username",
		key: "username",
		dataTestId: "username",
	};

	const emailCol = {
		title: t("Email"),
		dataIndex: "email_username",
		key: "email_username",
		dataTestId: "email_username",
	};

	const balanceCol = {
		title: t("Balance"),
		dataIndex: "balance",
		key: "balance",
		render: ({ balance, currency, extra_balances }: WalletListItem) => (
			<Balance balance={balance} currency={currency} extra_balances={extra_balances} />
		),
	};

	const extraInfoCol = {
		title: t("Extra information"),
		dataIndex: "upi_addresses",
		key: "upi_addresses",
		render: ({ upi_addresses, display_identifier }: WalletListItem) => (
			<>
				<span data-test-id="upi_addresses">{upi_addresses && upi_addresses.join(", ")}</span>
				<div>{display_identifier && he.decode(display_identifier)}</div>
			</>
		),
	};

	const commentCol = {
		title: t("Comment"),
		dataIndex: "comment",
		key: "comment",
		render: ({ comment, hash_id }: WalletListItem) => (
			<Comment comment={comment} hash_id={hash_id} dataTestId="comment" />
		),
	};

	let columns = [idCol, hashCol, walletTypeCol, identifierCol(setWalletId, setID)];

	if (privateAccess) {
		columns = [...columns, emailCol, usernameCol];
	}

	if (balanceAccess) {
		columns = [...columns, balanceCol];
	}

	columns = [...columns, extraInfoCol, commentCol];

	return columns;
};

export const subRow = ({ hash_id, states, priority, identifier }: WalletListItem) => {
	return (
		<div className={styles.subRow}>
			<Priority priority={priority} />
			{(states || []).length > 0 && (
				<>
					<ColumnTitleSeparator />
					<StatusBadges state={states} />
				</>
			)}
			<Actions hash_id={hash_id} identifier={identifier} />
		</div>
	);
};

export default disabledWalletsColumns;
