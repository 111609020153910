// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StockpilingsContext__transactionsModal-vau.StockpilingsContext__transactionsModal-vau{max-width:1240px}`, "",{"version":3,"sources":["webpack://./src/modules/Stockpilings/components/Columns/contexts/StockpilingsContext/stockpilingsContext.module.scss"],"names":[],"mappings":"AAAA,uFACC,gBAAA","sourcesContent":[".transactionsModal.transactionsModal {\n\tmax-width: 1240px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"transactionsModal": `StockpilingsContext__transactionsModal-vau`
};
export default ___CSS_LOADER_EXPORT___;
