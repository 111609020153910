import { UpdateSettingsType } from "api";
import { t } from "pay-kit";

const UPI_Error = (method: string) =>
	`${method}: ${t(`Link-only payment is not available. Select other payment method.`)}`;

const checkForLink = (methodTypeList: readonly paymentMethodsType[]) =>
	methodTypeList.map((elem1) => ({
		isLink: elem1.methodsCount === 1 && elem1.methods.find((elem2) => elem2.code === "LINK"),
		method: elem1.methodTitle,
	}));

const checkForError = (arr: readonly checkForError[]) => arr.map((elem) => (elem.isLink ? UPI_Error(elem.method) : ``));

const compose = <T>(...functions: readonly Function[]) => {
	return (array: readonly T[]) => {
		return functions.reduce((param, func) => func(param), array);
	};
};

export const upiErrors = compose(checkForLink, checkForError);

type paymentMethodsType = {
	readonly methods: UpdateSettingsType[`upi_p2p_payment_methods` | `upi_api_payment_methods`];
	readonly methodTitle: string;
	readonly methodsCount: number;
};

type checkForError = { readonly isLink: object; readonly method: string };
