// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ActivateWalletModal__successBtn-yNV{background-color:#24a148;color:#fff}`, "",{"version":3,"sources":["webpack://./src/modules/Wallets/DisabledWallets/components/modals/ActivateWalletModal/ActivateWalletModal.module.scss"],"names":[],"mappings":"AAAA,qCACC,wBAAA,CACA,UAAA","sourcesContent":[".successBtn {\n\tbackground-color: #24a148;\n\tcolor: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"successBtn": `ActivateWalletModal__successBtn-yNV`
};
export default ___CSS_LOADER_EXPORT___;
