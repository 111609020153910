import { t } from "@paykassma/pay-kit";
import API from "api";
import { IWithdrawalListItem, IWithdrawalsFilterForm, IWithdrawalsPaginatorParams } from "api/withdrawalGroup";
import { useState } from "react";
import { fromLocaleDate } from "utils/date";

const useWithdrawalsListAPI: useWithdrawalsListAPIType = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [withdrawals, setWithdrawals] = useState<readonly IWithdrawalListItem[]>([]);
	const [error, setError] = useState<null | Error>(null);
	const [paginationInfo, setPaginationInfo] = useState<null | paginationInfoType>(null);

	const getWithdrawalsList = (params: IWithdrawalsFilterForm & IWithdrawalsPaginatorParams) => {
		setIsLoading(true);
		setError(null);
		setWithdrawals([]);

		API.withdrawal
			.getList(transformDates(params))
			.then((res: any) => {
				setIsLoading(false);

				if (res?.status === "ok" && res?.withdrawals?.data) {
					const { data, ...rest } = res.withdrawals;
					setWithdrawals(data);
					setPaginationInfo(rest);

					return;
				} else {
					throw new Error(t("Invalid response"));
				}
			})
			.catch((err) => {
				setIsLoading(false);
				setError(err);
			});
	};

	return {
		getWithdrawalsList,
		isLoading,
		withdrawals,
		error,
		paginationInfo,
	};
};

export default useWithdrawalsListAPI;

export type useWithdrawalsListAPIType = () => {
	readonly getWithdrawalsList: any;
	readonly isLoading: boolean;
	readonly withdrawals: readonly IWithdrawalListItem[];
	readonly error: Error | null;
	readonly paginationInfo: paginationInfoType | null;
};

export type paginationInfoType = {
	readonly current_page: number;
	readonly from: number;
	readonly to: number;
	readonly per_page: number;
	readonly last_available_page: number;
};

export type getListSuccessResponseType = {
	readonly status: "ok";
	readonly withdrawals: {
		readonly data: readonly IWithdrawalListItem[];
	} & paginationInfoType;
};

const transformDates = (
	filter: IWithdrawalsFilterForm & { readonly date?: { readonly from?: string; readonly to?: string } }
) => {
	if (filter.date !== undefined) {
		const filterClone = JSON.parse(JSON.stringify(filter));

		if (filter.date.from) {
			filterClone.date_from = fromLocaleDate(filterClone.date.from);
		}

		if (filter.date.to) {
			filterClone.date_to = fromLocaleDate(filterClone.date.to);
		}

		delete filterClone.date;

		return filterClone;
	}

	return filter;
};
