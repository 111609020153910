// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ApproveModal__form-qOR{display:flex;flex-direction:column;gap:28px}.ApproveModal__form-qOR>:nth-child(1){display:grid;grid-template-columns:170px 1fr;gap:24px}.ApproveModal__form-qOR>[class^=Select__Select]{display:grid;grid-template-columns:170px 1fr;gap:24px}.ApproveModal__form-qOR>[class^=TextInput],.ApproveModal__form-qOR>.Textarea{display:grid;grid-template-columns:170px 1fr;gap:24px}.ApproveModal__form-qOR>[class^=TextInput]>:nth-child(1),.ApproveModal__form-qOR>.Textarea>:nth-child(1){font-weight:400;font-size:14px;line-height:20px;color:#697077}.ApproveModal__form-qOR .ApproveModal__actions-Ojy{display:flex;flex:1;align-self:flex-end}.ApproveModal__note-H7C{margin-top:40px}`, "",{"version":3,"sources":["webpack://./src/modules/Withdrawals/components/ActionsProvider/ApproveModal/ApproveModal.module.scss"],"names":[],"mappings":"AAAA,wBACI,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,sCACI,YAAA,CACA,+BAAA,CACA,QAAA,CAGJ,gDACI,YAAA,CACA,+BAAA,CACA,QAAA,CAGJ,6EAEI,YAAA,CACA,+BAAA,CACA,QAAA,CAEA,yGACI,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,aAAA,CAIR,mDACI,YAAA,CACA,MAAA,CACA,mBAAA,CAIR,wBACI,eAAA","sourcesContent":[".form {\n    display: flex;\n    flex-direction: column;\n    gap: 28px;\n\n    >:nth-child(1) {\n        display: grid;\n        grid-template-columns: 170px 1fr;\n        gap: 24px;\n    }\n\n    >:global([class^=Select__Select]) {\n        display: grid;\n        grid-template-columns: 170px 1fr;\n        gap: 24px;\n    }\n\n    >:global([class^=TextInput]),\n    >:global(.Textarea) {\n        display: grid;\n        grid-template-columns: 170px 1fr;\n        gap: 24px;\n\n        >:nth-child(1) {\n            font-weight: 400;\n            font-size: 14px;\n            line-height: 20px;\n            /* Blue_gray/70 */\n            color: #697077;\n        }\n    }\n\n    .actions {\n        display: flex;\n        flex: 1;\n        align-self: flex-end;\n    }\n}\n\n.note {\n    margin-top: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `ApproveModal__form-qOR`,
	"actions": `ApproveModal__actions-Ojy`,
	"note": `ApproveModal__note-H7C`
};
export default ___CSS_LOADER_EXPORT___;
