// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Replenish__submitButton-tJz{margin-top:16px}.Replenish__submitButton-tJz>button{margin-left:auto}`, "",{"version":3,"sources":["webpack://./src/modules/TransferHistory/components/Replenish/replenish.module.scss"],"names":[],"mappings":"AAAA,6BACC,eAAA,CAEA,oCACC,gBAAA","sourcesContent":[".submitButton {\n\tmargin-top: 16px;\n\n\t& > button {\n\t\tmargin-left: auto;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"submitButton": `Replenish__submitButton-tJz`
};
export default ___CSS_LOADER_EXPORT___;
