import { WalletTypes } from "utils/enums";

export interface Scheme {
	readonly username?: boolean;
	readonly password?: boolean;
	readonly email?: boolean;
	readonly defaultLogin?: boolean;
	readonly parser?: boolean;
	readonly accountType?: boolean;
	readonly privateApiKey?: boolean;
	readonly publicApiKey?: boolean;
	readonly merchantId?: boolean;
	readonly token?: boolean;
	readonly accountName?: boolean;
	readonly accountNumber?: boolean;
	readonly upi?: boolean;
	readonly ifscCode?: boolean;
	readonly cookie?: boolean;
	readonly codephrases?: boolean;
	readonly parsingObject?: boolean;
	readonly upiAddresses?: boolean;
	readonly projectId?: boolean;
	readonly integrationType?: boolean;
	readonly webhook?: boolean;
}

//Для Paykassa отдельный перевод, остальные - wallets.secretKey
export interface WithPrivateApiKey {
	readonly api_private_key: string;
}

//Особенные переводы для Nagad и Paykassa, остальные - wallets.apiKey
export interface WithPublicApiKey {
	readonly api_public_key: string;
}

//Nagad API - длина 15
export interface WithMerchantId {
	readonly merchant_id: string;
}

//Paygiga особый перевод
export interface WithMerchantKey {
	readonly merchant_key: string;
}

export interface WithToken {
	readonly token: string;
}

//1 специфичный перевод и одно специфичное услове отображения
export interface WithAccountName {
	readonly account_name: string;
}

export interface WithUpi {
	readonly upi_addresses: readonly string[];
}

export interface WithIFSCCode {
	readonly ifsc_code: string;
}

export const AkBankWalletScheme: Scheme = {
	email: true,
};

export const BCABankWalletScheme: Scheme = {
	email: true,
};

export const BKashAPIWalletScheme: Scheme = {
	defaultLogin: true,
	privateApiKey: true,
	publicApiKey: true,
};

export const BkashWalletScheme: Scheme = {
	parser: true,
	accountType: true,
};

export const CashmaalWalletScheme: Scheme = {
	privateApiKey: true,
};

export const Help2PayWalletScheme: Scheme = {
	privateApiKey: true,
};

export const ImpsAbWalletScheme: Scheme = {
	parser: true,
	accountName: true,
	ifscCode: true,
	codephrases: true,
	accountNumber: true,
};

export const ImpsHbWalletScheme: Scheme = {
	defaultLogin: true,
	accountName: true,
	ifscCode: true,
	parsingObject: true,
};

export const ImpsIbWalletScheme: Scheme = {
	accountName: true,
	defaultLogin: true,
	ifscCode: true,
};

export const ImpsIdbWalletWalletScheme: Scheme = {
	defaultLogin: true,
	accountName: true,
	ifscCode: true,
};

export const LaykaPayWalletScheme: Scheme = {
	privateApiKey: true,
	publicApiKey: true,
};

export const LocalCryptoWalletScheme: Scheme = {};

export const MayBankWalletScheme: Scheme = {
	defaultLogin: true,
	accountName: true,
};

export const MpesaWalletScheme: Scheme = {
	merchantId: true,
	publicApiKey: true,
	privateApiKey: true,
	password: true,
};

export const NagadApiWalletScheme: Scheme = {
	merchantId: true,
	accountNumber: true,
	publicApiKey: true,
};

export const NagadWalletScheme: Scheme = {
	parser: true,
	accountType: true,
};

export const NganluongEmailWalletScheme: Scheme = {
	email: true,
};

export const PagSmileWalletScheme: Scheme = {
	privateApiKey: true,
	merchantId: true,
};

export const PayKassaWalletScheme: Scheme = {
	privateApiKey: true,
	publicApiKey: true,
};

export const PayTecaWalletScheme: Scheme = {
	merchantId: true,
};

export const PayTrust88WalletScheme: Scheme = {
	publicApiKey: true,
};

export const PaygigaWalletScheme: Scheme = {
	merchantId: true,
	password: true,
};

export const PayopWalletScheme: Scheme = {
	privateApiKey: true,
	publicApiKey: true,
	token: true,
};

export const PaytmApiWalletScheme: Scheme = {
	merchantId: true,
	publicApiKey: true,
};

export const PaytmBizWalletScheme: Scheme = {
	email: true,
};

export const PaytmWalletScheme: Scheme = {
	cookie: true,
	parser: true,
	password: true,
};

export const PerfectMoneyWalletScheme: Scheme = {
	defaultLogin: true,
};

export const PhonePeWalletScheme: Scheme = {
	email: true,
	accountNumber: true,
	parser: true,
	codephrases: true,
	parsingObject: true,
};

export const RHBBankWalletScheme: Scheme = {
	defaultLogin: true,
	accountName: true,
};

export const RocketWalletScheme: Scheme = {
	// email: true,
};

export const SafecommerzWalletScheme: Scheme = {
	publicApiKey: true,
	accountName: true,
};

export const SkrillWalletScheme: Scheme = {
	email: true,
};

export const SurepayWalletScheme: Scheme = {
	publicApiKey: true,
	merchantId: true,
};

export const TechcombankWalletScheme: Scheme = {
	defaultLogin: true,
};

export const UPIHbWalletScheme: Scheme = {
	defaultLogin: true,
	// upi: true,
	accountNumber: true,
	parsingObject: true,
};

export const UPIAbWalletScheme: Scheme = {
	email: true,
	// upi: true,
	accountNumber: true,
};

export const UPIIdbWalletScheme: Scheme = {
	defaultLogin: true,
	// upi: true,
};

export const UPILWalletScheme: Scheme = {
	// email: true,
	// upi: true,
	username: true,
	integrationType: true,
	webhook: true,
};

export const UPINaapWalletScheme: Scheme = {
	publicApiKey: true,
	privateApiKey: true,
	webhook: true,
	projectId: true,
};

export const UPIPbWalletScheme: Scheme = {
	merchantId: true,
	publicApiKey: true,
};

export const UPISWalletScheme: Scheme = {
	publicApiKey: true,
	// upiAddresses: true,
	accountName: true,
	merchantId: true,
	privateApiKey: true,
	projectId: true,
	// upi: true,
	integrationType: true,
	webhook: true,
};

export const UPISFWalletScheme: Scheme = {
	publicApiKey: true,
	upiAddresses: true,
	accountName: true,
	merchantId: true,
	upi: true,
};

export const UPIApWalletScheme: Scheme = {
	publicApiKey: true,
	upiAddresses: true,
	accountName: true,
	merchantId: true,
	upi: true,
};

export const VietcombankWalletScheme: Scheme = {
	email: true,
	accountName: true,
};

export const UzcardApiWalletScheme: Scheme = {
	merchantId: true,
	token: true,
};

export const UPIIbWalletScheme: Scheme = {
	// upi: true,
	defaultLogin: true,
	accountName: true,
};

export const NganLuongWalletScheme: Scheme = {
	email: true,
};

export const EasyPaisaWalletScheme: Scheme = {
	email: true,
};

export const UPIINWalletScheme: Scheme = {
	accountNumber: true,
	defaultLogin: true,
	codephrases: true,
	// upi: true,
};

export const UPIWWalletScheme: Scheme = {
	email: true,
	// upi: true,
	username: true,
};

export const BkashApiCelebraiWalletScheme: Scheme = {
	defaultLogin: true,
	publicApiKey: true,
	privateApiKey: true,
};

export const ClickUZSWalletScheme: Scheme = {
	// email: true,
	username: true,
};

export const WavepayWalletScheme: Scheme = {
	// email: true,
};

export const UPIMWalletScheme: Scheme = {
	// email: true,
	// upi: true,
	username: true,
};

export const PhonePeSWalletScheme: Scheme = {
	merchantId: true,
	publicApiKey: true,
	upi: true,
	accountName: true,
};

export const JazzCashWalletScheme: Scheme = {
	// email: true,
};

export const IPayWalletScheme: Scheme = {
	accountNumber: true,
	defaultLogin: true,
};

export const IPayApiWalletScheme: Scheme = {
	token: true,
	privateApiKey: true,
	callback_source: true,
};

export const JazzCashBusinessScheme: Scheme = {
	email: true,
};

export const EasypaisaApiScheme: Scheme = {
	defaultLogin: true,
	publicApiKey: true,
	privateApiKey: true,
	accountNumber: true,
	merchantId: true,
};

export const walletTypeSchemeMapping: Record<WalletTypes, Scheme | undefined> = {
	//Undefined === deleted
	[WalletTypes.TEST]: undefined,
	[WalletTypes.PAYZEN]: undefined,
	[WalletTypes.EPAY]: undefined,
	[WalletTypes.CERTUS_FINANCE]: undefined,
	[WalletTypes.DUSUPAY]: undefined,
	[WalletTypes.PAYME_UZ]: undefined,
	[WalletTypes.YAAR_PAY]: undefined,
	[WalletTypes.NGAN_LUONG]: NganLuongWalletScheme,
	[WalletTypes.UPI_L]: UPILWalletScheme,
	[WalletTypes.PAY_TM]: PaytmWalletScheme,
	[WalletTypes.PAY_TM_BIZ]: PaytmBizWalletScheme,
	[WalletTypes.SKRILL]: SkrillWalletScheme,
	[WalletTypes.NETELLER]: SkrillWalletScheme,
	[WalletTypes.PERFECT_MONEY]: PerfectMoneyWalletScheme,
	[WalletTypes.HELP_2_PAY]: Help2PayWalletScheme,
	[WalletTypes.IMPS_IB]: ImpsIbWalletScheme,
	[WalletTypes.UPI_IB]: UPIIbWalletScheme,
	[WalletTypes.BKASH]: BkashWalletScheme,
	[WalletTypes.NGAN_LUONG_EMAIL]: NganluongEmailWalletScheme,
	[WalletTypes.PAY_OP]: PayopWalletScheme,
	[WalletTypes.PAYKASSA]: PayKassaWalletScheme,
	[WalletTypes.NAGAD]: NagadWalletScheme,
	[WalletTypes.NAGAD_API]: NagadApiWalletScheme,
	[WalletTypes.ROCKET]: RocketWalletScheme,
	[WalletTypes.PHONE_PE]: PhonePeWalletScheme,
	[WalletTypes.VIETCOM_BANK]: VietcombankWalletScheme,
	[WalletTypes.CASHMAAL]: CashmaalWalletScheme,
	[WalletTypes.PAYTRUST88]: PayTrust88WalletScheme,
	[WalletTypes.SUREPAY]: SurepayWalletScheme,
	[WalletTypes.PAYTECA]: PayTecaWalletScheme,
	[WalletTypes.TECHCOMBANK]: TechcombankWalletScheme,
	[WalletTypes.PAYGIGA]: PaygigaWalletScheme,
	[WalletTypes.PAG_SMILE]: PagSmileWalletScheme,
	[WalletTypes.AKBANK]: AkBankWalletScheme,
	[WalletTypes.BCA_BANK]: BCABankWalletScheme,
	[WalletTypes.RHB_BANK]: RHBBankWalletScheme,
	[WalletTypes.MAYBANK]: MayBankWalletScheme,
	[WalletTypes.UPI_AB]: UPIAbWalletScheme,
	[WalletTypes.UPI_HB]: UPIHbWalletScheme,
	[WalletTypes.IMPS_HB]: ImpsHbWalletScheme,
	[WalletTypes.LOCAL_CRYPTO]: LocalCryptoWalletScheme,
	[WalletTypes.SAFECOMMERZ]: SafecommerzWalletScheme,
	[WalletTypes.UPI_NAAP]: UPINaapWalletScheme,
	[WalletTypes.IMPS_AB]: ImpsAbWalletScheme,
	[WalletTypes.IMPS_IDB]: ImpsIdbWalletWalletScheme,
	[WalletTypes.UPI_IDB]: UPIIdbWalletScheme,
	[WalletTypes.LAYKAPAY]: LaykaPayWalletScheme,
	[WalletTypes.UPI_AP]: UPIApWalletScheme,
	[WalletTypes.UZCARD_API]: UzcardApiWalletScheme,
	[WalletTypes.PAYTM_API]: PaytmApiWalletScheme,
	[WalletTypes.UPI_PB]: UPIPbWalletScheme,
	[WalletTypes.UPI_S]: UPISWalletScheme,
	[WalletTypes.BKASH_API]: BKashAPIWalletScheme,
	[WalletTypes.MPESA]: MpesaWalletScheme,
	[WalletTypes.EASYPAISA]: EasyPaisaWalletScheme,
	[WalletTypes.UPI_SF]: UPISFWalletScheme,
	[WalletTypes.UPI_IN]: UPIINWalletScheme,
	[WalletTypes.UPI_W]: UPIWWalletScheme,
	[WalletTypes.BKASH_API_CEBALRAI]: BkashApiCelebraiWalletScheme,
	[WalletTypes.CLICK_UZS]: ClickUZSWalletScheme,
	[WalletTypes.WAVEPAY]: WavepayWalletScheme,
	[WalletTypes.UPI_M]: UPIMWalletScheme,
	[WalletTypes.PHONE_PE_S]: PhonePeSWalletScheme,
	[WalletTypes.JAZZCASH]: JazzCashWalletScheme,
	[WalletTypes.IPAY]: IPayWalletScheme,
	[WalletTypes.IPAY_API]: IPayApiWalletScheme,
	[WalletTypes.JAZZCASH_BUSINESS]: JazzCashBusinessScheme,
	[WalletTypes.EASYPAISA_API]: EasypaisaApiScheme,
};
