import { useTranslation } from "@paykassma/pay-kit";
import api from "api/tariffsGroup";
import { useState } from "react";

type useRemoveCommissionHookType = () => {
	readonly remove: (
		id: number,
		args: {
			readonly onSuccess?: (res: any) => void;
			readonly onError?: (error: Error) => void;
		}
	) => void;
	readonly isLoading: boolean;
};

const useRemoveCommissionHook: useRemoveCommissionHookType = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const {t} = useTranslation();

	const remove: ReturnType<useRemoveCommissionHookType>["remove"] = (id: number, { onSuccess, onError }) => {
		setIsLoading(true);

		api
			.removeCommission(id)
			.then((result) => {
				onSuccess && onSuccess(result);
				window.pushAlert({ type: "success", description: t("Comission successfully deleted"), timeout: 2000 });
				return result;
			})
			.finally(() => setIsLoading(false))
			.catch((error) => {
				console.error(error);
				window.pushAlert({ type: "error", description: t("Deleting comission error"), timeout: 2000 });
				onError && onError(error);
			});
	};

	return {
		remove,
		isLoading,
	};
};

export default useRemoveCommissionHook;
