// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PostbacksPage__panel-s0Q{padding:48px 0}.PostbacksPage__panel-s0Q .PostbacksPage__panel__title-gcK{font-size:24px;font-weight:500;margin-bottom:48px}.PostbacksPage__panel-s0Q .PostbacksPage__tabs__wrapper-uuA{width:100%;position:relative}`, "",{"version":3,"sources":["webpack://./src/layout/pages/PostbacksPage/styles.module.scss"],"names":[],"mappings":"AAAA,0BACI,cAAA,CAEA,2DACI,cAAA,CACA,eAAA,CACA,kBAAA,CAGJ,4DACI,UAAA,CACA,iBAAA","sourcesContent":[".panel {\n    padding: 48px 0;\n\n    .panel__title {\n        font-size: 24px;\n        font-weight: 500;\n        margin-bottom: 48px;\n    }\n\n    .tabs__wrapper {\n        width: 100%;\n        position: relative;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": `PostbacksPage__panel-s0Q`,
	"panel__title": `PostbacksPage__panel__title-gcK`,
	"tabs__wrapper": `PostbacksPage__tabs__wrapper-uuA`
};
export default ___CSS_LOADER_EXPORT___;
