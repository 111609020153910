import { Button, Modal, Table, Tooltip } from "@paykassma/pay-kit";
import { TableColumnType } from "@paykassma/pay-kit/lib/elements/Table/types";
import ColumnTitleSeparator from "components/ColumnTitleSeparator";
import { QuestionMark } from "components/Icons/QuestionMark";
import { RefreshIcon } from "components/Icons/RefreshIcon";
import Money from "components/Money";
import PaymentSystemLogo from "components/PaymentSystemLogo";
import { Roles, useHasAccessHook } from "contexts/AuthContext";
import { StatisticWalletsCtxProvider, useStatisticWalletsCtx } from "modules/Wallets/contexts/StatisticWallets";
import { useTranslation } from "pay-kit";
import { useMemo } from "react";
import { useIntersectionObserver } from "utils/hooks/useIntersectionObserver";

import StatisticModal from "../components/modals/StatisticModal";
import { getMaxValue } from "../components/modals/StatisticModal/StatisticModal";
import { InfoIcon } from "./icons/InfoIcon";
import styles from "./StatisticWallets.module.scss";

const StatisticWalletsModule = () => {
	const { t } = useTranslation();
	const {
		handleModal,
		isLoading,
		isModalOpened,
		walletsStatistic,
		checkWalletType,
		checkedWalletType,
		fetchWalletsStatistic,
	} = useStatisticWalletsCtx();

	const WalletStatisticsColumns = {
		WALLET_TYPE: `walletType`,
		/** Видимые/Рабочие/Все*/
		VISIBLE_WORKING_ALL: `visibleWorkingAll`,
		/** Текущий/макс.  баланс видимых */
		BALANCE_OF_VISIBLE_MAX: `balanceOfVisibleMax`,
		/** Текущий/макс. оборот видимых */
		TURNOVER_OF_VISIBLE_MAX: `turnoverOfVisibleMax`,
		/** Текущий/макс. баланс всех */
		BALANCE_OF_ALL_MAX: `balanceOfAllMax`,
		/** Текущий/макс. оборот всех */
		TURNOVER_OF_ALL_MAX: `turnoverOfAllMax`,
		/** Макс. дневной оборот */
		TURNOVER_DAILY_TURNOVER: `turnoverDailyTurnover`,
	};

	const cols: ReadonlyArray<TableColumnType> = [
		{
			dataIndex: WalletStatisticsColumns.WALLET_TYPE,
			key: WalletStatisticsColumns.WALLET_TYPE,
			title: <div>{t("Wallet type")}</div>,
			render: ({ walletType }) => <PaymentSystemLogo id={walletType} dataTestId="wallet_type_name" />,
		},
		{
			dataIndex: WalletStatisticsColumns.VISIBLE_WORKING_ALL,
			key: WalletStatisticsColumns.VISIBLE_WORKING_ALL,
			title: (
				<div className={styles.visibleWorkingAll}>
					{t("Visible")} <ColumnTitleSeparator /> {t("Working")} <ColumnTitleSeparator /> {t("All")}
				</div>
			),
			render: ({ [WalletStatisticsColumns.VISIBLE_WORKING_ALL]: { visible_counter, worked_counter, all_counter } }) => (
				<div data-test-id="visible_counter">
					{visible_counter} <ColumnTitleSeparator /> {worked_counter} <ColumnTitleSeparator /> {all_counter}
				</div>
			),
		},
		{
			dataIndex: WalletStatisticsColumns.BALANCE_OF_VISIBLE_MAX,
			key: WalletStatisticsColumns.BALANCE_OF_VISIBLE_MAX,
			title: (
				<div className={styles.balanceOfVisibleMax}>
					{t("Balance of visible")} <ColumnTitleSeparator /> {t("Max.")} <br />
				</div>
			),
		},
		{
			dataIndex: WalletStatisticsColumns.TURNOVER_OF_VISIBLE_MAX,
			key: WalletStatisticsColumns.TURNOVER_OF_VISIBLE_MAX,
			title: (
				<div>
					{t("Turnover of visible")} <ColumnTitleSeparator /> {t("Max.")}
				</div>
			),
		},
		{
			dataIndex: WalletStatisticsColumns.BALANCE_OF_ALL_MAX,
			key: WalletStatisticsColumns.BALANCE_OF_ALL_MAX,
			title: (
				<div>
					{t("Balance of all")} <ColumnTitleSeparator /> {t("Max.")}
				</div>
			),
		},
		{
			dataIndex: WalletStatisticsColumns.TURNOVER_OF_ALL_MAX,
			key: WalletStatisticsColumns.TURNOVER_OF_ALL_MAX,
			title: (
				<div>
					{t("Turnover of all")} <ColumnTitleSeparator /> {t("Max.")}
				</div>
			),
		},
		{
			dataIndex: WalletStatisticsColumns.TURNOVER_DAILY_TURNOVER,
			key: WalletStatisticsColumns.TURNOVER_DAILY_TURNOVER,
			title: (
				<Tooltip preferredSide="bottom" tip={t("Maximum daily turnover of 7 days for all wallets")}>
					<div className={styles.turnoverDailyTurnover}>
						{t("Max. daily turnover")}
						<QuestionMark />
					</div>
				</Tooltip>
			),
			render: (data) => {
				const items = data[WalletStatisticsColumns.TURNOVER_DAILY_TURNOVER];
				return items.map((item, i) => (
					<div className={styles.arrayItem} key={i}>
						<Button
							data-test-id="HbF_TL_jGi5Cjspt60cRq"
							variant="text-secondary"
							onClick={() => {
								const selectedWallet = walletsStatistic.find((el) => el.wallet_type === item.wallet_type);
								handleModal("open")();
								checkWalletType(selectedWallet?.wallet_type);
							}}
							type="button"
							dataTestId="all_daily_turnover"
						>
							{/* {getMaxValue(item.all_daily_turnover)} {item.currency} */}
							<Money amount={getMaxValue(item.all_daily_turnover)} currencyCode={item.currency} />
							<InfoIcon width={24} height={24} />
						</Button>
					</div>
				));
			},
		},
	];

	const dataDesc = {
		[WalletStatisticsColumns.BALANCE_OF_VISIBLE_MAX]: ["visible_balance", "visible_wallet_off_limit"],
		[WalletStatisticsColumns.TURNOVER_OF_VISIBLE_MAX]: ["visible_daily_turnover", "visible_wallet_off_load"],
		[WalletStatisticsColumns.BALANCE_OF_ALL_MAX]: ["not_removed_balance", "not_removed_wallet_off_limit"],
		[WalletStatisticsColumns.TURNOVER_OF_ALL_MAX]: ["not_removed_daily_turnover", "not_removed_wallet_off_load"],
		[WalletStatisticsColumns.TURNOVER_DAILY_TURNOVER]: ["all_daily_turnover", "wallet_type"],
	};

	const groupData = useMemo(() => {
		let result: readonly any[] = [];

		for (const statisticItem of walletsStatistic) {
			let found = result.find((s) => s.wallet_type === statisticItem.wallet_type);
			if (!found) {
				const newItem = {
					[WalletStatisticsColumns.WALLET_TYPE]: statisticItem.wallet_type,
					[WalletStatisticsColumns.VISIBLE_WORKING_ALL]: {
						visible_counter: 0,
						worked_counter: 0,
						all_counter: 0,
					},
					[WalletStatisticsColumns.BALANCE_OF_VISIBLE_MAX]: [],
					[WalletStatisticsColumns.TURNOVER_OF_VISIBLE_MAX]: [],
					[WalletStatisticsColumns.BALANCE_OF_ALL_MAX]: [],
					[WalletStatisticsColumns.TURNOVER_OF_ALL_MAX]: [],
					[WalletStatisticsColumns.TURNOVER_DAILY_TURNOVER]: [],
				};
				found = newItem;
				result.push(found);
			}

			found[WalletStatisticsColumns.VISIBLE_WORKING_ALL] = {
				visible_counter:
					found[WalletStatisticsColumns.VISIBLE_WORKING_ALL].visible_counter + statisticItem.visible_counter,
				worked_counter:
					found[WalletStatisticsColumns.VISIBLE_WORKING_ALL].worked_counter + statisticItem.worked_counter,
				all_counter: found[WalletStatisticsColumns.VISIBLE_WORKING_ALL].all_counter + statisticItem.all_counter,
			};

			for (const key in dataDesc) {
				const objKeys = dataDesc[key];
				const obj = objKeys.reduce(
					(prev, curr) => ({
						...prev,
						[curr]: statisticItem[curr],
					}),
					{}
				);
				obj.currency = statisticItem.currency;
				found[key].push(obj);
			}
		}

		const render = (data, column) => {
			const items = data[column];
			const keys = dataDesc[column];
			return (
				<div>
					{items.map(({ currency, ...item }) => (
						<div className={styles.arrayItem} key={currency}>
							<Money amount={item[keys[0]]} currencyCode={currency} dataTestId={keys[0]} />
							<div className={styles.secondLabel}>
								<Money amount={item[keys[1]]} currencyCode={currency} dataTestId={keys[1]} />
							</div>
						</div>
					))}
				</div>
			);
		};

		const columnsToRender = Object.keys(dataDesc).filter(
			(col) => col !== WalletStatisticsColumns.TURNOVER_DAILY_TURNOVER
		);
		result = result.map((value) => {
			for (const column of columnsToRender) {
				value[column] = render(value, column);
			}

			return value;
		});

		return result;
	}, [walletsStatistic.length]);

	const intersectionObserverAPI = useIntersectionObserver({
		list: groupData,
		offset: 10,
	});
	useHasAccessHook({ rule: Roles.WALLET_LIST, redirect: true });

	return (
		<div>
			<Button data-test-id="dWuq5cY_1HvD4SHE3PAGq" variant="text-primary" onClick={fetchWalletsStatistic}>
				<RefreshIcon />
				<span
					style={{
						marginLeft: "8px",
					}}
				>
					{t("Refresh")}
				</span>
			</Button>
			<Modal title={t("Turnover for 7 days")} isOpen={isModalOpened} onClose={handleModal("close")}>
				<StatisticModal data={walletsStatistic.find(({ wallet_type }) => wallet_type === checkedWalletType)} />
			</Modal>
			<Table columns={cols} data={intersectionObserverAPI.list} rowKey="payment_system" isLoading={isLoading} />
			<div ref={intersectionObserverAPI.targetRef} className={styles.target} />
		</div>
	);
};

export const StatisticWallets = (props) => (
	<StatisticWalletsCtxProvider>
		<StatisticWalletsModule {...props} />
	</StatisticWalletsCtxProvider>
);
