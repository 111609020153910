import { useTranslation } from "pay-kit";
import { useRef, useState } from "react";

import styles from "./DropZone.module.scss";
import FormattedError from "./FormattedError";
import CloudIcon from "./icons/cloudIcon.svg";
import TrashbinIcon from "./icons/trashbin.svg";

interface IDragDropFileProps {
	readonly onChange: (files: FileList | null) => void;
	readonly setErrors: (errors: any) => void;
	readonly errors: any;
	readonly files: FileList | null;
	readonly fileExtensionWhiteList?: readonly string[];
}

const DragDropFile: React.FC<IDragDropFileProps> = ({
	onChange,
	files,
	fileExtensionWhiteList = [".xlsx"],
	errors,
	setErrors,
}) => {
	const {t} = useTranslation();
	const [dragActive, setDragActive] = useState(false);
	const inputRef = useRef<HTMLInputElement>(null);

	const onAddFile = (files: FileList) => {
		onChange(null);

		if (fileExtensionWhiteList) {
			for (let i = 0; i < files.length; i++) {
				const isWhiteListedByExtension = fileExtensionWhiteList.filter((ext) => files[i].name.endsWith(ext)).length > 0;
				if (!isWhiteListedByExtension) {
					// return setErrors([`${files[i].name} – неподдерживаемый тип файла`]);
					return setErrors([`${files[i].name} – ${t("file type is not supported")}`]);
				}
			}
		}

		onChange(files);
	};

	const handleDrag = function (e: any) {
		e.preventDefault();
		e.stopPropagation();
		if (e.type === "dragenter" || e.type === "dragover") {
			setDragActive(true);
		} else if (e.type === "dragleave") {
			setDragActive(false);
		}
	};

	const handleDrop = function (e: any) {
		e.preventDefault();
		e.stopPropagation();
		setDragActive(false);
		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
			onAddFile(e.dataTransfer.files);
		}
	};

	const handleChange = function (e: any) {
		e.preventDefault();
		if (e.target.files && e.target.files[0]) {
			onAddFile(e.target.files);
		}
	};

	const onButtonClick = () => {
		if (inputRef.current && inputRef.current.click !== undefined) {
			inputRef.current.click();
		}
	};

	return (
		<form className={styles.formFileUpload} onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
			<label
				className={[styles.labelFileUpload, dragActive ? styles.dragActive : null].filter((c) => c !== null).join(" ")}
			>
				<input
					ref={inputRef}
					type="file"
					className={styles.inputFileUpload}
					multiple={false}
					onChange={handleChange}
					data-test-id=""
				/>

				<div>
					<CloudIcon />
					<p>
						{t("Drag file here")}
						{fileExtensionWhiteList ? fileExtensionWhiteList.map((ext) => <b key={ext}>{ext}</b>) : null}
					</p>
					<div>{t("or")}</div>
					<button className={styles.uploadButton} onClick={onButtonClick} data-test-id="">
						{t("Upload file")}
					</button>
				</div>
			</label>

			{dragActive && (
				<div
					className={styles.dragFileElement}
					onDragEnter={handleDrag}
					onDragLeave={handleDrag}
					onDragOver={handleDrag}
					onDrop={handleDrop}
				/>
			)}

			<div className={styles.filesList}>
				{files !== null &&
					fileListToNamesArray(files).map((file) => {
						return (
							<div className={styles.file} key={file.name}>
								<div className={styles.name}>{file.name}</div>
								<button
									data-test-id=""
									onClick={() => {
										onChange(null);
										setErrors(null);
									}}
								>
									<TrashbinIcon />
								</button>
							</div>
						);
					})}
			</div>

			<div className={styles.errors}>
				{errors !== null && <div>{t("Error occurred")}</div>}
				{errors?.wallet_not_found && <FormattedError title={t("Wallet not found")} error={errors?.wallet_not_found} />}
				{errors?.incorrect_status && <FormattedError title={t("Incorrect status")} error={errors?.incorrect_status} />}
				{Array.isArray(errors) && errors.join('\n')}
			</div>
		</form>
	);
};

export default DragDropFile;

const fileListToNamesArray = (files: FileList) => {
	const list = [];

	for (let i = 0; i < files.length; i++) {
		list.push(files[i]);
	}

	return list;
};
