import { Button, Tooltip, useTranslation } from "@paykassma/pay-kit";
import { WalletTypeType } from "api/walletGroup";
import { PenIcon } from "components/Icons/PenIcon";
import AuthContext from "contexts/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";
import { ArchivedWalletContext } from "modules/Wallets/contexts/ArchivedWalletContext";
import { WorkingWalletContext } from "modules/Wallets/contexts/WorkingWalletContext";
import { useContext, useState } from "react";

import styles from "./Comments.module.scss";

const Comment = ({ comment, hash_id, ctx = WorkingWalletContext, dataTestId }: CommentProps) => {
	const { openCommentModal } = useContext(ctx);
	const { hasRole } = useContext(AuthContext);
	const [hovered, setHovered] = useState<boolean>(false);
	const { t } = useTranslation();

	const commentAccess = hasRole(Roles.WALLET_COMMENT);

	const toggleHover = () => {
		setHovered((state) => !state);
	};

	const handleOpen = () => {
		if (commentAccess) {
			openCommentModal(hash_id, comment);
		}
	};

	return (
		<div
			data-test-id="1d3fKoDLcOTeHczWj196b"
			onMouseEnter={toggleHover}
			onMouseLeave={toggleHover}
			onClick={handleOpen}
			className={styles.commentCell}
		>
			{comment ? (
				<Tooltip tip={comment} preferredSide="bottom">
					<div className={styles.commentWrapper} data-test-id={dataTestId}>
						<div className={styles.comment} dangerouslySetInnerHTML={{ __html: comment }} />
						{hovered && commentAccess && (
							<Button variant="secondary">
								<PenIcon activeClass={styles.penIcon} />
							</Button>
						)}
					</div>
				</Tooltip>
			) : commentAccess ? (
				<Button variant="link-primary" dataTestId={dataTestId}>
					{t("Add comment")}
				</Button>
			) : (
				<></>
			)}
		</div>
	);
};

export default Comment;

type CommentProps = {
	readonly hash_id: WalletTypeType[`hash_id`];
	readonly comment: string | null;
	readonly ctx?: typeof WorkingWalletContext | typeof ArchivedWalletContext;
	readonly dataTestId?: string;
};
