// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Togglers__button-uwA{border-color:rgba(0,0,0,0);outline:rgba(0,0,0,0);border-radius:4px;display:flex;padding:2px;margin:0;cursor:pointer;background-color:#f2f3f5;color:#697077;transition:.1s linear all}.Togglers__active-R7V{color:#24a148;background-color:#dbf3e1}.Togglers__not_active-fZX:hover{background-color:#eaecf0}.Togglers__activeButton-_uA{background-color:#e8faed;color:#24a148}.Togglers__activeButton-_uA:hover{background-color:#a7f0ba}`, "",{"version":3,"sources":["webpack://./src/modules/Wallets/WorkingWallets/components/Togglers/Toggler.module.scss"],"names":[],"mappings":"AAAA,sBACC,0BAAA,CACA,qBAAA,CACA,iBAAA,CACA,YAAA,CACA,WAAA,CACA,QAAA,CACA,cAAA,CACA,wBAAA,CACA,aAAA,CACA,yBAAA,CAGD,sBACC,aAAA,CACA,wBAAA,CAIA,gCACC,wBAAA,CAIF,4BACC,wBAAA,CACA,aAAA,CAEA,kCACC,wBAAA","sourcesContent":[".button {\n\tborder-color: transparent;\n\toutline: transparent;\n\tborder-radius: 4px;\n\tdisplay: flex;\n\tpadding: 2px;\n\tmargin: 0;\n\tcursor: pointer;\n\tbackground-color: #F2F3F5;\n\tcolor: #697077;\n\ttransition: .1s linear all;\n}\n\n.active {\n\tcolor: #24A148;\n\tbackground-color: #dbf3e1;\n}\n\n.not_active {\n\t&:hover {\n\t\tbackground-color: #EAECF0;\n\t}\n}\n\n.activeButton {\n\tbackground-color: #E8FAED;\n\tcolor: #24A148;\n\n\t&:hover {\n\t\tbackground-color: #A7F0BA;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Togglers__button-uwA`,
	"active": `Togglers__active-R7V`,
	"not_active": `Togglers__not_active-fZX`,
	"activeButton": `Togglers__activeButton-_uA`
};
export default ___CSS_LOADER_EXPORT___;
