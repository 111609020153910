import { t } from "pay-kit";

import { LimitType } from "./hooks/useWalletTypesLimits";
import { FormStateType } from "./index";

export const requiredFieldValidation = (value: string) => (!value ? t("Required field") : undefined);

export const isValidAmount = (value: string | number) => {
	const strValue = value.toString();
	const parsedInt = parseInt(strValue);
	const parsedFloat = parseFloat(strValue);
	const isValid = !(isNaN(parsedInt) || isNaN(parsedFloat));

	if (!isValid || parsedFloat <= 0 || parsedFloat.toString() !== strValue) {
		// return "Введите корректную сумму";
		return t("Wrong format of the amount");
	}

	return undefined;
};

export const isAmountInRange = (limits: readonly LimitType[]) => (amount: string, formState: FormStateType) => {
	const { wallet_type, currency_code, selectedPossibleTrId } = formState;

	// ignore if has possible transaction
	if (selectedPossibleTrId !== undefined) {
		return undefined;
	}

	const foundLimit = limits.find((l) => l.currency === currency_code && l.wallet_type === wallet_type);

	if (!foundLimit) {
		return undefined;
	}

	const numAmount = parseFloat(amount);

	if (foundLimit.min !== undefined && numAmount < foundLimit.min) {
		// return `Сумма должна быть не меньше ${foundLimit.min}`;
		return `${t("The amount must not be less than")} ${foundLimit.min}`;
	}

	if (foundLimit.max !== undefined && numAmount > foundLimit.max) {
		// return `Сумма должна быть не больше ${foundLimit.max}`;
		return `${t("The amount must not be greater than")} ${foundLimit.max}`;
	}
};

export const digitsOnly = (value: string) => {
	const isNum = /^\d+$/.test(value);
	// return isNum ? undefined : "Только цифры";
	return isNum ? undefined : t("Only digits");
};
