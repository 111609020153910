// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Skeleton__row-W4r{height:24px;width:calc(100% - 48px);margin:24px 24px 0 24px;background:#f2f3f5;border-radius:12px}.Skeleton__row-W4r:last-child{margin-bottom:24px}`, "",{"version":3,"sources":["webpack://./src/modules/PaymentSystems/Translations/components/Skeleton/Skeleton.module.scss"],"names":[],"mappings":"AAAA,mBACI,WAAA,CACA,uBAAA,CACA,uBAAA,CAEA,kBAAA,CACA,kBAAA,CAEA,8BACI,kBAAA","sourcesContent":[".row {\n    height: 24px;\n    width: calc(100% - 48px);\n    margin: 24px 24px 0 24px;\n    /* Blue_gray/10 */\n    background: #F2F3F5;\n    border-radius: 12px;\n\n    &:last-child {\n        margin-bottom: 24px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `Skeleton__row-W4r`
};
export default ___CSS_LOADER_EXPORT___;
