// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Switcher__wrapper-asp .Switcher__label-Uwc{font-weight:400;font-size:14px;line-height:20px;color:#697077}.Switcher__wrapper-asp .Switcher__switcher-C_V{padding:2px;height:32px;background:#eaecf0;border-radius:8px;display:inline-block}.Switcher__wrapper-asp .Switcher__switcher-C_V>button{display:inline-block;border:0;height:28px;background:rgba(0,0,0,0);border-radius:6px;font-weight:400;font-size:13px;line-height:20px;text-align:center;cursor:pointer;color:#21272a}.Switcher__wrapper-asp .Switcher__switcher-C_V>button.Switcher__active-IXA{background:#0072c3;color:#fff}`, "",{"version":3,"sources":["webpack://./src/components/Switcher/Switcher.module.scss"],"names":[],"mappings":"AACC,4CACC,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,aAAA,CAGD,+CACC,WAAA,CACA,WAAA,CAEA,kBAAA,CACA,iBAAA,CACA,oBAAA,CAEA,sDACC,oBAAA,CACA,QAAA,CAEA,WAAA,CACA,wBAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,iBAAA,CACA,cAAA,CAEA,aAAA,CAEA,2EAEC,kBAAA,CAEA,UAAA","sourcesContent":[".wrapper {\n\t.label {\n\t\tfont-weight: 400;\n\t\tfont-size: 14px;\n\t\tline-height: 20px;\n\t\t/* Blue_gray/70 */\n\t\tcolor: #697077;\n\t}\n\n\t.switcher {\n\t\tpadding: 2px;\n\t\theight: 32px;\n\t\t/* Blue_gray/20 */\n\t\tbackground: #EAECF0;\n\t\tborder-radius: 8px;\n\t\tdisplay: inline-block;\n\n\t\t> button {\n\t\t\tdisplay: inline-block;\n\t\t\tborder: 0;\n\t\t\t// padding: 0 36px;\n\t\t\theight: 28px;\n\t\t\tbackground: transparent;\n\t\t\tborder-radius: 6px;\n\t\t\tfont-weight: 400;\n\t\t\tfont-size: 13px;\n\t\t\tline-height: 20px;\n\t\t\ttext-align: center;\n\t\t\tcursor: pointer;\n\t\t\t/* Blue_gray/90 */\n\t\t\tcolor: #21272A;\n\n\t\t\t&.active {\n\t\t\t\t/* Cyan/70 */\n\t\t\t\tbackground: #0072C3;\n\t\t\t\t/* White/100 */\n\t\t\t\tcolor: #FFFFFF;\n\t\t\t}\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Switcher__wrapper-asp`,
	"label": `Switcher__label-Uwc`,
	"switcher": `Switcher__switcher-C_V`,
	"active": `Switcher__active-IXA`
};
export default ___CSS_LOADER_EXPORT___;
