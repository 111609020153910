import { t } from "pay-kit";
import { WithdrawalsLogType } from "api/walletsDebuggingGroup";
import DateTime from "components/DateTime";
import DynamicMultilineText from "components/DynamicMultilineText";
import PaymentSystemLogo from "components/PaymentSystemLogo";

import styles from "./withdrawalsLogsListColumns.module.scss";

const WalletType = {
	title: t("Wallet type"),
	dataIndex: "wallet_type",
	key: "wallet_type",
	render: ({ wallet_type }: WithdrawalsLogType) => <PaymentSystemLogo id={wallet_type} dataTestId="wallet_type" />
};

const Message = {
	title: t("Message"),
	dataIndex: "message",
	key: "message",
	render: ({ message }: WithdrawalsLogType) =>
		<DynamicMultilineText
			text={message}
			className={styles.messageWrapper}
			// toggleButtonValue={{ hideText: "Свернуть" }}
			toggleButtonValue={{ hideText: t("Hide") }}
			dataTestId="message"
		/>
};

const Date = {
	title: t("Date"),
	dataIndex: "created_at",
	key: "created_at",
	render: ({ created_at }: WithdrawalsLogType) =>
		<DateTime dateTime={created_at} dataTestId="created_at" />
};

export const withdrawalsLogsListColumns = [
	WalletType,
	Message,
	Date
];
