import CancelIcon from "assets/icons/cancelIcon.svg";
import ConfirmIcon from "assets/icons/confirmIcon.svg";
import { Button } from "pay-kit";
import { FC } from "react";

import styles from "./confirmAction.module.scss";

const ConfirmationButtons: FC<ConfirmationButtonsType> = ({
	className,
	onConfirm,
	onCancel,
	isConfirmDisabled,
	isLoading = false
}) => {

	const confirmStyle = isLoading || isConfirmDisabled ? [styles.confirm, styles.loadingAction].join(" ") : styles.confirm;

	return (
		<div className={[styles.confirmAction, className].join(" ")}
		>
			<Button
				data-test-id="51XVBz3hcAzeMR31Vqzv1"
				classname={confirmStyle}
				onClick={() => onConfirm && onConfirm()}
				disabled={isLoading || isConfirmDisabled}
			>
				<span>
					<ConfirmIcon />
				</span>
			</Button>
			<Button
				data-test-id="M0dODD3eG_oqQUgh381nz"
				classname={styles.cancel}
				onClick={() => onCancel && onCancel()}
				disabled={isLoading}
			>
				<span>
					<CancelIcon />
				</span>
			</Button>
		</div>
	);
};

export default ConfirmationButtons;

type ConfirmationButtonsType = {
	readonly className?: string
	readonly onConfirm?: () => void
	readonly onCancel?: () => void
	readonly isLoading?: boolean
	readonly isConfirmDisabled?: boolean
}
