import { OldResponse } from "api/types";
import { WalletTypeType } from "api/walletGroup";
import { DeleteModal } from "components/Modals/DeleteModal/DeleteModal";
import { useTranslation } from "pay-kit";
import { ModalProps } from "utils/types";

import styles from "./ActivateWalletModal.module.scss";

export const ActivateWalletModal = ({ closeModal, actions, hash_id }: ActivateWalletModalProps) => {
	const { t } = useTranslation();
	return (
		<DeleteModal
			closeModal={closeModal}
			btnClassname={styles.successBtn}
			text={<>{t("Are you sure you want to activate wallet {{hash_id}}?", { hash_id })}</>}
			closeLabel={t("Cancel")}
			deleteLabel={t("Activate")}
			actions={{
				onDelete: () => {
					return actions.activateWallet(hash_id).then((resp) => {
						// eslint-disable-next-line promise/always-return
						if (resp.status === "fail") {
							window.pushAlert({
								type: "error",
								title: t("Error"),
								description: resp.message,
							});
						} else {
							window.pushAlert({
								type: "success",
								title: t("Success"),
								description: t("Wallet {{hash_id}} successfully activated", {hash_id}),
							});
						}
					});
				},
			}}
		/>
	);
};

type ActivateWalletModalProps = ModalProps & {
	readonly hash_id: WalletTypeType[`hash_id`];
	readonly actions: {
		readonly activateWallet: (id: WalletTypeType[`hash_id`]) => Promise<OldResponse<void>>;
	};
};
