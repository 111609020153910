import { useTranslation } from "pay-kit";
import { ENUMFormType, ICreateNewWalletType } from "api/walletTypesGroup";
import PlusIcon from "assets/icons/plus.svg";
import TrashIcon from "assets/icons/trash.svg";
import HelpTip from "components/HelpTip";
import RenderIf from "components/RenderIf";
import Spoiler from "components/Spoiler";
import { CurrenciesContext } from "contexts/CurrenciesContext";
import { Button, ScrollBox, Select, TextInput, Toggler } from "pay-kit";
import { useEffect } from "react";

import useLanguages from "../../../hooks/useLanguages";
import DropZone from "../../../../../../../../components/DropZone";
import TextArea from "../../components/TextArea";
import styles from "./CreateNewForm.module.scss";

interface CreateNewFormProps {
	readonly formState: ICreateNewWalletType;
	readonly visibleErrors: { readonly [key: string]: string };
	readonly validationErrors: { readonly [key: string]: string } | null;
	readonly touchedFields: readonly string[] | "*";
	readonly isLoading: boolean;
	readonly step: 1 | 2;
	readonly actions: {
		readonly setFormField: (name: string) => (value: string | number | undefined) => void;
		readonly setDepositLimit: (name: "max" | "min") => (value?: string) => void;
		readonly addRequisite: () => void;
		readonly setRequisiteCode: (n: number) => (value: string) => void;
		readonly removeRequisite: (n: number) => void;
		readonly removeHint: (index: number) => void;
		readonly addHint: (form_type: ENUMFormType) => void;
		readonly setHintFieldParams: (index: number) => (name: string) => (value?: string) => void;
		readonly goStep: (step: 1 | 2) => void;
		readonly submitHandler: () => void;
	};
}

const CreateNewForm: React.FC<CreateNewFormProps> = ({ formState, visibleErrors, isLoading, step, actions }) => {
	const {t} = useTranslation();
	const languagesHook = useLanguages();

	const requisiteHints = formState.hints
		.map((h, index) => ({ ...h, index }))
		.filter((h) => h.form_type === ENUMFormType.REQUISITE);

	const paymentHints = formState.hints
		.map((h, index) => ({ ...h, index }))
		.filter((h) => h.form_type === ENUMFormType.PAYMENT);

	const requisiteLangsOptions = languagesHook.list.map((l) => ({ label: l, value: l }));

	const paymentLangsOptions = languagesHook.list.map((l) => ({ label: l, value: l }));

	useEffect(() => languagesHook.load(), []);

	return (
		<>
			<RenderIf visible={step === 1}>
				<ScrollBox className={styles.scrollBox}>
					<FieldWrapper
						className={styles.fileInput}
						label={t("Logo")}
						value={
							<DropZone
								file={formState.logo}
								fileExtensionWhiteList={[".png", ".jpeg", ".jpg", ".svg", ".gif"]}
								onChange={actions.setFormField("logo") as any}
								data-test-id=""
								error={visibleErrors.logo}
							/>
						}
						isRequired
					/>

					<FieldWrapper
						label={
							<>
								{t("Name")}&nbsp;
								<HelpTip tip={t("Name in english. For example, UPI")}/>
							</>
						}
						value={
							<TextInput
								className={styles.textInput}
								name="name"
								value={formState.name}
								onChange={(e) => actions.setFormField("name")(e.target.value)}
								data-test-id=""
								error={visibleErrors.name}
							/>
						}
						isRequired
					/>

					<FieldWrapper
						label={
							<>
								{t("Code name")}&nbsp;
								<HelpTip tip={t("Name in english with postfix _ml; Lower letters without spaces. For example, upi_ib_ml")} />
							</>
						}
						value={
							<TextInput
								className={styles.textInput}
								name="code"
								value={formState.code}
								onChange={(e) => actions.setFormField("code")(e.target.value)}
								data-test-id=""
								error={visibleErrors.code}
							/>
						}
						isRequired
					/>

					<FieldWrapper
						label={t("Currency")}
						value={
							<CurrenciesContext.Consumer>
								{({ list, isLoading }) => (
									<Select
										options={list.map((c) => ({ label: c.code, value: c.code }))}
										className={styles.select}
										placeholder=""
										name={"currency"}
										value={formState.currency}
										onChange={actions.setFormField("currency") as any}
										data-test-id=""
										isLoading={isLoading}
										error={visibleErrors.currency}
									/>
								)}
							</CurrenciesContext.Consumer>
						}
						isRequired
					/>

					<FieldWrapper
						label={t("Min deposit")}
						value={
							<TextInput
								className={styles.textInput}
								name="min"
								value={formState.limits.deposit.min as any}
								onChange={(e) => actions.setDepositLimit("min")(e.target.value)}
								data-test-id=""
								error={visibleErrors["limits.deposit.min"]}
							/>
						}
						isRequired
					/>

					<FieldWrapper
						label={t("Max deposit")}
						value={
							<TextInput
								className={styles.textInput}
								name="max"
								value={formState.limits.deposit.max as any}
								onChange={(e) => actions.setDepositLimit("max")(e.target.value)}
								data-test-id=""
								error={visibleErrors["limits.deposit.max"]}
							/>
						}
						isRequired
					/>

					<div className={styles.requisitesHeader}>
						<h4>
							{t("Billing details")}
							<span className={styles.requiredMark}>*</span>
						</h4>
						<button onClick={actions.addRequisite} className={styles.addNew} data-test-id="">
							+ {t("Add")}
						</button>
					</div>

					<div className={styles.requisites}>
						{formState.requisites.map((r, n) => (
							<div key={n.toString()} className={styles.requisite}>
								<TextInput
									placeholder={t("Name of requisite in English")}
									className={styles.textInput}
									name={`requisite[${n}][code]`}
									value={r.code}
									onChange={(e) => actions.setRequisiteCode(n)(e.target.value)}
									data-test-id=""
									error={visibleErrors[`requisites[${n}].code`]}
								/>

								<button onClick={() => actions.removeRequisite(n)} data-test-id="">
									<TrashIcon />
								</button>
							</div>
						))}
					</div>

					<FieldWrapper
						className={styles.lastFields}
						label={t("Request transaction_id")}
						value={
							<Toggler
								value={formState.show_transaction_id === 1}
								onChange={(v: boolean) => actions.setFormField("show_transaction_id")(v ? 1 : 0)}
								data-test-id=""
								disabled
							/>
						}
						isRequired
					/>

					<FieldWrapper
						className={styles.lastFields}
						label={t("Validation for the number of characters")}
						value={
							<div className={styles.rangeFieldSet}>
								<TextInput
									className={styles.textInput}
									label={t("Date from")}
									name={"validation_transaction_id_from"}
									value={formState.validation_transaction_id_from as any}
									onChange={(e) =>
										actions.setFormField("validation_transaction_id_from")(e.target.value.replace(/\D/g, ""))
									}
									data-test-id=""
									error={visibleErrors.validation_transaction_id_from}
								/>
								<TextInput
									className={styles.textInput}
									label={t("Date to")}
									name={"validation_transaction_id_to"}
									value={formState.validation_transaction_id_to as any}
									onChange={(e) =>
										actions.setFormField("validation_transaction_id_to")(e.target.value.replace(/\D/g, ""))
									}
									data-test-id=""
									error={visibleErrors.validation_transaction_id_to}
								/>
							</div>
						}
						isRequired
					/>

					<FieldWrapper
						className={styles.lastFields}
						label={t("Request a payment receipt")}
						value={
							<Toggler
								value={formState.show_payment_bill === 1}
								onChange={(v: boolean) => actions.setFormField("show_payment_bill")(v ? 1 : 0)}
								data-test-id=""
							/>
						}
						isRequired
					/>
				</ScrollBox>
			</RenderIf>
			<RenderIf visible={step === 2}>
				<ScrollBox className={styles.scrollBox}>
					<Spoiler title={t("Payment making")} className={styles.spoiler} isExpanded>
						<button className={styles.addNew} onClick={() => actions.addHint(ENUMFormType.REQUISITE)} data-test-id="">
							<PlusIcon /> {t("Add")}
						</button>

						{requisiteHints.map((h) => (
							<div key={h.index} className={styles.hintFieldset}>
								<Select
									options={requisiteLangsOptions}
									value={h.lang}
									name={`requisite_hint_${h.index}_lang`}
									onChange={actions.setHintFieldParams(h.index)("lang") as any}
									placeholder={t("Language")}
									data-test-id=""
									className={styles.select}
									isLoading={languagesHook.isLoading}
									error={visibleErrors[`hints[${h.index}].lang`]}
								/>
								<TextArea
									name={`requisite_hint_${h.index}_text`}
									value={h.text}
									placeholder={t("Hint text")}
									onChange={(e: any) => actions.setHintFieldParams(h.index)("text")(e.target.value)}
									error={visibleErrors[`hints[${h.index}].text`]}
									data-test-id=""
								/>

								<button onClick={() => actions.removeHint(h.index)} data-test-id="">
									<TrashIcon />
								</button>
							</div>
						))}
					</Spoiler>

					<Spoiler title={t("Payment confirmation")} className={styles.spoiler} isExpanded>
						<button className={styles.addNew} data-test-id="" onClick={() => actions.addHint(ENUMFormType.PAYMENT)}>
							<PlusIcon /> {t("Add")}
						</button>

						{paymentHints.map((h) => {
							return (
								<div key={h.index} className={styles.hintFieldset}>
									<Select
										options={paymentLangsOptions}
										value={h.lang}
										onChange={actions.setHintFieldParams(h.index)("lang") as any}
										name={`payment_hint_${h.index}_lang`}
										placeholder={t("Language")}
										data-test-id=""
										className={styles.select}
										isLoading={languagesHook.isLoading}
										error={visibleErrors[`hints[${h.index}].lang`]}
									/>
									<TextArea
										name={`payment_hint_${h.index}_text`}
										placeholder={t("Hint text")}
										value={h.text}
										onChange={(e: any) => actions.setHintFieldParams(h.index)("text")(e.target.value)}
										error={visibleErrors[`hints[${h.index}].text`]}
										data-test-id=""
									/>
									<button onClick={() => actions.removeHint(h.index)} data-test-id="">
										<TrashIcon />
									</button>
								</div>
							);
						})}
					</Spoiler>

					<FieldWrapper
						className={styles.fileInput}
						label={t("Animation hint")}
						value={
							<DropZone
								file={formState.animation_hint}
								fileExtensionWhiteList={[".gif"]}
								onChange={actions.setFormField("animation_hint") as any}
								data-test-id=""
								error={visibleErrors.animation_hint}
							/>
						}
						isRequired
					/>

					<FieldWrapper
						className={styles.fileInput}
						label={t("Screenshot for payment confirmation")}
						value={
							<DropZone
								file={formState.screenshot}
								onChange={actions.setFormField("screenshot") as any}
								data-test-id=""
								fileExtensionWhiteList={[".png", ".jpeg", ".jpg"]}
								error={visibleErrors.screenshot}
							/>
						}
						isRequired
					/>
				</ScrollBox>
			</RenderIf>
			<div className={styles.actions}>
				<RenderIf visible={step === 1}>
					<Button onClick={() => actions.goStep(2)} isLoading={isLoading} variant="primary" data-test-id="">
						{t("Next")}
					</Button>
				</RenderIf>
				<RenderIf visible={step === 2}>
					<Button onClick={() => actions.goStep(1)} variant="secondary" data-test-id="">
						{t("Back")}
					</Button>
					<Button onClick={actions.submitHandler} isLoading={isLoading} variant="primary" data-test-id="">
						{t("Create")}
					</Button>
				</RenderIf>
			</div>
		</>
	);
};

export default CreateNewForm;

const FieldWrapper: React.FC<{
	readonly label: React.ReactNode;
	readonly value: React.ReactNode;
	readonly isRequired?: boolean;
	readonly className?: string;
}> = ({ label, value, isRequired, className }) => (
	<div className={[styles.fieldWrapper, className].filter((cn) => cn !== undefined).join(" ")}>
		<div className={styles.label}>
			{label}
			{isRequired && <span className={styles.requiredMark}>*</span>}
		</div>
		{value}
	</div>
);
