import RoutedTabs, { RoutedTab } from "components/RoutedTabs";
import { Outlet } from "react-router-dom";
import PageTitle from "src/components/PageTitle";
import { useTranslation, t } from "pay-kit";

import styles from "./styles.module.scss";

const tabs: RoutedTab[] = [
	{ value: "/postbacks", label: t("Sent") },
	{ value: "/postbacks/not-accepted", label: t("Not accepted") },
];

export default function PostbacksPage() {
	const {t} = useTranslation();
	return (
		<div className={styles.panel}>
			<PageTitle title={t("Postbacks")} />
			<RoutedTabs tabs={tabs} />
			<Outlet />
		</div>
	);
}
