// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommonData__CommonData-NhU{margin-top:24px;display:flex;flex-direction:column;justify-content:space-between;height:calc(100% - 60px);gap:24px}.CommonData__CommonData-NhU .CommonData__row-R4l{display:flex;gap:24px}.CommonData__CommonData-NhU .CommonData__row-R4l .CommonData__title-bPk{display:flex;align-items:center;flex:1;height:40px;font-weight:400;font-size:14px;line-height:20px;color:#697077}.CommonData__CommonData-NhU .CommonData__row-R4l .CommonData__value-bms{display:flex;flex:1;height:40px;align-items:center;font-weight:400;font-size:14px;color:#21272a}.CommonData__CommonData-NhU .CommonData__actions-Oo3{margin-top:auto}`, "",{"version":3,"sources":["webpack://./src/modules/Transactions/ArchivedTransactions/DetailedView/CommonData/CommonData.module.scss"],"names":[],"mappings":"AAAA,4BACC,eAAA,CACA,YAAA,CACA,qBAAA,CACA,6BAAA,CACA,wBAAA,CACA,QAAA,CAEA,iDACC,YAAA,CACA,QAAA,CAEA,wEACC,YAAA,CACA,kBAAA,CACA,MAAA,CACA,WAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,aAAA,CAGD,wEACC,YAAA,CACA,MAAA,CACA,WAAA,CACA,kBAAA,CACA,eAAA,CACA,cAAA,CAEA,aAAA,CAIF,qDACC,eAAA","sourcesContent":[".CommonData {\n\tmargin-top: 24px;\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: space-between;\n\theight: calc(100% - 60px);\n\tgap: 24px;\n\n\t.row {\n\t\tdisplay: flex;\n\t\tgap: 24px;\n\n\t\t.title {\n\t\t\tdisplay: flex;\n\t\t\talign-items: center;\n\t\t\tflex: 1;\n\t\t\theight: 40px;\n\t\t\tfont-weight: 400;\n\t\t\tfont-size: 14px;\n\t\t\tline-height: 20px;\n\t\t\t/* Blue_gray/70 */\n\t\t\tcolor: #697077;\n\t\t}\n\n\t\t.value {\n\t\t\tdisplay: flex;\n\t\t\tflex: 1;\n\t\t\theight: 40px;\n\t\t\talign-items: center;\n\t\t\tfont-weight: 400;\n\t\t\tfont-size: 14px;\n\t\t\t/* Blue_gray/90 */\n\t\t\tcolor: #21272A;\n\t\t}\n\t}\n\n\t.actions {\n\t\tmargin-top: auto;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CommonData": `CommonData__CommonData-NhU`,
	"row": `CommonData__row-R4l`,
	"title": `CommonData__title-bPk`,
	"value": `CommonData__value-bms`,
	"actions": `CommonData__actions-Oo3`
};
export default ___CSS_LOADER_EXPORT___;
