// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DetailedRequest__DetailedRequest-BQR{width:100%}.DetailedRequest__DetailedRequest-BQR .DetailedRequest__subheader-Ldl{display:flex;justify-content:space-between;align-items:center}.DetailedRequest__DetailedRequest-BQR .DetailedRequest__subheader-Ldl h3{font-style:normal;font-weight:400;font-size:14px;line-height:20px;color:#697077}.DetailedRequest__DetailedRequest-BQR .DetailedRequest__scrollBox-wfq{margin-top:10px;font-weight:400;font-size:14px;line-height:20px;color:#21272a;word-wrap:break-word;max-height:250px}.DetailedRequest__DetailedRequest-BQR button.DetailedRequest__closeButton-uel{margin-left:auto;margin-top:20px}`, "",{"version":3,"sources":["webpack://./src/modules/Postbacks/PostbacksActionsProvider/DetailedRequest/DetailedRequest.module.scss"],"names":[],"mappings":"AAAA,sCACC,UAAA,CAEA,sEACC,YAAA,CACA,6BAAA,CACA,kBAAA,CAEA,yEACC,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,aAAA,CAIF,sEACC,eAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,aAAA,CACA,oBAAA,CACA,gBAAA,CAGD,8EACC,gBAAA,CACA,eAAA","sourcesContent":[".DetailedRequest {\n\twidth: 100%;\n\n\t.subheader {\n\t\tdisplay: flex;\n\t\tjustify-content: space-between;\n\t\talign-items: center;\n\n\t\th3 {\n\t\t\tfont-style: normal;\n\t\t\tfont-weight: 400;\n\t\t\tfont-size: 14px;\n\t\t\tline-height: 20px;\n\t\t\t/* Blue_gray/70 */\n\t\t\tcolor: #697077;\n\t\t}\n\t}\n\n\t.scrollBox {\n\t\tmargin-top: 10px;\n\t\tfont-weight: 400;\n\t\tfont-size: 14px;\n\t\tline-height: 20px;\n\t\t/* Blue_gray/90 */\n\t\tcolor: #21272a;\n\t\tword-wrap: break-word;\n\t\tmax-height: 250px;\n\t}\n\n\tbutton.closeButton {\n\t\tmargin-left: auto;\n\t\tmargin-top: 20px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DetailedRequest": `DetailedRequest__DetailedRequest-BQR`,
	"subheader": `DetailedRequest__subheader-Ldl`,
	"scrollBox": `DetailedRequest__scrollBox-wfq`,
	"closeButton": `DetailedRequest__closeButton-uel`
};
export default ___CSS_LOADER_EXPORT___;
