import { Button, Checkbox } from "pay-kit";
import { useTranslation } from "pay-kit";
import React, { useState } from "react";

import styles from "./CheckboxGroup.module.scss";
import SearchForm from "./SearchForm";

export type OptionType = { readonly value: any; readonly label: string };

type CheckboxGroupPropsType = {
	readonly className?: string;
	readonly name: string;
	readonly placeholder?: string;
	readonly label?: string;
	readonly value?: readonly any[];
	readonly options: readonly OptionType[];
	readonly onChange: (value?: readonly (string | number | undefined | null)[]) => void;
};

const CheckboxGroup: React.FC<CheckboxGroupPropsType> = ({
	className,
	name,
	value,
	options,
	onChange,
	// placeholder,
	// label,
}) => {
	const { t } = useTranslation();
	const [searchKeyword, setSearchKeyword] = useState<string>("");
	const classNames = [styles.checkboxGroup, className];

	const onChangeHandler = (optionValue: string | number, isChecked: boolean) => {
		const prevValue = value && Array.isArray(value) ? value : [];
		let newValue;

		if (isChecked) {
			newValue = [...prevValue, optionValue];
		} else {
			newValue = prevValue.filter((v) => v !== optionValue);
		}

		if (newValue.length === 0) {
			onChange(undefined);
		} else {
			onChange(newValue);
		}
	};

	const foundOptions = searchKeyword
		? options.filter(({ label }) => label.toLowerCase().indexOf(searchKeyword.toLowerCase()) !== -1)
		: options;

	const notFound = foundOptions.length === 0 && options.length > 0;

	const showSearchForm = options.length > 5;

	const selectAllHandler = () => {
		const prevValue = value && Array.isArray(value) ? value : [];
		const newValue = [...new Set([...prevValue, ...foundOptions.map(({ value }) => value)])];

		onChange(newValue);
	};

	return (
		<div className={classNames.join(" ")}>
			{showSearchForm && <SearchForm value={searchKeyword} onChange={setSearchKeyword} />}
			{notFound && <div className={styles.notFound}>{t("No results")}</div>}

			{foundOptions.length > 1 && (
				<div className={styles.chooseAllWrapper}>
					<Button data-test-id="pGz4Ee_56OAwwDOXFRt5r" variant="text-primary" onClick={selectAllHandler}>
						{t("Select all")}
					</Button>
				</div>
			)}

			{foundOptions.map((option) => {
				const key = `${name}_${option.value}`;
				const isChecked = value && Array.isArray(value) ? value.indexOf(option.value) !== -1 : false;

				return (
					<Checkbox
						key={key}
						id={key}
						label={option.label}
						isChecked={isChecked}
						onChange={() => onChangeHandler(option.value, !isChecked)}
						data-test-id="useless-data-test-id"
					/>
				);
			})}
		</div>
	);
};

export default CheckboxGroup;
