import { UpdateSettingsType } from "api";
import { t } from "pay-kit";

export const getInitState = (params: UpdateSettingsType | null) => ({
	upi_api_payment_methods: params?.upi_api_payment_methods,
	upi_p2p_payment_methods: params?.upi_p2p_payment_methods,
	use_url_from_settings: !!params?.use_url_from_settings,
	succeeded_urls: params?.succeeded_urls,
	pending_urls: params?.pending_urls,
	failed_urls: params?.failed_urls,
	transaction_reactivation_timer: params?.transaction_reactivation_timer,
	transaction_recheck_timer: params?.transaction_recheck_timer,
	del_possible_trans_after_days: params?.del_possible_trans_after_days,
	use_sms_box: params?.use_sms_box,
	display_report_problem: !!params?.display_report_problem,
	restrict_withdrawal_requests: params?.restrict_withdrawal_requests,
});

export const transactionsTips = {
	TRANSACTION_REACTIVATION_TIMER: t("The time that must elapse before trying to activate the transaction again."),
	TRANSACTION_RECHECK_TIMER: t(
		"Period of re-checking the existence of a transaction during the waiting time between transaction attempts of a transaction"
	),
	DEL_POSSIBLE_TRANSFER_AFTER_DAYS: t(
		"The number of days after which the unprocessed withdrawal will be deleted from the System"
	),
};

export const transactionsHints = {
	TRANSACTION_REACTIVATION_TIMER: t(`Waiting time between attempts to activate a transaction (in seconds)`),
	TRANSACTION_RECHECK_TIMER: t(`Period of re-checking the existence of a transaction (in seconds)`),
	DEL_POSSIBLE_TRANSFER_AFTER_DAYS: t("Number of downtime days to delete a possible transaction"),
};

export const UPI_PAYMENT_METHOD_OPTIONS = [
	{
		label: "UPI ID",
		value: `UPI_ID`,
	},
	{
		label: "QR",
		value: `QR`,
		api: true,
	},
	{
		label: t("Url"),
		value: `LINK`,
		api: true,
	},
];

export const SMS_BOX_OPTIONS = [
	{
		value: false,
		label: t("Manual input"),
	},
	{
		value: true,
		label: t("Automatic input"),
	},
];
