// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WithdrawalType__WithdrawalType-EQR{line-height:20px;padding:0 8px;border-radius:10px;text-transform:uppercase;font-weight:500;font-size:14px}.WithdrawalType__APPROVED-KNh{background:#fff;color:#24a148;border:1px solid #6fdc8c}.WithdrawalType__TESTING-uM7{background:#fff;color:#e8882e;border:1px solid #ffce78}.WithdrawalType__isNotDefined-jPN{background:#fff;color:#222;border:1px solid #222}`, "",{"version":3,"sources":["webpack://./src/modules/Withdrawals/components/columns/components/WithdrawalType/WithdrawalType.module.scss"],"names":[],"mappings":"AAAA,oCACI,gBAAA,CACA,aAAA,CACA,kBAAA,CACA,wBAAA,CACA,eAAA,CACA,cAAA,CAIJ,8BAEI,eAAA,CAEA,aAAA,CAEA,wBAAA,CAIJ,6BAEI,eAAA,CAEA,aAAA,CAEA,wBAAA,CAGJ,kCAEI,eAAA,CAEA,UAAA,CACA,qBAAA","sourcesContent":[".WithdrawalType {\n    line-height: 20px;\n    padding: 0 8px;\n    border-radius: 10px;\n    text-transform: uppercase;\n    font-weight: 500;\n    font-size: 14px;\n}\n\n/* ПОДТВЕРЖДЕННАЯ */\n.APPROVED {\n    /* White/100 */\n    background: #FFFFFF;\n    /* Additional/Green/Primary */\n    color: #24A148;\n    /* Green/30 */\n    border: 1px solid #6FDC8C;\n}\n\n/* ОТЛАДОЧНАЯ */\n.TESTING {\n    /* White/100 */\n    background: #FFFFFF;\n    /* Orange/50 */\n    color: #E8882E;\n    /* Orange/30 */\n    border: 1px solid #FFCE78;\n}\n\n.isNotDefined {\n    /* White/100 */\n    background: #FFFFFF;\n    /* Orange/50 */\n    color: #222222;\n    border: 1px solid #222222;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"WithdrawalType": `WithdrawalType__WithdrawalType-EQR`,
	"APPROVED": `WithdrawalType__APPROVED-KNh`,
	"TESTING": `WithdrawalType__TESTING-uM7`,
	"isNotDefined": `WithdrawalType__isNotDefined-jPN`
};
export default ___CSS_LOADER_EXPORT___;
