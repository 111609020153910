import { ProxyCommonWallets } from "modules/Proxies/components/ProxyModalRows";
import { formStateType } from "modules/Proxies/components/UpdateProxyModal";
import { ProxyWalletSelectType } from "modules/Proxies/hooks/useAvailableWallets";

export const getUniqueWalletOptions = (
	wallets: readonly ProxyCommonWallets[] | undefined,
	dynamicSelectOptions: readonly ProxyWalletSelectType[]
) => {
	return (formState: formStateType, currentValue: string) => {
		const responseWallets = wallets?.length
			? wallets.map((elem) => ({
					value: elem.hash_id,
					label: `${elem.type} | ${elem.identifier}`,
					parser_type: elem.parser_type,
			  }))
			: [];

		const filteredDynamicSelectOptions = [...responseWallets, ...dynamicSelectOptions].filter(
			(option, index, self) => index === self.findIndex((selfOption) => selfOption.value === option.value)
		);

		// getting already chosen options
		const selectedTypes = formState.wallets?.map(({ type }) => type).filter((type) => type !== currentValue);

		const options = filteredDynamicSelectOptions.filter((type) => !selectedTypes?.includes(`${type.value}`));
		return options.length ? options : responseWallets;
	};
};
