import { BodyEndPortal } from "pay-kit";
import React, { useEffect,useState } from "react";

import Alert, { AlertType } from "./Alert";
import styles from "./Alerts.module.scss";

const DEFAULT_AUTOCLOSE_TIMEOUT = 5000;

declare global {
	interface Window {
		readonly pushAlert: (alert: AlertType) => void;
	}
}

export const AlertsContext = React.createContext({});

const AlertsProvider = ({ children }: AlertssProviderPropsType) => {
	const [alerts, setAlerts] = useState<readonly AlertType[]>([]);

	const pushAlert = (alert: AlertType) => {
		const id = Date.now();

		setAlerts((prevState: any) => [...prevState, { ...alert, id }]);

		if (!alert.pinned) {
			setTimeout(() => removeAlert(id), alert.timeout || DEFAULT_AUTOCLOSE_TIMEOUT);
		}
	};

	useEffect(() => {
		window.pushAlert = pushAlert;
	}, []);

	const removeAlert = (id: number | string) =>
		setAlerts((prevState) => prevState.filter((notification: any) => notification.id !== id));

	return (
		<AlertsContext.Provider value={{ alerts: { list: alerts, push: pushAlert } }}>
			{alerts.length > 0 && (
				<BodyEndPortal>
					<div className={styles.alertsWrapper}>
						{alerts.map(({ id, pinned, onClose, ...rest }) => (
							<Alert {...rest} key={id} onClose={pinned && id ? () => removeAlert(id) : undefined} />
						))}
					</div>
				</BodyEndPortal>
			)}
			{children}
		</AlertsContext.Provider>
	);
};

type AlertssProviderPropsType = {
	readonly children: JSX.Element;
};

export default AlertsProvider;

export const withAlertsHOC = (Component: React.ComponentType) => (props: any) =>
	<AlertsContext.Consumer>{(value) => <Component {...props} {...value} />}</AlertsContext.Consumer>;
