import IconButton from "components/IconButton";
import { WorkingWalletContext } from "modules/Wallets/contexts/WorkingWalletContext";
import { useTranslation } from "pay-kit";
import { useContext } from "react";

const MultipleActions = () => {
	const { openMassArchiveWalletModal, openMassActivateWalletModal } = useContext(WorkingWalletContext);
	const { t } = useTranslation();

	const handleMassArchiveClick = () => {
		openMassArchiveWalletModal();
	};

	const handleMassActivateClick = () => {
		openMassActivateWalletModal();
	};

	return (
		<div style={{ display: "flex", gap: "10px" }}>
			<IconButton
				data-test-id="ZX6C0z-RlLp5xMAmuzIpr"
				icon="archive"
				onClick={handleMassArchiveClick}
				caption={t("Archive")}
			/>
			<IconButton
				data-test-id="SeTcx7axKz4y5-2ZqQOHF"
				icon="activate"
				onClick={handleMassActivateClick}
				caption={t("Activate")}
			/>
		</div>
	);
};

export default MultipleActions;
