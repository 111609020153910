const VisibleIcon = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd"
				d="M22.182 12C22.182 12 18.3638 5 12.0002 5C5.63654 5 1.81836 12 1.81836 12C1.81836 12 5.63654 19 12.0002 19C18.3638 19 22.182 12 22.182 12ZM3.31127 12C3.92666 12.9353 4.63445 13.8064 5.424 14.6002C7.062 16.2407 9.302 17.7273 12.0002 17.7273C14.6984 17.7273 16.9371 16.2407 18.5776 14.6002C19.3672 13.8064 20.075 12.9353 20.6904 12C20.075 11.0647 19.3672 10.1936 18.5776 9.39982C16.9371 7.75927 14.6971 6.27273 12.0002 6.27273C9.302 6.27273 7.06327 7.75927 5.42272 9.39982C4.63317 10.1936 3.92538 11.0647 3.31 12H3.31127Z"
				fill="currentColor" />
			<path fillRule="evenodd" clipRule="evenodd"
				d="M11.9995 8.81814C11.1556 8.81814 10.3463 9.15336 9.74958 9.75007C9.15288 10.3468 8.81765 11.1561 8.81765 12C8.81765 12.8438 9.15288 13.6531 9.74958 14.2498C10.3463 14.8465 11.1556 15.1818 11.9995 15.1818C12.8433 15.1818 13.6526 14.8465 14.2494 14.2498C14.8461 13.6531 15.1813 12.8438 15.1813 12C15.1813 11.1561 14.8461 10.3468 14.2494 9.75007C13.6526 9.15336 12.8433 8.81814 11.9995 8.81814ZM7.54492 12C7.54492 10.8185 8.01424 9.68551 8.84963 8.85012C9.68502 8.01473 10.818 7.54541 11.9995 7.54541C13.1809 7.54541 14.3139 8.01473 15.1493 8.85012C15.9847 9.68551 16.454 10.8185 16.454 12C16.454 13.1814 15.9847 14.3144 15.1493 15.1498C14.3139 15.9852 13.1809 16.4545 11.9995 16.4545C10.818 16.4545 9.68502 15.9852 8.84963 15.1498C8.01424 14.3144 7.54492 13.1814 7.54492 12Z"
				fill="currentColor" />
		</svg>
	);
};

export default VisibleIcon;
