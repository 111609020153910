import { useAccountsBalancesContext } from "contexts/AccountsBalanceContext";
import { useTranslation } from "pay-kit";
import { Toggler } from "pay-kit";

import styles from "./hideZeroBalances.module.scss";

const HideZeroBalances = () => {
	const { setHideZeroBalance, hideZeroBalance } = useAccountsBalancesContext();
	const { t } = useTranslation();

	const toggler = <Toggler onChange={(value) => setHideZeroBalance(value)} value={hideZeroBalance} />;
	const text = <span className={styles.togglerHeader}>{t("Hide zero balances")}</span>;

	return (
		<div className={styles.hideZeroBalancesWrapper}>
			{toggler} {text}
		</div>
	);
};

export default HideZeroBalances;
