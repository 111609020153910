import { useTranslation } from "pay-kit";
import { ILimitsItem } from "api/walletTypesGroup";
import { Button, ScrollBox, TextInput } from "pay-kit";
import { useEffect, useState } from "react";

import styles from "./LimitsForm.module.scss";
import { formatValue, validateForm } from "./utils";

interface ILimitsFormProps {
	readonly initState: readonly ILimitsItem[];
	readonly isLoading: boolean;
	readonly onSubmit: (data: readonly ILimitsItem[]) => void;
}

const LimitsForm: React.FC<ILimitsFormProps> = ({ initState, onSubmit, isLoading }) => {
	const {t} = useTranslation();
	const [formState, setFormState] = useState<readonly ILimitsItem[]>(initState);

	useEffect(() => {
		setFormState(initState);
	}, [initState]);

	const getFieldStateSetter =
		({ rowNumber, name }: any) =>
		({ target: { value } }: any) => {
			setFormState((prevState) => {
				const prevStateCopy: readonly IMutableLimitsItem[] = JSON.parse(JSON.stringify(prevState));
				const parsedValue = value || null;

				switch (name) {
					case "deposit.min":
						prevStateCopy[rowNumber].limits.deposit.min = parsedValue;
						break;
					case "deposit.max":
						prevStateCopy[rowNumber].limits.deposit.max = parsedValue;
						break;
					case "withdrawal.min":
						prevStateCopy[rowNumber].limits.withdrawal.min = parsedValue;
						break;
					case "withdrawal.max":
						prevStateCopy[rowNumber].limits.withdrawal.max = parsedValue;
						break;
					case "stockpiling.min":
						prevStateCopy[rowNumber].limits.stockpiling.min = parsedValue;
				}

				return prevStateCopy;
			});
		};

	const errors = validateForm(formState);
	const hasErrors: boolean = Object.values(errors).find((err) => err !== undefined) !== undefined;

	return (
		<>
			<ScrollBox className={styles.scrollBox}>
				<table className={styles.table}>
					<thead>
						<tr>
							<th>{t("Currency")}</th>
							<th colSpan={2}>{t("Deposit")}</th>
							<th colSpan={2}>{t("Withdrawal")}</th>
							<th colSpan={1}>{t("Stockpiling")}</th>
						</tr>
						<tr>
							<th></th>
							<th>{t("Min")}</th>
							<th>{t("Max")}</th>
							<th>{t("Min")}</th>
							<th>{t("Max")}</th>
							<th>{t("Min")}</th>
						</tr>
					</thead>
					<tbody>
						{formState.map((fieldset, rowNumber) => (
							<tr key={fieldset.currency}>
								<td>{fieldset.currency}</td>
								<td>
									<TextInput
										name="deposit.min"
										value={formatValue(fieldset.limits.deposit.min)}
										onChange={getFieldStateSetter({ rowNumber, name: "deposit.min" })}
										data-test-id=""
										error={errors[`${fieldset.currency}.deposit.min.error`]}
									/>
								</td>
								<td>
									<TextInput
										name="deposit.max"
										value={formatValue(fieldset.limits.deposit.max)}
										onChange={getFieldStateSetter({ rowNumber, name: "deposit.max" })}
										data-test-id=""
										error={errors[`${fieldset.currency}.deposit.max.error`]}
									/>
								</td>
								<td>
									<TextInput
										name="withdrawal.min"
										value={formatValue(fieldset.limits.withdrawal.min)}
										onChange={getFieldStateSetter({ rowNumber, name: "withdrawal.min" })}
										data-test-id=""
										error={errors[`${fieldset.currency}.withdrawal.min.error`]}
									/>
								</td>
								<td>
									<TextInput
										name="withdrawal.max"
										value={formatValue(fieldset.limits.withdrawal.max)}
										onChange={getFieldStateSetter({ rowNumber, name: "withdrawal.max" })}
										data-test-id=""
										error={errors[`${fieldset.currency}.withdrawal.max.error`]}
									/>
								</td>
								<td>
									<TextInput
										name="stockpiling.min"
										value={formatValue(fieldset.limits.stockpiling.min)}
										onChange={getFieldStateSetter({ rowNumber, name: "stockpiling.min" })}
										data-test-id=""
										error={errors[`${fieldset.currency}.stockpiling.min.error`]}
									/>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</ScrollBox>

			<div className={styles.actions}>
				<Button
					variant="primary"
					onClick={hasErrors ? undefined : () => onSubmit(formState)}
					data-test-id=""
					isLoading={isLoading}
					disabled={isLoading || hasErrors}
				>
					{t("Save")}
				</Button>
			</div>
		</>
	);
};

export default LimitsForm;

export interface IMutableLimitsItem {
	readonly currency: string;
	readonly limits: {
		readonly withdrawal: {
			min: null | number; // eslint-disable-line functional/prefer-readonly-type
			max: null | number; // eslint-disable-line functional/prefer-readonly-type
		};
		readonly deposit: {
			min: null | number; // eslint-disable-line functional/prefer-readonly-type
			max: null | number; // eslint-disable-line functional/prefer-readonly-type
		};
		readonly stockpiling: {
			min: number | null; // eslint-disable-line functional/prefer-readonly-type
		};
	};
	readonly defaults: {
		readonly withdrawal: {
			min: null | number; // eslint-disable-line functional/prefer-readonly-type
			max: null | number; // eslint-disable-line functional/prefer-readonly-type
		};
		readonly deposit: {
			min: null | number; // eslint-disable-line functional/prefer-readonly-type
			max: null | number; // eslint-disable-line functional/prefer-readonly-type
		};
		readonly stockpiling: {
			min: number | null; // eslint-disable-line functional/prefer-readonly-type
		};
	};
}
