// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StatisticModal__table-wli{width:100%;margin-bottom:32px;border-collapse:collapse}.StatisticModal__thead-eUY{color:#697077}.StatisticModal__thead-eUY tr{border-bottom:2px solid #f2f3f5}.StatisticModal__thead-eUY td{padding:10px}.StatisticModal__maxContainer-X0z{position:relative}.StatisticModal__tbody-XWJ tr:nth-child(even){background:#f2f3f5}.StatisticModal__tbody-XWJ td{padding:10px}.StatisticModal__bold-J84{font-size:16px;font-weight:600}.StatisticModal__max-PoQ{font-size:10px;position:absolute;bottom:2px;left:-22px}.StatisticModal__sum-__q{font-size:12px;font-style:normal;font-weight:400}.StatisticModal__copy-zDx{margin-right:0;margin-left:auto}`, "",{"version":3,"sources":["webpack://./src/modules/Wallets/components/modals/StatisticModal/StatisticModal.module.scss"],"names":[],"mappings":"AAAA,2BACC,UAAA,CACA,kBAAA,CACA,wBAAA,CAGD,2BACC,aAAA,CAEA,8BACC,+BAAA,CAGD,8BACC,YAAA,CAIF,kCACC,iBAAA,CAKC,8CACC,kBAAA,CAIF,8BACC,YAAA,CAIF,0BACC,cAAA,CACA,eAAA,CAGD,yBACC,cAAA,CACA,iBAAA,CACA,UAAA,CACA,UAAA,CAGD,yBACC,cAAA,CACA,iBAAA,CACA,eAAA,CAGD,0BACC,cAAA,CACA,gBAAA","sourcesContent":[".table {\n\twidth: 100%;\n\tmargin-bottom: 32px;\n\tborder-collapse: collapse;\n}\n\n.thead {\n\tcolor: #697077;\n\n\ttr {\n\t\tborder-bottom: 2px solid #F2F3F5;\n\t}\n\n\ttd {\n\t\tpadding: 10px;\n\t}\n}\n\n.maxContainer {\n\tposition: relative;\n}\n\n.tbody {\n\ttr {\n\t\t&:nth-child(even) {\n\t\t\tbackground: #F2F3F5;\n\t\t}\n\t}\n\n\ttd {\n\t\tpadding: 10px;\n\t}\n}\n\n.bold {\n\tfont-size: 16px;\n\tfont-weight: 600;\n}\n\n.max {\n\tfont-size: 10px;\n\tposition: absolute;\n\tbottom: 2px;\n\tleft: -22px;\n}\n\n.sum {\n\tfont-size: 12px;\n\tfont-style: normal;\n\tfont-weight: 400;\n}\n\n.copy {\n\tmargin-right: 0;\n\tmargin-left: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `StatisticModal__table-wli`,
	"thead": `StatisticModal__thead-eUY`,
	"maxContainer": `StatisticModal__maxContainer-X0z`,
	"tbody": `StatisticModal__tbody-XWJ`,
	"bold": `StatisticModal__bold-J84`,
	"max": `StatisticModal__max-PoQ`,
	"sum": `StatisticModal__sum-__q`,
	"copy": `StatisticModal__copy-zDx`
};
export default ___CSS_LOADER_EXPORT___;
