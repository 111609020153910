import { useTranslation } from "pay-kit";
import { Button } from "pay-kit";
import { FC, ReactElement } from "react";

import styles from "./selectBuilderButton.module.scss";

const SelectBuilderButton: FC<SelectBuilderButtonType> = ({ children, insertItem, error }) => {
	const { t } = useTranslation();

	return (
		<>
			<div className={styles.addButtonWrapper}>
				<div className={styles.walletText}>{t("Wallet")}</div>
				<Button
					data-test-id="add-wallet"
					variant="text-primary"
					onClick={() => insertItem({ type: undefined }, "insertAfter")}
				>
					+ {t("Add")}
				</Button>
			</div>
			{error && <div className={styles.errorMessage}>{error}</div>}
			{children}
		</>
	);
};

export default SelectBuilderButton;

type SelectBuilderButtonType = {
	readonly children: ReactElement;
	readonly error: string;
	readonly insertItem: (value: { readonly type: undefined }, pos: string) => void;
};
