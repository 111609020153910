// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StatusCode__success-D4c{color:#24a148}.StatusCode__error-mwg{color:#dc3545}`, "",{"version":3,"sources":["webpack://./src/modules/Withdrawals/components/ActionsProvider/DetailedView/Postback/StatusCode/StatusCode.module.scss"],"names":[],"mappings":"AAAA,yBAEC,aAAA,CAGD,uBAEC,aAAA","sourcesContent":[".success {\n\t/* Green/50 */\n\tcolor: #24a148;\n}\n\n.error {\n\t/* Red/60 */\n\tcolor: #dc3545;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"success": `StatusCode__success-D4c`,
	"error": `StatusCode__error-mwg`
};
export default ___CSS_LOADER_EXPORT___;
