import { fetchApi } from "api/fetch";

const walletTypeToCurrencyGroup = {
	updateSorting: (data: UpdateSortingParamsType) => fetchApi<UpdateSortingType>(`wallet-type-to-currency`, data, `POST`)
};

export default walletTypeToCurrencyGroup;

export type UpdateSortingType = readonly unknown[]

export type ItemType = {
	readonly id: number
	readonly order: number
}

export type UpdateSortingParamsType = {
	readonly items: readonly ItemType[]
}