import { useTranslation } from "pay-kit";
/* eslint-disable ternary/no-unreachable */
import { Button } from "@paykassma/pay-kit";
import API from "api/reportsGroup";
import React, { useState } from "react";

import styles from "./RemoveConfirmation.module.scss";

type RemoveConfirmationPropsType = {
	readonly removeIds: readonly number[];
	readonly closeHandler: () => void;
	readonly refreshList: () => void;
};

const RemoveConfirmation: React.FC<RemoveConfirmationPropsType> = ({ removeIds, closeHandler, refreshList }) => {
	const {t} = useTranslation();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const isManyTasks = removeIds.length >= 2;

	const removeTasks = () => {
		setIsLoading(true);

		Promise.all(
			removeIds.map((id) =>
				API.deleteAutoReport(id).then((res) => {
					return res;
				})
			)
		)
			.then((responses) => {
				window.pushAlert({ title: isManyTasks ? t("Tasks deleted") : t("Task deleted"), type: "success" });
				return responses;
			})
			.finally(() => {
				setIsLoading(false);
				refreshList();
				closeHandler();
			})
			.catch((err) => {
				window.pushAlert({ title: t("An error occurred while deleting reports"), type: "error" });
				console.error(err);
			});
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.text}>
				{t("Are you sure you want to delete {{taskLabel}} for autogeneration with ID {{ids}}", {
					taskLabel: isManyTasks ? t("task") : t("tasks"),
					ids: removeIds.join(", "),
				})}
			</div>
			<div className={styles.actions}>
				<Button variant="secondary" onClick={closeHandler} data-test-id="cancel">
					{t("Cancel")}
				</Button>
				<Button variant="delete" data-test-id="remove" onClick={removeTasks} isLoading={isLoading}>
					{t("Delete")}
				</Button>
			</div>
		</div>
	);
};

export default RemoveConfirmation;
