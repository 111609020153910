const DebugIcon = () => {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M12.6673 8.66671V5.83643" stroke="#697077" strokeLinecap="round" strokeLinejoin="round"/>
			<rect x="11.334" y="8.66663" width="2.66667" height="6" rx="1.33333" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M12.0263 2.18419L11.2365 3.76273C11.1081 4.01942 11.1583 4.3295 11.3613 4.53245L12.6652 5.83639L13.9707 4.53089C14.1737 4.32793 14.224 4.01785 14.0955 3.76116L13.3065 2.18419C13.25 2.0713 13.1346 2 13.0084 2H12.3244C12.1982 2 12.0828 2.0713 12.0263 2.18419V2.18419Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M3.33398 2L1.33398 4V5.33333H8.00065C8.36884 5.33333 8.66732 5.03486 8.66732 4.66667V2.66667C8.66732 2.29848 8.36884 2 8.00065 2H3.33398Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M4 5.33337H6.66667V13.3334C6.66667 14.0698 6.06971 14.6667 5.33333 14.6667V14.6667C4.59695 14.6667 4 14.0698 4 13.3334V5.33337Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
	)
}

export default DebugIcon;
