import { ICreateWithdrawalData } from "api/withdrawalGroup";
import { t, useTranslation } from "pay-kit";
import { number, object, string } from "yup";

import { IFormData } from "./index";

const validationSchema = object().shape({
	payment_system: string().required(t("Required field")),
	label: string().required(t("Required field")),
	amount: number()
		.positive(t("Only positive number"))
		.typeError(t("Only number"))
		.required(t("Enter amount"))
		.when("payment_system", (payment_system: string, schema: any) => {
			switch (payment_system) {
				case "help2pay":
				case "cashmaal":
				case "paygiga":
				case "imps_ib":
				case "upi_ib":
					return schema.integer(t("Only whole number"));
				case "paytm":
					return schema.test("kratno-10", t("Must be a multiple of 10"), (value: any) => value % 10 == 0);
				default:
					return schema;
			}
		}),
	currency_code: string().required(t("Select currency")),
	account_number: string()
		.required(t("Required field"))
		.when("payment_system", (payment_system: string, schema: any) => {
			switch (payment_system) {
				case "imps_ib":
				case "upi_ib":
					return number()
						.positive(t("Only positive number"))
						.typeError(t("Only numbers"))
						.integer(t("Only whole number"))
						.required(t("Required field"));
				case "paytm":
					return string()
						.matches(/^\+?\d+$/, t("Only numbers"))
						.min(13, t("The value without country code must be 10 digits long"))
						.max(13, t("The value without country code must be 10 digits long"))
						.required(t("Required field"));
				case "bkash":
				case "nagad":
					return string()
						.matches(/^01\d{9}$/, t("Wrong format"))
						.required(t("Required field"));
				case "rocket":
					return string()
						.matches(/^01\d{9,10}$/, t("Wrong format"))
						.required(t("Required field"));
				default:
					return schema;
			}
		}),
	account_name: string().when("payment_system", (payment_system: string, schema: any) => {
		switch (payment_system) {
			case "help2pay":
			case "upi_ib":
			case "paygiga":
				return string().required(t("Required field"));
			case "imps_ib":
				return string().required(t("Required field")).max(191, t("191 characters maximum"));
			default:
				return schema;
		}
	}),
	account_email: string().when("payment_system", (payment_system: string, schema: any) => {
		switch (payment_system) {
			case "bkash":
			case "cashmaal":
				return string().email(t("Enter a correct email")).required("Required field");
			default:
				return string().email(t("Enter a correct email"));
		}
	}),
	bank_code: string().when("payment_system", (payment_system: string, schema: any) => {
		switch (payment_system) {
			case "help2pay":
			case "paygiga":
				return string().required(t("Required field"));
			case "imps_ib":
				return string().max(11, t("Exactly 11 characters")).min(11, t("Exactly 11 characters"));
			default:
				return string();
		}
	}),
	comment: string().max(64, t("64 characters maximum")),
	withdrawal_id: string().max(36, t("36 characters maximum")),
});

export const validate = (formData: any) => {
	try {
		validationSchema.validateSync(formData, { abortEarly: false });
	} catch (e: any) {
		const err: any = {};

		e.inner.forEach((error: any) => {
			if (error.path) {
				err[error.path] = error.message;
			}
		});

		return err;
	}

	return {};
};

type prepareRequestDataType = (formatData: IFormData) => ICreateWithdrawalData;

export const prepareRequestData: prepareRequestDataType = ({
	payment_system,
	label,
	amount,
	currency_code,
	account_number,
	withdrawal_id,
	account_name,
	account_email,
	payments_method,
	payments_provider,
	bank_code,
	branch_code,
	bank_code_in_payments_system,
	comment,
}: IFormData) => {
	return {
		payment_system,
		label,
		amount,
		currency_code,
		account_number: /^\+91/.test(`${account_number}`) ? account_number?.substring(3) : account_number,
		comment,
		withdrawal_id,
		account_name,
		account_email,
		payments_details: {
			payments_method,
			payments_provider,
		},
		bank_details: {
			bank_code,
			branch_code,
			bank_code_in_payments_system,
		},
		type: 1,
	} as ICreateWithdrawalData;
};

/**
 * isSpecialPs.
 *
 * Определяет, есть ли необходимость навешивать  особенные правила на поля и хэндлеры.
 *
 */
export function isSpecialPs(ps?: string) {
	return ps === "nagad" || ps === "bkash" || ps === "rocket";
}

/**
 * isAlphabeticValue.
 *
 * Проверяет наличие букв в строке.
 */
export function isAlphabeticValue(value: unknown) {
	return /\D+/.test(value as string);
}

export function isLengthTooLong(length: number, value?: string) {
	if (!value) return false;

	return value.length > length;
}
