import { TextInput, useOutsideClick } from "pay-kit";
import { FormEvent, useContext, useRef, useState } from "react";

import { ActionsContext } from "../../ActionsProvider";
import CancelIconSVG from "./icons/cancel.svg";
import SubmitIconSVG from "./icons/submit.svg";
import styles from "./Threshold.module.scss";

interface IThresholdProps {
	readonly initialValue: number;
	readonly type: string;
	readonly notifications: boolean;
	readonly is_check_amount: boolean;
}

const Threshold: React.FC<IThresholdProps> = ({ initialValue, type, notifications, is_check_amount }) => {
	const [value, setValue] = useState<number>(initialValue);
	const [isEditingMode, setEditingMode] = useState<boolean>(false);
	const editControlRef = useRef(null);
	const actionsContext = useContext(ActionsContext);

	const cancelEditingHandler = () => {
		setEditingMode(false);
		setValue(initialValue);
	};

	useOutsideClick(editControlRef, cancelEditingHandler);

	if (!isEditingMode) {
		return (
			<button className={styles.threshold} onClick={() => setEditingMode(true)} data-test-id="standard_threshold">
				{value}
			</button>
		);
	}

	const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = parseInt(e.target.value.replace(/\D/, "").replace(/^0/, ""), 10);

		if (!isNaN(value)) {
			setValue(value);
		}
	};

	const submitHandler = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		actionsContext.updateSettingsAPI.update({
			wallet_type: type,
			standard_threshold: value,
			notifications,
			is_check_amount,
		});
		setEditingMode(false);
	};

	return (
		<form className={styles.editControlWrapper} ref={editControlRef} onSubmit={submitHandler}>
			<TextInput
				name="threshold"
				value={value.toString()}
				onChange={inputHandler}
				noError
				data-test-id=""
				dataTestId="threshold"
				className={styles.input}
			/>
			<div className={styles.buttons}>
				<button
					className={styles.confirm}
					data-test-id="submitTresholdButton"
					disabled={initialValue === value || actionsContext.updateSettingsAPI.isLoading}
					type="submit"
				>
					<SubmitIconSVG />
				</button>
				<button
					type="button"
					className={styles.cancel}
					onClick={cancelEditingHandler}
					data-test-id="cancelTresholdButton"
				>
					<CancelIconSVG />
				</button>
			</div>
		</form>
	);
};

export default Threshold;
