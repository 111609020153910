import { useTranslation } from "pay-kit";
import RoutedTabs, { RoutedTab } from "components/RoutedTabs";
import { Outlet } from "react-router-dom";
import PageTitle from "src/components/PageTitle";

import styles from "./styles.module.scss";

export default function ConstructorPage() {
	const {t} = useTranslation();

	const tabs: RoutedTab[] = [
		{ value: "/payment-systems", label: t("Payment systems") },
		{ value: "/payment-systems/tariffs", label: t("Tariffs") },
		{ value: "/payment-systems/translates", label: t("Translations") },
		{ value: "/payment-systems/plugin", label: t("Plugin") },
	];

	return (
		<div className={styles.panel}>
			<PageTitle title={t("Constructor")} />
			<RoutedTabs tabs={tabs} />
			<Outlet />
		</div>
	);
}
