import { FormTypesItemType, TranslationHintType } from "api/translationsGroup";

export const prepareNavTreeData = (
	translations: readonly TranslationHintType[],
	formTypes: readonly FormTypesItemType[]
) => {
	const names = translations.map((t) => t.name);
	const uniqueNames = [...new Set(names)];

	return uniqueNames
		.map((name) => {
			const items = translations.filter((t) => t.name === name);
			const isDefault = items.length > 0 && items[0].is_default === true;

			const groupedByFormTypes = formTypes.map((formType) => ({
				formType,
				hint: items.filter((item) => item.form_type === formType.type)[0],
			}));

			return {
				name,
				formTypes: groupedByFormTypes.filter((group) => group.hint !== undefined),
				isDefault,
			};
		})
		.sort((a, b) => {
			if (a.isDefault === b.isDefault) {
				return 0;
			} else if (a.isDefault === true) {
				return -1;
			}

			return 1;
		});
};
