// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ActiveFilter__activeFilter-Xu8{display:inline-flex;justify-content:flex-start;align-items:center;height:32px;padding:4px 5px 4px 10px;gap:8px;background:#fcfcfd;border-radius:8px;font-weight:400;line-height:20px;color:#697077}.ActiveFilter__activeFilter-Xu8 .ActiveFilter__label-nw8{font-size:12px}.ActiveFilter__activeFilter-Xu8 .ActiveFilter__value-gsb{font-size:14px}.ActiveFilter__activeFilter-Xu8 button{height:16px;width:16px;border:0;background:rgba(0,0,0,0);padding:0;cursor:pointer}.ActiveFilter__activeFilter-Xu8 button:hover{opacity:.8}`, "",{"version":3,"sources":["webpack://./src/components/Filter/ActiveFilters/ActiveFilter/ActiveFilter.module.scss"],"names":[],"mappings":"AAAA,gCACC,mBAAA,CACA,0BAAA,CACA,kBAAA,CACA,WAAA,CACA,wBAAA,CACA,OAAA,CAEA,kBAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CAEA,aAAA,CAEA,yDACC,cAAA,CAGD,yDACC,cAAA,CAGD,uCACC,WAAA,CACA,UAAA,CACA,QAAA,CACA,wBAAA,CACA,SAAA,CACA,cAAA,CACA,6CACC,UAAA","sourcesContent":[".activeFilter {\n\tdisplay: inline-flex;\n\tjustify-content: flex-start;\n\talign-items: center;\n\theight: 32px;\n\tpadding: 4px 5px 4px 10px;\n\tgap: 8px;\n\t/* Blue_gray/5 */\n\tbackground: #fcfcfd;\n\tborder-radius: 8px;\n\tfont-weight: 400;\n\tline-height: 20px;\n\t/* Blue_gray/70 */\n\tcolor: #697077;\n\n\t.label {\n\t\tfont-size: 12px;\n\t}\n\n\t.value {\n\t\tfont-size: 14px;\n\t}\n\n\tbutton {\n\t\theight: 16px;\n\t\twidth: 16px;\n\t\tborder: 0;\n\t\tbackground: transparent;\n\t\tpadding: 0;\n\t\tcursor: pointer;\n\t\t&:hover {\n\t\t\topacity: 0.8;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"activeFilter": `ActiveFilter__activeFilter-Xu8`,
	"label": `ActiveFilter__label-nw8`,
	"value": `ActiveFilter__value-gsb`
};
export default ___CSS_LOADER_EXPORT___;
