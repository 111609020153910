// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScreenshotModal__ScreenshotModalBody-oAw{display:flex;gap:16px;width:100%;justify-content:space-between}.ScreenshotModal__ScreenshotModalBody-oAw .ScreenshotModal__label-xmq{font-weight:400;font-size:14px;line-height:20px;color:#697077}.ScreenshotModal__ScreenshotModalBody-oAw img{max-width:400px;width:100%}`, "",{"version":3,"sources":["webpack://./src/modules/Transactions/PossibleTransactions/ActionsProvider/ScreenshotModal/ScreenshotModal.module.scss"],"names":[],"mappings":"AAAA,0CACI,YAAA,CACA,QAAA,CACA,UAAA,CACA,6BAAA,CAEA,sEACI,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,aAAA,CAGJ,8CACI,eAAA,CACA,UAAA","sourcesContent":[".ScreenshotModalBody {\n    display: flex;\n    gap: 16px;\n    width: 100%;\n    justify-content: space-between;\n\n    .label {\n        font-weight: 400;\n        font-size: 14px;\n        line-height: 20px;\n        /* Blue_gray/70 */\n        color: #697077;\n    }\n\n    img {\n        max-width: 400px;\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ScreenshotModalBody": `ScreenshotModal__ScreenshotModalBody-oAw`,
	"label": `ScreenshotModal__label-xmq`
};
export default ___CSS_LOADER_EXPORT___;
