import { t, useTranslation } from "pay-kit";
import API from "api";
import { postbackType } from "api/postbackGroup";
import { Modal } from "pay-kit";
import React, { useState } from "react";

import ConfirmationModal from "./ConfirmationModal";
import DetailedRequest from "./DetailedRequest";

export const PostbacksActionsContext = React.createContext({
	showResponseDetails: (id: postbackType["id"]) => {},
	resetCountFailures: (ids: readonly postbackType["id"][]) => {},
	resetCountFailure: (ids: readonly postbackType["id"][]) => {},
	markAsSentIds: (ids: readonly postbackType["id"][]) => {},
	markAsSentId: (ids: readonly postbackType["id"][]) => {},
	forceSend: (id: postbackType["id"]) => {},
});

type PostbacksActionsProviderPropsType = {
	readonly children: React.ReactNode;
	readonly postbacks: readonly postbackType[];
	readonly onSuccess: () => void;
};

const PostbacksActionsProvider = ({ children, postbacks, onSuccess }: PostbacksActionsProviderPropsType) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [responseDetailsId, setResponseDetailsId] = useState<postbackType["id"] | undefined>(undefined);
	const [resetCountFailureIds, setResetCountFailureIds] = useState<readonly postbackType["id"][] | undefined>(
		undefined
	);
	const [resetCountFailureId, setResetCountFailureId] = useState<readonly postbackType["id"][] | undefined>(undefined);
	const [markAsSentIds, setMarkAsSentIds] = useState<readonly postbackType["id"][] | undefined>();
	const [markAsSentId, setMarkAsSentId] = useState<readonly postbackType["id"][] | undefined>();
	const [forceSendId, setForceSendId] = useState<postbackType["id"] | undefined>(undefined);

	const foundPostbackForDetailedView = postbacks.find((postback) => postback.id === responseDetailsId);

	const sendResetCountFailureRequest = () => {
		if (resetCountFailureIds === undefined && resetCountFailureId === undefined) return;

		setIsLoading(true);

		const idsToSend = resetCountFailureIds || resetCountFailureId;

		API.postback
			.resetCountFailure(idsToSend)
			.then((res) => {
				onSuccess();
				// window.pushAlert({ description: "Счетчик попыток успешно сброшен", type: "success" });
				window.pushAlert({ description: t("Counter successfully reset"), type: "success" });
				setResetCountFailureIds(undefined);
				setResetCountFailureId(undefined);

				return res;
			})
			.finally(() => setIsLoading(false))
			.catch(() => {
				// window.pushAlert({ description: "Произошла ошибка при попытке сброса счетчика", type: "error" });
				window.pushAlert({ description: t("Failed to reset counter"), type: "error" });
			});
	};

	const sendMarkAsSentRequest = (ids: readonly postbackType["id"][] | undefined) => {
		if (ids === undefined) return;

		setIsLoading(true);

		API.postback
			.markAsSent(ids)
			.then((res) => {
				onSuccess();
				// window.pushAlert({ description: "Постбeк успешно помечен как отправленный", type: "success" });
				window.pushAlert({ description: t("Postback successfully marked as sent"), type: "success" });
				setMarkAsSentIds(undefined);
				setMarkAsSentId(undefined);

				return res;
			})
			.finally(() => setIsLoading(false))
			.catch(() => {
				// window.pushAlert({ description: "Не удалось отметить постбeк как отправленный", type: "error" });
				window.pushAlert({ description: t("Failed to mark postback as sent"), type: "error" });
			});
	};

	const sendForceSendRequest = () => {
		if (forceSendId === undefined) return;

		setIsLoading(true);

		API.postback
			.forceSend(forceSendId)
			.then((res) => {
				onSuccess();
				// window.pushAlert({ description: "Постбeк успешно переслан", type: "success" });
				window.pushAlert({ description: t("Postback successfully sent"), type: "success" });
				setForceSendId(undefined);

				return res;
			})
			.finally(() => setIsLoading(false))
			.catch(() => {
				// window.pushAlert({ description: "Не удалось переслать постбeк", type: "error" });
				window.pushAlert({ description: t("Failed to send postback"), type: "error" });
			});
	};

	return (
		<PostbacksActionsContext.Provider
			value={{
				showResponseDetails: setResponseDetailsId,
				resetCountFailure: setResetCountFailureId,
				resetCountFailures: setResetCountFailureIds,
				markAsSentIds: setMarkAsSentIds,
				markAsSentId: setMarkAsSentId,
				forceSend: setForceSendId,
			}}
		>
			{/* <Modal isOpen={responseDetailsId !== undefined} title="Постбeк" onClose={() => setResponseDetailsId(undefined)}> */}
			<Modal
				isOpen={responseDetailsId !== undefined}
				title={t("Postback")}
				onClose={() => setResponseDetailsId(undefined)}
			>
				<DetailedRequest
					request={foundPostbackForDetailedView?.request_body}
					onClose={() => setResponseDetailsId(undefined)}
				/>
			</Modal>

			<Modal
				isOpen={resetCountFailureIds !== undefined}
				title={t("Reset attempt counter")}
				onClose={() => setResetCountFailureIds(undefined)}
			>
				<ConfirmationModal
					// text="Вы уверены, что хотите сбросить счетчик попыток отправки у выбранных постбеков?"
					text={t("Are you sure you want to reset the attempt counter for the selected postbacks?")}
					onConfirm={sendResetCountFailureRequest}
					onClose={() => setResetCountFailureIds(undefined)}
					confirmText={t("Reset")}
					isLoading={isLoading}
				/>
			</Modal>

			<Modal
				isOpen={resetCountFailureId !== undefined}
				title={t("Reset attempt counter")}
				onClose={() => setResetCountFailureId(undefined)}
			>
				<ConfirmationModal
					// text="Вы уверены, что хотите сбросить счетчик попыток отправки постбека?"
					text={t("Are you sure you want to reset the attempt counter for the postback?")}
					onConfirm={sendResetCountFailureRequest}
					onClose={() => setResetCountFailureId(undefined)}
					confirmText={t("Reset")}
					isLoading={isLoading}
				/>
			</Modal>

			<Modal
				isOpen={markAsSentIds !== undefined}
				// title="Отметить постбeки как отправленные"
				title={t("Mark postbacks as sent")}
				onClose={() => setMarkAsSentIds(undefined)}
			>
				<ConfirmationModal
					// text="Вы уверены, что хотите отметить выбранные постбeки как успешно отправленные?"
					text={t("Are you sure you want to mark the selected postbacks as sent?")}
					onConfirm={() => sendMarkAsSentRequest(markAsSentIds)}
					onClose={() => setMarkAsSentIds(undefined)}
					// confirmText="Отметить постбeк"
					confirmText={t("Mark postback as sent")}
					isLoading={isLoading}
				/>
			</Modal>

			<Modal
				isOpen={markAsSentId !== undefined}
				title={t("Mark postback as sent")}
				onClose={() => setMarkAsSentId(undefined)}
			>
				<ConfirmationModal
					// text="Вы уверены, что хотите отметить постбек как успешно отправленный?"
					// TODO: этой локали почему то нет в словаре
					text={t("Are you sure you want to mark the postback as successfully sent?")}
					onConfirm={() => sendMarkAsSentRequest(markAsSentId)}
					onClose={() => setMarkAsSentId(undefined)}
					// confirmText="Отметить постбeк"
					confirmText={t("Mark postback as sent")}
					isLoading={isLoading}
				/>
			</Modal>

			<Modal
				isOpen={forceSendId !== undefined}
				// title={"Переслать постбeк"}
				title={t("Resend postback")}
				onClose={() => setForceSendId(undefined)}
			>
				<ConfirmationModal
					// text="Вы уверены, что хотите переслать постбeк?"
					text={t("Are you sure you want to resend the postback?")}
					onConfirm={sendForceSendRequest}
					onClose={() => setForceSendId(undefined)}
					// confirmText="Переслать постбeк"
					confirmText={t("Resend postback")}
					isLoading={isLoading}
				/>
			</Modal>
			{children}
		</PostbacksActionsContext.Provider>
	);
};

export default PostbacksActionsProvider;
