import { useTranslation } from "pay-kit";
import { Button } from "pay-kit";
import { FC } from "react";

import styles from "./postbackConfirmation.module.scss";

type PostbackConfirmationType = {
	readonly onConfirm: () => void;
	readonly onClose: () => void;
};
const PostbackConfirmation: FC<PostbackConfirmationType> = ({ onClose, onConfirm }) => {
	const { t } = useTranslation();

	const handleSend = () => {
		onClose();
		onConfirm();
	};

	return (
		<div className={styles.postbackConfirmation}>
			{/* <div className={styles.postbackButtonsTitle}>Вы действительно хотите отправить постбeк?</div> */}
			<div className={styles.postbackButtonsTitle}>{t("Are you sure you want to send a postback?")}</div>
			<div className={styles.postbackButtonsContainer}>
				{/* <Button data-test-id="cancel" onClick={onClose} variant="secondary">Отмена</Button> */}
				<Button data-test-id="cancel" onClick={onClose} variant="secondary">
					{t("Cancel")}
				</Button>
				<Button data-test-id="send" onClick={handleSend}>
					{t("Send")}
				</Button>
			</div>
		</div>
	);
};

export default PostbackConfirmation;
