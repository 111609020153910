// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ShowBalances__accountsWrapper-cFe{display:inline-block;color:#21272a;font-size:14px;padding:8px;width:149px;text-overflow:ellipsis}.ShowBalances__accountsWrapper-cFe .ShowBalances__accountsBalance-COu{display:inline-flex;text-decoration:none;color:initial}.ShowBalances__accountsWrapper-cFe .ShowBalances__accountsCurrencyCode-tki{margin-right:4px;color:#697077}.ShowBalances__accountsWrapper-cFe:not(:nth-child(6n)){margin-right:36px}.ShowBalances__buttonText-Rc6{display:block;background:initial;border:initial;color:#0072c3;margin-top:14px;cursor:pointer}.ShowBalances__buttonText-Rc6:hover{color:#00539a}.ShowBalances__caretWrapper-sPk{padding:0 6px}`, "",{"version":3,"sources":["webpack://./src/modules/Balance/components/ShowBalances/showBalances.module.scss"],"names":[],"mappings":"AAAA,mCACC,oBAAA,CACA,aAAA,CACA,cAAA,CACA,WAAA,CACA,WAAA,CACA,sBAAA,CAEA,sEACC,mBAAA,CACA,oBAAA,CACA,aAAA,CAGD,2EACC,gBAAA,CACA,aAAA,CAIF,uDACC,iBAAA,CAGD,8BACC,aAAA,CACA,kBAAA,CACA,cAAA,CACA,aAAA,CACA,eAAA,CACA,cAAA,CAGD,oCACC,aAAA,CAGD,gCACC,aAAA","sourcesContent":[".accountsWrapper {\n\tdisplay: inline-block;\n\tcolor: #21272A;\n\tfont-size: 14px;\n\tpadding: 8px;\n\twidth: 149px;\n\ttext-overflow: ellipsis;\n\n\t.accountsBalance {\n\t\tdisplay: inline-flex;\n\t\ttext-decoration: none;\n\t\tcolor: initial;\n\t}\n\n\t.accountsCurrencyCode {\n\t\tmargin-right: 4px;\n\t\tcolor: #697077;\n\t}\n}\n\n.accountsWrapper:not(:nth-child(6n)) {\n\tmargin-right: 36px;\n}\n\n.buttonText {\n\tdisplay: block;\n\tbackground: initial;\n\tborder: initial;\n\tcolor: #0072C3;\n\tmargin-top: 14px;\n\tcursor: pointer;\n}\n\n.buttonText:hover {\n\tcolor: #00539A;\n}\n\n.caretWrapper {\n\tpadding: 0 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accountsWrapper": `ShowBalances__accountsWrapper-cFe`,
	"accountsBalance": `ShowBalances__accountsBalance-COu`,
	"accountsCurrencyCode": `ShowBalances__accountsCurrencyCode-tki`,
	"buttonText": `ShowBalances__buttonText-Rc6`,
	"caretWrapper": `ShowBalances__caretWrapper-sPk`
};
export default ___CSS_LOADER_EXPORT___;
