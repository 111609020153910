import { WalletTypeType } from "api/walletGroup";
import { Roles } from "contexts/AuthContext/utils/enums";
import RecoverIcon from "modules/Wallets/ArchivedWallets/components/Actions/icons/RecoverIcon";
import { ArchivedWalletContext } from "modules/Wallets/contexts/ArchivedWalletContext";
import { useTranslation } from "pay-kit";
import { Button } from "pay-kit";
import React, { useContext } from "react";
import RoleContext from "src/contexts/AuthContext";

import styles from "./Actions.module.scss";

type ActionsProps = {
	readonly hash_id: WalletTypeType[`hash_id`];
};

const Actions = ({ hash_id }: ActionsProps) => {
	const { openRecoverWalletModal, openDeleteWalletModal } = useContext(ArchivedWalletContext);
	const { hasRole } = useContext(RoleContext);
	const { t } = useTranslation();

	const handleRecover = (e: React.MouseEvent) => {
		e.stopPropagation();
		openRecoverWalletModal(hash_id);
	};

	const handleDelete = (e: React.MouseEvent) => {
		e.stopPropagation();
		openDeleteWalletModal(hash_id);
	};

	return (
		<div className={styles.wrapper}>
			{hasRole(Roles.WALLET_DELETE) && (
				<Button data-test-id="restoreButton" onClick={handleRecover} dataTestId="restoreButton">
					<RecoverIcon />
					<div className={styles.label}>{t("Recovery")}</div>
				</Button>
			)}
			{hasRole(Roles.WALLET_HARD_DELETE) && (
				<Button data-test-id="removeButton" variant="delete" onClick={handleDelete} dataTestId="removeButton">
					{t("Delete")}
				</Button>
			)}
		</div>
	);
};

export default Actions;
