// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmptyPlaceholder__placeholder-BrI{display:flex;flex-direction:row;justify-content:flex-end;align-items:flex-end}.EmptyPlaceholder__placeholder-BrI div:first-child{display:flex;flex-direction:column;justify-content:center;align-items:center}.EmptyPlaceholder__placeholder-BrI div:first-child .EmptyPlaceholder__caption-Ogn{display:inline-block;position:relative;margin-top:36px;font-weight:400;font-size:16px;line-height:24px;display:flex;align-items:center;color:#697077}`, "",{"version":3,"sources":["webpack://./src/modules/WalletsLoad/components/EmptyPlaceholder/EmptyPlaceholder.module.scss"],"names":[],"mappings":"AAAA,mCACI,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,oBAAA,CAEA,mDACI,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CAEA,kFACI,oBAAA,CACA,iBAAA,CACA,eAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,YAAA,CACA,kBAAA,CAEA,aAAA","sourcesContent":[".placeholder {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-end;\n    align-items: flex-end;\n\n    & div:first-child {\n        display: flex;\n        flex-direction: column;\n        justify-content: center;\n        align-items: center;\n\n        .caption {\n            display: inline-block;\n            position: relative;\n            margin-top: 36px;\n            font-weight: 400;\n            font-size: 16px;\n            line-height: 24px;\n            /* identical to box height, or 150% */\n            display: flex;\n            align-items: center;\n            /* Blue_gray/70 */\n            color: #697077;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"placeholder": `EmptyPlaceholder__placeholder-BrI`,
	"caption": `EmptyPlaceholder__caption-Ogn`
};
export default ___CSS_LOADER_EXPORT___;
