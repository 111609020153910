import { IPossibleTransaction, PossibleTransactionStatusType } from "api/transactionGroup";
import { useTranslation } from "pay-kit";

import { ActionsContext } from "../ActionsProvider";
import styles from "./Actions.module.scss";
import AcceptIconSVG from "./icons/accept.svg";
import DetailsIconSVG from "./icons/details.svg";
import RemoveIconSVG from "./icons/remove.svg";
import { useAuthContext } from "contexts/AuthContext/AuthContext";
import { Roles } from "contexts/AuthContext";

const Actions = ({
	status,
	primary_id,
	screenshot,
	is_manual,
}: Pick<IPossibleTransaction, "status" | "primary_id" | "screenshot" | "is_manual">) => {
	const { t } = useTranslation();
	const { hasRole } = useAuthContext();

	return (
		<ActionsContext.Consumer>
			{({ showScreenshot, removeTransaction, approveTransaction }) => (
				<div data-test-id="_zFvTsPIDN2-8vk3enqjA-" className={styles.actions} onClick={(e) => e.stopPropagation()}>
					{screenshot && (
						<button
							data-test-id="showScreenshotButton"
							className={styles.showScreenshotButton}
							onClick={() => showScreenshot(screenshot)}
						>
							<DetailsIconSVG />
							{t("Transaction")}{" "}
						</button>
					)}
					{is_manual === 1 && status === PossibleTransactionStatusType.NEW && (
						<button
							data-test-id="approveButton"
							className={styles.approveButton}
							onClick={() => approveTransaction(primary_id)}
						>
							<AcceptIconSVG />
							{t("Approve")}
						</button>
					)}
					{status === PossibleTransactionStatusType.NEW && hasRole(Roles.TRANSACTION_DELETE) && (
						<button
							data-test-id="removeButton"
							className={styles.removeButton}
							onClick={() => removeTransaction(primary_id)}
						>
							<RemoveIconSVG />
							{t("Delete")}{" "}
						</button>
					)}
				</div>
			)}
		</ActionsContext.Consumer>
	);
};

export default Actions;
