import "./RefreshBlock.sass";

import { PlusIcon } from "components/Icons/PlusIcon/PlusIcon";
import { RefreshIcon } from "components/Icons/RefreshIcon";
import { Roles, useHasAccessHook } from "contexts/AuthContext";
import { Button, useTranslation } from "pay-kit";

export type RefreshBlockProps = {
	readonly addBtnLabel: string;
	readonly onAddClick: () => void;
	readonly onRefreshClick: () => void;
};

export const RefreshBlock = ({ addBtnLabel, onAddClick, onRefreshClick }: RefreshBlockProps) => {
	const { t } = useTranslation();
	return (
		<div className="refresh__wrapper">
			<div>
				{useHasAccessHook({ rule: Roles.PROXY_CREATE }) && (
					<Button data-test-id="utPFmhErEvdNo1tRc9xHo" onClick={onAddClick} variant="primary">
						<PlusIcon />
						<span
							style={{
								marginLeft: "8px",
							}}
						>
							{addBtnLabel}
						</span>
					</Button>
				)}
			</div>
			<Button data-test-id="dWuq5cY_1HvD4SHE3PAGq" variant="text-primary" onClick={onRefreshClick}>
				<RefreshIcon />
				<span
					style={{
						marginLeft: "8px",
					}}
				>
					{t("Refresh")}
				</span>
			</Button>
		</div>
	);
};
