import { useTranslation } from "pay-kit";
import Close from "assets/icons/encircledCross.svg";
import Magnifier from "assets/icons/magnifier.svg";
import { ChangeEvent, FC } from "react";

import styles from "./searchBar.module.scss";

type SearchBarType = {
	readonly onChange: (e: ChangeEvent<HTMLInputElement>) => void
	readonly value: string | undefined
	readonly resetIcon?: boolean
	readonly resetValue?: () => void
	readonly classNames?: {
		readonly wrapperClass?: string
		readonly inputClass?: string
	}
}

const SearchBar: FC<SearchBarType> = ({
	classNames,
	onChange,
	resetIcon,
	resetValue = () => ({}),
	value
}) => {
	const { t } = useTranslation();

	const inputIcon = value && resetIcon ?
		<span data-test-id="Mtm1ZFEwz9s-UtoMACDvX"
			className={styles.closeButton}
			onClick={resetValue}>
			<Close />
		</span> :
		<Magnifier />;

	return (
		<div
			data-test-id="_w-FxLm_nQ2F7jEzz_qOh"
			className={[styles.searchBar, classNames?.wrapperClass].join(" ")}
		>
			<input
				data-test-id="_w-FxLm_nQ2csgfjhszz_qOh"
				className={classNames?.inputClass}
				placeholder={t("Submit")}
				onChange={onChange}
				value={value || ""}
			/>
			{inputIcon}
		</div>
	);
};

export default SearchBar;
