import { t } from "@paykassma/pay-kit";
import API from "api";
import { useState } from "react";

type useForceSendPostbackArgType = {
	readonly onSuccess?: () => void;
	readonly onError?: (err: unknown) => void;
};

type useForceSendPostbackType = (arg: useForceSendPostbackArgType) => {
	readonly sendForceSendRequest: (forceSendId: number) => void;
	readonly isLoading: boolean;
};

const useForceSendPostback: useForceSendPostbackType = ({ onSuccess, onError }) => {
	const [isLoading, setIsLoading] = useState(false);

	const sendForceSendRequest = (forceSendId: number) => {
		setIsLoading(true);

		API.postback
			.forceSend(forceSendId)
			.then((res) => {
				if (res.status === "success") {
					onSuccess && onSuccess();
					// window.pushAlert({ description: "Постбeк успешно переслан", type: "success" });
					window.pushAlert({ description: t("Postback successfully sent"), type: "success" });

					return res;
				}

				onError && onError(res);
				// throw new Error("Не удалось переслать постбeк");
				throw new Error(t("Failed to send postback"));
			})
			.finally(() => {
				setIsLoading(false);
			})
			.catch(() => {
				// window.pushAlert({ description: "Не удалось переслать постбeк", type: "error" });
				window.pushAlert({ description: t("Failed to send postback"), type: "error" });
			});
	};

	return {
		sendForceSendRequest,
		isLoading,
	};
};

export default useForceSendPostback;
