import EditPen from "assets/icons/editPen.svg";
import Relaunch from "assets/icons/relaunch.svg";
import DisabledRelaunch from "assets/icons/relaunchDisabled.svg";
import WorkingRelaunch from "assets/icons/relaunchWorking.svg";
import TrashBin from "assets/icons/trashBin.svg";
import { Roles, useHasAccessHook } from "contexts/AuthContext";
import { Button, Loader } from "pay-kit";
import { FC, ReactElement } from "react";

import styles from "./manipulateActions.module.scss";

const ManipulateActions: FC<ManipulateActionsType> = ({
	className,
	classNameGroup,
	onRelaunch,
	onEdit,
	onDelete,
	isRelaunchLoading = false,
	allButtonsDisabled = false,
	relaunchIconType,
}) => {
	const buttonsStyle = allButtonsDisabled
		? [styles.actionsColButton, styles.loadingAction].join(" ")
		: styles.actionsColButton;
	const relaunchStyle = ["NOT_WORKING", "WORKING"].includes(relaunchIconType || "")
		? styles.relaunchDisabled
		: styles.relaunch;

	let relaunchIcon: ReactElement;
	switch (relaunchIconType) {
		case "NOT_WORKING":
			relaunchIcon = <DisabledRelaunch />;
			break;
		case "WORKING":
			relaunchIcon = <WorkingRelaunch />;
			break;
		default:
			relaunchIcon = <Relaunch />;
	}

	const relaunchButton = <span className={[relaunchStyle, classNameGroup?.relaunch].join(" ")}>{relaunchIcon}</span>;

	const loader = (
		<span className={styles.loader}>
			<Loader color="#A2A9B0" />
		</span>
	);

	return (
		<div className={[styles.actionsColButtons, className].join(" ")}>
			{useHasAccessHook({ rule: Roles.PROXY_CHECK }) && (
				<Button
					data-test-id="X1Noi2i2I2-jsBOtQzy6S"
					classname={buttonsStyle}
					onClick={() => onRelaunch && onRelaunch()}
					disabled={allButtonsDisabled}
					dataTestId="relaunchButton"
				>
					{isRelaunchLoading ? loader : relaunchButton}
				</Button>
			)}
			{useHasAccessHook({ rule: Roles.PROXY_UPDATE }) && (
				<Button
					data-test-id="cOEGPHAnPjgOxs7M8dV8I"
					classname={buttonsStyle}
					onClick={() => onEdit && onEdit()}
					disabled={allButtonsDisabled}
					dataTestId="editButton"
				>
					<span className={[styles.edite, classNameGroup?.edit].join(" ")}>
						<EditPen />
					</span>
				</Button>
			)}
			{useHasAccessHook({ rule: Roles.PROXY_DELETE }) && (
				<Button
					data-test-id="SmM3RXNFLRYlE7XKSGmVA"
					classname={buttonsStyle}
					onClick={() => onDelete && onDelete()}
					disabled={allButtonsDisabled}
					dataTestId="removeButton"
				>
					<span className={[styles.delete, classNameGroup?.delete].join(" ")}>
						<TrashBin />
					</span>
				</Button>
			)}
		</div>
	);
};

export default ManipulateActions;

type ManipulateActionsType = {
	readonly className?: string;
	readonly classNameGroup?: {
		readonly relaunch?: string;
		readonly edit?: string;
		readonly delete?: string;
	};
	readonly onRelaunch?: () => void;
	readonly onEdit?: () => void;
	readonly onDelete?: () => void;
	readonly isRelaunchLoading?: boolean;
	readonly relaunchIconType?: "NOT_WORKING" | "WORKING" | "NOT_CHECKED";
	readonly allButtonsDisabled?: boolean;
};
