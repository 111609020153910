import API from "api";
import {
	ChartDataRequestParams,
	LoadTransactionMetricsType,
	LoadTransactionRecordType,
	UpdateTransactionMetricsParamsType,
} from "api/dashboardGroup";
import { LoadWalletsSummaryType } from "api/walletsGroup";
import AuthContext from "contexts/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import { errorsMap } from "utils/enums";

const DashboardContext = createContext({} as DashboardContextType);

const DashboardContextProvider = ({ children }: { readonly children: ReactNode }) => {
	const [error, setError] = useState<Error | null>(null);
	const [isTransactionMetricsLoading, setIsTransactionMetricsLoading] = useState<boolean>(false);
	const [sumOfActivatedTransactions, setSumOfActivatedTransactions] = useState<DashboardContextType["sumOfActivatedTransactions"] | null>(null);
	const [sumOfActivatedTransactionsToDay, setSumOfActivatedTransactionsToDay] = useState<DashboardContextType["sumOfActivatedTransactionsToDay"] | null>(null);
	const [sumOfCompletedTransactions, setSumOfCompletedTransactions] = useState<DashboardContextType["sumOfCompletedTransactions"] | null>(null);
	const [sumOfTransactionsCommission, setSumOfTransactionsCommission] = useState<DashboardContextType["sumOfTransactionsCommission"] | null>(null);
	const [sumOfTransactionsCommissionToDay, setSumOfTransactionsCommissionToDay] = useState<DashboardContextType["sumOfTransactionsCommissionToDay"] | null>(null);
	const [sumOfCompletedTransactionsCommission, setSumOfCompletedTransactionsCommission] = useState<DashboardContextType["sumOfCompletedTransactionsCommission"] | null>(null);
	const [activatedTransactionsCount, setActivatedTransactionsCount] = useState<DashboardContextType["activatedTransactionsCount"] | null>(null);
	const [activatedTransactionsCountToDay, setActivatedTransactionsCountToDay] = useState<DashboardContextType["activatedTransactionsCountToDay"] | null>(null);
	const [completedTransactionsCount, setCompletedTransactionsCount] = useState<DashboardContextType["completedTransactionsCount"] | null>(null);
	const [transactionRestMetrics, setTransactionRestMetrics] = useState<DashboardContextType["transactionRestMetrics"] | null>(null);
	const [walletsSummary, setWalletsSummary] = useState<readonly LoadWalletsSummaryType[]>([]);
	const [isLoadWalletsSummaryLoading, setIsLoadWalletsSummaryLoading] = useState(false);
	const [checkedPS, setCheckedPS] = useState<checkedPSType>({});
	const [filterState, setFilterState] = useState<ChartDataRequestParams | undefined>(undefined);

	const updateTransactionMetrics = (params: UpdateTransactionMetricsParamsType) => {
		setError(null);
		setIsTransactionMetricsLoading(true);
		setFilterState(params);

		API.dashboard
			.updateTransactionMetrics(params)
			.then((res) => {
				if (res.status === "ok") {
					const {
						sum_of_activated_transactions,
						sum_of_activated_transactions_today,
						sum_of_withdrawals,
						sum_of_withdrawals_for_today,
						sum_of_transactions_commission,
						sum_of_transactions_commission_today,
						sum_of_withdrawals_commission,
						sum_of_withdrawals_commission_today,
						sum_of_settlement_commission,
						sum_of_settlement_commission_today,
						activated_transactions_count,
						activated_transactions_count_today,
						activated_withdrawals_count,
						activated_withdrawals_count_today,
						completed_transactions_count,
						completed_transactions_count_today,
						sum_of_completed_transactions,
						sum_of_completed_transactions_today,
						sum_of_completed_transactions_commission,
						sum_of_completed_transactions_commission_today,
						...rest
					} = res.data;

					setTransactionRestMetrics(rest);

					setSumOfActivatedTransactions({
						sum_of_activated_transactions,
						sum_of_withdrawals,
					});

					setSumOfActivatedTransactionsToDay({
						sum_of_activated_transactions_today,
						sum_of_withdrawals_for_today,
					});

					const sumOfCompletedTransactions = params.stockpiling_status === 2 ? { sum_of_completed_transactions, sum_of_completed_transactions_today, } : null;

					setSumOfCompletedTransactions(sumOfCompletedTransactions);

					setSumOfTransactionsCommission({
						sum_of_transactions_commission,
						sum_of_withdrawals_commission,
						sum_of_settlement_commission,
					});

					setSumOfTransactionsCommissionToDay({
						sum_of_transactions_commission_today,
						sum_of_withdrawals_commission_today,
						sum_of_settlement_commission_today,
					});

					const sumOfCompletedTransactionsCommission = params.stockpiling_status === 2 ? { sum_of_completed_transactions_commission, sum_of_completed_transactions_commission_today, } : null;

					setSumOfCompletedTransactionsCommission(sumOfCompletedTransactionsCommission);

					setActivatedTransactionsCount({
						activated_transactions_count,
						activated_withdrawals_count,
					});
					setActivatedTransactionsCountToDay({
						activated_transactions_count_today,
						activated_withdrawals_count_today,
					});

					const completedTransactionsCount = params.stockpiling_status === 2 ? { completed_transactions_count, completed_transactions_count_today } : null;

					setCompletedTransactionsCount(completedTransactionsCount);
					return res;
				} else {
					// throw new Error("Unexpected response in loadTransactionMetrics");
					throw new Error(errorsMap.cantGetMetricsData);
				}
			})
			.finally(() => {
				setIsTransactionMetricsLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setError(err);
			});
	};

	const { hasRole } = useContext(AuthContext);
	const hasSummaryAccess = hasRole(Roles.WALLET_LIST)

	useEffect(() => {
		if (hasSummaryAccess) {
			setError(null);
			setIsLoadWalletsSummaryLoading(true);
	
			API.wallets.loadWalletsSummary().then(
				res => {
					if (res.status === "success") {
						setWalletsSummary(res.data);
					} else {
						// throw new Error("Unexpected response in loadWalletsSummary");
						throw new Error(errorsMap.cantGetLoadSummaryData);
					}
				}
			).catch(err => {
				console.log(err);
				setError(err);
			}).finally(() => {
				setIsLoadWalletsSummaryLoading(false);
			});
		}
	}, [hasSummaryAccess]);

	const contextValue = {
		updateTransactionMetrics,
		filterState,
		setCheckedPS,
		checkedPS,
		sumOfActivatedTransactions,
		sumOfActivatedTransactionsToDay,
		sumOfTransactionsCommission,
		sumOfTransactionsCommissionToDay,
		activatedTransactionsCount,
		activatedTransactionsCountToDay,
		sumOfCompletedTransactions,
		sumOfCompletedTransactionsCommission,
		completedTransactionsCount,
		transactionRestMetrics,
		walletsSummary,
		isLoadWalletsSummaryLoading,
		isTransactionMetricsLoading,
		error,
	};

	return <DashboardContext.Provider value={contextValue}>{children}</DashboardContext.Provider>;
};

export default DashboardContextProvider;

export const useDashboardContext = () => useContext(DashboardContext);

export type DashboardContextType = {
	readonly updateTransactionMetrics: (params: UpdateTransactionMetricsParamsType) => void;
	readonly filterState: ChartDataRequestParams | undefined;
	readonly setCheckedPS: Dispatch<SetStateAction<checkedPSType>>;
	readonly checkedPS: checkedPSType;

	readonly sumOfActivatedTransactions: {
		readonly sum_of_activated_transactions: LoadTransactionRecordType;
		readonly sum_of_withdrawals: LoadTransactionRecordType;
	} | null;

	readonly sumOfCompletedTransactions: {
		readonly sum_of_completed_transactions: LoadTransactionRecordType;
		readonly sum_of_completed_transactions_today: LoadTransactionRecordType;
	} | null;

	readonly sumOfActivatedTransactionsToDay: {
		readonly sum_of_activated_transactions_today: LoadTransactionRecordType;
		readonly sum_of_withdrawals_for_today: LoadTransactionRecordType;
	} | null;

	readonly sumOfTransactionsCommission: {
		readonly sum_of_transactions_commission: LoadTransactionRecordType;
		readonly sum_of_withdrawals_commission: LoadTransactionRecordType;
		readonly sum_of_settlement_commission: LoadTransactionRecordType;
	} | null;

	readonly sumOfTransactionsCommissionToDay: {
		readonly sum_of_transactions_commission_today: LoadTransactionRecordType;
		readonly sum_of_withdrawals_commission_today: LoadTransactionRecordType;
		readonly sum_of_settlement_commission_today: LoadTransactionRecordType;
	} | null;

	readonly sumOfCompletedTransactionsCommission: {
		readonly sum_of_completed_transactions_commission: LoadTransactionRecordType;
		readonly sum_of_completed_transactions_commission_today: LoadTransactionRecordType;
	} | null;

	readonly activatedTransactionsCount: {
		readonly activated_transactions_count: number;
		readonly activated_withdrawals_count: number;
	} | null;

	readonly activatedTransactionsCountToDay: {
		readonly activated_transactions_count_today: number;
		readonly activated_withdrawals_count_today: number;
	} | null;

	readonly completedTransactionsCount: {
		readonly completed_transactions_count: number;
		readonly completed_transactions_count_today: number;
	} | null;

	readonly transactionRestMetrics: {
		readonly active_proxies_count: number;
		readonly currency: LoadTransactionMetricsType["data"]["currency"];
		readonly data: LoadTransactionMetricsType["data"]["data"];
		readonly payment_systems_count: number;
		readonly total_wallets_balance: LoadTransactionRecordType;
	} | null;

	readonly walletsSummary: readonly LoadWalletsSummaryType[] | null;

	readonly isLoadWalletsSummaryLoading: boolean;
	readonly isTransactionMetricsLoading: boolean;
	readonly error: Error | null;
};

type checkedPSType = Partial<{
	readonly [key: number]: {
		readonly isChecked: boolean;
		readonly value: string;
	};
}>;
