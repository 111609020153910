// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Caret__caretWrapper-UGJ{width:max-content}.Caret__caretWrapper-UGJ .Caret__showAllButton-GVW{display:flex;align-items:center;cursor:pointer;color:#0072c3;font-weight:400;font-size:14px;padding:initial;background:initial;border:initial}.Caret__caretWrapper-UGJ .Caret__showAllButton-GVW .Caret__caretUP-z2H{display:flex;justify-content:center;align-items:center;margin-right:10px;transform:rotateX(0deg)}.Caret__caretWrapper-UGJ .Caret__showAllButton-GVW .Caret__caretDOWN-WG8{display:flex;justify-content:center;align-items:center;margin-right:10px;transform:rotateX(180deg)}.Caret__caretWrapper-UGJ .Caret__showAllButton-GVW .Caret__caret-OUU{width:8px}.Caret__caretWrapper-UGJ .Caret__showAllButton-GVW .Caret__caret-OUU>svg{line-height:initial;width:100%;height:100%}`, "",{"version":3,"sources":["webpack://./src/components/Icons/Caret/caret.module.scss"],"names":[],"mappings":"AAQA,yBACC,iBAAA,CAEA,mDACC,YAAA,CACA,kBAAA,CACA,cAAA,CACA,aAAA,CACA,eAAA,CACA,cAAA,CACA,eAAA,CACA,kBAAA,CACA,cAAA,CAEA,uEArBD,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,iBAAA,CAoBE,uBAAA,CAGD,yEA1BD,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,iBAAA,CAyBE,yBAAA,CAGD,qEACC,SAAA,CAEA,yEACC,mBAAA,CACA,UAAA,CACA,WAAA","sourcesContent":["@mixin caret {\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\tmargin-right: 10px;\n}\n\n\n.caretWrapper {\n\twidth: max-content;\n\n\t.showAllButton {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tcursor: pointer;\n\t\tcolor: #0072C3;\n\t\tfont-weight: 400;\n\t\tfont-size: 14px;\n\t\tpadding: initial;\n\t\tbackground: initial;\n\t\tborder: initial;\n\n\t\t.caretUP {\n\t\t\t@include caret;\n\t\t\ttransform: rotateX(0deg);\n\t\t}\n\n\t\t.caretDOWN {\n\t\t\t@include caret;\n\t\t\ttransform: rotateX(180deg);\n\t\t}\n\n\t\t.caret {\n\t\t\twidth: 8px;\n\n\t\t\t& > svg {\n\t\t\t\tline-height: initial;\n\t\t\t\twidth: 100%;\n\t\t\t\theight: 100%;\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"caretWrapper": `Caret__caretWrapper-UGJ`,
	"showAllButton": `Caret__showAllButton-GVW`,
	"caretUP": `Caret__caretUP-z2H`,
	"caretDOWN": `Caret__caretDOWN-WG8`,
	"caret": `Caret__caret-OUU`
};
export default ___CSS_LOADER_EXPORT___;
