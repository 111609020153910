// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Columns__amountsBottomBorder-mz2{padding:8px;border-bottom:1px solid #dde1e6;border-radius:2px;cursor:pointer}.Columns__amountsBottomBorder-mz2:hover{background:#f2f3f5;border-bottom:1px solid #f2f3f5;border-radius:4px}.Columns__amountsBottomBorder-mz2:active{background:#eaecf0;border-bottom:1px solid #eaecf0}`, "",{"version":3,"sources":["webpack://./src/modules/Balance/components/Columns/columns.module.scss"],"names":[],"mappings":"AAAA,kCACC,WAAA,CACA,+BAAA,CACA,iBAAA,CACA,cAAA,CAGD,wCACC,kBAAA,CACA,+BAAA,CACA,iBAAA,CAED,yCACC,kBAAA,CACA,+BAAA","sourcesContent":[".amountsBottomBorder {\n\tpadding: 8px;\n\tborder-bottom: 1px solid #DDE1E6;\n\tborder-radius: 2px;\n\tcursor: pointer;\n}\n\n.amountsBottomBorder:hover {\n\tbackground: #F2F3F5;\n\tborder-bottom: 1px solid #F2F3F5;\n\tborder-radius: 4px;\n}\n.amountsBottomBorder:active {\n\tbackground: #EAECF0;\n\tborder-bottom: 1px solid #EAECF0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"amountsBottomBorder": `Columns__amountsBottomBorder-mz2`
};
export default ___CSS_LOADER_EXPORT___;
