// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchBar__searchBar-xbz{border:1px solid #c5cbd1;border-radius:4px;padding:8px 12px;display:flex;justify-content:center;align-items:center;background:#fff}.SearchBar__searchBar-xbz>input{border:initial;outline:initial;width:169px}.SearchBar__searchBar-xbz ::placeholder{color:#a2a9b0}.SearchBar__searchBar-xbz>svg{margin-left:12px}.SearchBar__searchBar-xbz>.SearchBar__closeButton-TRB{display:flex;align-items:center;margin-left:12px;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/modules/TransferHistory/components/SearchBar/searchBar.module.scss"],"names":[],"mappings":"AAAA,0BACC,wBAAA,CACA,iBAAA,CACA,gBAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,eAAA,CAEA,gCACC,cAAA,CACA,eAAA,CACA,WAAA,CAGD,wCACC,aAAA,CAGD,8BACC,gBAAA,CAGD,sDACC,YAAA,CACA,kBAAA,CACA,gBAAA,CACA,cAAA","sourcesContent":[".searchBar {\n\tborder: 1px solid #C5CBD1;\n\tborder-radius: 4px;\n\tpadding: 8px 12px;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\tbackground: #FFFFFF;\n\n\t& > input {\n\t\tborder: initial;\n\t\toutline: initial;\n\t\twidth: 169px;\n\t}\n\n\t::placeholder {\n\t\tcolor: #A2A9B0;\n\t}\n\n\t& > svg {\n\t\tmargin-left: 12px;\n\t}\n\n\t& > .closeButton {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tmargin-left: 12px;\n\t\tcursor: pointer;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchBar": `SearchBar__searchBar-xbz`,
	"closeButton": `SearchBar__closeButton-TRB`
};
export default ___CSS_LOADER_EXPORT___;
