// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WithdrawalAmount__ingoing-kA6{font-weight:400;font-size:14px;color:#24a148}.WithdrawalAmount__outgoing-QHC{font-weight:400;font-size:14px;color:#dc3545}`, "",{"version":3,"sources":["webpack://./src/modules/TransferHistory/components/OperationHistory/components/WithdrawalAmount/withdrawalAmount.module.scss"],"names":[],"mappings":"AAAA,+BACC,eAAA,CACA,cAAA,CACA,aAAA,CAGD,gCACC,eAAA,CACA,cAAA,CACA,aAAA","sourcesContent":[".ingoing {\n\tfont-weight: 400;\n\tfont-size: 14px;\n\tcolor: #24A148;\n}\n\n.outgoing {\n\tfont-weight: 400;\n\tfont-size: 14px;\n\tcolor: #DC3545;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ingoing": `WithdrawalAmount__ingoing-kA6`,
	"outgoing": `WithdrawalAmount__outgoing-QHC`
};
export default ___CSS_LOADER_EXPORT___;
