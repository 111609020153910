// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LogsList__logListTableWrapper-dMQ .LogsList__logListTable-FEg .LogsList__reloadButton-qEB{border:3px solid red}.LogsList__logListTableWrapper-dMQ .LogsList__paginator-r6M{margin:24px 0}.LogsList__logListModal-WmZ{width:100%;max-width:560px}.LogsList__logsFilters-CxH{margin-top:36px}.LogsList__logsFilters-CxH .LogsList__selectField-ZS0{max-width:180px;width:100%}`, "",{"version":3,"sources":["webpack://./src/modules/WalletsDebugging/LogsList/logsList.module.scss"],"names":[],"mappings":"AAIE,2FACC,oBAAA,CAIF,4DACC,aAAA,CAIF,4BACC,UAAA,CACA,eAAA,CAGD,2BACC,eAAA,CAEA,sDACC,eAAA,CACA,UAAA","sourcesContent":[".logListTableWrapper {\n\n\t.logListTable {\n\n\t\t.reloadButton {\n\t\t\tborder: 3px solid red;\n\t\t}\n\t}\n\n\t.paginator {\n\t\tmargin: 24px 0;\n\t}\n}\n\n.logListModal {\n\twidth: 100%;\n\tmax-width: 560px;\n}\n\n.logsFilters {\n\tmargin-top: 36px;\n\n\t.selectField {\n\t\tmax-width: 180px;\n\t\twidth: 100%;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logListTableWrapper": `LogsList__logListTableWrapper-dMQ`,
	"logListTable": `LogsList__logListTable-FEg`,
	"reloadButton": `LogsList__reloadButton-qEB`,
	"paginator": `LogsList__paginator-r6M`,
	"logListModal": `LogsList__logListModal-WmZ`,
	"logsFilters": `LogsList__logsFilters-CxH`,
	"selectField": `LogsList__selectField-ZS0`
};
export default ___CSS_LOADER_EXPORT___;
