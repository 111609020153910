import { ReduceStateType } from "modules/Proxies/contexts/ProxyContext";

export const setTimeoutPromise: setTimeoutPromiseType = ({ milliseconds, delayedMethod }) => {
	new Promise((resolve) => setTimeout(resolve, milliseconds)).then(delayedMethod);
};

export const proxyCheckingStates = {
	PENDING: "PENDING",
	SUCCEEDED: "SUCCEEDED",
	FAILED: "FAILED",
	FINALLY: "FINALLY",
	RESET: "RESET"
} as const;

export const proxyWorkingStatuses = {
	WORKING: "WORKING",
	NOT_WORKING: "NOT_WORKING"
} as const;

export const reduce: ReduceType = (state, action) => {

	switch (action.type) {
		case "PENDING":
			return ({
				...state,
				[action.payload.uuid]: { ...state[action.payload.uuid], isLoading: true }
			});
		case "SUCCEEDED":
			return ({
				...state,
				[action.payload.uuid]: { ...state[action.payload.uuid], workingStatus: proxyWorkingStatuses.WORKING }
			});
		case "FAILED":
			return ({
				...state,
				[action.payload.uuid]: { ...state[action.payload.uuid], workingStatus: proxyWorkingStatuses.NOT_WORKING }
			});
		case "FINALLY":
			return ({
				...state,
				[action.payload.uuid]: { ...state[action.payload.uuid], isLoading: false }
			});
		case "RESET":
			return ({});
		default:
			return state;
	}
};

type PayloadType = { readonly uuid: string }

type PendingType = {
	readonly type: "PENDING"
	readonly payload: PayloadType
}

type SucceededType = {
	readonly type: "SUCCEEDED"
	readonly payload: PayloadType
}

type FailedType = {
	readonly type: "FAILED"
	readonly payload: PayloadType
}

type FinallyType = {
	readonly type: "FINALLY"
	readonly payload: PayloadType
}

type ResetType = {
	readonly type: "RESET"
}

type ReduceType = (
	proxyCheckingState: ReduceStateType,
	action: PendingType | SucceededType | FailedType | FinallyType | ResetType
) => ReduceStateType

type setTimeoutPromiseType = (params: { readonly milliseconds: number, readonly delayedMethod: () => void }) => void

