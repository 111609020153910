import { proxiesListElemType, useAddingProxiesContext } from "modules/Proxies/contexts/AddingProxiesContext";
import { constructMultiAddingProxiesList } from "modules/Proxies/helpers";
import { Alert, Button, Textarea, useTranslation } from "pay-kit";
import { FC, useState } from "react";

import styles from "./notWorkingProxiesWarning.module.scss";

const formProxiesList: formProxiesListType = (notWorkingProxiesList) => {
	let list = "";
	for (const elem of notWorkingProxiesList) {
		list += elem.inputValue + "\n";
	}

	return list;
};

const NotWorkingProxiesWarning: FC<NotWorkingProxiesWarning> = ({ notWorkingProxiesList, allChangedDataSaved }) => {
	const { proxiesList, initRecheckingMultiAddingProxies, isRecheckingMultiAddingProxiesLoading } =
		useAddingProxiesContext();
	const { t } = useTranslation();

	const [value, setValue] = useState(formProxiesList(notWorkingProxiesList));
	const reconstructedList = constructMultiAddingProxiesList(value);

	const handleOnclick = () => {
		const validList = reconstructedList.filter((elem) => !elem.isFormatValid);
		const fixedProxies = reconstructedList.filter((elem) => elem.isFormatValid).map(({ proxy }) => proxy);
		const proxies = proxiesList.filter((elem) => elem.isFormatValid).map(({ proxy }) => proxy);

		setValue(formProxiesList(validList));

		initRecheckingMultiAddingProxies([...proxies, ...fixedProxies], t(`Data updated`));
	};

	return (
		<div className={styles.notWorkingProxiesWarningWrapper}>
			<div className={styles.proxiesListContentContainer}>
				<div className={styles.invalidFormatMessage}>{t(`Format error`)}</div>

				<div className={styles.NoteOnLaterAction}>
					{t(`The data that failed validation and contain errors. They will not be added in further actions.`)}
				</div>

				<Alert
					className={styles.infoAlertWrapper}
					withIcon={true}
					title={t(`String format - IP:Port:Login:Password:Type:Country_code`)}
					variant="info"
				/>

				<Textarea
					className={styles.notWorkingList}
					onChange={(e) => setValue(e.target.value)}
					value={value}
					rows={10}
				/>

				<Button
					data-test-id="rNBurOBWJNISyvSSXvSCM"
					classname={styles.editButton}
					onClick={handleOnclick}
					disabled={isRecheckingMultiAddingProxiesLoading || allChangedDataSaved}
				>
					{t(`Submit for review`)}
				</Button>
			</div>
		</div>
	);
};

export default NotWorkingProxiesWarning;

type NotWorkingProxiesWarning = {
	readonly notWorkingProxiesList: readonly proxiesListElemType[];
	readonly allChangedDataSaved: boolean;
};

type formProxiesListType = (notWorkingProxiesList: readonly proxiesListElemType[]) => string;
