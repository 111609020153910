import { useTranslation } from "pay-kit";
/* eslint-disable regexp/no-unused-capturing-group */
/* eslint-disable regexp/prefer-d */
/* eslint-disable regexp/no-super-linear-backtracking */
/* eslint-disable react/prop-types */
import { TextInput, useOutsideClick } from "@paykassma/pay-kit";
import { TariffType } from "api/tariffsGroup";
import CancelSVG from "assets/icons/cancelIcon.svg";
import ConfirmSVG from "assets/icons/confirmIcon.svg";
import EditSVG from "assets/icons/edit_24x24.svg";
import TrashSVG from "assets/icons/trash.svg";
import { useRef, useState } from "react";

import useAddCommissionHook from "../../../../hooks/useAddCommission";
import useEditCommissionHook from "../../../../hooks/useEditCommission";
import useRemoveCommissionHook from "../../../../hooks/useRemoveCommission";
import ConfirmationDialog from "../../../ConfirmationDialog";
import { isRequired, isValidValue, maxDigitsAfterDecimal } from "../../validation";
import styles from "./CommissionEditor.module.scss";

type CommissionEditorProps = {
	readonly commission: TariffType["commissions"][number];
	readonly index?: number;
	readonly resetState: () => void;
	readonly tariffId: number;
	readonly canRemove: boolean;
	readonly onSuccess: () => void;
};

const CommissionEditor: React.FC<CommissionEditorProps> = ({
	commission,
	resetState,
	tariffId,
	canRemove,
	onSuccess,
}) => {
	const {t} = useTranslation();
	const [editMode, setEditMode] = useState(commission.id === undefined);
	const [value, setValue] = useState(commission.commission);
	const errorMessage = [isRequired, isValidValue, maxDigitsAfterDecimal(8)].reduce(
		(accum: string | undefined, current) => (accum === undefined ? current(value, {}) : accum),
		undefined
	);
	const commissionEl = useRef(null);
	const [editConfirmationIsVisible, setEditConfirmationVisibility] = useState<boolean>(false);
	const [removeConfirmationIsVisible, setRemoveConfirmationVisibility] = useState<boolean>(false);

	useOutsideClick(commissionEl, () => {
		setEditMode(false);
	});

	const editCommissionAPI = useEditCommissionHook();

	const removeCommissionAPI = useRemoveCommissionHook();

	const addCommissionAPI = useAddCommissionHook();

	const submitHandler = () => {
		setEditMode(false);

		if (commission.id) {
			editCommissionAPI.edit(
				commission.id,
				{
					tariff_id: tariffId,
					type: "fix",
					direction: commission.direction,
					commission: value,
					min_amount: value,
					max_amount: null,
				},
				{ onSuccess }
			);
		} else {
			addCommissionAPI.add(
				{
					tariff_id: tariffId,
					type: "fix",
					direction: commission.direction,
					commission: value,
					min_amount: "0",
					max_amount: "",
				},
				{ onSuccess, onError: resetState }
			);
		}
	};

	const removeHandler = () => removeCommissionAPI.remove(commission.id, { onSuccess });

	return (
		<>
			<form className={styles.commission} ref={commissionEl} onSubmit={(e) => e.preventDefault()}>
				<TextInput
					className={styles.input}
					label={t("Fix amount")}
					value={value}
					name="commission"
					onChange={(e) => setValue(e.target.value.replace(/[^\d.]/g, ""))}
					data-test-id="commissionTextInput"
					isDisabled={!editMode || editCommissionAPI.isLoading}
					error={errorMessage}
				/>
				<div className={styles.actions} data-disabled={editCommissionAPI.isLoading}>
					{editMode ? (
						<>
							<button
								className={styles.confirm}
								data-test-id="confirmButton"
								onClick={() => setEditConfirmationVisibility(true)}
							>
								<ConfirmSVG />
							</button>

							<button className={styles.cancel} onClick={() => setEditMode(false)} data-test-id="cancelButton">
								<CancelSVG />
							</button>
						</>
					) : (
						<>
							<button data-test-id="editButton" onClick={() => setEditMode(true)}>
								<EditSVG />
							</button>
							{canRemove && (
								<button data-test-id="deleteButton" onClick={() => setRemoveConfirmationVisibility(true)}>
									<TrashSVG />
								</button>
							)}
						</>
					)}
				</div>
			</form>
			<ConfirmationDialog
				isVisible={editConfirmationIsVisible}
				type="edit"
				isLoading={editCommissionAPI.isLoading}
				onConfirm={submitHandler}
				onCancel={() => setEditConfirmationVisibility(false)}
			/>
			<ConfirmationDialog
				isVisible={removeConfirmationIsVisible}
				type="remove"
				isLoading={removeCommissionAPI.isLoading}
				onConfirm={removeHandler}
				onCancel={() => setRemoveConfirmationVisibility(false)}
			/>
		</>
	);
};

export default CommissionEditor;
