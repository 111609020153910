import { Button } from "@paykassma/pay-kit";
import { WalletTypeType } from "api/walletGroup";
import useResetCookies from "modules/Wallets/components/modals/ResetCookiesModal/hooks/useResetCookies";
import { useWorkingWalletContext } from "modules/Wallets/contexts/WorkingWalletContext/WorkingWalletContext";
import { useTranslation } from "pay-kit";
import { FC } from "react";

import styles from "./resetCookiesModal.module.scss";

const ResetCookiesModal: FC<ResetCookiesModalType> = ({ hashID, onReset, onCancel }) => {
	const { t } = useTranslation();
	const workingWalletContextAPI = useWorkingWalletContext();
	const resetCookiesAPI = useResetCookies();

	const resetCookies = () => {
		const onSuccess = () => {
			window.pushAlert({
				type: `success`,
				description: t(`Wallet's cookies successfully reset!`),
			});

			onReset();
			workingWalletContextAPI.getWallets();
		};

		hashID && resetCookiesAPI.update(hashID, onSuccess);
	};

	return (
		<div className={styles.ResetCookiesModal}>
			{hashID && t(`Are you sure you want to reset cookies for the wallet {{hashID}}?`, { hashID })}

			<div className={styles.actionsWrapper}>
				<Button data-test-id="cancelReaset" variant="secondary" classname={""} onClick={onCancel}>
					{t(`Cancel`)}
				</Button>

				<Button
					data-test-id="resetCookies"
					variant="delete"
					onClick={resetCookies}
					isLoading={resetCookiesAPI.isLoading}
				>
					{t(`Reset`)}
				</Button>
			</div>
		</div>
	);
};

export default ResetCookiesModal;

type ResetCookiesModalType = {
	readonly hashID: WalletTypeType[`hash_id`] | undefined;
	readonly onCancel: () => void;
	readonly onReset: () => void;
};
