import { useTranslation } from "pay-kit";
import API from "api";
import {
	GetListByStockPilingParamsType,
	GetListByStockPilingType,
	GetPostbackListParamsProps,
	getPostbackListType, StockPilingsType, TransactionsType
} from "api/stockpilingsGroup";
import Postback from "modules/Stockpilings/components/Postback";
import PostbackConfirmation from "modules/Stockpilings/components/PostbackConfirmation";
import StockpilingTransactions from "modules/Stockpilings/components/StockpilingTransactions";
import { SendPostbackType } from "modules/Stockpilings/contexts/StockPilingsListContext";
import { Modal } from "pay-kit";
import { createContext, ReactNode, useState } from "react";

import styles from "./stockpilingsContext.module.scss";

export type StockPilingsContextType = {
	readonly sendPostback: SendPostbackType;
	readonly getListByStockPiling: (id: GetListByStockPilingParamsType) => void;
	readonly getPostbackList: (id: number, params: GetPostbackListParamsProps) => void;
	readonly postbackList: getPostbackListType | null;
	readonly transactionByStockpilingId: GetListByStockPilingType["transactions"] | null;
	readonly error: null | Error;
	readonly isLoading: boolean,
	readonly showModal: (arg: string) => void,
	readonly modalType?: string | undefined,
	readonly handleClose: () => void,
	readonly getStockpilingId: (arg: number) => void
}

export type TransactionByStockpilingIdType =
	TransactionsType
	& { readonly status?: StockPilingsType[`status`]; readonly direction?: StockPilingsType[`direction`] }
export const StockpilingsContext = createContext<StockPilingsContextType>({} as StockPilingsContextType);

export const ModalTypes = {
	TRANSACTIONS: "TRANSACTIONS",
	POSTBACK: "POSTBACK",
	SEND_POSTBACK: "SEND_POSTBACK"
};

const StockpilingsContextProvider = ({ children }: { readonly children: ReactNode }) => {
	const {t} = useTranslation();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<null | Error>(null);
	const [transactionByStockpilingId, setTransactionByStockpilingId] = useState<GetListByStockPilingType["transactions"] | null>(null);
	const [postbackList, setPostbackList] = useState<getPostbackListType | null>(null);
	const [modalType, setModalType] = useState<undefined | string>(undefined);
	const [stockpiling_id, setStockpiling_id] = useState<number | null>(null);

	const handleClose = () => setModalType(undefined);

	const sendPostback = (id: number | null) => {
		if (!id) return;
		setIsLoading(true);
		setError(null);
		API.stockpiling.sendPostback(id).then(
			res => {
				if (res.status === "ok") {
					setIsLoading(false);
					window.pushAlert({
						type: `success`,
						description: t("Postback sent")
					});
				}
			}
		).catch((err) => {
			setIsLoading(false);
			setError(err);
		}
		);
	};

	const getListByStockPiling = (id: GetListByStockPilingParamsType) => {
		setIsLoading(true);
		setError(null);
		API.stockpiling.getListByStockPiling(id).then(
			res => {
				if (res?.status === "ok" && res.transactions) {
					setIsLoading(false);
					setTransactionByStockpilingId(res.transactions);
				}
			}
		).catch((err) => {
			setIsLoading(false);
			setError(err);
		});
	};
	const getPostbackList = (id: number, params: GetPostbackListParamsProps) => {
		setIsLoading(true);
		API.stockpiling.getPostbackList(id, params).then(
			res => {
				if (res?.status === "success") {
					const { data } = res;

					setIsLoading(false);
					setPostbackList(data);
				}
			}
		).catch((err) => {
			setIsLoading(false);
			setError(err);
		}
		);
	};

	const data = {
		getListByStockPiling: getListByStockPiling,
		getPostbackList: getPostbackList,
		sendPostback: sendPostback,
		postbackList,
		transactionByStockpilingId,
		error,
		isLoading,
		showModal: setModalType,
		modalType,
		handleClose,
		getStockpilingId: setStockpiling_id
	};

	return (
		<StockpilingsContext.Provider value={data}>
			<Modal
				title={t("Transaction list")}
				isOpen={!isLoading && modalType === ModalTypes.TRANSACTIONS}
				onClose={handleClose}
				className={styles.transactionsModal}
			>
				<StockpilingTransactions
					transactionByStockpilingId={transactionByStockpilingId}
				/>
			</Modal>
			<Modal
				title={t("Postback")}
				isOpen={!isLoading && modalType === ModalTypes.POSTBACK}
				onClose={handleClose}
			>
				<Postback{...(postbackList)} />
			</Modal>
			<Modal
				title={t("Send postback")}
				isOpen={!isLoading && modalType === ModalTypes.SEND_POSTBACK && !!stockpiling_id}
				onClose={() => setStockpiling_id(0)}
			>
				<PostbackConfirmation onClose={() => setStockpiling_id(null)}
					onConfirm={() => sendPostback(stockpiling_id)}
				/>
			</Modal>

			{children}
		</StockpilingsContext.Provider>);
};

export default StockpilingsContextProvider;
