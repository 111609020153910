export enum WalletState {
	ALLOWED_BALANCE_EXCEEDED = `allowed_balance_exceeded`,
	AUTHORISATION_ERROR = `authorisation_error`,
	INTEGRATION_ERROR = `integration_error`,
	PROBABLY_BANNED = `probably_banned`,
	PROXY_NOT_WORKING = `proxy_isnt_working`,
	ERROR_IN_TRANSACTION_PARSING = `error_in_transaction_parsing`,
	PARSER_ERROR = `parser_error`,
	NO_COOKIES = `no_cookies`,
	PENDING_SMS_CONFIRMATION = `pending_sms_confirmation`,
	UPDATE_COOKIES = `update_cookies`,
	ACCOUNT_DATA_ERROR = `account_data_error`,
	UNKNOWN_ERROR = `unknown_error`,
	DAILY_LIMIT_EXCEEDED = `daily_limit_exceeded`,
	DATA_ACCESS_ERROR = `data_access_error`,
	PK_CONNECTION_ERROR = `pk_connection_error`,
	MONTHLY_TURNOVER_LIMIT_EXCEEDED = `monthly_turnover_limit_exceeded`,
	DAILY_TURNOVER_LIMIT_EXCEEDED = `daily_turnover_limit_exceeded`,
	DAILY_TRANSACTION_LIMIT_EXCEEDED = `daily_transaction_limit_exceeded`,
	MANUAL_INPUT = `manual_input`,
}

export enum AccountType {
	AGENT = "agent",
	PERSONAL = "personal",
	MERCHANT = "merchant",
}

export enum ParserType {
	PARSER_ICICI = "icici",
	PARSER_INDUSIN = "indusind",
	PARSER_FREE_CHARGE = `freecharge`,
	PARSER_HDFC = "hdfc",
	PARSER_EMAIL = "email",
	PARSER_AXIS = "icici",
	PARSER_SITE = "site",
	PARSER_APP = "app",
	PARSER_PHONE_PE_MOBILE_API = "mobile_api",
	MOBILE_API = "mobile_api",
	SMS_BOX = "sms_box",
	MANUAL_INPUT = "manual_input",
	MOBILE_DATA = "mobile_data",
	FEDNET = "fednet",
}
