// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TransferHistory__goBackButton-p9R{background:#f2f3f5;border:none;color:#666}.TransferHistory__goBackButton-p9R>svg{margin-right:10px}.TransferHistory__goBackButton-p9R:hover{background:#f2f3f5;color:#21272a;text-decoration:none}.TransferHistory__transactionHistoryHeader-xKS{font-weight:500;font-size:24px;color:#21272a;margin-top:16px}.TransferHistory__loading-uJ3{margin-top:36px;text-align:center}`, "",{"version":3,"sources":["webpack://./src/modules/TransferHistory/transferHistory.module.scss"],"names":[],"mappings":"AAAA,mCACC,kBAAA,CACA,WAAA,CACA,UAAA,CAGD,uCACC,iBAAA,CAGD,yCACC,kBAAA,CACA,aAAA,CACA,oBAAA,CAGD,+CACC,eAAA,CACA,cAAA,CACA,aAAA,CACA,eAAA,CAGD,8BACC,eAAA,CACA,iBAAA","sourcesContent":[".goBackButton {\n\tbackground: #F2F3F5;\n\tborder: none;\n\tcolor: #666666;\n}\n\n.goBackButton > svg {\n\tmargin-right: 10px;\n}\n\n.goBackButton:hover {\n\tbackground: #F2F3F5;\n\tcolor: #21272A;\n\ttext-decoration: none;\n}\n\n.transactionHistoryHeader {\n\tfont-weight: 500;\n\tfont-size: 24px;\n\tcolor: #21272A;\n\tmargin-top: 16px;\n}\n\n.loading {\n\tmargin-top: 36px;\n\ttext-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"goBackButton": `TransferHistory__goBackButton-p9R`,
	"transactionHistoryHeader": `TransferHistory__transactionHistoryHeader-xKS`,
	"loading": `TransferHistory__loading-uJ3`
};
export default ___CSS_LOADER_EXPORT___;
