import { RowStatesType } from "modules/Proxies/components/UploadedProxiesListTable";
import { MultiAddingProxiesType, useAddingProxiesContext } from "modules/Proxies/contexts/AddingProxiesContext";
import { multiAddingProxiesHelper } from "modules/Proxies/helpers";
import { Select, useTranslation } from "pay-kit";
import { Dispatch, FC, SetStateAction } from "react";

const ProxiesEditableSelect: FC<ProxiesEditableSelectType> = ({
	name,
	id,
	classname,
	options,
	editableColContent,
	tableEditableRow,
}) => {
	const { setMultiAddingProxies } = useAddingProxiesContext();
	const { t } = useTranslation();

	const handleOnChange = () => {
		setMultiAddingProxies((prevState) =>
			multiAddingProxiesHelper({
				state: prevState,
				id,
			})
		);
	};

	return (
		<div>
			{tableEditableRow[id] ? (
				<Select
					name={name}
					className={classname}
					placeholder={t(`Choose`)}
					options={options}
					value={editableColContent}
					onChange={handleOnChange}
				/>
			) : (
				editableColContent
			)}
		</div>
	);
};

export default ProxiesEditableSelect;

type ProxiesEditableSelectType = {
	readonly name: keyof MultiAddingProxiesType;
	readonly id: string;
	readonly classname?: string;
	readonly options: readonly { readonly label: string; readonly value: string }[];
	readonly editableColContent: string;
	readonly setTableEditableRow: Dispatch<SetStateAction<RowStatesType>>;
	readonly tableEditableRow: RowStatesType;
};
