// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WithdrawalsHistoryTabs__panelWrapper-_nZ{margin-top:36px}.WithdrawalsHistoryTabs__tabsWrapper-yu6{margin-top:36px}`, "",{"version":3,"sources":["webpack://./src/modules/TransferHistory/components/WithdrawalsHistoryTabs/withdrawalsHistoryTabs.module.scss"],"names":[],"mappings":"AAAA,0CACC,eAAA,CAGD,yCACC,eAAA","sourcesContent":[".panelWrapper {\n\tmargin-top: 36px;\n}\n\n.tabsWrapper {\n\tmargin-top: 36px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panelWrapper": `WithdrawalsHistoryTabs__panelWrapper-_nZ`,
	"tabsWrapper": `WithdrawalsHistoryTabs__tabsWrapper-yu6`
};
export default ___CSS_LOADER_EXPORT___;
