import { useTranslation } from "pay-kit";
import api from "api/withdrawalGroup";
import DropZone from "modules/Withdrawals/components/ActionsProvider/ImportReportModal/DropZone";
import { Button, Modal } from "pay-kit";
import React, { useEffect, useState } from "react";

import styles from "./ImportReportModal.module.scss";

interface IImportReportModal {
	readonly onClose: () => void;
	readonly onSuccess: () => void;
	readonly refreshHandler?: () => void;
	readonly isOpen: boolean;
}

const ImportReportModal: React.FC<IImportReportModal> = ({ isOpen, onClose, onSuccess }) => {
	const {t} = useTranslation();
	const [errors, setErrors] = useState<any>(null);
	const [files, setFiles] = useState<FileList | null>(null);

	useEffect(() => {
		if (!isOpen) {
			setErrors(null);
			setFiles(null);
		}
	}, [isOpen]);

	const handleFiles = (files: FileList | null) => {
		setErrors(null);
		setFiles(files);
	};

	const uploadFile = (files: FileList) => {
		const formData = new FormData();
		formData.append("report", files[0]);

		api
			.importReport(formData)
			.then((res: any) => {
				if (res.status === "ok") {
					if (res.logs) {
						setErrors(res.logs);
					} else {
						window.pushAlert({
							// description: `Заявки на ручные выплаты загружены`,
							description: t("Manual withdrawals requests uploaded"),
							type: "success",
						});

						onSuccess && onSuccess();
					}
				} else {
					window.pushAlert({
						// description: `Произошла ошибка при загрузке отчета`,
						description: t("Failed to upload report"),
						type: "error",
					});
				}
				return res;
			})
			.catch((err) => {
				window.pushAlert({
					// description: `Произошла ошибка при загрузке отчета`,
					description: t("Failed to upload report"),
					type: "error",
				});
			});
	};

	return (
		<Modal isOpen={isOpen} title={t("Upload manual withdrawal requests")} onClose={onClose}>
			<div className={styles.form}>
				<DropZone onChange={handleFiles} files={files} setErrors={setErrors} errors={errors} data-test-id="DropZone" />

				<div className={styles.actions}>
					<Button disabled={files === null} onClick={() => (files ? uploadFile(files) : undefined)} data-test-id="">
						{t("Upload")}					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default ImportReportModal;
