import { t } from "pay-kit";
import { postbackType } from "api/postbackGroup";
import PaymentSystemLogo from "components/PaymentSystemLogo";
import TextWithCopyButton from "components/TextWithCopyButton";

import IconButton from "../../../../components/IconButton";
import { PostbacksActionsContext } from "../../PostbacksActionsProvider";
import styles from "./Columns.module.scss";
import ColumnTitleSeparator from "./components/ColumnTitleSeparator";
import Date from "./components/Date";
import Direction from "./components/Direction";
import ResponseJSON from "./components/ResponseJSON";
import StatusCode from "./components/StatusCode";
import { toLocaleDate } from "utils/date";

const idCol = {
	title: "ID",
	dataIndex: "id",
	key: "id",
	dataTestId: "id",
};

const urlCol = {
	title: t("Url"),
	dataIndex: "url",
	key: "url",
	dataTestId: "url",
};

const statusCodeAndResponseCol = {
	title: (
		<>
			{t("Status code")}
			<ColumnTitleSeparator /> {t("Responce")}
		</>
	),
	dataIndex: "status_code",
	key: "status_code",
	render: ({ status_code, response }: postbackType) => (
		<>
			<StatusCode code={status_code} dataTestId="status_code" />
			<ResponseJSON value={response} dataTestId="response" />
		</>
	),
};

const countFailureCol = {
	// title: "Количество\nпопыток",
	title: t("Number\nof attemps\nto load"),
	dataIndex: "count_failure",
	key: "count_failure",
	dataTestId: "count_failure",
};

const labelAndStockpilingIdCol = {
	title: (
		<>
			{t("Label")}
			<ColumnTitleSeparator /> {t("Order ID")}
		</>
	),
	dataIndex: "label",
	key: "label",
	render: ({ label, merchant_order_id }: postbackType) => (
		<>
			<TextWithCopyButton textClassName={styles.label} text={label} dataTestId="label" />
			<TextWithCopyButton
				className={styles.merchantOrderIdBadge}
				textClassName={styles.merchantOrderId}
				text={merchant_order_id}
				dataTestId="merchant_order_id"
			/>
		</>
	),
};

const walletTypeCol = {
	// title: "Тип\nкошелька",
	title: t("Wallet type"),
	dataIndex: "wallet_type",
	key: "wallet_type",
	render: ({ wallet_type }: postbackType) => <PaymentSystemLogo id={wallet_type} dataTestId="wallet_type" />,
};

const directionCol = {
	title: (
		<>
			{/* {t("Type")}<ColumnTitleSeparator /> Дата создания */}
			{t("Type")}
			<ColumnTitleSeparator /> {t("Date creation")}
		</>
	),
	dataIndex: "direction",
	key: "direction",
	render: ({ direction, created_at }: postbackType) => (
		<>
			<Direction direction={direction} dataTestId="direction" />
			<Date date={toLocaleDate(created_at)} dataTestId="created_at" />
		</>
	),
};

const actionsForAcceptedPostbacksCol = (resendPostbackAccess: boolean) => ({
	title: t("Actions"),
	dataIndex: "id",
	key: "actions",
	render: ({ id }: postbackType) => (
		<PostbacksActionsContext.Consumer>
			{({ showResponseDetails, forceSend }) => (
				<div className={styles.postbacksActions}>
					<IconButton
						data-test-id="N2i8GDjjX21PGEAPuFuRK"
						tip={t("Request body")}
						icon="details"
						dataTestId="responseBody"
						onClick={() => showResponseDetails(id)}
					/>
					{resendPostbackAccess && (
						<IconButton
							data-test-id="QQpER8TVSSCwWoEpU-NR1"
							tip={t("Resend postback")}
							icon="resend"
							dataTestId="resendPostback"
							onClick={() => forceSend(id)}
						/>
					)}
				</div>
			)}
		</PostbacksActionsContext.Consumer>
	),
});

const actionsForFailedPostbacksCol = (resendPostbackAccess: boolean) => ({
	title: t("Actions"),
	dataIndex: "id",
	key: "actions",
	render: ({ id }: postbackType) => (
		<PostbacksActionsContext.Consumer>
			{({ showResponseDetails, forceSend, resetCountFailure, markAsSentId }) => (
				<div className={styles.postbacksActions}>
					<IconButton
						data-test-id="responseBody"
						tip={t("Request body")}
						icon="details"
						dataTestId="responseBody"
						onClick={() => showResponseDetails(id)}
					/>
					{/* <IconButton data-test-id="resendPostback" tip="Переслать постбeк" icon="resend" */}
					{resendPostbackAccess && (
						<IconButton
							data-test-id="resendPostback"
							tip={t("Send postback")}
							icon="resend"
							dataTestId="resendPostback"
							onClick={() => forceSend(id)}
						/>
					)}
					{/* <IconButton data-test-id="resetAttempsCounter" tip="Сбросить счётчик попыток" icon="resetCount" */}
					{resendPostbackAccess && (
						<IconButton
							data-test-id="resetAttempsCounter"
							tip={t("Reset attempts counter")}
							icon="resetCount"
							dataTestId="resetAttempsCounter"
							onClick={() => resetCountFailure([id])}
						/>
					)}
					{resendPostbackAccess && (
						<IconButton
							data-test-id="markAsSent"
							tip={t("Mark as sent")}
							icon="accept"
							dataTestId="markAsSent"
							onClick={() => markAsSentId([id])}
						/>
					)}
				</div>
			)}
		</PostbacksActionsContext.Consumer>
	),
});

export type PostbackColumnProps = {
	readonly resendPostbackAccess: boolean;
}

export const acceptedTableColumns = (props: PostbackColumnProps) => {
	return [
		idCol,
		urlCol,
		statusCodeAndResponseCol,
		labelAndStockpilingIdCol,
		// TODO: The realization of this column is postponed to the next stage as yet there are no data for this column!
		walletTypeCol,
		directionCol,
		actionsForAcceptedPostbacksCol(props.resendPostbackAccess),
	];
}

export const failedTableColumns = (props: PostbackColumnProps) => {
	return [
		idCol,
		urlCol,
		statusCodeAndResponseCol,
		countFailureCol,
		labelAndStockpilingIdCol,
		// TODO: The realization of this column is postponed to the next stage as yet there are no data for this column!
		walletTypeCol,
		directionCol,
		actionsForFailedPostbacksCol(props.resendPostbackAccess),
	];
}
