import { convertIntoCurrency } from "helpers/convertIntoCurrency";
import { getCurrencyAndAmount } from "modules/Dashboard/helpers";
import { FC } from "react";

import styles from "./walletSummaryLimit.module.scss";

const WalletSummaryLimit: FC<WalletSummaryLimit> = ({
	limits,
	header,
	className
}) => {

	return (
		<>
			<div className={[styles.header, className].join(" ")}>{header}</div>
			{
				limits?.map((limit, i) => {
					const [currency, amount] = getCurrencyAndAmount(limit);
					const currenciefiedAmount = convertIntoCurrency({ amount, minimumFractionDigits: 0 });

					return (
						<div key={i} className={styles.turnOffLimit}>
							{currenciefiedAmount} {currency}
						</div>
					);
				})
			}
		</>
	);
};

export default WalletSummaryLimit;

type WalletSummaryLimit = {
	readonly limits: readonly {
		readonly [p: string]: number
	}[] | undefined
	readonly className: string
	readonly header: string
}