// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Money__Money-V5Y{font-weight:400;font-size:14px;line-height:20px;color:#697077}.Money__Money-V5Y.Money__ingoing-sQs{color:#24a148}.Money__Money-V5Y.Money__outgoing-MWS{color:#dc3545}`, "",{"version":3,"sources":["webpack://./src/components/Money/Money.module.scss"],"names":[],"mappings":"AAAA,kBACC,eAAA,CACA,cAAA,CACA,gBAAA,CAEG,aAAA,CAEH,qCAEC,aAAA,CAGD,sCAEC,aAAA","sourcesContent":[".Money {\n\tfont-weight: 400;\n\tfont-size: 14px;\n\tline-height: 20px;\n    /* Blue_gray/70 */\n    color: #697077;\n\n\t&.ingoing {\n\t\t/* Green/50 */\n\t\tcolor: #24a148;\n\t}\n\n\t&.outgoing {\n\t\t/* Red/60 */\n\t\tcolor: #dc3545;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Money": `Money__Money-V5Y`,
	"ingoing": `Money__ingoing-sQs`,
	"outgoing": `Money__outgoing-MWS`
};
export default ___CSS_LOADER_EXPORT___;
