// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchForm__searchForm-vpK{position:relative}.SearchForm__searchForm-vpK>svg{position:absolute;right:12px;top:50%;transform:translateY(-50%)}`, "",{"version":3,"sources":["webpack://./src/components/Filter/Field/CheckboxGroup/SearchForm/SearchForm.module.scss"],"names":[],"mappings":"AAAA,4BACC,iBAAA,CAEA,gCACC,iBAAA,CACA,UAAA,CACA,OAAA,CACA,0BAAA","sourcesContent":[".searchForm {\n\tposition: relative;\n\n\t> svg {\n\t\tposition: absolute;\n\t\tright: 12px;\n\t\ttop: 50%;\n\t\ttransform: translateY(-50%);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchForm": `SearchForm__searchForm-vpK`
};
export default ___CSS_LOADER_EXPORT___;
