// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextWithCopyButton__TextWithCopyButton-rpM{display:flex;gap:8px}.TextWithCopyButton__TextWithCopyButton-rpM .TextWithCopyButton__text-ky5{overflow:hidden;text-overflow:ellipsis;direction:rtl}`, "",{"version":3,"sources":["webpack://./src/modules/Withdrawals/components/ActionsProvider/DetailedView/Postback/TextWithCopyButton/TextWithCopyButton.module.scss"],"names":[],"mappings":"AAAA,4CACI,YAAA,CACA,OAAA,CAEH,0EACO,eAAA,CACA,sBAAA,CACA,aAAA","sourcesContent":[".TextWithCopyButton {\n    display: flex;\n    gap: 8px;\n\n\t.text {\n        overflow: hidden;\n        text-overflow: ellipsis;\n        direction: rtl;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TextWithCopyButton": `TextWithCopyButton__TextWithCopyButton-rpM`,
	"text": `TextWithCopyButton__text-ky5`
};
export default ___CSS_LOADER_EXPORT___;
