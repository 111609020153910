// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ThumbnailIcon__thumbnailWrapper-Y46 .ThumbnailIcon__thumbnail-m_i{width:760px}.ThumbnailIcon__thumbnailWrapper-Y46 .ThumbnailIcon__thumbnail-m_i>svg{height:100%;width:100%;line-height:initial}`, "",{"version":3,"sources":["webpack://./src/modules/LoginForm/ThumbnailIcon/thumbnail.module.scss"],"names":[],"mappings":"AACC,mEACC,WAAA,CAEA,uEACC,WAAA,CACA,UAAA,CACA,mBAAA","sourcesContent":[".thumbnailWrapper {\n\t.thumbnail {\n\t\twidth: 760px;\n\n\t\t& > svg {\n\t\t\theight: 100%;\n\t\t\twidth: 100%;\n\t\t\tline-height: initial;\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"thumbnailWrapper": `ThumbnailIcon__thumbnailWrapper-Y46`,
	"thumbnail": `ThumbnailIcon__thumbnail-m_i`
};
export default ___CSS_LOADER_EXPORT___;
