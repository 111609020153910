// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StatusBadges__wrapper-tsO{display:flex;gap:8px;overflow:scroll}.StatusBadges__wrapper-tsO::-webkit-scrollbar{display:none}`, "",{"version":3,"sources":["webpack://./src/modules/Wallets/WorkingWallets/components/StatusBadges/StatusBadges.module.scss"],"names":[],"mappings":"AAAA,2BACC,YAAA,CACA,OAAA,CACA,eAAA,CACA,8CACC,YAAA","sourcesContent":[".wrapper {\n\tdisplay: flex;\n\tgap: 8px;\n\toverflow: scroll;\n\t&::-webkit-scrollbar {\n\t\tdisplay: none;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `StatusBadges__wrapper-tsO`
};
export default ___CSS_LOADER_EXPORT___;
