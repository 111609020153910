import { MixedCheckbox, PayKitForm, useTranslation } from "@paykassma/pay-kit";

import styles from "./AdditionalSettingsSection.module.scss";

const AdditionalSettingsSection = (props) => {
	const checkboxGroupName = props.elements[0].name;
	const checkboxGroupOptions = props.elements[0]?.options || [];
	const checkboxGroupOptionsLength = checkboxGroupOptions.length || 0;

	const {t} = useTranslation();

	return (
		<PayKitForm.Group
			{...props}
			render={(children, props) => {
				const checkboxGroupState = props.formDataAPI.formState?.[checkboxGroupName];

				let mixedCheckboxValue: "none" | "all" | "mixed" = "none";

				if (checkboxGroupState === undefined) {
					mixedCheckboxValue = "none";
				} else if (Array.isArray(checkboxGroupState)) {
					if (checkboxGroupState.length === checkboxGroupOptionsLength) {
						mixedCheckboxValue = "all";
					} else {
						mixedCheckboxValue = "mixed";
					}
				}

				return (
					<div className={styles.additionalSettings}>
						<h3>
							<MixedCheckbox
								value={mixedCheckboxValue}
								id="additionalSettings"
								onClick={() => {
									if (mixedCheckboxValue === "none") {
										props.formDataAPI.setValue(
											checkboxGroupName,
											checkboxGroupOptions.map((opt) => opt.value)
										);
									}

									if (mixedCheckboxValue === "all" || mixedCheckboxValue === "mixed") {
										props.formDataAPI.setValue(checkboxGroupName, []);
									}
								}}
								data-test-id=""
							/>
							{t("Additionally show")}
						</h3>

						<div className={styles.checkboxGroup}>{children}</div>
					</div>
				);
			}}
		/>
	);
};

export default AdditionalSettingsSection;
