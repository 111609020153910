// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WalletSummaryLimit__header-2Ua{font-size:14px;color:#697077}.WalletSummaryLimit__turnOffLimit-WIZ{font-size:14px}.WalletSummaryLimit__turnOffLimit-WIZ:first-child{margin-top:4px}.WalletSummaryLimit__dailyLimit-wrM{margin-top:8px;color:#697077}`, "",{"version":3,"sources":["webpack://./src/modules/Dashboard/components/WalletsTotalBalance/components/WalletSummaryLimit/walletSummaryLimit.module.scss"],"names":[],"mappings":"AAAA,gCACC,cAAA,CACA,aAAA,CAGD,sCACC,cAAA,CAGD,kDACC,cAAA,CAGD,oCACC,cAAA,CACA,aAAA","sourcesContent":[".header {\n\tfont-size: 14px;\n\tcolor: #697077;\n}\n\n.turnOffLimit {\n\tfont-size: 14px;\n}\n\n.turnOffLimit:first-child {\n\tmargin-top: 4px;\n}\n\n.dailyLimit {\n\tmargin-top: 8px;\n\tcolor: #697077;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `WalletSummaryLimit__header-2Ua`,
	"turnOffLimit": `WalletSummaryLimit__turnOffLimit-WIZ`,
	"dailyLimit": `WalletSummaryLimit__dailyLimit-wrM`
};
export default ___CSS_LOADER_EXPORT___;
