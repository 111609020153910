import { ENUMBooleanSetting, ENUMFormType, ICreateNewWalletType } from "api/walletTypesGroup";
import { useTranslation } from "pay-kit";
import { Modal } from "pay-kit";
import { useEffect, useState } from "react";
import { deepClone } from "utils/deepClone";
import { errorsMap } from "utils/enums";

import useCreateNewPS from "../../hooks/useCreateNewPS";
import CreateNewForm from "./CreateNewForm";
import styles from "./CreateNewForm/CreateNewForm.module.scss";
import { getErrorsForTouchedFields, step1ValidationSchema, step2ValidationSchema, validateForm } from "./utils";

export interface ICreateNewProps {
	readonly isOpen: boolean;
	readonly onClose: () => void;
	readonly onSuccess: () => void;
}

const INIT_STATE = {
	logo: null,
	name: "",
	code: "",
	currency: undefined,
	limits: {
		deposit: {
			min: undefined,
			max: undefined,
		},
	},
	requisites: [
		{
			code: "",
		},
	],
	show_transaction_id: ENUMBooleanSetting.ON,
	validation_transaction_id_from: undefined,
	validation_transaction_id_to: undefined,
	show_payment_bill: ENUMBooleanSetting.OFF,
	hints: [
		{
			lang: undefined,
			text: "",
			form_type: ENUMFormType.REQUISITE,
		},
		{
			lang: undefined,
			text: "",
			form_type: ENUMFormType.PAYMENT,
		},
	],
	animation_hint: null,
	screenshot: null,
};

const CreateNew: React.FC<ICreateNewProps> = ({ isOpen, onClose, onSuccess }) => {
	const { t } = useTranslation();
	const [step, setStep] = useState<1 | 2>(1);
	const [formState, setFormState] = useState<ICreateNewWalletType>(INIT_STATE);
	const [touchedFields, setTouchedFields] = useState<readonly string[] | "*">([]);
	const [step1ValidationErrors, setStep1ValidationErrors] = useState<null | { readonly [key: string]: string }>(null);
	const [step2ValidationErrors, setStep2ValidationErrors] = useState<null | { readonly [key: string]: string }>(null);
	const createNewPSHook = useCreateNewPS({ onSuccess });
	const visibleErrorsStep1 = getErrorsForTouchedFields(step1ValidationErrors, touchedFields);
	const visibleErrorsStep2 = getErrorsForTouchedFields(step2ValidationErrors, touchedFields);
	const validationErrors = { ...step1ValidationErrors, ...step2ValidationErrors };
	const visibleErrors = { ...visibleErrorsStep1, ...visibleErrorsStep2 };

	useEffect(() => {
		if (!isOpen) {
			setStep(1);
			setFormState(INIT_STATE);
			setTouchedFields([]);
			setStep1ValidationErrors(null);
			setStep2ValidationErrors(null);
		}
	}, [isOpen]);

	useEffect(() => {
		(async () => {
			const errorsStep1 = await validateForm(formState, step1ValidationSchema);
			const errorsStep2 = await validateForm(formState, step2ValidationSchema);

			setStep1ValidationErrors(errorsStep1 as any);
			setStep2ValidationErrors(errorsStep2 as any);
		})();
	}, [formState]);

	const markFieldAsTouched = (name: string) =>
		setTouchedFields((prevState) => (prevState === "*" ? prevState : [...new Set([...prevState, name])]));

	const setFormField = (name: string) => (value: string | number | undefined) =>
		setFormState((prevState) => {
			const prevStateClone = deepClone(prevState);
			prevStateClone[name] = value;
			markFieldAsTouched(name);
			return prevStateClone;
		});

	const setDepositLimit = (name: "max" | "min") => (value?: string) =>
		setFormState((prevState) => {
			const transformedValue =
				value
					?.replace(/,/g, ".")
					.replace(/[^\d.]/g, "")
					.replace(/(\..*?)\..*/g, "$1") || "";
			const prevStateClone = deepClone(prevState);
			prevStateClone.limits.deposit[name] = transformedValue;
			markFieldAsTouched(`limits.deposit.${name}`);
			return prevStateClone;
		});

	const addRequisite = () =>
		setFormState((prevState) => {
			const prevStateClone = deepClone(prevState);
			prevStateClone.requisites.push({ code: "" });
			return prevStateClone;
		});

	const removeRequisite = (n: number) =>
		setFormState((prevState) => {
			if (prevState.requisites.length <= 1) {
				return prevState;
			}

			const prevStateClone = deepClone(prevState);
			prevStateClone.requisites = prevStateClone.requisites.filter((r: any, _n: number) => _n !== n);
			return prevStateClone;
		});

	const setRequisiteCode = (n: number) => (value: string) =>
		setFormState((prevState) => {
			const prevStateClone = deepClone(prevState);
			prevStateClone.requisites[n] = { code: value };
			markFieldAsTouched(`requisites[${n}].code`);
			return prevStateClone;
		});

	const addHint = (form_type: ENUMFormType) =>
		setFormState((prevState) => {
			const prevStateClone = deepClone(prevState);
			prevStateClone.hints = [...prevStateClone.hints, { lang: undefined, text: "", form_type }];
			return prevStateClone;
		});

	const setHintFieldParams = (index: number) => (name: string) => (value?: string) =>
		setFormState((prevState) => {
			const prevStateClone = deepClone(prevState);
			prevStateClone.hints[index][name] = value;
			markFieldAsTouched(`hints[${index}].${name}`);
			return prevStateClone;
		});

	const removeHint = (index: number) =>
		setFormState((prevState) => {
			const formType = prevState.hints[index].form_type;
			const formTypeTotalHintsCount = prevState.hints.filter((h) => h.form_type === formType).length;

			if (formTypeTotalHintsCount <= 1) {
				return prevState;
			}

			const prevStateClone = deepClone(prevState);
			prevStateClone.hints = prevStateClone.hints.filter((h: any, _n: any) => _n !== index);
			return prevStateClone;
		});

	const goStep = (step: 1 | 2) => {
		if (step === 2 && step1ValidationErrors && Object.keys(step1ValidationErrors).length > 0) {
			window.pushAlert({ type: "error", description: errorsMap.checkTheFieldForCorrectness });
			setTouchedFields(Object.keys(step1ValidationErrors));
			return;
		}

		setStep(step);
	};

	const submitHandler = () => {
		if (validationErrors !== null && Object.keys(validationErrors).length > 0) {
			setTouchedFields("*");
			window.pushAlert({ type: "error", description: errorsMap.checkTheFieldForCorrectness });
			return;
		}

		const formStateClone = deepClone(formState);

		createNewPSHook.create(formStateClone);
	};

	return (
		<Modal className={styles.modal} isOpen={isOpen} title={t("Creating a Manual PS")} onClose={onClose}>
			<CreateNewForm
				{...{
					formState,
					isLoading: createNewPSHook.isLoading,
					visibleErrors,
					validationErrors,
					touchedFields,
					step,
					actions: {
						goStep,
						setFormField,
						setDepositLimit,
						addRequisite,
						setRequisiteCode,
						removeRequisite,
						removeHint,
						addHint,
						setHintFieldParams,
						submitHandler,
					},
				}}
			/>
		</Modal>
	);
};

export default CreateNew;
