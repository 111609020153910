import Down from "assets/icons/buttonDown.svg";
import Up from "assets/icons/buttonUp.svg";
import { FC } from "react";

import styles from "./upAndDownButtons.module.scss";

const UpAndDownButtons: FC<UpAndDownButtonsType> = ({
	className,
	onUp,
	onDown
}) => {

	return (
		<div className={[styles.upAndDownButtonsWrapper, className].join(" ")}>
			<span data-test-id="OhH7nrhKmPYXGuANI76p0"
				className={styles.caret}
				onClick={onUp}
			>
				<Up />
			</span>
			<span data-test-id="23fUnnLDxnlV2l7RKvuz9"
				className={styles.caret}
				onClick={onDown}
			>
				<Down />
			</span>
		</div>
	);
};

export default UpAndDownButtons;

type UpAndDownButtonsType = {
	readonly className?: string
	readonly onUp?: () => void
	readonly onDown?: () => void
}
