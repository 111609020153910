// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditTask__modal-OaH{min-width:1000px;background:#f2f3f5}.EditTask__modal-OaH .EditTask__wrapper-BMn{display:flex;flex-direction:column}`, "",{"version":3,"sources":["webpack://./src/modules/Reports/AutoReports/ActionsProvider/EditTask/EditTask.module.scss"],"names":[],"mappings":"AAAA,qBACI,gBAAA,CACA,kBAAA,CAEA,4CACI,YAAA,CACA,qBAAA","sourcesContent":[".modal {\n    min-width: 1000px;\n    background: #F2F3F5;\n\n    .wrapper {\n        display: flex;\n        flex-direction: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `EditTask__modal-OaH`,
	"wrapper": `EditTask__wrapper-BMn`
};
export default ___CSS_LOADER_EXPORT___;
