import { useTranslation } from "pay-kit";
import Tariffs from 'modules/PaymentSystems/Tariffs';
import { useEffect } from "react";

export default function TariffsPage() {
	const {t} = useTranslation();

	useEffect(() => {
		document.title = t("Tariffs");
	}, []);

	return <Tariffs />;
}
