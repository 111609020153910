import { useTranslation } from "pay-kit";
import React from "react";

import styles from "./Direction.module.scss";

type DirectionPropsType = {
	readonly direction: "outgoing" | "ingoing";
	readonly dataTestId?: string
};

const Direction: React.FC<DirectionPropsType> = ({ direction, dataTestId }) => {
	const {t} = useTranslation();
	const classNames = [styles.Direction];

	const TYPES = {
		outgoing: t("Withdrawal"),
		ingoing: t("Receipt")
	} as const;

	switch (direction) {
		case "outgoing":
			classNames.push(styles.outgoing);
			break;
		case "ingoing":
			classNames.push(styles.ingoing);
			break;
	}

	return <div className={classNames.join(" ")} data-test-id={dataTestId}>{TYPES[direction]}</div>;
};

export default Direction;
