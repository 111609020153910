// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ResetCookiesModal__ResetCookiesModal-gFv .ResetCookiesModal__actionsWrapper-Lfh{width:max-content;display:flex;gap:16px;margin-left:auto;margin-top:32px}`, "",{"version":3,"sources":["webpack://./src/modules/Wallets/components/modals/ResetCookiesModal/resetCookiesModal.module.scss"],"names":[],"mappings":"AAEC,iFACC,iBAAA,CACA,YAAA,CACA,QAAA,CACA,gBAAA,CACA,eAAA","sourcesContent":[".ResetCookiesModal {\n\n\t.actionsWrapper {\n\t\twidth: max-content;\n\t\tdisplay: flex;\n\t\tgap: 16px;\n\t\tmargin-left: auto;\n\t\tmargin-top: 32px;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ResetCookiesModal": `ResetCookiesModal__ResetCookiesModal-gFv`,
	"actionsWrapper": `ResetCookiesModal__actionsWrapper-Lfh`
};
export default ___CSS_LOADER_EXPORT___;
