import { useTranslation } from "pay-kit";
import PaymentSystemLogo from "components/PaymentSystemLogo";
import Switcher from "components/Switcher";
import { Roles, useHasAccessHook } from 'contexts/AuthContext';
import { WalletTypesContext } from "contexts/WalletTypesContext";
import { Select } from "pay-kit";
import { useContext, useEffect } from "react";
import { rejectSettlement } from "utils/filterSettlement";

import useURLState from "/utils/hooks/useURLState";

import Hints from './Hints';
import useGetFormTypes from "./hooks/useGetFormTypes";
import useGetHints from "./hooks/useGetHints";
import useGetLanguages from "./hooks/useGetLanguages";
import useGetPackages from "./hooks/useGetPackages";
import Packages from './Packages';
import styles from "./Translations.module.scss";

const Translations = () => {
	useHasAccessHook({ rule: Roles.MANAGE_PAYMENTS_CONSTRUCTOR, redirect: true });

	const [selectedWalletTypeId, setSelectedWalletTypeId] = useURLState<string | undefined>("selectedWalletTypeId", "");
	const [selectedTranslationType, setSelectedTranslationType] = useURLState<"packages" | "hints">("activeTab", "packages");
	const walletTypesAPI = useContext(WalletTypesContext);
	const useGetFormTypesAPI = useGetFormTypes();
	const useGetLanguagesAPI = useGetLanguages();
	const getHintsAPI = useGetHints();
	const getPackagesAPI = useGetPackages();

	const { t } = useTranslation();

	useEffect(() => {
		walletTypesAPI.getWalletTypesList();
		useGetFormTypesAPI.load();
		useGetLanguagesAPI.load();
	}, []);

	// autoselect initial wallet_type_id (first in list)
	useEffect(() => {
		if (walletTypesAPI?.walletTypes[0]?.code && selectedWalletTypeId === "") {
			setSelectedWalletTypeId(walletTypesAPI.walletTypes[0].code);
		}
	}, [walletTypesAPI.walletTypes, selectedWalletTypeId]);

	// upload translations on wallet_type_id change
	useEffect(() => {
		if (selectedWalletTypeId) {
			if (selectedTranslationType === "packages") {
				getPackagesAPI.load(selectedWalletTypeId, false);
			}

			if (selectedTranslationType === "hints") {
				getHintsAPI.load(selectedWalletTypeId, false);
			}
		}
	}, [selectedWalletTypeId, selectedTranslationType]);

	const paymentSystemOptions = rejectSettlement(walletTypesAPI.walletTypes).map((item) => ({
		value: item.code,
		label: (
			<div className={styles.paymentSystemOptionLabel}>
				<PaymentSystemLogo
					classNames={{ logo: styles.logo }}
					id={item.code}
					hideLabel
				/>
				<span>{item.name}</span>
			</div>
		),
	}));

	return (
		<>
			<div className={styles.filter}>
				<Select
					className={styles.select}
					name={"payment-system-select"}
					options={paymentSystemOptions}
					onChange={(v) => setSelectedWalletTypeId((v || "") as string)}
					value={selectedWalletTypeId}
					data-test-id="payment-system-select"
					isLoading={walletTypesAPI.isLoading || getPackagesAPI.isLoading || getHintsAPI.isLoading}
					isDisabled={getPackagesAPI.isLoading || getHintsAPI.isLoading}
					noError
				/>
				<Switcher
					className={styles.translationTypeSwitcher}
					options={[
						{ label: t("Fields"), value: "packages" },
						{ label: t("Hints"), value: "hints" },
					]}
					onSwitch={(v) => setSelectedTranslationType(v as "packages" | "hints")}
					data-test-id="translations-type-tab"
					value={selectedTranslationType}
				/>
			</div>
			{selectedTranslationType === "packages" && selectedWalletTypeId && (
				<Packages
					walletType={selectedWalletTypeId}
					formTypes={useGetFormTypesAPI.list}
					languages={useGetLanguagesAPI.list}
					getPackagesAPI={getPackagesAPI}
				/>
			)}
			{selectedTranslationType === "hints" && selectedWalletTypeId && (
				<Hints
					walletType={selectedWalletTypeId}
					formTypes={useGetFormTypesAPI.list}
					languages={useGetLanguagesAPI.list}
					getHintsAPI={getHintsAPI}
				/>
			)}
		</>
	);
};

export default Translations;
