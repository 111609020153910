// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PaymentSystemLogo__PaymentSystemBadge-mVR{display:flex;flex-direction:column;gap:8px}.PaymentSystemLogo__PaymentSystemBadge-mVR div.PaymentSystemLogo__logo-XPv{display:block;height:20px;width:90px;background-size:contain;background-repeat:no-repeat;background-position:0% 50%}.PaymentSystemLogo__PaymentSystemBadge-mVR div.PaymentSystemLogo__logo-XPv.PaymentSystemLogo__defaultLogo-D5C{display:flex;align-items:center;gap:12px}.PaymentSystemLogo__PaymentSystemBadge-mVR .PaymentSystemLogo__caption-k94{font-weight:400;font-size:14px;line-height:20px;color:#697077;line-break:normal}.PaymentSystemLogo__withoutLogo-mgk{white-space:nowrap;max-width:90px;overflow:hidden;text-overflow:ellipsis}`, "",{"version":3,"sources":["webpack://./src/components/PaymentSystemLogo/PaymentSystemLogo.module.scss"],"names":[],"mappings":"AAAA,2CACC,YAAA,CACA,qBAAA,CACA,OAAA,CAEA,2EACC,aAAA,CACA,WAAA,CACA,UAAA,CACA,uBAAA,CACA,2BAAA,CACA,0BAAA,CAEA,8GACC,YAAA,CACA,kBAAA,CACA,QAAA,CAIF,2EACC,eAAA,CACA,cAAA,CACA,gBAAA,CACA,aAAA,CACA,iBAAA,CAIF,oCACC,kBAAA,CACA,cAAA,CACA,eAAA,CACA,sBAAA","sourcesContent":[".PaymentSystemBadge {\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\tgap: 8px;\r\n\r\n\tdiv.logo {\r\n\t\tdisplay: block;\r\n\t\theight: 20px;\r\n\t\twidth: 90px;\r\n\t\tbackground-size: contain;\r\n\t\tbackground-repeat: no-repeat;\r\n\t\tbackground-position: 0% 50%;\r\n\r\n\t\t&.defaultLogo {\r\n\t\t\tdisplay: flex;\r\n\t\t\talign-items: center;\r\n\t\t\tgap: 12px;\r\n\t\t}\r\n\t}\r\n\r\n\t.caption {\r\n\t\tfont-weight: 400;\r\n\t\tfont-size: 14px;\r\n\t\tline-height: 20px;\r\n\t\tcolor: #697077;\r\n\t\tline-break: normal;\r\n\t}\r\n}\r\n\r\n.withoutLogo {\r\n\twhite-space: nowrap;\r\n\tmax-width: 90px;\r\n\toverflow: hidden;\r\n\ttext-overflow: ellipsis;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PaymentSystemBadge": `PaymentSystemLogo__PaymentSystemBadge-mVR`,
	"logo": `PaymentSystemLogo__logo-XPv`,
	"defaultLogo": `PaymentSystemLogo__defaultLogo-D5C`,
	"caption": `PaymentSystemLogo__caption-k94`,
	"withoutLogo": `PaymentSystemLogo__withoutLogo-mgk`
};
export default ___CSS_LOADER_EXPORT___;
