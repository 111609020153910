import { Tabs } from "pay-kit";
import { useLocation, useNavigate } from "react-router-dom";

export type RoutedTab = {
	readonly value: string;
	readonly label: string;
	readonly visible?: boolean;
}

export type RoutedTabsPropType = {
	readonly tabs: readonly RoutedTab[];
	readonly className?: string;
};

const RoutedTabs: React.FC<RoutedTabsPropType> = ({ tabs, className }) => {
	const navigate = useNavigate();
	const location = useLocation();

	const validTabs = tabs.filter((tab) => ("visible" in tab ? tab.visible : true));

	if (validTabs.length <= 1) return null;

	return (
		<Tabs
			tabs={validTabs}
			value={location.pathname}
			onChange={(path) => navigate(path as string)}
			data-test-id="tabs"
			className={className}
		/>
	);
};

export default RoutedTabs;
