// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RemoveModal__removeModal-HJd{max-width:350px}.RemoveModal__removeModal-HJd p{margin-bottom:32px}.RemoveModal__removeModal-HJd .RemoveModal__controls-nVi{margin-top:8px;display:flex;gap:6px}.RemoveModal__removeModal-HJd .RemoveModal__controls-nVi button{flex:1}`, "",{"version":3,"sources":["webpack://./src/modules/PaymentSystems/Translations/components/ActionsProvider/modals/RemoveModal/RemoveModal.module.scss"],"names":[],"mappings":"AAAA,8BACI,eAAA,CAEA,gCACI,kBAAA,CAGJ,yDACI,cAAA,CACA,YAAA,CACA,OAAA,CAEA,gEACI,MAAA","sourcesContent":[".removeModal {\n    max-width: 350px;\n\n    p {\n        margin-bottom: 32px;\n    }\n\n    .controls {\n        margin-top: 8px;\n        display: flex;\n        gap: 6px;\n\n        button {\n            flex: 1;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"removeModal": `RemoveModal__removeModal-HJd`,
	"controls": `RemoveModal__controls-nVi`
};
export default ___CSS_LOADER_EXPORT___;
