// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DeleteModal__wrapper-jqU{width:100%}.DeleteModal__text-Y5v{padding:16px 0}.DeleteModal__controls-jvt{display:flex;justify-content:flex-end;padding:16px 0}.DeleteModal__closeBtn-GJV{margin-right:16px}`, "",{"version":3,"sources":["webpack://./src/components/Modals/DeleteModal/DeleteModal.module.scss"],"names":[],"mappings":"AAAA,0BACC,UAAA,CAGD,uBACC,cAAA,CAGD,2BACC,YAAA,CACA,wBAAA,CACA,cAAA,CAGD,2BACC,iBAAA","sourcesContent":[".wrapper {\n\twidth: 100%;\n}\n\n.text {\n\tpadding: 16px 0;\n}\n\n.controls {\n\tdisplay: flex;\n\tjustify-content: flex-end;\n\tpadding: 16px 0;\n}\n\n.closeBtn {\n\tmargin-right: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `DeleteModal__wrapper-jqU`,
	"text": `DeleteModal__text-Y5v`,
	"controls": `DeleteModal__controls-jvt`,
	"closeBtn": `DeleteModal__closeBtn-GJV`
};
export default ___CSS_LOADER_EXPORT___;
