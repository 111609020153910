import { useContext } from "react";

import { StatisticWalletsCtx } from "./StatisticWalletsCtx";

export function useStatisticWalletsCtx() {
	const ctx = useContext(StatisticWalletsCtx);
	
	if (ctx === undefined) {
		throw new Error("useStatisticWalletsCtx must be used inside a StatisticWalletsCtxProvider");
	}

	return ctx;
}