import { useTranslation } from "pay-kit";
import PossibleTransactions from "modules/Transactions/PossibleTransactions";
import { useEffect } from "react";

export default function PossibleTransactionsPage() {
	const {t} = useTranslation();

	useEffect(() => {
		document.title = t("Possible transactions");
	}, []);

	return <PossibleTransactions />;
}
