import API from "api";
import { WalletTypeLimitsType } from "api/walletTypesGroup";
import { useState } from "react";
import { errorsMap } from "utils/enums";

const useWalletTypeLimitsList = () => {
	const [list, setList] = useState<readonly WalletTypeLimitsType[] | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<null | Error>(null);
	const [extendedList, setExtendedList] = useState<ExtendedListType>({});

	const load = (walletType: string) => {
		setExtendedList(list => ({
			...list,
			[walletType]: {
				state: "pending",
				list: null
			}
		}));
		setIsLoading(true);
		setError(null);

		API.walletType.getWalletTypeLimits(walletType).then(
			res => {
				if (res.status === "ok") {
					setList(res.data);

					setExtendedList(list => ({
						...list,
						[walletType]: {
							state: "fulfilled",
							list: res.data
						}
					}));
				} else {
					// throw new Error("Unexpected response in useChangeLogUsers");
					throw new Error(errorsMap.anyResponse);
				}
			}
		).catch((err) => {
			setError(err);
		}).finally(() => {
			setIsLoading(false);
			setExtendedList(list => ({
				...list,
				[walletType]: {
					...list[walletType],
					state: "settled"
				}
			}));
		}
		);

	};

	return {
		load,
		list,
		extendedList,
		isLoading,
		error
	};
};

export default useWalletTypeLimitsList;

type ExtendedListType = {
	readonly [key: string]: {
		readonly state: "pending" | "fulfilled" | "settled"
		readonly list: readonly WalletTypeLimitsType[] | null
	}
}