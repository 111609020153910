import { ProxyCommonWallets } from "modules/Proxies/components/ProxyModalRows";
import { useProxyContext } from "modules/Proxies/contexts/ProxyContext";
import { useMemo } from "react";

const useAvailableWallets: UseAvailableWalletsType = (wallets) => {
	const { walletsList } = useProxyContext();

	return useMemo(() => {
		const selectedWallets = wallets?.map((item) => {
			return item?.value || item?.hash_id;
		});

		const availableWalletList = walletsList
			? walletsList.filter(({ hash_id }) => !selectedWallets?.includes(hash_id))
			: walletsList;

		return availableWalletList?.reduce(
			(result: readonly ProxyWalletSelectType[], { id, hash_id, type, identifier }) => {
				const selectElement: ProxyWalletSelectType | readonly ProxyWalletSelectType[] = {
					value: hash_id,
					label: identifier ? `${type} | ${identifier}` : `${type}`,
					status: undefined,
					parser_type: null,
				};

				return Array.isArray(selectElement) ? [...result, ...selectElement] : [...result, selectElement];
			},
			[]
		);
	}, [walletsList?.length]);
};

export default useAvailableWallets;

type UseAvailableWalletsType = (
	wallets: readonly (ProxyCommonWallets & { readonly value?: string })[] | undefined
) => readonly ProxyWalletSelectType[] | undefined;

export type ProxyWalletSelectType = {
	readonly value: string;
	readonly label: string;
	readonly status?: number;
	readonly parser_type: string | null;
	readonly id?: number;
};
