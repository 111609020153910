import API from "api";
import { t } from "pay-kit";
import { useCallback, useState } from "react";
import { errorsMap } from "utils/enums";

const useResetCookies = () => {
	const [data, setData] = useState<readonly unknown[] | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<null | Error>(null);

	const update: UpdateType = useCallback((hashId, onSuccess) => {
		setIsLoading(true);
		setError(null);
		setData(null);

		API.cookies
			.resetCookies(hashId)
			.then((res) => {
				if (res.status === "success") {
					// setData(res.params);
					setData(res.data);
					onSuccess && onSuccess();
				} else {
					throw new Error(errorsMap.anyResponse);
				}

				return res;
			})
			.catch((err) => {
				setError(err);
			})
			.finally(() => setIsLoading(false));
	}, []);

	return {
		update,
		data,
		isLoading,
		error,
	};
};

export default useResetCookies;

type UpdateType = (hashId: string, onSuccess?: () => void) => void;
