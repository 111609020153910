// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WalletsLoadPage__panel-a3s{padding:8px 0 0 0}.WalletsLoadPage__panel-a3s .WalletsLoadPage__panel__title-d2b{font-size:24px;font-weight:500;margin-bottom:36px}`, "",{"version":3,"sources":["webpack://./src/layout/pages/WalletsLoadPage/styles.module.scss"],"names":[],"mappings":"AAAA,4BACI,iBAAA,CAEA,+DACI,cAAA,CACA,eAAA,CACA,kBAAA","sourcesContent":[".panel {\n    padding: 8px 0 0 0;\n\n    .panel__title {\n        font-size: 24px;\n        font-weight: 500;\n        margin-bottom: 36px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": `WalletsLoadPage__panel-a3s`,
	"panel__title": `WalletsLoadPage__panel__title-d2b`
};
export default ___CSS_LOADER_EXPORT___;
