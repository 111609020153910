import api, { TranslationHintType, TranslationPackageType } from "api/translationsGroup";
import { useTranslation } from "pay-kit";
import { Button, Modal, TextInput } from "pay-kit";
import { useEffect, useState } from "react";

import styles from "./addNewModal.module.scss";

export type AddNewModalPropsType = {
	readonly data?: {
		readonly translationType: "hint" | "package";
		readonly usedNames: readonly string[];
		readonly walletType: string;
	};
	readonly onClose: () => void;
	readonly onSuccess: () => void;
};

const AddNewModal: React.FC<AddNewModalPropsType> = ({ data, onClose, onSuccess }) => {
	const { t } = useTranslation();
	const [name, setName] = useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const error = data?.usedNames.includes(name) ? t("Name already in use") : undefined;

	useEffect(() => {
		if (data === undefined) {
			setName("");
		}
	}, [data]);

	const submitHandler = () => {
		if (!data?.walletType) {
			return;
		}

		if (data?.translationType === "package") {
			addNewPackage();
		}

		if (data?.translationType === "hint") {
			addNewHint();
		}
	};

	const addNewPackage = () => {
		if (!data?.walletType) {
			return;
		}

		setIsLoading(true);

		api
			.getPackages(data.walletType, true)
			.then((res: any) => {
				const packages = res.data as readonly TranslationPackageType[];

				return Promise.all(
					packages.map((pckg) =>
						api.createNewPackages({
							form_type: pckg.form_type,
							name,
							is_manual: pckg.is_manual,
							wallet_type: data.walletType,
							packages_translations: pckg.packages_translations.map((pt) => ({
								field_uuid: pt.field,
								translations: pt.translations,
							})),
						})
					)
				);
			})
			.then((res) => {
				onSuccess();
				window.pushAlert({ type: "success", title: t("Translation successfully created") });
				return res;
			})
			.finally(() => {
				setIsLoading(false);
				onClose();
			})
			.catch((err) => {
				console.error(err);
				window.pushAlert({ type: "error", title: t("Translation creation error") });
			});
	};

	const addNewHint = () => {
		if (!data?.walletType) {
			return;
		}

		setIsLoading(true);

		api
			.getHints(data.walletType, true)
			.then((res: any) => {
				const hints = res.data as readonly TranslationHintType[];

				return Promise.all(
					hints.map((pckg) =>
						api.createNewHint({
							form_type: pckg.form_type,
							name,
							is_manual: pckg.is_manual,
							wallet_type: data.walletType,
							translations: pckg.translations,
						})
					)
				);
			})
			.then((res) => {
				onSuccess();
				window.pushAlert({ type: "success", title: t("Translation successfully created") });
				return res;
			})
			.finally(() => {
				setIsLoading(false);
				onClose();
			})
			.catch((err) => {
				console.error(err);
				window.pushAlert({ type: "error", title: t("Translation creation error") });
			});
	};

	return (
		<Modal className={styles.addNewModal} isOpen={data !== undefined} title={t("New field adding")} onClose={onClose}>
			<TextInput
				placeholder={t("Enter name")}
				name="name"
				value={name}
				onChange={(e) => setName(e.target.value)}
				data-test-id=""
				error={error}
			/>
			<div className={styles.controls}>
				<Button data-test-id="cancel" onClick={onClose} variant="secondary" disabled={isLoading}>
					{t("Cancel")}
				</Button>
				<Button
					data-test-id="rename-submit"
					onClick={submitHandler}
					disabled={error !== undefined || name === "" || isLoading}
					isLoading={isLoading}
				>
					{t("Save")}
				</Button>
			</div>
		</Modal>
	);
};

export default AddNewModal;
