// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Chart__wrapper-taP{margin-top:24px;padding:24px;border-radius:8px;background:#fff}.Chart__wrapper-taP .Chart__topControls-Gr7{display:flex;gap:32px}.Chart__wrapper-taP .Chart__topControls-Gr7 :last-child{margin-left:auto}.Chart__wrapper-taP .Chart__chartContainer-tQI{width:100%;height:380px;margin-top:24px;position:relative}.Chart__wrapper-taP .Chart__chartContainer-tQI .Loader{position:absolute;left:50%;top:50%}`, "",{"version":3,"sources":["webpack://./src/modules/Dashboard/components/Chart/Chart.module.scss"],"names":[],"mappings":"AAAA,oBACC,eAAA,CACA,YAAA,CACA,iBAAA,CACA,eAAA,CAEA,4CACC,YAAA,CACA,QAAA,CAEA,wDACC,gBAAA,CAIF,+CACC,UAAA,CACA,YAAA,CACA,eAAA,CACA,iBAAA,CAEA,uDACC,iBAAA,CACA,QAAA,CACA,OAAA","sourcesContent":[".wrapper {\n\tmargin-top: 24px;\n\tpadding: 24px;\n\tborder-radius: 8px;\n\tbackground: #FFF;\n\n\t.topControls {\n\t\tdisplay: flex;\n\t\tgap: 32px;\n\n\t\t:last-child {\n\t\t\tmargin-left: auto;\n\t\t}\n\t}\n\n\t.chartContainer {\n\t\twidth: 100%;\n\t\theight: 380px;\n\t\tmargin-top: 24px;\n\t\tposition: relative;\n\n\t\t:global(.Loader) {\n\t\t\tposition: absolute;\n\t\t\tleft: 50%;\n\t\t\ttop: 50%;\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Chart__wrapper-taP`,
	"topControls": `Chart__topControls-Gr7`,
	"chartContainer": `Chart__chartContainer-tQI`
};
export default ___CSS_LOADER_EXPORT___;
