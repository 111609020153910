import { t, useTranslation } from "pay-kit";
export enum WalletTypes {
	PAY_TM = `paytm`,
	PAY_TM_BIZ = `paytm_biz`,
	SKRILL = `skrill`,
	NETELLER = `neteller`,
	PERFECT_MONEY = `perfect_money`,
	EPAY = `epay`,
	HELP_2_PAY = `help2pay`,
	CERTUS_FINANCE = `certus_fin`,
	IMPS_IB = `imps_ib`,
	UPI_IB = `upi_ib`,
	BKASH = `bkash`,
	DUSUPAY = `dusupay`,
	NGAN_LUONG = `nganluong`,
	NGAN_LUONG_EMAIL = `nganluong_email`,
	PAY_OP = `payop`,
	PAYKASSA = `paykassa`,
	PAYKASSMA = `paykassma`,
	NAGAD = `nagad`,
	NAGAD_API = `nagad_api`,
	ROCKET = `rocket`,
	PHONE_PE = `phone_pe`,
	VIETCOM_BANK = `vietcombank`,
	CASHMAAL = `cashmaal`,
	PAYTRUST88 = `paytrust88`,
	PAYME_UZ = `payme_uz`,
	SUREPAY = `surepay`,
	PAYTECA = `payteca`,
	PAYZEN = `payzen`,
	TECHCOMBANK = `techcombank`,
	PAYGIGA = `paygiga`,
	PAG_SMILE = `pagsmile`,
	AKBANK = `akbank`,
	YAAR_PAY = `yaarpay`,
	BCA_BANK = `bcabank`,
	RHB_BANK = `rhbbank`,
	MAYBANK = `maybank`,
	UPI_AB = `upi_ab`,
	UPI_HB = `upi_hb`,
	IMPS_HB = `imps_hb`,
	LOCAL_CRYPTO = `local_crypto`,
	SAFECOMMERZ = `safecommerz`,
	UPI_NAAP = `upi_naap`,
	IMPS_AB = `imps_ab`,
	IMPS_IDB = `imps_idb`,
	UPI_IDB = `upi_idb`,
	TEST = `test`,
	LAYKAPAY = `layka_pay`,
	UPI_AP = `upi_ap`,
	UPI_L = `upi_l`,
	UZCARD_API = `uzcard_api`,
	PAYTM_API = `paytm_api`,
	UPI_PB = `upi_pb`,
	UPI_S = `upi_s`,
	BKASH_API = `bkash_api`,
	MPESA = `mpesa`,
	EASYPAISA = `easypaisa`,
	/*
		См. https://jira.fixmost.com/browse/PAY-12492,
		раскомментить как будет понятно как должно работать
	*/
	//UPI_SB: `upi_sb`,
	UPI_SF = `upi_sf`,
	UPI_IN = `upi_in`,
	UPI_W = `upi_w`,
	BKASH_API_CEBALRAI = `bkash_api_cebalrai`,
	CLICK_UZS = `click_uzs`,
	WAVEPAY = `wavepay`,
	UPI_M = `upi_m`,
	PHONE_PE_S = `phonepe_s`,
	JAZZCASH = `jazzcash`,
	IPAY = `ipay`,
	IPAY_API = `ipay_api`,
	JAZZCASH_BUSINESS = `jazzcash_business`,
	EASYPAISA_API = `easypaisa_api`,
}

export const fieldErrorsMap = {
	isRequired: "Поле обязательно",
	wrongLinkFormat: t("Invalid URL format"),
};

// .required
// .typeError
// .positive
// .test

export const errorsMap = {
	required: "",
	// Общая ошибка. Неизвестно, в чем дело. Замена для: Something went wrong!
	any: t("Error"),
	anyResponse: t("Request error"),
	anySending: t("Sending error"),
	anyCreate: t("Creation error"),
	noAccess: t("No access"),
	cantCopy: t("Can`t copy"),
	cantUpdate: t("Error happened while updating"),
	cantUpdateComission: t("Commision update error"),
	cantGetWalletData: t("Getting wallet data error"),
	cantConfirmPhone: t("Phone confirmation error"),
	cantConfirmComment: t("Comment confirmation error"),
	cantGetCurrenciesData: t("Currencies data load error"),
	cantGetWalletsData: t("Wallet data load error"),
	cantGetMetricsData: t("Metrics data load error"),
	cantGetLoadSummaryData: t("Wallet load data fetching error"),
	cantGetStatisticData: t("Wallet statistics load error"),
	cantCreateManualPaymentSystem: t("Manual PS creation error"),
	cantSaveTheSettings: t("Saving settings error"),
	checkTheFieldForCorrectness: t("Check the field for correctness"),
	urlsIsrequired: t("Url list is required"),


	getNotFoundRoute: (url: string | undefined) => t(`Route {{url}} не найден`, {url}),
	getStatusForEndpoint: (url: string | undefined, status: number) => t(`{{url}} - error {{status}}`, {url, status}),
	getCurrencyError: (type: string | undefined) => t(`Loading currency data error for {{type}} payment system`, {type}),
	getConstructor: (type: string) => t(`Loading settings error for {{type}} payment system`, {type}),
	getProxy: (type: string) => t(`Loading proxy error for {{type}} payment system`, {type}),
	getWorkTime: (type: string) => t(`Loading working type error for {{type}} payment system`, {type}),
	getWrongExtension: (file: string) => t(`{{file}} has incorrect format`, {file}),
};

export const successMap = {
	updated: t("Successfully updated"),
};
