// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReportsPage__panel-t5i{padding:48px 0}`, "",{"version":3,"sources":["webpack://./src/layout/pages/ReportsPage/styles.module.scss"],"names":[],"mappings":"AAAA,wBACI,cAAA","sourcesContent":[".panel {\n    padding: 48px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": `ReportsPage__panel-t5i`
};
export default ___CSS_LOADER_EXPORT___;
