import { useTranslation } from "pay-kit";
import { Loader, Modal } from "pay-kit";
import React, { useEffect, useState } from "react";

import API from "/api/reportsGroup";

import TransactionsListForm from "../../components/forms/TransactionsList";
import TransactionsSumForm from "../../components/forms/TransactionsSum";
import styles from "./EditTask.module.scss";
import { preparePostData, prepareReceivedData, TaskType } from './utils';
import { errorsMap } from "utils/enums";

type EditTaskType = {
	readonly closeHandler: () => void;
	readonly taskId?: number;
	readonly refreshList: () => void;
};

const EditTask: React.FC<EditTaskType> = ({ taskId, closeHandler, refreshList }) => {
	const {t} = useTranslation();
	const [taskType, setTaskType] = useState<TaskType | undefined>(undefined);
	const [initialFormState, setInitialFormState] = useState<any>(undefined);
	const [getIsLoading, setGetIsLoading] = useState<boolean>(false);
	const [updateIsLoading, setUpdateIsLoading] = useState<boolean>(false);

	useEffect(() => {
		if (taskId !== undefined) {
			getTask(taskId);
		} else {
			setTaskType(undefined);
			setInitialFormState(undefined);
		}
	}, [taskId]);

	const getTask = (taskId: number) => {
		setGetIsLoading(true);

		API.getAutoReport(taskId)
			.then((res: any) => {
				setTaskType(res.data.report_type);
				setInitialFormState(prepareReceivedData(res.data));

				return res;
			})
			.finally(() => {
				setGetIsLoading(false);
			})
			.catch((err) => console.error(err));
	};

	const updateTask = (data) => {
		setUpdateIsLoading(true);

		API.updateAutoReport(taskId as number, preparePostData(taskType as TaskType, data))
			.then((res) => {
				if (res.status === "success") {
					closeHandler();
					refreshList();
					window.pushAlert({ description: t("Task changed"), type: "success" });
					return res;
				} else {
					if (res.errors) {
						const plainErrors = Object.values(res.errors).join(", ");
						window.pushAlert({ description: plainErrors, type: "error" });
					} else {
						// throw new Error("Error in updateAutoReport");
						throw new Error(errorsMap.cantUpdate);
					}

					return res;
				}

			})
			.finally(() => {
				setUpdateIsLoading(false);
			})
			.catch((err) => {
				console.error(err);
				window.pushAlert({
					description: t("An error occurred while editing"),
					type: "error",
				});
			});
	};

	let Form = null;

	switch (taskType) {
		case "transactions_list":
			Form = TransactionsListForm;
			break;
		case "transactions_sum-detail":
		case "transactions_sum":
			Form = TransactionsSumForm;
			break;
		case undefined:
			break;
	}

	return (
		<Modal
			isOpen={taskId !== undefined}
			title={t("Updating autogeneration task")}
			onClose={closeHandler}
			className={styles.modal}
		>
			{getIsLoading && <Loader />}
			{Form && (
				<div className={styles.wrapper}>
					<Form initialState={initialFormState} onSubmit={updateTask} isLoading={updateIsLoading} editMode />
				</div>
			)}
		</Modal>
	);
};

export default EditTask;
