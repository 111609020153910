import IconButton from "components/IconButton";
import {ArchivedWalletContext} from "modules/Wallets/contexts/ArchivedWalletContext";
import { useTranslation } from "pay-kit";
import {useContext} from "react";

const MultipleActions = () => {
	const { openMassRecoverWalletModal, openMassDeleteWalletModal } = useContext(ArchivedWalletContext);
	const {t} = useTranslation();

	const handleMassRecoverClick = () => {
		openMassRecoverWalletModal();
	};

	const handleMassDeleteClick = () => {
		openMassDeleteWalletModal();
	};
	return (
		<div style={{
			display: "flex",
			gap: "16px"
		}}>
			<IconButton data-test-id="dYpgzQE3NEAp5fPdckCWV" icon="recover" onClick={handleMassRecoverClick} caption={t("Recovery")}/>
			<IconButton data-test-id="Qq6yg6Qev_Kq56Dl92zm6" icon="disable" onClick={handleMassDeleteClick} caption={t("Delete")}/>
		</div>
	);
};

export default MultipleActions;
