import { SchemaType } from "@paykassma/pay-kit/lib/elements/PayKitForm/FormBuilder/types";
import { t } from "pay-kit";

import { isCorrectInterval, isNotRequired, isRequired, isValidValue, maxDigitsAfterDecimal } from "../validation";
import { FormStateType } from "./index";
import styles from "./PercentEditor.module.scss";
import { isRowInEditMode, transformToFloatString } from "./utils";

const schema: SchemaType<FormStateType> = [
	{
		type: "Wrapper",
		name: "commissions",
		elements: [
			{
				type: "PercentRow",
				elements: [
					{
						type: "TextInput",
						label: t("Amount from"),
						name: "min_amount",
						className: styles.input,
						disabled: isRowInEditMode,
						transform: transformToFloatString,
						validation: [isRequired, isValidValue, isCorrectInterval("min_amount"), maxDigitsAfterDecimal(8)],
					},
					{
						type: "TextInput",
						label: t("Amount to"),
						name: "max_amount",
						className: styles.input,
						disabled: isRowInEditMode,
						transform: transformToFloatString,
						validation: isNotRequired([isValidValue, isCorrectInterval("max_amount"), maxDigitsAfterDecimal(8)]),
					},
					{
						type: "PercentTextInput",
						elements: [
							{
								type: "TextInput",
								label: t("Percent"),
								name: "commission",
								className: styles.input,
								disabled: isRowInEditMode,
								transform: transformToFloatString,
								validation: [isRequired, isValidValue, maxDigitsAfterDecimal(2)],
							},
						],
					},
					{
						type: "Hidden",
						name: "id",
					},
					{
						type: "Hidden",
						name: "type",
					},
					{
						type: "Hidden",
						name: "direction",
					},
				],
			},
		],
	},
];

export default schema;
