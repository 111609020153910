// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StockpilingModalLoader__loaderWrapper-oxE{margin:0 auto;text-align:center}`, "",{"version":3,"sources":["webpack://./src/modules/Stockpilings/components/StockpilingModalLoader/stockpilingModalLoader.module.scss"],"names":[],"mappings":"AAAA,2CACC,aAAA,CACA,iBAAA","sourcesContent":[".loaderWrapper {\n\tmargin: 0 auto;\n\ttext-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderWrapper": `StockpilingModalLoader__loaderWrapper-oxE`
};
export default ___CSS_LOADER_EXPORT___;
