import { useTranslation } from "pay-kit";
import { Tabs } from "@paykassma/pay-kit";
import OperationHistoryTab from "modules/TransferHistory/components/OperationHistoryTab";
import WithdrawalsRequestPage from "modules/TransferHistory/components/WithdrawalsHistoryTabs/components/WithdrawalsRequestPage";
import { useOperationHistoryContext } from "modules/TransferHistory/contexts/OperationHistoryContext";
import { useEffect, useState } from "react";

import styles from "./withdrawalsHistoryTabs.module.scss";

const WithdrawalsHistoryTabs = () => {
	const { getWithdrawalsList } = useOperationHistoryContext();
	const [tabValue, setTabValue] = useState<number | string>(1);

	const { t } = useTranslation();

	useEffect(getWithdrawalsList, []);

	const tabs = [
		{
			label: t("Withdrawal requests"),
			value: 1,
		},
		{
			label: t("Operation history"),
			value: 2,
		},
	];

	const handleChange = (tabName: string | number) => {
		setTabValue(tabName);
	};

	return (
		<div className={styles.tabsWrapper}>
			<Tabs tabs={tabs} onChange={handleChange} value={tabValue} />

			<div className={styles.panelWrapper}>
				<div className={styles.tabPanel}>{tabValue === 1 ? <WithdrawalsRequestPage /> : <OperationHistoryTab />}</div>
			</div>
		</div>
	);
};

export default WithdrawalsHistoryTabs;
