import { t } from "pay-kit";
import { Button, Modal } from "@paykassma/pay-kit";

import styles from "./ConfirmationDialog.module.scss";

const typeMap = {
	edit: {
		title: t("Edit"),
		text: t("You sure you want to change data?"),
	},
	remove: {
		title: t("Remove"),
		text: t("You sure you want to delete data?"),
	},
};

const ConfirmationDialog = ({ isVisible, onConfirm, onCancel, isLoading, type }) => {
	const locales = typeMap[type];

	return (
		<Modal isOpen={isVisible} title={locales.title} onClose={onCancel}>
			{<p className={styles.text}>{locales.text}</p>}
			<div className={styles.actions}>
				<Button
					data-test-id="onConfirm"
					onClick={onConfirm}
					isLoading={isLoading}
					variant={type === "remove" ? "delete" : "primary"}
				>
					{t("Yes")}
				</Button>
				<Button data-test-id="onCancel" onClick={onCancel} variant="secondary">
					{t("Cancel")}
				</Button>
			</div>
		</Modal>
	);
};

export default ConfirmationDialog;
