import API from "api";
import { UpdatePrivateSettingsType } from "api/settingsGroup";
import { t } from "pay-kit";
import { useState } from "react";
import { errorsMap } from "utils/enums";

const useUpdatePrivateSettings = () => {
	const [error, setError] = useState<Error | null>(null);
	const [isLoading, setIsLoading] = useState(false);

	const update = (form: UpdatePrivateSettingsType) => {
		setError(null);
		setIsLoading(true);

		API.settings
			.updatePrivateSettings(form)
			.then((res) => {
				if (res.status === "success") {
					window.pushAlert({
						type: `success`,
						description: t(`Successfully updated`),
					});
				} else {
					throw new Error(errorsMap.anyResponse);
				}
			})
			.catch((err) => {
				console.log(err);
				setError(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return {
		update,
		isLoading,
		error,
	};
};

export default useUpdatePrivateSettings;
