import { PSBalanceType } from "api/balanceGroup";
import SearchBar from "modules/TransferHistory/components/SearchBar";
import { getFilteredList } from "modules/TransferHistory/helpers";
import { ScrollBox } from "pay-kit";
import { ChangeEvent, Dispatch, FC, useEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";

import styles from "./ButtonsList.module.scss";

const ButtonsList: FC<ButtonsListType> = ({
	balances = [],
	paymentSystem,
	PSBalance,
	setSearchBarValue,
	searchBarValue
}) => {
	const [filteredBalances, setFilteredBalances] = useState<readonly PSBalanceType[]>(balances);

	useEffect(() => {
		setFilteredBalances(balances);
	}, [balances?.length]);

	const isSearchBarVisible = useMemo(() => balances?.length >= 13, [balances?.length]);

	const renderLinks = () => {

		return filteredBalances.map(({ currency_code, id }) => {
			const linkStyles = PSBalance?.currency_code === currency_code ? [styles.link, styles.activeLink].join(" ") : styles.link;
			return (
				<NavLink
					data-test-id="-52NpFjvjyxqlu4wcN0Wx"
					className={linkStyles}
					to={`/operation-history/${paymentSystem}/${id}`}
					key={id}
					onClick={() => setSearchBarValue(currency_code)}
				>
					{currency_code}
				</NavLink>);
		}) ||
			[];
	};

	const resetSearchBar = () => {
		setSearchBarValue("");
		setFilteredBalances(balances);
	};

	const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value;
		const filteredBalances = getFilteredList({ list: balances, fieldKey: "currency_code", value: inputValue });
		setFilteredBalances(filteredBalances);
		setSearchBarValue(inputValue);

	};

	return (
		<div className={styles.buttonListWrapper}>
			{!isSearchBarVisible &&
				<SearchBar
					classNames={{
						wrapperClass: styles.searchBarWrapper,
						inputClass: styles.searchBarInput
					}}
					onChange={handleOnChange}
					resetIcon={true}
					resetValue={resetSearchBar}
					value={searchBarValue}
				/>}
			<ScrollBox className={styles.scrollBox} scrollDirection={"horizontal"}>
				{renderLinks()}
			</ScrollBox>
		</div>);
};

export default ButtonsList;

type ButtonsListType = {
	readonly balances: readonly PSBalanceType[] | undefined
	readonly paymentSystem: string
	readonly PSBalance: PSBalanceType | undefined
	readonly setSearchBarValue: Dispatch<string>
	readonly searchBarValue: string
}
