// import { useNavigate } from "react-router-dom";
import AuthContext from "contexts/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";
import { useTranslation } from "pay-kit";
import React, { useContext, useEffect, useState } from "react";

import { useCreateBalanceOperationsReport } from "./hooks/createBalanceOperations";
import useCreateCommissionReport from "./hooks/createCommissionReport";
import useCreateLabelStatisticsReport from "./hooks/createLabelStatisticsReport";
import useCreateSettlementOperationsReport from "./hooks/createSettlementOperationsReport";
import useCreateTransactionsCompletedListReport from "./hooks/createTransactionsCompletedListReport";
import useCreateTransactionsCompletedSumReport from "./hooks/createTransactionsCompletedSumReport";
import useCreateTransactionsListReport from "./hooks/createTransactionsListReport";
import useCreateTransactionsSumReport from "./hooks/createTransactionsSumReport";
import useCreateWithdrawalsReport from "./hooks/createWithdrawalsReport";
import useGetReports from "./hooks/getReports";
import useGetWallets from "./hooks/getWallets";
import DeleteReportsModal from "./modals/DeleteReports";
import NewReportModal from "./modals/NewReport";
import { ActionsProviderPropsType, IActionsContextValue } from "./types";
import { multiDownload } from "./utils";

export const ActionsContext = React.createContext<IActionsContextValue>({
	getReportsAPI: { load: () => {}, list: [], isLoading: false },
	getWalletsAPI: { load: () => {}, list: [], isLoading: false },
	createTransactionsSumReportAPI: { create: () => {}, isLoading: false },
	createTransactionsListReportAPI: { create: () => {}, isLoading: false },
	createTransactionsCompletedSumReportAPI: { create: () => {}, isLoading: false },
	createTransactionsCompletedListReportAPI: { create: () => {}, isLoading: false },
	createWithdrawalsReportAPI: { create: () => {}, isLoading: false },
	createCommissionReportAPI: { create: () => {}, isLoading: false },
	createLabelStatisticsReportAPI: { create: () => {}, isLoading: false },
	createSettlementOperationsReportAPI: { create: () => {}, isLoading: false },
	createBalanceOperationsReportAPI: { create: async () => {}, isLoading: false },
	setSelectedRows: (ids) => console.log({ ids }),
	selectedRows: [],
	downloadReports: (file_paths) => {
		console.log("downloadReports", { file_paths });
	},
	deleteReports: (reports) => {
		console.log("deleteReports", { reports });
	},
	createNewReport: () => {
		console.log("create new report");
	},
});

const ActionsProvider = ({ children }: ActionsProviderPropsType) => {
	const [selectedRows, setSelectedRows] = useState<IActionsContextValue["selectedRows"]>([]);
	const [newReportModalIsVisible, setNewReportModalVisibility] = useState(false);
	const [reportsToDelete, setReportsToDelete] = useState<readonly string[] | undefined>(undefined);

	const getReportsAPI = useGetReports();
	const getWalletsAPI = useGetWallets();

	const onCreateReportSuccess = () => {
		getReportsAPI.load();
		setNewReportModalVisibility(false);
	};

	const createTransactionsSumReportAPI = useCreateTransactionsSumReport({ onSuccess: onCreateReportSuccess });
	const createTransactionsListReportAPI = useCreateTransactionsListReport({ onSuccess: onCreateReportSuccess });
	const createTransactionsCompletedSumReportAPI = useCreateTransactionsCompletedSumReport({
		onSuccess: onCreateReportSuccess,
	});
	const createTransactionsCompletedListReportAPI = useCreateTransactionsCompletedListReport({
		onSuccess: onCreateReportSuccess,
	});
	const createWithdrawalsReportAPI = useCreateWithdrawalsReport({ onSuccess: onCreateReportSuccess });
	const createCommissionReportAPI = useCreateCommissionReport({ onSuccess: onCreateReportSuccess });
	const createLabelStatisticsReportAPI = useCreateLabelStatisticsReport({ onSuccess: onCreateReportSuccess });
	const createSettlementOperationsReportAPI = useCreateSettlementOperationsReport({ onSuccess: onCreateReportSuccess });
	const createBalanceOperationsReportAPI = useCreateBalanceOperationsReport({ onSuccess: onCreateReportSuccess });

	const { hasRole } = useContext(AuthContext);
	const hasWalletsAccess = hasRole(Roles.WALLET_LIST);

	useEffect(() => {
		getReportsAPI.load();
		if (hasWalletsAccess) {
			getWalletsAPI.load();
		}
	}, [hasWalletsAccess]);

	const downloadReports = (file_paths: readonly string[]) => multiDownload(file_paths);

	const createNewReport = () => setNewReportModalVisibility(true);

	return (
		<ActionsContext.Provider
			value={{
				getReportsAPI,
				selectedRows,
				setSelectedRows,
				downloadReports,
				deleteReports: setReportsToDelete,
				createNewReport,
				getWalletsAPI,
				createTransactionsSumReportAPI,
				createTransactionsListReportAPI,
				createTransactionsCompletedSumReportAPI,
				createTransactionsCompletedListReportAPI,
				createWithdrawalsReportAPI,
				createCommissionReportAPI,
				createLabelStatisticsReportAPI,
				createSettlementOperationsReportAPI,
				createBalanceOperationsReportAPI,
			}}
		>
			<NewReportModal isOpen={newReportModalIsVisible} onClose={() => setNewReportModalVisibility(false)} />
			<DeleteReportsModal
				fileNames={reportsToDelete}
				onClose={() => setReportsToDelete(undefined)}
				onSuccess={() => setReportsToDelete(undefined)}
			/>
			{children}
		</ActionsContext.Provider>
	);
};

export default ActionsProvider;
