// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DeleteReports__modal-fyT .DeleteReports__text-C3H{font-weight:400;font-size:14px;line-height:20px;color:#21272a}.DeleteReports__modal-fyT .DeleteReports__actions-dTY{margin-top:32px;gap:16px;display:flex;align-items:center;justify-content:flex-end}`, "",{"version":3,"sources":["webpack://./src/modules/Reports/ReportList/components/ActionsProvider/modals/DeleteReports/DeleteReports.module.scss"],"names":[],"mappings":"AACI,mDACI,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,aAAA,CAGJ,sDACI,eAAA,CACA,QAAA,CACA,YAAA,CACA,kBAAA,CACA,wBAAA","sourcesContent":[".modal {\n    .text {\n        font-weight: 400;\n        font-size: 14px;\n        line-height: 20px;\n        /* Blue_gray/90 */\n        color: #21272A;\n    }\n\n    .actions {\n        margin-top: 32px;\n        gap: 16px;\n        display: flex;\n        align-items: center;\n        justify-content: flex-end;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `DeleteReports__modal-fyT`,
	"text": `DeleteReports__text-C3H`,
	"actions": `DeleteReports__actions-dTY`
};
export default ___CSS_LOADER_EXPORT___;
