/* eslint-disable react/prop-types */
import { TariffType } from "api/tariffsGroup";

import styles from "./CommissionsEditor.module.scss";
import FixEditor from "./FixEditor";
import PercentEditor from "./PercentEditor";

type Props = {
	readonly title: string;
	readonly onSuccess: () => void;
	readonly commissions?: TariffType["commissions"];
	readonly direction: "ingoing" | "outgoing";
	readonly tariffId: number;
	readonly isLoading: boolean;
};

const CommissionsEditor: React.FC<Props> = ({ title, onSuccess, commissions, direction, tariffId }) => {
	return (
		<div className={styles.wrapper}>
			<h3>{title}</h3>
			<FixEditor
				commissions={commissions?.filter((c) => c.type === "fix") || []}
				tariffId={tariffId}
				onSuccess={onSuccess}
				direction={direction}
			/>
			<PercentEditor
				type="percent"
				commissions={commissions?.filter((c) => c.type === "percent") || []}
				tariffId={tariffId}
				onSuccess={onSuccess}
				direction={direction}
			/>
			{direction === "outgoing" && (
				<PercentEditor
					type="settlement"
					commissions={commissions?.filter((c) => c.type === "settlement") || []}
					tariffId={tariffId}
					onSuccess={onSuccess}
					direction={direction}
				/>
			)}
		</div>
	);
};

export default CommissionsEditor;
