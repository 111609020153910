export const PAYKASSMA_TOKEN_NAME = "PAYKASSMA_TOKEN";
export const PAYKASSMA_REFRESH_TOKEN_NAME = "PAYKASSMA_REFRESH_TOKEN_NAME";
export const TOKEN_UPDATE_EVENT_NAME = "TOKEN_UPDATE";

export const removeRefreshToken = () => {
	localStorage.removeItem(PAYKASSMA_REFRESH_TOKEN_NAME);
}
export const getRefreshToken = (): string => {
	return localStorage.getItem(PAYKASSMA_REFRESH_TOKEN_NAME) || "";
};
export const getToken = (): string => {
	return localStorage.getItem(PAYKASSMA_TOKEN_NAME) || "";
};

export const tokenUpdater = (token: string, refresh_token: string) => {
	if (token && refresh_token) {
		localStorage.setItem(PAYKASSMA_TOKEN_NAME, token);
		localStorage.setItem(PAYKASSMA_REFRESH_TOKEN_NAME, refresh_token);
	} else {
		localStorage.removeItem(PAYKASSMA_TOKEN_NAME);
		removeRefreshToken();
	}
	const event = new CustomEvent(TOKEN_UPDATE_EVENT_NAME, { detail: token });
	document.dispatchEvent(event);
};
