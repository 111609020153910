import { ILimitsItem } from "api/walletTypesGroup";
import { t } from "pay-kit";

export type errorsType = {
	// eslint-disable-next-line functional/prefer-readonly-type
	[key: string]: string | undefined;
};

type validateFormType = (formState: readonly ILimitsItem[]) => errorsType;

export const validateForm: validateFormType = (formState) => {
	const errors: errorsType = {};

	formState.forEach((field) => {
		const currency: string = field.currency as string;

		const depositMin = field.limits.deposit.min;
		const depositMax = field.limits.deposit.max;
		const withdrawalMin = field.limits.withdrawal.min;
		const withdrawalMax = field.limits.withdrawal.max;

		errors[`${currency}.deposit.min.error`] = validateValue(depositMin, { max: depositMax });
		errors[`${currency}.deposit.max.error`] = validateValue(depositMax, { min: depositMin });
		errors[`${currency}.withdrawal.min.error`] = validateValue(withdrawalMin, { max: withdrawalMax });
		errors[`${currency}.withdrawal.max.error`] = validateValue(withdrawalMax, { min: withdrawalMin });
	});

	return errors;
};

const validateValue = (
	value: null | number,
	anotherLimit: { readonly min?: number | null; readonly max?: number | null }
) => {
	const hasMaxValue = !(anotherLimit.max === undefined || anotherLimit.max === null);
	const hasMinValue = !(anotherLimit.min === undefined || anotherLimit.min === null);

	if (value === null) {
		if (hasMaxValue || hasMinValue) {
			return t("Required") as string;
		}
	} else {
		if (isValidNumber(value)) {
			if (value < 0) {
				return t("The value must be greater than 0") as string;
			}

			// @ts-ignore
			if (hasMaxValue && value > parseInt(anotherLimit.max)) {
				return "Minimum > maximum" as string;
			}

			// @ts-ignore
			if (hasMinValue && value < parseInt(anotherLimit.min)) {
				return "Maximum < minimum" as string;
			}
		} else {
			return t("Numbers only") as string;
		}
	}

	return undefined;
};

const isValidNumber = (value: any) => {
	const parsedInt = parseInt(value);
	const parsedFloat = parseFloat(value);

	return !(isNaN(parsedInt) || isNaN(parsedFloat));
};

export const formatValue = (val: number | null) => {
	if (val === null) {
		return "";
	}

	return val.toString();
};