import { LoadWalletsSummaryType } from "api/walletsGroup";
import { reducedWalletsSummaryType } from "modules/Dashboard/components/WalletsTotalBalance";

export const reduceWalletsSummary: ReduceType = (arr) => {

	return arr.reduce((acc, curr) => {

		return acc[curr.wallet_type_name] ?
			({
				...acc, [curr.wallet_type_name]: {
					all: acc[curr.wallet_type_name].all + curr.all,
					worked: acc[curr.wallet_type_name].worked + curr.worked,
					wallet_off_limit: [...acc[curr.wallet_type_name].wallet_off_limit,
						{ [curr.currency]: curr.wallet_off_limit }],
					wallet_off_load: [...acc[curr.wallet_type_name].wallet_off_load,
						{ [curr.currency]: curr.wallet_off_load }]
				}
			}) :
			({
				...acc,
				[curr.wallet_type_name]: {
					all: curr.all,
					worked: curr.worked,
					wallet_off_limit: [{ [curr.currency]: curr.wallet_off_limit }],
					wallet_off_load: [{ [curr.currency]: curr.wallet_off_load }]
				}
			});
	}, {} as reducedWalletsSummaryType);
};

type ReduceType = (arr: readonly LoadWalletsSummaryType[]) => reducedWalletsSummaryType