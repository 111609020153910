import api, { ReportsListItemType } from "api/reportsGroup";
import { useState } from "react";
import { errorsMap } from "utils/enums";

export type getReportsResultType = {
	readonly load: () => void;
	readonly list: readonly ReportsListItemType[];
	readonly isLoading: boolean;
};

export type getReportsHookType = () => getReportsResultType;

const useGetReportsHook = () => {
	const [list, setList] = useState<readonly ReportsListItemType[]>([]);
	const [isLoading, setLoading] = useState<boolean>(false);

	const load = () => {
		setLoading(true);

		api
			.getReports()
			.then((res: any) => {
				setLoading(false);
				if (res.status !== "ok" || !res?.reports) {
					// throw new Error("Unexpected response in get /reports/");
					throw new Error(errorsMap.anyResponse);
				}

				setList(res.reports);

				return res;
			})
			.catch((err) => {
				console.error(err);
				// window.pushAlert({ title: err, type: "error" });
				setLoading(false);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return {
		load,
		list,
		isLoading,
	};
};

export default useGetReportsHook;
