import { Badge, t } from "@paykassma/pay-kit";
import { ReportStatus } from "api/reportsGroup";

type StatusParams = {
	readonly message: string;
	readonly variant: React.ComponentProps<typeof Badge>["variant"];
};

export const reportParamsByStatus: Record<ReportStatus, StatusParams> = {
	[ReportStatus.NEW]: {
		message: t(`New`),
		variant: `blue`,
	},
	[ReportStatus.IN_PROGRESS]: {
		message: t(`In progress`),
		variant: `orange`,
	},
	[ReportStatus.SUCCESS]: {
		message: t(`Completed`),
		variant: `green`,
	},
	[ReportStatus.FAIL]: {
		message: t(`Error`),
		variant: `red`,
	},
};

type StatusBadgeProps = {
	readonly status: ReportStatus;
};

const StatusBadge = ({ status }: StatusBadgeProps) => {
	const badgeParams = reportParamsByStatus[status];

	return <Badge variant={badgeParams.variant}>{badgeParams.message}</Badge>;
};

export default StatusBadge;
