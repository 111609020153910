import { Tooltip } from "@paykassma/pay-kit";
import { ICurrentTransaction, IPossibleTransaction } from "api/transactionGroup";
import ColumnTitleSeparator from "components/ColumnTitleSeparator";
import Money from "components/Money";
import PaymentSystemLogo from "components/PaymentSystemLogo";
import TextWithCopyButton from "components/TextWithCopyButton";
import { Roles } from "contexts/AuthContext";
import { useAuthContext } from "contexts/AuthContext/AuthContext";
import { t } from "pay-kit";
import { toLocaleDate } from "utils/date";

import Actions from "../../CurrentTransactions/Actions";
import PossibleTransactionsActions from "../../PossibleTransactions/Actions";
import CreationType from "../CreationType";
import PossibleTransactionStatus from "../PossibleTransactionStatus";
import PostbackStatus from "../PostbackStatus";
import TransactionStatus from "../TransactionStatus";
import TransactionType from "../TransactionType";
import WarningIcon from "./assets/icons/warning_16x16.svg";
import styles from "./Columns.module.scss";

export const currentTransactionsTableSubRow = ({
	status,
	postback_status,
	type,
	creation_type,
	primary_id,
	direction,
	is_fake,
	id,
}: ICurrentTransaction) => {
	const { hasRole } = useAuthContext();

	return (
		<div className={styles.subRow}>
			{is_fake && (
				<Tooltip tip={t("Scam")} preferredSide="bottom">
					<WarningIcon />
				</Tooltip>
			)}
			<TransactionStatus status={status} dataTestId="status" />
			{hasRole(Roles.POSTBACKS) && (
				<>
					<ColumnTitleSeparator />
					<PostbackStatus status={postback_status} dataTestId="postback_status" />
				</>
			)}
			{hasRole(Roles.DISPLAY_TRANSACTION_TYPE) && (
				<>
					<ColumnTitleSeparator />
					<CreationType type={creation_type} dataTestId="creation_type" />
				</>
			)}
			{hasRole(Roles.DISPLAY_TRANSACTION_TYPE) && (
				<>
					<ColumnTitleSeparator />
					<TransactionType type={type} dataTestId="type" />
				</>
			)}
			<Actions primary_id={primary_id} id={id} status={status} type={type} direction={direction} is_fake={is_fake} />
		</div>
	);
};

export const archivedTransactionsTableColumns = (commissionAccess: boolean) => [
	{
		title: t("Wallet type"),
		dataIndex: "wallet_type",
		key: "wallet_type",
		render: ({ wallet_type }: ICurrentTransaction) => <PaymentSystemLogo id={wallet_type} dataTestId="wallet_type" />,
	},
	{
		title: (
			<>
				ID <ColumnTitleSeparator /> {t("Wallet number")}{" "}
			</>
		),
		dataIndex: "primary_id",
		key: "primary_id",
		render: ({ primary_id, wallet_identifier }: ICurrentTransaction) => (
			<>
				<div data-test-id="primary_id">{primary_id}</div>
				<TextWithCopyButton
					className={styles.walletIdentifier}
					text={wallet_identifier}
					dataTestId="wallet_identifier"
				/>
			</>
		),
	},
	{
		title: (
			<>
				{t("Amount")}
				{commissionAccess && (
					<>
						<ColumnTitleSeparator /> {t("Total commission")}
					</>
				)}
			</>
		),
		dataIndex: "amount",
		key: "amount",
		render: ({ amount, total_commission, direction, currency_code }: ICurrentTransaction) => (
			<>
				<Money direction={direction} amount={amount} currencyCode={currency_code} dataTestId="amount" />
				{commissionAccess && (
					<Money
						amount={total_commission}
						currencyCode={currency_code}
						className={styles.totalCommission}
						dataTestId="total_commission"
					/>
				)}
			</>
		),
	},
	{
		title: t("Balance"),
		dataIndex: "wallet_balance",
		key: "wallet_balance",
		render: ({ wallet_balance, currency_code }: ICurrentTransaction) =>
			wallet_balance !== undefined && (
				<Money amount={wallet_balance} currencyCode={currency_code} dataTestId="wallet_balance" />
			),
	},
	{
		title: t("Archiving date"),
		dataIndex: "removed_at",
		key: "removed_at",
		dataTestId: "removed_at",
		render: ({ removed_at }: ICurrentTransaction) => (
			<>
				<div data-test-id="removed_at">{toLocaleDate(removed_at)}</div>
			</>
		),
	},
	{
		// title: "Дата созд./актив.",
		title: t("Creation date"),
		dataIndex: "created_at",
		key: "created_at",
		render: ({ created_at, updated_at }: ICurrentTransaction) => (
			<>
				<div data-test-id="created_at">{toLocaleDate(created_at)}</div>
				<div data-test-id="updated_at">{toLocaleDate(updated_at)}</div>
			</>
		),
	},
	{
		title: (
			<>
				{/* {t("Label")}<ColumnTitleSeparator /> Номер заказа */}
				{t("Label")}
				<ColumnTitleSeparator /> {t("Order ID")}{" "}
			</>
		),
		dataIndex: "label",
		key: "label",
		render: ({ label, merchant_order_id }: ICurrentTransaction) => (
			<>
				<TextWithCopyButton textClassName={styles.label} text={label} dataTestId="label" />
				<TextWithCopyButton
					className={styles.merchantOrderIdBadge}
					textClassName={styles.merchantOrderId}
					text={merchant_order_id}
					dataTestId="merchant_order_id"
				/>
			</>
		),
	},
	{
		title: (
			<>
				{/* {t("Transaction number")}<ColumnTitleSeparator />  */}
				{t("Transaction number")}
				<ColumnTitleSeparator /> {t("Counterparty")}
			</>
		),
		dataIndex: "id",
		key: "id",
		render: ({ id, exchanger_identifier }: ICurrentTransaction) => (
			<>
				<TextWithCopyButton text={id} dataTestId="id" />
				<div className={styles.exchangerIdentifier} data-test-id="exchanger_identifier">
					{exchanger_identifier}
				</div>
			</>
		),
	},
];

export const archivedTransactionsTableSubRow = ({
	status,
	postback_status,
	creation_type,
	type,
	is_fake,
}: ICurrentTransaction) => (
	<div className={styles.subRow}>
		{is_fake && (
			// <Tooltip tip="Фрод" preferredSide="bottom">
			<Tooltip tip={t("Scam")} preferredSide="bottom">
				<WarningIcon />
			</Tooltip>
		)}
		<TransactionStatus status={status} dataTestId="status" />
		<ColumnTitleSeparator />
		<PostbackStatus status={postback_status} dataTestId="postback_status" />
		<ColumnTitleSeparator />
		<CreationType type={creation_type} dataTestId="creation_type" />
		<ColumnTitleSeparator />
		<TransactionType type={type} dataTestId="type" />
	</div>
);

export const possibleTransactionsTableColumns = [
	{
		title: t("Wallet type"),
		dataIndex: "wallet_type",
		key: "wallet_type",
		render: ({ wallet_type }: IPossibleTransaction) => <PaymentSystemLogo id={wallet_type} dataTestId="wallet_type" />,
	},
	{
		title: "ID",
		dataIndex: "primary_id",
		key: "primary_id",
		dataTestId: "primary_id",
	},
	{
		title: t("Transaction number"),
		dataIndex: "id",
		key: "id",
		render: ({ id }: IPossibleTransaction) => <TextWithCopyButton text={id} dataTestId="id" />,
	},
	{
		title: t("Amount"),
		dataIndex: "amount",
		key: "amount",
		render: ({ amount, currency_symbol }: IPossibleTransaction) => (
			<Money amount={amount} currencyCode={currency_symbol} dataTestId="amount" />
		),
	},
	{
		title: (
			<>
				{t("Label")}
				<ColumnTitleSeparator /> {t("Order ID")}
			</>
		),
		dataIndex: "label",
		key: "label",
		render: ({ label, merchant_order_id }: IPossibleTransaction) => (
			<>
				<TextWithCopyButton textClassName={styles.label} text={label} dataTestId="label" />
				<TextWithCopyButton
					className={styles.merchantOrderIdBadge}
					textClassName={styles.merchantOrderId}
					text={merchant_order_id}
					dataTestId="merchant_order_id"
				/>
			</>
		),
	},
	{
		title: t("Creation date"),
		dataIndex: "created_at",
		key: "created_at",
		dataTestId: "created_at",
		render: ({ created_at }: ICurrentTransaction) => (
			<>
				<div data-test-id="created_at">{toLocaleDate(created_at)}</div>
			</>
		),
	},
];

export const possibleTransactionsTableSubRow = ({
	status,
	primary_id,
	screenshot,
	is_manual,
}: IPossibleTransaction) => (
	<div className={styles.subRow}>
		<PossibleTransactionStatus status={status} dataTestId="status" />
		<PossibleTransactionsActions
			status={status}
			primary_id={primary_id}
			screenshot={screenshot}
			is_manual={is_manual}
		/>
	</div>
);
