// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OperationHistoryTab__filterWrapper-BzF{margin-top:36px}.OperationHistoryTab__reloadButtonWrapper-GDW{margin-top:44px}.OperationHistoryTab__tableWrapper-j3u{margin-top:32px;margin-bottom:35px}.OperationHistoryTab__target-Uzs{height:20px}.OperationHistoryTab__additionalInfo-x70{width:max-content}`, "",{"version":3,"sources":["webpack://./src/modules/TransferHistory/components/OperationHistoryTab/operationHistory.module.scss"],"names":[],"mappings":"AAAA,wCACC,eAAA,CAGD,8CACC,eAAA,CAGD,uCACC,eAAA,CACA,kBAAA,CAGD,iCACC,WAAA,CAGD,yCACC,iBAAA","sourcesContent":[".filterWrapper {\n\tmargin-top: 36px;\n}\n\n.reloadButtonWrapper {\n\tmargin-top: 44px;\n}\n\n.tableWrapper {\n\tmargin-top: 32px;\n\tmargin-bottom: 35px;\n}\n\n.target {\n\theight: 20px;\n}\n\n.additionalInfo {\n\twidth: max-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterWrapper": `OperationHistoryTab__filterWrapper-BzF`,
	"reloadButtonWrapper": `OperationHistoryTab__reloadButtonWrapper-GDW`,
	"tableWrapper": `OperationHistoryTab__tableWrapper-j3u`,
	"target": `OperationHistoryTab__target-Uzs`,
	"additionalInfo": `OperationHistoryTab__additionalInfo-x70`
};
export default ___CSS_LOADER_EXPORT___;
