import { useTranslation } from "pay-kit";
import { Tooltip } from "pay-kit";
import React from "react";

interface ICreationType {
	readonly type: "manual" | "auto";
	readonly dataTestId?: string;
}

const CreationType: React.FC<ICreationType> = ({ type, dataTestId }) => {
	const { t } = useTranslation();

	if (type === "auto") {
		return (
			// <Tooltip tip="Тип транзакции: Авто" preferredSide="bottom">
			<Tooltip tip={t("Transaction type: Auto")} preferredSide="bottom">
				<svg
					width="25"
					height="25"
					viewBox="0 0 25 25"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					data-test-id={dataTestId}
				>
					<path
						d="M8.68111 19.7725H6.35156L11.5859 5.22705H14.1214L19.3558 19.7725H17.0263L12.9141 7.8691H12.8004L8.68111 19.7725ZM9.07173 14.0765H16.6286V15.9231H9.07173V14.0765Z"
						fill="#21272A"
					/>
				</svg>
			</Tooltip>
		);
	}

	if (type === "manual") {
		return (
			// <Tooltip tip="Тип транзакции: Ручная" preferredSide="bottom">
			<Tooltip tip={t("Transaction type: Manual")} preferredSide="bottom">
				{/* иконка Р */}
				{/* <svg
					width="25"
					height="25"
					viewBox="0 0 25 25"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					data-test-id={dataTestId}
				>
					<path
						d="M8.85352 19.7725V5.22705H14.0382C15.1698 5.22705 16.1073 5.43302 16.8507 5.84495C17.594 6.25688 18.1504 6.82033 18.5197 7.53529C18.889 8.24552 19.0737 9.04571 19.0737 9.93586C19.0737 10.8307 18.8867 11.6357 18.5126 12.3506C18.1433 13.0609 17.5846 13.6243 16.8365 14.041C16.0931 14.4529 15.158 14.6589 14.0311 14.6589H10.4657V12.7981H13.8322C14.5472 12.7981 15.1272 12.675 15.5723 12.4288C16.0173 12.1778 16.344 11.8369 16.5524 11.406C16.7607 10.9752 16.8649 10.4851 16.8649 9.93586C16.8649 9.38662 16.7607 8.89893 16.5524 8.47279C16.344 8.04665 16.015 7.71285 15.5652 7.47137C15.1201 7.22989 14.533 7.10915 13.8038 7.10915H11.0481V19.7725H8.85352Z"
						fill="#21272A"
					/>
				</svg> */}
				{/* Иконка М */}
				<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path 
						d="M4.56836 5.17676H7.25034L11.9134 16.5626H12.085L16.7481 5.17676H19.4301V19.8239H17.3274V9.22475H17.1915L12.8718 19.8025H11.1267L6.80692 9.2176H6.67103V19.8239H4.56836V5.17676Z" fill="black"/>
				</svg>

			</Tooltip>
		);
	}

	return type;
};

export default CreationType;
