// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Status__new-ZnX{width:max-content;font-weight:500;font-size:14px;text-transform:uppercase;padding:0 8px;border-radius:100px;color:#0072c3;background:#f1faff}.Status__successful-LSo{width:max-content;font-weight:500;font-size:14px;text-transform:uppercase;padding:0 8px;border-radius:100px;color:#24a148;background:#e8faed}.Status__declined-fnw{width:max-content;font-weight:500;font-size:14px;text-transform:uppercase;padding:0 8px;border-radius:100px;color:#e8882e;background:#fff4e2}`, "",{"version":3,"sources":["webpack://./src/modules/TransferHistory/components/WithdrawalsHistoryTabs/components/Status/status.module.scss"],"names":[],"mappings":"AASA,iBARC,iBAAA,CACA,eAAA,CACA,cAAA,CACA,wBAAA,CACA,aAAA,CACA,mBAAA,CAKA,aAAA,CACA,kBAAA,CAGD,wBAdC,iBAAA,CACA,eAAA,CACA,cAAA,CACA,wBAAA,CACA,aAAA,CACA,mBAAA,CAWA,aAAA,CACA,kBAAA,CAGD,sBApBC,iBAAA,CACA,eAAA,CACA,cAAA,CACA,wBAAA,CACA,aAAA,CACA,mBAAA,CAiBA,aAAA,CACA,kBAAA","sourcesContent":["@mixin status {\n\twidth: max-content;\n\tfont-weight: 500;\n\tfont-size: 14px;\n\ttext-transform: uppercase;\n\tpadding: 0 8px;\n\tborder-radius: 100px;\n}\n\n.new {\n\t@include status;\n\tcolor: #0072C3;\n\tbackground: #F1FAFF;\n}\n\n.successful {\n\t@include status;\n\tcolor: #24A148;\n\tbackground: #E8FAED;\n}\n\n.declined {\n\t@include status;\n\tcolor: #E8882E;\n\tbackground: #FFF4E2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"new": `Status__new-ZnX`,
	"successful": `Status__successful-LSo`,
	"declined": `Status__declined-fnw`
};
export default ___CSS_LOADER_EXPORT___;
