import { useTranslation } from "pay-kit";
import DropdownButton from "components/DropdownButton";
import { Tooltip } from "pay-kit";
import { useContext } from "react";

import { ActionsContext } from "../../ActionsProvider";
import styles from "./Actions.module.scss";
import ContentViewIconSVG from "./icons/content_view.svg";
import CurrencyIconSVG from "./icons/currency.svg";
// import DeleteIconSVG from './icons/delete.svg'; // TODO: раскомментировать когда будет готова фича
// import EditIconSVG from './icons/edit.svg';
import LimitsIconSVG from "./icons/limits.svg";
import MoreIconSVG from "./icons/more.svg";
import SettingsIconSVG from "./icons/settings.svg";
import TariffsIconSVG from "./icons/tariffs.svg";

type ActionsPropsType = {
	readonly type: string;
	readonly dataTestId?: string
}

const Actions: React.FC<ActionsPropsType> = ({ type, dataTestId }) => {
	const {t} = useTranslation();
	const actionsContext = useContext(ActionsContext);

	return (
		<div className={styles.psActions}>
			<Tooltip tip={t("Tariffs")} preferredSide="bottom">
				<button data-test-id="tariffsButton" onClick={() => actionsContext.showTariffSettings(type)}>
					<TariffsIconSVG />
				</button>
			</Tooltip>
			<Tooltip tip={t("Limits")} preferredSide="bottom">
				<button data-test-id="limitsButton" onClick={() => actionsContext.showLimitsSettings(type)}>
					<LimitsIconSVG />
				</button>
			</Tooltip>
			<DropdownButton className={styles.dropdownButton} dropdownClassName={styles.dropdown} dataTestId={dataTestId}
				options={[
					{
						id: 1,
						dataTestId: "currencyButton",
						title: <><CurrencyIconSVG /> {t("Currencies")}</>,
						onClick: () => actionsContext.showCurrenciesSettings(type)
					},
					{
						id: 2,
						dataTestId: "contentViewButton",
						title: <><ContentViewIconSVG /> {t("View")}</>,
						onClick: () => actionsContext.showViewSettings(type)
					},
					{
						id: 3,
						dataTestId: "settingsButton",
						title: <><SettingsIconSVG /> {t("Settings")}</>,
						onClick: () => actionsContext.showCommonSettings(type)
					}
					// TODO: раскомментировать когда будут готовы backend методы
					// { id: 4, title: <><EditIconSVG /> {t("Edit")}</>, onClick: () => actionsContext.showEditingModal(type) },
					// { id: 5, title: <><DeleteIconSVG /> {t("Delete")}</>, onClick: () => actionsContext.showRemoveConfirmation(type) },
				]} data-test-id="">
				<MoreIconSVG />
			</DropdownButton>
		</div>
	);
};

export default Actions;