import styles from "./SkeletonLoader.module.scss";

const SkeletonLoader = () => {
	const box = (
		<div className={styles.box}>
			<div className={styles.header}></div>
			<div className={styles.sub2Header}></div>
			<div className={styles.sub3Header}></div>
			<div className={styles.fixField}></div>
			<div className={styles.sub4Header}></div>
			<div className={styles.fields1Row}></div>
			<div className={styles.fields2Row}></div>
			<div className={styles.action}></div>
		</div>
	);

	return (
		<div className={styles.skeletonLoader}>
			{box}
			{box}
		</div>
	);
};

export default SkeletonLoader;
