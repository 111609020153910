import { useTranslation } from "pay-kit";
import { FC } from "react";

import styles from "./status.module.scss";

export enum Statuses {
	NEW,
	SUCCESS,
	DECLINED,
}

const Status: FC<{ readonly status: Statuses }> = ({ status }) => {
	const { t } = useTranslation();

	const labels = {
		[Statuses.NEW]: t("New"),
		[Statuses.SUCCESS]: t("Success"), // "Успешный",
		[Statuses.DECLINED]: t("Rejected"), //"Отклоненный"
	};

	const style = {
		[Statuses.NEW]: styles.new,
		[Statuses.SUCCESS]: styles.successful,
		[Statuses.DECLINED]: styles.declined,
	};

	return <div className={style[status]}>{labels[status]}</div>;
};

export default Status;
