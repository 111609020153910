import { OldResponse } from "api/types";
import { WalletTypeType } from "api/walletGroup";
import { DeleteModal } from "components/Modals/DeleteModal/DeleteModal";
import { useTranslation } from "pay-kit";
import { ModalProps } from "utils/types";

type ArchiveWalletModalProps = ModalProps & {
	readonly hash_id: WalletTypeType[`hash_id`];
	readonly actions: {
		readonly archiveWallet: (hash_id: WalletTypeType[`hash_id`]) => Promise<OldResponse<void>>;
	};
};

export const ArchiveWalletModal = ({ closeModal, actions, hash_id }: ArchiveWalletModalProps) => {
	const { t } = useTranslation();
	return (
		<DeleteModal
			closeModal={closeModal}
			text={<>{t("Are you sure you want to archive wallet {{hash_id}}?", { hash_id })}</>}
			closeLabel={t("Cancel")}
			deleteLabel={t("Archive")}
			actions={{
				onDelete: () => {
					return actions.archiveWallet(hash_id).then(() => {
						window.pushAlert({
							type: "success",
							title: t("Success"),
							description: t("Wallet {{hash_id}} successfully archived", { hash_id }),
						});
					});
				},
			}}
		/>
	);
};
