import Api from "api";
import { InitCheckingMultiAddingProxiesType } from "api/proxiesGroup";
import { stringifyErrorMessage } from "helpers/stringifyErrorMessage";
import { useTranslation } from "pay-kit";
import { useState } from "react";

const useInitCheckingMultiAddingProxy = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<Error | null>(null);
	const { t } = useTranslation();

	const create = (params: InitCheckingMultiAddingProxiesType) => {
		setIsLoading(true);
		setError(null);

		Api.proxies
			.initCheckingMultiAddingProxy(params)
			.then((res) => {
				if (res.status === "ok") {
					window.pushAlert({
						description: t(`All proxies successfully checked!`),
						type: "success",
					});
				}
				if (res.status === "fail") {
					window.pushAlert({
						description: stringifyErrorMessage(res.message),
						type: "error",
					});
				}
			})
			.catch((err) => {
				console.log(err);
				setError(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return {
		create,
		isLoading,
		error,
	};
};

export default useInitCheckingMultiAddingProxy;
