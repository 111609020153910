import { CommonWalletData, WalletListItem } from "api/walletGroup";
import AuthContext from "contexts/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";
import { WorkingWalletContext } from "modules/Wallets/contexts/WorkingWalletContext";
import NotVisibleIcon from "modules/Wallets/WorkingWallets/components/Togglers/VisibilityToggler/icons/NotVisibleIcon";
import VisibleIcon from "modules/Wallets/WorkingWallets/components/Togglers/VisibilityToggler/icons/VisibleIcon";
import { useTranslation } from "pay-kit";
import { Tooltip } from "pay-kit";
import { useContext, useMemo, useState } from "react";

import styles from "../Toggler.module.scss";

export const VisibilityToggler = ({ plugin_status, hash_id }: VisibilityTogglerProps) => {
	const { t } = useTranslation();
	const { updateSetting } = useContext(WorkingWalletContext);
	const { hasRole } = useContext(AuthContext);

	const updateAccess = hasRole(Roles.WALLET_SETTINGS);

	const [visibilityState, setVisibilityState] = useState<boolean>(plugin_status || false);

	const handleClick = () => {
		if (updateAccess) {
			updateSetting({ hash_id, plugin_status: !visibilityState }).then((resp) => {
				if (resp.status === "success") {
					setVisibilityState((state) => !state);
					window.pushAlert({
						type: "success",
						title: t("Success"),
						description: t("Visibility of wallet {{hash_id}} successfully changed", { hash_id }),
					});
				}
			});
		}
	};

	const className = useMemo(() => {
		const classes = [styles.button];

		if (visibilityState) {
			classes.push(styles.active);
		} else {
			classes.push(styles.not_active);
		}

		return classes.join(` `);
	}, [visibilityState]);

	const standardTip = visibilityState ? t("On") : t("Off");
	const dataTestId = visibilityState ? "visibilityButton" : "notVisibilityButton";

	return (
		<Tooltip tip={`${t("Visibility")} : ${standardTip}`} preferredSide="bottom">
			<button data-test-id={dataTestId} className={className} disabled={!updateAccess} onClick={handleClick}>
				{visibilityState ? <VisibleIcon /> : <NotVisibleIcon />}
			</button>
		</Tooltip>
	);
};

type VisibilityTogglerProps = {
	readonly hash_id: CommonWalletData["hash_id"];
	readonly plugin_status: WalletListItem["plugin_status"];
};
