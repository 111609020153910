import { ChangeLogType } from "api/changeLogGroup";
import CaretIcon from "components/Icons/CaretIcon";
import { Button } from "pay-kit";
import { FC, useState } from "react";

import styles from "./logInfoViewer.module.scss";

const defaultText = {
	showMore: "Show more",
	showLess: "Show less",
};

const Spoiler: FC<SpoilerType> = ({ infoList, count = 2, buttonText = defaultText, dataTestId }) => {
	const [rowCount, setRowCount] = useState<RowCountType>(count);
	const [textKey, setTextKey] = useState<ButtonTextType>("showMore");

	const renderInfoStatus = () =>
		infoList.slice(0, rowCount).map((row, i) => {
			const infoStatus = /^(?<status>[^:]+):\s(?<dateOrTex>.+)(?<timeText>\s[\d:]+)?/.exec(row)?.groups;

			return infoStatus ? (
				<div key={i} className={styles.logInfoBlock} data-test-id={dataTestId}>
					<span className={styles.status}>
						{infoStatus?.status} {infoStatus?.status ? ":" : null}{" "}
					</span>
					<span className={styles.text}>{infoStatus?.dateOrTex}</span>
					<span className={styles.text}>{infoStatus?.timeText}</span>
				</div>
			) : null;
		});

	const OnButtonClick = () => {
		setTextKey((textKey) => (textKey === "showMore" ? "showLess" : "showMore"));
		setRowCount((rowCount) => (rowCount ? undefined : count));
	};

	const caretIconStyle = textKey === "showMore" ? styles.caret : [styles.caret, styles.caretUp].join(" ");

	return (
		<div className={styles.logInfoViewerWrapper}>
			{renderInfoStatus()}
			<Button
				data-test-id="VH5wbXqalWHNHbMRb9hgK"
				classname={styles.logCaret}
				onClick={OnButtonClick}
				dataTestId="spoilerButton"
			>
				{infoList.length > count && (
					<>
						<CaretIcon className={caretIconStyle} />
						{buttonText[textKey]}
					</>
				)}
			</Button>
		</div>
	);
};

export default Spoiler;

type SpoilerType = {
	readonly infoList: ChangeLogType["old_value"];
	readonly count?: number;
	readonly buttonText?: {
		readonly showMore: string;
		readonly showLess: string;
	};
	readonly dataTestId?: string;
};

type RowCountType = number | undefined;
type ButtonTextType = "showMore" | "showLess";
