// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Section__pluginSectionWrapper-ykN{margin-top:36px;background:#fcfcfd}.Section__pluginSectionWrapper-ykN .Section__header-MoJ{font-size:18px;font-style:normal;font-weight:500;padding:13px 16px;border-radius:8px 8px 0 0;border-bottom:1px solid #dde1e6}`, "",{"version":3,"sources":["webpack://./src/modules/AppSettings/components/Section/section.module.scss"],"names":[],"mappings":"AAAA,mCACE,eAAA,CACA,kBAAA,CAEA,wDACE,cAAA,CACA,iBAAA,CACA,eAAA,CACA,iBAAA,CACA,yBAAA,CACA,+BAAA","sourcesContent":[".pluginSectionWrapper {\n  margin-top: 36px;\n  background: #FCFCFD;\n\n  .header {\n    font-size: 18px;\n    font-style: normal;\n    font-weight: 500;\n    padding: 13px 16px;\n    border-radius: 8px 8px 0 0;\n    border-bottom: 1px solid #DDE1E6;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pluginSectionWrapper": `Section__pluginSectionWrapper-ykN`,
	"header": `Section__header-MoJ`
};
export default ___CSS_LOADER_EXPORT___;
