// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PostbackStatus__PostbackStatus-zIQ{display:inline-flex;justify-content:center;align-items:center;gap:8px}.PostbackStatus__PostbackStatus-zIQ span{font-weight:400;font-size:14px;line-height:20px;color:#21272a}`, "",{"version":3,"sources":["webpack://./src/modules/Withdrawals/components/columns/components/PostbackStatus/PostbackStatus.module.scss"],"names":[],"mappings":"AAAA,oCACI,mBAAA,CACA,sBAAA,CACA,kBAAA,CACA,OAAA,CAEA,yCACI,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,aAAA","sourcesContent":[".PostbackStatus {\n    display: inline-flex;\n    justify-content: center;\n    align-items: center;\n    gap: 8px;\n\n    span {\n        font-weight: 400;\n        font-size: 14px;\n        line-height: 20px;\n        /* Blue_gray/90 */\n        color: #21272A;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PostbackStatus": `PostbackStatus__PostbackStatus-zIQ`
};
export default ___CSS_LOADER_EXPORT___;
