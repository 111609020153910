// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tariffs__filter-XQ2{display:flex;gap:38px;margin-top:36px}.Tariffs__filter-XQ2 .Tariffs__select-y0X{width:250px}.Tariffs__paymentSystemOptionLabel-Y8b{display:flex;gap:12px}.Tariffs__paymentSystemOptionLabel-Y8b .Tariffs__logo-PG8.Tariffs__logo-PG8{height:20px;width:60px}.Tariffs__switcher-Ny8{margin-top:36px}.Tariffs__commissionsWrapper-WWS{position:relative;display:grid;gap:24px;grid-template-columns:1fr 1fr;max-width:100%}`, "",{"version":3,"sources":["webpack://./src/modules/PaymentSystems/Tariffs/Tariffs.module.scss"],"names":[],"mappings":"AAAA,qBACI,YAAA,CACA,QAAA,CACA,eAAA,CAEA,0CACI,WAAA,CAIR,uCACI,YAAA,CACA,QAAA,CAEA,4EACI,WAAA,CACA,UAAA,CAIR,uBACI,eAAA,CAGJ,iCACI,iBAAA,CACA,YAAA,CACA,QAAA,CACA,6BAAA,CACA,cAAA","sourcesContent":[".filter {\n    display: flex;\n    gap: 38px;\n    margin-top: 36px;\n\n    .select {\n        width: 250px;\n    } \n}\n\n.paymentSystemOptionLabel {\n    display: flex;\n    gap: 12px;\n\n    .logo.logo {\n        height: 20px;\n        width: 60px;\n    }\n}  \n\n.switcher {\n    margin-top: 36px;\n}\n\n.commissionsWrapper {\n    position: relative;\n    display: grid;\n    gap: 24px;\n    grid-template-columns: 1fr 1fr;\n    max-width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter": `Tariffs__filter-XQ2`,
	"select": `Tariffs__select-y0X`,
	"paymentSystemOptionLabel": `Tariffs__paymentSystemOptionLabel-Y8b`,
	"logo": `Tariffs__logo-PG8`,
	"switcher": `Tariffs__switcher-Ny8`,
	"commissionsWrapper": `Tariffs__commissionsWrapper-WWS`
};
export default ___CSS_LOADER_EXPORT___;
