import API from "api";
import { IStatusListItem } from "api/withdrawalGroup";
import React, { useEffect, useState } from "react";
import { errorsMap } from "utils/enums";

export const StatusesContext = React.createContext({
	list: [] as readonly IStatusListItem[],
	isLoading: false,
});

type statusesContextProviderPropsType = {
	readonly children: React.ReactNode;
};

const StatusesContextProvider = ({ children }: statusesContextProviderPropsType) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [list, setList] = useState<readonly IStatusListItem[]>([]);

	const getStatusesList = () => {
		setIsLoading(true);
		setList([]);

		API.withdrawal
			.getStatuses()
			.then((res: any) => {
				setIsLoading(false);

				if (res?.status === "ok" && res?.statuses) {
					setList(Object.values(res?.statuses));
				} else {
					// throw new Error("Invalid response");
					throw new Error(errorsMap.anyResponse);
				}

				return res;
			})
			.catch((err) => {
				setIsLoading(false);
				window.pushAlert({ type: "error", description: errorsMap.anyResponse });
			});
	};

	useEffect(getStatusesList, []);

	return (
		<StatusesContext.Provider value={{
			isLoading,
			list,
		}}>
			{children}
		</StatusesContext.Provider>
	);
};

export default StatusesContextProvider;