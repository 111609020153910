// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImportReportModal__form-y_v{display:flex;flex-direction:column;width:100%;gap:28px}.ImportReportModal__actions-niJ{display:flex;flex:1;align-self:flex-end}`, "",{"version":3,"sources":["webpack://./src/modules/Withdrawals/components/ActionsProvider/ImportReportModal/ImportReportModal.module.scss"],"names":[],"mappings":"AAAA,6BACI,YAAA,CACA,qBAAA,CACA,UAAA,CACA,QAAA,CAGJ,gCACI,YAAA,CACA,MAAA,CACA,mBAAA","sourcesContent":[".form {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    gap: 28px;\n}\n\n.actions {\n    display: flex;\n    flex: 1;\n    align-self: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `ImportReportModal__form-y_v`,
	"actions": `ImportReportModal__actions-niJ`
};
export default ___CSS_LOADER_EXPORT___;
