import { useTranslation } from "pay-kit";
import Accounts from "modules/Balance/Accounts";
import { useEffect } from "react";
import PageTitle from "components/PageTitle";


const AccountsBalancesPage = () => {
	const {t} = useTranslation();
	useEffect(() => {
		document.title = t("Accounts balance");
	}, []);

	return (
		<>
			<PageTitle title={t("Accounts balance")} />
			<Accounts />
		</>
	);
};

export default AccountsBalancesPage;
