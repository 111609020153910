import CopyTextButton from "components/CopyTextButton";
import { Tooltip } from "pay-kit";
import React from "react";

import styles from "./ResponseJSON.module.scss";

type ResponseJSONPropsType = {
	readonly value: string;
	readonly dataTestId?: string
};

const ResponseJSON: React.FC<ResponseJSONPropsType> = ({ value, dataTestId }) => {
	return (
		<div className={styles.ResponseJSONContainer}>
			<Tooltip tip={value} preferredSide="bottom">
				<div className={styles.Response} data-test-id={dataTestId}>{value}</div>
			</Tooltip>
			<CopyTextButton text={value} />
		</div>
	);
};

export default ResponseJSON;
