import { useTranslation } from "pay-kit";
import { ModalTypes, StockpilingsContext } from "modules/Stockpilings/components/Columns/contexts/StockpilingsContext";
import StockpilingModalLoader from "modules/Stockpilings/components/StockpilingModalLoader";
import { Button } from "pay-kit";
import { FC, useContext } from "react";

import styles from "./stockpilingsActions.module.scss";
import { useAuthContext } from "contexts/AuthContext/AuthContext";
import { Roles } from "contexts/AuthContext";

export type StockpilingsActionsType = {
	readonly postback_send: number;
	readonly stockpiling_id: number;
	readonly direction: string;
	readonly postback_id: number;
};

const StockpilingsActions: FC<StockpilingsActionsType> = ({
	postback_send,
	stockpiling_id,
	direction,
	postback_id,
}) => {
	const { t } = useTranslation();
	const { hasRole } = useAuthContext();
	const resendPostbackAccess = hasRole(Roles.POSTBACK_SENDING);
	const { getListByStockPiling, getPostbackList, showModal, getStockpilingId, isLoading, modalType } =
		useContext(StockpilingsContext);

	const buttonValue = {
		[ModalTypes.TRANSACTIONS]: t("Transactions"),
		[ModalTypes.POSTBACK]: t("Postback"),
	};

	const text = (type: string) => (modalType === type && isLoading ? <StockpilingModalLoader /> : buttonValue[type]);

	return (
		<div className={styles.stockPilingsActions}>
			<Button
				data-test-id="cNsPwlf1CY83GqvEfdNH4"
				disabled={false}
				variant="secondary"
				onClick={() => {
					getListByStockPiling({ stockpiling_id });
					showModal(ModalTypes.TRANSACTIONS);
				}}
				dataTestId="transactions"
			>
				{text(ModalTypes.TRANSACTIONS)}
			</Button>
			{postback_send ? (
				<Button
					data-test-id="VJpX-WeH4A9Due7syRuNy"
					disabled={false}
					variant="secondary"
					onClick={() => {
						getPostbackList(postback_id, { direction });
						showModal(ModalTypes.POSTBACK);
					}}
					dataTestId="postback"
				>
					{text(ModalTypes.POSTBACK)}
				</Button>
			) : resendPostbackAccess && (
				<Button
					data-test-id="kh_yYIjClHhaLn-AO3fnl"
					variant="primary"
					disabled={false}
					onClick={() => {
						getStockpilingId(stockpiling_id);
						showModal(ModalTypes.SEND_POSTBACK);
					}}
					dataTestId="sendPostback"
				>
					{/* Отправить постбeк	*/}
					{t("Send postback")}
				</Button>
			)}
		</div>
	);
};

export default StockpilingsActions;
