import React, { MouseEventHandler, useState } from "react";

import styles from "./Spoiler.module.scss";

type SpoilerPropsType = {
	readonly label: React.ReactNode;
	readonly onClear?: () => void;
	readonly children: React.ReactNode;
	readonly expanded: boolean;
};

const Spoiler: React.FC<SpoilerPropsType> = ({ label, onClear, children, expanded }) => {
	const [isExpanded, setIsExpanded] = useState(expanded);

	const classNames = [styles.spoiler];

	if (isExpanded) {
		classNames.push(styles.isExpanded);
	}

	const toggleExpand: MouseEventHandler = (e) => {
		e.stopPropagation();
		setIsExpanded((prevState) => !prevState);
	};

	return (
		<div className={classNames.join(" ")}>
			<div className={styles.header}>
				<div data-test-id="SzPNO6HQwaPSRH5SaP5p_" className={styles.label} onClick={toggleExpand}>
					{label}
				</div>
				{onClear && (
					<button data-test-id="tPt6bg47RPjAVGnF7gx9q" className={styles.clear} type="button" onClick={onClear}>
						<ClearIcon />
					</button>
				)}
				<button data-test-id="_AlneOggZnh9Wme-DFlpG" className={styles.collapse} type="button" onClick={toggleExpand}>
					<CollapseIcon />
				</button>
			</div>
			<div className={styles.content}>{children}</div>
		</div>
	);
};

const CollapseIcon = () => (
	<svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M0.146447 1.12301C0.341709 0.927747 0.658291 0.927747 0.853553 1.12301L5 5.26946L9.14645 1.12301C9.34171 0.927747 9.65829 0.927747 9.85355 1.12301C10.0488 1.31827 10.0488 1.63485 9.85355 1.83012L5.35355 6.33012C5.15829 6.52538 4.84171 6.52538 4.64645 6.33012L0.146447 1.83012C-0.0488155 1.63485 -0.0488155 1.31827 0.146447 1.12301Z"
			fill="currentColor"
		/>
	</svg>
);

const ClearIcon = () => (
	<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M13.5 6.72656C13.5 5.89814 12.8284 5.22656 12 5.22656C11.1716 5.22656 10.5 5.89814 10.5 6.72656H9.5C9.5 5.34585 10.6193 4.22656 12 4.22656C13.3807 4.22656 14.5 5.34585 14.5 6.72656H19C19.2761 6.72656 19.5 6.95042 19.5 7.22656C19.5 7.5027 19.2761 7.72656 19 7.72656H18.446L17.1499 18.9558C17.0335 19.965 16.179 20.7266 15.1631 20.7266H8.83688C7.821 20.7266 6.9665 19.965 6.85006 18.9558L5.553 7.72656H5C4.75454 7.72656 4.55039 7.54969 4.50806 7.31644L4.5 7.22656C4.5 6.95042 4.72386 6.72656 5 6.72656H13.5ZM17.438 7.72656H6.561L7.84347 18.8412C7.90169 19.3458 8.32894 19.7266 8.83688 19.7266H15.1631C15.6711 19.7266 16.0983 19.3458 16.1565 18.8412L17.438 7.72656ZM10.5 10.2266C10.7455 10.2266 10.9496 10.3813 10.9919 10.5854L11 10.6641V16.7891C11 17.0307 10.7761 17.2266 10.5 17.2266C10.2545 17.2266 10.0504 17.0718 10.0081 16.8677L10 16.7891V10.6641C10 10.4224 10.2239 10.2266 10.5 10.2266ZM13.5 10.2266C13.7455 10.2266 13.9496 10.3813 13.9919 10.5854L14 10.6641V16.7891C14 17.0307 13.7761 17.2266 13.5 17.2266C13.2545 17.2266 13.0504 17.0718 13.0081 16.8677L13 16.7891V10.6641C13 10.4224 13.2239 10.2266 13.5 10.2266Z"
			fill="currentColor"
		/>
	</svg>
);

export default Spoiler;
