import { AccountsBalanceDateType, PaymentSystemType, PSAndBalancesType } from "api/balanceGroup";
import PaymentSystemLogo from "components/PaymentSystemLogo";
import BalancesLinks, { BalancesListType } from "modules/Balance/components/BalancesLinks";
import BalancesList, { BalancesListWalletsType } from "modules/Balance/components/BalancesList";
import ShowBalances from "modules/Balance/components/ShowBalances";
import { t, useTranslation } from "pay-kit";

import styles from "./columns.module.scss";

const walletTypeCol = {
	title: t("Wallet type"),
	dataIndex: "payment_system",
	key: "payment_system",
	render: ({ payment_system }: PaymentSystemType) => {
		return <PaymentSystemLogo id={payment_system} dataTestId="payment_system" />;
	},
};

export const accountBalancesColumns = (filters: AccountsBalanceDateType) => {
	const accountCol = {
		title: t("Balance"),
		dataIndex: "wallets",
		key: "wallets",
		render: ({ payment_system, balances }: PSAndBalancesType) => {
			const links = (list: readonly BalancesListType[]) => (
				<BalancesLinks
					balances={list}
					payment_system={payment_system}
					amountClass={styles.amountsBottomBorder}
					dataTestId="balances"
					filters={filters}
				/>
			);

			return <ShowBalances list={balances} range={12} balancesList={links} dataTestId="showBalances" />;
		},
	};
	return [walletTypeCol, accountCol];
};
