import { fetchApi, fetchOldApi } from "api/fetch";

const changeLogGroup = {
	getChangeLogList: (params: string) => fetchOldApi<GetChangeLogListType>(`change-logs/?${params}`),
	getChangeLogUsers: () => fetchApi<readonly ChangeLogUsersDataType[]>("change-logs/users"),
	getChangeLogObjectList: () => fetchOldApi<GetChangeLogObjectListType>("change-logs/object-types"),
	getChangeLogActionList: (params: string) => fetchOldApi<GetChangeLogActionListType>(`change-logs/action-types/?${params}`)
};

export default changeLogGroup;

export type ChangeLogActionType = {
	readonly id: string
	readonly name: string
}

export type GetChangeLogActionListType = {
	readonly data: readonly ChangeLogActionType[]
}

export type  ChangeLogObject = {
	readonly id: string
	readonly name: string
}

export type GetChangeLogObjectListType = {
	readonly data: readonly ChangeLogObject[]
}

export type ChangeLogUsersDataType = {
	readonly name: string
}

export type GetChangeLogUsersType = {
	readonly code: number
	readonly data: readonly ChangeLogUsersDataType[]
	readonly message?: string
	readonly paginate: {
		readonly offset: number
		readonly total: number
		readonly limit: number
	}
}

export type ChangeLogType = {
	readonly uuid: string
	readonly action: string
	readonly created_at: string
	readonly new_value: readonly string[]
	readonly object_id: number
	readonly object_type: string
	readonly old_value: readonly string[]
	readonly user: string
}

export type GetChangeLogListType = {
	readonly change_logs: {
		readonly data: readonly ChangeLogType[]
		readonly current_page: number
		readonly from: number
		readonly last_available_page: number
		readonly per_page: number
		readonly to: number
	}
}