import API from "api";
import { WalletListItem, WalletsFilterForm } from "api/walletGroup";
import { useCallback, useState } from "react";

const useGetWallets = () => {
	const [list, setList] = useState<readonly WalletListItem[]>([]);
	const [error, setError] = useState<Error | null>(null);
	const [isLoading, setLoading] = useState<boolean>(false);
	const [paginationInfo, setPaginationInfo] = useState<PaginationInfoType | undefined>(undefined);

	const load = useCallback((params: WalletsFilterForm = { removed: 0, is_active: 1 }) => {
		setLoading(true);
		setError(null);

		// eslint-disable-next-line promise/catch-or-return
		return API.wallet
			.getList(params)
			.then((resp) => {
				if (resp.status === "success") {
					setLoading(false);
					setList(resp.data);
					setPaginationInfo(resp.paginate);

					return resp;
				} else {
					throw new Error("Unexpected error in useGetWallets");
				}
			})
			.catch((err) => {
				console.error(err);
				setError(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	return {
		load,
		list,
		isLoading,
		error,
		paginationInfo,
	};
};

export default useGetWallets;

type PaginationInfoType = {
	readonly limit: number;
	readonly offset: number;
	readonly total: number;
};
