import { OldResponse } from "api/types";
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { validate } from "utils/validate";

type UseFormProps<T> = {
	readonly schema: any,
	readonly initForm: Partial<T>,
	readonly onSubmit: (form: Partial<T>) => Promise<OldResponse<any>>,
	readonly onSuccess: () => void,
	readonly onError?: (e: {
		readonly status: string,
		readonly error_message: string | readonly { readonly [key: string]: string }[],
		readonly errors: Error
	}) => void
	readonly closeModal?: () => void,
}

export type FormErrors<T> = Partial<T> & {
	readonly all?: string,
}

export type UseForm<T> = {
	readonly form: Partial<T>,
	readonly setForm: Dispatch<SetStateAction<Partial<T>>>,
	readonly submitting: boolean,
	readonly errors: FormErrors<T>,
	readonly submit: (e?: any) => void,
	readonly changeField: (fieldName: keyof T) => (e: ChangeEvent<HTMLInputElement>) => void,
	readonly setFieldValue: (fieldName: keyof T, value: any) => void,
	readonly setErrors: Dispatch<SetStateAction<Partial<T>>>,
}

function useForm<T>({ schema, initForm, onSubmit, onSuccess, onError, closeModal }: UseFormProps<T>): UseForm<T> {
	const [form, setForm] = useState<Partial<T>>(initForm);
	const [errors, setErrors] = useState<FormErrors<T>>({});
	const [submitting, setSubmitting] = useState<boolean>(false);

	const submit = async (e?: any) => {
		e.preventDefault();
		const validationErrors: any = validate(schema, form);
		setErrors(validationErrors);
		if (!Object.keys(validationErrors).length) {
			setSubmitting(true);
			onSubmit(form).then((resp) => {
				if (resp.status === "ok" || resp.status === "success") {
					onSuccess();
					closeModal && closeModal();
				} else {
					const data: any = {
						status: resp.status,
						error_message: resp.error_message,
						errors: resp.errors
					};
					onError && onError(data);
					setErrors(() => ({
						...data
					}));
				}
			}).finally(() => {
				setSubmitting(false);
			});
		}
	};

	const changeField = (fieldName: keyof T) => {
		return (e: ChangeEvent<HTMLInputElement>) => {

			const value = e.target ? e.target?.value : e;

			setForm((prevForm: any) => ({
				...prevForm,
				[fieldName]: value
			}));
		};
	};

	const setFieldValue = (fieldName: keyof T, value: any) => {
		setForm((prevForm: any) => ({
			...prevForm,
			[fieldName]: value
		}));
	};

	return { form, setForm, submitting, errors, submit, changeField, setFieldValue, setErrors };
}

export default useForm;