import { Loader, Switcher } from "@paykassma/pay-kit";
import AuthContext from "contexts/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";
import { useDashboardContext } from "contexts/DashboardContext";
import { WalletTypesContext } from "contexts/WalletTypesContext";
import { useTranslation } from "pay-kit";
import { useContext, useEffect, useMemo, useState } from "react";

import PaymentOptionsList from "../PaymentOptionsList";
import BarChart from "./BarChart";
import styles from "./Chart.module.scss";
import { useGetTransactionsData, useGetWithdrawalsData } from "./hooks";
import LineChart from "./LineChart";
import { prepareChartData } from "./utils";

const Chart = () => {
	const [direction, setDirection] = useState<"transactionsData" | "withdrawalsData">("transactionsData");
	const [dataType, setDataType] = useState<"sum" | "count">("sum");
	const [chartType, setChartType] = useState<"line" | "bar">("line");
	const { filterState } = useDashboardContext();
	const getTransactionsDataAPI = useGetTransactionsData();
	const getWithdrawalsDataAPI = useGetWithdrawalsData();
	const { walletTypes } = useContext(WalletTypesContext);
	const { hasRole } = useContext(AuthContext);
	const withdrawalsAccess = hasRole(Roles.WITHDRAWALS_VIEW);
	const { t } = useTranslation();

	const psMap: Record<string, string> = {};
	walletTypes.forEach((wt) => (psMap[wt.code] = wt.name));
	psMap.all = t("All");

	useEffect(() => {
		const _filter = { ...filterState, stockpiling_status: filterState?.stockpiling_status || 1 };

		if (direction === "transactionsData") {
			getTransactionsDataAPI.load(_filter);
		}

		if (direction === "withdrawalsData") {
			getWithdrawalsDataAPI.load(_filter);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [direction, filterState]);

	const isLoading = getTransactionsDataAPI.isLoading || getWithdrawalsDataAPI.isLoading;

	const chartData = useMemo(
		() =>
			prepareChartData(
				dataType,
				direction === "transactionsData" ? getTransactionsDataAPI.data : getWithdrawalsDataAPI.data
			),
		[getTransactionsDataAPI.data, getWithdrawalsDataAPI.data, dataType, direction]
	);

	const chartUnit = dataType === "sum" ? filterState?.display_in_currency : "";

	return (
		<div className={styles.wrapper}>
			<div className={styles.topControls}>
				<Switcher
					onSwitch={(v) => setDirection(v as "transactionsData" | "withdrawalsData")}
					value={direction}
					options={[
						{ label: t("Deposits"), value: "transactionsData" },
						...(withdrawalsAccess ? [{ label: t("Withdrawals"), value: "withdrawalsData" }] : []),
					]}
					data-test-id="direction-switcher"
				/>

				<Switcher
					onSwitch={(v) => setDataType(v as "sum" | "count")}
					value={dataType}
					options={[
						{ label: t("Sum"), value: "sum" },
						{ label: t("Count"), value: "count" },
					]}
					data-test-id="data-type-switcher"
				/>

				<Switcher
					onSwitch={(v) => setChartType(v as "line" | "bar")}
					value={chartType}
					options={[
						{ label: t(`Graph`), value: "line" },
						{ label: t(`Diagram`), value: "bar" },
					]}
					data-test-id="chart-type-switcher"
				/>
			</div>

			<div className={styles.chartContainer}>
				{isLoading && <Loader />}
				{chartData &&
					{
						// @ts-ignore
						// TODO: The readonly field `label` in `chartData` causes TS
						//  error due to the fact that `LineChart` `package` requires the label field to be mutable )
						line: <LineChart data={chartData} unit={chartUnit} psMap={psMap} />,
						// @ts-ignore
						// TODO: The readonly field `label` in `chartData` causes TS
						//  error due to the fact that `LineChart` `package` requires the label field to be mutable )
						bar: <BarChart data={chartData} unit={chartUnit} psMap={psMap} />,
					}[chartType]}
			</div>
			<PaymentOptionsList />
		</div>
	);
};

export default Chart;
