import { useTranslation } from "pay-kit";
import { GetImagesType, ImageType } from "api/walletTypesGroup";
import FileControlPanel from "modules/AppSettings/Plugin/components/PSHints/components/FileControlPanel";
import RemovalConfirmation
	from "modules/AppSettings/Plugin/components/PSHints/components/RemovalConfirmation";
import useGetImages from "modules/AppSettings/Plugin/components/PSHints/hooks/useGetImages";
import { Modal } from "pay-kit";
import { createContext, FC, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from "react";

import styles from "./context.module.scss";

const HintContext = createContext<HintContextType | undefined>(undefined);
const ActionProvider: FC<ActionProviderType> = ({ children }) => {
	const {t} = useTranslation();
	const getImagesAPI = useGetImages();
	const [isHintModal, setIsHintModal] = useState(false);
	const [walletType, setWalletType] = useState<WalletType | undefined>(undefined);
	const [remove, setRemove] = useState(false);
	const [hint, setHint] = useState<Partial<HintType> | null>(null);
	const [isFileUploading, setIsFileUploading] = useState(false);


	useEffect(() => {
		getImagesAPI.load();
	}, [getImagesAPI.load]);

	const loadImages = getImagesAPI.load;

	const uploadingFile: UploadingFileType = useCallback((storing) => setIsFileUploading(storing), []);

	const uploadFile: UploadFileType = useCallback((walletType) => {
		setIsHintModal(open => !open);
		setWalletType(walletType);
	}, []);

	const removeHint: RemoveHintType = useCallback((hint) => {
		setRemove(true);
		setIsHintModal(false);
		setHint(hint);
	}, []);

	const closeHintModal = useCallback(() => setIsHintModal(false), []);

	const closeConfirmationModal = () => {
		setRemove(false);
	};

	const context = useMemo(() => ({
		uploadFile,
		removeHint,
		loadImages,
		images: getImagesAPI.data,
		closeHintModal,
		uploadingFile,
		isFileUploading
	}), [
		uploadFile,
		removeHint,
		loadImages,
		getImagesAPI.data,
		closeHintModal,
		uploadingFile,
		isFileUploading
	]);

	return (
		<HintContext.Provider value={context}>
			{children}
			<Modal
				title={t("Hint")}
				className={styles.hintsModal}
				onClose={() => setIsHintModal(open => !open)} isOpen={isHintModal}
			>
				<FileControlPanel walletType={walletType} />
			</Modal>
			<Modal
				title={t("Delete hint")}
				onClose={() => {
					setRemove(remove => !remove);
				}}
				isOpen={remove}
			>
				<RemovalConfirmation hint={hint} closeConfirmationModal={closeConfirmationModal} />
			</Modal>
		</HintContext.Provider>
	);
};

export default ActionProvider;

export const useHintContext = () => useContext(HintContext);

type ActionProviderType = {
	readonly children: ReactElement
}

export type HintContextType = {
	readonly uploadFile: UploadFileType
	readonly removeHint: RemoveHintType
	readonly uploadingFile: UploadingFileType
	readonly closeHintModal: () => void
	readonly loadImages: (onSuccess: () => void) => void
	readonly images: GetImagesType | null
	readonly isFileUploading: boolean
}

export type WalletType = { readonly name: string, readonly code: string, readonly hint: ImageType | null }

type UploadingFileType = (storing: boolean) => void
type UploadFileType = (params: WalletType) => void
export type HintType = { readonly name: string, readonly code: string }
type RemoveHintType = (hint: Partial<HintType>) => void
