// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PluginInstallation__pluginInstallation-YhK{border-radius:8px;padding:0 24px 24px 24px}.PluginInstallation__pluginInstallation-YhK .PluginInstallation__generateFieldsValuesHeader-arV{margin-top:24px;font-size:14px;font-weight:400}.PluginInstallation__fieldsGroup-rpC{display:grid;grid-template-columns:380px 380px;column-gap:64px;margin-top:24px}.PluginInstallation__inputField-NtT{justify-content:space-between;gap:8px}.PluginInstallation__inputField-NtT>div{width:100%;max-width:172px}.PluginInstallation__selectField-vvb{justify-content:space-between}.PluginInstallation__selectField-vvb>div{width:100%;max-width:172px}.PluginInstallation__submitButton-mLE{margin-top:16px}.PluginInstallation__submitButton-mLE .PluginInstallation__submitButtonValue-mQz{display:flex;align-items:center;gap:8px}`, "",{"version":3,"sources":["webpack://./src/modules/AppSettings/Plugin/components/PluginInstallation/pluginInstallation.module.scss"],"names":[],"mappings":"AAAA,4CACC,iBAAA,CACA,wBAAA,CAEA,gGACC,eAAA,CACA,cAAA,CACA,eAAA,CAIF,qCACC,YAAA,CACA,iCAAA,CACA,eAAA,CACA,eAAA,CAGD,oCACC,6BAAA,CACA,OAAA,CAEA,wCACC,UAAA,CACA,eAAA,CAIF,qCACC,6BAAA,CAEA,yCACC,UAAA,CACA,eAAA,CAIF,sCACC,eAAA,CAEA,iFACC,YAAA,CACA,kBAAA,CACA,OAAA","sourcesContent":[".pluginInstallation {\n\tborder-radius: 8px;\n\tpadding: 0 24px 24px 24px;\n\n\t.generateFieldsValuesHeader {\n\t\tmargin-top: 24px;\n\t\tfont-size: 14px;\n\t\tfont-weight: 400;\n\t}\n}\n\n.fieldsGroup {\n\tdisplay: grid;\n\tgrid-template-columns: 380px 380px;\n\tcolumn-gap: 64px;\n\tmargin-top: 24px;\n}\n\n.inputField {\n\tjustify-content: space-between;\n\tgap: 8px;\n\n\t& > div {\n\t\twidth: 100%;\n\t\tmax-width: 172px;\n\t}\n}\n\n.selectField {\n\tjustify-content: space-between;\n\n\t& > div {\n\t\twidth: 100%;\n\t\tmax-width: 172px;\n\t}\n}\n\n.submitButton {\n\tmargin-top: 16px;\n\n\t.submitButtonValue {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tgap: 8px;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pluginInstallation": `PluginInstallation__pluginInstallation-YhK`,
	"generateFieldsValuesHeader": `PluginInstallation__generateFieldsValuesHeader-arV`,
	"fieldsGroup": `PluginInstallation__fieldsGroup-rpC`,
	"inputField": `PluginInstallation__inputField-NtT`,
	"selectField": `PluginInstallation__selectField-vvb`,
	"submitButton": `PluginInstallation__submitButton-mLE`,
	"submitButtonValue": `PluginInstallation__submitButtonValue-mQz`
};
export default ___CSS_LOADER_EXPORT___;
