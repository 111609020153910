// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChangeLog__logsFiltersWrapper-aUM{margin-top:4px}.ChangeLog__logsFiltersWrapper-aUM .ChangeLog__logsField-q80{min-width:180px}.ChangeLog__changeLogsTableWrapper-ZAV{margin-top:38px;margin-bottom:38px}.ChangeLog__changeLogsTableWrapper-ZAV .ChangeLog__changeLogsTable-ax_{margin-top:26px;margin-bottom:26px}.ChangeLog__changeLogsTableWrapper-ZAV .ChangeLog__changeLogsTable-ax_ col:nth-child(6){width:252px}.ChangeLog__changeLogsTableWrapper-ZAV .ChangeLog__changeLogsTable-ax_ col:nth-child(7){width:252px}`, "",{"version":3,"sources":["webpack://./src/modules/ChangeLog/changeLog.module.scss"],"names":[],"mappings":"AAAA,mCACC,cAAA,CAEA,6DACC,eAAA,CAIF,uCACC,eAAA,CACA,kBAAA,CAEA,uEACC,eAAA,CACA,kBAAA,CAEA,wFACC,WAAA,CAGD,wFACC,WAAA","sourcesContent":[".logsFiltersWrapper {\n\tmargin-top: 4px;\n\n\t.logsField {\n\t\tmin-width: 180px;\n\t}\n}\n\n.changeLogsTableWrapper {\n\tmargin-top: 38px;\n\tmargin-bottom: 38px;\n\n\t.changeLogsTable {\n\t\tmargin-top: 26px;\n\t\tmargin-bottom: 26px;\n\n\t\tcol:nth-child(6) {\n\t\t\twidth: 252px;\n\t\t}\n\n\t\tcol:nth-child(7) {\n\t\t\twidth: 252px;\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logsFiltersWrapper": `ChangeLog__logsFiltersWrapper-aUM`,
	"logsField": `ChangeLog__logsField-q80`,
	"changeLogsTableWrapper": `ChangeLog__changeLogsTableWrapper-ZAV`,
	"changeLogsTable": `ChangeLog__changeLogsTable-ax_`
};
export default ___CSS_LOADER_EXPORT___;
