// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CheckboxGroup__checkboxGroup-O_h{display:flex;flex-direction:column;gap:8px;font-size:14px}.CheckboxGroup__checkboxGroup-O_h .CheckboxGroup__notFound-Dxz{padding:6px 16px;font-weight:400;font-size:14px;line-height:20px;color:#697077}`, "",{"version":3,"sources":["webpack://./src/components/Filter/Field/CheckboxGroup/CheckboxGroup.module.scss"],"names":[],"mappings":"AAAA,kCACC,YAAA,CACA,qBAAA,CACA,OAAA,CACA,cAAA,CAEA,+DACC,gBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,aAAA","sourcesContent":[".checkboxGroup {\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 8px;\n\tfont-size: 14px;\n\n\t.notFound {\n\t\tpadding: 6px 16px;\n\t\tfont-weight: 400;\n\t\tfont-size: 14px;\n\t\tline-height: 20px;\n\t\t/* Blue_gray/70 */\n\t\tcolor: #697077;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkboxGroup": `CheckboxGroup__checkboxGroup-O_h`,
	"notFound": `CheckboxGroup__notFound-Dxz`
};
export default ___CSS_LOADER_EXPORT___;
