import { fetchApi, fetchOldApi } from "api/fetch";
import { useTranslation } from "pay-kit";

const balanceGroup = {
	getWalletsBalances: () => fetchOldApi<GetWalletsBalancesType>("/wallet/balances"),
	getAccountsBalanceHistory: () => fetchOldApi<GetAccountsBalanceHistoryType>("accounts-balance-history/list"),
	getAccountsBalanceHistoryByDate: (date: AccountsBalanceDateType) =>
		fetchOldApi<GetAccountsBalanceHistoryType>("accounts-balance-history/list", date, "GET"),
	transferBalance: (data: Partial<TransferBalanceDataType>) => fetchOldApi("account-balance/transfer", data, "POST"),
	cancelBalance: (id: string) => fetchApi(`account-balance/transfer/cancel`, { id }, "POST"),
	getOperationHistory: (id: string, data: string) =>
		fetchApi<GetOperationHistoryDataType>(`accounts-balance-history/${id}?${data}`),
	makingReplenishment: (data: Partial<MakingReplenishmentParamsType>) =>
		fetchOldApi<MakingReplenishmentType>("settlement/external-replenishment", data, "POST"),
	getWithdrawalsList: () => fetchOldApi<GetWithdrawalListType>("settlement/withdrawal/list"),
	rejectApproveNewWithdrawal: (id: number, type: string) =>
		fetchOldApi(`settlement/withdrawal/${type}/${id}`, {}, "POST"),
	createWithdrawal: (params: Partial<CreateWithdrawalParamsType>) =>
		fetchOldApi("settlement/withdrawal/create", params, "POST"),
};

export default balanceGroup;

export type CreateWithdrawalParamsType = {
	readonly amount: string;
	readonly bitcoin_address: string;
};

export type Withdrawals = {
	readonly amount: number;
	readonly bitcoin_address: string;
	readonly created_at: string;
	readonly id: number;
	readonly status: number;
};

export type GetWithdrawalListType = {
	readonly withdrawals: readonly Withdrawals[];
};

export type MakingReplenishmentParamsType = { readonly amount: string };

export type MakingReplenishmentType = {
	readonly balance: number;
	readonly settlement_history: {
		readonly amount: string;
		readonly balance: string;
		readonly bitcoin_address: null | string;
		readonly commission: number;
		readonly created_at: string;
		readonly currency: null | string;
		readonly direction: "outgoing" | "ingoing";
		readonly ex_rate: null | number;
		readonly external_address: unknown;
		readonly id: number;
		readonly operation_type: number;
		readonly payment_system: string;
		readonly total_commission: number;
		readonly updated_at: string;
		readonly wallet_type: string;
	};
};

export type OperationHistoryTransactions = {
	/** 	Идентификатор транзакции  */
	readonly id: string;
	/**Дата и время транзакции */
	readonly date: string;
	/** * Тип операции Справочник "Типы операций", возможные варианты:
	 * 1- Переводы между счетами клиента (ПС)/Переводы между счетом клиента (ПС) и сеттлементом
	 * 2-Переводы между внешним счетом клиента и сеттлементом.
	 */
	readonly operation_type: number;
	/**
	 * Роль в процессе отмены операции с балансами , возможны варианты:
	 * 0 - Отменяемая транзакция
	 * 1 - Транзакция созданная в результате отмены
	 * null - возможное значение, не описанное в енаме.
	 */
	readonly cancellations_role: 0 | 1 | null;
	/** t("Transaction amount")*/
	readonly amount: number;
	/** Комиссия транзакции */
	readonly total_commission?: number;
	/** Направление транзакции */
	readonly directing: "outgoing" | "ingoing";
	/** Тип ПС счета списания/зачисления из парной транзакции.  */
	readonly wallet_type?: string;
	/** true - если ПС относится к сеттлементу
	 *   false - если ПС не относится к сеттлементу
	 */
	readonly is_settlement: number;
	/** Валюта счета списания/зачисления из парной транзакции. */
	readonly currency_code: string;
	/** Курс операции */
	readonly ex_rate: number;
	/** Адрес для вывода на внешний счет. */
	readonly external_address?: unknown;
	/** Дата создания записи */
	readonly created_at: string;
};

export type GetOperationHistoryDataType = {
	readonly balance: number;
	readonly transactions: readonly OperationHistoryTransactions[];
};

export type TransferBalanceDataType = {
	readonly commission_percent: string;
	readonly currency_code: string;
	readonly ex_rate: string;
	readonly id: string;
	readonly recipient_payment_system: string;
	readonly writeoff_amount: string;
	readonly crediting: string;
};

export type TransactionHistoryType = {
	readonly amount: number;
	readonly created_at: string;
	readonly currency_code: string;
	readonly direction: "outgoing" | "ingoing";
	readonly ex_rate: number;
	readonly external_address: string | null;
	readonly is_settlement: number;
	readonly total_commission: number;
	readonly wallet_type: string;
};

export type GetOperationHistoryType = {
	readonly balance: number;
	readonly transactions: readonly TransactionHistoryType[];
};

export type PaymentSystemWalletsType = {
	readonly amount: number;
	readonly currency_code: string;
	readonly currency_symbol: string;
};

export type PaymentSystemType = {
	readonly payment_system: string;
	readonly wallets: readonly PaymentSystemWalletsType[];
};

export type GetWalletsBalancesType = {
	readonly balances: readonly PaymentSystemType[];
};

export type PSBalanceType = {
	readonly currency_code: string;
	readonly amount: number;
	readonly id: number;
	readonly is_settlement: boolean;
};

export type PSAndBalancesType = {
	readonly payment_system: string;
	readonly additional_info: unknown;
	readonly balances: readonly PSBalanceType[];
};

export type GetAccountsBalanceHistoryType = {
	readonly balances: readonly PSAndBalancesType[];
};

export type AccountsBalanceDateType = { readonly date?: string };
