// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdditionalSettingsSection__additionalSettings-y7F h3{display:flex;align-items:center;height:44px;gap:8px;font-weight:500;font-size:20px;line-height:44px;color:#21272a;border-bottom:1px solid #dde1e6;margin-bottom:8px}.AdditionalSettingsSection__additionalSettings-y7F .AdditionalSettingsSection__checkboxGroup-dxG{margin-top:16px;display:grid;grid-template-columns:1fr 1fr 1fr;row-gap:20px}`, "",{"version":3,"sources":["webpack://./src/modules/Reports/ReportList/components/ActionsProvider/modals/NewReport/components/AdditionalSettingsSection/AdditionalSettingsSection.module.scss"],"names":[],"mappings":"AACI,sDACI,YAAA,CACA,kBAAA,CACA,WAAA,CACA,OAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,aAAA,CAEA,+BAAA,CACA,iBAAA,CAGJ,iGACI,eAAA,CACA,YAAA,CACA,iCAAA,CACA,YAAA","sourcesContent":[".additionalSettings {\n    h3 {\n        display: flex;\n        align-items: center;\n        height: 44px;\n        gap: 8px;\n        font-weight: 500;\n        font-size: 20px;\n        line-height: 44px;\n        /* Blue_gray/90 */\n        color: #21272A;\n        /* Blue_gray/30 */\n        border-bottom: 1px solid #DDE1E6;\n        margin-bottom: 8px;\n    }\n\n    .checkboxGroup {\n        margin-top: 16px;\n        display: grid;\n        grid-template-columns: 1fr 1fr 1fr;\n        row-gap: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"additionalSettings": `AdditionalSettingsSection__additionalSettings-y7F`,
	"checkboxGroup": `AdditionalSettingsSection__checkboxGroup-dxG`
};
export default ___CSS_LOADER_EXPORT___;
