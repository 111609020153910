// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AutoReports__topActions-K7v{display:flex;justify-content:space-between;align-items:center;margin:36px 0 0 0}.AutoReports__actions-kV9{display:flex;gap:8px}.AutoReports__actions-kV9 button{all:unset;display:flex;height:32px;width:32px;justify-content:center;align-items:center;cursor:pointer;border-radius:4px}.AutoReports__actions-kV9 button:hover{background:#f2f3f5}.AutoReports__actions-kV9 button:hover[data-test-id=Remove]{color:#dc3545}.AutoReports__removeSelectedItemsButton-RH_{all:unset;display:flex;align-items:center;justify-content:center;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/modules/Reports/AutoReports/AutoReports.module.scss"],"names":[],"mappings":"AAAA,6BACI,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,iBAAA,CAGJ,0BACI,YAAA,CACA,OAAA,CAEA,iCACI,SAAA,CACA,YAAA,CACA,WAAA,CACA,UAAA,CACA,sBAAA,CACA,kBAAA,CACA,cAAA,CACA,iBAAA,CAEA,uCACI,kBAAA,CACA,4DACI,aAAA,CAMhB,4CACI,SAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,cAAA","sourcesContent":[".topActions {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin: 36px 0 0 0;\n}\n\n.actions {\n    display: flex;\n    gap: 8px;\n\n    button {\n        all: unset;\n        display: flex;\n        height: 32px;\n        width: 32px;\n        justify-content: center;\n        align-items: center;\n        cursor: pointer;\n        border-radius: 4px;\n\n        &:hover {\n            background: #F2F3F5;\n            &[data-test-id=\"Remove\"] {\n                color: #DC3545;\n            }\n        }\n    }\n}\n\n.removeSelectedItemsButton {\n    all: unset;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topActions": `AutoReports__topActions-K7v`,
	"actions": `AutoReports__actions-kV9`,
	"removeSelectedItemsButton": `AutoReports__removeSelectedItemsButton-RH_`
};
export default ___CSS_LOADER_EXPORT___;
