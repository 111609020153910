import API from "api";
import { getListParamsType, postbackType } from "api/postbackGroup";
import { useState } from "react";
import {t} from "pay-kit";

const usePostbacksListAPI: usePostbacksListAPIType = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [postbacks, setPostbacks] = useState<readonly postbackType[]>([]);
	const [error, setError] = useState<null | Error>(null);
	const [paginationInfo, setPaginationInfo] = useState<null | paginationInfoType>(null);

	const getPostbackList: getPostbackListType = (params) => {
		setIsLoading(true);
		setError(null);
		setPostbacks([]);

		API.postback
			.getList(params)
			.then((res: getListSuccessResponseType) => {
				setIsLoading(false);

				if (res?.status === "ok" && res?.postbacks?.data) {
					const { data, ...rest } = res.postbacks;

					setPostbacks(data);
					setPaginationInfo(rest);
				} else {
					throw new Error(t("Invalid response"));
				}
			})
			.catch((err) => {
				setIsLoading(false);
				setError(err);
			});
	};

	return {
		getPostbackList,
		isLoading,
		postbacks,
		error,
		paginationInfo,
	};
};

export default usePostbacksListAPI;

export type usePostbacksListAPIType = () => {
	readonly getPostbackList: getPostbackListType;
	readonly isLoading: boolean;
	readonly postbacks: readonly postbackType[];
	readonly error: Error | null;
	readonly paginationInfo: paginationInfoType | null;
};

export type paginationInfoType = {
	readonly current_page: number;
	readonly from: number;
	readonly to: number;
	readonly per_page: number;
	readonly last_available_page: number;
};

export type getListSuccessResponseType = {
	readonly status: "ok";
	readonly postbacks: {
		readonly data: readonly postbackType[];
	} & paginationInfoType;
};

export type getPostbackListType = (params: getListParamsType) => void;
