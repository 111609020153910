import API from "api";
import { IApprovePossibleParams, IPossibleTransaction } from "api/transactionGroup";
import { t } from "pay-kit";
import { Modal } from "pay-kit";
import React, { useState } from "react";

import ApproveConfirmationModal from "./ApproveConfirmationModal";
import ConfirmationModal from "./ConfirmationModal";
import ScreenshotModal from "./ScreenshotModal";

export const ActionsContext = React.createContext({
	showScreenshot: (url: IPossibleTransaction["screenshot"]) => {},
	removeTransaction: (primary_id: IPossibleTransaction["primary_id"]) => {},
	approveTransaction: (primary_id: IPossibleTransaction["primary_id"]) => {},
});

type ActionsProviderPropsType = {
	readonly children: React.ReactNode;
	readonly onSuccess: () => void;
	readonly transactions: readonly IPossibleTransaction[];
};

const ActionsProvider = ({ children, transactions, onSuccess }: ActionsProviderPropsType) => {
	const [screenshot, setScreenshot] = useState<IPossibleTransaction["screenshot"]>(null);
	const [removeTransactionId, setRemoveTransactionId] = useState<IPossibleTransaction["primary_id"] | undefined>();
	const [approveTransactionId, setApproveTransactionId] = useState<IPossibleTransaction["primary_id"] | undefined>();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const sendRemoveRequest = () => {
		if (removeTransactionId === undefined) {
			return;
		}

		setIsLoading(true);

		API.transaction
			.removePossible({ primary_id: removeTransactionId })
			.then((res) => {
				setIsLoading(false);

				if (res?.status === "ok") {
					setIsLoading(false);
					onSuccess();
					// window.pushAlert({ description: `Транзакция ${removeTransactionId} уcпешно удалена`, type: "success" });
					window.pushAlert({
						description: `${t("Transaction")} ${removeTransactionId} ${t("successfully")}`,
						type: "success",
					});
					setRemoveTransactionId(undefined);
					return;
				} else {
					throw new Error(t("Unexpected response"));
				}
			})
			.catch((err) => {
				setIsLoading(false);
				// window.pushAlert({ description: "Не удалось удалить транзакцию", type: "error" });
				window.pushAlert({ description: t("Failed to delete transaction"), type: "error" });
			});
	};

	const sendApproveRequest = ({ primary_id, currency, amount, wallet_id, wallet_hash_id }: IApprovePossibleParams) => {
		if (approveTransactionId === undefined) {
			return;
		}

		setIsLoading(true);

		API.transaction
			.approvePossible({ primary_id, currency, amount, wallet_hash_id })
			.then((res: any) => {
				setIsLoading(false);

				if (res?.status === "success") {
					setIsLoading(false);
					setTimeout(onSuccess, 500);
					window.pushAlert({
						description: `${t("Transaction")} ${approveTransactionId} ${t("successfully confirmed")}`,
						type: "success",
					});
					setApproveTransactionId(undefined);
					return;
				} else {
					typeof res?.message === "string" && window.pushAlert({ description: res?.message, type: "error" });
					throw new Error(t("Unexpected response"));
				}
			})
			.catch((err) => {
				setIsLoading(false);
				// window.pushAlert({ description: "Не удалось подтвердить транзакцию", type: "error" });
				window.pushAlert({ description: t("Failed to approve transaction"), type: "error" });
			});
	};

	const approveTransaction = transactions?.find((t) => t.primary_id === approveTransactionId) || undefined;

	return (
		<ActionsContext.Provider
			value={{
				showScreenshot: setScreenshot,
				removeTransaction: setRemoveTransactionId,
				approveTransaction: setApproveTransactionId,
			}}
		>
			<Modal
				isOpen={approveTransactionId !== undefined}
				title={t("Possible transaction approval")}
				onClose={() => setApproveTransactionId(undefined)}
			>
				{approveTransaction !== undefined && (
					<ApproveConfirmationModal
						transaction={approveTransaction}
						confirmText={t("Approve")}
						onConfirm={sendApproveRequest}
						onClose={() => setApproveTransactionId(undefined)}
						isLoading={isLoading}
						walletType={approveTransaction?.wallet_type}
					/>
				)}
			</Modal>

			<Modal
				isOpen={removeTransactionId !== undefined}
				// title="Удаление транзакции"
				title={t("Remove transaction")}
				onClose={() => setRemoveTransactionId(undefined)}
			>
				<ConfirmationModal
					// text={`Вы уверены, хотите что удалить транзакцию ${removeTransactionId}?`}
					text={`${t("Are you sure you want to delete transaction")} ${removeTransactionId}?`}
					onConfirm={sendRemoveRequest}
					onClose={() => setRemoveTransactionId(undefined)}
					confirmText={t("Delete")}
					isLoading={isLoading}
				/>
			</Modal>

			<ScreenshotModal screenshot={screenshot} onClose={() => setScreenshot(null)} />

			{children}
		</ActionsContext.Provider>
	);
};

export default ActionsProvider;
