// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DateTime__time-FK5{margin-top:4px;color:#697077}`, "",{"version":3,"sources":["webpack://./src/components/DateTime/dateTime.module.scss"],"names":[],"mappings":"AAAA,oBACC,cAAA,CACA,aAAA","sourcesContent":[".time {\n\tmargin-top: 4px;\n\tcolor: #697077;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"time": `DateTime__time-FK5`
};
export default ___CSS_LOADER_EXPORT___;
