import { ICurrentTransaction } from "api/transactionGroup";
import RightModal from "components/Modals/RightModal";
import { Wallet } from "modules/Transactions/CurrentTransactions/DetailedView/Tabs/Wallet";
import { t } from "pay-kit";
import React from "react";

import styles from "./WalletDetails.module.scss";

interface IWalletDetailsProps {
	readonly walletHashID: ICurrentTransaction["wallet_hash_id"];
	readonly onClose: () => void;
}

const WalletDetails: React.FC<IWalletDetailsProps> = ({ onClose, walletHashID }) => {
	return (
		<RightModal
			bodyClassName={styles.WalletDetailsWrapper}
			onShow={() => null}
			isVisible={walletHashID !== null}
			onClose={onClose}
			heading={t("Wallet")}
		>
			{walletHashID && (
				<Wallet
					wallet_hash_id={walletHashID}
					showAllTransactionsForWallet={(walletHashID: string) =>
						window.open(`/transactions?page=1&wallet_hash_id=${walletHashID}`, "_blank")
					}
				/>
			)}
		</RightModal>
	);
};

export default WalletDetails;
