// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TransactionsIcon__commonIcon-Scj{width:24px;color:#fff}.TransactionsIcon__commonIcon-Scj>svg{width:100%;height:100%;line-height:initial}`, "",{"version":3,"sources":["webpack://./src/components/Icons/TransactionsIcon/transactionsIcon.module.scss"],"names":[],"mappings":"AAAA,kCACC,UAAA,CACA,UAAA,CAEA,sCACC,UAAA,CACA,WAAA,CACA,mBAAA","sourcesContent":[".commonIcon {\n\twidth: 24px;\n\tcolor: white;\n\n\t& > svg {\n\t\twidth: 100%;\n\t\theight: 100%;\n\t\tline-height: initial;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"commonIcon": `TransactionsIcon__commonIcon-Scj`
};
export default ___CSS_LOADER_EXPORT___;
