// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CopyTextButton__CopyTextButton-SlO{display:inline-block;padding:0;margin:0;background:rgba(0,0,0,0);border:0;outline:none;color:#a2a9b0;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/components/CopyTextButton/CopyTextButton.module.scss"],"names":[],"mappings":"AAAA,oCACC,oBAAA,CACA,SAAA,CACA,QAAA,CACA,wBAAA,CACA,QAAA,CACA,YAAA,CAEA,aAAA,CACA,cAAA","sourcesContent":[".CopyTextButton {\n\tdisplay: inline-block;\n\tpadding: 0;\n\tmargin: 0;\n\tbackground: transparent;\n\tborder: 0;\n\toutline: none;\n\t// Blue_gray/50\n\tcolor: #a2a9b0;\n\tcursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CopyTextButton": `CopyTextButton__CopyTextButton-SlO`
};
export default ___CSS_LOADER_EXPORT___;
