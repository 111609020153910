import { InitCheckingMultiAddingProxiesType } from "api/proxiesGroup";
import ConfirmationButtons from "components/ConfirmationButtons";
import ManipulateActions from "components/ManipulateActions";
import { RowStatesType } from "modules/Proxies/components/UploadedProxiesListTable";
import { MultiAddingProxiesType, useAddingProxiesContext } from "modules/Proxies/contexts/AddingProxiesContext";
import { multiAddingProxiesHelper } from "modules/Proxies/helpers";
import { Dispatch, FC, SetStateAction, useEffect } from "react";

import styles from "./proxyManipulatingButtons.module.scss";

const ProxyManipulatingButtons: FC<ProxyManipulatingButtonsType> = ({
	id,
	isLoading,
	create,
	setTableEditableRow,
	tableEditableRow,
	isFieldValid,
	isRelaunchLoading
}) => {
	const {
		setMultiAddingProxies,
		multiAddingProxies,
		reservedMultiAddingProxies
	} = useAddingProxiesContext();

	const handleOnCheck = () => {
		const isMulti = (elem: MultiAddingProxiesType): elem is MultiAddingProxiesType => elem.id === id;
		const proxy = multiAddingProxies?.find(isMulti);

		const {
			ip_host,
			port,
			username,
			password,
			type,
			country_code
		} = proxy || {} as MultiAddingProxiesType;

		create({ ip_host, port, username, password, type, country_code });

		setTableEditableRow(prevState => ({ ...prevState, [id]: false }));

		setMultiAddingProxies(prevState =>
			multiAddingProxiesHelper({ state: prevState, id, params: { key: "isChecking", value: true } })
		);
	};

	useEffect(() => {
		if (multiAddingProxies) {
			const proxyId = multiAddingProxies.find(proxy => proxy.id === id);

			if (proxyId?.isChecking && !isLoading) {
				setMultiAddingProxies(prevState =>
					multiAddingProxiesHelper({ state: prevState, id, params: { key: "isChecking", value: false } })
				);
			}
		}
	}, [isLoading]);

	const handleOnCancel = () => {
		setTableEditableRow(prevState => ({ ...prevState, [id]: false }));
		setMultiAddingProxies(prevState => {
			const reservedProxies = reservedMultiAddingProxies?.find(elem => elem.id === id);
			if (!reservedProxies) return prevState;

			return multiAddingProxiesHelper({ state: prevState, id, reservedState: reservedProxies });
		});

	};

	const handleOnEdit = () => {
		setTableEditableRow(prevState => ({ ...prevState, [id]: true }));
	};

	const handleOnDelete = () => {
		setMultiAddingProxies(prevState => {
			return (prevState || []).filter(elem => elem.id !== id);
		});
	};

	return (
		<div>
			{
				tableEditableRow[id] ?
					<ConfirmationButtons
						onConfirm={handleOnCheck}
						onCancel={handleOnCancel}
						isConfirmDisabled={isFieldValid}
						isLoading={isLoading}
					/> :
					<ManipulateActions
						className={styles.UploadedProxiesActions}
						onRelaunch={handleOnCheck}
						onEdit={handleOnEdit}
						onDelete={handleOnDelete}
						allButtonsDisabled={isLoading}
						isRelaunchLoading={isRelaunchLoading}
					/>
			}
		</div>
	);
};

export default ProxyManipulatingButtons;

type ProxyManipulatingButtonsType = {
	readonly id: string
	readonly isLoading?: boolean
	readonly create: (params: InitCheckingMultiAddingProxiesType) => void
	readonly setTableEditableRow: Dispatch<SetStateAction<RowStatesType>>
	readonly tableEditableRow: RowStatesType
	readonly isFieldValid: boolean
	readonly isRelaunchLoading: boolean
}
