import RoutedTabs, { RoutedTab } from "components/RoutedTabs";
import AuthContext from "contexts/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";
import { useTranslation } from "pay-kit";
import { Panel } from "pay-kit";
import { useContext } from "react";
import { Outlet } from "react-router-dom";

export default function WalletsPage() {
	const { hasRole } = useContext(AuthContext);
	const {t} = useTranslation();
	const tabs: readonly RoutedTab[] = [
		{
			label: t("Wallets"),
			value: "/wallets",
			visible: hasRole(Roles.WALLET_LIST),
		},
		{
			label: t("Disabled"),
			value: "/wallets/disabled",
			visible: hasRole(Roles.WALLETS_DISABLED),
		},
		{
			label: t("Archived"),
			value: "/wallets/archived",
			visible: hasRole(Roles.ARCHIVED_WALLETS_VIEW),
		},
		{
			label: t("Statistic"),
			value: "/wallets/statistic",
			visible: hasRole(Roles.WALLET_LIST),
		},
	];

	return (
		<Panel title={t("Wallets")}>
			<RoutedTabs tabs={tabs} />
			<Outlet />
		</Panel>
	);
}
