const NotVisibleIcon = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M18.8197 16.7983C20.9844 14.8664 22.1806 12.6776 22.1806 12.6776C22.1806 12.6776 18.3628 5.67822 11.9998 5.67822C10.7776 5.68243 9.56918 5.9371 8.44922 6.42651L9.42913 7.40769C10.2531 7.10756 11.1229 6.95296 11.9998 6.95083C14.6977 6.95083 16.9362 8.43723 18.5766 10.0776C19.3661 10.8713 20.0738 11.7424 20.6891 12.6776C20.6153 12.7883 20.5339 12.9104 20.441 13.0441C20.0147 13.6549 19.3847 14.4694 18.5766 15.2775C18.3666 15.4875 18.1477 15.6949 17.9187 15.896L18.8197 16.7983Z" fill="currentColor"/>
			<path d="M16.1958 14.1741C16.4798 13.3798 16.5324 12.5212 16.3475 11.6981C16.1625 10.8751 15.7477 10.1215 15.1512 9.52504C14.5548 8.92856 13.8012 8.51373 12.9781 8.3288C12.1551 8.14388 11.2965 8.19647 10.5022 8.48046L11.5495 9.52782C12.0386 9.45781 12.5373 9.50268 13.006 9.65885C13.4747 9.81502 13.9006 10.0782 14.25 10.4276C14.5993 10.7769 14.8625 11.2028 15.0187 11.6716C15.1749 12.1403 15.2197 12.6389 15.1497 13.128L16.1958 14.1741ZM12.4505 15.8272L13.4966 16.8733C12.7023 17.1573 11.8437 17.2099 11.0207 17.025C10.1976 16.84 9.44403 16.4252 8.84755 15.8287C8.25107 15.2322 7.83624 14.4786 7.65131 13.6556C7.46639 12.8326 7.51898 11.974 7.80297 11.1797L8.85033 12.227C8.78032 12.7161 8.82519 13.2147 8.98136 13.6835C9.13753 14.1522 9.40072 14.5781 9.75007 14.9275C10.0994 15.2768 10.5253 15.54 10.9941 15.6962C11.4628 15.8524 11.9615 15.8972 12.4505 15.8272Z" fill="currentColor"/>
			<path d="M6.08159 9.45775C5.85252 9.66137 5.63236 9.86753 5.42238 10.0775C4.63291 10.8712 3.92518 11.7422 3.30985 12.6774L3.55801 13.044C3.98433 13.6548 4.61427 14.4693 5.42238 15.2774C7.06277 16.9178 9.30256 18.4042 11.9992 18.4042C12.9104 18.4042 13.7681 18.2349 14.5699 17.946L15.5498 18.9285C14.4298 19.4179 13.2214 19.6725 11.9992 19.6768C5.63618 19.6768 1.81836 12.6774 1.81836 12.6774C1.81836 12.6774 3.01334 10.4873 5.17931 8.55675L6.08032 9.45903L6.08159 9.45775ZM19.1843 20.7636L3.91307 5.49231L4.81407 4.59131L20.0853 19.8626L19.1843 20.7636Z" fill="currentColor"/>
		</svg>
	);
};

export default NotVisibleIcon;
