// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConfirmationDialog__actions-QD4{display:flex;gap:8px;justify-content:flex-end;margin-top:40px;width:100%}`, "",{"version":3,"sources":["webpack://./src/modules/PaymentSystems/Tariffs/components/ConfirmationDialog/ConfirmationDialog.module.scss"],"names":[],"mappings":"AAAA,iCACI,YAAA,CACA,OAAA,CACA,wBAAA,CACA,eAAA,CACA,UAAA","sourcesContent":[".actions {\n    display: flex;\n    gap: 8px;\n    justify-content: flex-end;\n    margin-top: 40px;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": `ConfirmationDialog__actions-QD4`
};
export default ___CSS_LOADER_EXPORT___;
