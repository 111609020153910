import React, { useState } from "react";

import WalletDetails from "./WalletDetails";

export const ActionsContext = React.createContext({
	showWalletDetails: (hashID: string) => {
		console.log(hashID);
	},
});

type ActionsProviderPropsType = {
	readonly children: React.ReactNode;
};

const ActionsProvider = ({ children }: ActionsProviderPropsType) => {
	const [walletHashID, setWalletHashID] = useState<string>();

	return (
		<ActionsContext.Provider
			value={{
				showWalletDetails: setWalletHashID,
			}}
		>
			{walletHashID && <WalletDetails onClose={() => setWalletHashID(undefined)} walletHashID={walletHashID} />}
			{children}
		</ActionsContext.Provider>
	);
};

export default ActionsProvider;
