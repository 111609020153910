// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Spoiler__spoiler-N_8 .Spoiler__control-m8G{display:flex;justify-content:space-between;align-items:center;height:40px;width:100%;margin:0;padding:0 16px;border:0;background:#f2f3f5;border-radius:4px;cursor:pointer;font-weight:400;font-size:16px;color:#21272a}.Spoiler__spoiler-N_8 .Spoiler__body-m4E{background:#fff;display:none;border:1px solid #f2f3f5;padding:16px}.Spoiler__spoiler-N_8.Spoiler__expanded-MCC>.Spoiler__control-m8G{border-radius:4px 4px 0 0}.Spoiler__spoiler-N_8.Spoiler__expanded-MCC>.Spoiler__control-m8G>svg{transform:rotateX(180deg)}.Spoiler__spoiler-N_8.Spoiler__expanded-MCC .Spoiler__body-m4E{display:block}`, "",{"version":3,"sources":["webpack://./src/components/Spoiler/Spoiler.module.scss"],"names":[],"mappings":"AACI,4CACI,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,WAAA,CACA,UAAA,CACA,QAAA,CACA,cAAA,CACA,QAAA,CAEA,kBAAA,CACA,iBAAA,CACA,cAAA,CACA,eAAA,CACA,cAAA,CAEA,aAAA,CAGJ,yCACI,eAAA,CACA,YAAA,CAEA,wBAAA,CACA,YAAA,CAIA,kEACI,yBAAA,CAEA,sEACI,yBAAA,CAIR,+DACI,aAAA","sourcesContent":[".spoiler {\n    .control {\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n        height: 40px;\n        width: 100%;\n        margin: 0;\n        padding: 0 16px;\n        border: 0;\n        /* Blue_gray/10 */\n        background: #F2F3F5;\n        border-radius: 4px;\n        cursor: pointer;\n        font-weight: 400;\n        font-size: 16px;\n        /* Blue_gray/90 */\n        color: #21272A;\n    }\n\n    .body {\n        background: #FFFFFF;\n        display: none;\n        /* Blue_gray/10 */\n        border: 1px solid #F2F3F5;\n        padding: 16px;\n    }\n\n    &.expanded {\n        > .control {\n            border-radius: 4px 4px 0 0;\n\n            > svg {\n                transform: rotateX(180deg);\n            }\n        }\n\n        .body {\n            display: block;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spoiler": `Spoiler__spoiler-N_8`,
	"control": `Spoiler__control-m8G`,
	"body": `Spoiler__body-m4E`,
	"expanded": `Spoiler__expanded-MCC`
};
export default ___CSS_LOADER_EXPORT___;
