// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputFormatHint__infoAlertWrapper-AT0{margin-top:24px}.InputFormatHint__infoAlertWrapper-AT0>div>h4{font-weight:400;font-size:14px}.InputFormatHint__infoAlertWrapper-AT0 .InputFormatHint__alertDescription-L9W{margin-left:4px;font-weight:600}.InputFormatHint__warningAlertWrapper-gcJ{margin-top:16px}.InputFormatHint__warningAlertWrapper-gcJ>div>h4{font-weight:400;font-size:14px}`, "",{"version":3,"sources":["webpack://./src/modules/Proxies/components/InputFormatHint/inputFormatHint.module.scss"],"names":[],"mappings":"AAAA,uCACC,eAAA,CAEA,8CACC,eAAA,CACA,cAAA,CAGD,8EACC,eAAA,CACA,eAAA,CAIF,0CACC,eAAA,CAEA,iDACC,eAAA,CACA,cAAA","sourcesContent":[".infoAlertWrapper {\n\tmargin-top: 24px;\n\n\t& > div > h4 {\n\t\tfont-weight: 400;\n\t\tfont-size: 14px;\n\t}\n\n\t.alertDescription {\n\t\tmargin-left: 4px;\n\t\tfont-weight: 600;\n\t}\n}\n\n.warningAlertWrapper {\n\tmargin-top: 16px;\n\n\t& > div > h4 {\n\t\tfont-weight: 400;\n\t\tfont-size: 14px;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoAlertWrapper": `InputFormatHint__infoAlertWrapper-AT0`,
	"alertDescription": `InputFormatHint__alertDescription-L9W`,
	"warningAlertWrapper": `InputFormatHint__warningAlertWrapper-gcJ`
};
export default ___CSS_LOADER_EXPORT___;
