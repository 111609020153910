import { FormTypesItemType, TranslationHintType } from "api/translationsGroup";
import RightChevron from "assets/icons/right_chevron.svg";
import Spoiler from "components/Spoiler";
import React from "react";

import { ActionsContext } from '../../components/ActionsProvider';
import EditTranslationPopup from '../../components/EditTranslationPopup';
import styles from "./NavigationTree.module.scss";

type NavigationTreePropsType = {
	readonly data: readonly {
		readonly name: string;
		readonly formTypes: readonly {
			readonly formType: FormTypesItemType;
			readonly hint: TranslationHintType;
		}[];
		readonly isDefault: boolean;
	}[],
	readonly selectedField?: string;
	readonly setSelectedField: (fieldId: string) => void;
	readonly usedNames: readonly string[];
}

const NavigationTree: React.FC<NavigationTreePropsType> = ({ data, selectedField, setSelectedField, usedNames }) => (
	<div className={styles.navigationTree}>
		{data.map((field) => (
			<Spoiler
				key={field.name}
				className={styles.spoiler}
				expandedClassName={styles.expanded}
				title={
					<>
						{!field.isDefault && (
							<ActionsContext.Consumer>{({ setRemoveModalData, setRenameModalData }) => (
								<EditTranslationPopup
									onRename={() => setRenameModalData({ formTypes: field.formTypes, usedNames, fieldName: field.name, translationType: "hint" })}
									onRemove={() => setRemoveModalData({ hints: field.formTypes.map(ft => ft.hint.uuid), fieldName: field.name, translationType: "hint" })}
								/>
							)}</ActionsContext.Consumer>
						)}
						<div title={field.name} className={styles.translationName}>{field.name}</div>
					</>
				}
			>
				{field.formTypes.map((formType) => (
					<React.Fragment key={formType.formType.title}>
						<button
							className={formType.hint.uuid === selectedField ? styles.active : undefined}
							key={formType.formType.title}
							onClick={() => setSelectedField(formType.hint.uuid)}
							data-test-id="hint-button"
						>
							{formType.formType.title}
							<RightChevron />
						</button>
					</React.Fragment>
				))}
			</Spoiler>
		))}
	</div>
);

export default NavigationTree;
