// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PossibleTransactionStatus__TransactionStatus-pRx{line-height:20px;padding:0 8px;border-radius:10px;text-transform:uppercase;font-weight:500;font-size:14px}.PossibleTransactionStatus__PROCESSED-nmS{background:#e8faed;color:#24a148}.PossibleTransactionStatus__NEW-jTH{background:#f1faff;color:#0072c3}`, "",{"version":3,"sources":["webpack://./src/modules/Transactions/components/PossibleTransactionStatus/PossibleTransactionStatus.module.scss"],"names":[],"mappings":"AAAA,kDACC,gBAAA,CACA,aAAA,CACA,kBAAA,CACA,wBAAA,CACA,eAAA,CACA,cAAA,CAGD,0CAEC,kBAAA,CAEA,aAAA,CAGD,oCAEC,kBAAA,CAEA,aAAA","sourcesContent":[".TransactionStatus {\n\tline-height: 20px;\n\tpadding: 0 8px;\n\tborder-radius: 10px;\n\ttext-transform: uppercase;\n\tfont-weight: 500;\n\tfont-size: 14px;\n}\n\n.PROCESSED {\n\t/* Additional/Green/Ghost */\n\tbackground: #E8FAED;\n\t/* Additional/Green/Primary */\n\tcolor: #24A148;\n}\n\n.NEW {\n\t/* Brand/Ghost */\n\tbackground: #F1FAFF;\n\t/* Brand/Primary */\n\tcolor: #0072C3;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TransactionStatus": `PossibleTransactionStatus__TransactionStatus-pRx`,
	"PROCESSED": `PossibleTransactionStatus__PROCESSED-nmS`,
	"NEW": `PossibleTransactionStatus__NEW-jTH`
};
export default ___CSS_LOADER_EXPORT___;
