// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProxyContext__UpdateProxyModalWrapper-N5z .ProxyContext__UpdateProxyModalLoader-BBh{margin:auto}.ProxyContext__UpdateProxyModalWrapper-N5z>div>div{overflow:hidden}.ProxyContext__walletsAmountWrapper-EF3{width:560px}`, "",{"version":3,"sources":["webpack://./src/modules/Proxies/contexts/ProxyContext/proxyContext.module.scss"],"names":[],"mappings":"AACC,qFACC,WAAA,CAIF,mDACC,eAAA,CAGD,wCACC,WAAA","sourcesContent":[".UpdateProxyModalWrapper {\n\t.UpdateProxyModalLoader {\n\t\tmargin: auto;\n\t}\n}\n\n.UpdateProxyModalWrapper > div > div {\n\toverflow: hidden;\n}\n\n.walletsAmountWrapper {\n\twidth: 560px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UpdateProxyModalWrapper": `ProxyContext__UpdateProxyModalWrapper-N5z`,
	"UpdateProxyModalLoader": `ProxyContext__UpdateProxyModalLoader-BBh`,
	"walletsAmountWrapper": `ProxyContext__walletsAmountWrapper-EF3`
};
export default ___CSS_LOADER_EXPORT___;
