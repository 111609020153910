import { IWithdrawalCommonFields } from "api/withdrawalGroup";
import { useTranslation } from "pay-kit";
import { Tooltip } from "pay-kit";
import React from "react";

import styles from "./WithdrawalType.module.scss";

interface IWithdrawalType {
	readonly isTest: IWithdrawalCommonFields["is_test"] | undefined;
	readonly dataTestId?: string;
}

const WithdrawalType: React.FC<IWithdrawalType> = ({ isTest, dataTestId }) => {
	const {t} = useTranslation();
	const classNames = [styles.WithdrawalType];

	switch (isTest) {
		case true:
			classNames.push(styles.TESTING);
			break;
		case false:
			classNames.push(styles.APPROVED);
			break;
		default:
			classNames.push(styles.isNotDefined);
	}

	return (
		<Tooltip tip={t("Request kind")} preferredSide="bottom">
			<div className={classNames.join(" ")} data-test-id={dataTestId}>
				{/* {isTest === true && "ОТЛАДОЧНАЯ"} */}
				{isTest === true && t("Debug")}
				{/* {isTest === false && "ПОДТВЕРЖДЕННАЯ"} */}
				{isTest === false && t("Confirmed")}
				{/* {isTest !== false && isTest !== true && "НЕ ОПРЕДЕЛЕН"} */}
				{isTest !== false && isTest !== true && t("Not defined")}
			</div>
		</Tooltip>
	);
};

export default WithdrawalType;
