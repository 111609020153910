export const numberWithSpaces = (x: string | number) => {
	if (x === null) {
		return null;
	}

	let parts = x.toString().split(".");
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
	if (parts.length === 1) {
		parts = [...parts, "00"];
	}

	return parts.join(".");
};
