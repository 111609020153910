import { WalletTypeType } from "api/walletGroup";
import { Roles } from "contexts/AuthContext/utils/enums";
import { WorkingWalletContext } from "modules/Wallets/contexts/WorkingWalletContext";
import ActivateIcon from "modules/Wallets/DisabledWallets/components/Actions/icons/ActivateIcon";
import ArchiveIcon from "modules/Wallets/DisabledWallets/components/Actions/icons/ArchiveIcon";
import DebugIcon from "modules/Wallets/WorkingWallets/components/Actions/icons/DebugIcon";
import EditIcon from "modules/Wallets/WorkingWallets/components/Actions/icons/EditIcon";
import { useTranslation } from "pay-kit";
import { Button } from "pay-kit";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import RoleContext from "src/contexts/AuthContext";

import styles from "./Actions.module.scss";

const Actions = ({ hash_id, identifier }: ActionsProps) => {
	const { openArchiveWalletModal, openActivateWalletModal, openEditWalletModal } = useContext(WorkingWalletContext);
	const { hasRole } = useContext(RoleContext);
	const navigate = useNavigate();
	const { t } = useTranslation();

	const editPermission = hasRole(Roles.WALLET_UPDATE);
	const logListPermission = hasRole(Roles.LOGS);

	const handleEdit = (e: React.MouseEvent) => {
		e.stopPropagation();
		openEditWalletModal(hash_id);
	};

	const handleArchive = (e: React.MouseEvent) => {
		e.stopPropagation();
		openArchiveWalletModal(hash_id);
	};

	const handleActivate = (e: React.MouseEvent) => {
		e.stopPropagation();
		openActivateWalletModal(hash_id);
	};

	//TODO: Поменять ссылку когда появится страница
	const handleDebug = (e: React.MouseEvent) => {
		e.stopPropagation();
		navigate(`/logs?identifier=${identifier}&page=1&limit=10`);
	};

	return (
		<div className={styles.wrapper}>
			{editPermission && (
				<Button data-test-id="i5O_Y0AkdXZVvg7OZ1RP0" variant="secondary" onClick={handleEdit} dataTestId="editButton">
					<EditIcon />
					<div className={styles.label}>{t("Edit")}</div>
				</Button>
			)}
			{logListPermission && (
				<Button
					data-test-id="VDwiPDCwP5ll0eAQ02F8A"
					variant="secondary"
					onClick={handleDebug}
					dataTestId="debuggingButton"
				>
					<DebugIcon />
					<div className={styles.label}>{t("Debug")}</div>
				</Button>
			)}
			{hasRole(Roles.WALLET_DELETE) && (
				<Button
					data-test-id="biUnJ2eoFFrbVNWsRFcjq"
					variant="delete"
					onClick={handleArchive}
					dataTestId="archiveButton"
				>
					<ArchiveIcon />
					<div className={styles.label}>{t("Archive")}</div>
				</Button>
			)}
			{hasRole(Roles.WALLET_SET_ACTIVE) && (
				<Button
					data-test-id="_ziZFSEVL2zid-AfU9T3n"
					color="#24a148"
					onClick={handleActivate}
					dataTestId="activateButtton"
				>
					<ActivateIcon />
					<div className={styles.label}>{t("Activate")}</div>
				</Button>
			)}
		</div>
	);
};

export default Actions;

type ActionsProps = {
	readonly hash_id: WalletTypeType[`hash_id`];
	readonly identifier: WalletTypeType[`identifier`];
};
