// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Comments__commentCell-fLF{cursor:pointer}.Comments__comment-mOJ{overflow:hidden;text-overflow:ellipsis}.Comments__commentWrapper-KaD{display:flex;gap:4px;align-items:center;justify-content:space-between}.Comments__commentWrapper-KaD [class*=Button__Button]{min-width:auto;padding:0 4px;height:auto}.Comments__commentWrapper-KaD [class*=Button__Button] svg{width:16px}.Comments__penIcon-tPa{fill:#697077}`, "",{"version":3,"sources":["webpack://./src/modules/Wallets/components/Comments/Comments.module.scss"],"names":[],"mappings":"AAAA,2BACC,cAAA,CAGD,uBAIC,eAAA,CACA,sBAAA,CAGD,8BACC,YAAA,CACA,OAAA,CACA,kBAAA,CACA,6BAAA,CAGD,sDACC,cAAA,CACA,aAAA,CACA,WAAA,CAEA,0DACC,UAAA,CAIF,uBACC,YAAA","sourcesContent":[".commentCell {\n\tcursor: pointer;\n}\n\n.comment {\n\t//display: -webkit-box;\n\t//-webkit-box-orient: vertical;\n\t//-webkit-line-clamp: 2;\n\toverflow: hidden;\n\ttext-overflow: ellipsis;\n}\n\n.commentWrapper {\n\tdisplay: flex;\n\tgap: 4px;\n\talign-items: center;\n\tjustify-content: space-between;\n}\n\n.commentWrapper [class*=\"Button__Button\"] {\n\tmin-width: auto;\n\tpadding: 0 4px;\n\theight: auto;\n\n\tsvg {\n\t\twidth: 16px;\n\t}\n}\n\n.penIcon {\n\tfill: #697077;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"commentCell": `Comments__commentCell-fLF`,
	"comment": `Comments__comment-mOJ`,
	"commentWrapper": `Comments__commentWrapper-KaD`,
	"penIcon": `Comments__penIcon-tPa`
};
export default ___CSS_LOADER_EXPORT___;
