import { t } from "pay-kit";
import { Withdrawals } from "api/balanceGroup";
import DynamicMultilineText from "components/DynamicMultilineText";
import Actions from "modules/TransferHistory/components/WithdrawalsHistoryTabs/components/Actions";
import Amount from "modules/TransferHistory/components/WithdrawalsHistoryTabs/components/Amount";
import CreatedAt from "modules/TransferHistory/components/WithdrawalsHistoryTabs/components/CreatedAt";
import Status from "modules/TransferHistory/components/WithdrawalsHistoryTabs/components/Status";

export const withdrawalsRequestPageColumns = [
	{
		title: t("Date • Time"),
		dataIndex: "created_at",
		key: "created_at",
		render: ({ created_at }: Withdrawals) => <CreatedAt created_at={created_at} />
	},
	{
		title: t("Write off address"),
		dataIndex: "bitcoin_address",
		key: "bitcoin_address",
		render: ({ bitcoin_address }: Withdrawals) => <DynamicMultilineText text={bitcoin_address} />
	},
	{
		title: t("Amount"),
		dataIndex: "amount",
		key: "amount",
		render: ({ amount }: Withdrawals) => <Amount amount={amount} />
	},
	{
		title: t("Status"),
		dataIndex: "status",
		key: "status",
		render: ({ status }: Withdrawals) => <Status status={status} />
	},
	{
		title: t("Actions"),
		dataIndex: "actions_status",
		key: "actions_status",
		render: ({ status, id }: Withdrawals) => <Actions status={status} id={id} />
	}
];
