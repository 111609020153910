import styles from './Switcher.module.scss';

interface ISwitcherProps {
	readonly onSwitch: (value: string | number | undefined) => void;
	readonly options: readonly { readonly value: string | number | undefined; readonly label: string | number }[];
	readonly value: string | number | undefined;
	readonly label?: string;
	readonly className?: string;
}

const Switcher: React.FC<ISwitcherProps> = ({ onSwitch, options, value, label, className }) => (
	<label className={[styles.wrapper, className].filter((c) => c !== undefined).join(' ')}>
		{label && <div className={styles.label}>{label}</div>}

		<div className={styles.switcher}>
			{options.map((option) => (
				<button
					key={option.value}
					className={option.value === value ? styles.active : undefined}
					onClick={() => onSwitch(option.value)}
					data-test-id="switcher-button"
				>
					{option.label}
				</button>
			))}
		</div>
	</label>
);

export default Switcher;