// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DetailedView__DetailedViewWrapper-xph{width:560px;display:flex;flex-direction:column}`, "",{"version":3,"sources":["webpack://./src/modules/Withdrawals/components/ActionsProvider/DetailedView/DetailedView.module.scss"],"names":[],"mappings":"AAAA,uCACC,WAAA,CACA,YAAA,CACG,qBAAA","sourcesContent":[".DetailedViewWrapper {\n\twidth: 560px;\n\tdisplay: flex;\n    flex-direction: column;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DetailedViewWrapper": `DetailedView__DetailedViewWrapper-xph`
};
export default ___CSS_LOADER_EXPORT___;
