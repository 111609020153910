import { useTranslation } from "pay-kit";
import MoreIcon from "assets/icons/more.svg";
import { useOutsideClick } from "pay-kit";
import { useRef, useState } from "react";

import PenIcon from "/assets/icons/edit_16x16.svg";
import TrashIcon from "/assets/icons/trash_16x16.svg";

import styles from "./EditTranslationPopup.module.scss";

type EditTranslationPopupProps = {
	readonly onRename: () => void;
	readonly onRemove: () => void;
}

const EditTranslationPopup: React.FC<EditTranslationPopupProps> = ({ onRename, onRemove }) => {
	const {t} = useTranslation();
	const [isPopupVisible, setPopupVisibility] = useState<boolean>(false);
	const containerRef = useRef<HTMLDivElement>(null);

	useOutsideClick(containerRef, () => setPopupVisibility(false));

	return (
		<div
			data-test-id="edit-translation-button"
			onClick={(e) => {
				e.stopPropagation();
				setPopupVisibility(true);
			}}
			className={styles.editTranslationButton}
			role="button"
		>
			{isPopupVisible && (
				<div ref={containerRef}>
					<div className={styles.popupContent}>
						<button
							className={styles.edit}
							onClick={onRename}
							data-test-id="edit"
						>
							<PenIcon />
							{t("Edit")}
						</button>
						<button
							className={styles.remove}
							onClick={onRemove}
							data-test-id="remove"
						>
							<TrashIcon />
							{t("Delete")}
						</button>
					</div>
				</div>
			)}
			<MoreIcon />
		</div>
	);
};

export default EditTranslationPopup;