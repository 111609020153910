import { FC } from "react";

type Props = {
	readonly width: number;
	readonly height: number;
};

export const InfoIcon: FC<Props> = ({ height, width }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 64 64" fill="none">
		<path
			d="M33.5595 28.5487C33.4254 27.8101 32.779 27.25 32.0017 27.25C31.1272 27.25 30.4183 27.9589 30.4183 28.8333V43.0902L30.4438 43.3748C30.5779 44.1134 31.2244 44.6735 32.0017 44.6735C32.8761 44.6735 33.585 43.9646 33.585 43.0902V28.8333L33.5595 28.5487ZM34.5309 21.7083C34.5309 20.3967 33.4676 19.3333 32.1559 19.3333C30.8442 19.3333 29.7809 20.3967 29.7809 21.7083C29.7809 23.02 30.8442 24.0833 32.1559 24.0833C33.4676 24.0833 34.5309 23.02 34.5309 21.7083ZM57.3346 32C57.3346 18.0088 45.9925 6.66669 32.0013 6.66669C18.0101 6.66669 6.66797 18.0088 6.66797 32C6.66797 45.9912 18.0101 57.3334 32.0013 57.3334C45.9925 57.3334 57.3346 45.9912 57.3346 32ZM9.83464 32C9.83464 19.7577 19.759 9.83335 32.0013 9.83335C44.2436 9.83335 54.168 19.7577 54.168 32C54.168 44.2423 44.2436 54.1667 32.0013 54.1667C19.759 54.1667 9.83464 44.2423 9.83464 32Z"
			fill="#697077"
		/>
	</svg>
);
