// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Plugin__container-ZS9{padding-bottom:48px}.Plugin__pluginSection-Aeo{border-radius:8px}.Plugin__pluginSection-Aeo .Plugin__generateFieldsValuesHeader-y6E{margin-top:24px;font-size:14px;font-weight:400}.Plugin__button-agl{padding:24px}.Plugin__psHintsWrapper-H4S{margin-top:12px}.Plugin__displayOrder-CJ4{margin-bottom:40px}.Plugin__psHintsScroll-tQi.Plugin__psHintsScroll-tQi{max-height:364px;scrollbar-width:thin}`, "",{"version":3,"sources":["webpack://./src/modules/AppSettings/Plugin/pluginInstallation.module.scss"],"names":[],"mappings":"AAAA,uBACC,mBAAA,CAGD,2BACC,iBAAA,CAEA,mEACC,eAAA,CACA,cAAA,CACA,eAAA,CAIF,oBACC,YAAA,CAGD,4BACC,eAAA,CAGD,0BACC,kBAAA,CAGD,qDACC,gBAAA,CACA,oBAAA","sourcesContent":[".container {\n\tpadding-bottom: 48px;\n}\n\n.pluginSection {\n\tborder-radius: 8px;\n\n\t.generateFieldsValuesHeader {\n\t\tmargin-top: 24px;\n\t\tfont-size: 14px;\n\t\tfont-weight: 400;\n\t}\n}\n\n.button {\n\tpadding: 24px;\n}\n\n.psHintsWrapper {\n\tmargin-top: 12px;\n}\n\n.displayOrder {\n\tmargin-bottom: 40px;\n}\n\n.psHintsScroll.psHintsScroll {\n\tmax-height: 364px;\n\tscrollbar-width: thin;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Plugin__container-ZS9`,
	"pluginSection": `Plugin__pluginSection-Aeo`,
	"generateFieldsValuesHeader": `Plugin__generateFieldsValuesHeader-y6E`,
	"button": `Plugin__button-agl`,
	"psHintsWrapper": `Plugin__psHintsWrapper-H4S`,
	"displayOrder": `Plugin__displayOrder-CJ4`,
	"psHintsScroll": `Plugin__psHintsScroll-tQi`
};
export default ___CSS_LOADER_EXPORT___;
