import { useLogsListContext } from "modules/WalletsDebugging/LogsList/contexts/LogsListContext";
import { FC } from "react";

import styles from "./walletID.module.scss";

const WalletIdentifier: FC<WalletIdentifierType> = ({ wallet_identifier, wallet_hash_id, id, dataTestId }) => {
	const { setWalletHashId, setID } = useLogsListContext();

	const onClick = () => {
		setWalletHashId(wallet_hash_id);
		setID(id);
	};

	return (
		<span className={styles.walletID} onClick={onClick} data-test-id={dataTestId}>
			{wallet_identifier}
		</span>
	);
};

export default WalletIdentifier;

type WalletIdentifierType = {
	readonly wallet_identifier: string;
	readonly wallet_hash_id: string;
	readonly id: number;
	readonly dataTestId?: string;
};
