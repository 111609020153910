// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Actions__accept-NG2{border:initial;border-radius:4px;padding:8px 12px 8px 0;cursor:pointer;background:#24a148;color:#fff}.Actions__accept-NG2 .Actions__checkMark-vNU{margin-right:10px;margin-left:14px}.Actions__decline-gyJ{border:initial;border-radius:4px;padding:8px 12px 8px 0;cursor:pointer;background:#fff1f1;color:#dc3545;margin-left:8px}.Actions__decline-gyJ .Actions__checkMark-vNU{margin-right:10px;margin-left:14px}.Actions__accept-NG2:hover{background:#14a83d}.Actions__decline-gyJ:hover{background:#fcd9d9}`, "",{"version":3,"sources":["webpack://./src/modules/TransferHistory/components/WithdrawalsHistoryTabs/components/Actions/actions.module.scss"],"names":[],"mappings":"AAOA,qBANC,cAAA,CACA,iBAAA,CACA,sBAAA,CACA,cAAA,CAKA,kBAAA,CACA,UAAA,CAEA,6CACC,iBAAA,CACA,gBAAA,CAIF,sBAjBC,cAAA,CACA,iBAAA,CACA,sBAAA,CACA,cAAA,CAgBA,kBAAA,CACA,aAAA,CACA,eAAA,CAEA,8CACC,iBAAA,CACA,gBAAA,CAIF,2BACC,kBAAA,CAGD,4BACC,kBAAA","sourcesContent":["@mixin actionsButton {\n\tborder: initial;\n\tborder-radius: 4px;\n\tpadding: 8px 12px 8px 0;\n\tcursor: pointer;\n}\n\n.accept {\n\t@include actionsButton;\n\tbackground: #24A148;\n\tcolor: #FFFFFF;\n\n\t.checkMark {\n\t\tmargin-right: 10px;\n\t\tmargin-left: 14px;\n\t}\n}\n\n.decline {\n\t@include actionsButton;\n\tbackground: #FFF1F1;\n\tcolor: #DC3545;\n\tmargin-left: 8px;\n\n\t.checkMark {\n\t\tmargin-right: 10px;\n\t\tmargin-left: 14px;\n\t}\n}\n\n.accept:hover {\n\tbackground: #14a83d;\n}\n\n.decline:hover {\n\tbackground: #fcd9d9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accept": `Actions__accept-NG2`,
	"checkMark": `Actions__checkMark-vNU`,
	"decline": `Actions__decline-gyJ`
};
export default ___CSS_LOADER_EXPORT___;
