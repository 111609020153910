import { fetchOldApi } from "api/fetch";

const tariffsGroup = {
	getTariffs: () => fetchOldApi("/tariff", {}, "GET"),
	getTariff: (id: number) => fetchOldApi(`/tariff/${id}`, {}, "GET"),
	addCommission: (commission: NewCommissionType) => fetchOldApi(`/commission`, commission, "POST"),
	removeCommission: (id: number) => fetchOldApi(`/commission/${id}`, {}, "DELETE"),
	editCommission: (id: number, commission: EditCommissionType) => fetchOldApi(`/commission/${id}`, commission, "PUT"),
};

export type TariffItemType = {
	readonly id: number;
	readonly wallet_type: string;
	readonly currency: string;
};

export type TariffType = {
	readonly id: number;
	readonly wallet_type: string;
	readonly currency: string;
	readonly commissions: readonly {
		readonly id: number;
		readonly type: "fix" | "percent" | "settlement";
		readonly direction: "ingoing" | "outgoing";
		readonly commission: string;
		readonly min_amount: string;
		readonly max_amount: string | null;
	}[];
};

export type EditCommissionType = {
	readonly tariff_id: number;
	readonly type: "fix" | "percent";
	readonly direction: "ingoing" | "outgoing";
	readonly commission: string;
	readonly min_amount: string;
	readonly max_amount: string | null;
};

export type NewCommissionType = EditCommissionType;

export default tariffsGroup;
