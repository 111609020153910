// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Accounts__accountsWrapper-k_f{margin-top:36px}.Accounts__accountsFilterContainer-Ugm{display:inline-block;margin-left:24px}.Accounts__filterWrapper-oNT{display:flex}.Accounts__filtersHeader-S4s{font-size:20px;font-weight:500;color:#21272a}.Accounts__accountsBalancesTable-mJW{width:1270px;margin-top:26px;table-layout:fixed;margin-bottom:35px}.Accounts__accountsBalancesTable-mJW th{white-space:pre}.Accounts__accountsBalancesTable-mJW td{box-sizing:content-box;height:4em}.Accounts__accountsBalancesTable-mJW col:nth-child(1){width:150px}`, "",{"version":3,"sources":["webpack://./src/modules/Balance/Accounts/accounts.module.scss"],"names":[],"mappings":"AAAA,+BACC,eAAA,CAGD,uCACC,oBAAA,CACA,gBAAA,CAGD,6BACC,YAAA,CAGD,6BACC,cAAA,CACA,eAAA,CACA,aAAA,CAID,qCACC,YAAA,CACA,eAAA,CACA,kBAAA,CACA,kBAAA,CAEA,wCACC,eAAA,CAGD,wCACC,sBAAA,CACA,UAAA,CAGD,sDACC,WAAA","sourcesContent":[".accountsWrapper {\n\tmargin-top: 36px;\n}\n\n.accountsFilterContainer {\n\tdisplay: inline-block;\n\tmargin-left: 24px;\n}\n\n.filterWrapper {\n\tdisplay: flex;\n}\n\n.filtersHeader {\n\tfont-size: 20px;\n\tfont-weight: 500;\n\tcolor: #21272A;\n}\n\n\n.accountsBalancesTable {\n\twidth: 1270px;\n\tmargin-top: 26px;\n\ttable-layout: fixed;\n\tmargin-bottom: 35px;\n\n\tth {\n\t\twhite-space: pre;\n\t}\n\n\ttd {\n\t\tbox-sizing: content-box;\n\t\theight: 4em;\n\t}\n\n\tcol:nth-child(1) {\n\t\twidth: 150px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accountsWrapper": `Accounts__accountsWrapper-k_f`,
	"accountsFilterContainer": `Accounts__accountsFilterContainer-Ugm`,
	"filterWrapper": `Accounts__filterWrapper-oNT`,
	"filtersHeader": `Accounts__filtersHeader-S4s`,
	"accountsBalancesTable": `Accounts__accountsBalancesTable-mJW`
};
export default ___CSS_LOADER_EXPORT___;
