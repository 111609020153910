import { fetchApi,fetchOldApi } from "./fetch";

const postbackGroup = {
	getList: (params: getListParamsType) => fetchOldApi("/postback/list", params, "GET"),
	getOne: ({ id, direction }: getOneParamsType) => fetchApi(`/postback/${id}`, { direction }, "GET"),
	forceSend: (id: postbackType["id"]) => fetchApi(`/postback/resend/${id}`, undefined, "POST"),
	resetCountFailure: (postback_ids: readonly postbackType["id"][]) => fetchOldApi(`/postback/reset-count-failure`, { postback_ids }, "POST"),
	markAsSent: (postback_ids: readonly postbackType["id"][]) => fetchOldApi(`/postback/mark-as-sent`, { postback_ids }, "POST")
};

export default postbackGroup;

export type getOneParamsType = {
	readonly id: postbackType["id"];
	readonly direction: postbackType["direction"];
};

export type postbackType = {
	readonly id: number;
	readonly url: string;
	readonly label: string;
	readonly wallet_type: string;
	readonly direction: "outgoing" | "ingoing";
	readonly request_body: string;
	readonly status_code: 400 | 200 | 500;
	readonly response: string;
	readonly status: 1 | 0;
	readonly count_failure: number;
	readonly created_at: string;
	readonly merchant_order_id: number
};

export type getListFilterParamsType = {
	readonly date_from?: string;
	readonly date?: {
		readonly from: string;
		readonly to: string;
	};
	readonly date_to?: string;
	readonly wallet_type?: string;
	readonly direction?: "outgoing" | "ingoing";
	readonly label?: string;
};

export type getListParamsType = {
	readonly accepted: 0 | 1;
	readonly limit?: number;
	readonly page?: number;
} & getListFilterParamsType;
