import { t } from "pay-kit";
import { StockPilingsType } from "api/stockpilingsGroup";
import Money from "components/Money";
import PaymentSystemLogo from "components/PaymentSystemLogo";
import StockpilingsContextProvider from "modules/Stockpilings/components/Columns/contexts/StockpilingsContext";
import StockpilingsActions from "modules/Stockpilings/components/StockpilingsActions";
import { Badge, Tooltip } from "pay-kit";

import CheckMark from "../../../../assets/icons/checkMark.svg";
import styles from "./columns.module.scss";

const paymentSystemCol = {
	// title: "Тип\nкошелька",
	title: `${t("Wallet type")}`,
	dataIndex: "payment_system",
	key: "payment_system",
	render: ({ payment_system }: StockPilingsType) => (
		<PaymentSystemLogo id={payment_system} dataTestId="payment_system" />
	),
};

const idCol = {
	title: t("ID"),
	dataIndex: "id",
	key: "id",
	dataTestId: "id",
};

const StockPilingsLabelCol = {
	title: t("Label"),
	dataIndex: "label",
	key: "label",
	render: ({ label }: StockPilingsType) => (
		<Tooltip tip={label} preferredSide="bottom">
			<div className={styles.stockPilingsLabel} data-test-id="label">
				{label}
			</div>
		</Tooltip>
	),
};

const minimalDepositCol = {
	// title: `Минимальная\nсумма`,
	title: `${t("Minimum")}\n${t("Amount")}`,
	dataIndex: "min_dep",
	key: "min_dep",
	render: ({ min_amount, code }: StockPilingsType & { readonly code: string }) => (
		<Money amount={parseFloat(min_amount)} currencyCode={code} dataTestId="min_dep" />
	),
};

const amountCol = {
	// title: `Сумма\nнакоплений`,
	title: `${t("Amount")}\n${t("Stockpilings")}`,
	dataIndex: "amount",
	key: "amount",
	render: ({ amount, code }: StockPilingsType & { readonly code: string }) => (
		<Money amount={parseFloat(amount)} currencyCode={code} dataTestId="amount" />
	),
};

const stockPilingTypeCol = {
	// title: `Тип накоплений`,
	title: t("Stockpilings type"),
	dataIndex: "is_real",
	key: "is_real",
	render: ({ is_real }: StockPilingsType) => (
		<div className={styles.isRealHall} data-test-id="is_real">
			<Badge variant={is_real ? "green" : "orange"}>{is_real ? t("Real") : t("Debug")}</Badge>
		</div>
	),
};

const statusCol = {
	title: t("Status"),
	dataIndex: "status",
	key: "status",
	render: ({ status }: StockPilingsType) => (
		<>
			{status ? (
				<span data-test-id="status">
					<CheckMark />
				</span>
			) : null}
		</>
	),
};

const transactionsCountCol = {
	// title: `Количество\nтранзакций`,
	title: `${t("Number")}\n${t("Transactions")}`,
	dataIndex: "count_transactions",
	key: "count_transactions",
	dataTestId: "count_transactions",
};

const actionsCol = {
	title: t("Actions"),
	dataIndex: "postback_send",
	key: "postback_send",
	render: ({ postback_send, id, direction, postback_id }: StockPilingsType) => (
		<StockpilingsContextProvider>
			<StockpilingsActions
				postback_send={postback_send}
				stockpiling_id={id}
				postback_id={postback_id}
				direction={direction}
			/>
		</StockpilingsContextProvider>
	),
};

export const stockPilingsColumns = [
	paymentSystemCol,
	idCol,
	StockPilingsLabelCol,
	minimalDepositCol,
	amountCol,
	stockPilingTypeCol,
	statusCol,
	transactionsCountCol,
	actionsCol,
];
