import {RefreshData, TokenData, UserData} from "contexts/AuthContext/utils/types";

import { fetchApi, fetchOldApi } from "./fetch";

const authGroup = {
	login: (login: string, password: string) => fetchApi<TokenData>("auth/login", { name: login, password }, "POST", {}, { withToken: false }),
	me: () => fetchApi<UserData>("user/me"),
	refresh: () => fetchApi<RefreshData>("auth/refresh", undefined, "POST"),
	logout: (refreshToken: string) => fetchApi<void>("auth/logout", { refresh_token: refreshToken }, "POST"),
	exchange: (sa_token: string) => fetchApi(`auth/exchange`, { token: sa_token }, `POST`, {},  {withToken: false }),
};

export default authGroup;
