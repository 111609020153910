// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Rate__rateTextInput-eL4 .Rate__inputContainer-Ogd>div{width:304px}`, "",{"version":3,"sources":["webpack://./src/modules/TransferHistory/components/TransferringMoney/components/Rate/rate.module.scss"],"names":[],"mappings":"AACC,uDACC,WAAA","sourcesContent":[".rateTextInput {\n\t.inputContainer > div {\n\t\twidth: 304px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rateTextInput": `Rate__rateTextInput-eL4`,
	"inputContainer": `Rate__inputContainer-Ogd`
};
export default ___CSS_LOADER_EXPORT___;
