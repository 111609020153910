import API from "api";
import { PickedUpdateSettingsType } from "api/settingsGroup";
import { stringifyErrorMessage } from "helpers/stringifyErrorMessage";
import { useState } from "react";
import { successMap } from "utils/enums";


const useUpdateSettings = () => {
	const [error, setError] = useState<Error | null>(null);
	const [isLoading, setIsLoading] = useState(false);

	const update = (updatedFields: PickedUpdateSettingsType) => {
		setError(null);
		setIsLoading(true);

		API.settings.updateSettings(updatedFields).then(
			res => {
				if (res.status === "success") {

					window.pushAlert({
						type: `success`,
						description: successMap.updated,
					});

				} else {
					window.pushAlert({
						type: `error`,
						description: stringifyErrorMessage(res.error_message)
					});
				}
			}
		).catch(err => {
			console.log(err);
			setError(err);
		}).finally(() => {
			setIsLoading(false);
		});
	};

	return ({
		update,
		isLoading,
		error
	});
};

export default useUpdateSettings;