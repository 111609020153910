import { t } from "pay-kit";
export const proxyTypes = [`socks4`, `socks5`, `https`, `http`] as const;

export const portPattern = /^\d{1,5}$/;

export const checkedStatuses = {
	WORKING: "WORKING",
	NOT_WORKING: "NOT_WORKING",
	NOT_CHECKED: "NOT_CHECKED",
} as const;

export const IPHOSTpattern =
	/^(?:(?:25[0-5]|2[0-4]\d|[01]?\d{1,2})\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d{1,2})$|^(?:[a-zA-Z\d-]+\.)*[a-zA-Z\d-]+\.[a-zA-Z]{2,}$/;

export const errorMessageIPHOST = t(
	`The field should accept either IP addresses from 0.0.0.0 to 255.255.255.255 or host web addresses`
);
export const errorMessagePort = t(`Port must consist only of digits, and its value should not exceed 65535`);

export const isIPHOSTValid: IsValidType = (value) => (!IPHOSTpattern.test(value) ? errorMessageIPHOST : undefined);
export const isPortValid: IsValidType = (value) =>
	value && !isNaN(+value) && +value >= 0 && +value <= 65535 ? undefined : errorMessagePort;

export const isRequired: IsValidType = (value) => (!value ? t("Required field") : undefined);
export const isIPHOSTRequired = (value: string) => (!value ? t("Required field") : undefined);

type IsValidType = (value: string) => string | undefined;
