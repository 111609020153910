import { t } from "@paykassma/pay-kit";

export const isRequired = (value: any, formState: any) => (!value ? t("Field is required") : undefined);

export const isValidFloatString = (value: string) => {
	return /^[0-9]+(\.)?[0-9]*$/.test(value);
};

export const maxDigitsAfterDecimal = (limit: number) => (floatNumberString: string) => {
	return floatNumberString?.split(".")?.[1]?.length > limit
		? t(`max {{limit}} digits after decimal`, { limit })
		: undefined;
};

export const isValidValue = (value: any, formState: any) =>
	!isValidFloatString(value) ? t("Invalid value") : undefined;

export const isCorrectInterval = (type: "min_amount" | "max_amount") => (value: any, formState: any) => {
	let error: string | undefined = undefined;

	formState.commissions.forEach((interval) => {
		const notFilledInterval = !interval.min_amount || !interval.max_amount;

		if (notFilledInterval) {
			return false;
		}

		const isCurrentInterval = interval[type] === value;

		if (isCurrentInterval) {
			// The minimum limit is greater than the maximum
			const minGreaterMax = type === "min_amount" && parseFloat(value) > parseFloat(interval.max_amount);
			if (minGreaterMax) {
				error = t(`"From" more than "To"`);
			}

			// The maximum limit is less than the maximum
			const maxLessMin = type === "max_amount" && parseFloat(value) < parseFloat(interval.min_amount);

			if (maxLessMin) {
				error = t(`"To" less than "From"`);
			}
		}

		return true;
	});

	return error;
};

export type validationFunctionType = (value: any, formState: any) => string | undefined;

export const isNotRequired =
	(arrayOfValidationFunctions: readonly validationFunctionType[]) => (value: string, formState: any) => {
		if (!value) {
			return undefined;
		}

		for (let i = 0; i < arrayOfValidationFunctions.length; i++) {
			const func = arrayOfValidationFunctions[i];
			if (typeof func === "function") {
				const error = func(value, formState);
				if (error) {
					return error;
				}
			}
		}

		return undefined;
	};
