// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Filter__wrapper-mUX .Filter__inlineFilters-Wg9{display:flex;flex-direction:row;gap:12px}.Filter__wrapper-mUX .Filter__inlineFilters-Wg9 button.Filter__showAdditionalFilters-hPQ{background:rgba(0,0,0,0);border:0}.Filter__additionalFiltersWrapper-z98{display:flex;flex-direction:column;gap:16px;height:calc(100% - 32px)}.Filter__additionalFiltersWrapper-z98 .Filter__scrollBox-LlW{flex:1;overflow-y:auto}.Filter__additionalFiltersWrapper-z98 .Filter__scrollBox-LlW .Filter__additionalFiltersForm-iIN{margin-top:16px;margin-bottom:16px;display:flex;flex-direction:column;gap:8px}.Filter__additionalFiltersWrapper-z98 .Filter__actions-xIC{display:flex;justify-content:space-between;padding-right:16px;gap:8px}`, "",{"version":3,"sources":["webpack://./src/components/Filter/Filter.module.scss"],"names":[],"mappings":"AACC,gDACC,YAAA,CACA,kBAAA,CACA,QAAA,CAEA,yFACC,wBAAA,CACA,QAAA,CAKH,sCACC,YAAA,CACA,qBAAA,CACA,QAAA,CACA,wBAAA,CAEA,6DACC,MAAA,CACA,eAAA,CAEA,gGACC,eAAA,CACA,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,OAAA,CAIF,2DACC,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,OAAA","sourcesContent":[".wrapper {\n\t.inlineFilters {\n\t\tdisplay: flex;\n\t\tflex-direction: row;\n\t\tgap: 12px;\n\n\t\tbutton.showAdditionalFilters {\n\t\t\tbackground: transparent;\n\t\t\tborder: 0;\n\t\t}\n\t}\n}\n\n.additionalFiltersWrapper {\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 16px;\n\theight: calc(100% - 32px);\n\n\t.scrollBox {\n\t\tflex: 1;\n\t\toverflow-y: auto;\n\n\t\t.additionalFiltersForm {\n\t\t\tmargin-top: 16px;\n\t\t\tmargin-bottom: 16px;\n\t\t\tdisplay: flex;\n\t\t\tflex-direction: column;\n\t\t\tgap: 8px;\n\t\t}\n\t}\n\n\t.actions {\n\t\tdisplay: flex;\n\t\tjustify-content: space-between;\n\t\tpadding-right: 16px;\n\t\tgap: 8px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Filter__wrapper-mUX`,
	"inlineFilters": `Filter__inlineFilters-Wg9`,
	"showAdditionalFilters": `Filter__showAdditionalFilters-hPQ`,
	"additionalFiltersWrapper": `Filter__additionalFiltersWrapper-z98`,
	"scrollBox": `Filter__scrollBox-LlW`,
	"additionalFiltersForm": `Filter__additionalFiltersForm-iIN`,
	"actions": `Filter__actions-xIC`
};
export default ___CSS_LOADER_EXPORT___;
