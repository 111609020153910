import { FC } from "react";

import styles from "./dateTime.module.scss";

const DateTime: FC<DateTimeType> = ({ dateTime, dataTestId }) => {

	const [date, time] = dateTime.split(" ");
	return (
		<div data-test-id={dataTestId}>
			<div>{date}</div>
			<div className={styles.time}>{time}</div>
		</div>
	);
};

export default DateTime;

type DateTimeType = {
	readonly dateTime: string
	readonly dataTestId?: string
}