import React, { ReactNode, useState } from "react";

import ChevronSVG from './chevron.svg';
import styles from "./Spoiler.module.scss";

interface ISpoilerProps {
	readonly title: ReactNode;
	readonly isExpanded?: boolean;
	readonly children: React.ReactNode;
	readonly className?: string;
	readonly expandedClassName?: string;
}

const Spoiler: React.FC<ISpoilerProps> = ({ title, isExpanded = false, children, className, expandedClassName }) => {
	const [expanded, setExpanded] = useState<boolean>(isExpanded);

	const classNames = [styles.spoiler];

	if (expanded) {
		classNames.push(styles.expanded);

		if (expandedClassName) {
			classNames.push(expandedClassName);
		}
	}

	if (className) {
		classNames.push(className);
	}

	return (
		<div className={classNames.join(' ')}>
			<div
				className={styles.control}
				onClick={() => setExpanded((prevState) => !prevState)}
				data-test-id=""
				role="button"
			>
				{title}
				<ChevronSVG />
			</div>
			<div className={styles.body}>
				{children}
			</div>
		</div>
	);
};

export default Spoiler;
